import React from 'react';
import PropTypes from 'prop-types';

function SEOQuestionType(props) {
  const { content } = props;
  let showcase = '';
  if (content.type === 'TYPE0') {
    const {
      question, explainer1, explainer2,
    } = content;
    showcase = (
      <div className="mx-0">
        <content.questionTag>
          {question}
        </content.questionTag>
        <div className="mx-0 mt-2 text-secondary">
          {explainer1}
        </div>
        <div className="mx-0 mt-2 text-secondary">
          {explainer2}
        </div>
      </div>
    );
    return showcase;
  }
  if (content.type === 'TYPE1') {
    const {
      question, explainer,
    } = content;
    showcase = (
      <div className="mx-0">
        <content.questionTag>
          {question}
        </content.questionTag>
        <div className="mx-0 mt-2 text-secondary">
          {explainer}
        </div>
      </div>
    );
    return showcase;
  }
  if (content.type === 'TYPE2') {
    const {
      question, explainer1, explainer2, explainer3,
    } = content;
    showcase = (
      <div>
        <content.questionTag>
          {question}
        </content.questionTag>
        <div className="mx-0 mt-2 text-secondary">
          {explainer1}
        </div>
        <div className="mx-0 mt-2 text-secondary">
          <ul>
            {
              explainer2.map((item) => (
                <li>{item}</li>
              ))
            }
          </ul>
        </div>
        <div className="mx-0 text-secondary">
          {explainer3}
        </div>
      </div>
    );
    return showcase;
  }
  if (content.type === 'TYPE3') {
    const {
      question, explainer,
    } = content;
    showcase = (
      <div>
        <content.questionTag>
          {question}
        </content.questionTag>
        <div className="mx-0 mt-2">
          <ul className="mb-0">
            {explainer.map((item) => (
              <>
                <item.headerTag
                  href={item.href}
                >
                  <li>
                    {item.header}
                  </li>
                </item.headerTag>
                <div className="text-secondary mb-3">{item.explainer}</div>
              </>
            ))}
          </ul>
        </div>
      </div>
    );
    return showcase;
  }
  if (content.type === 'TYPE4') {
    const {
      question, explainer1, explainer2,
    } = content;
    showcase = (
      <div>
        <content.questionTag>
          {question}
        </content.questionTag>
        <div className="mx-0 mt-2">
          <ul className="mb-0">
            {explainer1.map((item) => (
              <>
                <item.headerTag>
                  <li>
                    {item.header}
                  </li>
                </item.headerTag>
                <div className="text-secondary mb-3">{item.explainer}</div>
              </>
            ))}
          </ul>
        </div>
        <div className="mx-0 text-secondary">
          {explainer2}
        </div>
      </div>
    );
    return showcase;
  }
}

SEOQuestionType.propTypes = {
  content: PropTypes.shape({}).isRequired,
};

export default SEOQuestionType;
