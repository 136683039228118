import React from 'react';
import PropTypes from 'prop-types';
import loader from './lottie-files/loader.json';
import orderPlacedSuccessful from './lottie-files/order-placed-successful.json';
import orderAckSuccess from './lottie-files/lottie_ack_success.json';
import offerUnlockedCircular from './lottie-files/offer-unlocked-circular.json';
import offerUnlockedBlast from './lottie-files/offer-unloacked-blast.json';

const isMobile = window.screen.width < 992;

function Lottie(props) {
  const {
    animationData, height, width, animationFor,
    speed, background, mode, preserveAspectRatio, autoplay,
  } = props;

  let animationDataJson = loader;
  if (animationData) {
    animationDataJson = animationData;
  } else {
    switch (animationFor) {
      case 'ORDER_PLACED':
        animationDataJson = orderPlacedSuccessful;
        break;

      case 'LOADER':
        animationDataJson = loader;
        break;

      case 'ORDER_ACK':
        animationDataJson = orderAckSuccess;
        break;

      case 'OFFER_UNLOCKED_CIRCULAR':
        animationDataJson = offerUnlockedCircular;
        break;

      case 'OFFER_UNLOCKED_BLAST':
        animationDataJson = offerUnlockedBlast;
        break;

      default:
        break;
    }
  }

  return (
    <div>
      <lottie-player
        src={JSON.stringify(animationDataJson)}
        background={background}
        speed={speed}
        mode={mode}
        preserveAspectRatio={preserveAspectRatio}
        autoplay={autoplay}
        style={{
          width,
          height,
        }}
      />
    </div>
  );
}

Lottie.propTypes = {
  animationData: PropTypes.shape({}),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  animationFor: PropTypes.string,
  background: PropTypes.string,
  speed: PropTypes.number,
  mode: PropTypes.string,
  preserveAspectRatio: PropTypes.string,
  // loop: PropTypes.bool,
  autoplay: PropTypes.bool,
};

Lottie.defaultProps = {
  animationData: null,
  height: isMobile ? 300 : 600,
  width: isMobile ? 300 : 600,
  animationFor: '',
  background: 'transparent',
  speed: 1,
  mode: 'normal',
  preserveAspectRatio: 'xMidYMid slice',
  // loop: true,
  autoplay: true,
};

export default Lottie;
