import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12.0001C22 16.9633 17.8184 21 12.6772 21C8.88411 21 5.50238 18.8162 4.03999 15.4412C3.85717 15.0442 4.06278 14.5809 4.47418 14.4264C4.88539 14.272 5.36532 14.4485 5.52535 14.8456C6.71342 17.6471 9.52407 19.4559 12.6545 19.4559C16.9046 19.4559 20.3777 16.1029 20.3777 12C20.3777 7.89717 16.9045 4.54422 12.6545 4.54422C9.29557 4.54422 6.4165 6.5956 5.34255 9.55153L5.6167 9.26472C5.9138 8.95591 6.41653 8.93391 6.7592 9.22056C7.07908 9.50737 7.10188 9.99269 6.80494 10.3235L4.72556 12.5294C4.56554 12.6838 4.35993 12.794 4.13151 12.794H4.01723C3.74308 12.7499 3.51453 12.5955 3.40024 12.3529L2.07495 9.61769C1.89213 9.22071 2.05216 8.7574 2.46338 8.58093C2.8746 8.40446 3.35452 8.55893 3.53732 8.95591L3.72014 9.35289C4.90837 5.60312 8.49575 3 12.6544 3C17.7956 3 22 7.03673 22 11.9999L22 12.0001ZM12.9286 7.69865C12.9286 7.27949 12.5629 6.92651 12.1287 6.92651C11.6946 6.92651 11.3289 7.27949 11.3289 7.69865L11.3291 12.0001C11.3291 12.1986 11.3976 12.3971 11.5576 12.5515L14.7108 15.5957C14.8708 15.7502 15.0764 15.8163 15.282 15.8163C15.4877 15.8163 15.6933 15.7502 15.8533 15.5957C16.1732 15.2869 16.1732 14.8016 15.8533 14.4927L12.9285 11.6692V7.69869L12.9286 7.69865Z"
      fill={fill}
    />
  </svg>
);

Svg.defaultProps = {
  width: '24px',
  fill: '#000000',
};

Svg.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
};

export default Svg;
