import React from 'react';
import Axios from 'axios';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cartProductActions } from 'app/store/cart/actions';
import * as Actions from '../../../store/Actions';
import * as screens from '../../../events/screens';
import { Utils, Constants } from '../../../utilities';
import { Svg, List } from '../../common';
import CustomScroller from '../../common/CustomScroller';
import RepeatProduct from '../repeatProduct/RepeatProduct';
import PopularProduct from '../onlineProduct/PopularProduct';
import ProductCategory from '../../../layout/home/ProductCategory';

const { CancelToken } = Axios;

const seeAllLink = (onclick, language) => (
  <Button
    variant="link"
    className="font-weight-bold text-black"
    onClick={onclick}
  >
    <span
      className="d-flex align-items-center"
    >
      <span>
        {Constants.String.SEE_ALL[language].toUpperCase()}
      </span>
      &nbsp;&nbsp;
      <span>
        <Svg
          svg="circleArrow"
          circleFill={Constants.Color.primary}
          pathFill={Constants.Color.white}
          width="1.4rem"
        />
      </span>
    </span>
  </Button>
);

class DefaultGlobalSearchResult extends React.Component {
  constructor() {
    super();
    this.source = CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    const {
      previousOrderProductsRequest, popularProductsRequest, productCategoryRequest,
    } = this.props;
    if (Utils.isAuthenticated()) {
      previousOrderProductsRequest(
        this.source.token,
        null,
      );
    }
    popularProductsRequest(this.source.token);
    productCategoryRequest(this.source.token);
  }

  render() {
    const {
      previousOrderProducts, popularProducts, categories,
      cartItems, language, page, toggleSideOverlay,
      toggleLogin, history, match,
    } = this.props;

    if ((Utils.isAuthenticated() && !previousOrderProducts) || !popularProducts || !categories) {
      return (
        <div className="py-4 text-center">
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    return (
      <Container
        fluid
        className="px-0 bg-grey"
      >
        <Row
          className="mx-0"
        >
          {
            previousOrderProducts
            && previousOrderProducts.count !== 0
            && (
              <Col
                xs={24}
                className="bg-white mb-4 px-0"
              >
                <Container
                  className="px-0 px-lg-2 py-3"
                >
                  <Row
                    className="mx-0 py-2 d-flex direction-row font-weight-bold "
                  >
                    <Col
                      className="fs-2 px-4"
                    >
                      {`${Constants.String.WANT_TO_REPEAT[language]}?`}
                    </Col>
                    {
                      previousOrderProducts.count > 4
                        && (
                          <Col
                            xs="auto"
                            className="ml-auto px-2"
                          >
                            <Button
                              variant="link"
                              className="px-0 d-flex align-items-center fs-4 text-dark"
                              onClick={() => {
                                history.push('/store-wise-order-past-products');
                              }}
                            >
                              {Constants.String.SEE_ALL[language].toUpperCase()}
                              &nbsp;&nbsp;
                              <Svg
                                svg="circleArrow"
                                circleFill={Constants.Color.primary}
                                pathFill={Constants.Color.white}
                                width="1.5rem"
                              />
                            </Button>
                          </Col>
                        )
                    }
                  </Row>
                  <Row
                    className="py-2 mx-0"
                  >
                    <Col
                      xs={24}
                      className="px-4"
                    >
                      <CustomScroller
                        content={(
                          <div
                            className="d-flex"
                          >
                            {
                              previousOrderProducts.results.map(
                                (item) => (
                                  <Col
                                    key={item.id}
                                    xs={18}
                                    sm={12}
                                    md={8}
                                    className="mr-4 px-0"
                                  >
                                    <RepeatProduct
                                      key={item.id}
                                      item={item}
                                      cartItems={cartItems}
                                      language={language}
                                      isMobile
                                      history={history}
                                    />
                                  </Col>
                                ),
                              )
                            }
                          </div>
                        )}
                        contentPrefix="previous-order-products"
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
            )
          }
          {
            popularProducts
            && popularProducts.count !== 0
            && (
              <Col
                xs={24}
                className="bg-white mb-4 py-0 px-2"
              >
                <Container
                  className="py-3"
                >
                  <Row
                    className="align-items-center py-2"
                  >
                    <Col
                      className="font-weight-bold fs-2 px-2"
                    >
                      {Constants.String.POPULAR_PRODUCTS[language]}
                    </Col>
                    {
                      popularProducts.count > 4
                        ? (
                          <Col
                            xs="auto"
                            className="px-2"
                          >
                            {
                              seeAllLink(
                                () => {
                                  history.push('/popular-products');
                                },
                                language,
                              )
                            }
                          </Col>
                        ) : ''
                    }
                  </Row>
                  <Row
                    className="py-2"
                  >
                    <Col
                      xs={24}
                    >
                      <List
                        idField="id"
                        Component={PopularProduct}
                        list={popularProducts.results.slice(0, 2)}
                        language={language}
                        cartItems={cartItems}
                        isMobile
                        history={history}
                        toggleSideOverlay={toggleSideOverlay}
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
            )
          }
          {
            categories
            && categories.length !== 0
            && (
              <Col
                xs={24}
                className="px-2 py-0 mb-4 bg-white"
              >
                <Container
                  className="py-3"
                >
                  <Row
                    className="py-2"
                  >
                    <Col
                      className="font-weight-bold fs-2 px-2"
                    >
                      {Constants.String.EXPLORE_CATEGORIES[language]}
                    </Col>
                    {
                      categories.length > 10
                        ? (
                          <Col
                            xs="auto"
                            className="d-none d-md-block"
                          >
                            {
                              seeAllLink(
                                () => {
                                  history.push('/pc');
                                },
                                language,
                              )
                            }
                          </Col>
                        ) : ''
                    }
                  </Row>
                  <Row
                    className="py-2 border-bottom"
                  >
                    <Col
                      xs={24}
                    >
                      <List
                        idField="categoryId"
                        match={match}
                        Component={ProductCategory}
                        list={categories.slice(0, 6)}
                        history={history}
                        toggleLogin={toggleLogin}
                        page={page}
                        isMobile
                        screen={screens.STORE_DETAILS}
                      />
                    </Col>
                  </Row>
                  {
                    categories.length > 6
                      ? (
                        <Row
                          className="justify-content-center pt-3"
                        >
                          <Col
                            xs="auto"
                          >
                            {
                              seeAllLink(
                                () => {
                                  history.push('/pc');
                                },
                                language,
                              )
                            }
                          </Col>
                        </Row>
                      ) : ''
                  }
                </Container>
              </Col>
            )
          }
        </Row>
        {
          (!!previousOrderProducts || !!popularProducts || !!categories)
          && (
            <div className="p-7 p-md-0" />
          )
        }
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  previousOrderProducts: state.main.previousOrderProducts,
  popularProducts: state.main.popularProducts,
  categories: state.main.categories,
  cartItems: state.main.cartItems,
});

const mapDispatchToProps = (dispatch) => ({
  previousOrderProductsRequest: (cancelToken, onSuccess) => {
    dispatch(Actions.previousOrderProductsRequest(null, onSuccess, cancelToken));
  },
  popularProductsRequest: (cancelToken) => {
    dispatch(Actions.popularProductsRequest(0, 4, null, null, null, null, cancelToken));
  },
  productCategoryRequest: (cancelToken) => {
    dispatch(Actions.productCategoryRequest(cancelToken, null));
  },
  addToCart: (storeCode, productId, quantity, productInfo) => {
    dispatch(cartProductActions.request(
      storeCode, productId, quantity, null, null, productInfo, false, 'ADD',
    ));
  },
});

DefaultGlobalSearchResult.propTypes = {
  previousOrderProducts: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    count: PropTypes.number,
  }),
  popularProducts: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    count: PropTypes.number,
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  cartItems: PropTypes.shape({}),
  previousOrderProductsRequest: PropTypes.func.isRequired,
  popularProductsRequest: PropTypes.func.isRequired,
  productCategoryRequest: PropTypes.func.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

DefaultGlobalSearchResult.defaultProps = {
  previousOrderProducts: null,
  popularProducts: null,
  categories: null,
  cartItems: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultGlobalSearchResult);
