import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.566 14.566a.8.8 0 0 0 0-1.132l-4-4a.8.8 0 0 0-1.132 0l-4 4a.8.8 0 0 0 1.132 1.132L12 11.13l3.434 3.435a.8.8 0 0 0 1.132 0z"
      fill="#000"
    />
  </svg>
);

Svg.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
