import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import CustomRoundBadge from 'app/components/common/CustomRoundBadge';
import Svg from '../../components/common/Svg';

const SearchTextSuggestions = (props) => {
  const {
    suggestions, onSelectSuggestion, onStoreSelectionSuggestion,
  } = props;
  return (
    <Row
      className="search-text-suggestions mx-0"
    >
      {
        suggestions && suggestions.map((item) => (
          <Col
            xs={24}
            className="py-2 border-bottom cursor-pointer"
            key={item.name}
            onClick={() => {
              if (item.type === 'PRODUCT') {
                onSelectSuggestion(item.name);
              } else if (item.type === 'STORE') {
                onStoreSelectionSuggestion(item.storeId, item.name);
              }
            }}
          >
            <Row
              className="align-items-center"
            >
              <Col xs="auto">
                <Svg
                  svg="searchIcon"
                  height={40}
                  width={40}
                />
              </Col>
              <Col
                key={item.name}
              >
                <div
                  className="fs-5 font-weight-bold"
                >
                  {item.name}
                </div>
                <div
                  className="fs-6 text-medium"
                >
                  {item.type}
                </div>
              </Col>
              {
                item.offerText && (
                  <Col
                    xs="auto"
                  >
                    <CustomRoundBadge
                      badgeText={item.offerText}
                      isRibbon
                      badgeType="ROUND_OFFER"
                    />
                  </Col>
                )
              }
            </Row>
          </Col>
        ))
      }
    </Row>
  );
};

SearchTextSuggestions.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectSuggestion: PropTypes.func.isRequired,
  onStoreSelectionSuggestion: PropTypes.func.isRequired,
};

SearchTextSuggestions.defaultProps = {
  suggestions: null,
};

export default SearchTextSuggestions;
