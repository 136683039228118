import React from 'react';
import PropTypes from 'prop-types';

const PreviousSearchIcon = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke="#C8C8C8"
      strokeLinecap="round"
      strokeWidth={1.8}
      d="M20 12a8 8 0 1 0-8 8m8-8-2-1m2 1 1.56-1.56"
    />
    <path
      stroke="#C8C8C8"
      strokeLinecap="round"
      strokeWidth={1.8}
      d="M12 9v3.75L15 15"
    />
    <path
      stroke="#C8C8C8"
      strokeDasharray="0.2 4"
      strokeLinecap="round"
      strokeWidth={1.8}
      d="M20 12a7.999 7.999 0 0 1-8 8"
    />
  </svg>
);

PreviousSearchIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default PreviousSearchIcon;
