export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

export const RECENT_ADDRESS_REQUEST = 'RECENT_ADDRESS_REQUEST';
export const RECENT_ADDRESS_SUCCESS = 'RECENT_ADDRESS_SUCCESS';
export const RECENT_ADDRESS_FAILURE = 'RECENT_ADDRESS_FAILURE';

export const ADDRESS_LOAD_REQUEST = 'ADDRESS_LOAD_REQUEST';
export const ADDRESS_LOAD_SUCCESS = 'ADDRESS_LOAD_SUCCESS';
export const ADDRESS_LOAD_FAILURE = 'ADDRESS_LOAD_FAILURE';

export const ADDRESS_CHANGE_REQUEST = 'ADDRESS_CHANGE_REQUEST';
export const ADDRESS_CHANGE_SUCCESS = 'ADDRESS_CHANGE_SUCCESS';
export const ADDRESS_CHANGE_FAILURE = 'ADDRESS_CHANGE_FAILURE';

export const ADDRESS_DELETE_REQUEST = 'ADDRESS_DELETE_REQUEST';
export const ADDRESS_DELETE_SUCCESS = 'ADDRESS_DELETE_SUCCESS';
export const ADDRESS_DELETE_FAILURE = 'ADDRESS_DELETE_FAILURE';

export const ADDRESS_EDIT_REQUEST = 'ADDRESS_EDIT_REQUEST';
export const ADDRESS_EDIT_SUCCESS = 'ADDRESS_EDIT_SUCCESS';
export const ADDRESS_EDIT_FAILURE = 'ADDRESS_EDIT_FAILURE';

export const APPEX_OFFER_LOAD_REQUEST = 'APPEX_OFFER_LOAD_REQUEST';
export const APPEX_OFFER_LOAD_SUCCESS = 'APPEX_OFFER_LOAD_SUCCESS';
export const APPEX_OFFER_LOAD_FAILURE = 'APPEX_OFFER_LOAD_FAILURE';

export const APPLY_REWARD_REQUEST = 'APPLY_REWARD_REQUEST';
export const APPLY_REWARD_SUCCESS = 'APPLY_REWARD_SUCCESS';
export const APPLY_REWARD_FAILURE = 'APPLY_REWARD_FAILURE';

export const BOGO_OFFERS_LOAD_REQUEST = 'BOGO_OFFERS_LOAD_REQUEST';
export const BOGO_OFFERS_LOAD_SUCCESS = 'BOGO_OFFERS_LOAD_SUCCESS';
export const BOGO_OFFERS_LOAD_FAILURE = 'BOGO_OFFERS_LOAD_FAILURE';

export const CART_AVAILABLE_OFFERS_REQUEST = 'CART_AVAILABLE_OFFERS_REQUEST';
export const CART_AVAILABLE_OFFERS_SUCCESS = 'CART_AVAILABLE_OFFERS_SUCCESS';
export const CART_AVAILABLE_OFFERS_FAILURE = 'CART_AVAILABLE_OFFERS_FAILURE';

export const CART_ADD_REQUEST = 'CART_ADD_REQUEST';
export const CART_ADD_SUCCESS = 'CART_ADD_SUCCESS';
export const CART_ADD_FAILURE = 'CART_ADD_FAILURE';

export const CART_BULK_ADD_REQUEST = 'CART_BULK_ADD_REQUEST';
export const CART_BULK_ADD_SUCCESS = 'CART_BULK_ADD_SUCCESS';
export const CART_BULK_ADD_FAILURE = 'CART_BULK_ADD_FAILURE';

export const CART_LOAD_REQUEST = 'CART_LOAD_REQUEST';
export const CART_LOAD_SUCCESS = 'CART_LOAD_SUCCESS';
export const CART_LOAD_FAILURE = 'CART_LOAD_FAILURE';

export const CART_ID_LOAD_REQUEST = 'CART_ID_LOAD_REQUEST';
export const CART_ID_LOAD_SUCCESS = 'CART_ID_LOAD_SUCCESS';
export const CART_ID_LOAD_FAILURE = 'CART_ID_LOAD_FAILURE';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const CLEAR_SELECTED_ADDRESS = 'CLEAR_SELECTED_ADDRESS';

export const COUPON_CODE_REQUEST = 'COUPON_CODE_REQUEST';
export const COUPON_CODE_SUCCESS = 'COUPON_CODE_SUCCESS';
export const COUPON_CODE_FAILURE = 'COUPON_CODE_FAILURE';

export const COUPONS_LOAD_REQUEST = 'COUPONS_LOAD_REQUEST';
export const COUPONS_LOAD_SUCCESS = 'COUPONS_LOAD_SUCCESS';
export const COUPONS_LOAD_FAILURE = 'COUPONS_LOAD_FAILURE';

export const CUSTOMER_STAT_REQUEST = 'CUSTOMER_STAT_REQUEST';
export const CUSTOMER_STAT_SUCCESS = 'CUSTOMER_STAT_SUCCESS';
export const CUSTOMER_STAT_FAILURE = 'CUSTOMER_STAT_FAILURE';

export const DELIVERY_PREFERENCE_PATCH_REQUEST = 'DELIVERY_PREFERENCE_PATCH_REQUEST';
export const DELIVERY_PREFERENCE_PATCH_SUCCESS = 'DELIVERY_PREFERENCE_PATCH_SUCCESS';
export const DELIVERY_PREFERENCE_PATCH_FAILURE = 'DELIVERY_PREFERENCE_PATCH_FAILURE';

export const HOME_BANNERS_REQUEST = 'HOME_BANNERS_REQUEST';
export const HOME_BANNERS_SUCCESS = 'HOME_BANNERS_SUCCESS';
export const HOME_BANNERS_FAILURE = 'HOME_BANNERS_FAILURE';

export const HOME_TOP_BANNERS_REQUEST = 'HOME_TOP_BANNERS_REQUEST';
export const HOME_TOP_BANNERS_SUCCESS = 'HOME_TOP_BANNERS_SUCCESS';
export const HOME_TOP_BANNERS_FAILURE = 'HOME_TOP_BANNERS_FAILURE';

export const BRAND_BANNERS_REQUEST = 'BRAND_BANNERS_REQUEST';
export const BRAND_BANNERS_SUCCESS = 'BRAND_BANNERS_SUCCESS';
export const BRAND_BANNERS_FAILURE = 'BRAND_BANNERS_FAILURE';

export const HERO_BANNER_REQUEST = 'HERO_BANNER_REQUEST';
export const HERO_BANNER_SUCCESS = 'HERO_BANNER_SUCCESS';
export const HERO_BANNER_FAILURE = 'HERO_BANNER_FAILURE';

export const INITIAL_LOAD = 'INITIAL_LOAD';

export const LOGOUT = 'LOGOUT';

export const NEWLY_ADDED_STORES_REQUEST = 'NEWLY_ADDED_STORES_REQUEST';
export const NEWLY_ADDED_STORES_SUCCESS = 'NEWLY_ADDED_STORES_SUCCESS';
export const NEWLY_ADDED_STORES_FAILURE = 'NEWLY_ADDED_STORES_FAILURE';

export const STORE_NEW_CATEGORY_REQUEST = 'STORE_NEW_CATEGORY_REQUEST';
export const STORE_NEW_CATEGORY_SUCCESS = 'STORE_NEW_CATEGORY_SUCCESS';
export const STORE_NEW_CATEGORY_FAILURE = 'STORE_NEW_CATEGORY_FAILURE';

export const PREMIUM_STORES_REQUEST = 'PREMIUM_STORES_REQUEST';
export const PREMIUM_STORES_SUCCESS = 'PREMIUM_STORES_SUCCESS';
export const PREMIUM_STORES_FAILURE = 'PREMIUM_STORES_FAILURE';

export const NOTIFICATIONS_LOAD_REQUEST = 'NOTIFICATIONS_LOAD_REQUEST';
export const NOTIFICATIONS_LOAD_SUCCESS = 'NOTIFICATIONS_LOAD_SUCCESS';
export const NOTIFICATIONS_LOAD_FAILURE = 'NOTIFICATIONS_LOAD_FAILURE';

export const OFFERS_LOAD_REQUEST = 'OFFERS_LOAD_REQUEST';
export const OFFERS_LOAD_SUCCESS = 'OFFERS_LOAD_SUCCESS';
export const OFFERS_LOAD_FAILURE = 'OFFERS_LOAD_FAILURE';

export const ONLINE_PRODUCTS_REQUEST = 'ONLINE_PRODUCTS_REQUEST';
export const ONLINE_PRODUCTS_SUCCESS = 'ONLINE_PRODUCTS_SUCCESS';
export const ONLINE_PRODUCTS_FAILURE = 'ONLINE_PRODUCTS_FAILURE';

export const CATEGORY_PRODUCT_SEARCH_REQUEST = 'CATEGORY_PRODUCT_SEARCH_REQUEST';
export const CATEGORY_PRODUCT_SEARCH_SUCCESS = 'CATEGORY_PRODUCT_SEARCH_SUCCESS';
export const CATEGORY_PRODUCT_SEARCH_FAILURE = 'CATEGORY_PRODUCT_SEARCH_FAILURE';

export const POPULAR_PRODUCTS_REQUEST = 'POPULAR_PRODUCTS_REQUEST';
export const POPULAR_PRODUCTS_SUCCESS = 'POPULAR_PRODUCTS_SUCCESS';
export const POPULAR_PRODUCTS_FAILURE = 'POPULAR_PRODUCTS_FAILURE';

export const ONLINE_STORES_LOAD_REQUEST = 'ONLINE_STORES_LOAD_REQUEST';
export const ONLINE_STORES_LOAD_SUCCESS = 'ONLINE_STORES_LOAD_SUCCESS';
export const ONLINE_STORES_LOAD_FAILURE = 'STORES_LOAD_FAILURE';

export const SEO_METADATA_LOAD_REQUEST = 'SEO_METADATA_LOAD_REQUEST';
export const SEO_METADATA_LOAD_SUCCESS = 'SEO_METADATA_LOAD_SUCCESS';
export const SEO_METADATA_LOAD_FAILURE = 'SEO_METADATA_LOAD_FAILURE';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE';

export const ORDER_PATCH_REQUEST = 'ORDER_PATCH_REQUEST';
export const ORDER_PATCH_SUCCESS = 'ORDER_PATCH_SUCCESS';
export const ORDER_PATCH_FAILURE = 'ORDER_PATCH_FAILURE';

export const ORDERS_CREATE_REQUEST = 'ORDERS_CREATE_REQUEST';
export const ORDERS_CREATE_SUCCESS = 'ORDERS_CREATE_SUCCESS';
export const ORDERS_CREATE_FAILURE = 'ORDERS_CREATE_FAILURE';

export const ORDERS_LOAD_REQUEST = 'ORDERS_LOAD_REQUEST';
export const ORDERS_LOAD_SUCCESS = 'ORDERS_LOAD_SUCCESS';
export const ORDERS_LOAD_FAILURE = 'ORDERS_LOAD_FAILURE';

export const PAST_PRODUCTS_REQUEST = 'PAST_PRODUCTS_REQUEST';
export const PAST_PRODUCTS_SUCCESS = 'PAST_PRODUCTS_SUCCESS';
export const PAST_PRODUCTS_FAILURE = 'PAST_PRODUCTS_FAILURE';

export const PREVIOUS_ORDER_PRODUCTS_REQUEST = 'PREVIOUS_ORDER_PRODUCTS_REQUEST';
export const PREVIOUS_ORDER_PRODUCTS_SUCCESS = 'PREVIOUS_ORDER_PRODUCTS_SUCCESS';
export const PREVIOUS_ORDER_PRODUCTS_FAILURE = 'PREVIOUS_ORDER_PRODUCTS_FAILURE';

export const PRODUCT_CATEGORY_REQUEST = 'PRODUCT_CATEGORY_REQUEST';
export const PRODUCT_CATEGORY_SUCCESS = 'PRODUCT_CATEGORY_SUCCESS';
export const PRODUCT_CATEGORY_FAILURE = 'PRODUCT_CATEGORY_FAILURE';

export const STORE_CATEGORIES_REQUEST = 'STORE_CATEGORIES_REQUEST';
export const STORE_CATEGORIES_SUCCESS = 'STORE_CATEGORIES_SUCCESS';
export const STORE_CATEGORIES_FAILURE = 'STORE_CATEGORIES_FAILURE';

export const PROFILE_EDIT_REQUEST = 'PROFILE_EDIT_REQUEST';
export const PROFILE_EDIT_SUCCESS = 'PROFILE_EDIT_SUCCESS';
export const PROFILE_EDIT_FAILURE = 'PROFILE_EDIT_FAILURE';

export const PROFILE_LOAD_REQUEST = 'PROFILE_LOAD_REQUEST';
export const PROFILE_LOAD_SUCCESS = 'PROFILE_LOAD_SUCCESS';
export const PROFILE_LOAD_FAILURE = 'PROFILE_LOAD_FAILURE';

export const REFERRAL_CODE_REQUEST = 'REFERRAL_CODE_REQUEST';
export const REFERRAL_CODE_SUCCESS = 'REFERRAL_CODE_SUCCESS';
export const REFERRAL_CODE_FAILURE = 'REFERRAL_CODE_FAILURE';

export const REWARD_LOAD_REQUEST = 'REWARD_LOAD_REQUEST';
export const REWARD_LOAD_SUCCESS = 'REWARD_LOAD_SUCCESS';
export const REWARD_LOAD_FAILURE = 'REWARD_LOAD_FAILURE';

export const STORE_AUXILIARY_REQUEST = 'STORE_AUXILIARY_REQUEST';
export const STORE_AUXILIARY_SUCCESS = 'STORE_AUXILIARY_SUCCESS';
export const STORE_AUXILIARY_FAILURE = 'STORE_AUXILIARY_FAILURE';

export const STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST = 'STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST';
export const STORE_WISE_PREV_ORDER_PRODUCTS_SUCCESS = 'STORE_WISE_PREV_ORDER_PRODUCTS_SUCCESS';
export const STORE_WISE_PREV_ORDER_PRODUCTS_FAILURE = 'STORE_WISE_PREV_ORDER_PRODUCTS_FAILURE';

export const SPEND_OFFERS_LOAD_REQUEST = 'SPEND_OFFERS_LOAD_REQUEST';
export const SPEND_OFFERS_LOAD_SUCCESS = 'SPEND_OFFERS_LOAD_SUCCESS';
export const SPEND_OFFERS_LOAD_FAILURE = 'SPEND_OFFERS_LOAD_FAILURE';

export const SPEND_OFFER_REQUEST = 'SPEND_OFFER_REQUEST';
export const SPEND_OFFER_SUCCESS = 'SPEND_OFFER_SUCCESS';
export const SPEND_OFFER_FAILURE = 'SPEND_OFFER_FAILURE';

export const STORES_LOAD_REQUEST = 'STORES_LOAD_REQUEST';
export const STORES_LOAD_SUCCESS = 'STORES_LOAD_SUCCESS';
export const STORES_LOAD_FAILURE = 'STORES_LOAD_FAILURE';

export const STORES_SPECIFIC_COUPON_REQUEST = 'STORES_SPECIFIC_COUPON_REQUEST';
export const STORES_SPECIFIC_COUPON_SUCCESS = 'STORES_SPECIFIC_COUPON_SUCCESS';
export const STORES_SPECIFIC_COUPON_FAILURE = 'STORES_SPECIFIC_COUPON_FAILURE';

export const TOP_PICKS_SHOPS_REQUEST = 'TOP_PICKS_SHOPS_REQUEST';
export const TOP_PICKS_SHOPS_SUCCESS = 'TOP_PICKS_SHOPS_SUCCESS';
export const TOP_PICKS_SHOPS_FAILURE = 'TOP_PICKS_SHOPS_FAILURE';

export const STORE_LIKE_REQUEST = 'STORE_LIKE_REQUEST';
export const STORE_LIKE_SUCCESS = 'STORE_LIKE_SUCCESS';
export const STORE_LIKE_FAILURE = 'STORE_LIKE_FAILURE';

export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';

export const TOGGLE_GUEST_LOCATION = 'TOGGLE_GUEST_LOCATION';

export const TOGGLE_MULTI_STORE_CHECKOUT = 'TOGGLE_MULTI_STORE_CHECKOUT';

export const TOGGLE_GLOBAL_SEARCH = 'TOGGLE_GLOBAL_SEARCH';

export const TOGGLE_MANAGE_LOCATION = 'TOGGLE_MANAGE_LOCATION';

export const TOGGLE_SCRATCH_CARD = 'TOGGLE_SCRATCH_CARD';

export const CART_NULL_REQUEST = 'CART_NULL_REQUEST';

export const TOP_SAVING_OFFER_REQUEST = 'TOP_SAVING_OFFER_REQUEST';
export const TOP_SAVING_OFFER_SUCCESS = 'TOP_SAVING_OFFER_SUCCESS';
export const TOP_SAVING_OFFER_FAILURE = 'TOP_SAVING_OFFER_FAILURE';

export const MONTHLY_SAMAN_SECTION_REQUEST = 'MONTHLY_SAMAN_SECTION_REQUEST';
export const MONTHLY_SAMAN_SECTION_SUCCESS = 'MONTHLY_SAMAN_SECTION_SUCCESS';
export const MONTHLY_SAMAN_SECTION_FAILURE = 'MONTHLY_SAMAN_SECTION_FAILURE';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';

export const RESET_REQUEST_STATUS = 'RESET_REQUEST_STATUS';

export const UNLOCK_REWARD_REQUEST = 'UNLOCK_REWARD_REQUEST';
export const UNLOCK_REWARD_SUCCESS = 'UNLOCK_REWARD_SUCCESS';
export const UNLOCK_REWARD_FAILURE = 'UNLOCK_REWARD_FAILURE';

export const UNUSED_OFFER_COUNT_REQUEST = 'UNUSED_OFFER_COUNT_REQUEST';
export const UNUSED_OFFER_COUNT_SUCCESS = 'UNUSED_OFFER_COUNT_SUCCESS';
export const UNUSED_OFFER_COUNT_FAILURE = 'UNUSED_OFFER_COUNT_FAILURE';

export const UPDATE_COACHMARK = 'UPDATE_COACHMARK';

export const VALUE_OFFER_REQUEST = 'VALUE_OFFER_REQUEST';
export const VALUE_OFFER_SUCCESS = 'VALUE_OFFER_SUCCESS';
export const VALUE_OFFER_FAILURE = 'VALUE_OFFER_FAILURE';

export const BEST_RATED_STORES_REQUEST = 'BEST_RATED_STORES_REQUEST';
export const BEST_RATED_STORES_SUCCESS = 'BEST_RATED_STORES_SUCCESS';
export const BEST_RATED_STORES_FAILURE = 'BEST_RATED_STORES_FAILURE';

export const APPLY_VALUE_OFFER_REQUEST = 'APPLY_VALUE_OFFER_REQUEST';
export const APPLY_VALUE_OFFER_FAILURE = 'APPLY_VALUE_OFFER_FAILURE';

export const REFERRAL_CODE_GET_REQUEST = 'REFERRAL_CODE_GET_REQUEST';
export const REFERRAL_CODE_GET_SUCCESS = 'REFERRAL_CODE_GET_SUCCESS';
export const REFERRAL_CODE_GET_FAILURE = 'REFERRAL_CODE_GET_FAILURE';

export const UPDATE_NOTIFY_PRODUCTS_DETAILS = 'UPDATE_NOTIFY_PRODUCTS_DETAILS';
