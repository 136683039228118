const { fbq = () => {} } = window;

export function fbLogPageView() {
  fbq('track', 'PageView');
}

export function fbLogPurchase(totalAmountValue = 0) {
  fbq('track', 'Purchase', {
    currency: 'INR',
    value: totalAmountValue,
  });
}

export function fbLogAddToCart(id, quantity, productValue) {
  // UPDATED AddToCart
  fbq('track', 'AddToCart',
    {
      content_id: id,
      value: productValue,
      currency: 'INR',
      quantity,
    });

  // OLD AddToCart
  // fbq('track', 'AddToCart', {
  //   content_type: 'product',
  //   contents: [
  //     id,
  //     quantity,
  //   ],
  // });
}

// To Log Product List Info
export function fbLogViewContent(skuIds = []) {
  fbq('track', 'ViewContent', {
    content_type: 'product',
    content_ids: skuIds,
  });
}

export function fbLogInitiateCheckout(checkoutInfo = {}) {
  fbq('track', 'InitiateCheckout', checkoutInfo);
}

export function fbLogAddPaymentInfo(paymentInfo = {}) {
  fbq('track', 'AddPaymentInfo', paymentInfo);
}

export function fbLogCustomEvent(
  eventName = '', eventData = null,
) {
  if (eventData) {
    fbq(
      'trackCustom',
      eventName,
      eventData,
    );
  } else {
    fbq(
      'trackCustom',
      eventName,
    );
  }
}
