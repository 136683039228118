import * as React from 'react';

const CashOnDeliveryIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2002 17.2V7.99995H20.8002V18.8H6.0002V17.2H19.2002Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2002 5.19995H18.8002V16.8H3.2002V5.19995ZM4.8002 6.79995V15.2H17.2002V6.79995H4.8002Z"
      fill="black"
    />
    <path
      d="M11.8959 10.3571C11.8101 10.793 11.6205 11.1502 11.3273 11.4286C11.0376 11.707 10.6423 11.9066 10.1416 12.0275L12.2929 14.5H10.4796L8.5 12.1099L8.70386 11.033H8.9882C9.52825 11.033 9.88591 10.9707 10.0612 10.8462C10.24 10.7179 10.3616 10.5549 10.426 10.3571H8.5V9.22527H10.4099C10.3526 9.08242 10.2722 8.9652 10.1685 8.87363C10.0683 8.78205 9.94671 8.71978 9.80365 8.68681C9.66059 8.65018 9.43884 8.63187 9.13841 8.63187H8.5V7.5H13.5V8.63187H11.4238C11.5883 8.76374 11.7207 8.96154 11.8208 9.22527H13.5V10.3571H11.8959Z"
      fill="black"
    />
  </svg>
);
export default CashOnDeliveryIcon;
