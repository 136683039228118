import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24.002"
  >
    <g id="Frames-24px">
      <rect y="0" fill="none" width={width} />
    </g>
    <g id="Outline">
      <g>
        <path
          fill={fill}
          d="M15.999,8c0-2.033-2.94-6.225-4-7.759c-0.691,1-4,5.613-4,7.759c0,2.205,1.794,4,4,4
           S15.999,10.205,15.999,8z M11.999,10c-1.103,0-2-0.897-2-2c0-0.662,0.933-2.473,2-4.179c1.067,1.705,2,3.517,2,4.179
           C13.999,9.103,13.102,10,11.999,10z"
        />
        <path
          fill={fill}
          d="M22.999,5h-2c-0.431,0-0.813,0.275-0.949,0.684l-2.488,7.465l-0.856-0.856c-0.391-0.39-1.023-0.39-1.414,0
           l-2,2c-0.283,0.283-0.37,0.707-0.222,1.079l1.495,3.737c-0.333,0.163-0.566,0.497-0.566,0.892v3c0,0.553,0.448,1,1,1h7
           c0.552,0,1-0.447,1-1v-3c0-0.352-0.193-0.647-0.469-0.826l1.363-2.726c0.069-0.138,0.105-0.292,0.105-0.447V6
           C23.999,5.447,23.551,5,22.999,5z M15.999,14.414l1.293,1.293c0.245,0.245,0.599,0.346,0.937,0.266
           c0.337-0.079,0.609-0.328,0.719-0.656L21.72,7h0.279v8.764L20.381,19h-3.705l-1.504-3.759L15.999,14.414z M20.999,22h-5v-1h5V22z"
        />
        <path
          fill={fill}
          d="M8.706,12.293c-0.391-0.39-1.023-0.39-1.414,0l-0.856,0.856L3.948,5.684C3.812,5.275,3.43,5,2.999,5h-2
           c-0.552,0-1,0.447-1,1v10c0,0.155,0.036,0.309,0.105,0.447l1.363,2.726C1.192,19.353,0.999,19.648,0.999,20v3c0,0.553,0.448,1,1,1
           h7c0.552,0,1-0.447,1-1v-3c0-0.394-0.234-0.728-0.566-0.892l1.495-3.737c0.148-0.371,0.061-0.795-0.222-1.079L8.706,12.293z
            M1.999,7h0.279l2.772,8.316c0.11,0.328,0.382,0.577,0.719,0.656c0.339,0.08,0.692-0.021,0.937-0.266l1.293-1.293l0.827,0.827
           L7.322,19H3.617l-1.618-3.236V7z M7.999,22h-5v-1h5V22z"
        />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
