import React, { Component } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import String from '../../../../utilities/partner/Constants';
import Constants from '../../../../utilities/Constants';
import { Svg } from '../../../../components/common';
import style from './index.module.scss';
import { webEnquiry } from '../../../../../api/url';

const API_WEBENQUIRY = webEnquiry;

class Consultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      mobileNumber: '',
      nameOfShop: '',
      message: '',
      errors: {},
      status: '',
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { errors } = this.state;
    errors[name] = null;
    this.setState({
      [name]: value,
      errors,
    });
  }

  handleSubmit = () => {
    if (!this.validated()) {
      return;
    }
    const {
      email, mobileNumber, nameOfShop,
      message,
    } = this.state;
    const { history, language } = this.props;
    this.setState({ status: 'loading' });
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        mobileNumber,
        nameOfShop,
        message,
      }),
    };
    fetch(API_WEBENQUIRY, requestOptions)
      .then((res) => {
        if (res.ok) {
          this.setState({ status: 'success' }, () => {
            history.push(`/partner/${language}/thanks-for-your-interest`);
            setTimeout(() => {
              this.setState({
                email: '',
                mobileNumber: '',
                nameOfShop: '',
                message: '',
                errors: {},
                status: '',
              });
            }, 3000);
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        this.setState({
          status: 'error',
        });
      });
  }

  validated = () => {
    const {
      mobileNumber,
    } = this.state;
    const errors = {};
    if (mobileNumber === '') {
      errors.mobileNumber = String.MOBILE_FIELD_EMPTY_ERROR;
    } else if (mobileNumber.trim().length < 10) {
      errors.mobileNumber = String.INVALID_MOBILE_ERROR;
    } else if (mobileNumber.match('^[0-9]{0,10}$') === null) {
      errors.mobileNumber = String.INVALID_MOBILE_ERROR;
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  render() {
    const {
      mobileNumber, errors, status,
    } = this.state;
    const { language } = this.props;
    return (
      <Container
        fluid
        className="bg-white"
      >
        <Row>

          <div className={style.helpImage}>
            <img src="/Assets/contactSupport.jpg" alt="Get help" />
          </div>
          <Col
            xs={24}
            className="pt-6"
          >
            <input
              className={` ${style.inputBox}`}
              type="tel"
              onChange={this.handleChange}
              value={mobileNumber}
              name="mobileNumber"
              placeholder={`${String.MOBILE_NUMBER[language]}*`}
            />
          </Col>
          <Col
            xs={24}
          >
            <div className={style.helpSubmit}>
              <button
                onClick={this.handleSubmit}
                disabled={status === 'loading' || status === 'success'}
                type="submit"
              >
                {
            String.SUBMIT[language]
            }
                <Svg
                  svg="arrowRight"
                  fill="none"
                  stroke={Constants.Color.white}
                  width="20px"
                />

              </button>
            </div>

            {errors.mobileNumber && <div className="text-danger pt-6 fs-6">{errors.mobileNumber[language]}</div>}

            <div className={style.m_b_30} />

          </Col>
          {status === 'success'
           && (
           <Col
             xs={24}
             className="mt-6 p-3 text-center bg-green"
           >
             <b>
               {String.THANK_YOU_FOR_MSG[language]}
             </b>
           </Col>
           )}
          {status === 'error'
           && (
           <Col
             xs={24}
             className="mt-6 p-3 text-center bg-danger"
           >
             <b>
               {String.OOPS_PLEASE_TRY_AGAIN[language]}
             </b>
           </Col>
           )}
        </Row>
      </Container>
    );
  }
}

Consultation.propTypes = {
  language: PropTypes.string.isRequired,
  history: PropTypes.func.isRequired,
};

export default Consultation;
