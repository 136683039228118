import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      d="M20 21v.8c.442 0 .8-.358.8-.8H20zM4 21h-.8c0 .442.358.8.8.8V21zM15.2 7c0 1.767-1.433 3.2-3.2 3.2v1.6c2.651 0 4.8-2.149 4.8-4.8h-1.6zM12 3.8c1.767 0 3.2 1.433 3.2 3.2h1.6c0-2.651-2.149-4.8-4.8-4.8v1.6zM8.8 7c0-1.767 1.433-3.2 3.2-3.2V2.2C9.349 2.2 7.2 4.349 7.2 7h1.6zm3.2 3.2c-1.767 0-3.2-1.433-3.2-3.2H7.2c0 2.651 2.149 4.8 4.8 4.8v-1.6zM20.8 21c0-4.86-3.94-8.8-8.8-8.8v1.6c3.976 0 7.2 3.224 7.2 7.2h1.6zM4 21.8h16v-1.6H4v1.6zm8-9.6c-4.86 0-8.8 3.94-8.8 8.8h1.6c0-3.976 3.224-7.2 7.2-7.2v-1.6z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
