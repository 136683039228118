import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Utils } from '../../utilities';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import { logButtonClick } from '../../events/Events';

function Nav(props) {
  const {
    buttonAction, childNode, link, className,
    state, toggleLogin, history, navBarDisplayText,
    toggleGlobalSearch, isHrefApplicable,
  } = props;
  return (
    <Button
      variant="link"
      as={isHrefApplicable ? Link : 'div'}
      to={{
        pathname: link,
        state: {
          scrollToTop: true,
        },
      }}
      className={`${className} ${state}`}
      onClick={() => {
        if (navBarDisplayText === 'SEARCH') {
          toggleGlobalSearch();
          return;
        }
        if (link === '/account') {
          LogClevertapEvent.accountTabClicked();
        }
        if (['/account', '/orders'].includes(link) && !Utils.isAuthenticated()) {
          toggleLogin();
          return;
        }
        logButtonClick(
          {
            BUTTON: buttonAction,
          },
        );
        if (!isHrefApplicable) {
          history.push({
            pathname: link,
            state: {
              scrollToTop: true,
            },
          });
        }
      }}
    >
      <span
        className="font-weight-normal"
      >
        {childNode}
      </span>
    </Button>
  );
}

Nav.propTypes = {
  childNode: PropTypes.element.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isHrefApplicable: PropTypes.bool.isRequired,
  buttonAction: PropTypes.string.isRequired,
  toggleGlobalSearch: PropTypes.func.isRequired,
  navBarDisplayText: PropTypes.string.isRequired,
};

export default Nav;
