import * as React from 'react';

const StorePickUpUnchecked = (props) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 9H20V19.7603C20.4587 19.5936 20.9753 19.6597 21.3801 19.947L21.9208 20.3306C22.2841 20.5885 22.5 21.0065 22.5 21.452C22.5 21.9787 22.1991 22.4591 21.7253 22.6891L16.2432 25.3495C16.0391 25.4485 15.8152 25.5 15.5884 25.5H9.56426C9.15434 25.5 8.76227 25.3322 8.47923 25.0357L6.2065 22.6548C6.11216 22.5559 5.98147 22.5 5.84483 22.5H2.5C1.67157 22.5 1 21.8284 1 21V18C1 17.1716 1.67157 16.5 2.5 16.5H6V9ZM6.35511 17.4787C6.40097 17.4925 6.44962 17.5 6.5 17.5C6.65698 17.5 6.79706 17.4277 6.88872 17.3145L8.69299 16.6844C8.87818 16.6198 9.08407 16.6694 9.21951 16.8112L11.3429 19.0357C11.6259 19.3322 12.018 19.5 12.4279 19.5H14.4357C14.5724 19.5 14.7031 19.5559 14.7974 19.6548L15.1799 20.0554C15.2686 20.1484 15.3182 20.2721 15.3182 20.4007V21.5H12.4279C12.2913 21.5 12.1606 21.4441 12.0662 21.3452L10.4526 19.6548C10.2619 19.455 9.94542 19.4477 9.74567 19.6383C9.54592 19.829 9.53856 20.1455 9.72923 20.3452L11.3429 22.0357C11.6259 22.3322 12.018 22.5 12.4279 22.5H16.5267C16.7691 22.5 17.0079 22.4412 17.2227 22.3287L20.28 20.7273C20.4466 20.6401 20.648 20.6536 20.8014 20.7625L21.342 21.1462C21.4411 21.2165 21.5 21.3305 21.5 21.452C21.5 21.5957 21.4179 21.7267 21.2887 21.7894L15.8067 24.4498C15.7386 24.4828 15.664 24.5 15.5884 24.5H9.56426C9.42762 24.5 9.29693 24.4441 9.20259 24.3452L6.92986 21.9643C6.64682 21.6678 6.25475 21.5 5.84483 21.5H2.5C2.22386 21.5 2 21.2761 2 21V18C2 17.7239 2.22386 17.5 2.5 17.5H6.10315C6.18773 17.5 6.27202 17.4928 6.35511 17.4787Z"
      fill="black"
    />
    <circle cx={23} cy={6} r={6} fill="white" />
    <circle cx={23} cy={6} r={6} fill="#D34E66" fillOpacity={0.8} />
    <circle cx={23} cy={6} r={5.5} stroke="black" strokeOpacity={0.32} />
    <path
      d="M20.75 8.25L25.25 3.75M20.75 3.75L25.25 8.25"
      stroke="white"
      strokeWidth={2}
    />
  </svg>
);
export default StorePickUpUnchecked;
