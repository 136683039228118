import React from 'react';
import PropTypes from 'prop-types';
import CustomSwiperCarousel from '../CustomSwiperCarousel';
import QuickCard from '../QuickCard';

const style = {
  slide_wrapper: {
    paddingBottom: '2.5rem',
    cursor: 'pointer',
  },
};

const FeaturedBanner = ({
  banners, captureBrandBannerClicked, history, isMobile,
}) => {
  const slides = [];
  banners.map((banner) => (
    slides.push(
      <swiper-slide key={banner.bannerId}>
        <div
          style={style.slide_wrapper}
          onClick={() => {
            captureBrandBannerClicked(banner, 'featured');
            history.push(`/brands/${banner.name.toLowerCase()}-${banner.brandId}`);
          }}
        >
          <QuickCard
            name={banner.name}
            image={(isMobile) ? banner.image : banner.webimage}
            cardStyle={
              {
                width: '1170px',
                height: isMobile ? '180px' : '200px',
                overflow: 'hidden',
                borderRadius: '16px',
              }
            }
          />
        </div>
      </swiper-slide>,
    )
  ));

  let onlyOneSlide = false;

  if (slides.length === 1) {
    onlyOneSlide = true;
  }

  return (
    <div className="r__banner_wrapper">
      <CustomSwiperCarousel
        slides={slides}
        slidesPerView={(onlyOneSlide) ? 1 : 1.05}
        spaceBetween={8}
        autoPlay
        speed={500}
        pagination
        paginationClickable
      />
    </div>
  );
};

FeaturedBanner.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.shape({})),
  captureBrandBannerClicked: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

FeaturedBanner.defaultProps = {
  banners: [],
  captureBrandBannerClicked: () => {},
};

export default FeaturedBanner;
