import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.412 17.519"
    style={{
      width,
    }}
  >
    <path
      d="M165.874,1208.969l-9.733-4.866a.977.977,0,0,0-.87,0l-9.733,4.866a.974.974,0,0,0-.538.871v1.947a.974.974,0,0,0,.973.973h.973v7.786a.974.974,0,0,0,.973.973h15.572a.974.974,0,0,0,.973-.973v-7.786h.973a.974.974,0,0,0,.973-.973v-1.947A.974.974,0,0,0,165.874,1208.969Zm-7.248,4.764h-5.84v-.973h5.84Zm-5.84,1.947h5.84v.973h-5.84Zm0,2.92h5.84v.973h-5.84Zm11.679-7.786h-.973a.974.974,0,0,0-.973.973v7.786h-1.947v-7.786a.974.974,0,0,0-.973-.973h-7.786a.974.974,0,0,0-.973.973v7.786h-1.947v-7.786a.974.974,0,0,0-.973-.973h-.973v-.371l8.759-4.38,8.759,4.38Z"
      transform="translate(-145 -1204)"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
