import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 24 24"
  >
    <g
      fill={fill}
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        stroke={stroke}
      >
        <g>
          <g>
            <g>
              <path d="M20.849 10.38l-3.867 3.948c-.114.119-.166.285-.14.448l.892 5.631c.037.185-.035.375-.185.488-.15.114-.353.132-.52.046l-4.786-2.634c-.14-.077-.31-.077-.452 0l-4.819 2.636c-.169.086-.372.068-.523-.046-.15-.115-.222-.306-.183-.492l.92-5.625c.028-.165-.025-.332-.141-.452L3.15 10.381c-.134-.135-.183-.333-.127-.515.056-.182.208-.319.395-.356l5.359-.84c.16-.026.297-.13.365-.277l2.426-5.11c.077-.173.248-.283.436-.283s.36.11.436.282l2.393 5.107c.067.148.205.253.365.28l5.373.836c.19.033.346.169.405.353.058.184.01.385-.127.521h0z" transform="translate(-168 -250) translate(32 162) translate(14 16) translate(122 72)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

export default Svg;
