import React from 'react';

const Svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM11.5657 5.93432C11.2533 5.6219 10.7467 5.6219 10.4343 5.93432C10.1219 6.24673 10.1219 6.75327 10.4343 7.06569L12.5686 9.2H5.50001C5.05818 9.2 4.70001 9.55817 4.70001 10C4.70001 10.4418 5.05818 10.8 5.50001 10.8H12.5686L10.4343 12.9343C10.1219 13.2467 10.1219 13.7533 10.4343 14.0657C10.7467 14.3781 11.2533 14.3781 11.5657 14.0657L15.0657 10.5657C15.3781 10.2533 15.3781 9.74673 15.0657 9.43431L11.5657 5.93432Z" fill="#B8238E" />
  </svg>

);

export default Svg;
