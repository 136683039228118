import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 32 32"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.4 15.556c1.325 0 2.4-1.02 2.4-2.278S12.725 11 11.4 11 9 12.02 9 13.278s1.075 2.278 2.4 2.278zM16 8.084c0-3.088-1.635-4.309-2.886-4.05C11.501 4.366 10 7.912 10 11m-4 6.288l.233-8.09c.015-.52.433-.939.953-.954L15.287 8c.27-.008.533.096.724.288l9.701 9.7c.384.384.384 1.006 0 1.39l-8.334 8.334c-.383.383-1.005.383-1.389 0L6.288 18.01c-.191-.19-.295-.452-.288-.722z"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
