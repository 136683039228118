import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Svg from './Svg';

function CustomModal(props) {
  const {
    title, body, footer, show, size, onEnter,
    onHide, reset, closeButton, backdrop, shadow, showTitleImage, titleImage,
    customBackground, isRound,
  } = props;
  return (
    <Modal
      show={show}
      onEnter={onEnter}
      onHide={onHide}
      size={size}
      centered
      enforceFocus
      animation
      backdrop={backdrop}
      backdropClassName="z-index-1050"
      onExit={reset}
    >
      {
      title && (
        <Modal.Header
          className={`d-flex align-items-start ${isRound && 'r__modalHeader-round'}`}
          style={customBackground ? { background: '#fff3e0' } : { background: '#ffffff' }}
          closeButton={closeButton}
        >
          { showTitleImage && (
            <Svg
              svg={titleImage}
            />
          )}

          <Modal.Title>
            <h3 className={`${showTitleImage ? 'm-3' : 'm-0'}`}>{title}</h3>
          </Modal.Title>
        </Modal.Header>
      )
      }
      <Modal.Body className={`${shadow ? 'shadow-lg border-radius-16' : ''}`}>
        {body}
      </Modal.Body>
      {
        footer && (
          <Modal.Footer
            className="d-flex align-items-start bg-white"
          >
            {footer}
          </Modal.Footer>
        )
      }
    </Modal>
  );
}

CustomModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  body: PropTypes.element.isRequired,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  onEnter: PropTypes.func,
  onHide: PropTypes.func,
  reset: PropTypes.func,
  closeButton: PropTypes.bool,
  shadow: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showTitleImage: PropTypes.bool,
  titleImage: PropTypes.string,
  customBackground: PropTypes.bool,
  isRound: PropTypes.bool,
};

CustomModal.defaultProps = {
  title: null,
  footer: null,
  reset: () => {},
  closeButton: false,
  size: null,
  onHide: null,
  onEnter: null,
  backdrop: 'static',
  shadow: false,
  showTitleImage: false,
  titleImage: '',
  customBackground: false,
  isRound: false,
};

export default CustomModal;
