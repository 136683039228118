import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  stroke1, stroke2, fill1, fill2, width, height,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M7.676 1.42 1.343 13.293A1.5 1.5 0 0 0 2.667 15.5h12.666a1.5 1.5 0 0 0 1.324-2.206L10.323 1.42C9.76.36 8.241.36 7.676 1.42z"
      fill={fill1}
    />
    <path
      d="M7.676 1.42 1.343 13.293A1.5 1.5 0 0 0 2.667 15.5h12.666a1.5 1.5 0 0 0 1.324-2.206L10.323 1.42C9.76.36 8.241.36 7.676 1.42z"
      stroke={stroke1}
    />
    <path
      d="M7.676 1.42 1.343 13.293A1.5 1.5 0 0 0 2.667 15.5h12.666a1.5 1.5 0 0 0 1.324-2.206L10.323 1.42C9.76.36 8.241.36 7.676 1.42z"
      stroke={stroke2}
      // strokeOpacity=".64"
    />
    <path
      d="M9.803 4.987v3.146c0 .337-.02.672-.06 1.006-.04.33-.094.667-.16 1.012H8.428c-.066-.345-.12-.682-.16-1.012-.04-.334-.06-.67-.06-1.006V4.987h1.595zm-1.782 7.15c0-.133.024-.255.071-.369a.993.993 0 0 1 .204-.297.967.967 0 0 1 .308-.198.95.95 0 0 1 .385-.077c.136 0 .262.026.38.077.117.048.22.114.308.198a.875.875 0 0 1 .203.297.923.923 0 0 1 0 .742.875.875 0 0 1-.204.297.967.967 0 0 1-.687.27c-.14 0-.268-.024-.385-.072a.967.967 0 0 1-.308-.198.993.993 0 0 1-.204-.296.984.984 0 0 1-.071-.374z"
      fill={fill2}
    />
  </svg>
);

Svg.propTypes = {
  stroke1: PropTypes.string.isRequired,
  stroke2: PropTypes.string.isRequired,
  fill1: PropTypes.string.isRequired,
  fill2: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
