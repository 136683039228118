import * as React from 'react';

const CheckedIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={9.5} fill="#B8238E" stroke="#B8238E" />
    <path d="M8 12.0612L10.6667 15L16 9" stroke="white" strokeWidth={2} />
  </svg>
);
export default CheckedIcon;
