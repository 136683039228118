import React from 'react';
import PropTypes from 'prop-types';
import Placeholder from './Placeholder';
import { Constants } from '../../utilities';

class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, language } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div
          className="d-flex justify-content-center"
        >
          <Placeholder
            language={language}
            imageSrc="/images/error-placeholder.png"
            heading={Constants.String.OH_NO[language]}
            handleRetry={() => {
              window.location.reload();
            }}
          />
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.func, PropTypes.object,
  ]).isRequired,
  language: PropTypes.string.isRequired,
};

export default ErrorBoundary;
