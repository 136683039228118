import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.55 11.667c.994 0 1.8-.765 1.8-1.709 0-.943-.806-1.708-1.8-1.708s-1.8.765-1.8 1.708c0 .944.806 1.709 1.8 1.709zM12 6.063c0-2.316-1.226-3.231-2.164-3.038C8.626 3.275 7.5 5.934 7.5 8.25m-3 4.716l.175-6.068c.011-.39.324-.704.714-.715L11.466 6c.202-.006.399.072.542.216l7.276 7.276c.288.287.288.754 0 1.041l-6.25 6.25c-.288.289-.754.289-1.042 0l-7.276-7.275c-.144-.144-.222-.34-.216-.542z"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
