import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    fill="none"
    viewBox="0 0 29 29"
  >
    <path fill="#F4F4F4" d="M0 9c0-2.21 1.79-4 4-4h16c2.21 0 4 1.79 4 4v16c0 2.21-1.79 4-4 4H4c-2.21 0-4-1.79-4-4V9z" />
    <path fill="#000" fillRule="evenodd" d="M1.5 9.5h13v4.085c.156-.055.325-.085.5-.085h2.586c.398 0 .78.158 1.06.44l3.415 3.414c.281.28.439.662.439 1.06V21c0 .828-.672 1.5-1.5 1.5h-.55c-.232 1.141-1.24 2-2.45 2-1.21 0-2.218-.859-2.45-2h-6.1c-.232 1.141-1.24 2-2.45 2-1.21 0-2.218-.859-2.45-2H1.5v-13zm3.05 12c.232-1.141 1.24-2 2.45-2 1.21 0 2.218.859 2.45 2h4.05v-11h-11v11h2.05zm9.95 0h1.05c.232-1.141 1.24-2 2.45-2 1.21 0 2.218.859 2.45 2H21c.276 0 .5-.224.5-.5v-2.586c0-.132-.053-.26-.146-.353l-3.415-3.415c-.093-.093-.22-.146-.353-.146H15c-.276 0-.5.224-.5.5v6.5zM4.5 13c0-.276.224-.5.5-.5h6c.276 0 .5.224.5.5s-.224.5-.5.5H5c-.276 0-.5-.224-.5-.5zm2 2c0-.276.224-.5.5-.5h4c.276 0 .5.224.5.5s-.224.5-.5.5H7c-.276 0-.5-.224-.5-.5zm2 2c0-.276.224-.5.5-.5h2c.276 0 .5.224.5.5s-.224.5-.5.5H9c-.276 0-.5-.224-.5-.5zM7 20.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm11 0c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5z" clipRule="evenodd" />
    <circle cx="23" cy="6" r="6" fill="#fff" />
    <circle cx="23" cy="6" r="6" fill="#38B823" fillOpacity=".6" />
    <circle cx="23" cy="6" r="5.5" stroke="#000" strokeOpacity=".32" />
    <path stroke="#fff" strokeWidth="2" d="M20 6.046l2 2.204 4-4.5" />
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
};

export default Svg;
