import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ pathFill, circleFill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 32 32"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <g
        fillRule="nonzero"
      >
        <g>
          <g>
            <g
              transform="translate(-24 -522) translate(0 311) translate(16 203) translate(8 8)"
            >
              <circle
                cx="16"
                cy="16"
                r="8"
                fill={circleFill}
              />
              <path
                fill={pathFill}
                d="M17.516 4.592l1.527 1.418c.163.152.39.223.615.192l2.104-.285c1.165-.163 2.253.595 2.453 1.708l.366 2.009c.038.214.179.4.38.502l1.878.955c1.042.526 1.457 1.753.936 2.767l-.933 1.833c-.101.195-.101.425 0 .62l.933 1.834c.522 1.013.106 2.24-.936 2.765l-1.878.956c-.201.102-.341.288-.38.503l-.366 2.007c-.2 1.114-1.288 1.872-2.453 1.71l-2.104-.286c-.225-.031-.452.04-.615.192l-1.527 1.416c-.405.38-.95.592-1.515.592s-1.107-.213-1.512-.59l-1.526-1.417c-.163-.152-.39-.223-.616-.192l-2.104.286c-.102.014-.206.021-.31.021-1.058.001-1.963-.73-2.141-1.731l-.368-2.007c-.038-.216-.178-.401-.38-.503l-1.883-.957c-1.042-.525-1.458-1.752-.936-2.765l.933-1.833c.101-.196.101-.426 0-.621l-.933-1.833c-.52-1.014-.106-2.24.936-2.767l1.878-.955c.201-.102.342-.287.38-.502l.367-2.009c.198-1.113 1.287-1.872 2.452-1.708l2.104.285c.225.03.452-.04.615-.192l1.527-1.418c.843-.79 2.189-.79 3.032 0zm1.375 12.565c-1.46.002-2.643 1.137-2.644 2.538 0 1.4 1.184 2.536 2.644 2.537 1.459-.004 2.64-1.138 2.645-2.537-.005-1.4-1.186-2.534-2.645-2.538zm2.386-6.21c-.283-.27-.739-.27-1.021 0l-9.52 9.135c-.136.129-.212.305-.212.488 0 .184.076.36.212.49.134.13.318.203.51.203.19 0 .374-.074.509-.204l9.522-9.133c.28-.271.28-.709 0-.98zM13.12 9.774c-1.459.005-2.64 1.138-2.645 2.538.003 1.4 1.186 2.534 2.645 2.535 1.458-.004 2.64-1.136 2.645-2.535-.005-1.4-1.186-2.533-2.645-2.538zm5.77 8.766c.663.001 1.2.517 1.201 1.154 0 .636-.538 1.152-1.201 1.153-.664 0-1.2-.517-1.2-1.153-.004-.307.123-.6.349-.817.225-.216.532-.337.85-.337zm-5.77-7.382c.664 0 1.202.517 1.202 1.154 0 .637-.538 1.153-1.202 1.154-.664-.002-1.201-.518-1.202-1.154 0-.636.538-1.153 1.202-1.154z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  pathFill: PropTypes.string.isRequired,
  circleFill: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default Svg;
