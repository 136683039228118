import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth="2px"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 7.5h16.5l-1.009 9.905C19.033 19.447 17.314 21 15.261 21H8.739c-2.052 0-3.771-1.553-3.98-3.595L3.75 7.5h0zm12.75 0C16.5 5.015 14.485 3 12 3S7.5 5.015 7.5 7.5"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
