import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl } = apiUrls();
const brandProductsApis = {
  brandProducts: (cancelToken, params = {}) => {
    const products = `${newOOBaseUrl}customer/brand-products`;
    return Axios({
      url: products,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },
};
export default brandProductsApis;
