import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components/common';
import { Constants } from '../../utilities';

const style = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  title: {
    marginBottom: '0',
    textTransform: 'capitalize',
    fontWeight: 900,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    textTransform: 'capitalize',
    fontSize: '18px',
    linkHeight: '24px',
    fontWeight: '900',
  },
};

const SectionTitle = ({
  titleCopy, hasLink, linkUrl, linkCopy, linkSVG, hasBtn, children,
}) => (
  <div style={style.wrapper}>
    <h3 style={style.title}>{titleCopy}</h3>
    {
      hasLink
      && (
        <a href={linkUrl} style={style.link}>
          <span>{linkCopy}</span>
          <Svg
            svg={linkSVG}
            stroke={Constants.Color.primary}
            width="18px"
          />
        </a>
      )
    }
    {
      hasBtn
      && (
        children
      )
    }
  </div>
);

SectionTitle.propTypes = {
  titleCopy: PropTypes.string.isRequired,
  hasLink: PropTypes.bool,
  linkUrl: PropTypes.string,
  linkCopy: PropTypes.string,
  linkSVG: PropTypes.string,
  hasBtn: PropTypes.bool,
  children: PropTypes.element,
};

SectionTitle.defaultProps = {
  hasLink: false,
  linkUrl: '',
  linkCopy: '',
  linkSVG: '',
  hasBtn: false,
  children: null,
};

export default SectionTitle;
