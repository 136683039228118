import React from 'react';
import {
  Col, Container, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import StorePickUpChecked from 'app/components/common/svg/Cart/StorePickUpChecked';
import ScooterChecked from 'app/components/common/svg/Cart/ScooterChecked';
import { getAddressString } from 'app/utilities/Utils';
import { Constants } from 'app/utilities';
import SelectDeliveryOption from './SelectDeliveryOption';

function DeliveryPreferenceBar({ cartDetails, toggleSideOverlay, language }) {
  const { deliveryPreference, deliveryAddress } = cartDetails;
  return (
    <Row
      className="mx-0"
    >
      {
        deliveryPreference === 'STORE PICKUP' && (
          <Col xs={24}>
            <Container
              className="bg-white px-0"
            >
              <Row
                className="py-2"
              >
                <Col xs="auto">
                  <StorePickUpChecked />
                </Col>
                <Col
                  className="pl-0"
                >
                  <div
                    className="fs-6 text-dark font-weight-black"
                  >
                    {Constants.String.PICKUP_FROM_STORE[language]}
                    {/* Pickup from Shop */}
                  </div>
                  <div
                    className="fs-7 text-dark text-medium text-truncate-1"
                  >
                    {Constants.String.PICK_UP_THE_PARCEL_FROM[language]}
                    {/* Pick the parcel from the shop at your convenience */}
                  </div>
                </Col>
                <Col xs="auto">
                  <Button
                    className="fs-7"
                    variant="link"
                    onClick={() => toggleSideOverlay(SelectDeliveryOption, false)}
                  >
                    {Constants.String.CHANGE[language].toUpperCase()}
                    {/* CHANGE */}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        )
      }
      {
        deliveryPreference === 'HOME DELIVERY' && (
          <Col xs={24}>
            <Container
              className="bg-white px-lg-0 px-md-4"
            >
              <Row
                className="py-2"
              >
                <Col
                  xs="auto"
                  className="pt-2"
                >
                  <ScooterChecked />
                </Col>
                <Col
                  className="pl-0"
                >
                  <div
                    className=""
                  >
                    <span
                      className="fs-6 text-dark font-weight-black"
                    >
                      {`Deliver to ${deliveryAddress.label}`}
                    </span>
                  </div>
                  <div
                    className="fs-7 text-dark text-medium text-truncate-1"
                  >
                    {getAddressString(deliveryAddress)}
                  </div>
                </Col>
                <Col xs="auto">
                  <Button
                    className="fs-7 px-0"
                    variant="link"
                    onClick={() => toggleSideOverlay(SelectDeliveryOption, false)}
                  >
                    {Constants.String.CHANGE[language].toUpperCase()}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        )
      }
    </Row>
  );
}

DeliveryPreferenceBar.propTypes = {
  toggleSideOverlay: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  cartDetails: PropTypes.shape({
    deliveryPreference: PropTypes.string,
    deliveryAddress: PropTypes.shape({
      label: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default DeliveryPreferenceBar;
