import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ circleFill, pathFill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <circle
        cx="12"
        cy="12"
        r="9"
        fill={circleFill}
        fillRule="nonzero"
      />
      <path
        stroke={pathFill}
        strokeWidth="1.4px"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 9l3 3m-3 3l3-3"
      />
    </g>
  </svg>
);

Svg.propTypes = {
  circleFill: PropTypes.string.isRequired,
  pathFill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
