import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.211 3.578 2.447 19.106A2 2 0 0 0 4.237 22h15.527a2 2 0 0 0 1.789-2.894L13.789 3.578c-.737-1.474-2.84-1.474-3.578 0Z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.211 3.578 2.447 19.106A2 2 0 0 0 4.237 22h15.527a2 2 0 0 0 1.789-2.894L13.789 3.578c-.737-1.474-2.84-1.474-3.578 0Z"
      stroke="#D34E66"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10v4"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10v4"
      stroke="#D34E66"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17v1"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17v1"
      stroke="#D34E66"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
