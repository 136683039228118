import React, { Component } from 'react';
import {
  Button, Col, Row, Container,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { gaLogSignUpClicked } from 'ecommerce_ga_events/EcommerceGAEvents';
import { LocationSearch, Svg } from '../../components/common';
import * as Actions from '../../store/Actions';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as screens from '../../events/screens';
import { Constants } from '../../utilities';
import { getLanguage } from '../../utilities/Storage';

class GuestLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSetupYouLocationClicked: false,
    };
  }

  render() {
    const {
      isSetupYouLocationClicked,
    } = this.state;
    const {
      language, history, toggleLogin, isMobile,
      toggleGuestLocation, toggleLanguagePopup,
    } = this.props;
    return (
      <Container
        className="position-relative rounded bg-white"
      >
        <Row>
          {
            !isSetupYouLocationClicked && (
              <Col
                xs={24}
                lg={10}
                className="bg-white text-center text-white px-0"
              >
                <img
                  alt=""
                  src="/images/onboarding-lady.jpg"
                  className="w-100 rounded"
                />
              </Col>
            )
          }
          <Col
            xs={24}
            lg={isSetupYouLocationClicked ? 24 : 12}
            className={`px-5 pb-5 ${(!isMobile && isSetupYouLocationClicked) ? '' : 'mt-lg-2 pt-lg-5'}`}
          >
            {
              isSetupYouLocationClicked ? (
                <>
                  {
                    isMobile ? (
                      <Row
                        className="p-4 bg-primary text-white"
                      >
                        <Col
                          xs={24}
                          className="p-0 d-flex justify-content-start align-items-center"
                        >
                          <Button
                            variant="link"
                            className="p-0"
                            onClick={() => {
                              this.setState({
                                isSetupYouLocationClicked: false,
                              });
                            }}
                          >
                            <Svg
                              svg="lessThan"
                              width="24px"
                              stroke={Constants.Color.white}
                            />
                          </Button>
                          &nbsp;&nbsp;
                          <b>{Constants.String.ADD_LOCATION[language]}</b>
                        </Col>
                      </Row>
                    ) : (
                      <Row
                        className="font-weight-black border-bottom"
                      >
                        <Col
                          xs={24}
                          className="p-4 d-flex align-items-center"
                        >
                          <Button
                            variant="link"
                            className="p-0"
                            onClick={() => {
                              this.setState({
                                isSetupYouLocationClicked: false,
                              });
                            }}
                          >
                            <Svg
                              svg="arrowLeft"
                              width="24px"
                              stroke={Constants.Color.medium}
                            />
                          </Button>
                          &nbsp;&nbsp;
                          {Constants.String.ADD_LOCATION[language]}
                        </Col>
                      </Row>
                    )
                  }
                  <Row>
                    <Col
                      xs={24}
                      className="mt-3"
                    >
                      <LocationSearch
                        isMobile={isMobile}
                        language={language}
                        screen={screens.GUEST_LOCATION}
                        onSuccess={
                          {
                            type: 'async',
                            callback: (data, addLocationSuccess) => {
                              const currentPathName = `${history.location.pathname}${history.location.search}`;
                              addLocationSuccess(data);
                              LogClevertapEvent.guestLocationSelected({
                                SCREEN: screens.GUEST_LOCATION,
                              });
                              if (!isMobile && toggleGuestLocation) {
                                toggleGuestLocation(false);
                              }
                              if (!getLanguage()) {
                                if (isMobile) {
                                  history.replace('/language', { currentPathName });
                                } else if (toggleLanguagePopup) {
                                  history.push(currentPathName);
                                  toggleLanguagePopup();
                                }
                              } else if (isMobile) {
                                history.push('/');
                              } else {
                                history.push(currentPathName);
                              }
                            },
                          }
                      }
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <Row
                  className={isMobile ? 'text-center' : ''}
                >
                  {
                    !isMobile && (
                      <Col
                        xs={24}
                        className="mb-4"
                      >
                        <Svg
                          svg="graphicLogoLL"
                          width="165px"
                          height="110px"
                          fill={Constants.Color.primary}
                        />
                      </Col>
                    )
                  }
                  <Col
                    xs={24}
                    className="font-weight-black fs-1"
                  >
                    {Constants.String.READY_TO_ORDER_FROM_TOP_LOCAL_SHOP[language]}
                  </Col>
                  <Col
                    xs={24}
                    className="mt-4"
                  >
                    <Button
                      variant="primary"
                      className="py-2 fs-3 font-weight-black"
                      block
                      onClick={() => {
                        LogClevertapEvent.setGuestLocationClicked({
                          SCREEN: screens.GUEST_LOCATION,
                        });
                        this.setState({
                          isSetupYouLocationClicked: true,
                        });
                      }}
                    >
                      {Constants.String.SETUP_YOUR_LOCATION[language]}
                    </Button>
                  </Col>
                  <Col
                    xs={24}
                    className="mt-2 text-medium cursor-pointer"
                    onClick={() => {
                      LogClevertapEvent.userLoginClicked({
                        SCREEN: screens.GUEST_LOCATION,
                      });
                      gaLogSignUpClicked();
                      if (!isMobile) {
                        toggleGuestLocation(false);
                      }
                      toggleLogin();
                    }}
                  >
                    {Constants.String.ALREADY_HAVE_AN_ACCOUNT[language]}
                    &nbsp;
                    <b
                      className="text-primary"
                    >
                      {Constants.String.LOG_IN[language]}
                    </b>
                  </Col>
                </Row>
              )
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

GuestLocation.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  customer: PropTypes.shape({}),
  selectedAddress: PropTypes.shape({}),
  toggleGuestLocation: PropTypes.func,
  toggleLogin: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    replace: PropTypes.func,
  }).isRequired,
  toggleLanguagePopup: PropTypes.func,
};

GuestLocation.defaultProps = {
  customer: null,
  selectedAddress: null,
  toggleGuestLocation: null,
  toggleLanguagePopup: null,
};

const mapStateToProps = (state) => ({
  customer: state.main.customer,
  selectedAddress: state.main.selectedAddress,
  tryLogin: state.main.tryLogin,
});

const mapDispatchToProps = (dispatch) => ({
  addressLoadRequest: (onSuccess, onFailure) => {
    dispatch(Actions.addressLoadRequest(onSuccess, onFailure));
  },
  toggleLogin: () => {
    dispatch(Actions.toggleLogin());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestLocation);
