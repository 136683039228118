import * as React from 'react';

const ValueOfferInActive = (props) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.638 25.62C11.8351 25.8268 12.165 25.8268 12.3621 25.62L14.1991 23.6917C14.3069 23.5785 14.4614 23.5223 14.6168 23.5397L17.2635 23.836C17.5474 23.8678 17.8002 23.6557 17.8182 23.3706L17.986 20.7126C17.9958 20.5566 18.078 20.4142 18.2082 20.3277L20.4262 18.8534C20.6641 18.6952 20.7214 18.3703 20.5519 18.1403L18.9719 15.9963C18.8792 15.8705 18.8506 15.7086 18.8947 15.5586L19.6462 13.0035C19.7268 12.7295 19.5618 12.4437 19.2842 12.3765L16.6957 11.7497C16.5438 11.7129 16.4178 11.6072 16.3552 11.464L15.2884 9.02366C15.174 8.76192 14.864 8.64907 14.6081 8.77603L12.2223 9.95974C12.0823 10.0292 11.9178 10.0292 11.7778 9.95974L9.392 8.77603C9.13612 8.64907 8.82605 8.76192 8.71164 9.02366L7.64488 11.464C7.58228 11.6072 7.45632 11.7129 7.30441 11.7497L4.7159 12.3765C4.43827 12.4437 4.27329 12.7295 4.35389 13.0035L5.10533 15.5586C5.14942 15.7086 5.12087 15.8705 5.02815 15.9963L3.44813 18.1403C3.27866 18.3703 3.33596 18.6952 3.57385 18.8534L5.79188 20.3277C5.92204 20.4142 6.00426 20.5566 6.01411 20.7126L6.18189 23.3706C6.19988 23.6557 6.45265 23.8678 6.73652 23.836L9.3833 23.5397C9.53863 23.5223 9.69314 23.5785 9.80095 23.6917L11.638 25.62Z"
      fill="#DCFFC7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7521 24.5611L13.0862 26.3098C12.4951 26.9302 11.5052 26.9302 10.9141 26.3098L9.24813 24.5611L6.84789 24.8298C5.99625 24.9251 5.23795 24.2889 5.18397 23.4336L5.03182 21.0232L3.02039 19.6862C2.30671 19.2118 2.13482 18.2369 2.64321 17.5471L4.07606 15.6028L3.39461 13.2857C3.15283 12.4635 3.64777 11.6062 4.48066 11.4046L6.82806 10.8362L7.79546 8.62312C8.1387 7.83791 9.06889 7.49935 9.83655 7.88022L12.0001 8.95368L14.1637 7.88022C14.9314 7.49935 15.8616 7.83791 16.2048 8.62312L17.1722 10.8362L19.5196 11.4046C20.3525 11.6062 20.8474 12.4635 20.6057 13.2857L19.9242 15.6028L21.3571 17.5471C21.8655 18.2369 21.6936 19.2118 20.9799 19.6862L18.9684 21.0232L18.8163 23.4336C18.7623 24.2889 18.004 24.9251 17.1524 24.8298L14.7521 24.5611ZM12.3621 25.62C12.1651 25.8268 11.8352 25.8268 11.6381 25.62L9.80105 23.6917C9.69324 23.5785 9.53872 23.5223 9.3834 23.5397L6.73662 23.836C6.45274 23.8678 6.19998 23.6557 6.18198 23.3706L6.01421 20.7126C6.00436 20.5566 5.92214 20.4142 5.79198 20.3277L3.57395 18.8534C3.33606 18.6952 3.27876 18.3703 3.44822 18.1403L5.02825 15.9963C5.12097 15.8705 5.14952 15.7086 5.10542 15.5586L4.35398 13.0035C4.27339 12.7295 4.43837 12.4437 4.716 12.3765L7.30451 11.7497C7.45641 11.7129 7.58238 11.6072 7.64498 11.464L8.71174 9.02366C8.82615 8.76192 9.13621 8.64907 9.3921 8.77603L11.7779 9.95974C11.9179 10.0292 12.0824 10.0292 12.2224 9.95974L14.6082 8.77603C14.8641 8.64907 15.1741 8.76192 15.2885 9.02366L16.3553 11.464C16.4179 11.6072 16.5439 11.7129 16.6958 11.7497L19.2843 12.3765C19.5619 12.4437 19.7269 12.7295 19.6463 13.0035L18.8948 15.5586C18.8507 15.7086 18.8793 15.8705 18.972 15.9963L20.552 18.1403C20.7215 18.3703 20.6642 18.6952 20.4263 18.8534L18.2083 20.3277C18.0781 20.4142 17.9959 20.5566 17.9861 20.7126L17.8183 23.3706C17.8003 23.6557 17.5475 23.8678 17.2636 23.836L14.6169 23.5397C14.4615 23.5223 14.307 23.5785 14.1992 23.6917L12.3621 25.62Z"
      fill="#787878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.60293 13.6318C9.51438 13.7647 9.44336 13.977 9.44336 14.2364C9.44336 14.4959 9.51438 14.7082 9.60293 14.8411C9.69381 14.9774 9.76445 14.9837 9.77488 14.9837C9.78531 14.9837 9.85595 14.9774 9.94683 14.8411C10.0354 14.7082 10.1064 14.4959 10.1064 14.2364C10.1064 13.977 10.0354 13.7647 9.94683 13.6318C9.85595 13.4955 9.78531 13.4892 9.77488 13.4892C9.76445 13.4892 9.69381 13.4955 9.60293 13.6318ZM8.77088 13.0771C8.98095 12.762 9.32607 12.4892 9.77488 12.4892C10.2237 12.4892 10.5688 12.762 10.7789 13.0771C10.9913 13.3957 11.1064 13.8071 11.1064 14.2364C11.1064 14.6658 10.9913 15.0772 10.7789 15.3958C10.5688 15.7109 10.2237 15.9837 9.77488 15.9837C9.32607 15.9837 8.98095 15.7109 8.77088 15.3958C8.55848 15.0772 8.44336 14.6658 8.44336 14.2364C8.44336 13.8071 8.55848 13.3957 8.77088 13.0771Z"
      fill="#787878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7602 19.4525C13.6716 19.5854 13.6006 19.7977 13.6006 20.0571C13.6006 20.3166 13.6716 20.5289 13.7602 20.6618C13.851 20.7981 13.9217 20.8044 13.9321 20.8044C13.9425 20.8044 14.0132 20.7981 14.1041 20.6618C14.1926 20.5289 14.2636 20.3166 14.2636 20.0571C14.2636 19.7977 14.1926 19.5854 14.1041 19.4525C14.0132 19.3162 13.9425 19.3099 13.9321 19.3099C13.9217 19.3099 13.851 19.3162 13.7602 19.4525ZM12.9281 18.8978C13.1382 18.5827 13.4833 18.3099 13.9321 18.3099C14.3809 18.3099 14.726 18.5827 14.9361 18.8978C15.1485 19.2164 15.2636 19.6278 15.2636 20.0571C15.2636 20.4865 15.1485 20.8979 14.9361 21.2165C14.726 21.5316 14.3809 21.8044 13.9321 21.8044C13.4833 21.8044 13.1382 21.5316 12.9281 21.2165C12.7157 20.8979 12.6006 20.4865 12.6006 20.0571C12.6006 19.6278 12.7157 19.2164 12.9281 18.8978Z"
      fill="#787878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0508 12.5795C15.277 12.7379 15.332 13.0497 15.1737 13.2759L9.35302 21.5911C9.19466 21.8173 8.8829 21.8724 8.65667 21.714C8.43045 21.5556 8.37543 21.2439 8.53379 21.0177L14.3544 12.7024C14.5128 12.4762 14.8246 12.4212 15.0508 12.5795Z"
      fill="#787878"
    />
    <circle cx={22} cy={7} r={6.5} fill="black" stroke="black" />
    <path
      d="M19 12C18.725 12 18.4895 11.9067 18.2935 11.72C18.0975 11.5333 17.9997 11.3092 18 11.0476V6.28571C18 6.02381 18.098 5.79952 18.294 5.61286C18.49 5.42619 18.7253 5.33302 19 5.33333H19.5V4.38095C19.5 3.72222 19.7438 3.16064 20.2315 2.69619C20.7192 2.23175 21.3087 1.99968 22 2C22.6917 2 23.2813 2.23222 23.769 2.69667C24.2567 3.16111 24.5003 3.72254 24.5 4.38095V5.33333H25C25.275 5.33333 25.5105 5.42667 25.7065 5.61333C25.9025 5.8 26.0003 6.02413 26 6.28571V11.0476C26 11.3095 25.902 11.5338 25.706 11.7205C25.51 11.9071 25.2747 12.0003 25 12H19ZM22 9.61905C22.275 9.61905 22.5105 9.52571 22.7065 9.33905C22.9025 9.15238 23.0003 8.92825 23 8.66667C23 8.40476 22.902 8.18048 22.706 7.99381C22.51 7.80714 22.2747 7.71397 22 7.71429C21.725 7.71429 21.4895 7.80762 21.2935 7.99429C21.0975 8.18095 20.9997 8.40508 21 8.66667C21 8.92857 21.098 9.15286 21.294 9.33952C21.49 9.52619 21.7253 9.61936 22 9.61905ZM20.5 5.33333H23.5V4.38095C23.5 3.98413 23.3542 3.64683 23.0625 3.36905C22.7708 3.09127 22.4167 2.95238 22 2.95238C21.5833 2.95238 21.2292 3.09127 20.9375 3.36905C20.6458 3.64683 20.5 3.98413 20.5 4.38095V5.33333Z"
      fill="white"
    />
  </svg>
);
export default ValueOfferInActive;
