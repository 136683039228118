import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoveLocal } from '../../utilities/partner/content';
import String from '../../utilities/partner/Constants';

const FooterDeskTop = ({ pinkLogo, language }) => (
  <Container
    className="py-3"
  >
    <Row
      className="text-white fs-6 justify-content-between flex-row"
    >
      <Col xs="auto">
        <a
          {...LoveLocal.lovelocal}
        >
          <img
            alt="LoveLocal"
            src={pinkLogo ? '/Assets/logo-pink.png' : '/Assets/logo.png'}
            {
              ...pinkLogo
                ? ({
                  width: '200px',
                }) : ({
                  height: '120px',
                })
            }
            className={pinkLogo ? 'mx-n5 my-n6' : 'pb-4'}
          />
        </a>
        <div
          className="w-180p"
        >
          {String.ADDRESS_LINE_1[language]}
          ,
        </div>
        <div
          className="w-180p"
        >
          {String.ADDRESS_LINE_2[language]}
          ,
        </div>
        <div
          className="w-180p"
        >
          {String.ADDRESS_LINE_3[language]}
        </div>
      </Col>
      <Col
        xs="auto"
      >
        <h6
          className="text-gray mb-4"
        >
          {String.FOOTER_LOVELOCAL[language]}
        </h6>
        <div
          className="mb-4"
        >
          <Link
            to={{
              pathname: `/partner/${language}/about`,
              state: {
                scrollToTop: true,
              },
            }}
            className="text-white"
          >
            {String.ABOUT_US[language]}
          </Link>
        </div>
        <div
          className="mb-4"
        >
          <a
            {...LoveLocal.contactUs}
            className="text-white"
          >
            {String.CONTACT_US[language]}
          </a>
        </div>
        <div
          className="mb-4"
        >
          <a
            {...LoveLocal.press}
            className="text-white"
          >
            <div>
              Press
              :
            </div>
            <div>
              <a
                {...LoveLocal.press}
                className="text-white"
              >
                media@lovelocal.in
              </a>
            </div>
            {/* {String.SHOP_FROM_LOVELOCAL[language]} */}
          </a>
        </div>
        <div
          className="mb-4"
        >
          <div>
            {String.FOR_CAREERS[language]}
            :
          </div>
          <div>
            <a
              {...LoveLocal.career}
              className="text-white"
            >
              dream@lovelocal.in
            </a>
          </div>
        </div>
        <div>
          <div>
            {String.FOR_PARTNERSHIP[language]}
            :
          </div>
          <div>
            <a
              {...LoveLocal.partnership}
              className="text-white"
            >
              partner@lovelocal.in
            </a>
          </div>
        </div>
      </Col>
      <Col xs="auto">
        <h6 className="text-gray mb-4">
          {String.FOLLOW_US[language]}
        </h6>
        <div className="mb-4">
          <a
            {...LoveLocal.facebook}
            className="text-white"
          >
            {String.FACEBOOK[language]}
          </a>
        </div>
        <div className="mb-4">
          <a
            {...LoveLocal.twitter}
            className="text-white"
          >
            {String.TWITTER[language]}
          </a>
        </div>
        <div className="mb-4">
          <a
            {...LoveLocal.insta}
            className="text-white"
          >
            {String.INSTAGRAM[language]}
          </a>
        </div>
        <div className="mb-4">
          <a
            {...LoveLocal.linkedin}
            className="text-white"
          >
            {String.LINKEDIN[language]}
          </a>
        </div>
        <div>
          <a
            {...LoveLocal.youtube}
            className="text-white"
          >
            {String.YOUTUBE[language]}
          </a>
        </div>
      </Col>
      <Col xs="auto">
        <h6 className="text-gray mb-4">
          {String.LEGAL[language]}
        </h6>
        <div className="mb-4">
          <a
            {...LoveLocal.privacyPolicy}
            className="text-white"
          >
            {String.PRIVACY_POLICY[language]}
          </a>
        </div>
        <div>
          <a
            {...LoveLocal.tnc}
            className="text-white"
          >
            {String.TERMS_CONDITION[language]}
          </a>
        </div>
      </Col>
      <Col xs="auto">
        <a
          target="_a"
          href="https://play.google.com/store/apps/details?id=mpaani.com.android.yodaapp"
        >
          <img alt="Call-us" src="/Assets/google-play.svg" width="180px" />
        </a>
      </Col>
    </Row>
  </Container>
);

FooterDeskTop.propTypes = {
  pinkLogo: PropTypes.bool,
  language: PropTypes.string.isRequired,
};

FooterDeskTop.defaultProps = {
  pinkLogo: false,
};

export default FooterDeskTop;
