import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" d="M13.26 19.782a1.81 1.81 0 0 1-2.43 0l-.09-.09C6.054 15.393 2.912 12.627 3.002 9.23a5.04 5.04 0 0 1 2.07-3.846A5.25 5.25 0 0 1 12 6.367a5.25 5.25 0 0 1 6.929-.984 4.784 4.784 0 0 1 2.07 3.846c.09 3.488-2.97 6.26-7.74 10.553z" />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
