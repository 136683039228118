import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  cartProductActions, CART_PRODUCT_REQUEST,
} from 'app/store/cart/actions';
import { gaLogAddOrRemoveProductFromCart } from 'ecommerce_ga_events/EcommerceGAEvents';
import CustomRoundBadge from 'app/components/common/CustomRoundBadge';
import RoundedCartCounter from '../../components/derived/cart/RoundedCartCounter';
import { Constants } from '../../utilities';
import CustomImage from '../../components/common/CustomImage';
import { getCDNImageUrl, getSeoProductUrl } from '../../utilities/Utils';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';

function CartProduct(props) {
  const {
    product, store, isCart, isCheckout, reorder,
    language, outOfStock,
    updateCartOffers, history, toggleSideOverlay,
  } = props;

  let tag = {
    title: '',
    bgColor: '',
  };

  const {
    pathName, state, searchParams,
  } = getSeoProductUrl(product.libraryProductId, product.name, store.id);

  switch (product.tag) {
    case 'NEWLY ADDED':
      tag = {
        title: Constants.String.SUGGESTED_PRODUCT[language].toUpperCase(),
        bgColor: 'bg-green-light',
        textColor: 'text-green',
      };
      break;
    case 'QUANTITY_CHANGED':
      tag = {
        title: Constants.String.UPDATED_QUANTITY[language].toUpperCase(),
        bgColor: 'bg-yellow-light',
        textColor: 'text-black',
      };
      break;
    case 'PRICE_CHANGED':
      tag = {
        title: Constants.String.UPDATED_PRICE[language].toUpperCase(),
        bgColor: 'bg-yellow-light',
        textColor: 'text-black',
      };
      break;
    default: break;
  }

  return (
    <Col
      id="cart-product"
      xs={24}
      lg={reorder ? 12 : 24}
      className={`px-3 py-4 ${isCheckout ? 'fs-6' : ''}`}
    >
      <Row
        className={`d-flex flex-nowrap ${reorder ? 'border p-3' : ''}`}
      >
        <Col
          xs={4}
          className="px-0"
        >
          {
            product.offerTag ? (
              <div
                className="top-saving-offer-tag-badge"
              >
                <CustomRoundBadge
                  badgeText={product.offerTag}
                  isRibbon
                  isSmall
                  badgeType="ROUND_OFFER"
                />
              </div>
            ) : null
          }
          <Button
            variant="link"
            className="p-0 cart-product-image"
            onClick={() => {
              if (toggleSideOverlay) {
                toggleSideOverlay(null);
              }
              history.push({
                pathname: pathName,
                state,
                search: searchParams,
              });
            }}
          >
            <CustomImage
              imageUrl={getCDNImageUrl(product.imageUrl)}
              imageType="PRODUCT"
              imageStyle="mh-100 mw-100"
              imageAlt={product.name}
              imageTitle={product.name}
            />
          </Button>
        </Col>
        <Col
          xs="auto"
          className="flex-grow-1 flex-shrink-1 fs-5 px-2"
        >
          {
            (tag.title || outOfStock) && (
              <Row
                className="mx-0 mb-1"
              >
                <Col
                  xs="auto"
                  className="px-4"
                >
                  <span
                    className={`bg-primary py-1 fs-7 px-2 border-radius-4 font-weight-bold ${
                      outOfStock ? 'bg-danger-light text-danger' : `${tag.bgColor} ${tag.textColor}`
                    }`}
                  >
                    {outOfStock ? Constants.String.OUT_OF_STOCK[language].toUpperCase() : tag.title}
                  </span>
                </Col>
              </Row>
            )
          }
          <Row
            className="mx-0  text-truncate-2 word-break"
            title={product.name}
          >
            <b>{product.name}</b>
          </Row>
          <Row
            className="mx-0"
          >
            <span
              className="text-medium mr-2 word-break"
            >
              {product.measurementDisplayText}
            </span>
          </Row>
        </Col>
        <Col
          xs={5}
          className="px-0 d-flex justify-content-center"
        >
          {/* SP + CartCounter */}
          <Row className="mx-0">
            {
              !outOfStock && (
                <Col
                  xs="auto"
                  className="px-0"
                >
                  <RoundedCartCounter
                    language={language}
                    productInfo={{
                      storeName: store.name,
                      productName: product.name,
                      sellingPrice: product.price,
                      // mrp: product.mrp,
                      imageUrl: product.imageUrl,
                      measurementDisplayText: product.measurementDisplayText,
                    }}
                    storeCode={store.id}
                    productId={product.retailerProductId}
                    quantity={product.quantity}
                    isCart={isCart}
                    isCheckout={isCheckout || product.freeSample}
                    onSuccess={(productCount, isAdding) => {
                      if (updateCartOffers) {
                        updateCartOffers();
                      }
                      LogClevertapEvent.skusUpdatedToCart(
                        store.name,
                        product.name,
                        productCount,
                        // product.categories,
                      );
                      gaLogAddOrRemoveProductFromCart(
                        isAdding,
                        product.price,
                        {
                          item_id: product.retailerProductId,
                          item_name: product.name,
                          price: product.price,
                          quantity: productCount,
                        },
                      );
                    }}
                  />
                </Col>
              )
            }
          </Row>
        </Col>
        <Col
          xs={5}
          className="d-flex flex-column align-items-end justify-content-start px-2"
          style={{
            // maxWidth: '58px',
          }}
        >
          {/* Product Name + Delete Icon (Cart) */}
          <Row
            className="pb-1 mx-0"
          >
            <b>
              <span
                className=""
              >
                {`\u20B9 ${Math.ceil(product.price)}`}
              </span>
            </b>
          </Row>
          {
            Math.ceil(product.mrp) !== Math.ceil(product.price) && (
              <Row className="mx-0">
                <span
                  className="text-medium text-strike-through"
                >
                  <del>
                    {`\u20B9 ${Math.ceil(product.mrp)}`}
                  </del>
                </span>
              </Row>
            )
          }
        </Col>
      </Row>
    </Col>
  );
}

const mapDispatchToProps = (dispatch) => ({
  cartProductReq: (
    storeCode, productId, quantity, onSuccess, onFailure, productInfo, forceAdd, actionType,
  ) => {
    dispatch(
      cartProductActions.request(
        storeCode, productId, quantity, onSuccess, onFailure, productInfo, forceAdd, actionType,
      ),
    );
  },
});

const mapStateToProps = (state) => ({
  processing: (
    state.main.requestsProcessing[CART_PRODUCT_REQUEST]
  ),
});

CartProduct.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    retailerProductId: PropTypes.number,
    imageUrl: PropTypes.string,
    mrp: PropTypes.number,
    quantity: PropTypes.number,
    price: PropTypes.number,
    tag: PropTypes.string,
    measurementDisplayText: PropTypes.string,
    freeSample: PropTypes.bool,
    offerTag: PropTypes.string,
    libraryProductId: PropTypes.number,
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isCheckout: PropTypes.bool,
  isCart: PropTypes.bool,
  // enableTags: PropTypes.bool,
  language: PropTypes.string.isRequired,
  outOfStock: PropTypes.bool,
  processing: PropTypes.shape({}).isRequired,
  reorder: PropTypes.bool,
  requestsProcessing: PropTypes.shape({}),
  updateCartOffers: PropTypes.func,
  toggleSideOverlay: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

CartProduct.defaultProps = {
  isCheckout: false,
  isCart: false,
  // enableTags: false,
  outOfStock: false,
  requestsProcessing: null,
  reorder: false,
  updateCartOffers: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartProduct);
