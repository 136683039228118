import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill="#000"
      d="M13.383 5.315a1.493 1.493 0 0 0-1.833 0l-6.26 4.872c-.562.44-.253 1.34.459 1.34h.744v5.978A1.495 1.495 0 0 0 7.987 19h1.986a1 1 0 0 0 1-1v-3.484a1.495 1.495 0 0 1 1.494-1.495 1.492 1.492 0 0 1 1.493 1.495V18a1 1 0 0 0 1 1h1.986a1.492 1.492 0 0 0 1.494-1.495v-5.978h.744c.711 0 1.022-.9.46-1.339l-6.26-4.873Z"
    />
  </svg>
);

Svg.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Svg;
