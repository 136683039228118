import * as React from 'react';

const ScratchCardActive = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={2.5}
      y={6.5}
      width={19}
      height={19}
      rx={4.5}
      fill="url(#paint0_linear_1856_36846)"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1 19.6842V19.456C13.6669 19.3028 14.1957 19.0325 14.6486 18.6607C15.1337 18.2624 15.5166 17.7602 15.7669 17.194C17.3135 16.8562 18.5 15.5256 18.5 13.8947V12.1938V11.6938H18H16.1V11V10.5H15.6H8.4H7.9V11V11.6938H6H5.5V12.1938V13.8947C5.5 15.5256 6.68646 16.8562 8.23309 17.194C8.4834 17.7602 8.86625 18.2624 9.35145 18.6607C9.80433 19.0325 10.3331 19.3028 10.9 19.456V19.6842C10.9 19.8528 10.8308 20.0183 10.7013 20.1432C10.5713 20.2686 10.3913 20.3421 10.2 20.3421H9.6H9.1V20.8421V22V22.5H9.6H14.4H14.9V22V20.8421V20.3421H14.4H13.8C13.6087 20.3421 13.4287 20.2686 13.2987 20.1432C13.1692 20.0183 13.1 19.8528 13.1 19.6842ZM7.7 13.8945V13.8517H7.9V14.5525C7.7692 14.3547 7.70032 14.1265 7.7 13.8945ZM16.3 13.8941C16.2997 14.1263 16.2308 14.3547 16.1 14.5526V13.8517H16.3V13.8941Z"
      stroke="black"
    />
    <circle cx={21} cy={7} r={6.5} fill="#B8238E" stroke="#7D005A" />
    <path
      d="M17.5 7.05357L19.8333 9.625L24.5 4.375"
      stroke="white"
      strokeWidth={2}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1856_36846"
        x1={3}
        y1={25}
        x2={28.0919}
        y2={15.3897}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9C132" />
        <stop offset={1} stopColor="#F9ED32" />
      </linearGradient>
    </defs>
  </svg>
);
export default ScratchCardActive;
