import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import Axios from 'axios';
import { cartAddCouponRequest, cartCouponDeleteActions } from 'app/store/cart/actions';
import {
  Placeholder, CustomModal, Svg,
} from '../../components/common';
import * as Actions from '../../store/Actions';
import * as ActionTypes from '../../store/ActionTypes';
import Reward from '../../components/derived/offer/Reward';
import { Constants } from '../../utilities';
import * as screens from '../../events/screens';
import { logPageLoad } from '../../events/Events';
import RewardCoupon from './RewardCoupon';
import Cart from '../cart/Cart';

const { CancelToken } = Axios;

class RewardList extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    let cartStoreId = '';
    if (location && location.state && (
      location.state.applyOffer || location.state.cartStoreId
    )) {
      cartStoreId = location.state.cartStoreId;
    }
    if (props.listProps) {
      cartStoreId = props.listProps.cartStoreId;
    }
    this.state = {
      nextUrl: '',
      offerApplied: null,
      rewardError: '',
      cartStoreId,
    };
    this.source = CancelToken.source();
  }

  componentDidMount() {
    this.loadRewards();
  }

  componentDidUpdate() {
    const {
      failure, processing, rewards,
    } = this.props;
    if (!failure && !processing && !rewards) {
      this.loadRewards(0);
    } else {
      logPageLoad(
        {
          SCREEN: screens.REWARDS,
          REWARD_IDS: rewards ? rewards.data.reduce(
            (Acc, Item) => Acc.concat([Item.id]),
            [],
          ) : null,
        },
      );
    }
  }

  componentWillUnmount = () => {
    const { resetRequestStatus } = this.props;
    this.source.cancel();
    resetRequestStatus([
      ActionTypes.REWARD_LOAD_REQUEST,
      ActionTypes.APPLY_REWARD_REQUEST,
    ]);
  }

  loadRewards = () => {
    const { rewardLoadRequest } = this.props;
    const { cartStoreId, nextUrl } = this.state;
    rewardLoadRequest(
      {
        storeId: cartStoreId,
      },
      this.source.token,
      {
        type: 'sync',
        callback: (data) => {
          this.setState({
            nextUrl: data.res.next,
          });
        },
      },
      null,
      nextUrl,
    );
  }

  handleLazyLoad = (event) => {
    const { processing } = this.props;
    const { nextUrl } = this.state;
    if (processing || !nextUrl) {
      return;
    }
    const {
      offsetHeight, scrollTop, scrollHeight,
    } = event.target;
    if (scrollHeight - offsetHeight - scrollTop < 10
      || offsetHeight + scrollTop === scrollHeight) {
      this.loadRewards();
    }
  }

  useReward = (reward, apply = true) => {
    const { applyRewardRequest, language } = this.props;
    applyRewardRequest(
      reward.coupon.couponCode,
      'SCRATCH_CARD',
      apply,
      {
        type: 'sync',
        callback: (res) => {
          const discountValue = res.res.appliedCoupon?.campaign?.discountValue || '';
          const code = res.res.appliedCoupon?.code || '';
          this.setState({
            offerApplied: {
              type: 'reward',
              feedbackMsgTitle: `Saved ₹${discountValue}`,
              feedbackMsgDesc: `You got a ₹${discountValue} by using coupon code ${code}.`,
            },
          });
        },
      },
      {
        type: 'sync',
        callback: (data) => {
          this.setState({
            offerApplied: {
              type: 'reward',
              feedbackMsgTitle: 'Sorry, can’t apply Reward',
              feedbackMsgDesc: (data && data.errorMsg) || Constants.String.OOPS[language],
            },
            rewardError: (
              (data && data.errorMsg)
              || Constants.String.OOPS[language]
            ),
          });
        },
      },
    );
  }

  render() {
    const {
      rewards, processing, failure, language, isMobile,
      history, processingRewardApply,
      toggleSideOverlay,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const {
      rewardError, offerApplied,
    } = this.state;

    if (!rewards && processing && processingRewardApply) {
      return (
        <Row
          className="w-100 py-3 mx-0 mt-3 justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </Row>
      );
    }

    if (!rewards && failure) {
      return (
        <>
          <Placeholder
            language={language}
            handleRetry={() => this.loadRewards(0)}
            imageSrc="/images/error-placeholder.png"
            heading={Constants.String.OH_NO[language]}
          />
        </>
      );
    }

    return (
      <Container
        fluid
        onScroll={this.handleLazyLoad}
        className="d-flex flex-column h-100 bg-white"
      >
        {offerApplied && (
          <CustomModal
            show
            backdrop
            body={(
              <Container>
                <Row
                  className="bg-white py-6 px-4 flex-column r__action_container"
                >
                  <Col className="mb-4 r__action_title">
                    {(rewardError ? (
                      <Svg
                        svg="circleClose"
                        circleFill={Constants.Color.danger}
                        pathFill={Constants.Color.white}
                        width="2rem"
                      />
                    ) : (
                      <Svg
                        svg="graphicOffer"
                        pathFill={Constants.Color.green}
                        circleFill={Constants.Color.white}
                        width="48px"
                      />
                    ))}
                  </Col>
                  <Col
                    className="mb-4 r__action_title"
                  >
                    {offerApplied.feedbackMsgTitle}
                  </Col>
                  <Col
                    className="mb-4 r__action_copy"
                  >
                    {offerApplied.feedbackMsgDesc}
                  </Col>
                  <Col
                    className="d-flex justify-content-center"
                  >
                    <Button
                      variant="link"
                      className="r__action_confirm_one"
                      onClick={() => {
                        this.setState({
                          offerApplied: null,
                          rewardError: '',
                        });
                        if (!rewardError) {
                          if (!isMobile) {
                            toggleSideOverlay(Cart);
                            return;
                          }
                          history.push('/cart', { fromRewardCoupon: true });
                        }
                      }}
                    >
                      { rewardError ? 'OK' : Constants.String.YAY[language].toUpperCase()}
                    </Button>
                  </Col>
                </Row>
              </Container>
            )}
            onHide={() => {
              this.setState({
                offerApplied: null,
                rewardError: '',
              });
              if (!rewardError) {
                if (!isMobile) {
                  toggleSideOverlay(Cart);
                  return;
                }
                history.push('/checkout', { fromRewardCoupon: true });
              }
            }}
          />
        )}
        {!isMobile && (
          <>
            <Row>
              <Col
                xs={24}
                className="py-4 align-items-center bg-white d-flex"
              >
                <Button
                  variant="link"
                  onClick={() => {
                    if (isMobile) {
                      history.push(
                        '/offers/reward-coupon',
                        { applyOffer: true },
                      );
                      return;
                    }
                    toggleSideOverlay(() => (<RewardCoupon {...this.props} />), false);
                  }}
                  className="p-0"
                >
                  <Svg
                    svg="leftArrow"
                    fill={Constants.Color.black}
                    width="1rem"
                  />
                </Button>
                <div
                  className="pl-2 font-weight-black flex-grow-1"
                >
                  {Constants.String.SCRATCH_CARDS[language]}
                </div>
                <Col
                  xs="auto"
                  className="cursor-pointer px-0"
                  onClick={() => {
                    toggleSideOverlay(null);
                  }}
                >
                  <Svg
                    svg="close"
                    width="24px"
                    fill={Constants.Color.mediumLight}
                  />
                </Col>
              </Col>
            </Row>
            <Row
              className="py-1 bg-light"
            />
          </>
        )}
        <Row
          className="h-100 overflow-y-scroll"
        >
          {
            rewards
            && rewards.count === 0
              ? (
                <Placeholder
                  language={language}
                  imageSrc="/images/no-offers.png"
                  heading={Constants.String.NO_OFFERS_FOUND[language]}
                  subHeading={Constants.String.NO_OFFERS_SUBHEADING[language]}
                />
              ) : (
                <Col
                  className="px-2"
                >
                  {
                    rewards && rewards.data.map((item) => (
                      <Reward
                        key={item.id}
                        isOfferView
                        item={item}
                        language={language}
                        isMobile={isMobile}
                        applyReward={this.useReward}
                        screen={screens.REWARDS}
                      />
                    ))
                  }
                  {
                    processing && (
                      <Row
                        className="w-100 py-3 mx-0 mt-3 justify-content-center"
                      >
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </Row>
                    )
                  }
                  {
                    failure && (
                      <Placeholder
                        language={language}
                        handleRetry={() => this.loadRewards()}
                        heading={Constants.String.OH_NO[language]}
                      />
                    )
                  }
                  <div className="p-6 p-md-0" />
                </Col>
              )
          }
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  rewards: state.main.rewards,
  processing: state.main.requestsProcessing[ActionTypes.REWARD_LOAD_REQUEST],
  failure: state.main.requestsFailure[ActionTypes.REWARD_LOAD_REQUEST],
  processingRewardApply: state.main.requestsProcessing[ActionTypes.APPLY_REWARD_REQUEST],
});

const mapDispatchToProps = (dispatch) => ({
  rewardLoadRequest: (params, cancelToken, onSuccess, onFailure, nextUrl) => {
    dispatch(Actions.rewardLoadRequest(
      params, cancelToken, onSuccess, onFailure, nextUrl,
    ));
  },
  resetRequestStatus: (requests) => {
    dispatch(Actions.resetRequestStatus(requests));
  },
  applyRewardRequest: (
    couponCode,
    type,
    apply,
    onSuccess,
    onFailure,
  ) => {
    const { addRequest } = cartAddCouponRequest;
    const { deleteRequest } = cartCouponDeleteActions;
    const requestMethod = apply ? addRequest : deleteRequest;
    dispatch(requestMethod(
      onSuccess,
      onFailure,
      {
        couponCode,
        type,
      },
      {},
    ));
  },
});

RewardList.propTypes = {
  rewardLoadRequest: PropTypes.func.isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  rewards: PropTypes.shape({
    count: PropTypes.number,
    data: PropTypes.arrayOf({}),
  }),
  listProps: PropTypes.shape({
    applyOffer: PropTypes.bool,
    cartStoreId: PropTypes.number,
  }),
  processing: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  applyRewardRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      applyOffer: PropTypes.bool,
      cartStoreId: PropTypes.number,
    }),
  }).isRequired,
  processingRewardApply: PropTypes.bool.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
};

RewardList.defaultProps = {
  rewards: null,
  listProps: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardList);
