import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 16.5 11.8"
  >
    <g id="Back_Arrow" transform="translate(-20 -43)">
      <path
        id="Left_Arrow"
        fill={fill}
        d="M35.3,47.7H24l2.7-2.7c0.2-0.2,0.3-0.5,0.3-0.8c0-0.6-0.5-1.2-1.2-1.2c-0.3,0-0.6,0.1-0.8,0.3L20.4,48
       c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.4,0.9l4.7,4.7c0.2,0.2,0.5,0.3,0.8,0.3c0.6,0,1.2-0.5,1.2-1.2c0-0.3-0.1-0.6-0.3-0.8
       L24,50.1h11.3c0.7,0,1.2-0.5,1.2-1.1S36,47.7,35.3,47.7C35.4,47.7,35.3,47.7,35.3,47.7z"
      />
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
