import React from 'react';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';

function SpendOfferSummary({
  offer, onHide,
}) {
  const validDate = new Date(offer.validUntil || offer.valid_until);
  const isSpendOffer = offer.type === 'SPEND_OFFERS' || offer.type === 'SPEND_OFFER';

  return (
    <Container
      fluid
      style={{
        width: '360px',
        position: 'relative',
      }}
    >
      <Row
        className={`${isSpendOffer ? 'px-4 py-3' : 'pl-1 py-3 pr-4'}`}
        style={{
          background: 'url(\'/images/spend-offer-bg.png\') center',
          objectFit: 'contain',
          borderRadius: '16px',
        }}
      >
        <Col
          xs="auto"
          style={{
            position: 'absolute',
            right: '0px',
            top: '0px',
            zIndex: 1,
          }}
        >
          <Button
            variant="link"
            className="fs-2 text-black px-0"
            onClick={onHide}
          >
            &times;
          </Button>
        </Col>
        <Col
          xs={7}
          className={`px-2 ${isSpendOffer ? 'py-4' : 'py-2'}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '126px',
            width: '98px',
          }}
        >
          {
            isSpendOffer ? (
              <>
                <div
                  className="border border-radius-16 p-1"
                  style={{ position: 'relative', overflow: 'hidden' }}
                >
                  <img
                    className="mw-100 mh-100"
                    alt="hgj"
                    title="gfhjk"
                    src={offer.image}
                  />
                  <div className={`${true ? 'r__offer_card-label' : ''}`}>
                    {'Free'.toUpperCase()}
                  </div>
                </div>
              </>
            ) : (
              <div className={`r__offer_card-image`}>
                {offer.offerAmount}
              </div>
            )
          }
        </Col>
        <Col
          xs={17}
          className="p-4"
        >
          <Row className="pr-4 fs-1 font-weight-black text-green-dark">
            {offer.description || offer.name}
          </Row>
          <Row className="mt-3 mb-4">
            This offer will be added automatically during check out
          </Row>
          <Row className="font-weight-bold fs-6 text-green-dark">
            {'Expires on'.toUpperCase()}
            &nbsp;
            {validDate.getDate()}
            &nbsp;
            {(validDate.toLocaleString('default', { month: 'long' })).toUpperCase()}
            &nbsp;
            {validDate.toLocaleString('default', { year: 'numeric' })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SpendOfferSummary;
