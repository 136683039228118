import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import CustomRoundBadge from 'app/components/common/CustomRoundBadge';
import { logCombinedOfferAdd } from 'clevertap/LogEvent';
import RoundedCartCounter from '../../components/derived/cart/RoundedCartCounter';
import CustomImage from '../../components/common/CustomImage';
import { getCDNImageUrl, getSEOFriendlyProductName, getSeoProductUrl } from '../../utilities/Utils';

const CartAvailableOffer = (props) => {
  const {
    item, cartItems, showStoreName, language, updateCartOffers,
    history, toggleSideOverlay, section,
  } = props;

  let quantity = 0;
  const cartProduct = cartItems && cartItems[item.retailerProductId];
  if (cartProduct && cartProduct.storeId === item.storeId) {
    quantity = cartProduct.quantity;
  }

  const handleProductScreen = () => {
    toggleSideOverlay(null);
    if (item.offerType === 'BOGO') {
      history.push({
        pathname: `/products/${getSEOFriendlyProductName(item.name)}-${item.bogoOfferId}${item.storeId ? `?sid=${item.storeId}` : ''}`,
        state: {
          showOfferDetail: true,
          scrollToTop: true,
        },
      });
    } else {
      const { pathName, state, searchParams } = getSeoProductUrl(item.id, item.name, item.storeId);
      history.push({
        pathname: pathName,
        state,
        search: searchParams,
      });
    }
  };

  return (
    <Col
      xs="auto"
      id="cart-applicable-offer"
      className="mr-2 p-0 text-wrap d-flex"
      key={item.id}
    >
      <Row
        className="mx-0 p-4 border shadow-sm border-radius-16 mx-auto bg-white cart-offer-content-section"
      >
        <Col
          xs={24}
          className="p-0 offer-product-image-box cursor-pointer d-flex mb-2"
          role="button"
          onClick={handleProductScreen}
        >
          {
            !!item.displayTag && (
              <div
                className="top-saving-offer-tag-badge"
              >
                <CustomRoundBadge
                  badgeText={item.displayTag}
                  isRibbon
                  badgeType="ROUND_OFFER"
                />
              </div>
            )
          }
          {
            (item.offerType === 'BOGO') ? (
              <>
                <div
                  className="bogo-offer-product-image"
                >
                  <CustomImage
                    imageStyle="mw-100 mh-100"
                    imageUrl={getCDNImageUrl(item.primaryImage)}
                    imageType="OFFER"
                    imageAlt={item.name}
                  />
                </div>
                <div
                  className="bogo-offer-product-image"
                >
                  <CustomImage
                    imageStyle="mw-100 mh-100"
                    imageUrl={getCDNImageUrl(item.secondaryImage)}
                    imageType="OFFER"
                    imageAlt={item.name}
                  />
                </div>
              </>
            ) : (
              <div
                className="discount-offer-product-image"
              >
                <CustomImage
                  imageStyle="mw-100 mh-100"
                  imageUrl={getCDNImageUrl(item.primaryImage)}
                  imageType="OFFER"
                  imageAlt={item.name}
                />
              </div>
            )
          }
        </Col>
        <Col
          className="px-0"
        >
          {
            showStoreName && (
              <div
                className="mb-1 fs-6 text-black-50"
              >
                {`${(item.storeName || '')}`}
              </div>
            )
          }
          <div
            className="fs-5 font-weight-bold text-black mb-2 text-truncate-2 word-break"
            title={item.name}
          >
            {item.name}
          </div>
          <Row
            className="fs-5 mb-2 mx-0 align-items-center text-truncate-1 word-break"
          >
            {
              `${item.primaryUnit}${item.secondaryUnit ? ` + ${item.secondaryUnit}` : ''}`
            }
          </Row>
          <Row
            className="mx-0 align-items-center justify-content-center"
          >
            <Col
              className="pl-0 pr-1"
            >
              <Row
                className="mx-0 align-items-center flex-column"
              >
                <Col
                  xs={24}
                  className="p-0"
                >
                  <span
                    className="fs-5 font-weight-black"
                  >
                    {`\u20B9${Math.ceil(item.sellingPrice)}`}
                  </span>
                </Col>
                {
                  !!(
                    item.mrp !== -1
                    && item.mrp > item.sellingPrice
                  ) && (
                  <Col
                    xs={24}
                    className="p-0"
                  >
                    <span
                      className="fs-5 text-strike-through"
                    >
                      <del>
                        {`\u20B9${Math.ceil(item.mrp)}`}
                      </del>
                    </span>
                  </Col>
                  )
                }
              </Row>
            </Col>
            <Col
              xs="auto"
              className="p-0"
            >
              <RoundedCartCounter
                language={language}
                productId={item.retailerProductId}
                productInfo={{
                  storeName: item.storeName,
                  productName: item.name,
                  sellingPrice: item.sellingPrice,
                  mrp: item.mrp,
                  imageUrl: item.primaryImage,
                  measurementDisplayText: `${item.primaryUnit}${item.secondaryUnit ? ` + ${item.secondaryUnit}` : ''}`,
                }}
                storeCode={item.storeId}
                quantity={quantity}
                onSuccess={() => {
                  if (updateCartOffers) {
                    updateCartOffers();
                  }
                  logCombinedOfferAdd(
                    {
                      Page: 'Cart',
                    },
                  );
                }}
                addButtonStyle={{ height: '40px' }}
                section={section}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

CartAvailableOffer.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    bogoOfferId: PropTypes.number,
    retailerProductId: PropTypes.number,
    primaryImage: PropTypes.string,
    secondaryImage: PropTypes.string,
    name: PropTypes.string,
    storeName: PropTypes.number,
    mrp: PropTypes.number,
    sellingPrice: PropTypes.number,
    storeId: PropTypes.number,
    offerType: PropTypes.string,
    displayTag: PropTypes.string,
    primaryUnit: PropTypes.string,
    secondaryUnit: PropTypes.string,
  }).isRequired,
  showStoreName: PropTypes.bool.isRequired,
  cartItems: PropTypes.shape({}),
  language: PropTypes.string,
  updateCartOffers: PropTypes.func,
  toggleSideOverlay: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  section: PropTypes.string,
};

CartAvailableOffer.defaultProps = {
  cartItems: null,
  language: 'en',
  updateCartOffers: null,
  section: '',
};

export default CartAvailableOffer;
