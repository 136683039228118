import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth="1px"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 9.3L12 3l8.25 6.3v9.9c0 .994-.82 1.8-1.833 1.8H5.583c-1.012 0-1.833-.806-1.833-1.8V9.3zM9.25 21v-9h5.5v9"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
