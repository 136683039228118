import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width, stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 48 48"
  >
    <defs>
      <filter
        id="prefix__a"
        width="183.3%"
        height="183.3%"
        x="-41.7%"
        y="-41.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      filter="url(#prefix__a)"
    >
      <circle
        cx="24"
        cy="24"
        r="18"
        fill={fill}
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M27 18l-6 6m6 6l-6-6"
      />
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
};

export default Svg;
