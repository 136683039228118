import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, fill1, fill2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 120 120"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <g>
        <g>
          <g>
            <g
              fill={fill1}
              transform="translate(-120 -240) translate(60 240) translate(60)"
            >
              <circle cx="60" cy="60" r="60" />
            </g>
            <g
              fill={fill2}
            >
              <path
                d="M56.25 56.25c12.426 0 22.5 10.074 22.5 22.5h-67.5c0-12.426 10.074-22.5 22.5-22.5zM45 11.25c9.32 0 16.875 7.555 16.875 16.875S54.32 45 45 45s-16.875-7.555-16.875-16.875S35.68 11.25 45 11.25z"
                transform="translate(-120 -240) translate(60 240) translate(60) translate(15 15)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill1: PropTypes.string.isRequired,
  fill2: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
