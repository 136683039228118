import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CustomModal } from 'app/components/common';
import { logPageLoad } from 'app/events/Events';
import { getGeoAddress, orderLocation } from 'api/api';
import { dummyAddressPayload } from 'app/utilities/Config';
import { isGuestUser } from 'app/utilities/Utils';
import { cartLoadActions } from 'app/store/cart/actions';
import ViewAddress from './ViewAddress';
import AddOrEditAddress from './AddOrEditAddress';
import { LocationActionAlert } from '../account';
import * as Actions from '../../store/Actions';
import * as screens from '../../events/screens';
import { fbLogPageView } from '../../../facebook/Facebook';
import Modal from '../modal';
import ChangeLocation from '../account/ChangeLocation';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';

const ManageAddress = (props) => {
  const {
    language, history, toggleManageLocation, isMobile, addressEditRequest,
    updateSelectedAddress, page,
  } = props;
  const dispatch = useDispatch();
  const openManageLocation = useSelector((state) => state.main.openManageLocation);
  const cartId = useSelector((state) => state.main.cartId);
  const stateSelectedAddress = useSelector((state) => state.main.selectedAddress);
  const [view, setView] = useState('address');
  const [actionOnAlert, setActionOnAlert] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isLocationSearched, setIsLocationSearched] = useState(false);
  const [showMoreAddress, setShowMoreAddress] = useState(false);
  const [changeLocationAlert, setChangeLocationAlert] = useState(null);

  useEffect(() => {
    fbLogPageView();
    logPageLoad(
      {
        SCREEN: screens.MANAGE_LOCATION,
      },
    );
  }, []);

  const setDetails = (address, actionAlert, isLocationSearch = false) => {
    setSelectedAddress(address || null);
    setActionOnAlert(actionAlert || null);
    setIsLocationSearched(isLocationSearch);
  };

  const reset = () => {
    setView('address');
    setSelectedAddress(null);
    setActionOnAlert(null);
    setIsLocationSearched(false);
    setShowMoreAddress(false);
    setChangeLocationAlert(null);
  };

  const onSettingLocation = () => {
    if (page === 'product-details') {
      // remove query params and render rest
      window.location.replace(window.location.pathname);
    } else {
      window.location.reload();
    }
  };

  const handleOnConfirm = (add) => {
    const dispatchAction = !isLocationSearched ? Actions.addAddress : Actions.addressChangeRequest;
    dispatch(dispatchAction(
      add,
      {
        type: 'async',
        callback: (data, addressChangeSuccess) => {
          if (!isGuestUser()) {
            dispatch(Actions.recentAddressLoadRequest(null, null));
            reset();
          }
          addressChangeSuccess();
          dispatch(cartLoadActions.loadRequest());
          setChangeLocationAlert(null);
          setDetails(null, null, false);
          LogClevertapEvent.appLaunchedOrAddressChanged(data.res);
          LogClevertapEvent.locationSwitch(
            data.res.addressText,
          );
          dispatch(Actions.toggleManageLocation());
          onSettingLocation();
        },
      },
    ));
  };

  const handleAction = (address, actionAlert, isLocationSearch = false) => {
    if (actionAlert === 'no-alert') {
      setIsLocationSearched(isLocationSearch);
      setSelectedAddress(address || null);
      handleOnConfirm(address);
    } else if (actionAlert === 'change' || actionAlert === 'set-default') {
      orderLocation(
        'PUT',
        address.location,
        cartId,
      ).then((res) => {
        if (res.data.allowed) {
          setSelectedAddress(address || null);
          setIsLocationSearched(isLocationSearch);
          handleOnConfirm(address);
        } else {
          setDetails(address, actionAlert, isLocationSearch);
        }
      }).catch(() => {
        setDetails(address, actionAlert, isLocationSearch);
      });
    } else {
      setDetails(address, actionAlert, isLocationSearch);
    }
  };

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleEditAddress = (address) => {
    addressEditRequest(
      address,
      {
        type: 'sync',
        callback: () => {
          handleAction();
          setView('address');
        },
      },
    );
  };

  const locateMe = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      getGeoAddress(`${latitude}, ${longitude}`)
        .then((res) => {
          const address = res.data.results[0].formatted_address;
          updateSelectedAddress({
            isCompleted: true,
            isDefault: true,
            flatNumber: '',
            buildingName: '',
            streetName: '',
            localityName: '',
            landmark: '',
            pinCode: '',
            addressType: 'Home',
            addressText: address,
            location: {
              lat: latitude,
              lng: longitude,
            },
          });
        })
        .catch(() => {
          updateSelectedAddress(dummyAddressPayload);
        })
        .finally(() => {
          onSettingLocation();
        });
    }, () => {
      updateSelectedAddress(dummyAddressPayload);
      onSettingLocation();
    });
  };

  const alertDialog = (
    <LocationActionAlert
      history={history}
      address={selectedAddress}
      action={actionOnAlert}
      isMobile={isMobile}
      language={language}
      notifyAction={handleAction}
      onCancel={isLocationSearched ? () => { setActionOnAlert(null); } : handleAction}
      isLocationSearch={isLocationSearched}
      reset={reset}
      page={page}
    />
  );

  const changeLocationDialog = (
    <ChangeLocation
      history={history}
      address={selectedAddress}
      action={changeLocationAlert}
      isMobile={isMobile}
      language={language}
      isLocationSearch={isLocationSearched}
      notifyAction={() => {
        setChangeLocationAlert(null);
        setDetails(null, null, false);
      }}
      onCancel={() => {
        setChangeLocationAlert(null);
        setDetails(null, null, false);
      }}
      reset={reset}
    />
  );

  const content = (
    <Row className="px-4 mx-0 bg-white">
      <Col xs={24}>
        <Row className="my-4">
          <Col xs={24}>
            {(() => {
              let showcase = null;
              if (view === 'address') {
                showcase = (
                  <ViewAddress
                    {...props}
                    handleChange={setView}
                    notifyAction={handleAction}
                    handleSelectAddress={handleSelectAddress}
                    showMoreAddresses={showMoreAddress}
                    toggleMoreOption={() => setShowMoreAddress(!showMoreAddress)}
                  />
                );
              } else if (view === 'add-address') {
                showcase = (
                  <ViewAddress
                    {...props}
                    handleChange={setView}
                    notifyAction={handleAction}
                    handleSelectAddress={handleSelectAddress}
                    showMoreAddresses={showMoreAddress}
                    toggleMoreOption={() => setShowMoreAddress(!showMoreAddress)}
                    isAddAddress
                  />
                );
              } else if (view === 'add-full-address') {
                showcase = (
                  <AddOrEditAddress
                    {...props}
                    language={language}
                    selectedAddress={selectedAddress}
                    notifyAction={handleAction}
                    handleEditAddress={handleEditAddress}
                  />
                );
              } else if (view === 'edit-address') {
                showcase = (
                  <AddOrEditAddress
                    {...props}
                    language={language}
                    selectedAddress={selectedAddress}
                    notifyAction={handleAction}
                    handleEditAddress={handleEditAddress}
                    isEditAddress
                  />
                );
              }
              return showcase;
            })()}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  if (actionOnAlert) {
    return (
      <CustomModal
        show={!!actionOnAlert}
        onHide={() => setSelectedAddress(null)}
        body={alertDialog}
        backdrop
      />
    );
  }

  if (changeLocationAlert) {
    return (
      <CustomModal
        show={!!changeLocationAlert}
        onHide={() => {
          setSelectedAddress(null);
          setChangeLocationAlert(null);
        }}
        body={changeLocationDialog}
        backdrop
      />
    );
  }

  return (
    <Modal
      show={openManageLocation}
      onHide={() => {
        if (!selectedAddress && !stateSelectedAddress) {
          locateMe();
        }
        toggleManageLocation();
        reset();
      }}
      bodystyle={isMobile ? {
        top: '62px',
        zIndex: 1040,
        left: '2%',
        right: '2%',
        width: '96%',
      } : {}}
    >
      {content}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  selectedAddress: state.main.selectedAddress,
});

const mapDispatchToProps = (dispatch) => ({
  toggleManageLocation:
  () => {
    dispatch(Actions.toggleManageLocation());
  },
  addressEditRequest:
  (address, onSuccess) => {
    dispatch(Actions.addressEditRequest(address, onSuccess));
  },
  updateSelectedAddress: (address) => {
    dispatch(Actions.updateSelectedAddress(address));
  },
});

ManageAddress.propTypes = {
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  toggleManageLocation: PropTypes.func.isRequired,
  addressEditRequest: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  updateSelectedAddress: PropTypes.func.isRequired,
  selectedAddress: PropTypes.shape({}).isRequired,
  page: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAddress);
