import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 3h16c.552 0 1 .448 1 1v12.25c0 .552-.448 1-1 1h-8.75 0L6.75 21v-3.75H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1z"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
