import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <g id="Frames-24px">
      <rect fill="none" width={width} />
    </g>
    <g id="Outline">
      <g>
        <g>
          <path
            fill={fill}
            d="M12,8c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S13.654,8,12,8z M12,4c-0.551,0-1,0.449-1,1
               c0,0.551,0.449,1,1,1s1-0.449,1-1C13,4.449,12.551,4,12,4z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M14,22h-4c-0.552,0-1-0.448-1-1v-4H8c-0.552,0-1-0.448-1-1v-3c0-2.757,2.243-5,5-5s5,2.243,5,5v3
               c0,0.552-0.448,1-1,1h-1v4C15,21.552,14.552,22,14,22z M11,20h2v-4c0-0.552,0.448-1,1-1h1v-2c0-1.654-1.346-3-3-3s-3,1.346-3,3v2
               h1c0.552,0,1,0.448,1,1V20z"
          />
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
