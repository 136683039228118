import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 8.308 9.23"
  >
    <g
      id="Date_Of_Birth"
      data-name="Date Of Birth"
      transform="translate(-483 -1490)"
    >
      <path id="Path_4831" data-name="Path 4831" d="M490.846,1500h-7.385a.462.462,0,0,0-.462.462v3.692a.462.462,0,0,0,.462.462h7.385a.462.462,0,0,0,.462-.462v-3.692A.462.462,0,0,0,490.846,1500Zm-5.538.923H489v1.385h-3.692Zm5.077,2.769h-6.461v-2.769h.462v1.846a.462.462,0,0,0,.462.462h4.615a.462.462,0,0,0,.462-.462v-1.846h.462Z" transform="translate(0 -5.385)" fill={fill} />
      <rect id="Rectangle_2158" data-name="Rectangle 2158" width="0.923" height="1.385" transform="translate(486.692 1490)" fill={fill} />
      <rect id="Rectangle_2159" data-name="Rectangle 2159" width="0.923" height="1.305" transform="translate(483.135 1491.249) rotate(-45)" fill={fill} />
      <rect id="Rectangle_2160" data-name="Rectangle 2160" width="1.305" height="0.923" transform="translate(489.597 1491.52) rotate(-45.02)" fill={fill} />
      <path id="Path_4832" data-name="Path 4832" d="M484,1496.308h.923a.462.462,0,0,1,.462-.461.455.455,0,0,1,.3.115.463.463,0,0,0,.729-.176,1.381,1.381,0,0,1,2.561,0,.462.462,0,0,0,.728.176.457.457,0,0,1,.3-.115.462.462,0,0,1,.462.461h.923a1.381,1.381,0,0,0-1.8-1.319,2.306,2.306,0,0,0-3.785,0,1.381,1.381,0,0,0-1.8,1.319Z" transform="translate(-0.538 -2.154)" fill={fill} />
      <circle id="Ellipse_285" data-name="Ellipse 285" cx="0.462" cy="0.462" r="0.462" transform="translate(486.692 1495.769)" fill={fill} />
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
