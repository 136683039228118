import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Helmet } from 'react-helmet';
import { fbLogPageView } from 'facebook/Facebook';
import { gaLogPageView } from 'ecommerce_ga_events/EcommerceGAEvents';
import CouponCard from 'app/components/derived/offer/CouponCard';
import { cartAddCouponRequest, cartCouponDeleteActions } from 'app/store/cart/actions';
import offerApis from 'api/offer';
import {
  Placeholder, Svg, CustomModal,
} from '../../components/common';
import * as Actions from '../../store/Actions';
import * as ActionTypes from '../../store/ActionTypes';
import Constants from '../../utilities/Constants';
import { isAuthenticated } from '../../utilities/Utils';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as screens from '../../events/screens';
import RewardCoupon from './RewardCoupon';
import Cart from '../cart/Cart';

const { CancelToken } = Axios;

class CouponList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerApplied: null,
      couponError: '',
      coupons: null,
      processing: true,
      failure: false,
    };
    this.source = CancelToken.source();
  }

  componentDidMount() {
    if (isAuthenticated()) {
      LogClevertapEvent.couponOfferLandingPage();
    }
    this.loadCoupons();
    gaLogPageView();
    fbLogPageView();
  }

  componentDidUpdate() {
    const {
      failure, processing, coupons,
    } = this.state;
    if (!failure && !processing && !coupons) {
      this.loadCoupons();
    }
  }

  componentWillUnmount = () => {
    const { resetRequestStatus } = this.props;
    this.source.cancel();
    resetRequestStatus([
      ActionTypes.COUPONS_LOAD_REQUEST,
      ActionTypes.COUPON_CODE_REQUEST,
    ]);
  }

  loadCoupons = () => {
    const {
      cartDetails,
      cartId,
    } = this.props;
    this.setState({
      processing: true,
      failure: false,
    });
    offerApis.cartCoupons(cartDetails.store.id, cartId).then((res) => {
      const coupons = res.data;
      this.setState({
        coupons,
        processing: false,
      });
    }).catch(() => {
      this.setState({
        failure: true,
        processing: false,
      });
    });
  }

  handleCouponCode = (couponCode, apply = true) => {
    const { couponCodeRequest, language } = this.props;
    couponCodeRequest(
      couponCode,
      'NORMAL',
      apply,
      {
        type: 'sync',
        callback: (res) => {
          const discountValue = res.res.appliedCoupon?.campaign?.discountValue || '';
          const code = res.res.appliedCoupon?.code || '';
          this.setState({
            offerApplied: {
              type: 'coupon',
              feedbackMsgTitle: `Saved ₹${discountValue}`,
              feedbackMsgDesc: `You got a ₹${discountValue} by using coupon code ${code}.`,
            },
          });
        },
      },
      {
        type: 'sync',
        callback: (data) => {
          this.setState({
            offerApplied: {
              type: 'coupon',
              feedbackMsgTitle: 'Sorry, can’t apply Coupon',
              feedbackMsgDesc: (data && data.errorMsg) || Constants.String.OOPS[language],
            },
            couponError: (
              (data && data.errorMsg)
              || Constants.String.OOPS[language]
            ),
          });
        },
      },
    );
  }

  render() {
    const {
      language, isMobile,
      processingCouponCode, history, toggleSideOverlay,
      isApplyCoupon,
    } = this.props;
    const {
      couponError, offerApplied, coupons, processing,
      failure,
    } = this.state;

    const pageMeta = (
      <Helmet>
        <title>
          Coupon Offers | Great Discounts | Daily needs at best prices | LoveLocal
        </title>
        <link
          rel="canonical"
          href={`https://${window.location.host}/offers/coupons`}
        />
      </Helmet>
    );

    if (!coupons && processing && processingCouponCode) {
      return (
        <Row
          className="w-100 py-3 mx-0 mt-3 justify-content-center"
        >
          {pageMeta}
          <Spinner
            animation="border"
            variant="primary"
          />
        </Row>
      );
    }

    if (!coupons && failure) {
      return (
        <>
          {pageMeta}
          <Placeholder
            language={language}
            handleRetry={this.loadCoupons}
            imageSrc="/images/error-placeholder.png"
            heading={Constants.String.OH_NO[language]}
          />
        </>
      );
    }

    return (
      <Container
        fluid
        className="px-0 bg-white list-section d-flex flex-column h-100"
      >
        {pageMeta}
        {offerApplied && (
          <CustomModal
            show
            backdrop
            body={(
              <Container>
                <Row
                  className="bg-white py-6 px-4 flex-column r__action_container"
                >
                  <Col className="mb-4 r__action_title">
                    {(couponError ? (
                      <Svg
                        svg="circleClose"
                        circleFill={Constants.Color.danger}
                        pathFill={Constants.Color.white}
                        width="2rem"
                      />
                    ) : (
                      <Svg
                        svg="graphicOffer"
                        pathFill={Constants.Color.green}
                        circleFill={Constants.Color.white}
                        width="48px"
                      />
                    ))}
                  </Col>
                  <Col
                    className="mb-4 r__action_title"
                  >
                    {offerApplied.feedbackMsgTitle}
                  </Col>
                  <Col
                    className="mb-4 r__action_copy"
                  >
                    {offerApplied.feedbackMsgDesc}
                  </Col>
                  <Col
                    className="d-flex justify-content-center"
                  >
                    <Button
                      variant="link"
                      className="r__action_confirm_one"
                      onClick={() => {
                        this.setState({
                          offerApplied: null,
                          couponError: '',
                        });
                        if (!couponError) {
                          if (!isMobile) {
                            toggleSideOverlay(Cart);
                            return;
                          }
                          history.push('/cart', { fromRewardCoupon: true });
                        }
                      }}
                    >
                      { couponError ? 'OK' : Constants.String.YAY[language].toUpperCase()}
                    </Button>
                  </Col>
                </Row>
              </Container>
            )}
            onHide={() => {
              this.setState({
                offerApplied: null,
                couponError: '',
              });
              if (!couponError) {
                if (!isMobile) {
                  toggleSideOverlay(Cart);
                  return;
                }
                history.push('/checkout', { fromRewardCoupon: true });
              }
            }}
          />
        )}
        {!isMobile && (
          <>
            <Row className="mx-0">
              <Col xs={24} className="py-4 align-items-center bg-white d-flex">
                <Button
                  variant="link"
                  className="py-0 pl-0"
                  onClick={() => {
                    toggleSideOverlay(RewardCoupon, false);
                  }}
                >
                  <Svg
                    svg="leftArrow"
                    width="1rem"
                    fill={Constants.Color.black}
                  />
                </Button>
                <div
                  className="pl-2 font-weight-black flex-grow-1"
                >
                  Coupons
                </div>
                <Col
                  xs="auto"
                  className="cursor-pointer px-0"
                  onClick={() => {
                    toggleSideOverlay(null);
                  }}
                >
                  <Svg
                    svg="close"
                    width="24px"
                    fill={Constants.Color.mediumLight}
                  />
                </Col>
              </Col>
            </Row>
            <Row
              className="py-1 bg-light"
            />
          </>
        )}
        <Row
          className="mx-0 py-4 overflow-y-scroll h-100 d-flex flex-column align-items-center gap-10"
        >
          {
              coupons
              && coupons.count === 0
                ? (
                  <Placeholder
                    language={language}
                    imageSrc="/images/no-offers.png"
                    heading={Constants.String.NO_OFFERS_FOUND[language]}
                    subHeading={Constants.String.NO_OFFERS_SUBHEADING[language]}
                  />
                ) : (
                  <Col xs={24} className="d-flex flex-column gap-10 align-items-center">
                    {
                      coupons && !processing && !failure && (
                        (coupons.data || []).map((item) => (
                          <CouponCard
                            item={item}
                            key={item.id}
                            language={language}
                            isMobile={isMobile}
                            isApplyCoupon={!!isApplyCoupon}
                            applyCoupon={this.handleCouponCode}
                            screen={screens.HOME_SCREEN}
                          />
                        ))
                      )
                    }
                    {
                      processing && (
                        <Row
                          className="w-100 py-3 mx-0 mt-3 justify-content-center"
                        >
                          <Spinner
                            animation="border"
                            variant="primary"
                          />
                        </Row>
                      )
                    }
                    {
                      failure && (
                        <Placeholder
                          language={language}
                          handleRetry={this.loadCoupons}
                          heading={Constants.String.OH_NO[language]}
                        />
                      )
                    }
                    <div className="p-6 p-md-0" />
                  </Col>
                )
            }
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  cartDetails: state.main.cartDetails,
  cartId: state.main.cartId,
  coupons: state.main.coupons,
  processing: state.main.requestsProcessing[ActionTypes.COUPONS_LOAD_REQUEST],
  failure: state.main.requestsFailure[ActionTypes.COUPONS_LOAD_REQUEST],
  processingCouponCode: state.main.requestsProcessing[ActionTypes.COUPON_CODE_REQUEST],
});

const mapDispatchToProps = (dispatch) => ({
  couponsLoadRequest: (
    params, onSuccess, cancelToken,
  ) => {
    dispatch(
      Actions.couponsLoadRequest(
        params, onSuccess, cancelToken,
      ),
    );
  },
  resetRequestStatus: (requests) => {
    dispatch(Actions.resetRequestStatus(requests));
  },
  couponCodeRequest: (
    couponCode,
    type,
    apply,
    onSuccess,
    onFailure,
  ) => {
    const { addRequest } = cartAddCouponRequest;
    const { deleteRequest } = cartCouponDeleteActions;
    const requestMethod = apply ? addRequest : deleteRequest;
    dispatch(requestMethod(
      onSuccess,
      onFailure,
      {
        couponCode,
        type,
      },
      {},
    ));
  },
});

CouponList.propTypes = {
  resetRequestStatus: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  couponCodeRequest: PropTypes.func.isRequired,
  processingCouponCode: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  isApplyCoupon: PropTypes.bool,
  cartDetails: PropTypes.shape({
    store: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  cartId: PropTypes.number.isRequired,
};

CouponList.defaultProps = {
  isApplyCoupon: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponList);
