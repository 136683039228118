import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { Button, Row, Col } from 'react-bootstrap';
import { BottomCartBarClicked } from 'clevertap/LogEvent';
import Svg from './Svg';
import { Constants } from '../../utilities';
import '../../styles/cartBar/CartBar.scss';
import { logButtonClick } from '../../events/Events';
import * as buttonActions from '../../events/buttonActions';

function GuestUserCartBar(props) {
  const {
    language, cartItems, fade, onClick, guestCustomCartItems,
    page,
  } = props;
  let billAmount = 0;
  let productCount = 0;
  let totalSavings = 0;

  if (cartItems && Object.keys(cartItems).length) {
    Object.keys(cartItems).forEach((productId) => {
      productCount += cartItems[productId].quantity;
      if (cartItems[productId].productInfo) {
        billAmount += cartItems[productId].productInfo.sellingPrice * cartItems[productId].quantity;
        totalSavings += Math.round(
          cartItems[productId].productInfo.mrp - cartItems[productId].productInfo.sellingPrice,
        ) * cartItems[productId].quantity;
      }
    });
  }

  const guestCustomItemsCnt = Object.values(guestCustomCartItems || {})
    .reduce((acc, customProduct) => acc + customProduct.quantity, 0);
  productCount += guestCustomItemsCnt;

  return (
    <Button
      id="cart-bar"
      className={`h-56p bg-yellow-light border-0 text-black ${(productCount - guestCustomItemsCnt) && !fade
        ? '' : 'fade-cartBar'}`}
      onClick={(event) => {
        onClick(event);
        logButtonClick(
          { BUTTON: buttonActions.CART_CLICKED },
        );
        BottomCartBarClicked(
          { Page: page },
        );
      }}
    >
      <Row
        className="mx-0 align-items-center"
      >
        <Col
          xs="auto"
          className="px-2"
        >
          <Svg
            svg="cart"
            fill="none"
            stroke={Constants.Color.black}
            width="24px"
          />
        </Col>
        <Col
          className="p-0 fs-5"
        >
          <Row
            className="mx-0 align-items-center justify-content-between"
          >
            <Col
              xs="auto"
              className="px-2 text-left"
            >
              <div
                className="d-flex align-items-center"
              >
                <div
                  className="fs-5 font-weight-bold"
                >
                  {`${productCount} ${
                    productCount > 1
                      ? Constants.String.ITEMS[language]
                      : Constants.String.ITEM[language]
                  }  -  `}
                  &#8377;
                  {billAmount}
                </div>
                {
                  !!(totalSavings) && (
                    <div
                      className="ml-3 px-2 bg-green text-light border-radius-4 fs-6"
                    >
                      &#8377;
                      {totalSavings}
                      &nbsp;Saved
                    </div>
                  )
                }
              </div>
              <div
                className="font-weight-light text-medium font-weight-bold fs-6"
              >
                {Constants.String.PENDING_ITEMS_IN_CART[language]}
              </div>
            </Col>
            <Col
              xs="auto"
              className="pl-2 pr-0 fs-5 d-flex align-items-center text-primary font-weight-black"
            >
              <div
                className="px-3"
              >
                <div className="border-left divider h-100 py-5" />
              </div>
              {Constants.String.CART[language].toUpperCase()}
                &nbsp;
              <Svg
                svg="circleArrow"
                width="24px"
                circleFill={Constants.Color.primary}
                pathFill={Constants.Color.white}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Button>
  );
}

GuestUserCartBar.propTypes = {
  language: PropTypes.string.isRequired,
  cartItems: PropTypes.shape({
    productId: PropTypes.shape({
      productId: PropTypes.string.isRequired,
      storeId: PropTypes.number.isRequired,
      productInfo: PropTypes.shape({}).isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    productInfo: shape({}),
  }),
  guestCustomCartItems: PropTypes.shape({}),
  fade: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
};

GuestUserCartBar.defaultProps = {
  cartItems: null,
  guestCustomCartItems: null,
};

export default GuestUserCartBar;
