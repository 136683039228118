import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      transform: 'rotate(180deg)',
    }}
    viewBox="0 0 24 24.001"
  >
    <g id="Frames-24px">
      <rect fill="none" width={width} />
      <rect fill="none" width={width} />
    </g>
    <g id="Outline">
      <g>
        <path
          fill={fill}
          d="M12.001,2.002c-4.411,0-8,3.589-8,8c0,4.41,4.644,9.44,8,12.318c3.354-2.876,8-7.906,8-12.318
           C20.001,5.591,16.412,2.002,12.001,2.002z M12.001,19.656c-2.13-1.997-6-6.316-6-9.654c0-3.309,2.691-6,6-6s6,2.691,6,6
           C18.001,13.339,14.131,17.659,12.001,19.656z"
        />
        <polygon
          fill={fill}
          points="13.001,6.002 11.001,6.002 11.001,9.002 8.001,9.002 8.001,11.002 11.001,11.002 11.001,14.002
           13.001,14.002 13.001,11.002 16.001,11.002 16.001,9.002 13.001,9.002"
        />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
