import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl, OOBaseUrl } = apiUrls();

const orderApis = {

  createOrder: (data, params = {}) => {
    const orderDetails = `${newOOBaseUrl}order/create`;
    return Axios({
      url: orderDetails,
      method: 'POST',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cancelOrder: (orderId, data = {}) => {
    const cancelOrderPost = `${newOOBaseUrl}order/${orderId}/customer/cancel`;
    return Axios({
      url: cancelOrderPost,
      method: 'POST',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
    });
  },

  getCancellationReason: () => {
    const cancellationReasons = `${newOOBaseUrl}order/customer/cancel-reasons`;
    return Axios({
      url: cancellationReasons,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
    });
  },

  orderList: (params = {}, nextUrl = '', cancelToken) => {
    const order = `${newOOBaseUrl}${nextUrl || 'order/customer/all'}`;
    return Axios({
      url: order,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },

  orderDetails: (orderId, params = {}) => {
    const orderDetails = `${newOOBaseUrl}order/${orderId || ''}/customer/details`;
    return Axios({
      url: orderDetails,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  orderModificationAccept: (orderId, params = {}) => {
    const orderModificationPatch = `${newOOBaseUrl}order/${orderId || ''}/customer/modifications/accept`;
    return Axios({
      url: orderModificationPatch,
      method: 'POST',
      data: {},
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  orderModificationReject: (orderId, params = {}) => {
    const orderModificationPatch = `${newOOBaseUrl}order/${orderId || ''}/customer/modifications/reject`;
    return Axios({
      url: orderModificationPatch,
      method: 'POST',
      data: {},
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  orderPaymentCreation: (orderId, params = {}) => {
    const orderPaymentCreation = `${newOOBaseUrl}payment/razorpay/customer/create`;
    return Axios({
      url: orderPaymentCreation,
      method: 'POST',
      data: {
        orderId,
      },
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  orderPaymentVerification: (razorpayOrderId = '') => {
    const orderPaymentVerification = `${newOOBaseUrl}payment/razorpay/customer/success`;
    return Axios({
      url: orderPaymentVerification,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams({
        razorpayOrderId,
      }),
    });
  },

  activeOrder: (method = 'GET', customerId = '') => {
    const activeOrder = `${OOBaseUrl}homedelivery/v1/activeorderofcustomerbycategory/${customerId || ''}`;
    return Axios({
      url: activeOrder,
      method,
      headers: setHeaders(),
    });
  },
};

export default orderApis;
