import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 16 16"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={stroke}>
        <g>
          <g>
            <g>
              <g>
                <path d="M8 14c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6c0 .765.293 1.808.88 3.13L2 14l2.885-.87C6.201 13.71 7.24 14 8 14zm3-4.508v.903c0 .17-.07.332-.196.447-.125.114-.293.17-.462.156-.928-.101-1.82-.418-2.603-.925-.73-.462-1.347-1.079-1.81-1.806-.51-.786-.828-1.68-.927-2.61-.015-.169.042-.336.156-.46.114-.126.275-.197.445-.197h.905c.303-.003.56.219.603.518.038.289.11.573.211.846.083.22.03.468-.135.635l-.384.383c.43.753 1.055 1.377 1.81 1.806l.384-.382c.167-.166.416-.219.636-.136.274.102.558.173.848.211.303.043.526.305.519.611z" transform="translate(-254 -232) translate(0 116) translate(16 92) translate(224 16) translate(14 8)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
