import React from 'react';
import PropTypes from 'prop-types';

const TickMark = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <circle cx={6} cy={6} r={6} fill="#fff" />
    <circle cx={6} cy={6} r={6} fill="#38B823" fillOpacity={0.6} />
    <circle cx={6} cy={6} r={5.5} stroke="#000" strokeOpacity={0.32} />
    <path stroke="#fff" strokeWidth={2} d="M3 6.046 5 8.25l4-4.5" />
  </svg>
);

TickMark.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default TickMark;
