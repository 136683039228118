import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, fillBackground, width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g
                fill={fillBackground}
                fillRule="nonzero"
                transform="translate(-16 -514) translate(0 184) translate(16 326) translate(0 4) translate(3 3)"
              >
                <circle cx="9" cy="9" r="9" />
              </g>
              <path
                fill={fill}
                d="M12 10.5c.414 0 .75.336.75.75v4.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75zm0-3c.414 0 .75.336.75.75S12.414 9 12 9s-.75-.336-.75-.75.336-.75.75-.75z"
                transform="translate(-16 -514) translate(0 184) translate(16 326) translate(0 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  fillBackground: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
