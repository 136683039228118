import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  fill1, width, fill2, height,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.333 1.333a1 1 0 0 0-1 1v5.253a1 1 0 0 0 .293.707l6.334 6.333a1 1 0 0 0 1.414 0l5.252-5.252a1 1 0 0 0 0-1.414L8.293 1.626a1 1 0 0 0-.707-.292H2.333zm2.5 4.9a1.4 1.4 0 1 0 0-2.8 1.4 1.4 0 0 0 0 2.8z"
      fill={fill1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.333 1.333a1 1 0 0 0-1 1v5.253a1 1 0 0 0 .293.707l6.334 6.333a1 1 0 0 0 1.414 0l5.252-5.252a1 1 0 0 0 0-1.414L8.293 1.626a1 1 0 0 0-.707-.292H2.333zm2.5 4.9a1.4 1.4 0 1 0 0-2.8 1.4 1.4 0 0 0 0 2.8z"
      fill={fill2}
    />
  </svg>
);

Svg.propTypes = {
  fill1: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  fill2: PropTypes.string.isRequired,
};

export default Svg;
