import String from './Constants';

const Errors = {
  addressType: {
    empty: String.String.ADDRESS_LABEL_IS_REQUIRED,
  },
  buildingName: {
    empty: String.String.BUILDING_NAME_IS_REQUIRED,
  },
  email: {
    invalid: String.String.PLEASE_ENTER_VALID_EMAIL_ID,
  },
  firstName: {
    empty: String.String.PLEASE_ENTER_FIRST_NAME,
  },
  lastName: {
    empty: String.String.NEED_LAST_NAME_TO_CONTINUE,
  },
  flatNumber: {
    empty: String.String.FLAT_NUMBER_IS_REQUIRED,
  },
  localityName: {
    empty: String.String.LOCALITY_NAME_IS_REQUIRED,
  },
  pinCode: {
    empty: String.String.PIN_CODE_IS_REQUIRED,
    length: String.String.PIN_CODE_SHOULD_CONTAIN_SIX_DIGITS,
    valid: String.String.PLEASE_ENTER_VALID_PIN_CODE,
  },
  otp: {
    empty: String.String.OTP_IS_REQUIRED,
    length: String.String.OTP_SHOULD_CONTAIN_FOUR_DIGITS,
    valid: String.String.PLEASE_ENTER_VALID_OTP,
  },
  phoneNumber: {
    empty: String.String.PHONE_NUMBER_IS_REQUIRED,
    length: String.String.MOBILE_NUMBER_SHOULD_CONTAIN_TEN_DIGITS,
    valid: String.String.PLEASE_ENTER_VALID_MOBILE_NUMBER,
  },
  streetName: {
    empty: String.String.STREET_NAME_IS_REQUIRED,
  },
  selectedAddressType: {
    empty: String.String.NEED_ADDRESS_TYPE,
  },
};

export default Errors;
