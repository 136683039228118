import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  fill, width, isReverse, height, isDown,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {
      (() => {
        if (isReverse) {
          return (
            <path d="M6 4 3 9h6L6 4z" fill={fill} />
          );
        }
        if (isDown) {
          return (
            <path d="M9 16V8L17 12L9 16Z" fill="#B8238E" stroke="#B8238E" strokeLinejoin="round" />
          );
        }
        return (
          <path d="M6 9 3 4h6L6 9z" fill={fill} />
        );
      })()
    }
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  isReverse: PropTypes.bool.isRequired,
  isDown: PropTypes.bool,
};

Svg.defaultProps = {
  isDown: false,
};

export default Svg;
