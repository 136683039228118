import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Axios from 'axios';
import { CustomModal, Svg } from 'app/components/common';
import { Constants } from 'app/utilities';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { isGuestUser } from 'app/utilities/Utils';
import { uploadImage } from 'api/api';
import cartApis from 'api/cart';
import { setGuestCustomCartItems } from 'app/store/cart/actions';
import CustomImage from 'app/components/common/CustomImage';
import { logCustomProductAdd } from 'clevertap/LogEvent';

const defaultProductPlaceHolder = require('app/components/common/images/default-custom-item-placeholder.png');

const { CancelToken } = Axios;

class CreateCustomProduct extends React.Component {
  constructor() {
    super();
    this.state = {
      productName: '',
      uomString: '',
      qty: 1,
      productImage: null,
      errorString: '',
      submitting: false,
      show: false,
    };
    this.source = CancelToken.source();
  }

  prepareForSubmit = () => {
    this.setState({
      errorString: '',
      submitting: true,
    });
  };

  addToGuestUserCart = (product) => {
    const { setUpdatedCustomCartItems, guestCustomCartItems } = this.props;
    setUpdatedCustomCartItems({
      ...guestCustomCartItems,
      [new Date().getTime()]: product,
    });
  }

  addCustomProductToCart = (imageUrl = '') => {
    const {
      productName, uomString, qty,
    } = this.state;
    const { cartDetails, onCloseOverlay } = this.props;
    const product = {
      productName,
      quantity: qty,
      imageUrl,
      unitString: uomString,
    };
    if (isGuestUser()) {
      logCustomProductAdd(
        {
          Page: 'Cart',
          Section: 'Custom product',
          IsGuestUser: 'true',
        },
      );
      this.addToGuestUserCart(product);
      this.setState({
        submitting: false,
        errorString: '',
      });
      this.toggleShow();
      onCloseOverlay();
    } else {
      cartApis.cartCustomProductAdd(
        cartDetails.cartId,
        product,
      ).then(() => {
        logCustomProductAdd(
          {
            Page: 'Cart',
            Section: 'Custom product',
            IsGuestUser: 'false',
          },
        );
        this.setState({
          submitting: false,
          productName: '',
          uomString: '',
          qty: 1,
          productImage: '',
        });
        this.toggleShow();
        onCloseOverlay();
      }).catch((err) => {
        let failureString = 'Oops! Something went wrong.';
        if (
          err.response
          && err.response.data
          && err.response.data.errors
          && err.response.data.errors[0].message
        ) {
          failureString = err.response.data.errors[0].message;
        }
        this.setState({
          submitting: false,
          errorString: failureString,
        });
      });
    }
  };

  createCustomProduct = () => {
    const {
      productImage,
    } = this.state;
    this.prepareForSubmit();
    if (productImage) {
      const formData = new FormData();
      formData.append('img', productImage);
      uploadImage(
        'POST',
        formData,
      ).then((imgInfo) => {
        this.addCustomProductToCart(imgInfo.data.url);
      }).catch(() => {
        this.setState({
          submitting: false,
          errorString: 'Error occured while uploading image.',
        });
      });
    } else {
      this.addCustomProductToCart();
    }
  };

  toggleShow = () => {
    this.setState((state) => ({
      show: !state.show,
    }));
  }

  render() {
    const {
      productName,
      uomString,
      qty,
      productImage,
      submitting,
      errorString,
      show,
    } = this.state;

    return (
      <>
        <CustomModal
          show={show}
          shadow
          backdrop={!submitting}
          onHide={this.toggleShow}
          body={(
            <Container className="p-4 bg-white border-radius-16">
              <Row className="m-0 border-bottom">
                <Col xs="auto" className="px-0 pb-4">
                  <div><b>Add Custom Item</b></div>
                  <div className="text-primary fs-5"><b>How it works?</b></div>
                </Col>
                <Col
                  xs={2}
                  className="ml-auto fs-3"
                >
                  <Button
                    variant="link"
                    className="p-0 text-black"
                    onClick={this.toggleShow}
                    disabled={submitting}
                  >
                    &times;
                  </Button>
                </Col>
              </Row>
              <Row className="fs-5">
                <Col xs={24}>
                  <Row className="py-2">
                    <Col xs={19}>
                      <div className="py-2">
                        Item Name
                      </div>
                      <input
                        type="text"
                        className="p-2 mb-2 w-100 border border-radius-16"
                        onChange={(e) => { this.setState({ productName: e.target.value }); }}
                        value={productName}
                        placeholder="Type the item name"
                      />
                      <div className="py-2">
                        Unit
                      </div>
                      <input
                        type="text"
                        className="p-2 mb-2 w-100 border border-radius-16"
                        onChange={(e) => { this.setState({ uomString: e.target.value }); }}
                        value={uomString}
                        placeholder="Add kg / gms / ml / unit"
                        name="uom"
                      />
                    </Col>
                    <Col
                      xs={4}
                      className="border border-radius-2 my-2 px-0 bg-grey d-flex align-items-center justify-content-center"
                    >
                      <input
                        type="file"
                        onChange={(e) => {
                          this.setState({ productImage: e.target.files[0] });
                        }}
                        style={{
                          opacity: 0,
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          zIndex: 1005,
                        }}
                      />
                      <div style={{ zIndex: 1001 }}>
                        <Svg
                          svg="camera"
                          fill={Constants.Color.gray}
                          width="1.4rem"
                        />
                      </div>
                      <img
                        alt=""
                        src={
                      (productImage && window.URL.createObjectURL(productImage))
                      || defaultProductPlaceHolder
                    }
                        width="100%"
                        style={{ position: 'absolute', zIndex: 1000 }}
                      />
                    </Col>
                  </Row>
                </Col>
                {
                true && (
                  <Col xs={24}>
                    <div className="bg-yellow-light mb-4 text-center p-1 border-radius-16">
                      We&apos;ll share this with the shop to get the price
                    </div>
                  </Col>
                )
              }
                <Col xs={24}>
                  <Row className="mx-0">
                    <Col
                      xs={6}
                      className={`d-flex border border-radius-8 align-items-center justify-content-between px-0
                      ${!productName || !uomString ? 'text-medium' : ''}`}
                    >
                      <Button
                        onClick={() => {
                          this.setState({ qty: qty - 1 });
                        }}
                        variant="link"
                        disabled={!qty || !productName || !uomString}
                        className="py-0"
                      >
                        -
                      </Button>
                      {qty}
                      <Button
                        onClick={() => {
                          if (qty < 10) {
                            this.setState({ qty: qty + 1 });
                          }
                        }}
                        variant="link"
                        className="py-0"
                        disabled={!productName || !uomString}
                      >
                        +
                      </Button>
                    </Col>
                    <Col xs={18} className="pr-0">
                      <Button
                        className="border-radius-8 fs-5 w-100"
                        onClick={this.createCustomProduct}
                        disabled={
                        submitting
                        || (
                          !qty || !productName || !uomString
                        )
                      }
                      >
                        {
                        submitting && (
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="primary"
                            className="text-white mr-2"
                          />
                        )
                      }
                        ADD
                      </Button>
                    </Col>
                  </Row>
                </Col>
                {
                errorString && (
                  <Col xs={24}>
                    <div className="mt-2 text-center p-1 text-danger">
                      {errorString}
                    </div>
                  </Col>
                )
              }
              </Row>
            </Container>
        )}
        />
        <Button
          variant="link"
          onClick={() => { this.toggleShow(); }}
          className="w-100 p-0"
        >
          <Row id="cart-product" className="px-0 py-4 d-flex align-items-center">
            <Col xs="auto">
              <Row className="cart-product-image">
                <CustomImage
                  imageType="PRODUCT"
                  imageStyle="mh-100 mw-100"
                  imageUrl={defaultProductPlaceHolder}
                />
              </Row>
            </Col>
            <Col className="text-left">
              <div className="fs-5 text-black"><b>Cant find and item?</b></div>
              <div className="fs-6 text-secondary">Add custom item</div>
            </Col>
            <Col xs="auto px-0">
              <Svg
                svg="circleArrow"
                width="24px"
                circleFill={Constants.Color.primary}
                pathFill={Constants.Color.white}
              />
            </Col>
          </Row>
        </Button>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  guestCustomCartItems: state.main.guestCustomCartItems,
});

const mapDispatchToProps = (dispatch) => ({
  setUpdatedCustomCartItems: (customCartItems) => {
    dispatch(setGuestCustomCartItems(customCartItems));
  },
});

CreateCustomProduct.defaultProps = {
  cartDetails: null,
};
CreateCustomProduct.propTypes = {
  cartDetails: PropTypes.shape({
    cartId: PropTypes.number,
    store: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  onCloseOverlay: PropTypes.func.isRequired,
  guestCustomCartItems: PropTypes.shape({}).isRequired,
  setUpdatedCustomCartItems: PropTypes.func.isRequired,

};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomProduct);
