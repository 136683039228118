import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, strokeWidth, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        stroke={stroke}
      >
        <g>
          <g>
            <g>
              <path
                strokeWidth={strokeWidth}
                d="M19.416 15.69v2.25c.002.422-.175.826-.486 1.111-.311.286-.728.427-1.149.39-2.308-.252-4.525-1.04-6.472-2.303-1.813-1.152-3.349-2.688-4.5-4.5C5.54 10.68 4.752 8.453 4.506 6.135c-.038-.42.102-.835.386-1.146C5.176 4.678 5.578 4.5 6 4.5h2.25c.752-.007 1.394.545 1.5 1.29.095.72.27 1.427.525 2.108.206.548.074 1.166-.338 1.582l-.952.953c1.067 1.877 2.622 3.432 4.5 4.5l.952-.953c.416-.412 1.034-.543 1.583-.337.68.253 1.387.43 2.107.525.754.106 1.309.76 1.29 1.522z"
                transform="translate(-32 -352) translate(0 84) translate(32 228) translate(0 40)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  strokeWidth: PropTypes.string,
};

Svg.defaultProps = {
  strokeWidth: '',
};

export default Svg;
