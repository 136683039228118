import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

const BackDrop = (props) => {
  const { show, onClick, backgroundTransparent } = props;
  return (
    <Col
      className={`${show ? 'backdrop' : ''} ${backgroundTransparent ? 'bg-transparent' : ''}`}
      onClick={onClick}
    />
  );
};

BackDrop.propTypes = {
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  backgroundTransparent: PropTypes.bool,
};

BackDrop.defaultProps = {
  backgroundTransparent: false,
};

export default BackDrop;
