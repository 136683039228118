import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.56 12.44"
    style={{
      width,
    }}
  >
    <title>002</title>
    <g>
      <g>
        <path
          fill={fill}
          d="M17.45,0C15,0,13.07,1.27,13.07,2.83s-2,2.83-4.38,2.83S4.3,4.39,4.3,2.83,2.42,0,0,0C0,0,.06,1.23,0,2.76v9.68H17.56"
        />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
