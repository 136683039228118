const blogLinks = {
  grains_oil_and_masala: [
    {
      id: 1,
      linkName: 'Top five benefits of brown rice',
      url: 'https://www.lovelocal.in/blog/top-five-benefits-of-brown-rice/',
    },
    {
      id: 2,
      linkName: '10 types of millets for a healthy diet',
      url: 'https://www.lovelocal.in/blog/10-types-of-millets-for-a-healthy-diet/',
    },
    {
      id: 3,
      linkName: 'Five best cooking oils to live a healthy lifestyle',
      url: 'https://www.lovelocal.in/blog/five-best-cooking-oils-to-live-a-healthy-lifestyle/',
    },
  ],
  bakery_baking_and_eggs: [
    {
      id: 1,
      linkName: 'Quick and delicious egg less cake recipe',
      url: 'https://www.lovelocal.in/blog/quick-and-delicious-eggless-cake-recipe/',
    },
  ],
  groceries: [
    {
      id: 1,
      linkName: 'Top five benefits of brown rice',
      url: 'https://www.lovelocal.in/blog/top-five-benefits-of-brown-rice/',
    },
    {
      id: 2,
      linkName: 'Most loved coffee brands in India',
      url: 'https://www.lovelocal.in/blog/most-loved-coffee-brands-in-india/',
    },
    {
      id: 3,
      linkName: 'Benefits of Almond (Badam), Nutritional Facts and Side Effects',
      url: 'https://www.lovelocal.in/blog/benefits-of-almond-badam-nutritional-facts-and-side-effects/',
    },
  ],
  food_and_beverages: [
    {
      id: 1,
      linkName: 'Most loved coffee brands in india',
      url: 'https://www.lovelocal.in/blog/most-loved-coffee-brands-in-india/',
    },
    {
      id: 2,
      linkName: 'Benefits of Almond (Badam), Nutritional Facts and Side Effects',
      url: 'https://www.lovelocal.in/blog/benefits-of-almond-badam-nutritional-facts-and-side-effects/',
    },
    {
      id: 3,
      linkName: 'Benefits Of Muesli',
      url: 'https://www.lovelocal.in/blog/benefits-of-muesli/',
    },
  ],
  covid_essentials: [
    {
      id: 1,
      linkName: 'Best disinfectant liquids to sanitize and maintain hygiene for your home',
      url: 'https://www.lovelocal.in/blog/best-disinfectant-liquids-to-sanitize-and-maintain-hygiene-for-your-home/',
    },
  ],
  household_and_cleaning: [
    {
      id: 1,
      linkName: 'Best disinfectant liquids to sanitize and maintain hygiene for your home',
      url: 'https://www.lovelocal.in/blog/best-disinfectant-liquids-to-sanitize-and-maintain-hygiene-for-your-home/',
    },
  ],
  personal_care: [
    {
      id: 1,
      linkName: 'Health & Wellness',
      url: 'https://www.lovelocal.in/blog/category/healthwellness/',
    },
    {
      id: 2,
      linkName: 'Ten best face washes for glowing & fair skin for women',
      url: 'https://www.lovelocal.in/blog/ten-best-face-washes-for-glowing-and-fair-skin-for-women/',
    },
    {
      id: 3,
      linkName: 'Benefits of drinking hot honey lemon water',
      url: 'https://www.lovelocal.in/blog/benefits-of-drinking-hot-honey-lemon-water/',
    },
    {
      id: 4,
      linkName: 'Best weight gain foods to include in your diet',
      url: 'https://www.lovelocal.in/blog/best-weight-gain-foods-to-include-in-your-diet/',
    },
    {
      id: 5,
      linkName: 'Top five giloy juice brands in India to strengthen immunity naturally',
      url: 'https://www.lovelocal.in/blog/top-five-giloy-juice-brands-in-india-to-strengthen-immunity-naturally//',
    },
    {
      id: 6,
      linkName: 'Benefits Of Muesli',
      url: 'https://www.lovelocal.in/blog/benefits-of-muesli/',
    },
    {
      id: 7,
      linkName: 'List of Foods to Increase Blood Cell Count and Circulation',
      url: 'https://www.lovelocal.in/blog/list-of-foods-to-increase-blood-cell-count-and-circulation/',
    },
    {
      id: 8,
      linkName: 'Difference between Chia Seeds and Basil Seeds – All you need to know',
      url: 'https://www.lovelocal.in/blog/difference-between-chia-seeds-and-basil-seeds/',
    },
    {
      id: 9,
      linkName: 'Benefits of Almond (Badam), Nutritional Facts and Side Effects',
      url: 'https://www.lovelocal.in/blog/benefits-of-almond-badam-nutritional-facts-and-side-effects/',
    },
    {
      id: 10,
      linkName: 'Healthy Seeds: Different Types of Seeds with Health Benefits',
      url: 'https://www.lovelocal.in/blog/healthy-seeds-different-types-of-seeds-with-health-benefits/',
    },
    {
      id: 11,
      linkName: 'Rice Varieties: Twenty types of rice',
      url: 'https://www.lovelocal.in/blog/rice-varieties-twenty-types-of-rice/',
    },
  ],
};
const fetchBlogLinks = (categoryId) => (blogLinks[categoryId]);

export default fetchBlogLinks;
