import * as React from 'react';

const OnlinePaymentIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={4.9}
      y={7.9}
      width={14.2}
      height={10.2}
      rx={2.1}
      stroke="black"
      strokeWidth={1.8}
    />
    <path d="M5 11H19" stroke="black" strokeWidth={1.8} />
    <path
      d="M11.3554 12.6762C10.885 12.5676 10.3919 12.7091 10.0506 13.0504C9.70929 13.3917 9.56787 13.8848 9.67641 14.3551L10.7371 18.9513C10.8733 19.5415 11.3735 19.9771 11.9767 20.031C12.58 20.0848 13.1494 19.7447 13.388 19.188L13.6341 18.6138L16.061 21.0407C16.6078 21.5874 17.4942 21.5874 18.0409 21.0407C18.5877 20.494 18.5877 19.6075 18.0409 19.0608L15.614 16.6339L16.1882 16.3878C16.7449 16.1492 17.0851 15.5797 17.0312 14.9765C16.9774 14.3732 16.5417 13.873 15.9516 13.7368L11.3554 12.6762Z"
      fill="black"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OnlinePaymentIcon;
