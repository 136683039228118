import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  fill, stroke, width, strokeWidth,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <circle
        cx="9"
        cy="9"
        r="9"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3 3)"
        strokeWidth={strokeWidth || '1px'}
      />
      <circle
        cx=".75"
        cy=".75"
        r="1"
        fill={fill}
        transform="translate(11.25 15.75)"
      />
      <path
        stroke={stroke}
        strokeWidth={strokeWidth || '1px'}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.893 14.25c.017-1.708.174-2.224 1.14-2.904.584-.414 1.217-.928 1.217-1.923 0-1.16-.833-1.923-2.1-1.923-1.567 0-2.3 1.11-2.4 2.321"
      />
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  strokeWidth: PropTypes.string.isRequired,
};

export default Svg;
