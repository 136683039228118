import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Container, Row, Col,
} from 'react-bootstrap';

class CustomScroller extends React.Component {
  constructor() {
    super();
    this.state = {
      prev: false,
      next: false,
      target: null,
    };
  }

  componentDidMount = () => {
    const { contentPrefix } = this.props;
    const target = document.getElementById(contentPrefix);
    this.setState({ target }, () => {
      this.handlePrevNextVisibilty();
    });
  }

  handlePrevNextVisibilty = () => {
    const { target, prev, next } = this.state;
    let currentPrev = prev;
    let currentNext = next;
    const { scrollLeft, scrollWidth, offsetWidth } = target;
    if ((scrollLeft && !prev) || (!scrollLeft && prev)) {
      currentPrev = !prev;
    }
    if (((scrollWidth - (scrollLeft + offsetWidth)) > 30 && !next)
    || (!(scrollWidth - (scrollLeft + offsetWidth) > 30) && next)
    ) {
      currentNext = !next;
    }
    this.setState({
      prev: currentPrev, next: currentNext,
    });
  }

  handleTargetScroll = (factor) => {
    const { target } = this.state;
    const { scrollLeft, offsetWidth } = target;
    target.scrollLeft = scrollLeft + factor * offsetWidth;
  }

  render() {
    const { content, contentPrefix, noMargin } = this.props;
    const { prev, next } = this.state;
    return (
      <Container
        fluid
        className="px-0"
        id="custom-scroller"
      >
        <Row
          className={`mx-0 ${!noMargin ? 'mx-lg-2' : ''}`}
        >
          <div
            className={`px-0 ${prev ? '' : 'd-none'}`}
            id="prev-button-section"
          >
            <Button
              variant="link"
              className="p-0 d-flex align-items-center rotate-180"
              onClick={() => this.handleTargetScroll(-1)}
            >
              <img
                alt=""
                src="/images/oval.png"
                width="35px"
              />
            </Button>
          </div>
          <Col
            className="px-0 horizontal-scroll-list"
            id={contentPrefix}
            onScroll={this.handlePrevNextVisibilty}
          >
            {content}
          </Col>
          <div
            className={`px-0 ${next ? '' : 'd-none'}`}
            id="next-button-section"
          >
            <Button
              variant="link"
              className="p-0 d-flex align-items-center"
              onClick={() => this.handleTargetScroll(1)}
            >
              <img
                alt=""
                src="/images/oval.png"
                width="35px"
              />
            </Button>
          </div>
        </Row>
      </Container>
    );
  }
}

CustomScroller.defaultProps = {
  noMargin: false,
};

CustomScroller.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element]).isRequired,
  contentPrefix: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
};

export default CustomScroller;
