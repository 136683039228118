function getProfileProperties(user) {
  const data = {
    Identity: user.uid,
    Name: `${user.first_name} ${user.last_name}`,
    Phone: `+91${user.phone_number}`,
    Email: user.email,
    Gender: user.gender,
    DOB: user.dob,
    ProfileImage: user.profile_image,
  };
  Object.keys(data).forEach((item) => {
    if (!data[item]) {
      delete data[item];
    }
  });
  return data;
}

export function initClevertap() {
  window.clevertap = {
    event: [], profile: [], account: [], onUserLogin: [], notifications: [], privacy: [],
  };
  window.clevertap.account.push({
    id: process.env.REACT_APP_ENV === 'staging' ? 'TEST-965-KZ4-475Z' : '865-KZ4-475Z',
  });
  window.clevertap.privacy.push({ optOut: false });
  window.clevertap.privacy.push({ useIP: false });
  const wzrk = document.createElement('script');
  wzrk.type = 'text/javascript';
  wzrk.async = true;
  wzrk.src = `${document.location.protocol === 'https:' ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com'}/js/a.js`;
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wzrk, s);
}

export function pushEvent(event, data = {}) {
  const eventData = { ...data, source: 'WEB' };
  window.clevertap.event.push(event, eventData);
}

export function pushProfile(user) {
  window.clevertap.profile.push({
    Site: getProfileProperties(user),
  });
}

export function onUserLogin(user) {
  window.clevertap.onUserLogin.push({
    Site: getProfileProperties(user),
  });
}

export function pushClevertapEvent() {

}

export const checkNotificationPermission = () => {
  window.clevertap.notifications.push(
    {
      askAgainTimeInSeconds: process.env.REACT_APP_ENV === 'production' ? 43200 : 60,
      skipDialog: true,
    },
  );
};
