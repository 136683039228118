import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      d="M12 2.625c5.178 0 9.375 4.197 9.375 9.375S17.178 21.375 12 21.375 2.625 17.178 2.625 12 6.822 2.625 12 2.625zm0 .75c-4.763 0-8.625 3.862-8.625 8.625S7.237 20.625 12 20.625s8.625-3.862 8.625-8.625S16.763 3.375 12 3.375zM12 4.5c4.142 0 7.5 3.358 7.5 7.5 0 4.142-3.358 7.5-7.5 7.5-4.142 0-7.5-3.358-7.5-7.5 0-4.142 3.358-7.5 7.5-7.5zM12 9l-1.39 2.571L7.5 9.864 8.877 15h6.246L16.5 9.864l-3.11 1.707L12 9z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
