import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, stroke, width, circleStroke }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g fill={fill} fillRule="nonzero" stroke={circleStroke} transform="translate(-100 -212) translate(0 88) translate(32 88) translate(16 16) translate(52 20)">
                  <circle cx="8" cy="8" r="7" />
                </g>
                <g stroke={stroke} strokeLinecap="round" strokeLinejoin="round">
                  <path d="M3.176 6h5.648M6 3v6" transform="translate(-100 -212) translate(0 88) translate(32 88) translate(16 16) translate(52 20) translate(2 2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  circleStroke: PropTypes.string,
};

Svg.defaultProps = {
  circleStroke: '#ffffff',
};

export default Svg;
