import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Form,
} from 'react-bootstrap';
import OnlinePaymentIcon from 'app/components/common/svg/Cart/OnlinePaymentIcon';
import CashOnDeliveryIcon from 'app/components/common/svg/Cart/CashOnDeliveryIcon';
import CheckedIcon from 'app/components/common/svg/Cart/CheckedIcon';
import { Constants } from 'app/utilities';
// import { Svg } from 'app/components/common';
import { logCTCustomEvents } from 'app/events/Events';
import * as buttonActions from '../../events/buttonActions';
import * as screens from '../../events/screens';

const getApplicablePaymentModes = (paymentModes = {}) => {
  const { digital, cash } = paymentModes;
  if (digital.isEnabled && cash.isEnabled) {
    return 'BOTH';
  } if (digital.isEnabled) {
    return 'DIGITAL';
  }
  return 'CASH';
};

const ConfirmOrder = (props) => {
  const {
    cartDetails, paymentPreference,
    updateCheckoutStore,
    language,
    isPayOnlineOnDelivery,
  } = props;
  const checkoutStore = cartDetails.store;
  const isLastStoreItem = true;
  const storePaymentMode = getApplicablePaymentModes(
    checkoutStore.paymentModes,
  );

  const deliveryOption = cartDetails.deliveryPreference === 'STORE PICKUP' ? 1 : 0;

  useEffect(() => {
    if (
      storePaymentMode !== 'BOTH'
    ) {
      updateCheckoutStore(storePaymentMode);
    }
  }, [storePaymentMode, updateCheckoutStore]);

  const isDigitalPayment = paymentPreference === 'DIGITAL' && !isPayOnlineOnDelivery;
  const isCashPayment = paymentPreference === 'CASH';
  // const isPayOnlineLater = paymentPreference === 'DIGITAL' && isPayOnlineOnDelivery;

  return (
    <div
      className=""
    >
      <div
        key={checkoutStore.id}
      >
        <Row
          className=""
        >
          {
            (storePaymentMode === 'DIGITAL' || storePaymentMode === 'BOTH') && (
              <Col
                xs={24}
                className={`bg-white py-3 cursor-pointer mb-2 border-radius-8 
                shadow-sm ${
                  (isDigitalPayment)
                    ? 'bg-primary-light border border-gray' : ''
                }`}
                onClick={() => {
                  updateCheckoutStore('DIGITAL');
                  logCTCustomEvents(
                    buttonActions.SELECT_PAYMENT_METHOD,
                    {
                      EVENT_NAME: buttonActions.PAY_ONLINE,
                      SCREEN: screens.CHECKOUT,
                      CART_ID: cartDetails.cartId,
                      PAYABLE_AMOUNT: cartDetails.totalBill,
                    },
                  );
                }}
              >
                <Row className="d-flex flex-row-reverse">
                  <Col
                    xs="auto"
                    className="pl-0"
                  >
                    {
                      (isDigitalPayment) ? (
                        <CheckedIcon />
                      ) : (
                        <Form.Check
                          custom
                          label=""
                          type="radio"
                          id={`${checkoutStore.id}-DIGITAL`}
                          checked={isDigitalPayment}
                        />
                      )
                    }
                  </Col>
                  <Col
                    className="pr-0 fs-5 font-weight-bold text-black-72"
                  >
                    <Row>
                      <Col
                        xs={24}
                        className="d-flex align-items-center"
                      >
                        <OnlinePaymentIcon />
                        &nbsp;
                        {Constants.String.ONLINE_PAYMENT[language]}
                      </Col>
                    </Row>
                    {
                        cartDetails && cartDetails.maxDiscountForOnlinePaymentShort && (
                          <Row
                            className="mx-0"
                          >
                            <Col
                              xs={24}
                              className="my-1 py-1 px-2 fs-7 bg-green-light border-radius-4"
                            >
                              {cartDetails.maxDiscountForOnlinePaymentShort}
                            </Col>
                          </Row>
                        )
                      }
                    <Row
                      className="pt-2 pb-2"
                    >
                      <Col
                        xs={24}
                        className="payment-box"
                      >
                        {
                            [
                              { id: 1, src: '/Assets/payments/visa.png' },
                              { id: 2, src: '/Assets/payments/master-card.png' },
                              { id: 3, src: '/Assets/payments/paytm.png' },
                              { id: 4, src: '/Assets/payments/phone-pe.png' },
                              { id: 5, src: '/Assets/payments/gpay.png' },
                            ].map((item) => (
                              <div
                                key={item.id}
                              >
                                <img
                                  src={item.src}
                                  alt=""
                                  width="42px"
                                  height="24px"
                                />
                              </div>
                            ))
                          }
                      </Col>
                    </Row>
                    <Row
                      className="d-flex align-items-center"
                    >
                      <Col
                        xs={24}
                        className=""
                      >
                        <span
                          className="fs-7"
                        >
                          powered by
                        </span>
                          &nbsp;
                        <span>
                          <img
                            src="/Assets/payments/rozorpay.png"
                            alt=""
                            width="52px"
                            height="12px"
                          />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )
          }
          {
            (storePaymentMode === 'CASH' || storePaymentMode === 'BOTH') && (
              <Col
                xs={24}
                className={`bg-white py-3 cursor-pointer mb-2 border-radius-8 
                shadow-sm ${
                  (isCashPayment)
                    ? 'bg-primary-light border border-gray' : ''
                } ${
                  isLastStoreItem ? '' : 'dotted-border-bottom'
                }`}
                onClick={() => {
                  updateCheckoutStore('CASH');
                  logCTCustomEvents(
                    buttonActions.SELECT_PAYMENT_METHOD,
                    {
                      EVENT_NAME: buttonActions.PAY_CASH_ON_DELIVERY,
                      SCREEN: screens.CHECKOUT,
                      CART_ID: cartDetails.cartId,
                      PAYABLE_AMOUNT: cartDetails.totalBill,
                    },
                  );
                }}
              >
                <Row
                  className="d-flex flex-row-reverse"
                >
                  <Col
                    xs="auto"
                    className="pl-0"
                  >
                    {
                      isCashPayment ? (
                        <CheckedIcon />
                      ) : (
                        <Form.Check
                          custom
                          label=""
                          readOnly
                          type="radio"
                          id={`${checkoutStore.id}-CASH`}
                          checked={isCashPayment}
                        />
                      )
                    }
                  </Col>
                  <Col
                    className="pr-0 fs-5 font-weight-bold text-black-72"
                  >
                    <Row>
                      <Col
                        xs={24}
                        className="d-flex align-items-center"
                      >
                        <CashOnDeliveryIcon />
                        &nbsp;
                        {
                          deliveryOption === 1
                            ? Constants.String.PAY_AT_STORE[language]
                            : Constants.String.COD[language]
                        }
                      </Col>
                    </Row>
                    {
                        deliveryOption !== 1
                        && checkoutStore
                        && checkoutStore.productTransferModes.homeDelivery
                        && storePaymentMode === 'CASH'
                        && cartDetails.codDeliveryCharge > 0
                        && (
                          <Row
                            className="mx-0"
                          >
                            <Col
                              xs={24}
                              className="my-1 py-1 px-2 fs-7 bg-green-light border-radius-4"
                            >
                              {`\u20B9${Constants.String.COD_EXTRA_DELIVERY_CHARGE[language]
                                .replace(/{extraDeliveryCharge}/g, cartDetails.codDeliveryCharge)}`}
                            </Col>
                          </Row>
                        )
                      }
                  </Col>
                </Row>
              </Col>
            )
          }

          {/* {
            storePaymentMode === 'BOTH' && (
              <Col
                xs={24}
                className={`bg-white py-3 cursor-pointer mb-4 border-radius-8
                 shadow-sm ${
                  (isPayOnlineLater)
                    ? 'bg-primary-light border border-gray' : ''
                }`}
                onClick={() => {
                  updateCheckoutStore('PAY_ONLINE_LATER');
                  logCTCustomEvents(
                    buttonActions.SELECT_PAYMENT_METHOD,
                    {
                      EVENT_NAME: buttonActions.PAY_ONLINE_ON_DELIVERY,
                      SCREEN: screens.CHECKOUT,
                      CART_ID: cartDetails.cartId,
                      PAYABLE_AMOUNT: cartDetails.totalBill,
                    },
                  );
                }}
              >
                <Row
                  className="d-flex align-items-center"
                >
                  <Col>
                    <Row
                      className="align-items-center"
                    >
                      <Col
                        xs="auto"
                        className="pr-1"
                      >
                        <Svg
                          svg="paymentTimer"
                        />
                      </Col>

                      <Col
                        className="px-0 fs-5 font-weight-bold text-black-72"
                      >
                        {Constants.String.PAY_ONLINE_ON_DELIVERY[language]}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs="auto"
                    className="pl-0"
                  >
                    {
                      (isPayOnlineLater) ? (
                        <CheckedIcon />
                      ) : (
                        <Form.Check
                          custom
                          label=""
                          readOnly
                          type="radio"
                          id={`${checkoutStore.id}-PAY_ONLINE_LATER`}
                          checked={isPayOnlineLater}
                        />
                      )
                    }
                  </Col>
                </Row>
              </Col>
            )
          } */}
        </Row>
      </div>
    </div>
  );
};

ConfirmOrder.propTypes = {
  paymentPreference: PropTypes.string.isRequired,
  cartDetails: PropTypes.shape({
    cartId: PropTypes.number,
    totalBill: PropTypes.number,
    store: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      productTransferModes: PropTypes.shape({
        homeDelivery: PropTypes.bool,
        storePickup: PropTypes.bool,
      }),
      paymentModes: PropTypes.shape({}),
    }),
    codDeliveryCharge: PropTypes.number,
    maxDiscountForOnlinePaymentShort: PropTypes.string,
    deliveryPreference: PropTypes.string,
  }),
  updateCheckoutStore: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  isPayOnlineOnDelivery: PropTypes.number.isRequired,
};

ConfirmOrder.defaultProps = {
  cartDetails: null,
};

export default ConfirmOrder;
