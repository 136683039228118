import * as React from 'react';

const DiscountIcon = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.05064 16.0335L2.6042 15.2007L2.05064 16.0335C1.33696 15.5591 1.16507 14.5842 1.67346 13.8944L3.55932 11.3354L2.66243 8.28566C2.42064 7.46351 2.91559 6.60625 3.74847 6.40457L6.83803 5.65646L8.11128 2.74374C8.45453 1.95852 9.38472 1.61997 10.1524 2.00084L13 3.41368L15.8476 2.00084L16.2921 2.89664L15.8476 2.00084C16.6153 1.61997 17.5455 1.95852 17.8887 2.74374L19.162 5.65646L22.2515 6.40457C23.0844 6.60625 23.5794 7.46351 23.3376 8.28566L22.4407 11.3354L24.3265 13.8944C24.8349 14.5842 24.663 15.5591 23.9494 16.0335L21.302 17.7932L21.1017 20.9657C21.0478 21.821 20.2895 22.4572 19.4378 22.3619L16.2787 22.0082L14.086 24.3098C13.4949 24.9302 12.5051 24.9302 11.914 24.3098L9.72129 22.0082L6.56218 22.3619C5.71054 22.4572 4.95224 21.8209 4.89826 20.9657L4.69801 17.7932L2.05064 16.0335ZM7.07915 5.59807C7.07894 5.59812 7.07874 5.59817 7.07853 5.59822L7.07914 5.59807L7.19681 6.08403L7.07915 5.59807ZM18.9209 5.59807L18.9215 5.59824C18.9213 5.59818 18.9211 5.59813 18.9209 5.59807L18.8032 6.08403L18.9209 5.59807ZM21.0954 17.9305C21.0956 17.9303 21.0958 17.9302 21.0961 17.93L21.0954 17.9305L20.8186 17.5141L21.0954 17.9305Z"
      fill="url(#paint0_linear_1353_45975)"
      stroke="#018B27"
      strokeWidth={2}
    />
    <ellipse cx={10.5} cy={9.5} rx={1} ry={1.5} fill="#38B823" stroke="white" />
    <ellipse
      cx={15.5}
      cy={16.5}
      rx={1}
      ry={1.5}
      fill="#38B823"
      stroke="white"
    />
    <path d="M9.5 18L16.5 8" stroke="white" strokeLinecap="round" />
    <defs>
      <linearGradient
        id="paint0_linear_1353_45975"
        x1={13}
        y1={29.5}
        x2={13}
        y2={4.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007922" />
        <stop offset={1} stopColor="#00B633" />
      </linearGradient>
    </defs>
  </svg>
);
export default DiscountIcon;
