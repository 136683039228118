import PropTypes from 'prop-types';
import { CustomModal, Svg } from 'app/components/common';
import DiscountBreakup from 'app/layout/checkout/DiscountBreakup';
import { Constants } from 'app/utilities';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PlatformFeeBreakup from 'app/layout/checkout/PlatformFeeBreakup';

function BillDetails({
  language, cartDetails,
}) {
  const [discountDialog, setDiscountDialog] = useState(false);
  const [platformFeeDialog, setPlatformFeeDialog] = useState(false);
  const { deliveryPreference } = cartDetails || {};
  const toggleDiscountDialog = () => {
    setDiscountDialog(!discountDialog);
  };
  const togglePlatformFeeDialog = () => {
    setPlatformFeeDialog(!platformFeeDialog);
  };

  let deliveryOption = -1;
  if (deliveryPreference !== 'NOT DEFINED') {
    deliveryOption = deliveryPreference === 'STORE PICKUP' ? 1 : 0;
  }
  let couponDiscount = 0;
  let valueDiscount = 0;
  const appliedValueOffer = (
    cartDetails && cartDetails.cartOffers.length > 0
  ) ? cartDetails.cartOffers[0] : null;
  if (cartDetails.appliedCoupon) {
    couponDiscount = cartDetails.appliedCoupon.campaign.discountValue;
  }
  if (appliedValueOffer) {
    valueDiscount = appliedValueOffer.discountValue;
  }

  const freeSamples = cartDetails.cartProducts.filter((item) => item.freeSample);
  const cartSpendOffers = (cartDetails.cartOffers || []).filter((p) => p.offerType === 'SPEND_OFFER');
  let totalSavingsAmt = cartDetails.totalSavings;
  if (
    cartDetails
    && cartDetails.cartProductsCount > 0
    && cartDetails.homeDeliveryRemainingMOV === 0
    && (
      (cartDetails.deliveryPreference === 'HOME DELIVERY'
      && cartDetails.deliveryCharges
      && !cartDetails.deliveryCharges.deliveryAmount)
      || cartDetails.deliveryPreference === 'NOT DEFINED'
    )
  ) {
    totalSavingsAmt = cartDetails.totalSavings + cartDetails.deliveryFee;
  }
  if (
    cartDetails
    && cartDetails.platformFee
  ) {
    totalSavingsAmt += (
      Math.ceil(cartDetails?.platformFee?.intialValue - cartDetails?.platformFee?.finalValueAndGst)
    );
  }
  const freeProductCount = freeSamples.length + cartSpendOffers.length;

  return (
    <div className="mx-n4 bg-white">
      {
        discountDialog && (
        <CustomModal
          show
          body={(
            <DiscountBreakup
              language={language}
              couponDiscount={couponDiscount}
              valueDiscount={valueDiscount}
              toggleDialog={toggleDiscountDialog}
            />
        )}
          backdrop
          shadow
          onHide={toggleDiscountDialog}
        />
        )
      }
      {
        platformFeeDialog && (
          <CustomModal
            show
            body={(
              <PlatformFeeBreakup
                language={language}
                platformFeeDetails={cartDetails?.platformFee}
                toggleDialog={togglePlatformFeeDialog}
              />
          )}
            backdrop
            shadow
            onHide={togglePlatformFeeDialog}
          />
        )
      }
      <div className="p-4">
        <div className="fs-5 font-weight-black">
          Bill Details
        </div>
        <div className="py-4 font-weight-semi-black border-bottom fs-6">
          <div className="d-flex justify-content-between py-1">
            <span>
              Item Total (Incl. taxes)
            </span>
            <div>
              {
                cartDetails.totalMrp !== cartDetails.billBeforeDiscount && (
                  <span className="text-strike-through font-weight-normal">
                    {`₹${cartDetails.totalMrp}`}
                  </span>
                )
              }
                &nbsp;&nbsp;
              <span className="font-weight-black">
                {`₹${cartDetails.billBeforeDiscount}`}
              </span>
            </div>
          </div>
          {
            cartDetails.deliveryCharges
            && cartDetails.deliveryCharges.deliveryAmount
            && deliveryOption !== 1
            && deliveryOption !== -1
              ? (
                <div className="d-flex justify-content-between py-1">
                  <div className="py-1">
                    Delivery Fee
                  </div>
                  <div>
                    {`+ \u20B9 ${cartDetails.deliveryCharges.deliveryAmount}`}
                  </div>
                </div>
              ) : deliveryOption !== 1 && cartDetails.homeDeliveryRemainingMOV === 0 && (
                <div className="d-flex justify-content-between py-1">
                  <div className="py-1">
                    <span>
                      Delivery Fee
                    </span>
                  &nbsp;&nbsp;
                    <span className="text-green">
                      {`(₹${cartDetails.deliveryFee} saved)`}
                    </span>
                  </div>
                  <div>
                    <span className="text-strike-through font-weight-normal">
                      {`₹${cartDetails.deliveryFee}`}
                    </span>
                  &nbsp;&nbsp;
                    <span className="text-green font-weight-black">
                      FREE!
                    </span>
                  </div>
                </div>
              )
          }
          {
            !!cartDetails?.platformFee && !!cartDetails?.platformFee?.intialValue && (
              <div className="d-flex justify-content-between py-1">
                <div className="d-flex">
                  <span>
                    Platform Fee
                  </span>
                  &nbsp;&nbsp;
                  <span className="text-green">
                    {`(₹${cartDetails.platformFee.intialValue - cartDetails.platformFee.finalValueAndGst} saved)`}
                  </span>
                  &nbsp;
                  <Button
                    variant="link"
                    className="p-0 d-flex align-items-center"
                    onClick={togglePlatformFeeDialog}
                  >
                    <Svg
                      svg="info"
                      fill={Constants.Color.gray}
                      width="1rem"
                    />
                  </Button>
                </div>
                <div>
                  <span className="text-strike-through font-weight-normal">
                    {`₹${Math.ceil(cartDetails?.platformFee?.intialValue)}`}
                  </span>
                &nbsp;&nbsp;
                  <span className="text-black font-weight-black">
                    {`₹${Math.ceil(cartDetails?.platformFee?.finalValueAndGst)}`}
                  </span>
                </div>
              </div>
            )
          }
        </div>
        {
          (!!couponDiscount
          || !!valueDiscount
          || !!freeSamples.length
          || !!cartSpendOffers.length)
          && (
            <div className="py-4 font-weight-semi-black border-bottom fs-6">
              {
                (!!couponDiscount
                || !!valueDiscount) && (
                  <div className="d-flex justify-content-between py-1">
                    <div className="d-flex">
                      Discount
                      &nbsp;
                      <Button
                        variant="link"
                        className="p-0 d-flex align-items-center"
                        onClick={toggleDiscountDialog}
                      >
                        <Svg
                          svg="info"
                          fill={Constants.Color.gray}
                          width="1rem"
                        />
                      </Button>
                    </div>
                    <span className="text-green font-weight-black">
                      {`- ₹${cartDetails.totalDiscount}`}
                    </span>
                  </div>
                )
              }
              {
                [...freeSamples, ...cartSpendOffers.filter((p) => !!p.productInfo)].map((item) => (
                  <div className="d-flex justify-content-between py-1">
                    <div className="pr-4">
                      <span>
                        {item.freeSample ? item.name : item.productInfo.productName}
                      </span>
                      &nbsp;&nbsp;
                      {
                        !item.freeSample && (
                          <span className="text-green">
                            {`(worth ₹${item.productInfo.mrp})`}
                          </span>
                        )
                      }
                    </div>
                    <span className="text-green font-weight-black">
                      FREE!
                    </span>
                  </div>
                ))
              }
            </div>
          )
        }
        <div className="pt-4 d-flex justify-content-between font-weight-black">
          <span>
            Total Amount
          </span>
          <span>
            {`₹${cartDetails.totalBill}`}
          </span>
        </div>
      </div>
      {
          cartDetails && cartDetails.cartProductsCount > 0 && (
            !!totalSavingsAmt || !!freeSamples.length || !!cartSpendOffers.length
          ) && (
            <div className="text-center bg-green-dark font-weight-black p-1 text-white">
              {`${totalSavingsAmt ? `₹${totalSavingsAmt} Saved` : ''}
              ${freeSamples.length + cartSpendOffers.length
                ? ` ${totalSavingsAmt ? '+' : ''} ${freeProductCount} FREE Product${freeProductCount > 1 ? 's' : ''}` : ''}`}
            </div>
          )
        }
    </div>
  );
}

BillDetails.propTypes = {
  cartDetails: PropTypes.shape({
    appliedCoupon: PropTypes.shape({
      campaign: PropTypes.shape({
        discountValue: PropTypes.number,
      }),
    }),
    deliveryPreference: PropTypes.string,
    totalBill: PropTypes.number,
    billBeforeDiscount: PropTypes.number,
    cartProductsCount: PropTypes.number,
    cartProducts: PropTypes.arrayOf(PropTypes.shape({})),
    homeDeliveryRemainingMOV: PropTypes.number,
    deliveryCharges: PropTypes.shape({
      deliveryAmount: PropTypes.number,
    }),
    totalDiscount: PropTypes.number,
    totalMrp: PropTypes.number,
    totalSavings: PropTypes.number,
    cartOffers: PropTypes.arrayOf(PropTypes.shape({})),
    deliveryFee: PropTypes.number,
    platformFee: PropTypes.shape({
      intialValue: PropTypes.number,
      finalValueAndGst: PropTypes.number,
    }),
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default BillDetails;
