import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.5"
      y="4.5"
      width="15"
      height="15"
      rx="7.5"
      stroke="white"
    />
    <rect
      x="4.5"
      y="4.5"
      width="15"
      height="15"
      rx="7.5"
      stroke="black"
      strokeOpacity="0.4"
    />
  </svg>

);

Svg.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
