import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Constants } from '../../utilities';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as buttonActions from '../../events/buttonActions';
import * as screens from '../../events/screens';
import { logButtonClick } from '../../events/Events';
import { getCDNImageUrl, getSEOFriendlyURL, LOVELOCAL } from '../../utilities/Utils';

const CategoryFilters = (props) => {
  const {
    categories, categoryId, subCategoryId, language,
    storeCode, toggleSideOverlay, replaceHistory,
  } = props;
  return (
    <div
      className={`${
        toggleSideOverlay
          ? 'vh-100 bg-white' : 'h-100'
      } w-100`}
    >
      {
        toggleSideOverlay ? (
          <>
            <Row
              className="mx-0 py-6 border-bottom"
            >
              <Col
                xs="auto font-weight-bold"
              >
                {Constants.String.SHOP_BY_CATEGORY[language]}
              </Col>
            </Row>
          </>
        ) : (
          <div
            className="bg-primary text-white p-4 font-weight-bold"
          >
            {Constants.String.SHOP_BY_CATEGORY[language]}
          </div>
        )
      }
      <div
        className={`${
          toggleSideOverlay
            ? 'overlay-list' : 'filter-section'
        }`}
      >
        {
          categories && categories.map((category) => (
            <React.Fragment
              key={category.categoryId}
            >
              <Link
                to={{
                  pathname: `/pc/${
                    getSEOFriendlyURL(category.categoryId)
                  }${storeCode ? `?sid=${storeCode}` : ''}`,
                  state: {
                    scrollToTop: true,
                  },
                }}
                replace={replaceHistory}
              >
                <Button
                  variant="link"
                  className={`px-4 py-6 border-bottom text-left d-flex align-items-center mt-0 ${
                    categoryId === category.categoryId
                      ? 'text-primary bg-pink-pale' : 'text-black'
                  }`}
                  block
                  onClick={() => {
                    if (category.categoryId !== categoryId) {
                      LogClevertapEvent.categoryFilterChanged(
                        false,
                        category.displayName,
                        category.categoryId,
                      );
                      logButtonClick(
                        {
                          BUTTON: buttonActions.CATEGORY,
                          SCREEN: screens.CATEGORY_LANDING,
                          CATEGORY_ID: category.categoryId,
                          ...(
                            storeCode ? ({
                              STORE_ID: storeCode,
                            }) : {}
                          ),
                        },
                      );
                    }
                    if (toggleSideOverlay) {
                      toggleSideOverlay(null);
                    }
                  }}
                >
                  <img
                    className="w-40p border mr-4"
                    src={getCDNImageUrl(category.image) || LOVELOCAL.categoryPlaceholder}
                    alt={category.image ? category.displayName : ''}
                    title={category.image ? category.displayName : ''}
                  />
                  <span
                    className={
                      categoryId === category.categoryId
                        ? '' : 'font-weight-normal'
                    }
                  >
                    {category.displayName}
                  </span>
                </Button>
              </Link>
              {
                category.categoryId === categoryId && (
                  category.children.map((subCategory) => (
                    <Link
                      to={{
                        pathname: `/pc/${
                          getSEOFriendlyURL(category.categoryId)
                        }/${
                          getSEOFriendlyURL(subCategory.categoryId)
                        }${storeCode ? `?sid=${storeCode}` : ''}`,
                        state: {
                          scrollToTop: true,
                        },
                      }}
                      replace={replaceHistory}
                    >
                      <Button
                        key={subCategory.categoryId}
                        variant="link"
                        className={`pl-4 pr-0 py-0 text-left mt-0 ${
                          subCategoryId === subCategory.categoryId
                            ? 'text-primary' : 'text-black'
                        }`}
                        block
                        onClick={() => {
                          if (subCategory.categoryId !== subCategoryId) {
                            LogClevertapEvent.categoryFilterChanged(
                              true,
                              subCategory.displayName,
                              subCategory.categoryId,
                            );
                            logButtonClick(
                              {
                                BUTTON: buttonActions.SUB_CATEGORY,
                                SCREEN: screens.CATEGORY_LANDING,
                                CATEGORY_ID: category.categoryId,
                                SUB_CATEGORY_ID: subCategory.categoryId,
                                ...(
                                  storeCode ? ({
                                    STORE_ID: storeCode,
                                  }) : {}
                                ),
                              },
                            );
                          }
                          if (toggleSideOverlay) {
                            toggleSideOverlay(null);
                          }
                        }}
                      >
                        <div
                          className="d-flex py-4 align-items-center border-bottom"
                        >
                          <span className="w-40p mr-4" />
                          <span
                            className={
                              subCategoryId === subCategory.categoryId
                                ? '' : 'font-weight-normal'
                            }
                          >
                            {subCategory.displayName}
                          </span>
                        </div>
                      </Button>
                    </Link>
                  ))
                )
              }
            </React.Fragment>
          ))
        }
      </div>
    </div>
  );
};

CategoryFilters.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  toggleSideOverlay: PropTypes.func,
  categoryId: PropTypes.string,
  subCategoryId: PropTypes.string,
  storeCode: PropTypes.string,
  language: PropTypes.string.isRequired,
  replaceHistory: PropTypes.bool,
};

CategoryFilters.defaultProps = {
  categories: null,
  categoryId: null,
  subCategoryId: null,
  storeCode: null,
  toggleSideOverlay: null,
  replaceHistory: true,
};

export default CategoryFilters;
