import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SellingStore from './SellingStore';
import { Utils } from '../../../utilities';
import { errorImageFallback, getCDNImageUrl, getSeoProductUrl } from '../../../utilities/Utils';

function OnlineProduct(props) {
  const {
    item, cartItems, pastProduct, language, isCoachmark,
    isMobile, toggleSideOverlay, history,
  } = props;

  const sellingStores = pastProduct
    ? item.sellingStores.slice(0, 1) : item.sellingStores;

  const storeId = sellingStores[0].store.id;

  const { pathName, state, searchParams } = getSeoProductUrl(item.id, item.displayName, storeId);

  return (
    <Col
      id="product-offer"
      xs={24}
      lg={pastProduct ? 12 : 24}
      className="p-2"
    >
      <div
        className={`p-3 bg-white h-100 ${isCoachmark
          ? 'pointer-event-none' : ''}`}
      >
        <Row
          className="mx-0"
        >
          <Col
            xs={6}
            as={item.curated ? Link : 'div'}
            to={{ pathname: pathName, state, search: searchParams }}
            className={`product-image-div p-1 ${
              item.curated ? '' : 'cursor-pointer'} `}
            onClick={() => {
              if (!item.curated) {
                history.push({
                  pathname: pathName,
                  state,
                  search: searchParams,
                });
              }
            }}
          >
            <img
              alt={item.image ? item.displayName : ''}
              title={item.image ? item.displayName : ''}
              src={item.image !== '' ? getCDNImageUrl(item.image) : Utils.LOVELOCAL.productPlaceholder}
              className="mh-100 mw-100"
              onError={(event) => {
                errorImageFallback(event, Utils.LOVELOCAL.productPlaceholder);
              }}
            />
          </Col>
          <Col
            className="px-1"
          >
            <Row>
              <Col
                className="pb-1 text-black"
                as={item.curated ? Link : 'div'}
                to={{ pathname: pathName, state }}
              >
                {item.displayName}
              </Col>
            </Row>
            <div>
              {
                sellingStores
                  .map((sellingStore, index1) => (
                    <div>
                      <SellingStore
                        key={sellingStore.store.id}
                        index={index1}
                        product={item}
                        sellingStore={sellingStore}
                        cartItems={cartItems}
                        language={language}
                        isMobile={isMobile}
                        toggleSideOverlay={toggleSideOverlay}
                      />
                      {index1 < sellingStores.length - 1
                      && (
                        <div
                          className="my-3 dotted-border-top"
                        />
                      )}
                    </div>
                  ))
              }
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

OnlineProduct.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
    displayName: PropTypes.string,
    description: PropTypes.string,
    productDetailDescription: PropTypes.string,
    sellingPrice: PropTypes.number,
    mrp: PropTypes.number,
    id: PropTypes.number,
    sellingStores: PropTypes.arrayOf(PropTypes.shape({})),
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    curated: PropTypes.bool,
    manufacturerId: PropTypes.string,
    brandId: PropTypes.number,
    displayTag: PropTypes.string,
  }).isRequired,
  cartItems: PropTypes.shape({}),
  pastProduct: PropTypes.bool,
  language: PropTypes.string.isRequired,
  isCoachmark: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

OnlineProduct.defaultProps = {
  cartItems: null,
  pastProduct: false,
  isCoachmark: false,
};

export default OnlineProduct;
