import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { offers } from 'api/api';
import { Placeholder, Svg } from 'app/components/common';
import ProductThumb from 'app/components/common/ProductThumb';
import { SHOP_FRONT } from 'app/events/screens';
import { Constants } from 'app/utilities';

function TopSavingSeeAll({
  storeId: storeIdFromProp, match, isMobile,
  changeStoreState, language, storeName,
}) {
  const { CancelToken } = Axios;
  const source = CancelToken.source();
  const cartItems = useSelector((state) => state.main.cartItems);
  const cartId = useSelector((state) => state.main.cartId);
  const selectedAddress = useSelector((state) => state.main.selectedAddress);
  const [productsList, setProductsList] = useState(null);
  const location = selectedAddress ? `${selectedAddress.location.lat},${selectedAddress.location.lng}` : '19.1207983,72.8782323';
  const [loadStatus, setLoadStatus] = useState('');
  const limit = 30;

  let storeId = storeIdFromProp;
  if (!storeId) {
    const { storeCode } = match.params;
    storeId = storeCode;
  }

  const loadProducts = () => {
    if (storeId) {
      setLoadStatus('loading');
      offers(
        'get',
        source.token,
        location,
        cartId,
        0,
        limit,
        storeId,
      ).then((res) => {
        const { data } = res;
        if (data.count === 0) {
          setLoadStatus('zero_results');
        } else {
          setProductsList(data);
          setLoadStatus('');
        }
      }).catch(() => {
        setLoadStatus('error');
      });
    }
  };

  useEffect(() => (() => {
    source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    changeStoreState('ALL');
  };

  return (
    <div className="bg-white px-4 px-md-0 py-4 py-md-0">
      {
        (() => {
          if (loadStatus === 'loading') {
            return (
              <div
                className="r__loader"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          }
          if (loadStatus === 'error') {
            return (
              <div className="r__loader">
                <Placeholder
                  language={language}
                  handleRetry={loadProducts}
                  imageSrc="/images/error-placeholder.png"
                  heading={Constants.String.OH_NO[language]}
                />
              </div>
            );
          }
          if (loadStatus === 'zero_results') {
            return (
              <Placeholder
                language={language}
                imageSrc="/images/no-results.png"
                heading={Constants.String.NO_RESULTS_FOUND[language]}
              />
            );
          }
          return (
            productsList && productsList.count > 0 ? (
              <>
                <div
                  className="d-flex justify-content-space-between pb-5"
                >
                  <div className="d-flex fs-3 font-weight-black">
                    {
                      !isMobile ? (
                        <>
                          <Button
                            variant="link"
                            onClick={handleBack}
                            className="p-0 d-flex align-items-center"
                          >
                            <Svg
                              svg="leftArrow"
                              fill={Constants.Color.black}
                              width="1rem"
                            />
                          </Button>
                          &nbsp;&nbsp;
                        </>
                      ) : null
                    }
                    {Constants.String.TOP_SAVING_PRODUCTS[language]}
                  </div>
                  <div className="">
                    {`${productsList.count} Items`}
                  </div>
                </div>
                <div className="r__flex r__flex-20 pb-2 flex-wrap">
                  {productsList.results.map((product) => {
                    const { primaryProduct } = product;
                    return (
                      <ProductThumb
                        key={primaryProduct.id}
                        libraryProductId={primaryProduct.id}
                        retailProductId={primaryProduct.retailerProductId}
                        name={primaryProduct.displayName}
                        image={primaryProduct.image}
                        mrp={primaryProduct.mrp}
                        sellingPrice={primaryProduct.sellingPrice}
                        measurementDisplayText={primaryProduct.unitString}
                        storeName={storeName}
                        storeId={storeId}
                        displayTag={primaryProduct.displayTag}
                        previouslyBought={primaryProduct.previouslyBought}
                        inStock={primaryProduct.inStock}
                        horizontalView={isMobile}
                        page={SHOP_FRONT}
                        cartItems={cartItems}
                        section="Top Saving Products See all"
                      />
                    );
                  })}
                </div>
              </>
            ) : null
          );
        })()
      }
    </div>
  );
}

TopSavingSeeAll.propTypes = {
  storeId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      storeCode: PropTypes.string,
    }),
  }).isRequired,
  changeStoreState: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
};

export default TopSavingSeeAll;
