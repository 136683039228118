import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 14.405 14.405"
  >
    <path id="Green_Check_Circle" data-name="Green Check Circle" d="M5.762,10.8l-3.6-3.6L3.169,6.194,5.762,8.787l5.474-5.474,1.008,1.008ZM7.2,0a7.2,7.2,0,1,0,7.2,7.2A7.224,7.224,0,0,0,7.2,0Z" fill={fill} />
  </svg>

);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
