import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import * as Actions from '../../../store/Actions';
import { Constants, Utils } from '../../../utilities';
import * as buttonActions from '../../../events/buttonActions';
import { logButtonClick } from '../../../events/Events';
import { isGuestUser } from '../../../utilities/Utils';

class Reward extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
  }

  componentDidMount = () => {
    const { isOfferView, isCheckout } = this.props;
    if (isOfferView || isCheckout) {
      return;
    }
    const target = this.ref.current;
    target.style.height = `${target.offsetWidth}px`;
  }

  render() {
    const {
      item, isOfferView, applyReward, language,
      processingOffer, toggleScratchCard, screen, toggleLogin,
    } = this.props;
    const {
      isScratched, offerValidTill, canApply,
      scratchedAllowFrom, isValid, offerValidFrom,
    } = item;
    const parts = new Date(scratchedAllowFrom).toDateString().split(' ');
    const isDisabled = (
      !canApply || !isScratched || processingOffer
    );
    let rewardBackgroundStyle = '';
    if (isScratched) {
      rewardBackgroundStyle = 'scratched-card';
    } else if (isValid) {
      rewardBackgroundStyle = 'scratch-card';
    } else {
      rewardBackgroundStyle = 'scratch-card-not-available';
    }
    return (
      <Col
        {...(
          isOfferView
            ? {}
            : {
              xs: 12,
              md: 8,
              lg: 8,
            }
        )}
        id="reward"
        className={`p-2 ${isOfferView ? 'offer-view' : ''}`}
      >
        <Row
          className="mx-0"
        >
          <Col
            ref={this.ref}
            xs={24}
            className={`main-content px-0 ${rewardBackgroundStyle}`}
          >
            {
              isScratched
                ? (
                  <Row
                    className="mx-0 h-100 p-4"
                    style={{ alignContent: 'space-between' }}
                  >
                    <Col
                      xs={24}
                      className="px-0"
                    >
                      <div
                        className="font-weight-bold fs-4"
                      >
                        {item.offerShortDescription}
                      </div>
                      <div className="fs-6 text-medium text-truncate-1">
                        {item.offerLongDescription}
                      </div>
                      {
                        new Date(offerValidFrom) > new Date() ? (
                          <div className="fs-6 text-medium">
                            {Constants.String.VALID_FROM[language]}
                            &nbsp;
                            {Utils.rewardsExpireOn(offerValidFrom)}
                          </div>
                        ) : (
                          <div
                            className="fs-6 text-medium"
                          >
                            {Constants.String.EXPIRES_ON[language]}
                            &nbsp;
                            {Utils.rewardsExpireOn(offerValidTill)}
                          </div>
                        )
                      }
                    </Col>
                    {
                      (applyReward && isValid) && (
                        <Col
                          xs={24}
                          className={`my-1 px-2 py-1 border border-primary text-center text-primary border-radius-8 fs-4 font-weight-bold ${
                            isDisabled ? 'opacity-28' : 'cursor-pointer'
                          }`}
                          onClick={() => {
                            if (!isDisabled) {
                              if (isGuestUser()) {
                                toggleLogin();
                              } else {
                                applyReward(item);
                                logButtonClick(
                                  {
                                    BUTTON: buttonActions.APPLY_REWARD,
                                    SCRATCH_CARD: item,
                                    SCREEN: screen,
                                  },
                                );
                              }
                            }
                          }}
                        >
                          {Constants.String.APPLY[language].toUpperCase()}
                        </Col>
                      )
                    }
                  </Row>
                )
                : (
                  <Row
                    className="mx-0 h-100 flex-column p-4 cursor-pointer"
                    onClick={() => {
                      toggleScratchCard(item);
                    }}
                  >
                    <Col
                      className="px-0"
                    >
                      <div
                        className="text-center font-weight-bold text-white fs-6"
                      >
                        {
                          isValid
                            ? `${Constants.String.AVAILABLE_NOW[language]}!`
                            : Constants.String.AVAILABLE_ON[language]
                        }
                      </div>
                      {
                        !isValid && (
                          <div
                            className="fs-5 text-white text-center"
                          >
                            {(parts[2])}
                            &nbsp;
                            {parts[1]}
                          </div>
                        )
                      }
                    </Col>
                    {
                      (isValid) && (
                        <Col
                          xs="auto"
                          className="px-0 py-1 bg-violet text-center text-white border-radius-4 fs-6 font-weight-bold"
                        >
                          {Constants.String.SCRATCH_NOW[language].toUpperCase()}
                        </Col>
                      )
                    }
                  </Row>
                )
            }
          </Col>
        </Row>
      </Col>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleScratchCard: (scratchCard) => (
    dispatch(Actions.toggleScratchCard(scratchCard))
  ),
  toggleLogin: (scratchCard) => (
    dispatch(Actions.toggleLogin(scratchCard))
  ),
});

Reward.propTypes = {
  item: PropTypes.shape({
    isScratched: PropTypes.bool,
    offerShortDescription: PropTypes.string,
    offerValidTill: PropTypes.string,
    offerValidFrom: PropTypes.string,
    scratchedAllowFrom: PropTypes.string,
    canApply: PropTypes.bool,
    isValid: PropTypes.bool,
    offerLongDescription: PropTypes.string,
  }).isRequired,
  isOfferView: PropTypes.bool,
  isCheckout: PropTypes.bool,
  applyReward: PropTypes.func,
  processingOffer: PropTypes.bool,
  language: PropTypes.string.isRequired,
  toggleScratchCard: PropTypes.func.isRequired,
  screen: PropTypes.string.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

Reward.defaultProps = {
  isOfferView: false,
  isCheckout: false,
  applyReward: null,
  processingOffer: false,
};

export default connect(null, mapDispatchToProps)(Reward);
