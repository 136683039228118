import React from 'react';
import PropTypes from 'prop-types';

const TrendingSearchIcon = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke="#C8C8C8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="m5 17.5 6-7 2 4 6.5-8m0 0-3 .5m3-.5.5 3"
    />
  </svg>
);

TrendingSearchIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default TrendingSearchIcon;
