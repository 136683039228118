import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 56 56"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <g>
        <g>
          <g>
            <g
              fill="#FFEBF9"
            >
              <g
                transform="translate(-230 -615) translate(216 607) translate(14 8)"
              >
                <circle cx="28" cy="28" r="28" />
              </g>
              <g
                transform="translate(-230 -615) translate(216 607) translate(14 8)"
              >
                <circle cx="28" cy="28" r="28" />
              </g>
            </g>
            <g
              stroke="#B8238E"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path
                d="M5 4h22c.552 0 1 .448 1 1v17c0 .552-.448 1-1 1H15h0l-6 5v-5H5c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1z"
                transform="translate(-230 -615) translate(216 607) translate(14 8) translate(12 12)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
};

export default Svg;
