import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { Constants } from 'app/utilities';
import { buttonEventClick } from 'clevertap/LogEvent';

const ShopOffline = (props) => {
  const {
    history, language, isMobile, toggleSideOverlay, page,
    reason, onHide,
  } = props;

  const getMeta = () => {
    switch (reason) {
      case 'DELAYED_DELIVERY':
        return {
          title: Constants.String.THE_SHOP_IS_CLOSED_FOR_THE_DAY[language],
          desc: Constants.String.YOU_CAN_STILL_DESC[language],
          ctaText: Constants.String.CONTINUE[language],
        };
      default:
        return {
          title: Constants.String.SHOP_OFFLINE[language],
          desc: Constants.String.SHOP_OFFLINE_DESC[language],
          ctaText: Constants.String.VIEW_OTHER_SHOPS[language],
        };
    }
  };

  return (
    <Container
      className="bg-white d-flex align-items-center justify-content-between p-7 border-radius-16"
    >
      <Row>
        <Col xs={10}>
          <div>
            <img
              alt="shopOffline"
              src="/images/shop_offline.png"
              height={`${isMobile ? '120px' : '146px'}`}
              width={`${isMobile ? '120px' : '146px'}`}
            />
          </div>
        </Col>
        <Col xs={14}>
          <div>
            <div className={`font-weight-black ${isMobile ? 'fs-2' : 'fs-1'}`}>
              {getMeta().title}
            </div>
            <div className={`mt-1 ${isMobile ? '' : 'fs-3'}`}>
              {getMeta().desc}
            </div>
            <div className="mt-5">
              <Button
                block
                className={`p-2 border-radius-8 ${isMobile ? '' : 'fs-4'}`}
                onClick={() => {
                  toggleSideOverlay();
                  if (reason === 'DELAYED_DELIVERY') {
                    onHide();
                  } else {
                    buttonEventClick(
                      'View other shops',
                      {
                        Page: page,
                      },
                    );
                    history.push('/shops');
                  }
                }}
              >
                {getMeta().ctaText}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

ShopOffline.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  toggleSideOverlay: PropTypes.func,
  page: PropTypes.string.isRequired,
  reason: PropTypes.string,
  onHide: PropTypes.func,
};

ShopOffline.defaultProps = {
  toggleSideOverlay: () => {},
  reason: '',
  onHide: () => {},
};

export default ShopOffline;
