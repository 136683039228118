import {
  cqAddToCart, cqProductTag, cqSalesTag,
  cqSearchProductListing, cqSetAccount, cqViewBasket,
} from 'app/criteoq';
import {
  scCompleteRegistration, scAddPaymentInfo, scInitiateCheckout,
  scViewContent, scLogAddToCart, scPurchase,
} from 'app/sharechat';
import { fbLogAddToCart } from 'facebook/Facebook';
import {
  singularAddOrRemoveProductFromCart, singularBeginCheckout, singularLogPayment,
  singularLogProductDetails, singularLogProductList, singularLogShippingInfo,
  singularLogin, singularPurchase, singularSignupClicked,
} from 'singular';

const {
  dataLayer = [], gtag = () => {},
} = window;

// Product Schema
// ||||||||||||||||
// item_id: "SKU_98765",
// item_name: "Cheese Garlic Sev",
// price: 98,
// quantity: 1
// ||||||||||||||||

export function gaLogPageView() {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({ event: 'page_view' });
}

export function gaLogSignUpClicked() {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'signUpClicked',
  });
  singularSignupClicked();
}

export function gaLogUserLogin(userId = '') {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'registration',
    authenticationMethod: 'mobile',
    userId,
  });
  scCompleteRegistration({
    authenticationMethod: 'mobile',
    userId,
  });
  cqSetAccount();
  singularLogin(userId);
}

export function gaLogSignIn(
  userId, phone,
) {
  gtag('event', 'sign_up_new', {
    method: 'Phone',
    user_id: userId,
    phone,
  });
}

export function gaLogSignUp(
  userId, phone,
) {
  gtag('event', 'sign_up_repeat', {
    method: 'Phone',
    user_id: userId,
    phone,
  });
}

export function gaLogProductList(
  listId = 'store_products', listName = 'Store Products', products = [],
) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'view_item_list',
    item_list_id: listId,
    item_list_name: listName,
    ecommerce: {
      items: products,
    },
  });
  scViewContent({
    ...products,
  });
  cqSearchProductListing(products, listName, listId);
  singularLogProductList(products);
}

export function gaLogProductDetails(productValue, product = []) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'view_item',
    ecommerce: {
      currency: 'INR',
      value: productValue,
      items: product,
    },
  });
  cqProductTag(
    product[0].item_id,
    product[0].price,
  );
  singularLogProductDetails(product[0]);
}

export function gaLogAddOrRemoveProductFromCart(
  isAdding, productValue, cartProduct = {},
) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: isAdding ? 'add_to_cart' : 'remove_from_cart',
    ecommerce: {
      currency: 'INR',
      value: productValue,
      items: [cartProduct],
    },
  });
  scLogAddToCart({
    currency: 'INR',
    value: productValue,
    items: [cartProduct],
  });
  cqAddToCart(
    cartProduct.item_id,
    productValue,
    cartProduct.quantity,
  );
  singularAddOrRemoveProductFromCart(isAdding, productValue, cartProduct);
  fbLogAddToCart(cartProduct.item_id, cartProduct.quantity, productValue);
}

export function gaLogBeginCheckout(
  totalBill, cartProducts = [],
) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      currency: 'INR',
      value: totalBill,
      items: cartProducts,
    },
  });
  scInitiateCheckout({
    currency: 'INR',
    value: totalBill,
    items: cartProducts,
  });
  cqViewBasket(cartProducts);
  singularBeginCheckout(totalBill, cartProducts);
}

export function gaLogPaymentInfo(
  totalBill, coupon, paymentMode, cartProducts,
) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'add_payment_info',
    ecommerce: {
      currency: 'INR',
      value: totalBill,
      coupon: coupon || '',
      payment_type: paymentMode,
      items: cartProducts,
    },
  });
  scAddPaymentInfo({
    currency: 'INR',
    value: totalBill,
    coupon: coupon || '',
    payment_type: paymentMode,
    items: cartProducts,
  });
  singularLogPayment(totalBill, coupon, paymentMode, cartProducts);
}

export function gaLogShippingInfo(
  totalBill, coupon, shippingMode, cartProducts,
) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: {
      currency: 'INR',
      value: totalBill,
      coupon: coupon || '',
      shipping_tier: shippingMode,
      items: cartProducts,
    },
  });
  singularLogShippingInfo(totalBill, coupon, shippingMode, cartProducts);
}

export function gaLogPurchaseEvents(
  profile, transactionId, coupon, billAfterDiscount,
  itemTotalBill, totalDiscount, cartProducts = [],
) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'purchase',
    user_id: profile.phone_number,
    phone: profile.phone_number,
    ecommerce: {
      transaction_id: transactionId,
      coupon: coupon || '',
      currency: 'INR',
      value: billAfterDiscount,
      item_total: itemTotalBill,
      discount: totalDiscount,
      items: cartProducts,
    },
  });
  scPurchase({
    transaction_id: transactionId,
    coupon: coupon || '',
    currency: 'INR',
    value: billAfterDiscount,
    item_total: itemTotalBill,
    discount: totalDiscount,
    items: cartProducts,
  });
  cqSalesTag(transactionId, cartProducts);
  singularPurchase(billAfterDiscount, profile.phone_number, '');
}

export function gaLogCustomEvents(eventName = '', data = {}) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: eventName,
    ...data,
  });
}
