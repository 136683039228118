import { convert24HrTo12Hr, getSEOFriendlyURL, getSeoShopUrl } from 'app/utilities/Utils';
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageMetaData = ({
  storeDetails,
}) => {
  let deliverOption = '';
  if (storeDetails) {
    deliverOption = storeDetails.pickup_only_allowed ? `No. ${storeDetails.store_name} provides only pick up services.` : `Yes. ${storeDetails.store_name} provides both home delivery and pick up services.`;
  }
  let city = '';
  let locality = '';
  let storeType = '';
  let { hasSeoData, shopUrl } = { hasSeoData: false, shopUrl: '' };
  if (storeDetails) {
    ({ hasSeoData, shopUrl } = getSeoShopUrl(
      'india',
      storeDetails.city,
      storeDetails.locality,
      storeDetails.store_type,
      storeDetails.store_name,
      storeDetails.uid,
    ));
    city = getSEOFriendlyURL(storeDetails.city);
    locality = getSEOFriendlyURL(storeDetails.locality);
    storeType = getSEOFriendlyURL(storeDetails.store_type);
  }

  return (
    <Helmet>
      {/* Title */}
      {
        storeDetails ? (
          <title>
            {`${storeDetails.store_name}, ${locality ? `${locality}, ` : ''}${city ? `${city}, ` : ''}| Online ${storeDetails.store_type} near me | LoveLocal`}
          </title>
        ) : null
      }

      {/* Description Meta */}
      {
        storeDetails ? (
          <meta
            name="description"
            content={`${storeDetails.store_name} in ${locality || ''} ${city || ''} offers ${storeDetails.store_type} product options for all your daily needs. Shop online with Lovelocal using various payment options & get the product delivered at your doorstep. Check the available items, address, reviews & ratings now.`}
          />
        ) : null
      }

      {/* Canonical Link */}
      {
        (hasSeoData && storeDetails) ? (
          <link
            rel="canonical"
            href={`https://${window.location.host}${shopUrl}`}
          />
        ) : null
      }

      {/* BreadcrumbList Store Markup */}
      {
        (hasSeoData && storeDetails) && (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Home',
                  item: `https://${window.location.host}/`,
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: 'India',
                  item: `https://${window.location.host}/india`,
                },
                {
                  '@type': 'ListItem',
                  position: 3,
                  name: `${storeDetails.city}`,
                  item: `https://${window.location.host}/india/${city}`,
                },
                {
                  '@type': 'ListItem',
                  position: 4,
                  name: `${storeDetails.locality}`,
                  item: `https://${window.location.host}/india/${city}/${locality}`,
                },
                {
                  '@type': 'ListItem',
                  position: 5,
                  name: `${storeDetails.store_type}`,
                  item: `https://${window.location.host}/india/${city}/${locality}/${storeType}`,
                },
                {
                  '@type': 'ListItem',
                  position: 6,
                  name: `${storeDetails.store_name}`,
                  item: `https://${window.location.host}${shopUrl}`,
                },
              ],
            })}
          </script>
        )
      }

      {/* BreadcrumbList Store Ratings */}
      {
        (
          !!storeDetails
          && hasSeoData
        ) && (
          storeDetails.store_type === 'Groceries' ? (
            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'GroceryStore',
                name: storeDetails.store_name,
                image: storeDetails.shopLogo,
                '@id': `https://${window.location.host}${shopUrl}`,
                url: `https://${window.location.host}${shopUrl}`,
                telephone: storeDetails.phone_number,
                hasOfferCatalog: 'groceries',
                priceRange: '₹₹',
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: storeDetails.completeAddress,
                  addressLocality: storeDetails.locality,
                  postalCode: storeDetails.address.postal_code,
                  addressCountry: 'India',
                },
                geo: {
                  '@type': 'GeoCoordinates',
                  ...storeDetails.address.location,
                },
              })}
            </script>
          ) : (
            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'LocalBusiness',
                name: storeDetails.store_name,
                url: `https://${window.location.host}${shopUrl}`,
                image: storeDetails.shop_story_images.filter((item) => item.type === 'IMAGE').length > 0
                  ? storeDetails.shop_story_images.filter((item) => item.type === 'IMAGE').map((image) => image.url)
                  : [],
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: storeDetails.completeAddress,
                  addressLocality: storeDetails.locality,
                  postalCode: storeDetails.address.postal_code,
                  addressRegion: storeDetails.city,
                  addressCountry: 'India',
                },
                geo: {
                  '@type': 'GeoCoordinates',
                  ...storeDetails.address.location,
                },
                ...parseFloat(storeDetails.rating.count) ? {
                  aggregateRating: {
                    '@type': 'AggregateRating',
                    ...parseFloat(storeDetails.rating.average_rating)
                      ? { ratingValue: storeDetails.rating.average_rating } : {},
                    ...parseFloat(storeDetails.rating.count)
                      ? { ratingCount: storeDetails.rating.count } : {},
                    bestRating: '5',
                    worstRating: '1',
                  },
                } : {},
                review: storeDetails.rating.reviews.map((review) => ({
                  '@type': 'Review',
                  reviewRating: {
                    '@type': 'Rating',
                    ...parseFloat(review.rating) ? { ratingValue: review.rating } : {},
                    bestRating: '5',
                  },
                  datePublished: new Date(review.created_at).toDateString(),
                  reviewBody: review.content,
                  author: {
                    '@type': 'Person',
                    name: review.customer_name,
                  },
                })),
                openingHoursSpecification: [{
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                  ],
                  opens: storeDetails.general_timings.opening_time,
                  closes: storeDetails.general_timings.closing_time,
                }],
                priceRange: '₹₹',
              })}
            </script>
          )
        )
      }

      {/* FAQ Schema */}
      {storeDetails && (
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: [{
            '@type': 'Question',
            name: `How can I find ${storeDetails.store_name}?`,
            acceptedAnswer: {
              '@type': 'Answer',
              text: `You can find ${storeDetails.store_name} at ${storeDetails.completeAddress}`,
            },
          },
          {
            '@type': 'Question',
            name: `What is the Phone number for ${storeDetails.store_name}?`,
            acceptedAnswer: {
              '@type': 'Answer',
              text: `You can contact ${storeDetails.store_name} at ${storeDetails.phone_number}.`,
            },
          },
          {
            '@type': 'Question',
            name: `Does the ${storeDetails.store_name} provide home delivery service?`,
            acceptedAnswer: {
              '@type': 'Answer',
              text: `${deliverOption}`,
            },
          },
          {
            '@type': 'Question',
            name: `What are hours of operation for ${storeDetails.store_name}?`,
            acceptedAnswer: {
              '@type': 'Answer',
              text: `${storeDetails.store_name} operates from Mon to Sun,
              ${convert24HrTo12Hr(storeDetails.general_timings.opening_time)} to
              ${convert24HrTo12Hr(storeDetails.general_timings.closing_time)}`,
            },
          },
          {
            '@type': 'Question',
            name: 'What are the various modes of payment accepted by the shop ?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: `${storeDetails.store_name} accepts payments in ${storeDetails.payment_mode.reduce((acc, cMode) => (
                cMode.isEnabled ? [...acc, cMode.name] : acc
              ), []).join(', ')}`,
            },
          },

          ],
        })}
      </script>
      )}
    </Helmet>
  );
};

PageMetaData.propTypes = {
  storeDetails: PropTypes.shape({
    pickup_only_allowed: PropTypes.bool,
    store_type: PropTypes.string,
    store_name: PropTypes.string,
    shopLogo: PropTypes.string,
    completeAddress: PropTypes.string,
    shop_story_images: PropTypes.arrayOf(PropTypes.shape({})),
    payment_mode: PropTypes.arrayOf(PropTypes.shape({})),
    address: PropTypes.shape({
      postal_code: PropTypes.string,
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    }),
    country: PropTypes.string,
    city: PropTypes.string,
    locality: PropTypes.string,
    general_timings: PropTypes.shape({
      opening_time: PropTypes.string,
      closing_time: PropTypes.string,
    }),
    phone_number: PropTypes.string.isRequired,
    rating: PropTypes.shape({
      count: PropTypes.number,
      average_rating: PropTypes.number,
      reviews: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
      my_review: PropTypes.shape({
        rating: PropTypes.number,
      }),
    }),
    uid: PropTypes.string,
  }).isRequired,
};

export default PageMetaData;
