/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Button,
  Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { Constants, validateForm, validateRegex } from 'app/utilities';
import { Svg } from 'app/components/common';
import { logButtonClick } from 'app/events/Events';
import * as ActionTypes from '../../store/ActionTypes';
import * as buttonActions from '../../events/buttonActions';

const AddOrEditAddress = (props) => {
  const {
    isEditAddress, language, isMobile,
    selectedAddress, notifyAction, handleEditAddress,
  } = props;
  const customer = useSelector((state) => state.main.customer);
  const requestsProcessing = useSelector((state) => state.main.requestsProcessing);
  const requestsFailure = useSelector((state) => state.main.requestsFailure);

  const [addressText, setAddressText] = useState('');
  const [addressSubText, setAddressSubText] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [flatNumber, setFlatNumber] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [localityName, setLocalityName] = useState('');
  const [landmark, setLandmark] = useState('');
  const [addressType, setAddressType] = useState('');
  const [selectedAddressType, setSelectedAddressType] = useState('');
  const [addressTypeLabel, setAddressTypeLabel] = useState('');
  const [checkError, setCheckError] = useState(false);
  const [errors, setErrors] = useState({});

  const setAddress = () => {
    if (selectedAddress) {
      setAddressText(selectedAddress.addressText);
      setAddressSubText(selectedAddress.addressType);
      setFlatNumber(selectedAddress.flatNumber);
      setBuildingName(selectedAddress.buildingName);
      setStreetName(selectedAddress.streetName);
      setPinCode(selectedAddress.pinCode);
      setLocalityName(selectedAddress.localityName);
      setLandmark(selectedAddress.landmark);
      setAddressType(isEditAddress ? selectedAddress.addressType : '');
      setSelectedAddressType(isEditAddress
        ? ((selectedAddress.addressType === 'Home' || selectedAddress.addressType === 'Work') ? selectedAddress.addressType : 'Other') : '');
      setAddressTypeLabel(isEditAddress
        ? ((selectedAddress.addressType === 'Home' || selectedAddress.addressType === 'Work') ? selectedAddress.addressType : 'Other') : '');
    }
  };

  const setNames = () => {
    setFirstName(customer.first_name || '');
    setLastName(customer.last_name || '');
  };

  const handleAddress = () => {
    setCheckError(!checkError);
    let fieldObj = {
      flatNumber, buildingName, streetName, pinCode, localityName, selectedAddressType,
    };
    if (selectedAddressType === 'Other') {
      fieldObj = {
        ...fieldObj,
        addressType,
      };
    }
    const newErrors = validateForm(errors, fieldObj);
    setErrors(newErrors);

    if (!Object.entries(newErrors).length) {
      const addressPayload = {
        ...selectedAddress,
        addressType: selectedAddressType === 'Other' ? (addressType || 'Other') : addressTypeLabel,
        buildingName,
        flatNumber,
        localityName,
        streetName,
        pinCode,
        landmark,
        isCompleted: true,
      };
      if (isEditAddress) {
        handleEditAddress(addressPayload);
      } else {
        notifyAction({ ...addressPayload, isDefault: true }, 'change', true);
      }
      logButtonClick(
        {
          BUTTON: isEditAddress ? buttonActions.UPDATE_ADDRESS : buttonActions.SAVE_ADDRESS,
          SCREEN: 'MANAGE_LOCATION',
        },
      );
    }
  };

  useEffect(() => {
    if (selectedAddress) {
      setAddress();
    }
    if (customer) {
      setNames();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loader = requestsProcessing[ActionTypes.ADDRESS_EDIT_REQUEST];
  const error = requestsFailure[ActionTypes.ADDRESS_EDIT_REQUEST];

  return (
    <Container fluid className="px-0 bg-white">
      <Row>
        <Col className="px-0">
          <span className="fs-3 font-weight-black">
            {`${isEditAddress ? Constants.String.EDIT_ADDRESS[language] : Constants.String.ADD_NEW_ADDRESS[language]}`}
          </span>
        </Col>
      </Row>
      <Row>
        <Col
          xs={22}
          className="px-0 pt-4"
        >
          <Row className="mx-0">
            <div className="d-flex">
              <Svg
                svg="locationIcon"
                width={24}
                height={24}
              />
            </div>
            &nbsp;
            <span className={`font-weight-bold text-truncate ${isMobile ? 'mw-250p' : 'mw-400p'}`}>
              {addressSubText}
            </span>
          </Row>
          <Row className="mx-0">
            <span className="text-truncate fs-6 text-secondary font-weight-bold">
              {addressText}
            </span>
          </Row>
        </Col>
      </Row>
      <Form>
        <Row className="py-4">
          <Col xs={24} className="px-0">
            <Row>
              <Col xs={24}>
                <Form.Group
                  className="mb-2"
                >
                  <div className="d-flex">
                    <div className="w-50">
                      <Form.Control
                        id="firstName"
                        type="text"
                        placeholder={Constants.String.FIRST_NAME[language]}
                        className="custom-input px-0 rounded-0 fs-6 text-black"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="w-50">
                      <Form.Control
                        id="lastName"
                        type="text"
                        placeholder={Constants.String.LAST_NAME[language]}
                        className="custom-input px-0 rounded-0 fs-6 text-black"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                >
                  <Form.Control
                    id="flatNumber"
                    type="text"
                    placeholder={`${Constants.String.FLAT_NUMBER[language]}*`}
                    className="custom-input px-0 rounded-0 fs-6 text-black"
                    value={flatNumber}
                    onChange={(e) => {
                      delete errors.flatNumber;
                      setFlatNumber(e.target.value);
                    }}
                    isInvalid={!!errors.flatNumber}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                  >
                    <span className="fs-6">
                      {errors.flatNumber && errors.flatNumber[language]}
                    </span>
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                >
                  <Form.Control
                    id="buildingName"
                    type="text"
                    placeholder={`${Constants.String.BUILDING_NAME[language]}*`}
                    className="custom-input px-0 rounded-0 fs-6 text-black"
                    value={buildingName}
                    onChange={(e) => {
                      delete errors.buildingName;
                      setBuildingName(e.target.value);
                    }}
                    isInvalid={!!errors.buildingName}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                  >
                    <span className="fs-6">
                      {errors.buildingName && errors.buildingName[language]}
                    </span>
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                >
                  <div className="d-flex">
                    <div className="w-33">
                      <Form.Control
                        id="streetName"
                        type="text"
                        placeholder={`${Constants.String.STREET_NAME[language]}*`}
                        className="custom-input px-0 rounded-0 fs-6 text-black"
                        value={streetName}
                        onChange={(e) => {
                          delete errors.streetName;
                          setStreetName(e.target.value);
                        }}
                        isInvalid={!!errors.streetName}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                      >
                        <span className="fs-6">
                          {errors.streetName && errors.streetName[language]}
                        </span>
                      </Form.Control.Feedback>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="w-33">
                      <Form.Control
                        id="pinCode"
                        type="text"
                        placeholder={`${Constants.String.PIN_CODE[language]}*`}
                        className="custom-input px-0 rounded-0 fs-6 text-black"
                        value={pinCode}
                        onChange={(e) => {
                          if (validateRegex('pinCode', e.target.value)) {
                            delete errors.pinCode;
                            setPinCode(e.target.value);
                          }
                        }}
                        isInvalid={!!errors.pinCode}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                      >
                        <span className="fs-6">
                          {errors.pinCode && errors.pinCode[language]}
                        </span>
                      </Form.Control.Feedback>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="w-33">
                      <Form.Control
                        id="localityName"
                        type="text"
                        placeholder={`${Constants.String.AREA_NAME[language]}*`}
                        className="custom-input px-0 rounded-0 fs-6 text-black"
                        value={localityName}
                        onChange={(e) => {
                          delete errors.localityName;
                          setLocalityName(e.target.value);
                        }}
                        isInvalid={!!errors.localityName}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                      >
                        <span className="fs-6">
                          {errors.localityName && errors.localityName[language]}
                        </span>
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                >
                  <Form.Control
                    id="landmark"
                    type="text"
                    placeholder={Constants.String.LANDMARK[language]}
                    className="custom-input px-0 rounded-0 fs-6 text-black"
                    value={landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  className="mb-2 px-0"
                >
                  <Form.Label
                    className="text-muted fs-6 mb-2"
                  >
                    {Constants.String.SAVE_ADDRESS_AS[language]}
                  </Form.Label>
                  <div className="d-flex align-items-center">
                    <Button
                      variant="link"
                      className={`py-1 px-3 border-radius-8 text-black
                        ${addressTypeLabel === 'Home' ? 'border-primary bg-primary-light' : 'border'}`}
                      onClick={() => {
                        setAddressType('Home');
                        setSelectedAddressType('Home');
                        setAddressTypeLabel('Home');
                        delete errors.selectedAddressType;
                        delete errors.addressType;
                      }}
                    >
                      <Svg
                        svg="homeIcon"
                        fill="none"
                        width={24}
                        height={24}
                      />
                      &nbsp;
                      {Constants.String.HOME[language]}
                    </Button>
                    <Button
                      variant="link"
                      className={`py-1 px-3 border-radius-8 text-black mx-3
                        ${addressTypeLabel === 'Work' ? 'border-primary bg-primary-light' : 'border'}`}
                      onClick={() => {
                        setAddressType('Work');
                        setSelectedAddressType('Work');
                        setAddressTypeLabel('Work');
                        delete errors.selectedAddressType;
                        delete errors.addressType;
                      }}
                    >
                      <Svg
                        svg="work"
                        width={24}
                        height={24}
                      />
                      &nbsp;
                      {Constants.String.WORK[language]}
                    </Button>
                    {
                      selectedAddressType === 'Other' && addressTypeLabel === 'Other' ? (
                        <div>
                          <input
                            type="text"
                            id="addressType"
                            placeholder="Enter Label*"
                            maxLength={25}
                            className="custom-input shadow-none bg-transparent fs-6 text-black"
                            value={addressType}
                            onChange={(e) => {
                              setAddressType(e.target.value);
                              delete errors.selectedAddressType;
                              delete errors.addressType;
                            }}
                            autoComplete="off"
                            required
                          />
                        </div>
                      ) : (
                        <Button
                          variant="link"
                          className={`py-1 px-3 border-radius-8 text-black
                            ${addressTypeLabel === 'Other' ? 'border-primary bg-primary-light' : 'border'}`}
                          onClick={() => {
                            setAddressType('');
                            setSelectedAddressType('Other');
                            setAddressTypeLabel('Other');
                            delete errors.selectedAddressType;
                          }}
                        >
                          <Svg
                            svg="locationIcon"
                            width={24}
                            height={24}
                          />
                          &nbsp;
                          {Constants.String.OTHER[language]}
                        </Button>
                      )
                    }
                  </div>
                  <span className="fs-6 text-danger">
                    {errors.selectedAddressType && errors.selectedAddressType[language]}
                  </span>
                  <span className="fs-6 text-danger">
                    {errors.addressType && errors.addressType[language]}
                  </span>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        {
          isEditAddress && (
            <Row>
              <Col xs={24} className="d-flex align-items-center justify-content-center pb-2">
                {
                  loader && (
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  )
                }
                {
                  error && (
                    <span className="text-danger">
                      {Constants.String.OOPS[language]}
                    </span>
                  )
                }
              </Col>
            </Row>
          )
        }
        <Row>
          <Col xs={24} className="px-0">
            <Button
              block
              className="p-3 border-radius-8"
              onClick={() => {
                handleAddress();
              }}
              disabled={isEditAddress && loader}
            >
              {
                `${isEditAddress ? Constants.String.UPDATE_ADDRESS[language] : Constants.String.SAVE_ADDRESS[language]}`
              }
                &nbsp;&nbsp;
              <Svg
                svg="chevron"
                fill={Constants.Color.white}
                height="24px"
                width="24px"
              />
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

AddOrEditAddress.propTypes = {
  isEditAddress: PropTypes.bool,
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedAddress: PropTypes.shape({
    addressText: PropTypes.string,
    flatNumber: PropTypes.string,
    buildingName: PropTypes.string,
    streetName: PropTypes.string,
    pinCode: PropTypes.string,
    localityName: PropTypes.string,
    landmark: PropTypes.string,
    addressType: PropTypes.string,
  }).isRequired,
  notifyAction: PropTypes.func.isRequired,
  handleEditAddress: PropTypes.func.isRequired,
};

AddOrEditAddress.defaultProps = {
  isEditAddress: false,
};

export default AddOrEditAddress;
