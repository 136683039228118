import * as React from 'react';

const ScratchCardInActive = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={2.5}
      y={6.5}
      width={19}
      height={19}
      rx={4.5}
      fill="#DCFFC7"
      stroke="#787878"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1 19.6842V19.456C13.6669 19.3028 14.1957 19.0325 14.6486 18.6607C15.1337 18.2624 15.5166 17.7602 15.7669 17.194C17.3135 16.8562 18.5 15.5256 18.5 13.8947V12.1938V11.6938H18H16.1V11V10.5H15.6H8.4H7.9V11V11.6938H6H5.5V12.1938V13.8947C5.5 15.5256 6.68646 16.8562 8.23309 17.194C8.4834 17.7602 8.86625 18.2624 9.35145 18.6607C9.80433 19.0325 10.3331 19.3028 10.9 19.456V19.6842C10.9 19.8528 10.8308 20.0183 10.7013 20.1432C10.5713 20.2686 10.3913 20.3421 10.2 20.3421H9.6H9.1V20.8421V22V22.5H9.6H14.4H14.9V22V20.8421V20.3421H14.4H13.8C13.6087 20.3421 13.4287 20.2686 13.2987 20.1432C13.1692 20.0183 13.1 19.8528 13.1 19.6842ZM7.7 13.8945V13.8517H7.9V14.5525C7.7692 14.3547 7.70032 14.1265 7.7 13.8945ZM16.3 13.8941C16.2997 14.1263 16.2308 14.3547 16.1 14.5526V13.8517H16.3V13.8941Z"
      stroke="#787878"
    />
    <circle cx={21} cy={7} r={6.5} fill="black" stroke="black" />
    <path
      d="M18 12C17.725 12 17.4895 11.9067 17.2935 11.72C17.0975 11.5333 16.9997 11.3092 17 11.0476V6.28571C17 6.02381 17.098 5.79952 17.294 5.61286C17.49 5.42619 17.7253 5.33302 18 5.33333H18.5V4.38095C18.5 3.72222 18.7438 3.16064 19.2315 2.69619C19.7192 2.23175 20.3087 1.99968 21 2C21.6917 2 22.2813 2.23222 22.769 2.69667C23.2567 3.16111 23.5003 3.72254 23.5 4.38095V5.33333H24C24.275 5.33333 24.5105 5.42667 24.7065 5.61333C24.9025 5.8 25.0003 6.02413 25 6.28571V11.0476C25 11.3095 24.902 11.5338 24.706 11.7205C24.51 11.9071 24.2747 12.0003 24 12H18ZM21 9.61905C21.275 9.61905 21.5105 9.52571 21.7065 9.33905C21.9025 9.15238 22.0003 8.92825 22 8.66667C22 8.40476 21.902 8.18048 21.706 7.99381C21.51 7.80714 21.2747 7.71397 21 7.71429C20.725 7.71429 20.4895 7.80762 20.2935 7.99429C20.0975 8.18095 19.9997 8.40508 20 8.66667C20 8.92857 20.098 9.15286 20.294 9.33952C20.49 9.52619 20.7253 9.61936 21 9.61905ZM19.5 5.33333H22.5V4.38095C22.5 3.98413 22.3542 3.64683 22.0625 3.36905C21.7708 3.09127 21.4167 2.95238 21 2.95238C20.5833 2.95238 20.2292 3.09127 19.9375 3.36905C19.6458 3.64683 19.5 3.98413 19.5 4.38095V5.33333Z"
      fill="white"
    />
  </svg>
);
export default ScratchCardInActive;
