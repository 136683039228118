import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  tailFill, width, height, rectFill,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 72 25"
  >
    <path
      fill={tailFill}
      d="M0 5h6v20l-6-5V5z"
    />
    <path
      fill={rectFill}
      d="M0 0H72V20H0z"
    />
  </svg>
);

Svg.propTypes = {
  tailFill: PropTypes.string.isRequired,
  rectFill: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
