const { scq = () => {} } = window;

export const scCompleteRegistration = (data = {}) => {
  scq('Complete registration', 'pre_defined', data);
};

export const scViewContent = (data = {}) => {
  scq('View content', 'pre_defined', data);
};

export const scLogAddToCart = (data = {}) => {
  scq('Add to cart', 'pre_defined', data);
};

export const scInitiateCheckout = (data = {}) => {
  scq('Initiate checkout', 'pre_defined', data);
};

export const scAddPaymentInfo = (data = {}) => {
  scq('Add payment info', 'pre_defined', data);
};

export const scPurchase = (data = {}) => {
  scq('Purchase', 'pre_defined', data);
};
