import React from 'react'

const CustomSwiperCarousel = (
  {
    slides, 
    slidesPerView, 
    spaceBetween, 
    autoPlay, 
    speed, 
    loop, 
    pagination, 
    paginationClickable,
    ...props
  }) => {

  return (
    <swiper-container 
      slides
      slides-per-view={slidesPerView} 
      space-between={spaceBetween}
      autoplay={autoPlay} 
      speed={speed}
      loop={loop} 
      pagination={pagination}
      pagination-clickable={paginationClickable} 
    >
      {slides}
    </swiper-container>
  )
}

export default CustomSwiperCarousel