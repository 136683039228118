import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  Col, Container, DropdownButton,
  FormControl, InputGroup, Row, Button,
} from 'react-bootstrap';
import { googleDirection } from 'api/url';
import Toast from 'app/HOC/Toast';
import {
  LOVELOCAL, convert24HrTo12Hr, copyToClipboard,
  getSEOFriendlyURL, getSeoShopUrl,
} from 'app/utilities/Utils';
import { Constants } from 'app/utilities';
import Events from 'clevertap/Event';
import { SHOP_FRONT } from 'app/events/screens';
import { Svg } from 'app/components/common';
import CustomImage from 'app/components/common/CustomImage';
import { logCTEvent } from '../../../events/Events';

const getShareShopText = (ShopName, shopUrl) => (`
Find ${ShopName || 'Shop'} on LoveLocal. You can get ₹50 OFF on your first order. 
Use Code: LOVELOCAL 
Visit shop: ${shopUrl} 
Download LoveLocal App: https://lovelocal.page.link/lovelocal-daily-needs 
`);

function StoreBasicDetails({
  storeDetails, b2bStorePreview, selectedAddress,
  language, storeState, changeStoreState, toggleSideOverlay,
  isMobile, showToast, handleProductSearch, searchText,
  moveToStorySection,
}) {
  let city = '';
  let locality = '';
  let storeType = '';
  let { hasSeoData, shopUrl } = { hasSeoData: false, shopUrl: '' };
  if (storeDetails) {
    ({ hasSeoData, shopUrl } = getSeoShopUrl(
      'india',
      storeDetails.city,
      storeDetails.locality,
      storeDetails.store_type,
      storeDetails.store_name,
      storeDetails.uid,
    ));
    city = getSEOFriendlyURL(storeDetails.city);
    locality = getSEOFriendlyURL(storeDetails.locality);
    storeType = getSEOFriendlyURL(storeDetails.store_type);
  }
  const retailerStory = !!storeDetails && storeDetails.storeBio
    ? (storeDetails.storeBio.join('')).trim() : '';
  const latLng = storeDetails ? `${storeDetails.address.location.latitude},${storeDetails.address.location.longitude}` : '';
  const customerLatLng = selectedAddress
    ? `${selectedAddress.location.lat},${selectedAddress.location.lng}`
    : '19.1207983,72.8782323';

  const socialShareMedia = [
    {
      mediaName: Constants.String.WHATSAPP[language],
      mediaIconJsx: (
        <WhatsappShareButton
          url="https://www.lovelocal.in"
          title={storeDetails ? getShareShopText(storeDetails.store_name, storeDetails.website_url) : ''}
        >
          <Svg
            svg="socialShareWhatsapp"
            width={isMobile ? '70px' : '50px'}
          />
        </WhatsappShareButton>
      ),
    },
    {
      mediaName: Constants.String.FACEBOOK[language],
      mediaIconJsx: (
        <FacebookShareButton
          url="https://www.lovelocal.in"
          quote={storeDetails ? getShareShopText(storeDetails.store_name, storeDetails.website_url) : ''}
        >
          <Svg
            svg="socialShareFacebook"
            width={isMobile ? '70px' : '50px'}
          />
        </FacebookShareButton>
      ),
    },
    {
      mediaName: Constants.String.TWITTER[language],
      mediaIconJsx: (
        <TwitterShareButton
          url="https://www.lovelocal.in"
          title={storeDetails ? getShareShopText(storeDetails.store_name, storeDetails.website_url) : ''}
        >
          <Svg
            svg="socialShareTwitter"
            width={isMobile ? '70px' : '50px'}
            fillOuter="#55acee"
            fillInner="#f1f2f2"
          />
        </TwitterShareButton>
      ),
    },
    {
      mediaName: Constants.String.COPY_LINK[language],
      mediaIconJsx: (
        <Button
          onClick={() => {
            copyToClipboard(storeDetails ? getShareShopText(storeDetails.store_name, storeDetails.website_url) : '');
            showToast(Constants.String.COPIED[language]);
          }}
          variant="link"
          className="p-0"
        >
          <Svg
            svg="socialShareCopy"
            width={isMobile ? '70px' : '50px'}
            fill={Constants.Color.pinkPale}
            stroke={Constants.Color.primary}
          />
        </Button>
      ),
    },
  ];

  const socialShareJSX = (
    <div>
      <div
        className="fs-3 font-weight-bold pt-3 pl-3"
      >
        {Constants.String.SHARE_THIS_SHOP_VIA[language]}
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
      >
        {
          socialShareMedia.map((item) => (
            <div
              className="p-3 bg-white"
              key={item.mediaName}
            >
              <div>
                {item.mediaIconJsx}
              </div>
              <div
                className="fs-6 text-center text-black-50 font-weight-bold"
              >
                {item.mediaName}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );

  const SearchInputBox = (
    <InputGroup
      className="bg-white overflow-hidden border-radius-16 py-1"
    >
      <InputGroup.Text
        className="border-0 fs-6 bg-white px-4"
      >
        <Svg
          svg="globalSearch"
          stroke={Constants.Color.black72}
          width="20px"
        />
      </InputGroup.Text>
      <FormControl
        // ref={this.searchInput}
        className="border-0 px-0 bg-white fs-5 text-black"
        id="searchProduct"
        type="text"
        placeholder="Search for a product in this shop"
        value={searchText}
        onChange={(event) => {
          if (event.target.value) {
            if (storeState !== 'STORE_SEARCH_PRODUCT') {
              changeStoreState('STORE_SEARCH_PRODUCT');
            }
          }
          handleProductSearch(event);
        }}
        autoComplete="off"
      />
      {
        !!searchText && (
          <InputGroup.Text
            className="border-0 fs-6 bg-white px-2 cursor-pointer"
            onClick={() => {
              changeStoreState('ALL');
            }}
          >
            <Svg
              svg="close"
              fill={Constants.Color.primary}
              width="12px"
            />
          </InputGroup.Text>
        )
      }
    </InputGroup>
  );

  return (
    <>
      {/* Breadcrumb Header */}
      <Col
        xs={24}
        className={`py-3 px-0 text-truncate ${
          b2bStorePreview ? 'd-none' : ''
        }`}
      >
        <Container>
          <Row>
            <Col
              xs={24}
              className="d-flex text-black-72 fs-6"
            >
              <Link
                className="text-black-72"
                to="/"
              >
                Home
              </Link>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
              <Link
                className="text-black-72"
                to="/india/all"
              >
                India
              </Link>
              {
                storeDetails && storeDetails.city && (
                  <>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                      className="text-black-72"
                      to={`/india/${city}`}
                    >
                      {storeDetails.city}
                    </Link>
                  </>
                )
              }
              {
                storeDetails && storeDetails.locality && hasSeoData && (
                  <>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                      className="text-black-72"
                      to={`/india/${city}/${locality}`}
                    >
                      {storeDetails.locality}
                    </Link>
                  </>
                )
              }
              {
                hasSeoData && (
                  <>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                      className="text-black-72"
                      to={`/india/${city}/${locality}/c/${storeType}`}
                    >
                      {storeDetails.store_type}
                    </Link>
                      &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                      className="text-black"
                      to={`${shopUrl}`}
                    >
                      {storeDetails.store_name}
                    </Link>
                  </>
                )
              }
            </Col>
          </Row>
        </Container>
      </Col>

      {/* Store Basic Details Desktop View */}
      <Col
        xs={24}
        className="bg-primary pt-4 px-0 d-none d-md-block"
      >
        <Container>
          <Row
            className="mx-0"
          >
            <Col
              xs="auto"
              className="px-0 position-relative bg-white store-logo border border-white"
            >
              <CustomImage
                imageType="STORE_PROFILE"
                imageUrl={storeDetails.shopLogo}
                imageStyle="mh-100 mw-100"
                imageAlt={storeDetails.store_name}
                imageTitle={storeDetails.store_name}
              />
            </Col>
            <Col
              className={`${
                'px-6'
              }`}
            >
              <div>
                <h1
                  className="m-0 text-white text-wrap text-truncate font-weight-bold fs-01"
                >
                  {storeDetails.store_name}
                </h1>
                <div
                  className="pt-1 text-white-72 d-flex"
                >
                  {storeDetails.store_type}
                  {
                    !!retailerStory && (
                      <>
                        <Button
                          variant="link"
                          className="py-0 pl-2 pr-1 m-0 fs-7 text-white mt-1 "
                          onClick={() => {
                            if (moveToStorySection) { moveToStorySection(); }
                          }}
                        >
                          {Constants.String.READ_MY_STORY[language]}
                        </Button>
                        <span className="font-weight-bold text-white">&gt;</span>
                      </>
                    )
                  }
                </div>
              </div>
              <div
                className="pt-3"
              >
                <Row>
                  {
                    storeDetails && storeDetails.rating && storeDetails.rating.count > 0 && (
                      <>
                        <Col
                          xs="auto"
                          className="cursor-pointer"
                          onClick={() => {
                            if (moveToStorySection) {
                              logCTEvent(
                                Events.Rating_button_tapped,
                                {
                                  'Store Name': storeDetails.store_name,
                                  'EP Code': storeDetails.uid,
                                  Page: SHOP_FRONT,
                                  'Shop Category Type': storeDetails.store_type,
                                },
                              );
                              moveToStorySection();
                            }
                          }}
                        >
                          <div
                            className="fs-6 text-white font-weight-bold d-flex align-items-center"
                          >
                            <Svg
                              svg="starFilled"
                              fill={Constants.Color.white}
                              width="12px"
                            />
                            &nbsp;
                            {storeDetails.rating.count ? storeDetails.rating.average_rating : '--'}
                            <span>
                              <Svg
                                svg="arrowDown"
                                stroke={Constants.Color.white}
                                width="16"
                              />
                            </span>
                          </div>
                          <div
                            className="fs-7 text-white-72"
                          >
                            {
                              storeDetails.rating.count
                                ? `${storeDetails.rating.count} ${Constants.String.RATINGS[language]}`
                                : `${Constants.String.NO_RATINGS[language]}`
                            }
                          </div>
                        </Col>
                        <Col
                          xs="auto"
                          className="border-right border-white opacity-28 px-1"
                        />
                      </>
                    )
                  }
                  <Col
                    xs="auto"
                  >
                    <div
                      className="fs-6 text-white font-weight-bold"
                    >
                      {storeDetails.delivery_time}
                    </div>
                    <div
                      className="fs-7 text-white-72"
                    >
                      {Constants.String.DELIVERY_TIME[language]}
                    </div>
                  </Col>
                  <Col
                    xs="auto"
                    className="border-right border-white opacity-28 px-1"
                  />
                  <Col
                    xs="auto"
                  >
                    <div
                      className="fs-6 text-white font-weight-bold"
                    >
                      {
                        storeDetails.pickup_only_allowed ? (
                          <span>
                            --
                          </span>
                        ) : (
                          <span>
                            &#8377;
                            {storeDetails.home_delivery_min_amount}
                          </span>
                        )
                      }
                    </div>
                    <div
                      className="fs-7 text-white-72"
                    >
                      {Constants.String.MINIMUM_ORDER[language]}
                    </div>
                  </Col>
                  <Col
                    xs="auto"
                    className="border-right border-white opacity-28 px-1"
                  />
                  <Col
                    xs="auto"
                  >
                    <div
                      className="fs-6 text-white font-weight-bold"
                    >
                      {`${convert24HrTo12Hr(storeDetails.general_timings.opening_time)} -
                        ${convert24HrTo12Hr(storeDetails.general_timings.closing_time)}`}
                    </div>
                    <div
                      className={`fs-7 text-white-72 ${
                        ''
                      }`}
                    >
                      {Constants.String.SHOP_TIMING[language]}
                    </div>
                  </Col>
                  <Col
                    xs="auto"
                    className="border-right border-white opacity-28 px-1"
                  />
                  <Col
                    xs="auto"
                  >
                    <a
                      href={`tel:${LOVELOCAL.support}`}
                      onClick={() => {
                        logCTEvent(
                          Events.SupportButton_Clicked,
                          {
                            'Store Name': storeDetails.store_name,
                            'EP Code': storeDetails.uid,
                            Page: SHOP_FRONT,
                            'Shop Category Type': storeDetails.store_type,
                          },
                        );
                      }}
                    >
                      <div
                        className="fs-6 text-white font-weight-bold"
                      >
                        {LOVELOCAL.support}
                      </div>
                      <div
                        className="fs-7 text-white-72"
                      >
                        {Constants.String.CALL_US[language]}
                      </div>
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              xs="auto"
              className="px-0"
            >
              <div
                className="d-flex social-button border border-white"
              >
                <Button
                  variant="link"
                  className="text-primary fs-7 font-weight-black border-right"
                  onClick={() => {
                    logCTEvent(
                      Events.Directions,
                      {
                        'Store Name': storeDetails.store_name,
                        'EP Code': storeDetails.uid,
                        Page: SHOP_FRONT,
                        'Shop Category Type': storeDetails.store_type,
                      },
                    );
                    window.open(
                      googleDirection(customerLatLng, latLng),
                      '_blank',
                    );
                  }}
                >
                  <Svg
                    svg="location"
                    fill={Constants.Color.primary}
                    width="1.5rem"
                  />
                  &nbsp;&nbsp;
                  {Constants.String.DIRECTION[language].toUpperCase()}
                </Button>
                <DropdownButton
                  variant="link"
                  onClick={() => {
                    logCTEvent(
                      Events.Share_clicked,
                      {
                        'Store Name': storeDetails.store_name,
                        'EP Code': storeDetails.uid,
                        Page: SHOP_FRONT,
                        'Shop Category Type': storeDetails.store_type,
                      },
                    );
                  }}
                  align
                  title={(
                    <div
                      className="text-primary fs-7 font-weight-black"
                    >
                      <Svg
                        svg="share"
                        fill={Constants.Color.white}
                        stroke={Constants.Color.primary}
                        width="1.5rem"
                      />
                      &nbsp;&nbsp;
                      {Constants.String.SHARE[language].toUpperCase()}
                    </div>
                  )}
                >
                  {socialShareJSX}
                </DropdownButton>
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col
        xs={24}
        className="bg-primary position-sticky sticky-top d-none d-md-block px-0 "
      >
        <Container
          className=""
        >
          <Row
            className="fs-5 font-weight-bold"
          >
            <Col
              xs={24}
              className="py-4 border border-primary border-bottom-0 d-flex justify-content-center"
            >
              {SearchInputBox}
            </Col>
          </Row>
        </Container>
      </Col>

      {/* Store Basic Details Mobile View */}
      <Col
        xs={24}
        className="bg-white py-3 px-0 d-md-none"
      >
        <Container>
          <Row
            className="mx-0"
          >
            <Col
              xs={24}
              className="px-0 py-1 fs-6 text-black-50 border border-black-50 border-radius-8 d-flex align-items-center"
              onClick={() => {
                if (storeState !== 'STORE_SEARCH_PRODUCT' && !b2bStorePreview) {
                  changeStoreState('STORE_SEARCH_PRODUCT');
                }
              }}
            >
              {SearchInputBox}
            </Col>
            {
              !(
                storeState === 'SHOP_PRODUCT'
                || storeState === 'STORE_SEARCH_PRODUCT'
              ) && (
                <Col
                  xs={24}
                  className="px-0 pt-4"
                >
                  <Row
                    className="mx-0 align-items-center"
                  >
                    <Col
                      xs="auto"
                      className={`px-0 ${
                        storeDetails.shopLogo ? 'mSite-store-logo' : ''
                      }`}
                    >
                      {
                        storeDetails.shopLogo ? (
                          <CustomImage
                            imageStyle="mh-100 mw-100"
                            imageUrl={storeDetails.shopLogo}
                            imageType="STORE_PROFILE"
                            imageAlt={storeDetails.store_name}
                            imageTitle={storeDetails.store_name}
                          />
                        ) : (
                          <Svg
                            svg="logo"
                            stroke="none"
                            fill={Constants.Color.primary}
                            width="65px"
                            strokeWidth="4px"
                          />
                        )
                      }
                    </Col>
                    <Col>
                      <div
                        className="fs-3 text-wrap font-weight-bold"
                      >
                        {storeDetails.store_name}
                      </div>
                      <div
                        className="pt-1 fs-6 text-black-64"
                      >
                        <span>
                          {storeDetails.store_type}
                        </span>
                        {
                          !!retailerStory && (
                            <Button
                              variant="link"
                              className="py-0 pl-2 pr-1 m-0 fs-7 text-primary"
                              onClick={() => {
                                changeStoreState('SHOP_DETAILS');
                              }}
                            >
                              {Constants.String.READ_MY_STORY[language]}
                            </Button>
                          )
                        }
                        <span className="font-weight-bold text-primary">&gt;</span>
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      className="px-0"
                      onClick={() => {
                        toggleSideOverlay(() => (
                          <Row
                            className="mx-0 w-100 justify-content-center align-items-center"
                          >
                            <Col
                              xs={24}
                              className="p-4"
                            >
                              {socialShareJSX}
                            </Col>
                          </Row>
                        ));
                      }}
                    >
                      <Svg
                        svg="share"
                        fill={Constants.Color.white}
                        stroke={Constants.Color.primary}
                        width="24px"
                      />
                    </Col>
                  </Row>
                  <Row
                    className="mx-0 pt-4 align-items-center"
                  >
                    <Col
                      xs={24}
                      className="px-0 py-2"
                    >
                      <Row
                        className="mx-0 py-2 dotted-border-top dotted-border-bottom"
                      >
                        <Col
                          xs={8}
                          className="px-0 text-center dotted-border-right d-flex align-items-center justify-content-center"
                          onClick={() => {
                            logCTEvent(
                              Events.Rating_button_tapped,
                              {
                                'Store Name': storeDetails.store_name,
                                'EP Code': storeDetails.uid,
                                Page: SHOP_FRONT,
                                'Shop Category Type': storeDetails.store_type,
                              },
                            );
                            changeStoreState('SHOP_DETAILS');
                          }}
                        >
                          <div>
                            <div>
                              <span>
                                <Svg
                                  svg="starFilled"
                                  fill={Constants.Color.primary}
                                  width="12px"
                                />
                              </span>
                              <span
                                className="font-weight-black fs-6 text-primary"
                              >
                                &nbsp;
                                {storeDetails.rating.average_rating || '--'}
                              </span>
                              <span>
                                <Svg
                                  svg="arrowDown"
                                  stroke={Constants.Color.primary}
                                  width="16"
                                />
                              </span>
                            </div>
                            <div
                              className="text-black-50 fs-7"
                            >
                              {
                                storeDetails.rating.count
                                  ? `${storeDetails.rating.count} ${Constants.String.RATINGS[language]}`
                                  : `${Constants.String.NO_RATINGS[language]}`
                              }
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          className="px-0 text-center dotted-border-right"
                        >
                          <div
                            className="fs-6 text-black-72 font-weight-black"
                          >
                            {storeDetails.delivery_time}
                          </div>
                          <div
                            className="fs-7 text-black-50"
                          >
                            {Constants.String.DELIVERY_TIME[language]}
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          className="px-0 text-center"
                        >
                          <div
                            className="fs-6 text-black-72 font-weight-black"
                          >
                            {
                              storeDetails.pickup_only_allowed ? (
                                <span>
                                  --
                                </span>
                              ) : (
                                <span>
                                  &#8377;
                                  {storeDetails.home_delivery_min_amount}
                                </span>
                              )
                            }
                          </div>
                          <div
                            className="fs-7 text-black-50"
                          >
                            {Constants.String.MINIMUM_ORDER[language]}
                          </div>
                        </Col>
                      </Row>
                      <Row
                        className="mx-0 py-2 dotted-border-bottom"
                      >
                        <Col
                          xs={8}
                          className="px-0 py-1 dotted-border-right d-flex align-items-center justify-content-center"
                        >
                          <a
                            href={`tel:${LOVELOCAL.support}`}
                          >
                            <div
                              className="fs-6 text-primary font-weight-black"
                            >
                              <span>
                                <Svg
                                  svg="phone"
                                  fill={Constants.Color.primary}
                                  width="24px"
                                />
                              </span>
                              {Constants.String.CALL_US[language]}
                            </div>
                          </a>
                        </Col>
                        <Col
                          xs={8}
                          className="px-0 py-1 dotted-border-right d-flex align-items-center justify-content-center"
                          onClick={() => {
                            window.open(
                              googleDirection(customerLatLng, latLng),
                              '_blank',
                            );
                          }}
                        >
                          <div
                            className="fs-6 text-primary font-weight-black"
                          >
                            <span>
                              <Svg
                                svg="location"
                                fill={Constants.Color.primary}
                                width="16px"
                              />
                            </span>
                            <span>
                              {` ${storeDetails.distance} Kms`}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          className="px-0 py-1 d-flex align-items-center justify-content-center"
                        >
                          <div
                            className="fs-6 text-black-72 font-weight-black"
                          >
                            {`${convert24HrTo12Hr(storeDetails.general_timings.opening_time)} -
                              ${convert24HrTo12Hr(storeDetails.general_timings.closing_time)}`}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              )
            }
          </Row>
        </Container>
      </Col>
    </>
  );
}

StoreBasicDetails.propTypes = {
  storeDetails: PropTypes.shape({
    uid: PropTypes.number.isRequired,
    storeBio: PropTypes.arrayOf(PropTypes.string),
    pickup_only_allowed: PropTypes.bool,
    distance: PropTypes.string,
    store_type: PropTypes.string,
    store_name: PropTypes.string,
    shopLogo: PropTypes.string,
    website_url: PropTypes.string,
    address: PropTypes.shape({
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    }),
    rating: PropTypes.shape({
      count: PropTypes.number,
      average_rating: PropTypes.number,
    }),
    city: PropTypes.string,
    locality: PropTypes.string,
    general_timings: PropTypes.shape({
      opening_time: PropTypes.string,
      closing_time: PropTypes.string,
    }),
    delivery_time: PropTypes.string.isRequired,
    home_delivery_min_amount: PropTypes.string.isRequired,
  }).isRequired,
  b2bStorePreview: PropTypes.bool.isRequired,
  selectedAddress: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  storeState: PropTypes.string.isRequired,
  changeStoreState: PropTypes.func.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  handleProductSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  moveToStorySection: PropTypes.func.isRequired,
};

export default Toast(StoreBasicDetails);
