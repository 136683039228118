import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  fillOuter, fillInner, width, height,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 73 72"
  >
    <defs>
      <path id="t5rkagan3a" d="M0 0L54.3 0 54.3 54 0 54z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-12 -563) translate(0 482) translate(0 57) translate(12.067 24) translate(9.05 9)">
        <mask id="pl2awyb0ob" fill="#fff">
          <use xlinkHref="#t5rkagan3a" />
        </mask>
        <path fill={fillOuter} d="M27.15 0C42.145 0 54.3 12.088 54.3 27S42.145 54 27.15 54C12.156 54 0 41.912 0 27S12.156 0 27.15 0" mask="url(#pl2awyb0ob)" />
      </g>
      <path fill={fillInner} d="M42.987 20.93c-1.165.497-2.418.833-3.732.983 1.342-.773 2.372-1.996 2.858-3.456-1.256.716-2.647 1.235-4.127 1.515C36.801 18.76 35.112 18 33.241 18c-3.588 0-6.498 2.798-6.498 6.248 0 .49.058.967.169 1.424-5.4-.26-10.19-2.748-13.395-6.529-.56.923-.88 1.997-.88 3.142 0 2.167 1.148 4.08 2.89 5.2-1.064-.032-2.067-.313-2.942-.782v.08c0 3.027 2.24 5.552 5.212 6.126-.544.143-1.12.219-1.711.219-.42 0-.827-.039-1.223-.112.827 2.482 3.226 4.289 6.07 4.34-2.224 1.676-5.026 2.674-8.071 2.674-.524 0-1.042-.03-1.55-.087 2.875 1.773 6.292 2.807 9.961 2.807 11.953 0 18.49-9.52 18.49-17.778 0-.27-.006-.54-.019-.808 1.271-.88 2.373-1.981 3.243-3.234" transform="translate(-12 -563) translate(0 482) translate(0 57) translate(12.067 24) translate(9.05 9)" />
    </g>
  </svg>
);

Svg.propTypes = {
  fillInner: PropTypes.string.isRequired,
  fillOuter: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Svg;
