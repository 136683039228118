import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { Constants } from '../../utilities';

const DiscountBreakup = (props) => {
  const {
    language, couponDiscount, valueDiscount, toggleDialog,
  } = props;
  return (
    <Container
      className="r__action_container r__action_container--small"
    >
      <Row>
        <Col
          xs={24}
          className="mb-4 fs-3 r__action_title"
        >
          <b>
            {Constants.String.DISCOUNT_BREAKUP[language]}
          </b>
        </Col>
        <Col
          xs={24}
          className="mb-6"
        >
          {
            !!couponDiscount && couponDiscount !== 0 && (
            <Row
              className="align-items-center justify-content-between"
            >
              <Col
                className="fs-5"
              >
                {Constants.String.SCRATCH_CARD_COUPON_DISCOUNT[language]}
              </Col>
              <Col
                xs="auto"
                className="fs-5 text-green"
              >
                {`\u20B9 ${couponDiscount}`}
              </Col>
            </Row>
            )
          }
          {
            !!valueDiscount && valueDiscount !== 0 && (
            <Row
              className="align-items-center justify-content-between"
            >
              <Col
                className="fs-5"
              >
                {Constants.String.VALUE_OFFER_DISCOUNT[language]}
              </Col>
              <Col
                xs="auto"
                className="fs-5 text-green"
              >
                {`\u20B9 ${valueDiscount}`}
              </Col>
            </Row>
            )
          }
        </Col>
        <Col
          xs={24}
          className="d-flex justify-content-end"
        >
          <Button
            variant="link"
            className="w-100 py-3 r__action_confirm_one"
            onClick={toggleDialog}
          >
            {Constants.String.OKAY[language].toUpperCase()}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

DiscountBreakup.propTypes = {
  language: PropTypes.string.isRequired,
  couponDiscount: PropTypes.number.isRequired,
  valueDiscount: PropTypes.number.isRequired,
  toggleDialog: PropTypes.func.isRequired,
};

export default DiscountBreakup;
