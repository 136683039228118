import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    style={{ width }}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g stroke={stroke}>
        <g>
          <g>
            <g>
              <path d="M8 2.5c3.314 0 6 2.239 6 5s-2.686 5-6 5c-.275 0-.545-.015-.81-.045L3.5 13.5l.997-1.94C2.985 10.652 2 9.172 2 7.5c0-2.761 2.686-5 6-5z" transform="translate(-834 -784) translate(540 615) translate(51 169) translate(243)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
