import React from 'react';
import { Svg } from 'app/components/common';
import { Constants } from 'app/utilities';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import style from './index.module.scss';
import RightCircularArrow from '../../Utils/cart-offers/RightCircularArrow';
import Cart from '../../Cart';

interface RootState {
  main: any,
}

function CartBar(props: any) {
  const { cartDetails } = useSelector<RootState, any>((state) => state.main);

  const onClickCartBar = () => {
    const { history, isMobile, toggleSideOverlay } = props;
    if (isMobile) {
      history.push('/cart');
      return;
    }
    toggleSideOverlay(Cart);
  };

  return (
    <Button className={style.cartBar} onClick={onClickCartBar}>
      <div className="d-flex align-items-center">
        <Svg
          svg="cart" // @ts-ignore
          fill="none"
          stroke={Constants.Color.white}
          width="24px"
        />
      </div>
      <div className="flex-grow-1">
        <div className="fs-7 text-white text-truncate-1 mb-1 mb-lg-0 text-align-start">
          {cartDetails?.store?.name}
        </div>
        <div className="d-flex font-weight-black gap-10 align-items-center">
          <div className="d-inline-block">{`₹${cartDetails?.totalBill}`}</div>
          <div className={`fs-7 bg-green-dark border-radius-4 p-1 ${cartDetails?.totalSavings > 0 ? 'd-inline-block' : 'd-none'}`}>
            {`₹${cartDetails?.totalSavings} Saved`}
          </div>
        </div>
        <div className="fs-6 text-align-start">
          {`${Number(cartDetails?.cartProductsCount) + Number(cartDetails?.cartCustomProductsCount)} items`}
        </div>
      </div>
      <div className="d-flex fs-6 font-weight-black text-white">
        {'checkout'.toUpperCase()}
        &nbsp;&nbsp;&nbsp;
        <RightCircularArrow />
      </div>
    </Button>
  );
}

export default CartBar;
