import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components/common';
import { Constants } from 'app/utilities';
import {
  Container, Col, Button,
} from 'react-bootstrap';
import '../../styles/stores/store.scss';

function DownloadAppStrip(props) {
  const { language, closeStrip } = props;

  return (
    <Container className="d-flex download-strip px-2 py-2">
      <div className="ml-2 px-0">
        <Svg
          svg="stripScooter"
          width="16px"
          fill={Constants.Color.yellow}
        />
      </div>
      <Col className="pb-1">
        <div className="font-weight-bold text-white strip-font">
          {Constants.String.ORDER_FASTER[language]}
        </div>
        <div className="text-white strip-font">
          {Constants.String.LOVELOCAL_APP[language]}
        </div>
      </Col>
      <div
        className="download-button px-4 py-1 mx-2 my-0"
      >
        <a
          href="https://lovelocal.sng.link/Bg5ou/495t/j5yu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="text-black font-weight-bold py-0 h-100 d-flex align-items-center strip-font">
            {Constants.String.GET_THE_APP[language]}
          </span>
        </a>
      </div>
      <div className="justify-content-end mx-0">
        <Button
          variant="link"
          onClick={() => {
            closeStrip();
          }}
        >
          <Svg
            svg="circleClose"
            width={16}
            pathFill={Constants.Color.primary}
            circleFill={Constants.Color.grayLighter}
          />
        </Button>
      </div>
    </Container>
  );
}
DownloadAppStrip.propTypes = {
  language: PropTypes.string,
  closeStrip: PropTypes.func,

};
DownloadAppStrip.defaultProps = {
  language: '',
  closeStrip: () => {},

};

export default DownloadAppStrip;
