export default function valueUnlockingStripEL(targetElement, remove = false) {
  const absoluteElement = document.getElementById('customer-support-value-strip');
  const handle = (event) => {
    const {
      offsetHeight, scrollTop, scrollHeight,
    } = event.target;
    if (((scrollHeight - scrollTop) - offsetHeight) <= 180) {
      absoluteElement.classList.add('hidden');
    } else {
      absoluteElement.classList.remove('hidden');
    }
  };
  // initially show the elmt - while landing/leaving from page,
  // later on scroll add listener
  if (remove) {
    absoluteElement.classList.remove('hidden');
    if (targetElement) { targetElement.removeEventListener('scroll', handle); }
  } else {
    absoluteElement.classList.remove('hidden');
    if (targetElement) { targetElement.addEventListener('scroll', handle); }
  }
}
