import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 26 26"
  >
    <path
      fill={fill}
      d="M13 3.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S3.25 18.385 3.25 13 7.615 3.25 13 3.25zm0 1.625c-4.487 0-8.125 3.638-8.125 8.125S8.513 21.125 13 21.125s8.125-3.638 8.125-8.125S17.487 4.875 13 4.875zm.816 3.25v5.164l3.246 1.655-.79 1.306-4.084-2.081V8.125h1.628z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
