import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 64 64"
  >
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g stroke={stroke} strokeWidth={4}>
        <g>
          <g>
            <g transform="translate(-264 -1190) translate(-56 1076) translate(320 114)">
              <circle cx="32" cy="32" r="24" />
            </g>
            <g>
              <path d="M20 8l-8 8m8 8l-8-8" transform="translate(-264 -1190) translate(-56 1076) translate(320 114) translate(16 16) matrix(-1 0 0 1 32 0)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
};

export default Svg;
