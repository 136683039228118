import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const StoreShimmer = () => (
  <Container>
    <Row>
      <Col
        xs={24}
        className="py-3 animate shimmer"
      />
    </Row>

    <Row
      className="mt-6 align-items-center"
    >
      <Col
        xs="auto"
        className="store-shimmer-profile animate shimmer"
      />
      <Col
        className="ml-3"
      >
        <div className="py-3 w-50 animate shimmer" />
        <div className="mt-2 py-3 w-25 animate shimmer" />
        <div className="mt-3 py-3 w-75 animate shimmer" />
      </Col>
    </Row>

    <Row>
      <Col
        xs={24}
        className="mt-6 py-3 animate shimmer"
      />
    </Row>

    <Row>
      <Col
        xs={24}
        className="mt-6 product-card animate shimmer"
      />
    </Row>

    <Row
      className="mt-9"
    >
      <Col
        xs={7}
        className="mx-2 py-6 animate shimmer"
      />
      <Col
        xs={7}
        className="mx-2 py-6 animate shimmer"
      />
      <Col
        xs={6}
        className="mx-2 py-6 animate shimmer"
      />
    </Row>

    <Row>
      <Col
        xs={24}
        className="mt-6 product-card animate shimmer"
      />
    </Row>

    <Row>
      <Col
        xs={24}
        className="mt-6 footer-card animate shimmer"
      />
    </Row>
  </Container>
);

export default StoreShimmer;
