import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import CustomImage from 'app/components/common/CustomImage';
import { getCDNImageUrl } from 'app/utilities/Utils';
import Events from 'clevertap/Event';
import { SHOP_FRONT } from 'app/events/screens';
import { logCTEvent } from 'app/events/Events';
import { Constants } from 'app/utilities';

function CategoryFilters({
  categories, changeStoreState,
  selectedCategory, language,
}) {
  return (
    <Row
      className="py-5 align-items-center border shadow"
    >
      <Col
        xs={24}
        className="font-weight-semi-black fs-5 mb-2"
      >
        {`${Constants.String.SHOP_CATEGORIES[language]}:`}
      </Col>
      <Col
        xs={24}
        className="px-0"
      >
        {
          categories && categories.count > 0 ? (
            categories.data.map((item, index) => (
              <Row
                className={`px-4 mx-0 align-items-center py-2 cursor-pointer ${(index + 1) < categories.count ? 'border-bottom' : ''}
                  ${selectedCategory && selectedCategory.id === item.id ? 'store-selected-category-bg' : ''}`}
                onClick={() => {
                  logCTEvent(
                    Events.Category_Clicked,
                    {
                      Page: SHOP_FRONT,
                      'Category name': item.name,
                    },
                  );
                  changeStoreState(
                    'CATEGORY_SEE_ALL',
                    {
                      code: 'category',
                      ...item,
                    },
                  );
                }}
              >
                <Col
                  xs={8}
                  className="px-0"
                >
                  <CustomImage
                    imageStyle="w-100"
                    imageUrl={getCDNImageUrl(item.image)}
                    imageType="CATEGORY"
                    imageAlt={item.name}
                    imageTitle={item.name}
                  />
                </Col>
                <Col
                  xs={16}
                  className="px-2 font-weight-semi-black flex-grow-1 fs-5"
                >
                  {item.name}
                </Col>
              </Row>
            ))
          ) : null
        }
      </Col>
    </Row>
  );
}

CategoryFilters.propTypes = {
  categories: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    count: PropTypes.number,
  }).isRequired,
  changeStoreState: PropTypes.func.isRequired,
  selectedCategory: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default CategoryFilters;
