import React from 'react';

const Svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11" fill="#19199D" stroke="white" strokeWidth="2" />
    <path d="M9.74998 16.0833C9.4979 16.0833 9.28202 15.9935 9.10236 15.8138C8.92269 15.6342 8.83301 15.4185 8.83331 15.1667V10.5833C8.83331 10.3313 8.92315 10.1154 9.10281 9.93572C9.28248 9.75605 9.4982 9.66637 9.74998 9.66668H10.2083V8.75001C10.2083 8.11598 10.4318 7.57545 10.8789 7.12843C11.3259 6.6814 11.8663 6.45804 12.5 6.45834C13.134 6.45834 13.6745 6.68186 14.1216 7.12889C14.5686 7.57591 14.792 8.11629 14.7916 8.75001V9.66668H15.25C15.5021 9.66668 15.7179 9.75651 15.8976 9.93618C16.0773 10.1158 16.167 10.3316 16.1666 10.5833V15.1667C16.1666 15.4188 16.0768 15.6346 15.8971 15.8143C15.7175 15.994 15.5018 16.0836 15.25 16.0833H9.74998ZM12.5 13.7917C12.7521 13.7917 12.9679 13.7018 13.1476 13.5222C13.3273 13.3425 13.417 13.1268 13.4166 12.875C13.4166 12.6229 13.3268 12.4071 13.1471 12.2274C12.9675 12.0477 12.7518 11.958 12.5 11.9583C12.2479 11.9583 12.032 12.0482 11.8524 12.2278C11.6727 12.4075 11.583 12.6232 11.5833 12.875C11.5833 13.1271 11.6731 13.343 11.8528 13.5226C12.0325 13.7023 12.2482 13.792 12.5 13.7917ZM11.125 9.66668H13.875V8.75001C13.875 8.36807 13.7413 8.04341 13.4739 7.77605C13.2066 7.50869 12.8819 7.37501 12.5 7.37501C12.118 7.37501 11.7934 7.50869 11.526 7.77605C11.2587 8.04341 11.125 8.36807 11.125 8.75001V9.66668Z" fill="white" />
  </svg>
);

export default Svg;
