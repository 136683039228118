import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { gaLogAddOrRemoveProductFromCart } from 'ecommerce_ga_events/EcommerceGAEvents';
import * as LogClevertapEvent from '../../../../clevertap/LogEvent';
import { Constants } from '../../../utilities';
import Svg from '../../common/svg/ArrowDown';
import Variants from './Variants';
import RoundedCartCounter from '../cart/RoundedCartCounter';

class SellingStore extends React.Component {
  constructor() {
    super();
    this.state = {
      addVariants: false,
    };
  }

  toggleAddVariants = () => {
    const { addVariants } = this.state;
    this.setState({
      addVariants: !addVariants,
    });
  }

  render() {
    const { addVariants } = this.state;
    const {
      sellingStore, product, cartItems, language, isMobile,
      toggleSideOverlay, isOfferSellingStores, onSuccess, onClose,
      isAppExOffer, isTopSavingOffer, isStoreProduct,
    } = this.props;
    const {
      allVariants, appPrice, sellingPrice, mrp, store,
    } = sellingStore;
    const customisable = !!allVariants.length;
    let quantity = 0;
    if (cartItems) {
      if (customisable) {
        for (let i = 0; i < allVariants.length; i += 1) {
          const cartItem = cartItems[allVariants[i].retailerProductId];
          if (cartItem
          && (Number(cartItem.storeId) === Number(store.id))
          ) {
            quantity += cartItem.quantity;
          }
        }
      } else {
        const cartItem = cartItems[product.retailerProductId];
        if (cartItem
          && (Number(cartItem.storeId) === Number(store.id))) {
          quantity = cartItem.quantity;
        }
      }
    }
    return (
      <>
        {
          (!isMobile && customisable) && (
          <Variants
            isMobile={isMobile}
            show={addVariants}
            product={product}
            sellingStore={sellingStore}
            onClose={this.toggleAddVariants}
            language={language}
          />
          )
        }
        <Row
          className="mx-0"
        >
          <Col
            className="pl-0 pr-4"
          >
            {
              (
                !!sellingStore.displayTag
              ) && (
              <span
                className="px-3 py-1 fs-6 bg-green text-white border-radius-3 text-center"
              >
                <b>
                  {
                    `${sellingStore.displayTag}`
                  }
                </b>
              </span>
              )
            }
            {
              appPrice > 0 && (
              <div
                className="text-primary"
              >
                <b>
                  {
                    `${Constants.String.OFFER_PRICE[language]}: \u20B9 ${appPrice}`
                  }
                </b>
              </div>
              )
            }
            <div
              className="pb-1 pt-1"
            >
              <b
                className="text-primary fs-5"
              >
                {`\u20B9 ${sellingPrice}`}
              </b>
              {
                (mrp > 0 && mrp > sellingPrice) && (
                  <span
                    className="m-0 fs-5 pl-2 text-secondary"
                  >
                    <del>
                      {`\u20B9 ${mrp}`}
                    </del>
                  </span>
                )
              }
              {
                (isOfferSellingStores || isStoreProduct)
                && sellingStore.unitString
                && (
                  <span
                    className="pl-2 fs-5 text-black-64"
                  >
                    {`${sellingStore.unitString}`}
                  </span>
                )
              }
            </div>
            {
              !(isOfferSellingStores || isStoreProduct) && (
                <div
                  className="pb-1 d-flex align-items-center"
                >
                  <div
                    className="text-secondary"
                  >
                    {sellingStore.unitString}
                  </div>
                  {
                    customisable && (
                      <div
                        className="pl-1"
                      >
                        <Svg
                          svg="arrowDown"
                          width="1.2rem"
                          stroke={Constants.Color.primary}
                        />
                      </div>
                    )
                  }
                </div>
              )
            }
            <div
              className={`${
                isOfferSellingStores ? '' : 'text-secondary'
              } ${isStoreProduct ? 'd-none' : ''}`}
            >
              {store.displayName}
            </div>
          </Col>
          <Col
            xs="auto"
            className="px-0"
          >
            <div
              className="d-flex justify-content-center"
            >
              <RoundedCartCounter
                language={language}
                productInfo={{
                  storeName: store.displayName,
                  productName: product.displayName,
                  sellingPrice,
                  mrp,
                  imageUrl: product.image,
                  measurementDisplayText: sellingStore.unitString,
                }}
                storeCode={store.id}
                productId={product.retailerProductId}
                quantity={quantity}
                onSuccess={(productCount, isAdding) => {
                  LogClevertapEvent.skusUpdatedToCart(
                    store.displayName,
                    product.displayName,
                    productCount,
                    product.categories,
                  );
                  gaLogAddOrRemoveProductFromCart(
                    isAdding,
                    sellingPrice,
                    {
                      item_id: product.id,
                      item_name: product.displayName,
                      price: sellingPrice,
                      quantity: productCount,
                    },
                  );
                  onSuccess(productCount);
                }}
                customisable={customisable}
                toggleAddVariants={() => {
                  if (isMobile) {
                    toggleSideOverlay(() => (
                      <Variants
                        show
                        product={product}
                        sellingStore={sellingStore}
                        onClose={() => {
                          toggleSideOverlay(null);
                        }}
                        language={language}
                        isMobile={isMobile}
                      />
                    ));
                    return;
                  }
                  this.toggleAddVariants();
                }}
                onClose={onClose}
                isOfferSellingStores={isOfferSellingStores}
                isAppExOffer={isAppExOffer}
                isTopSavingOffer={isTopSavingOffer}
                page="Monthly Samaan"
              />
            </div>
            {customisable && (
            <div
              className="pt-1 fs-6 text-center"
            >
              {Constants.String.CUSTOMISABLE[language]}
            </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

SellingStore.propTypes = {
  sellingStore: PropTypes.shape({
    store: PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
    }),
    sellingPrice: PropTypes.number,
    mrp: PropTypes.number,
    appPrice: PropTypes.number,
    allVariants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        retailerProductId: PropTypes.string,
      }),
    ),
    unitString: PropTypes.string,
    displayTag: PropTypes.string,
  }).isRequired,
  cartItems: PropTypes.shape({}),
  product: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    retailerProductId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    displayName: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    image: PropTypes.string,
  }).isRequired,
  language: PropTypes.string.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOfferSellingStores: PropTypes.bool,
  isAppExOffer: PropTypes.bool,
  isTopSavingOffer: PropTypes.bool,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isStoreProduct: PropTypes.bool,
};

SellingStore.defaultProps = {
  cartItems: null,
  onSuccess: () => {},
  onClose: () => {},
  isOfferSellingStores: false,
  isAppExOffer: false,
  isTopSavingOffer: false,
  isStoreProduct: false,
};

export default SellingStore;
