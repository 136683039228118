import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  width,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 73 72"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#1877F2">
        <path d="M63.35 36.165C63.35 21.162 51.195 9 36.2 9S9.05 21.162 9.05 36.165c0 13.559 9.928 24.797 22.908 26.835V44.017h-6.894v-7.852h6.894V30.18c0-6.808 4.053-10.569 10.255-10.569 2.97 0 6.077.53 6.077.53v6.686h-3.423c-3.373 0-4.425 2.094-4.425 4.242v5.096h7.53l-1.204 7.852h-6.326V63c12.98-2.038 22.908-13.276 22.908-26.835" transform="translate(-99 -563) translate(0 482) translate(0 57) translate(99 24)" />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  width: PropTypes.number.isRequired,
};

export default Svg;
