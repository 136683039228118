import React from 'react';
import PropTypes from 'prop-types';
import Events from 'clevertap/Event';
import CustomImage from 'app/components/common/CustomImage';
import { Svg } from 'app/components/common';
import { logCTEvent } from 'app/events/Events';
import { Constants } from 'app/utilities';
import { getCDNImageUrl } from 'app/utilities/Utils';
import { SHOP_FRONT } from 'app/events/screens';

function TopCategorySectionTitle({
  productCategory, changeStoreState, isMobile, storeId, history,
  scrollMainContainerToTop, storeName, showSeeAll, language,
}) {
  return (
    <div
      className="d-flex px-6 py-4 align-items-center font-weight-black"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${productCategory.topColor} 0%, ${productCategory.bottomColor} 100%)`,
      }}
    >
      <div>
        <CustomImage
          imageStyle="top-category-image"
          imageUrl={getCDNImageUrl(productCategory.image)}
          imageType="CATEGORY"
          imageAlt={productCategory.name}
          imageTitle={productCategory.name}
        />
      </div>
      <div className="flex-grow-1 px-3 fs-3">
        {productCategory.name}
      </div>
      {
        showSeeAll ? (
          <div>
            <button
              type="button"
              className="r__title_link-btn"
              onClick={() => {
                logCTEvent(
                  Events.See_All_Brand_Category_Clicked,
                  {
                    'Store Name': storeName,
                    'EP Code': storeId,
                    Page: SHOP_FRONT,
                    'Category Type': productCategory.name,
                  },
                );
                if (isMobile && history) {
                  history.push({
                    pathname: `/shops/category-products/${storeId}`,
                    state: {
                      sname: storeName,
                      productCategory: {
                        code: 'brand',
                        ...productCategory,
                      },
                    },
                  });
                } else {
                  if (scrollMainContainerToTop) {
                    scrollMainContainerToTop();
                  }
                  changeStoreState(
                    'CATEGORY_SEE_ALL',
                    {
                      code: 'brand',
                      ...productCategory,
                    },
                  );
                }
              }}
            >
              {Constants.String.SEE_ALL[language]}
              <Svg
                svg="arrowRightNew"
                stroke={Constants.Color.primary}
                width="18px"
              />
            </button>
          </div>
        ) : null
      }
    </div>
  );
}

TopCategorySectionTitle.propTypes = {
  productCategory: PropTypes.shape({
    topColor: PropTypes.string,
    bottomColor: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  changeStoreState: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  storeId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  scrollMainContainerToTop: PropTypes.func.isRequired,
  storeName: PropTypes.string.isRequired,
  showSeeAll: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

export default TopCategorySectionTitle;
