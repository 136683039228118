import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { Constants } from '../../utilities';

export default function Placeholder(props) {
  const {
    handleRetry, language, imageSrc, heading, subHeading,
  } = props;
  return (
    <Row
      className="text-center mx-0 justify-content-center p-6 w-100"
    >
      {
        imageSrc
          ? (
            <Col
              xs={24}
              className="mb-4"
            >
              <img
                alt=""
                src={imageSrc}
                className="error-placeholder"
              />
            </Col>
          ) : ''
      }
      {
        heading !== ''
        || subHeading !== ''
          ? (
            <Col
              xs={24}
              className="mb-4"
            >
              <div
                className="mw-400p text-center mx-auto"
              >
                <div
                  className="font-weight-bold"
                >
                  {heading}
                </div>
                <div>
                  {subHeading}
                </div>
              </div>
            </Col>
          ) : ''
      }
      <Col
        xs={24}
        className={handleRetry ? 'text-center' : 'd-none'}
      >
        <Button
          variant="outline-primary"
          onClick={() => {
            handleRetry();
          }}
        >
          {Constants.String.RETRY[language]}
        </Button>
      </Col>
    </Row>
  );
}

Placeholder.propTypes = {
  handleRetry: PropTypes.func,
  language: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
};

Placeholder.defaultProps = {
  handleRetry: null,
  imageSrc: null,
  heading: '',
  subHeading: '',
};
