import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  cartProductActions, CART_PRODUCT_REQUEST,
} from 'app/store/cart/actions';
import { Constants } from '../../utilities';
import CustomImage from '../../components/common/CustomImage';
import { getCDNImageUrl } from '../../utilities/Utils';

function CartFreeSample(props) {
  const {
    product, isCheckout, reorder,
    language, outOfStock, freeProductType,
  } = props;

  let tag = {
    title: '',
    bgColor: '',
  };

  let productName = '';
  let productUnitString = '';

  if (freeProductType === 'SPEND_OFFER') {
    productName = product.productInfo.productName;
    productUnitString = product.productInfo.measurementDisplayText;
  } else {
    productName = product.name;
    productUnitString = product.measurementDisplayText;
  }

  switch (product.tag) {
    case 'NEWLY ADDED':
      tag = {
        title: Constants.String.SUGGESTED_PRODUCT[language].toUpperCase(),
        bgColor: 'bg-green-light',
        textColor: 'text-green',
      };
      break;
    case 'QUANTITY_CHANGED':
      tag = {
        title: Constants.String.UPDATED_QUANTITY[language].toUpperCase(),
        bgColor: 'bg-yellow-light',
        textColor: 'text-black',
      };
      break;
    case 'PRICE_CHANGED':
      tag = {
        title: Constants.String.UPDATED_PRICE[language].toUpperCase(),
        bgColor: 'bg-yellow-light',
        textColor: 'text-black',
      };
      break;
    default: break;
  }

  return (
    <Col
      id="cart-product"
      xs={24}
      lg={reorder ? 12 : 24}
      className={`p-2 my-2 gradient-green ${isCheckout ? 'fs-6' : ''}`}
    >
      <Row
        className={`mx-0 d-flex flex-nowrap ${reorder ? 'border p-3' : ''}`}
      >
        <Col
          xs="auto"
          className="px-0"
        >
          <div
            className="cart-product-image"
          >
            <CustomImage
              imageUrl={getCDNImageUrl(product.imageUrl || product.image)}
              imageType="PRODUCT"
              imageStyle="mh-100 mw-100"
              imageAlt={productName}
              imageTitle={productName}
            />
          </div>
        </Col>
        <Col
          xs="auto"
          className="px-2 flex-grow-1 flex-shrink-1 fs-5"
        >
          {
            (tag.title || outOfStock) && (
              <Row
                className="mx-0 mb-1"
              >
                <Col
                  xs="auto"
                  className="px-4"
                >
                  <span
                    className={`bg-primary py-1 fs-7 px-2 border-radius-4 font-weight-bold ${
                      outOfStock ? 'bg-danger-light text-danger' : `${tag.bgColor} ${tag.textColor}`
                    }`}
                  >
                    {outOfStock ? Constants.String.OUT_OF_STOCK[language].toUpperCase() : tag.title}
                  </span>
                </Col>
              </Row>
            )
          }
          <Row
            className="mx-0 text-truncate-1 word-break"
            title={productName}
          >
            <b>{productName}</b>
          </Row>
          <Row
            className="mx-0"
          >
            <span
              className="text-medium mr-2 word-break text-truncate-1"
            >
              {productUnitString || '1 Unit'}
            </span>
          </Row>
          <Row
            className="mx-0 fs-6"
          >
            {freeProductType === 'FREE_SAMPLE' ? (
              <span className="text-green-dark">
                <b>FREE Sample</b>
                {' '}
                with this order
              </span>
            ) : (
              <span className="text-green-dark">
                <b>FREE</b>
                {' '}
                with spends over
                {' '}
                <b>{`\u20B9${product.minimumSpendAmount}`}</b>
              </span>
            )}
          </Row>
        </Col>
        <Col
          xs="auto"
        >
          {/* Product Name + Delete Icon (Cart) */}
          <Row
            className="pb-1"
          >
            <b>
              <span
                className="mr-2 text-green-dark font-weight-black"
              >
                {`${Constants.String.FREE[language]}!`.toUpperCase()}
              </span>
            </b>
          </Row>
          {
            freeProductType === 'SPEND_OFFER'
            && product.productInfo.mrp && (
              <Row>
                <span
                  className="text-medium text-strike-through"
                >
                  <del>
                    {`\u20B9 ${Math.ceil(product.productInfo.mrp)}`}
                  </del>
                </span>
              </Row>
            )
          }
        </Col>
      </Row>
    </Col>
  );
}

const mapDispatchToProps = (dispatch) => ({
  cartProductReq: (
    storeCode, productId, quantity, onSuccess, onFailure, productInfo, forceAdd, actionType,
  ) => {
    dispatch(
      cartProductActions.request(
        storeCode, productId, quantity, onSuccess, onFailure, productInfo, forceAdd, actionType,
      ),
    );
  },
});

const mapStateToProps = (state) => ({
  processing: (
    state.main.requestsProcessing[CART_PRODUCT_REQUEST]
  ),
});

CartFreeSample.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    retailerProductId: PropTypes.number,
    imageUrl: PropTypes.string,
    mrp: PropTypes.number,
    quantity: PropTypes.number,
    price: PropTypes.number,
    tag: PropTypes.string,
    measurementDisplayText: PropTypes.string,
    freeSample: PropTypes.bool,
    minimumSpendAmount: PropTypes.number,
    image: PropTypes.string,
    productInfo: {
      productName: PropTypes.string,
      measurementDisplayText: PropTypes.string,
    },
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isCheckout: PropTypes.bool,
  // enableTags: PropTypes.bool,
  language: PropTypes.string.isRequired,
  outOfStock: PropTypes.bool,
  processing: PropTypes.shape({}).isRequired,
  reorder: PropTypes.bool,
  requestsProcessing: PropTypes.shape({}),
  freeProductType: PropTypes.string.isRequired,
};

CartFreeSample.defaultProps = {
  isCheckout: false,
  // enableTags: false,
  outOfStock: false,
  requestsProcessing: null,
  reorder: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartFreeSample);
