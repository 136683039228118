import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  cartLoadActions, cartProductActions, setCartItems,
} from 'app/store/cart/actions';
import cartApis from 'api/cart';
import { Constants } from 'app/utilities';
import { isGuestUser } from 'app/utilities/Utils';
import Toast from '../../HOC/Toast';
import CustomModal from './CustomModal';

const MultiStoreModal = (props) => {
  const {
    toggleMultiStoreModal, showMultiStoreCheckoutDialog,
    addToCart, product, productInfo, showToast, cartId,
    multiStoreCheckoutErrorMsg, language, cartLoadRequest,
    cartItems, setUpdatedCartItems,
  } = props;
  const closeModalData = {
    product: {},
    productInfo: {},
    productId: '',
    errorMsg: '',
  };
  const [settingCart, setIsSettingCart] = useState(false);
  const guestUser = isGuestUser();
  const lang = language || 'en';
  const successCallback = () => ({
    type: 'sync',
    callback: () => {
      setIsSettingCart(false);
      toggleMultiStoreModal(closeModalData);
      if (!guestUser) {
        cartLoadRequest();
      }
    },
  }
  );

  const onFailure = {
    type: 'sync',
    callback: (data) => {
      setIsSettingCart(false);
      if (data.error) {
        showToast(data.error);
      }
    },
  };

  const cancelAddFromOtherStore = () => {
    //
    toggleMultiStoreModal(closeModalData);
  };

  const resetCartAndAddProduct = () => {
    setIsSettingCart(true);
    const addToCartRequest = () => {
      addToCart(
        product.storeId,
        product.productId,
        product.quantity,
        successCallback(),
        onFailure,
        productInfo,
        true,
        'ADD',
      );
    };
    if (guestUser) {
      const newCartItems = cartItems;
      if (cartItems && Object.keys(cartItems).length) {
        Object.keys(cartItems).forEach((keyId) => {
          delete newCartItems[keyId];
        });
      }
      setUpdatedCartItems(newCartItems);
      addToCartRequest();
      return;
    }
    cartApis.cartReset(cartId, {}).then(() => {
      addToCartRequest();
    }).catch(() => {
      showToast(Constants.String.OOPS[lang]);
    });
  };

  const body = (
    <Col
      className="bg-white p-5 r__modalBody-round"
    >
      <Row>
        <Col
          xs="auto"
          className="d-flex r__action_copy"
        >
          {// eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: multiStoreCheckoutErrorMsg }} />
          }
        </Col>
      </Row>
      <Row
        className="pt-3 d-flex justify-content-center"
      >
        <Col
          xs="auto"
          className="d-flex p-2"
        >
          <Button
            variant="primary"
            className="w-100 py-1 border-radius-8 r__action_confirm"
            style={{ width: '220px', height: '48px' }}
            onClick={() => {
              cancelAddFromOtherStore();
            }}
          >
            CANCEL
          </Button>
        </Col>
        <Col
          xs="auto"
          className="d-flex p-2"
        >
          <Button
            variant="outline-primary"
            className="w-100 py-1 border-radius-8 r__action_cancel"
            style={{ width: '220px', height: '48px' }}
            onClick={() => {
              resetCartAndAddProduct();
            }}
            disabled={settingCart}
          >
            PROCEED
          </Button>
        </Col>
      </Row>
    </Col>
  );

  return (
    <Container
      fluid
      className="modal-content"
    >
      <CustomModal
        show={showMultiStoreCheckoutDialog}
        title="Clear items in cart?"
        onHide={cancelAddFromOtherStore}
        closeButton
        body={body}
        showTitleImage
        titleImage="cartDanger"
        customBackground
        backdrop
        isRound
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  showMultiStoreCheckoutDialog: state.main.showMultiStoreCheckoutDialog,
  product: state.main.product,
  productInfo: state.main.productInfo,
  multiStoreCheckoutErrorMsg: state.main.multiStoreCheckoutErrorMsg,
  cartDetails: state.main.cartDetails,
  cartItems: state.main.cartItems,
  cartId: state.main.cartId,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (
    storeCode, productId, quantity, onSuccess, onFailure, productInfo, forceAdd, actionType,
  ) => {
    dispatch(cartProductActions.request(
      storeCode, productId, quantity, onSuccess, onFailure, productInfo, forceAdd, actionType,
    ));
  },
  cartLoadRequest: (params, onSuccess, onFailure) => {
    dispatch(cartLoadActions.loadRequest(onSuccess, onFailure, params));
  },
  setUpdatedCartItems: (cartItems) => {
    dispatch(setCartItems(cartItems));
  },
});

MultiStoreModal.propTypes = {
  addToCart: PropTypes.func.isRequired,
  cartLoadRequest: PropTypes.func.isRequired,
  cartId: PropTypes.string,
  language: PropTypes.string,
  toggleMultiStoreModal: PropTypes.func.isRequired,
  setUpdatedCartItems: PropTypes.func.isRequired,
  showMultiStoreCheckoutDialog: PropTypes.bool.isRequired,
  showToast: PropTypes.func.isRequired,
  cartItems: PropTypes.shape({}),
  product: PropTypes.shape({
    storeId: PropTypes.string,
    productId: PropTypes.number,
    quantity: PropTypes.number,
  }).isRequired,
  productInfo: PropTypes.shape({
    mrp: PropTypes.number,
    productName: PropTypes.string,
    sellingPrice: PropTypes.number,
    imageUrl: PropTypes.string,
  }),
  multiStoreCheckoutErrorMsg: PropTypes.string.isRequired,
};

MultiStoreModal.defaultProps = {
  productInfo: {},
  cartId: null,
  language: 'en',
  cartItems: {},
};

export default Toast(connect(mapStateToProps, mapDispatchToProps)(MultiStoreModal));
