import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 16 16"
  >
    <defs>
      <filter id="pbj2u1diaa">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#pbj2u1diaa)" transform="translate(-884 -2971)">
        <g>
          <path fill={fill} fillRule="nonzero" d="M16 1.872c-.595.261-1.229.434-1.89.518.68-.406 1.199-1.044 1.443-1.813-.634.378-1.334.645-2.08.794-.602-.64-1.46-1.038-2.396-1.038-1.816 0-3.278 1.474-3.278 3.281 0 .26.022.51.076.748-2.727-.133-5.14-1.44-6.761-3.43-.283.49-.449 1.052-.449 1.657 0 1.136.585 2.143 1.457 2.726-.527-.01-1.044-.163-1.482-.404v.036c0 1.594 1.137 2.918 2.628 3.223-.267.073-.558.108-.86.108-.21 0-.422-.012-.621-.056.425 1.3 1.631 2.254 3.065 2.285-1.116.873-2.533 1.4-4.067 1.4-.269 0-.527-.013-.785-.046 1.453.937 3.175 1.472 5.032 1.472 6.036 0 9.336-5 9.336-9.334 0-.145-.005-.285-.012-.424.651-.462 1.198-1.039 1.644-1.703z" transform="translate(884 2971)" />
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
