import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  fill, width, stroke, height,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.433 8.92h-2.132a1.732 1.732 0 0 1 .676.943h1.456v1.352h-1.456a2.39 2.39 0 0 1-.578 1.014c-.282.299-.646.54-1.092.722.151.064.292.151.422.26.135.108.252.24.351.396L15.277 17h-1.761c-.33 0-.568-.126-.715-.377l-1.794-2.893a.745.745 0 0 0-.247-.247.796.796 0 0 0-.39-.078H9.09v-1.293h1.312c.447 0 .804-.082 1.073-.247.273-.165.47-.381.591-.65H9.09V9.863h3.016a1.29 1.29 0 0 0-.585-.65c-.277-.16-.646-.24-1.105-.24H9.09V7.567h6.344v1.353z"
      fill={fill}
    />
    <path
      d="M12 5V2M12 22v-3M7.05 7.05 4.93 4.93M19.071 19.071 16.95 16.95M5 12H2M22 12h-3M7.05 16.95 4.93 19.07M19.071 4.929 16.95 7.05"
      stroke={stroke}
      strokeWidth="1.4"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
};

export default Svg;
