import * as React from 'react';

const UnlockIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1433 11.5603H9.23041V8.59662C9.23133 7.80527 9.53813 7.04829 10.0802 6.49946C10.6223 5.95073 11.3532 5.65721 12.1052 5.68635C13.6039 5.7444 14.7687 7.08894 14.7687 8.66628V8.89426C14.7687 9.19505 14.9213 9.47309 15.169 9.62356C15.4167 9.77402 15.7221 9.77402 15.9698 9.62356C16.2175 9.47311 16.3702 9.19505 16.3702 8.89426V8.59662C16.3689 7.3914 15.9182 6.23483 15.1149 5.37537C14.3115 4.51591 13.2196 4.02238 12.0738 4.00061C9.62948 3.95828 7.62913 6.11857 7.62913 8.68975V11.5603H6.85672C6.62948 11.5603 6.41157 11.6552 6.25092 11.8242C6.09027 11.9931 6 12.2223 6 12.4613V20.099C5.99977 20.338 6.08993 20.5673 6.25069 20.7363C6.41145 20.9053 6.62948 21.0002 6.85672 21H17.1433C17.3705 21 17.5884 20.9051 17.7491 20.7361C17.9097 20.567 18 20.3378 18 20.099V12.4613C17.9998 12.2224 17.9094 11.9934 17.7489 11.8244C17.5882 11.6556 17.3704 11.5605 17.1433 11.5603ZM12.9447 18.8771C12.9504 18.9517 12.926 19.0255 12.8776 19.0804C12.8292 19.1353 12.7611 19.1664 12.6899 19.1662H11.3087C11.2375 19.1664 11.1696 19.1352 11.1215 19.0803C11.0733 19.0253 11.0491 18.9516 11.0551 18.8771L11.2139 16.8546C10.9219 16.6225 10.7363 16.2718 10.7025 15.8885C10.6688 15.5052 10.79 15.1246 11.0365 14.84C11.2829 14.5555 11.632 14.3931 11.998 14.3928C12.364 14.3924 12.7132 14.5542 12.9601 14.8384C13.207 15.1225 13.3289 15.5028 13.2957 15.8862C13.2626 16.2696 13.0775 16.6206 12.7859 16.8532L12.9447 18.8771Z"
      fill="black"
    />
    <path
      d="M18 8.5L21 6M18.5 11H22.5"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default UnlockIcon;
