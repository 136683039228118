import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl } = apiUrls();
const referralsApis = {
  referrals: (cancelToken, params = {}) => {
    const referral = `${newOOBaseUrl}coupon/customer/referrals`;
    return Axios({
      url: referral,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },
};
export default referralsApis;
