import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { convert24HrTo12Hr } from '../../utilities/Utils';
import { Constants } from '../../utilities';

function StoreFAQs(props) {
  const {
    storeDetails, language,
  } = props;
  return (
    <Container>
      <Row
        className="pb-2"
      >
        <Col
          xs={24}
          className="text-center font-weight-black fs-3 pb-2"
        >
          {Constants.String.SHOP_FAQ_DESC[language].replace(/{shopName}/g, storeDetails.store_name)}
        </Col>
      </Row>
      <Row
        className="bg-white text-black mb-4 mx-4 py-4 border-radius-16 shadow-sm"
      >
        <Col
          xs={24}
          className="fs-4 mb-2 font-weight-bold"
        >
          {`How can I find ${storeDetails.store_name}?`}
        </Col>
        <Col
          xs={24}
          className="fs-4 mb-4"
        >
          {`You can find ${storeDetails.store_name} at ${storeDetails.completeAddress}`}
        </Col>
      </Row>
      <Row
        className="bg-white text-black mb-4 mx-4 py-4 border-radius-16 shadow-sm"
      >
        <Col
          xs={24}
          className="fs-4 mb-2 font-weight-bold"
        >
          {`What is the Phone number for ${storeDetails.store_name}?`}
        </Col>
        <Col
          xs={24}
          className="fs-4 mb-4"
        >
          {`You can contact ${storeDetails.store_name} at ${storeDetails.phone_number}.`}
        </Col>
      </Row>
      <Row
        className="bg-white text-black mb-4 mx-4 py-4 border-radius-16 shadow-sm"
      >
        <Col
          xs={24}
          className="fs-4 mb-2 font-weight-bold"
        >
          {`Does the ${storeDetails.store_name} provide home delivery service?`}
        </Col>
        <Col
          xs={24}
          className="fs-4 mb-4"
        >
          {
            storeDetails.pickup_only_allowed ? (
              <>
                {`No. ${storeDetails.store_name} provides only pick up services.`}
              </>
            ) : (
              <>
                {`Yes. ${storeDetails.store_name} provides both home delivery and pick up services.`}
              </>
            )
          }
        </Col>
      </Row>
      {
        storeDetails.locality && (
          <Row
            className="bg-white text-black mb-4 mx-4 py-4 border-radius-16 shadow-sm"
          >
            <Col
              xs={24}
              className="fs-4 mb-2 font-weight-bold"
            >
              {`What is the nearest landmark to ${storeDetails.store_name}?`}
            </Col>
            <Col
              xs={24}
              className="fs-4 mb-4"
            >
              {`You can find ${storeDetails.store_name} near ${storeDetails.locality}.`}
            </Col>
          </Row>
        )
      }
      <Row
        className="bg-white text-black mb-4 mx-4 py-4 border-radius-16 shadow-sm"
      >
        <Col
          xs={24}
          className="fs-4 mb-2 font-weight-bold"
        >
          {`What are hours of operation for ${storeDetails.store_name}?`}
        </Col>
        <Col
          xs={24}
          className="fs-4 mb-4"
        >
          {`${storeDetails.store_name} operates from Mon to Sun,
          ${convert24HrTo12Hr(storeDetails.general_timings.opening_time)} to
          ${convert24HrTo12Hr(storeDetails.general_timings.closing_time)}`}
        </Col>
      </Row>
      <Row
        className="bg-white text-black mb-4 mx-4 py-4 border-radius-16 shadow-sm"
      >
        <Col
          xs={24}
          className="fs-4 mb-2 font-weight-bold"
        >
          What are the various modes of payment accepted by the shop ?
        </Col>
        <Col
          xs={24}
          className="fs-4 mb-3"
        >
          {`${storeDetails.store_name} accepts payments in ${storeDetails.payment_mode.reduce((acc, cMode) => (
            cMode.isEnabled ? [...acc, cMode.name] : acc
          ), []).join(', ')}`}
        </Col>
        <Col
          xs={24}
          className="fs-4 mb-4"
        >
          {`You can pay in cash or via ${storeDetails.payment_mode.reduce((acc, cMode) => (
            cMode.isEnabled ? [...acc, cMode.name] : acc
          ), []).join(', ')}`}
        </Col>
      </Row>
    </Container>
  );
}

StoreFAQs.propTypes = {
  storeDetails: PropTypes.shape({
    completeAddress: PropTypes.string,
    pickup_only_allowed: PropTypes.bool,
    store_name: PropTypes.string,
    payment_mode: PropTypes.arrayOf(PropTypes.shape({})),
    locality: PropTypes.string,
    landmark: PropTypes.string,
    general_timings: PropTypes.shape({
      opening_time: PropTypes.string,
      closing_time: PropTypes.string,
    }),
    phone_number: PropTypes.string.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default StoreFAQs;
