import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl } = apiUrls();
const homeNewApis = {
  homeNew: (cancelToken, params = {}) => {
    const home = `${newOOBaseUrl}customer/home`;
    return Axios({
      url: home,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },
};
export default homeNewApis;
