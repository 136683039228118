import { homePageStrings } from './language/homepage';

export const BannerConstants = (language:string) => {
  const translate = (string:string) => (
    homePageStrings[string][language]
  );

  return ([
    {
      id: 1,
      img: '/Assets/no-fee.png',
      title: translate('NO_REGISTRATION_FEE'),
    },
    {
      id: 2,
      img: '/Assets/no-margin.png',
      title: translate('NO_MARGINS'),
    },
    {
      id: 3,
      img: '/Assets/new-customers.png',
      title: translate('NEW_CUSTOMERS'),
    },
    {
      id: 4,
      img: '/Assets/exciting-offers.png',
      title: translate('EXCITING_OFFERS'),
    },
    {
      id: 5,
      img: '/Assets/easy-catalogue.png',
      title: translate('EASY_CATALOGUE'),
    },
    {
      id: 6,
      img: '/Assets/customer-service.png',
      title: translate('CUSTOMER_SERVICE'),
    },
  ]);
};

export const ourRetailersWords = [
  {
    id: 1,
    image: '/Assets/about-us/ll-partner-01.jpg',
  },
  {
    id: 2,
    image: '/Assets/about-us/ll-partner-02.jpg',
  },
  {
    id: 3,
    image: '/Assets/about-us/ll-partner-03.jpg',
  },
  {
    id: 4,
    image: '/Assets/about-us/ll-partner-04.jpg',
  },
  {
    id: 5,
    image: '/Assets/about-us/ll-partner-05.jpg',
  },
  {
    id: 6,
    image: '/Assets/about-us/ll-partner-06.jpg',
  },
  {
    id: 7,
    image: '/Assets/about-us/ll-partner-07.jpg',
  },
];

export const partnerBanners = [
  {
    id: 1,
    image: '/Assets/partner-banner/banner1.jpg',
  },
  {
    id: 2,
    image: '/Assets/partner-banner/banner2.jpg',
  },
  {
    id: 3,
    image: '/Assets/partner-banner/banner3.jpg',
  },
  {
    id: 4,
    image: '/Assets/partner-banner/banner4.jpg',
  },
];

export const ourCustomersWords = [
  {
    id: 1,
    image: '/Assets/about-us/ll-cust-01.jpg',
  },
  {
    id: 2,
    image: '/Assets/about-us/ll-cust-02.jpg',
  },
  {
    id: 3,
    image: '/Assets/about-us/ll-cust-03.jpg',
  },
  {
    id: 4,
    image: '/Assets/about-us/ll-cust-04.jpg',
  },
  {
    id: 5,
    image: '/Assets/about-us/ll-cust-05.jpg',
  },
  {
    id: 6,
    image: '/Assets/about-us/ll-cust-06.jpg',
  },
];

export const partnerUs = (partnerUsLanguage:string) => {
  const translate = (string:string) => (
    homePageStrings[string][partnerUsLanguage]
  );
  return ([
    {
      id: 1,
      image: '/Assets/download_icon.png',
      desc: translate('DOWNLOAD_APP'),
    },
    {
      id: 2,
      image: '/Assets/textfield_icon.png',
      desc: translate('ENTER_PHONE_NUMBER'),
    },
    {
      id: 3,
      image: '/Assets/pancard_icon.png',
      desc: translate('ENTER_PERSONAL_DETAILS'),
    },
    {
      id: 4,
      image: '/Assets/team_icon.png',
      desc: translate('TEAM_WILL_REVIEW'),
    },
  ]);
};

export const products = (productsLanguage:string) => {
  const translate = (string:string) => (
    homePageStrings[string][productsLanguage]
  );
  return ([
    {
      id: 1,
      image: '/Assets/products/grains.png',
      title: translate('GRAINS_OILS'),
      desc: translate('GRAINS_OILS_DESC'),
      link: 'https://www.lovelocal.in/pc/grains-oil-and-masala',
    },
    {
      id: 6,
      image: '/Assets/products/personal-care.png',
      title: translate('PERSONAL_CARE'),
      desc: translate('PERSONAL_CARE_DESC'),
      link: 'https://www.lovelocal.in/pc/personal-care',
    },
    {
      id: 8,
      image: '/Assets/products/medicine.png',
      title: translate('HEALTH_MEDICINE'),
      desc: translate('HEALTH_MEDICINE_DESC'),
      link: 'https://www.lovelocal.in/pc/health-and-medicine',
    },
    {
      id: 10,
      image: '/Assets/products/fruits.png',
      title: translate('FRUITS_VEGETABLES'),
      desc: translate('FRUITS_VEGETABLES_DESC'),
      link: 'https://www.lovelocal.in/pc/fruits-and-vegetables',
    },
    {
      id: 9,
      image: '/Assets/products/dairy.png',
      title: translate('DAIRY'),
      desc: translate('DAIRY_DESC'),
      link: 'https://www.lovelocal.in/pc/dairy',
    },
    {
      id: 3,
      image: '/Assets/products/seafood.png',
      title: translate('MEAT_SEAFOOD'),
      desc: translate('MEAT_SEAFOOD_DESC'),
      link: 'https://www.lovelocal.in/pc/meat-and-seafood',
    },
    {
      id: 7,
      image: '/Assets/products/baby-care.png',
      title: translate('BABY_CARE'),
      desc: translate('BABY_CARE_DESC'),
      link: 'https://www.lovelocal.in/pc/maternity-and-baby-care',
    },
    {
      id: 4,
      image: '/Assets/products/cleaning.png',
      title: translate('HOUSEHOLD_CLEANING'),
      desc: translate('HOUSEHOLD_CLEANING_DESC'),
      link: 'https://www.lovelocal.in/pc/household-and-cleaning',
    },
    {
      id: 12,
      image: '/Assets/products/snacks.png',
      title: translate('CHOC_SNACKS'),
      desc: translate('CHOC_SNACKS_DESC'),
      link: 'https://www.lovelocal.in/pc/chocolates-snacks-and-namkeen',
    },
    {
      id: 14,
      image: '/Assets/products/food.png',
      title: translate('FOOD_BEVERAGE'),
      desc: translate('FOOD_BEVERAGE_DESC'),
      link: 'https://www.lovelocal.in/pc/food-and-beverages',
    },
    {
      id: 13,
      image: '/Assets/products/ready.png',
      title: translate('READY_TO_EAT'),
      desc: translate('READY_TO_EAT_DESC'),
      link: 'https://www.lovelocal.in/pc/ready-to-eat-instant-and-frozen-food',
    },
    {
      id: 2,
      image: '/Assets/products/dryfruits.png',
      title: translate('DRY_FRUITS'),
      desc: translate('DRY_FRUITS_DESC'),
      link: 'https://www.lovelocal.in/pc/dry-fruits-mithai-and-sweets',
    },
    {
      id: 17,
      image: '/Assets/products/bakery.png',
      title: translate('BAKERY'),
      desc: translate('BAKERY_DESC'),
      link: 'https://www.lovelocal.in/pc/bakery-baking-and-eggs',
    },
    {
      id: 11,
      image: '/Assets/products/pet-care.png',
      title: translate('PET_CARE'),
      desc: translate('PET_CARE_DESC'),
      link: 'https://www.lovelocal.in/pc/pet-supplies',
    },
    {
      id: 5,
      image: '/Assets/products/beverages.png',
      title: translate('BEVERAGES'),
      desc: translate('BEVERAGES_DESC'),
      link: 'https://www.lovelocal.in/pc/food-and-beverages',
    },
    {
      id: 15,
      image: '/Assets/products/stationery.png',
      title: translate('STATIONERY'),
      desc: translate('STATIONERY_DESC'),
      link: 'https://www.lovelocal.in/pc/stationary',
    },
    // {
    //   id: 16,
    //   image: '/Assets/products/grocery.png',
    //   title: 'Medicines',
    //   desc: 'Medicines to always keep one healhty',
    // },
  ]);
};

export const social = (socialLanguage:string) => {
  const translate = (string:string) => (
    homePageStrings[string][socialLanguage]
  );

  return ([
    {
      id: 1,
      image: '/Assets/youtube_logo.png',
      title: translate('YOUTUBE'),
      desc: translate('YOUTUBE_DESC'),
      hrefLink: 'https://www.youtube.com/channel/UCqs6-xP0gZZZBtaED7DssZg',
    },
    {
      id: 2,
      image: '/Assets/facebook_logo.png',
      title: translate('FACEBOOK'),
      desc: translate('FACEBOOK_DESC'),
      hrefLink: 'https://www.facebook.com/lovelocaldukaan/',
    },
    {
      id: 3,
      image: '/Assets/instagram_logo.png',
      title: translate('INSTAGRAM'),
      desc: translate('INSTAGRAM_DESC'),
      hrefLink: 'https://www.instagram.com/lovelocalindia/',
    },
  ]);
};

export const languageConstant = [
  {
    id: 1,
    language: 'English',
    code: 'en',
    title: 'English',
  },
  {
    id: 2,
    language: 'Hindi',
    code: 'hi',
    title: 'हिंदी',
  },
  // {
  //   id: 3,
  //   language: 'Marati',
  //   code: 'hi',
  //   title: 'हिंदी',
  // },
];

export const cardResponsiveBreakpoints = [
  {
    breakpoint: 1800,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1500,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 900,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 625,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

// export default BannerConstants;
