import React from 'react';
import style from './index.module.scss';

type colorType = 'violet' | 'purple';
type themeType = 'regular' | 'rounded';
type backgroundType = 'white' | 'solid' | 'solidBorder';

interface buttonProps{
    color?: colorType;
    label?: string;
    theme?: themeType;
    background?: backgroundType;
    children?: any;
    onClick?: () => void;
    fullWidth?: boolean;
  }

const Button = ({
  label,
  children,
  color = 'purple',
  theme = 'regular',
  background = 'white',
  fullWidth = false,
  onClick,
}:buttonProps) => {
  let txtColor = '';
  if (color === 'purple') {
    txtColor = '#bf008a';
  } else {
    txtColor = '#2b2ba0';
  }

  let buttonStyle:any;

  if (background === 'white') {
    buttonStyle = {
      ...buttonStyle,
      background: '#fff',
      color: txtColor,
    };
  } else if (background === 'solid') {
    buttonStyle = {
      ...buttonStyle,
      background: txtColor,
      color: '#fff',
    };
  } else if (background === 'solidBorder') {
    buttonStyle = {
      ...buttonStyle,
      background: '#fff',
      color: txtColor,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: txtColor,
    };
  }

  if (fullWidth) {
    buttonStyle = {
      ...buttonStyle,
      width: '100%',
    };
  }

  return (
    <button
      type="button"
      className={`${style[theme]}`}
      style={
        buttonStyle
    }
      onClick={onClick}
    >

      {children || label}

    </button>
  );
};

export default Button;
