import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl, OOBaseUrl } = apiUrls();

const offerApis = {
  storeOffers: (storeCode = '', params = {}, cancelToken) => {
    const storeOffer = `${newOOBaseUrl}offers/store/${storeCode}`;
    return Axios({
      url: storeOffer,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },

  cartCoupons: (storeCode = '', cartId, params = {}, cancelToken) => {
    const cartCoupons = `${newOOBaseUrl}coupon/customer/order`;
    return Axios({
      url: cartCoupons,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams({
        ...params,
        storeCode,
        cartId,
      }),
      cancelToken,
    });
  },

  cartRewards: (params = {}, cancelToken, nextUrl = '') => {
    const cartScratch = `${newOOBaseUrl}${nextUrl || 'scratch-card'}`;
    return Axios({
      url: cartScratch,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },

  generateScratchCard: (orderId, params = {}, cancelToken) => {
    const generateScratchCard = `${newOOBaseUrl}order/${orderId || ''}/customer/scratch-card`;
    return Axios({
      url: generateScratchCard,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },

  revealScratchCard: (scratchCardId, params = {}, cancelToken) => {
    const revelScratchCard = `${newOOBaseUrl}scratch-card/reveal`;
    return Axios({
      url: revelScratchCard,
      method: 'PATCH',
      data: {
        scratchCardId,
      },
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },

  gotoItemProducts: (cancelToken, params = {}) => {
    const gotoitems = `${OOBaseUrl}homedelivery/v1/gotoitemproduct/`;
    return Axios({
      url: gotoitems,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },

  getMaxSavingsOffers: (cancelToken, params = {}) => {
    const maxSavings = `${OOBaseUrl}homedelivery/v1/topsavingoffercategory/`;
    return Axios({
      url: maxSavings,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },

  getBogoAndComboOffers: (cancelToken, params = {}) => {
    const bogoOffer = `${OOBaseUrl}homedelivery/v1/specialoffers/`;
    return Axios({
      url: bogoOffer,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },

};

export default offerApis;
