import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  fill, stroke, strokeWidth, width,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.762 14.104c-.92 4.057-4.59 6.896-8.75 6.896H3v-9.012c0-4.16 2.84-7.83 6.896-8.75 6.6-1.494 12.36 4.267 10.866 10.866zM10.545 9.676H6.563v7.761h7.76v-3.982h-3.778V9.676z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  strokeWidth: PropTypes.string,
  width: PropTypes.string.isRequired,
};

Svg.defaultProps = {
  strokeWidth: '1px',
};

export default Svg;
