import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <circle cx={8.5} cy={8} r={4} stroke="#fff" />
    <path stroke="#fff" d="M8.5 4V2M8.5 14v-2M4.5 8h-2M14.5 8h-2" />
    <circle cx={8.5} cy={8} r={2} fill="#fff" />
  </svg>
);

Svg.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Svg;
