import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M12 21c4.97 0 9-4.03 9-9s-4.03-9-9-9-9 4.03-9 9c0 1.147.44 2.712 1.32 4.695L3 21l4.328-1.306C9.302 20.564 10.859 21 12 21zm4.5-6.762v1.355c0 .254-.106.497-.293.67-.188.171-.44.256-.694.233-1.392-.15-2.73-.626-3.905-1.386-1.093-.694-2.02-1.619-2.715-2.71-.765-1.178-1.24-2.52-1.39-3.915-.022-.253.062-.503.234-.69.17-.188.413-.295.667-.295h1.358c.454-.004.841.328.905.777.057.433.163.86.317 1.269.124.33.044.702-.204.953l-.575.573c.644 1.131 1.582 2.067 2.715 2.71l.575-.573c.251-.248.624-.328.955-.204.41.153.837.26 1.271.316.455.065.79.459.779.917z"
    />
  </svg>

);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
