import * as React from 'react';

const HomeDeliveryLocked = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1432_2883)">
      <path
        d="M3.5 5.23607L5.11803 2H16.882L18.5 5.23607V20H3.5V5.23607Z"
        fill="black"
        stroke="black"
        strokeWidth={2}
      />
      <path
        d="M3.5 0.5H3.19098L3.05279 0.776393L1.05279 4.77639L1 4.88197V5V21V21.5H1.5H16.5H17V21V5.06155L17.9851 1.12127L18.1404 0.5H17.5H3.5Z"
        fill="black"
        stroke="#F9ED32"
      />
      <path d="M3.5 5L14.5 5" stroke="#F9ED32" strokeLinecap="round" />
      <path
        d="M13 13C13 15.5361 11.1609 17.5 9 17.5C6.83911 17.5 5 15.5361 5 13C5 10.4639 6.83911 8.5 9 8.5C11.1609 8.5 13 10.4639 13 13Z"
        stroke="#F9ED32"
        strokeLinecap="round"
      />
      <circle
        cx={17.5}
        cy={17}
        r={6}
        fill="#F9ED32"
        stroke="black"
        strokeWidth={2}
      />
      <path
        d="M17.5 15V19M15.5 17H19.5"
        stroke="black"
        strokeWidth={2}
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_1432_2883">
        <rect width={24} height={24} fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default HomeDeliveryLocked;
