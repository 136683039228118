import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke="#000"
      d="M7.676 1.42 1.343 13.293A1.5 1.5 0 0 0 2.667 15.5h12.666a1.5 1.5 0 0 0 1.324-2.206L10.323 1.42C9.76.36 8.241.36 7.676 1.42Z"
    />
    <path
      fill="#000"
      d="M9.803 4.986v3.146c0 .338-.02.673-.06 1.007-.04.33-.094.667-.16 1.012H8.428c-.066-.345-.12-.682-.16-1.012-.04-.334-.06-.67-.06-1.007V4.986h1.595Zm-1.782 7.15c0-.131.024-.254.071-.368a.993.993 0 0 1 .204-.297.963.963 0 0 1 .308-.198.949.949 0 0 1 .385-.077c.136 0 .262.026.38.077.117.048.22.114.308.198a.875.875 0 0 1 .203.297.923.923 0 0 1 0 .742.877.877 0 0 1-.204.297.967.967 0 0 1-.687.27c-.14 0-.268-.024-.385-.072a.967.967 0 0 1-.308-.198.996.996 0 0 1-.204-.296.984.984 0 0 1-.071-.374Z"
    />
  </svg>
);

Svg.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

Svg.defaultProps = {
  height: 16,
  width: 18,
};

export default Svg;
