import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 20.888 18.892"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_1999" data-name="Rectangle 1999" width="18.888" height="18.892" fill={fill} />
      </clipPath>
    </defs>
    <g id="Shop" transform="translate(2647.484 -4360.574)">
      <g id="Group_8331" data-name="Group 8331" transform="translate(-2647.484 4360.574)">
        <g id="Group_8330" data-name="Group 8330" transform="translate(0 0)" clipPath="url(#clip-path)">
          <path id="Path_4192" data-name="Path 4192" d="M551.906,549.4l-4.33-4.33a8.328,8.328,0,0,1-2.5,2.5l4.33,4.33a1.769,1.769,0,0,0,2.5-2.5Zm0,0" transform="translate(-533.551 -533.551)" fill={fill} />
        </g>
      </g>
      <path id="Path_4193" data-name="Path 4193" d="M309.155,302.078a7.077,7.077,0,1,0-7.077,7.077,7.078,7.078,0,0,0,7.077-7.077Zm-7.077,5.308a5.308,5.308,0,1,1,5.308-5.308,5.314,5.314,0,0,1-5.308,5.308Zm0,0" transform="translate(-2942.484 4065.574)" fill={fill} />
      <path id="Path_4194" data-name="Path 4194" d="M359,363.129h1.18a2.952,2.952,0,0,1,2.949-2.949V359A4.134,4.134,0,0,0,359,363.129Zm0,0" transform="translate(-3003.535 4004.523)" fill={fill} />
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
