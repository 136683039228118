import * as React from 'react';

const RightCircularArrow = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22ZM13.566 7.934C13.4159 7.78389 13.2123 7.69955 13 7.69955C12.7877 7.69955 12.5841 7.78389 12.434 7.934C12.2839 8.08411 12.1996 8.28771 12.1996 8.5C12.1996 8.71229 12.2839 8.91589 12.434 9.066L14.57 11.2H7.5C7.28783 11.2 7.08434 11.2843 6.93431 11.4343C6.78429 11.5843 6.7 11.7878 6.7 12C6.7 12.2122 6.78429 12.4157 6.93431 12.5657C7.08434 12.7157 7.28783 12.8 7.5 12.8H14.569L12.434 14.934C12.2839 15.0841 12.1996 15.2877 12.1996 15.5C12.1996 15.7123 12.2839 15.9159 12.434 16.066C12.5841 16.2161 12.7877 16.3004 13 16.3004C13.2123 16.3004 13.4159 16.2161 13.566 16.066L17.066 12.566C17.1404 12.4917 17.1994 12.4035 17.2396 12.3064C17.2799 12.2092 17.3006 12.1051 17.3006 12C17.3006 11.8949 17.2799 11.7908 17.2396 11.6936C17.1994 11.5965 17.1404 11.5083 17.066 11.434L13.566 7.934Z"
      fill="white"
    />
  </svg>
);
export default RightCircularArrow;
