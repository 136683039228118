import React from 'react';
import {
  Button, Container, Row, Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { gaLogSignUpClicked } from 'ecommerce_ga_events/EcommerceGAEvents';
import { Svg } from '../../components/common';
import * as Actions from '../../store/Actions';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import { Constants } from '../../utilities';

const LoginAlert = (props) => {
  const {
    toggleLogin, language, screen,
  } = props;

  return (
    <Container
      className="h-100 bg-white d-flex text-center justify-content-center align-items-center py-4"
    >
      <Row>
        <Col
          xs={24}
        >
          <Svg
            svg="profileIconOval"
            fill1={Constants.Color.pinkLight}
            fill2={Constants.Color.pink}
            width="120px"
          />
        </Col>
        <Col
          xs="auto"
          className="pt-4 fs-3 mx-auto width240p"
        >
          {Constants.String.SHOP_FROM_YOUR_LOCAL_SHOPS[language]}
        </Col>
        <Col
          xs={24}
          className="pt-2"
        >
          <Button
            variant="primary"
            className="width240p"
            onClick={() => {
              LogClevertapEvent.userLoginClicked({
                SCREEN: screen,
              });
              gaLogSignUpClicked();
              toggleLogin();
            }}
          >
            {Constants.String.LOG_IN[language]}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleLogin: () => {
    dispatch(Actions.toggleLogin());
  },
});

LoginAlert.propTypes = {
  toggleLogin: PropTypes.func.isRequired,
  language: PropTypes.func.isRequired,
  screen: PropTypes.string.isRequired,
};

export default connect(null, mapDispatchToProps)(LoginAlert);
