import React from 'react';

const Svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11" fill="white" stroke="white" strokeWidth="2" />
    <path d="M6 11.5556L10.2857 16L18 8" stroke="#38B823" strokeWidth="3" />
  </svg>
);

export default Svg;
