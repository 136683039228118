import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { gaLogAddOrRemoveProductFromCart } from 'ecommerce_ga_events/EcommerceGAEvents';
import CartCounterBorderNone from '../cart/CartCounterBorderNone';
import { getCDNImageUrl, getSeoProductUrl } from '../../../utilities/Utils';
import * as LogClevertapEvent from '../../../../clevertap/LogEvent';
import { Constants } from '../../../utilities';
import CustomImage from '../../common/CustomImage';
import RoundedCartCounter from '../cart/RoundedCartCounter';

const isMobile = window.screen.width < 992;

const RepeatProduct = (props) => {
  const {
    item, cartItems, language, history,
    isStoreAggregatedView, b2bStorePreview,
  } = props;
  let quantity = 0;

  const product = {
    displayName: item.displayName,
    image: item.image,
    mrp: isStoreAggregatedView ? item.mrp : item.sellingStores[0].mrp,
    id: item.retailerProductId,
    sellingPrice: isStoreAggregatedView ? item.sellingPrice : item.sellingStores[0].sellingPrice,
    storeId: isStoreAggregatedView ? item.storeId : item.sellingStores[0].store.id,
    storeName: isStoreAggregatedView ? item.displayName : item.sellingStores[0].store.displayName,
    unitString: isStoreAggregatedView ? item.unitString : item.sellingStores[0].unitString,
  };

  const cartProduct = cartItems && cartItems[product.id];
  if (cartProduct && cartProduct.storeId === product.storeId) {
    quantity = cartProduct.quantity;
  }

  const {
    pathName, state, searchParams,
  } = getSeoProductUrl(product.id, product.displayName, product.storeId);

  const logProductAddOrRemoveToCart = (productCount, isAdding) => {
    LogClevertapEvent.previouslyOrderedProductAddedToCart(
      product.displayName,
      product.mrp,
      product.mrp - product.sellingPrice,
      productCount,
    );
    gaLogAddOrRemoveProductFromCart(
      isAdding,
      product.sellingPrice,
      {
        item_id: product.id,
        item_name: product.displayName,
        price: product.sellingPrice,
        quantity: productCount,
      },
    );
  };

  return (
    <Col
      xs={`${isMobile ? 24 : 12}`}
      className={`${isMobile ? 'h-100 px-0' : 'py-4'} ${b2bStorePreview
        ? 'pointer-event-none' : ''}`}
    >
      <div
        className="h-100 w-100 border-radius-8 p-4 border"
      >
        <Row
          className="mx-0"
        >
          <Col
            xs={6}
            className={`${isMobile ? 'd-none' : 'pl-0'}`}
          >
            <Row
              as={item.curated ? Link : 'div'}
              to={{ pathname: pathName, state, search: searchParams }}
              className={`mx-0 ${item.curated ? 'link-tag' : 'cursor-pointer'}`}
              onClick={() => {
                if (!item.curated) {
                  history.push({
                    pathname: pathName,
                    state,
                    search: searchParams,
                  });
                }
              }}
            >
              <div
                className="border border-radius-4"
              >
                <CustomImage
                  imageAlt=""
                  imageUrl={getCDNImageUrl(product.image)}
                  imageStyle={`mw-100 mh-100 ${isMobile ? 'p-0' : 'p-3'}`}
                  imageType="PRODUCT"
                />
              </div>
            </Row>
          </Col>
          <Col
            xs={24}
            lg={18}
            className="p-1"
          >
            <div
              className={`text-medium ${product.mrp === -1
                || product.mrp === product.sellingPrice
                ? 'bg-white text-white' : 'd-inline-block mb-2 px-2 fs-6 font-weight-bold bg-green text-white rounded'}`}
            >
              <b>
                {`${Constants.String.SAVE[language].toUpperCase()}  \u20B9 ${
                  product.mrp - product.sellingPrice
                }`}
              </b>
            </div>
            <div
              className="fs-5 font-weight-bold text-wrap text-truncate-2"
              title={product.displayName}
            >
              {product.displayName}
            </div>
            <div
              className="mt-2 fs-5 d-flex flex-direction-row flex-wrap"
            >
              <span
                className="font-weight-bold"
              >
                {`\u20B9 ${product.sellingPrice}`}
              </span>
              {
                product.mrp !== -1
                && product.mrp > product.sellingPrice
                && (
                  <span
                    className="text-medium"
                  >
                    &nbsp;&nbsp;
                    <del>
                      {`\u20B9 ${product.mrp}`}
                    </del>
                  </span>
                )
              }
              {
                product.unitString && (
                  <span
                    className="text-medium pl-3"
                  >
                    · &nbsp;
                    {`${product.unitString}`}
                  </span>
                )
              }
              <span
                className="ml-auto cart-width"
              >
                {
                  isMobile ? (
                    <CartCounterBorderNone
                      productInfo={{
                        storeName: product.storeName,
                        productName: product.displayName,
                        sellingPrice: product.sellingPrice,
                        mrp: product.mrp,
                        imageUrl: product.image,
                        measurementDisplayText: product.unitString,
                      }}
                      storeCode={product.storeId}
                      productId={product.id}
                      quantity={quantity}
                      onSuccess={(productCount, isAdding) => {
                        logProductAddOrRemoveToCart(productCount, isAdding);
                      }}
                      shadow
                    />
                  ) : (
                    <RoundedCartCounter
                      language={language}
                      productInfo={{
                        storeName: product.storeName,
                        productName: product.displayName,
                        sellingPrice: product.sellingPrice,
                        mrp: product.mrp,
                        imageUrl: product.image,
                        measurementDisplayText: product.unitString,
                      }}
                      storeCode={product.storeId}
                      productId={product.id}
                      quantity={quantity}
                      onSuccess={(productCount, isAdding) => {
                        logProductAddOrRemoveToCart(productCount, isAdding);
                      }}
                    />
                  )
                }
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

RepeatProduct.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    retailerProductId: PropTypes.number,
    displayName: PropTypes.string,
    image: PropTypes.string,
    unit: PropTypes.number,
    uom: PropTypes.string,
    sellingStores: PropTypes.arrayOf(PropTypes.shape({
      mrp: PropTypes.number,
      appPrice: PropTypes.number,
      sellingPrice: PropTypes.number,
      store: PropTypes.shape({
        displayName: PropTypes.string,
        id: PropTypes.string,
      }),
      unitString: PropTypes.string,
    })),
    discount: PropTypes.number,
    mrp: PropTypes.number,
    productId: PropTypes.number,
    sellingPrice: PropTypes.number,
    storeId: PropTypes.string,
    unitString: PropTypes.string,
    curated: PropTypes.bool,
    displayTag: PropTypes.string,
    manufacturerId: PropTypes.string,
    brandId: PropTypes.number,
  }).isRequired,
  cartItems: PropTypes.shape({}),
  language: PropTypes.string.isRequired,
  isStoreAggregatedView: PropTypes.bool,
  b2bStorePreview: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

RepeatProduct.defaultProps = {
  cartItems: null,
  isStoreAggregatedView: false,
  b2bStorePreview: false,
};

export default RepeatProduct;
