import { singularSdk, SingularConfig } from 'singular-sdk';

const SDK_KEY = 'mpaani_570d2d55';
const SDK_SECRET = '69068f02cd6dc2b54c7c46a8b7e0322d';
const PRODUCT_ID = process.env.REACT_APP_ENV === 'production' ? 'in.lovelocal' : 'in.lovelocal-staging';

export const initSingular = () => {
  const config = new SingularConfig(
    SDK_KEY,
    SDK_SECRET,
    PRODUCT_ID,
  );
  singularSdk.init(config);
};

export const singularLogin = (
  userId = `Guest-${new Date().getTime()}`,
) => {
  singularSdk.login(userId);
};

export const singularLogout = () => {
  singularSdk.logout();
};

export function singularSignupClicked() {
  singularSdk.event(
    'Sign_up_clicked',
  );
}

export const singularRegistrationComplete = (
  phone, email = 'marketing@mpaani.com',
) => {
  singularSdk.event(
    'Registration_Completed',
    {
      phone,
      email,
    },
  );
};

export const singularLogProductList = (
  products = [],
) => {
  singularSdk.event(
    'Product_List_Impression',
    {
      products,
    },
  );
};

export const singularLogProductDetails = (
  product = {},
) => {
  singularSdk.event(
    'Product_List_Impression',
    {
      product,
    },
  );
};

export const singularAddOrRemoveProductFromCart = (
  isAdding, productValue, cartProduct,
) => {
  singularSdk.event(
    isAdding ? 'Add_To_Cart' : 'Remover_From_Cart',
    {
      currency: 'INR',
      value: productValue,
      cartProduct,
    },
  );
};

export const singularBeginCheckout = (
  totalBill, cartProducts = [],
) => {
  singularSdk.event(
    'Begin_Checkout',
    {
      currency: 'INR',
      value: totalBill,
      items: cartProducts,
    },
  );
};

export const singularLogPayment = (
  totalBill, coupon, paymentMode, cartProducts,
) => {
  singularSdk.event(
    'Payment_Info',
    {
      currency: 'INR',
      value: totalBill,
      coupon: coupon || '',
      payment_type: paymentMode,
      items: cartProducts,
    },
  );
};

export const singularLogShippingInfo = (
  totalBill, coupon, shippingMode, cartProducts,
) => {
  singularSdk.event(
    'Shipping_Info',
    {
      currency: 'INR',
      value: totalBill,
      coupon: coupon || '',
      shipping_tier: shippingMode,
      items: cartProducts,
    },
  );
};

export const singularPurchase = (
  purchaseValue, phone, email,
) => {
  singularSdk.revenue(
    'Item_Purchased',
    'INR',
    purchaseValue,
    {
      phone,
      email,
    },
  );
};
