// Brand Products Load Actions
export const REFERRALS_LOAD_REQUEST = 'REFERRALS_LOAD_REQUEST';
export const REFERRALS_LOAD_SUCCESS = 'REFERRALS_LOAD_SUCCESS';
export const REFERRALS_LOAD_FAILURE = 'REFERRALS_LOAD_FAILURE';
export const referralsLoadActions = {
  loadRequest: (
    cancelToken, params,
  ) => ({
    type: REFERRALS_LOAD_REQUEST,
    payload: {
      cancelToken,
      params,
    },
  }),
  loadSuccess: (data) => ({
    type: REFERRALS_LOAD_SUCCESS,
    payload: {
      referrals: data.res,
    },
  }),
  loadFailure: () => ({
    type: REFERRALS_LOAD_FAILURE,
  }),
};
