import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill1, fill2, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={fill1}
      fillRule="evenodd"
      d="M19.2 17.2V8h1.6v10.8H6v-1.6h13.2z"
      clipRule="evenodd"
    />
    <path
      fill={fill2}
      fillOpacity=".72"
      fillRule="evenodd"
      d="M19.2 17.2V8h1.6v10.8H6v-1.6h13.2z"
      clipRule="evenodd"
    />
    <path
      fill={fill1}
      fillRule="evenodd"
      d="M3.2 5.2h15.6v11.6H3.2V5.2zm1.6 1.6v8.4h12.4V6.8H4.8z"
      clipRule="evenodd"
    />
    <path
      fill={fill2}
      fillOpacity=".72"
      fillRule="evenodd"
      d="M3.2 5.2h15.6v11.6H3.2V5.2zm1.6 1.6v8.4h12.4V6.8H4.8z"
      clipRule="evenodd"
    />
    <path
      fill={fill1}
      fillRule="evenodd"
      d="M11 9.8c-.662 0-1.2.537-1.2 1.2 0 .663.537 1.2 1.2 1.2.663 0 1.2-.537 1.2-1.2 0-.663-.537-1.2-1.2-1.2zM8.2 11c0-1.546 1.254-2.8 2.8-2.8 1.547 0 2.8 1.254 2.8 2.8 0 1.546-1.253 2.8-2.8 2.8-1.546 0-2.8-1.254-2.8-2.8z"
      clipRule="evenodd"
    />
    <path
      fill={fill2}
      fillOpacity=".72"
      fillRule="evenodd"
      d="M11 9.8c-.662 0-1.2.537-1.2 1.2 0 .663.537 1.2 1.2 1.2.663 0 1.2-.537 1.2-1.2 0-.663-.537-1.2-1.2-1.2zM8.2 11c0-1.546 1.254-2.8 2.8-2.8 1.547 0 2.8 1.254 2.8 2.8 0 1.546-1.253 2.8-2.8 2.8-1.546 0-2.8-1.254-2.8-2.8z"
      clipRule="evenodd"
    />
  </svg>
);

Svg.propTypes = {
  fill1: PropTypes.string.isRequired,
  fill2: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
