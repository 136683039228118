import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <g id="Frames-24px">
      <rect fill="none" width={width} />
    </g>
    <g id="Solid">
      <g>
        <path
          fill={fill}
          d="M22,10h-3.42l-3.712-6.497l-1.736,0.992L16.277,10H7.723l3.145-5.504L9.132,3.504L5.42,10H2v2h1.198
           l1.826,8.217C5.125,20.675,5.531,21,6,21h12c0.469,0,0.875-0.326,0.976-0.783L20.802,12H22V10z M17.198,19H6.802l-1.556-7h13.507
           L17.198,19z"
        />
        <rect x="11" y="13" fill={fill} width="2" height="5" />
        <rect x="14" y="13" fill={fill} width="2" height="5" />
        <rect x="8" y="13" fill={fill} width="2" height="5" />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
