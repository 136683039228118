import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  tailFill, width, height, rectFill,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 57 30"
  >
    <path
      fill={tailFill}
      d="M0 10h6v20l-6-5.5V10z"
    />
    <path
      fill={rectFill}
      fillRule="evenodd"
      d="M45 0H0v24h57l-6-12 6-12H45z"
      clipRule="evenodd"
    />
  </svg>
);

Svg.propTypes = {
  tailFill: PropTypes.string.isRequired,
  rectFill: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
