import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  width, circleFill, pathFill,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 72 72"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-250 -802) translate(0 626) translate(16 88) translate(234 88)">
              <circle cx="36" cy="36" r="18" fill={circleFill} />
              <path fill={pathFill} d="M39.411 10.332l3.435 3.191c.367.342.879.5 1.385.431l4.733-.641c2.622-.366 5.07 1.34 5.52 3.844l.823 4.519c.087.483.403.9.855 1.13l4.225 2.148c2.345 1.184 3.28 3.946 2.106 6.226l-2.1 4.124c-.227.44-.227.957 0 1.397l2.1 4.125c1.175 2.279.24 5.04-2.106 6.223l-4.225 2.149c-.452.23-.768.648-.855 1.132l-.824 4.516c-.448 2.506-2.896 4.212-5.519 3.847l-4.733-.642c-.507-.07-1.019.088-1.385.43L39.41 61.67C38.5 62.522 37.275 63 36.001 63c-1.27-.001-2.49-.478-3.4-1.329l-3.435-3.187c-.366-.343-.878-.502-1.385-.43l-4.733.64c-.231.033-.464.05-.698.049-2.38.002-4.417-1.643-4.819-3.895l-.826-4.516c-.086-.484-.402-.903-.855-1.132l-4.237-2.151c-2.346-1.182-3.281-3.944-2.106-6.223l2.1-4.125c.227-.44.227-.957 0-1.397l-2.1-4.124c-1.172-2.28-.238-5.041 2.106-6.226l4.225-2.149c.453-.228.77-.646.855-1.13l.826-4.518c.446-2.506 2.895-4.213 5.517-3.844l4.733.641c.506.07 1.018-.09 1.385-.43l3.435-3.192c1.897-1.776 4.925-1.776 6.822 0zm3.093 28.271c-3.285.004-5.946 2.558-5.949 5.71.003 3.151 2.664 5.706 5.95 5.71 3.282-.01 5.94-2.561 5.95-5.71-.01-3.15-2.668-5.7-5.95-5.71zm5.369-13.974c-.636-.606-1.662-.606-2.298 0l-21.42 20.554c-.304.292-.476.687-.476 1.1 0 .413.172.809.477 1.1.302.294.716.46 1.146.458.43 0 .843-.165 1.147-.458l21.424-20.55c.632-.61.632-1.594 0-2.204zM29.52 21.994c-3.283.01-5.941 2.56-5.952 5.71.007 3.152 2.669 5.702 5.952 5.705 3.28-.01 5.938-2.558 5.951-5.705-.01-3.15-2.669-5.7-5.951-5.71zm12.982 19.723c1.493.002 2.702 1.164 2.703 2.596-.001 1.432-1.21 2.593-2.703 2.596-1.493-.003-2.701-1.164-2.701-2.596-.007-.69.278-1.35.786-1.837.508-.487 1.197-.76 1.915-.76zM29.52 25.107c1.494.002 2.704 1.164 2.706 2.597-.002 1.433-1.212 2.594-2.706 2.596-1.493-.003-2.702-1.164-2.704-2.596 0-1.431 1.21-2.594 2.704-2.596z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  circleFill: PropTypes.string.isRequired,
  pathFill: PropTypes.string.isRequired,
};

export default Svg;
