import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { gaLogAddOrRemoveProductFromCart } from 'ecommerce_ga_events/EcommerceGAEvents';
import Variants from './Variants';
import Svg from '../../common/svg/ArrowDown';
import { Constants } from '../../../utilities';
import * as LogClevertapEvent from '../../../../clevertap/LogEvent';
import CartCounterBorderNone from '../cart/CartCounterBorderNone';
import {
  errorImageFallback, getCDNImageUrl, getSeoProductUrl, LOVELOCAL,
} from '../../../utilities/Utils';

class PopularProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addVariants: false,
    };
  }

  toggleAddVariants = () => {
    const { addVariants } = this.state;
    this.setState({
      addVariants: !addVariants,
    });
  }

  render() {
    const { addVariants } = this.state;
    const {
      item, cartItems, language, isMobile,
      toggleSideOverlay, b2bStorePreview, history,
    } = this.props;
    const {
      allVariants, appPrice, sellingPrice, mrp, store,
    } = item.sellingStores[0];
    const discountPrice = mrp - sellingPrice;
    const customisable = !!allVariants.length;
    let quantity = 0;
    if (cartItems) {
      if (customisable) {
        for (let i = 0; i < allVariants.length; i += 1) {
          const cartItem = cartItems[allVariants[i].id];
          if (cartItem
          && (cartItem.storeId === store.id)
          ) {
            quantity += cartItem.quantity;
          }
        }
      } else {
        const cartItem = cartItems[item.id];
        if (cartItem
          && (cartItem.storeId === store.id)) {
          quantity = cartItem.quantity;
        }
      }
    }

    const { pathName, state, searchParams } = getSeoProductUrl(
      item.id, item.displayName, item.sellingStores[0].store.code,
    );

    return (
      <Col
        xs={24}
        md={12}
        id="popular-product"
        className={`p-2 ${b2bStorePreview ? 'pointer-event-none' : ''}`}
      >
        {!isMobile && customisable && (
        <Variants
          isMobile={isMobile}
          show={addVariants}
          product={item}
          sellingStore={item.sellingStores[0]}
          onClose={this.toggleAddVariants}
          language={language}
        />
        )}
        <div
          className={`px-2 py-4 h-100 rounded ${isMobile ? '' : 'border'}`}
        >
          <Row
            className="mx-0"
          >
            <Col
              xs={isMobile ? 16 : 17}
            >
              {(
                (appPrice <= 0) && (discountPrice > 9)
              ) && (
                <span
                  className="px-3 py-1 fs-5 bg-green text-white border-radius-3"
                >
                  <b>
                    {
                      `${Constants.String.SAVE[language].toUpperCase()} \u20B9 ${parseFloat(discountPrice.toFixed(2))}`
                    }
                  </b>
                </span>
              )}
              <div
                className="pt-2 font-weight-bold"
              >
                {item.displayName}
              </div>
              {appPrice > 0 && (
                <div
                  className="pb-1 text-blue"
                >
                  <b>
                    {
                      `${Constants.String.OFFER_PRICE[language]}: \u20B9 ${appPrice}`
                    }
                  </b>
                </div>
              )}
              <div
                className="d-flex"
              >
                <div
                  className="pb-1"
                >
                  <b>
                    {`\u20B9 ${sellingPrice}`}
                  </b>
                  {mrp > 0 && mrp > sellingPrice && (
                  <span
                    className="m-0 fs-6 pl-3 text-secondary"
                  >
                    <del>
                      {`\u20B9 ${mrp}`}
                    </del>
                  </span>
                  )}
                </div>
                {customisable && (
                <div
                  className="pb-1 pl-3 d-flex align-items-center"
                >
                  <div
                    className="text-secondary"
                  >
                    {item.sellingStores[0].unitString}
                  </div>
                  <div
                    className="pl-1"
                  >
                    <Svg
                      svg="arrowDown"
                      width="1.2rem"
                      stroke={Constants.Color.primary}
                    />
                  </div>
                </div>
                )}
              </div>
              <div
                className="text-secondary"
              >
                {store.displayName}
              </div>
            </Col>
            <Col
              xs={isMobile ? 8 : 7}
            >
              <Row
                as={item.curated ? Link : 'div'}
                to={{ pathname: pathName, state, search: searchParams }}
                className={`product-image-div shadow-sm border mx-0 
                  ${item.curated ? '' : 'cursor-pointer'} 
                  ${item.curated ? 'link-tag' : ''}`}
                onClick={() => {
                  if (!item.curated) {
                    history.push({
                      pathname: pathName,
                      state,
                      search: searchParams,
                    });
                  }
                }}
              >
                <img
                  src={
                    item.image
                      ? getCDNImageUrl(item.image)
                      : LOVELOCAL.productPlaceholder
                    }
                  alt={item.image ? item.displayName : ''}
                  title={item.image ? item.displayName : ''}
                  className="mh-100 mw-100"
                  onError={(event) => {
                    errorImageFallback(event, LOVELOCAL.productPlaceholder);
                  }}
                />
              </Row>
              <div
                className={`shadow-lg bg-white popular-product-add ${isMobile ? 'mx-2' : 'mx-3'}`}
              >
                <CartCounterBorderNone
                  productInfo={{
                    storeName: store.displayName,
                    productName: item.displayName,
                    sellingPrice,
                    mrp,
                    imageUrl: item.image || LOVELOCAL.productPlaceholder,
                    measurementDisplayText: item.sellingStores[0].unitString,
                  }}
                  storeCode={store.id}
                  productId={item.id}
                  quantity={quantity}
                  onSuccess={(productCount, isAdding) => {
                    LogClevertapEvent.skusUpdatedToCart(
                      store.displayName,
                      item.displayName,
                      productCount,
                      item.categories,
                    );
                    gaLogAddOrRemoveProductFromCart(
                      isAdding,
                      item.sellingPrice,
                      {
                        item_id: item.id,
                        item_name: item.displayName,
                        price: item.sellingPrice,
                        quantity: productCount,
                      },
                    );
                  }}
                  customisable={customisable}
                  toggleAddVariants={() => {
                    if (isMobile) {
                      toggleSideOverlay(() => (
                        <Variants
                          show
                          product={item}
                          sellingStore={item.sellingStores[0]}
                          onClose={() => {
                            toggleSideOverlay(null);
                          }}
                          language={language}
                          isMobile={isMobile}
                        />
                      ));
                      return;
                    }
                    this.toggleAddVariants();
                  }}
                />
              </div>
              {customisable && (
              <div
                className="fs-6 text-center popular-product-add pt-1"
              >
                {Constants.String.CUSTOMISABLE[language]}
              </div>
              )}
            </Col>
          </Row>
        </div>
        {
          isMobile && (
            <div
              className="border-bottom"
            />
          )
        }
      </Col>
    );
  }
}

PopularProduct.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
    displayName: PropTypes.string,
    description: PropTypes.string,
    sellingPrice: PropTypes.number,
    mrp: PropTypes.number,
    id: PropTypes.number,
    sellingStores: PropTypes.arrayOf(PropTypes.shape({
      appPrice: PropTypes.number,
      sellingPrice: PropTypes.number,
      mrp: PropTypes.number,
      unitString: PropTypes.string,
      store: PropTypes.shape({
        displayName: PropTypes.string,
        id: PropTypes.number,
        code: PropTypes.number,
      }),
      allVariants: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
      })),
    })).isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    curated: PropTypes.bool,
    displayTag: PropTypes.string,
    manufacturerId: PropTypes.string,
    brandId: PropTypes.number,
  }).isRequired,
  cartItems: PropTypes.shape({}),
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  b2bStorePreview: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

PopularProduct.defaultProps = {
  cartItems: null,
  b2bStorePreview: false,
};

export default PopularProduct;
