import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'app/lottie';
import style from './index.module.scss';
import FreeDeliveryActive from '../../Utils/cart-offers/FreeDeliveryActive';
import FreeDeliveryInActive from '../../Utils/cart-offers/FreeDeliveryInActive';
import ValueOfferActive from '../../Utils/cart-offers/ValueOfferActive';
import ValueOfferInActive from '../../Utils/cart-offers/ValueOfferInActive';
import ScratchCardActive from '../../Utils/cart-offers/ScratchCardActive';
import ScratchCardInActive from '../../Utils/cart-offers/ScratchCardInActive';
import FreeProductAtive from '../../Utils/cart-offers/FreeProductAtive';
import FreeProductInActive from '../../Utils/cart-offers/FreeProductInActive';
import { CartOfferPropTypes } from './type-interfaces';

function CartOffer({
  offer, horizontalView, singleOffer, showAnimationForIds,
}: CartOfferPropTypes) {
  const [showAnimation, setShowAnimation] = useState(showAnimationForIds?.includes(offer.id));
  const iconWidths = () => {
    if (singleOffer) { return 24; }
    if (horizontalView) { return 22; }
    return 28;
  };

  const icons = {
    FREE_DELIVERY: {
      active: <FreeDeliveryActive width={iconWidths()} height={iconWidths()} />,
      inActive: <FreeDeliveryInActive width={iconWidths()} height={iconWidths()} />,
    },
    VALUE_OFFER: {
      active: <ValueOfferActive width={iconWidths()} height={iconWidths()} />,
      inActive: <ValueOfferInActive width={iconWidths()} height={iconWidths()} />,
    },
    SCRATCH_CARD: {
      active: <ScratchCardActive width={iconWidths()} height={iconWidths()} />,
      inActive: <ScratchCardInActive width={iconWidths()} height={iconWidths()} />,
    },
    SPEND_OFFER: {
      active: <FreeProductAtive width={iconWidths()} height={iconWidths()} />,
      inActive: <FreeProductInActive width={iconWidths()} height={iconWidths()} />,
    },
    FREE_SAMPLE: {
      active: <FreeProductAtive width={iconWidths()} height={iconWidths()} />,
      inActive: <FreeProductInActive width={iconWidths()} height={iconWidths()} />,
    },
  };

  useEffect(() => {
    setShowAnimation(showAnimationForIds?.includes(offer.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnimationForIds]);

  const offerTextStyle = `${horizontalView ? 'fs-6' : 'fs-7'} ${offer.isLocked ? 'text-medium' : ''}`;

  return (
    <div className={`${style.cartOffer} ${horizontalView ? 'flex-row gap-8' : ''}`}>
      <div className={`position-relative ${horizontalView ? 'pr-1' : ''}`}>
        {
          showAnimation && (
            <div className={`${style.lottie} ${!offer.isLocked ? '' : 'd-none'}`}>
              <Lottie
                background="transparent"
                height={48}
                width={48}
                animationFor="OFFER_UNLOCKED_CIRCULAR"
              />
            </div>
          )
        }
        <div className={style.cartOfferIcon}>{icons[offer.stripType][offer.isLocked ? 'inActive' : 'active']}</div>
      </div>
      {
        singleOffer && offer.stripType === 'FREE_DELIVERY' && !offer.isLocked
          ? (
            <div className={offerTextStyle}>
              Yay! You’ve unlocked &nbsp;
              {' '}
              <b>FREE DELIVERY</b>
            </div>
          ) : (
            <>
              <div className={offerTextStyle}>
                {`₹${offer.amount}${offer.stripType === 'SCRATCH_CARD' ? ' off' : ''}`}
              </div>
              <div className={`${horizontalView ? '' : 'd-none'}`}>&nbsp;-&nbsp;</div>
              <div
                className={`font-weight-black text-truncate-1 ${offerTextStyle}`}
              >
                {offer.text}
              </div>
            </>
          )
      }
    </div>
  );
}

CartOffer.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    isLocked: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    stripType: PropTypes.string.isRequired,
  }).isRequired,
  horizontalView: PropTypes.bool,
  singleOffer: PropTypes.bool,
  showAnimationForIds: PropTypes.bool,
};

CartOffer.defaultProps = {
  horizontalView: false,
  singleOffer: false,
  showAnimationForIds: false,
};

export default CartOffer;
