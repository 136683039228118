export const ACCOUNT = 'ACCOUNT';
export const ADD_NEW_LOCATION = 'ADD_NEW_LOCATION';
export const APPLICABLE_VALUE_OFFERS = 'APPLICABLE_VALUE_OFFERS';
export const BOGO_OFFERS = 'BOGO_OFFERS';
export const CART = 'CART';
export const CATEGORY_LANDING = 'CATEGORY_LANDING';
export const CATEGORIES = 'CATEGORIES';
export const CHECKOUT = 'CHECKOUT';
export const COUPONS = 'COUPONS';
export const CUSTOMER_PROFILE = 'CUSTOMER_PROFILE';
export const DISCOUNT_OFFERS = 'DISCOUNT_OFFERS';
export const FAVOURITE_STORES = 'FAVOURITE_STORES';
export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';
export const HOME_SCREEN = 'HOME_SCREEN';
export const MANAGE_LOCATION = 'MANAGE_LOCATION';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const OFFERS = 'OFFERS';
export const ORDER_DETAILS = 'ORDER_DETAILS';
export const ORDER_LIST = 'ORDER_LIST';
export const POPULAR_PRODUCT = 'POPULAR_PRODUCT';
export const PRODUCT_DETAILS = 'PRODUCT_DETAILS';
export const PAST_ORDER_PRODUCTS = 'PAST_ORDER_PRODUCTS';
export const REFER_AND_EARN = 'REFER_AND_EARN';
export const REORDER = 'REORDER';
export const REWARD_COUPONS = 'REWARD_COUPONS';
export const REWARDS = 'REWARDS';
export const SIGN_UP = 'SIGN_UP';
export const FREE_GIFT_OFFER = 'FREE_GIFT_OFFER';
export const SHOP_COUPONS = 'SHOP_COUPONS';
export const STORE_DETAILS = 'STORE_DETAILS';
export const SHOP_FRONT = 'Shop_Front';
export const STORES = 'STORES';
export const STORECATEGORYPAGE = 'ShopCategory_Page';
export const STOREPAGE = 'Shops_page';
export const GUEST_LOCATION = 'GUEST_LOCATION';
export const HOME_TAB = 'Home_Tab';
export const InitialTabFragment = 'InitialTabFragment';
