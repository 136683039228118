import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components/common';
import DiscountIcon from 'app/components/common/svg/Cart/DiscountIcon';
import RewardCoupon from 'app/pages/offers/RewardCoupon';
import { Constants } from 'app/utilities';
import { Col, Row } from 'react-bootstrap';

function ScratchCardCoupons(props) {
  const {
    history, language, isMobile,
    toggleSideOverlay,
  } = props;

  return (
    <Row
      className="mx-0"
    >
      <Col
        xs={24}
        className="py-3 bg-white cursor-pointer"
        onClick={() => {
          if (isMobile) {
            history.push(
              '/offers/reward-coupon',
              { applyOffer: true },
            );
            return;
          }
          toggleSideOverlay(() => (<RewardCoupon {...props} />), false);
        }}
      >
        <Row
          className="align-items-center"
        >
          <Col
            xs="auto"
            className="px-0"
          >
            <DiscountIcon />
          </Col>
          <Col
            className="fs-4 font-weight-bold"
          >
            {Constants.String.APPLY_COUPONS_OR_OFFERS_AND_SAVE_MORE[language]}
          </Col>
          <Col
            xs="auto"
            className="px-0"
          >
            <Svg
              svg="filledTriangle"
              width="24px"
              height="24px"
              fill={Constants.Color.primary}
              isDown
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

ScratchCardCoupons.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
};

export default ScratchCardCoupons;
