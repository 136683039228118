import ScooterWithBg from 'app/components/common/svg/Cart/ScooterWithBg';
import UnlockIcon from 'app/components/common/svg/Cart/UnlockIcon';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

function FreeDeliveryStrip() {
  return (
    <Row
      className="mx-0"
    >
      <Col
        xs={24}
        className="py-3 bg-white"
      >
        <Row
          className="align-items-center"
        >
          <Col
            xs="auto"
            className="px-0"
          >
            <ScooterWithBg />
          </Col>
          <Col
            className="fs-4 font-weight-bold"
          >
            Free Delivery Unlocked
          </Col>
          <Col
            xs="auto"
            className="px-0"
          >
            <UnlockIcon />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FreeDeliveryStrip;
