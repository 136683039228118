import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 29 30"
  >
    <path fill={fill} fillRule="evenodd" d="M15.54 4.882l1.353 1.294c.145.139.347.203.546.175l1.866-.26c1.034-.149 2 .543 2.176 1.56l.325 1.832c.034.196.159.365.337.458l1.666.872c.925.48 1.293 1.6.83 2.525l-.827 1.673c-.09.179-.09.388 0 .567l.828 1.673c.463.924.094 2.045-.83 2.524l-1.667.872c-.178.093-.302.263-.337.459l-.325 1.832c-.176 1.016-1.142 1.709-2.176 1.56l-1.866-.26c-.2-.029-.401.036-.546.175l-1.354 1.292c-.36.347-.842.54-1.345.54-.5 0-.982-.194-1.34-.539L11.5 24.414c-.145-.14-.347-.204-.547-.175l-1.866.26c-.09.013-.183.02-.275.02-.939 0-1.741-.667-1.9-1.58l-.326-1.832c-.034-.197-.158-.366-.337-.46l-1.67-.872c-.925-.48-1.294-1.6-.83-2.524l.827-1.673c.09-.179.09-.388 0-.567l-.828-1.673c-.462-.925-.094-2.045.83-2.525l1.667-.872c.178-.092.303-.262.337-.458l.325-1.833c.176-1.016 1.142-1.709 2.176-1.56l1.866.26c.2.029.4-.036.546-.174l1.354-1.294c.748-.72 1.942-.72 2.69 0zm1.218 11.467c-1.295.002-2.344 1.038-2.345 2.316 0 1.279 1.05 2.315 2.345 2.316 1.295-.004 2.343-1.038 2.347-2.316-.004-1.277-1.052-2.312-2.347-2.316zm2.117-5.668c-.25-.246-.655-.246-.906 0l-8.445 8.337c-.12.119-.188.28-.188.447 0 .167.068.328.188.446.12.119.282.186.452.186.17 0 .333-.067.452-.186l8.447-8.336c.25-.247.25-.647 0-.894zM11.64 9.612c-1.294.004-2.342 1.039-2.346 2.317.002 1.278 1.052 2.312 2.346 2.313 1.293-.004 2.341-1.037 2.346-2.314-.004-1.277-1.052-2.312-2.346-2.316zm5.118 8c.59.001 1.066.472 1.066 1.053s-.477 1.052-1.066 1.053c-.588 0-1.064-.472-1.067-1.053 0-.28.112-.547.313-.745.2-.197.471-.308.754-.308zm-5.118-6.737c.589 0 1.066.472 1.067 1.053 0 .581-.478 1.053-1.067 1.053s-1.065-.472-1.066-1.052c0-.581.477-1.053 1.066-1.054z" />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
