import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 26 26"
  >
    <path
      fill={fill}
      d="M12.637 3.336c.228-.114.498-.114.726 0l1.325.662 2.117-.705c.248-.082.52-.042.732.111.213.153.338.399.338.66v3.475c.484.283.813.8.813 1.4v12.187c0 .896-.73 1.625-1.625 1.625H8.938c-.897 0-1.625-.729-1.625-1.625V8.938c0-.599.329-1.116.812-1.399V4.063c0-.26.125-.506.337-.659.212-.153.484-.193.732-.11l2.119.704zm4.426 5.602H8.938v12.188h8.122l.003-12.188zm-6.5 9.75c.448 0 .812.364.812.813 0 .448-.364.812-.813.812-.448 0-.812-.364-.812-.812 0-.449.364-.813.813-.813zm4.874 0c.449 0 .813.364.813.813 0 .448-.364.812-.813.812-.448 0-.812-.364-.812-.812 0-.449.364-.813.813-.813zm-2.437 0c.448 0 .813.364.813.813 0 .448-.365.812-.813.812-.448 0-.813-.364-.813-.812 0-.449.365-.813.813-.813zm-2.438-2.437c.449 0 .813.364.813.812 0 .449-.364.813-.813.813-.448 0-.812-.364-.812-.813 0-.448.364-.812.813-.812zm4.876 0c.448 0 .812.364.812.812 0 .449-.364.813-.813.813-.448 0-.812-.364-.812-.813 0-.448.364-.812.813-.812zm-2.438 0c.448 0 .813.364.813.812 0 .449-.365.813-.813.813-.448 0-.813-.364-.813-.813 0-.448.365-.812.813-.812zm-2.438-2.438c.449 0 .813.364.813.813 0 .448-.364.812-.813.812-.448 0-.812-.364-.812-.812 0-.449.364-.813.813-.813zm4.876 0c.448 0 .812.364.812.813 0 .448-.364.812-.813.812-.448 0-.812-.364-.812-.812 0-.449.364-.813.813-.813zm-2.438 0c.448 0 .813.364.813.813 0 .448-.365.812-.813.812-.448 0-.813-.364-.813-.812 0-.449.365-.813.813-.813zm3.25-4.062V13h-6.5V9.75h6.5zM13 4.973l-1.262.63c-.192.096-.415.112-.62.044L9.75 5.19v2.123h6.5V5.19l-1.368.457c-.205.068-.428.052-.62-.045L13 4.972z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
