import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Lottie from 'app/lottie';
import style from './index.module.scss';
import CartOffer from '../layout/cart-offer';
import CartBar from '../layout/cart-bar';
import HomeDeliveryLocked from '../Utils/cart-offers/HomeDeliveryLocked';

interface RootState {
  main: any,
}

const ValueUnlockingStrip = (props:any) => {
  const { cartView } = props;
  const { cartDetails } = useSelector<RootState, any>((state) => state.main);
  const cartOffersCount = cartDetails?.valueStripDetails?.elementCount;
  const refToCart = useRef<any>(null);
  const refToStrip = useRef<any>(null);
  const [showAnimationForIds, setShowAnimationForIds] = useState([]);
  const [showBlastAnimation, setShowBlastAnimation] = useState(false);
  const cartProductCount = (
    Number(cartDetails?.cartProductsCount) + Number(cartDetails?.cartCustomProductsCount)
  );

  const filterActive = (data: any) => data.filter((i: any) => !i.isLocked).map((t: any) => t.id);

  const activateBlastAnimation = (newlyActiveIds: any) => {
    setShowBlastAnimation(newlyActiveIds && newlyActiveIds.length > 0);
    setTimeout(() => {
      setShowBlastAnimation(false);
    }, 2000);
  };

  useEffect(() => {
    if (cartDetails) {
      // show lottie in value unlocking strip for recently active offers
      if (!refToCart.current) {
        refToCart.current = cartDetails?.valueStripDetails;
      } else if (JSON.stringify(refToCart.current) !== JSON.stringify(cartDetails)) {
        const prevActive = filterActive(refToCart?.current?.elementsData);
        const nowActive = filterActive(cartDetails?.valueStripDetails?.elementsData);
        const newlyActive = nowActive.filter((x: any) => !prevActive.includes(x));
        setShowAnimationForIds(newlyActive);
        activateBlastAnimation(newlyActive);
        refToCart.current = cartDetails?.valueStripDetails;
      }

      // Scroll to right if last offer is locked to attract user
      if (
        cartDetails?.valueStripDetails?.elementsData?.length === 5
        && !cartDetails?.valueStripDetails?.elementsData[3].isLocked
        && cartDetails?.valueStripDetails?.elementsData[4].isLocked
      ) {
        refToStrip.current.scrollTo({
          left: refToStrip.current.scrollWidth - refToStrip.current.clientWidth,
          behavior: 'smooth',
        });
      }
    }
  }, [cartDetails]);

  return (
    <div className={cartDetails ? '' : 'd-none'}>
      {(cartOffersCount === 1
        && cartDetails?.valueStripDetails?.elementsData[0].stripType === 'FREE_DELIVERY'
        && cartDetails?.valueStripDetails?.elementsData[0].isLocked)
        ? (
          <div className="bg-yellow p-2 text-center text-truncate">
            <HomeDeliveryLocked />
            &nbsp;Add items worth
            {' '}
            <b>{cartDetails?.homeDeliveryRemainingMOV}</b>
            {' '}
            for &nbsp;
            <b>FREE DELIVERY</b>
          </div>
        ) : (
          <div
            className={`${style.offersStrip} px-4 py-2 ${cartOffersCount > 4 ? 'justify-content-space-between' : ''} ${cartOffersCount ? '' : 'd-none'}`}
            itemType={cartOffersCount === 2 ? 'horizontal' : 'vertical'}
            ref={refToStrip}
          >
            {showBlastAnimation && (
              <div className={`${style.blastAnimationBlock}`}>
                <Lottie
                  background="transparent"
                  height={324}
                  width={324}
                  animationFor="OFFER_UNLOCKED_BLAST"
                />
              </div>
            )}
            {((cartDetails?.valueStripDetails?.elementsData) || [])
              .map((item: any, index: number) => (
                <>
                  <CartOffer
                    key={item.id}
                    offer={item}
                    horizontalView={cartOffersCount <= 2}
                    singleOffer={cartOffersCount === 1}
                    showAnimationForIds={showAnimationForIds}
                  />
                  {cartOffersCount === 2 && index === 0
                    ? <div className={style.solidRightBorder} /> : null}
                </>
              ))}
          </div>
        )}
      <div className={`${style.cartBar} ${!cartProductCount || cartView ? 'd-none' : ''}`}>
        <CartBar {...props} />
      </div>
    </div>
  );
};

export default ValueUnlockingStrip;
