import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  stroke1, stroke2, width, height,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 8.5h6m0 0v5.536m0-5.536-7 7"
      stroke={stroke1}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 8.5h6m0 0v5.536m0-5.536-7 7"
      stroke={stroke2}
      // strokeOpacity=".72"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke1: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  stroke2: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
