import { pushClevertapEvent, pushEvent } from './Clevertap';
import Event from './Event';
import { getDateLocaleString } from '../app/utilities/Utils';
import { Storage } from '../app/utilities';

function getLocationString(address) {
  const {
    locationId,
    addressType,
    addressText,
    location,
  } = address;
  return `id=${locationId}, label=${addressType}, fullAddress=${addressText}
  , location=LatLng(latitude=${location.lat}, longitude=${location.lng})`;
}

export function accountTabClicked() {
  pushEvent(Event.Accounts_Tab_Clicked);
}

export function addressSelectionPage(currentLocation) {
  pushEvent(
    Event.Address_Selection_Page,
    { 'Current location': currentLocation },
  );
}

export function authOtpRequested(loginType) {
  pushEvent(
    Event.Auth_Otp_Requested,
    { 'Login type': loginType },
  );
}

export function categoryClicked(categoryName, categoryId) {
  pushEvent(
    Event.Category_Clicked,
    {
      CategoryName: categoryName,
      'Category-id': categoryId,
    },
  );
}

export function categoryFilterChanged(isChildcategory, categoryName, categoryId) {
  pushEvent(
    Event.Category_Filter_Changed,
    {
      'category-is-child': isChildcategory,
      'Category Name': categoryName,
      'Category-id': categoryId,
    },
  );
}

export function checkoutPageLaunched() {
  pushEvent(Event.Checkout_Page_Launched, { Source: 'Web' });
}

export function couponBannerClicked(storeId, storeName, offerName) {
  pushEvent(
    Event.Home_Coupon_Banner_Click,
    {
      Store_id: storeId,
      Store_Name: storeName,
      Offer_Name: offerName,
    },
  );
}

export function couponOfferLandingPage() {
  pushEvent(Event.Coupon_offer_landing_page);
}

export function direction(storeName, epCode) {
  pushEvent(
    Event.Directions,
    {
      'Store Name': storeName,
      'EP Code': epCode,
    },
  );
}

export function deliveryType(type) {
  pushEvent(
    Event.Delivery_Type,
    {
      type,
    },
  );
}

// export function editAdress(isOld) {
//   pushEvent(
//     Event.Edit_Address,
//     { Old: isOld }, // isOld ?
//   );
// }

export function faqHelpUpdate(ishelpful, question, answer) {
  pushEvent(
    Event[ishelpful ? 'FAQ_RESPONSE_YES' : 'FAQ_RESPONSE_NO'],
    {
      question,
      answer,
    },
  );
}

export function frequentlyAskedQuesClicked(section) {
  pushEvent(
    Event.FAQLink_Clicked,
    {
      clicked_section: section,
    },
  );
}

export function homeBannerClicked(bannerUid, bannerTitle, bannerStoreCodes) {
  pushEvent(
    Event.Home_Banner_Clicked,
    {
      banner_id: bannerUid,
      banner_title: bannerTitle,
      banner_store_codes: bannerStoreCodes,
    },
  );
}

export function homeScreen(isGuestUser) {
  pushEvent(
    Event.Home_Screen,
    { 'Guest User': isGuestUser },
  );
}

// export function homePageCategoryClicked(categoryName) {   /* ambiguous categoryClicked event */
//   pushEvent(
//     Event.Home_Page_Offer_Section_Clicked,
//     { Type: categoryName }, // Product Offers, Coupon, Special
//   );
// }

export function homePageOfferSectionClicked(offerType) {
  pushEvent(
    Event.Home_Page_Offer_Section_Clicked,
    {
      Type: offerType, // Product Offers, Coupon, Special
    },
  );
}

// export function homePagePromoBannerClicked(bannerName) { // there is no promo banner
//   pushEvent(
//     Event.Home_Page_Promo_Banner_Clicked,
//     { 'Banner Name': bannerName },
//   );
// }

// export function homePageProductAddedToCart() {  /* No such event on CT dashboard */
//   pushEvent(
//     Event.Home_Page_Products_Added_To_Cart,
//   );
// }

export function locationSwitch(location) {
  pushEvent(
    Event.Location_Switch, {
      'Address set': location,
    },
  );
}

export function logout() {
  pushEvent(Event.Logout);
}

export function loginSuccessful(dateTime, customerLocation) {
  pushEvent(
    Event.Login_Successful,
    {
      'Date & Time Stamp': dateTime,
      'Customers Location': customerLocation, /* customer without address */
    },
  );
}

export function minOrderNotMet(storeName, skuValue, skuCount, isClicked) {
  pushEvent(
    Event.Min_Order_Not_Met,
    {
      'Store Name': storeName,
      'Value of SKUs': skuValue,
      'No of SKUs': skuCount,
      'Add more product clicked': isClicked, /* should be separate event, storeId form android  */
    },
  );
}

export function newLocationAddButton() {
  pushEvent(Event.New_Location_Add_Button); /* trigger on click */
}

export function newLocationAdded(location) {
  pushEvent(
    Event.New_Location_Added,
    { 'Address set': location },
  );
}

export function notificationInboxClicked() {
  pushEvent(Event.Notification_Inbox_Clicked);
}

export function offersTabClicked(offerCount, isGuestUser) {
  pushEvent(
    Event.Offers_Tab_Clicked,
    {
      'Count of offers': offerCount,
      'Guest User': isGuestUser,
    },
  );
}

export function offerTabAddedToCart(storeName, productName, savingValue) {
  pushEvent(
    Event.Offer_Tab_Added_to_Cart, /* disccount, have a Storelanding offer added */
    {
      'Store Name': storeName,
      'Product Name': productName,
      'Saving Value': savingValue,
    },
  );
}

export function offerTabOfferZone(offerName, offerValue) {
  pushEvent(
    Event.Offer_Tab_Offer_Zone,
    {
      'Offer Name': offerName,
      'Offer Value': offerValue,
    },
  );
}

export function offerCopies(offerName) {
  pushEvent(
    Event.Offer_Copies,
    { 'Offer Name': offerName },
  );
}

export function otpSubmission(reason, timeTaken) {
  pushEvent(
    Event.OTP_Submission,
    {
      'otp-reason': reason,
      'Time Taken': timeTaken,
    },
  );
}

export function guestLogin(timeTaken) {
  pushEvent(
    Event.GuestUser_Created,
    {
      'Time Taken': timeTaken,
    },
  );
}

export function ratedAndReviewed(review) {
  const { rating, content, created_at: createdOn } = review;
  pushEvent(
    Event.Rated_And_Reviewed,
    {
      'No of stars': rating,
      Is_reviewd: !!content,
      'Date & Time of rating': getDateLocaleString(createdOn),
      'Order ID': '',
      'Review details': content,
    },
  );
}

export function retailerCall(storeName, epCode) {
  pushEvent(
    Event.Retailer_Call,
    {
      'Store Name': storeName,
      'EP Code': epCode,
    },
  );
}

export function shoppingCartViewed(totalAmount, productCount) {
  pushEvent(
    Event.Shopping_Cart_Viewed,
    {
      'Total Amount': totalAmount,
      'No of products': productCount,
    },
  );
}

export function shoppingCartBuyNow(serviceableItemCount, storeCount) {
  pushEvent(
    Event.Shopping_Cart_Buy_Now,
    {
      'Number of Serviceable items': serviceableItemCount,
      'No of stores': storeCount,
    },
  );
}

export function searchCategoryLandingPage(searchTermFound, searchTermNotFound) {
  pushEvent(
    Event.Search_Category_Landing_Page,
    searchTermFound
      ? { 'Search terms_Found': searchTermFound }
      : { 'Search terms_Not found': searchTermNotFound },
  );
}

export function searchedGlobal(searchTermFound, searchTermNotFound) {
  pushEvent(
    Event.Searched_Global,
    searchTermFound
      ? { 'Search terms_Found': searchTermFound }
      : { 'Search terms_Not found': searchTermNotFound },
  );
}

export function searchedElementClick(termSearched, name) {
  pushEvent(
    Event.Search_Element_Click,
    {
      Type: 'Category',
      'Term Searched': termSearched,
      'Name Clicked': name,
    },
  );
}

export function recentProductSearch(eventData) {
  pushEvent(
    Event.LL_Search,
    eventData,
  );
}

export function popularBrandClick(eventData) {
  pushEvent(
    Event.LL_Brand_Banner_Clicked,
    eventData,
  );
}

export function mostSearchAndBought(eventName, eventData) {
  pushEvent(
    eventName,
    eventData,
  );
}

// export function signUpLocationSet(locationStatus) {
//   pushEvent(
//     Event.Sign_Up_Location_Set,
//     { 'Current Location': locationStatus },
//   );
// }

export function signUpSuccessful() {
  pushEvent(Event.Sign_up_Successful);
}

export function skusUpdatedToCart(storeName, productName, quantity, categories) {
  pushEvent(
    Event.SKU_Updated_To_Cart,
    {
      'Store Name': storeName,
      'Product Name': productName,
      Quantity: quantity,
      categories,
    },
  );
}

export function storeClickedOnHomePage(storeName, isFavorite) {
  pushEvent(
    Event.Store_Clicked_On_Home_Page,
    {
      'Store Name': storeName,
      Favorite: isFavorite,
    },
  );
}

export function storeFavoriteAdded(storeName, epCode, pageSource) {
  pushEvent(
    Event.Store_Favorite_Added,
    {
      'Store Name': storeName,
      'EP Code': epCode,
      'Page Source': pageSource,
    },
  );
}

export function storeFavoriteRemoved(storeName, epCode, pageSource) {
  pushEvent(
    Event.Store_Favorite_Removed,
    {
      'Store Name': storeName,
      'EP Code': epCode,
      'Page Source': pageSource,
    },
  );
}

export function storeLandingPage(storeName, epCode) {
  pushEvent(
    Event.Store_Landing_Page,
    {
      'Store Name': storeName,
      'EP Code': epCode,
    },
  );
}

export function storeListSearch(searchTermFound, searchTermNotFound) {
  pushEvent(
    Event.Store_List_Search,
    searchTermFound
      ? { 'Search terms_Found': searchTermFound }
      : { 'Search terms_Not found': searchTermNotFound },
  );
}

export function storeListPage(storeCount, isGuestUser) {
  pushEvent(
    Event.Store_List_Page,
    {
      'No of stores - Online ordering': storeCount,
      'Guest User': isGuestUser,
    },
  );
}

export function supportCall(page = '') {
  pushEvent(Event.Support_Call, {
    SCREEN: page,
  });
}

export function supportChat(page = '') {
  pushEvent(Event.Support_Chat, {
    SCREEN: page,
  });
}

// events to be logged //

export function appLaunchedOrAddressChanged(address) {
  pushClevertapEvent(
    Event.App_Launch_Or_Address_Changed,
    { 'Customers Location': getLocationString(address) },
  );
}

export function storesNotFoundError(address) {
  pushClevertapEvent(
    Event.Stores_Not_Found_Error,
    { 'Customers Location': getLocationString(address) },
  );
}

export function storeLandingCategoryClicked(categoryName, categoryId) {
  pushClevertapEvent(
    Event.Store_Landing_Category_Clicked,
    {
      'Category Name': categoryName,
      'Category-id': categoryId,
    },
  );
}

export function storeLandingOfferAddedToCart(storeName, productName, savingValue) {
  pushClevertapEvent(
    Event.Store_Landing_Offer_Added_to_Cart,
    {
      'Store Name': storeName,
      'Product Name': productName,
      'Saving Value': savingValue,
    },
  );
}

export function specialOfferLandingPage() {
  pushClevertapEvent(Event.Special_Offer_Landing_Page);
}

export function popularProductsLandingPage() {
  pushClevertapEvent(Event.Popular_Products_Landing_Page);
}

export function specialOfferAddedToCart(
  productName, productCount, amount, offerDetails, storeName,
) {
  pushClevertapEvent(
    Event.Special_Offer_Added_To_Cart,
    {
      'Product Name': productName,
      Quantity: productCount,
      Amount: amount,
      'Offer details': offerDetails,
      'Store Name': storeName,
    },
  );
}

export function storeSpecificOffersLoadingPage() {
  pushClevertapEvent(Event.Store_Specific_Offers_Loading_Page);
}

export function shareReferralClicked(page, referralCode) {
  pushClevertapEvent(
    Event.Share_Referral_Clicked,
    {
      'Referral Code': referralCode,
      page,
    },
  );
}

// home page flash sale
export function homePageFlashSaleBuy(product) {
  pushClevertapEvent(
    Event.HomePageFlashSale_Buy,
    {
      'User Properties': Storage.getProfile() || { isGuestUser: true },
      SKU: product,
      'Product Name': product.displayName,
    },
  );
}

export function homePageFlashSaleAdd(product, storeCode, storeName) {
  pushClevertapEvent(
    Event.HomePageFlashSale_Add,
    {
      'User Properties': Storage.getProfile() || { isGuestUser: true },
      'Retailer Id': storeCode,
      'Retailer Name': storeName,
      SKU: product,
      'Product Name': product.displayName,
    },
  );
}

export function homePageFlashSaleRemove(product, storeCode, storeName) {
  pushClevertapEvent(
    Event.HomePageFlashSale_Remove,
    {
      'User Properties': Storage.getProfile() || { isGuestUser: true },
      'Retailer Id': storeCode,
      'Retailer Name': storeName,
      SKU: product,
      'Product Name': product.displayName,
    },
  );
}

// shop card
export function shopCardClicked(shopName) {
  pushClevertapEvent(
    Event.ShopCard_Clicked,
    {
      user_properties: Storage.getProfile() || { isGuestUser: true },
      shop_name: shopName,
    },
  );
}

export function shopCardSeeAll() {
  pushClevertapEvent(
    Event.ShopCard_SeeAll,
    {
      user_properties: Storage.getProfile() || { isGuestUser: true },
    },
  );
}

// shop category

export function shopCategoryClicked(page, categoryName) {
  pushEvent(
    Event.ShopCategory_Clicked,
    {
      SCREEN: page,
      Category_Name: categoryName,
    },
  );
}

// shop filters

export function shopFilterClicked(page, filterName) {
  pushEvent(
    Event.ShopFilter_Clicked,
    {
      SCREEN: page,
      Filter_Name: filterName,
    },
  );
}

// shop filters deselection

export function shopFilterRemoved(page, filterName) {
  pushEvent(
    Event.ShopFilter_Removed,
    {
      SCREEN: page,
      Filter_Name: filterName,
    },
  );
}
// shop card selection

export function shopCardshopClicked(page, categoryName) {
  pushEvent(
    Event.ShopCardshop_Clicked,
    {
      SCREEN: page,
      Filter_Name: categoryName,
      Page: page,
    },
  );
}

export function shopCardShareClicked(page, categoryName) {
  pushEvent(
    Event.ShopCardShare_Clicked,
    {
      SCREEN: page,
      Filter_Name: categoryName,
    },
  );
}

// combo offers
export function bogoOfferAddedToCart(
  shopName, offerName, amount, savedAmount,
) {
  pushClevertapEvent(
    Event.BogoOffer_AddToCart,
    {
      user_properties: Storage.getProfile() || { isGuestUser: true },
      shop_name: shopName,
      offer_name: offerName,
      amount,
      saved_amount: savedAmount,
    },
  );
}

export function bogoOfferSeeAllClicked(fromPage) {
  pushClevertapEvent(
    Event.BogoOffer_SeeAll,
    {
      page: fromPage,
    },
  );
}

// spend offers
export function spendOfferSeeAllClicked(fromPage) {
  pushClevertapEvent(
    Event.ValueOffer_SeeAll,
    {
      page: fromPage,
    },
  );
}

// prevoiusly ordered product
export function previouslyOrderedProductAddedToCart(
  productName, shopName, amount, savedAmount, productCount,
) {
  pushClevertapEvent(
    Event.RepeatOrder_Add,
    {
      product_name: productName,
      shop_name: shopName,
      amount,
      saved_amount: savedAmount,
      product_count: productCount,
    },
  );
}

// home page
export function homePageChangeLocationClicked() {
  pushClevertapEvent(Event.ChangeLocation_Clicked);
}

export function homePageReferLocalShopsClicked() {
  pushClevertapEvent(Event.ReferLocalShops_Clicked);
}

// newly opened shop
export function newlyOpenedShopsClicked(shopName) {
  pushClevertapEvent(
    Event.NewlyOpenedShop_Clicked,
    {
      shop_name: shopName,
    },
  );
}

// Top Picks And Best Rated
export function topPicksCardClicked(eventMeta) {
  pushClevertapEvent(
    Event.Top_Pick_Card_Clicked,
    eventMeta,
  );
}

export function bestRatedCardClicked(eventMeta) {
  pushClevertapEvent(
    Event.Best_Rated_Card_Clicked,
    eventMeta,
  );
}

// Distance Alert Popup
export function farthestDistanceAlertViewed(eventMeta) {
  pushClevertapEvent(
    Event.Distance_Warning_Popup_Viewed,
    eventMeta,
  );
}

export function farthestDistanceAlertGoBack(eventMeta) {
  pushClevertapEvent(
    Event.Distance_Warning_Popup_Go_Back,
    eventMeta,
  );
}

export function farthestDistanceAlertContinue(eventMeta) {
  pushClevertapEvent(
    Event.Distance_Warning_Popup_Continue,
    eventMeta,
  );
}

// Guest User
export function setGuestLocationClicked(eventMeta) {
  pushClevertapEvent(
    Event.Setup_Guest_Location_Clicked,
    eventMeta,
  );
}

export function guestLocationSelected(eventMeta) {
  pushClevertapEvent(
    Event.Guest_Location_Selected,
    eventMeta,
  );
}

export function defaultLocationSelected(eventMeta) {
  pushClevertapEvent(
    Event.Default_Location_Selected,
    eventMeta,
  );
}

export function userLoginClicked(eventMeta) {
  pushClevertapEvent(
    Event.UserLogin_Clicked,
    eventMeta,
  );
}

// Rating and Review
export function ratingSubmitted(rating, review) {
  pushClevertapEvent(
    Event.UserRating_Submitted,
    {
      user_properties: Storage.getProfile() || { isGuestUser: true },
      rating,
      review,
    },
  );
}

export function selectPaymentMode(mode) {
  pushEvent(
    Event.Select_Payment_Method,
    {
      'Payment Mode': mode,
      Source: 'Web',
    },
  );
}

// About Us Page
export function logAboutUsCTEvents(event, data = {}) {
  pushEvent(
    event,
    data,
  );
}

export function logPageViewAboutUsCTEvent() {
  pushEvent(
    Event.About_Us_Page_View,
  );
}

export function logBrandBannerImpression(data = {}) {
  pushEvent(
    Event.Brand_Banner_Impression,
    data,
  );
}

export function logBrandProductClick(data = {}) {
  pushEvent(
    Event.Brand_Product_Clicked,
    data,
  );
}

export function logBrandBannerClick(data = {}) {
  pushEvent(
    Event.Brand_Banner_Clicked,
    data,
  );
}

// cart + checkout

export function logCustomProductAdd(data = {}) {
  pushEvent(
    Event.LL_Added_To_Cart,
    data,
  );
}

export function logCombinedOfferAdd(data = {}) {
  pushEvent(
    Event.CombinedOffer_Added,
    data,
  );
}

export function logViewBillDetailsClick(data = {}) {
  pushEvent(
    Event.View_Bill_details,
    data,
  );
}

export function logValueOfferSeeAllClicked(data = {}) {
  pushEvent(
    Event.ValueOffer_Clicked,
    data,
  );
}

export function logApplyCouponCheckoutClick() {
  pushEvent(
    Event.ApplyCouponCheckout_Click,
  );
}

export function BottomCartBarClicked(data = {}) {
  pushEvent(
    Event.BottomCartButton_Clicked,
    data,
  );
}

export function logSeeAllClick(data = {}) {
  pushEvent(
    Event.See_All_Clicked,
    data,
  );
}

export function buttonEventClick(eventName, eventData) {
  pushEvent(
    eventName,
    eventData,
  );
}

export function logCTEvent(data = {}) {
  pushEvent(
    data.eventName,
    data.payload,
  );
}
