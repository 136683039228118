import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height: width,
    }}
    viewBox="0 0 8 16"
  >
    <defs>
      <filter id="qjr82aejba">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#qjr82aejba)" transform="translate(-796 -2970)">
        <g>
          <path fill={fill} fillRule="nonzero" d="M5 5.5v-2c0-.552.448-1 1-1h1V0H5C3.343 0 2 1.343 2 3v2.5H0V8h2v8h3V8h2l1-2.5H5z" transform="translate(796 2970)" />
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
