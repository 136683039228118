import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 16 16"
  >
    <defs>
      <filter id="6h2sb41qra">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#6h2sb41qra)" transform="translate(-976 -2970)">
        <g>
          <path fill={fill} fillRule="nonzero" d="M11 0c2.761 0 5 2.239 5 5v6c0 2.761-2.239 5-5 5H5c-2.761 0-5-2.239-5-5V5c0-2.761 2.239-5 5-5zm0 1.5H5C3.07 1.5 1.5 3.07 1.5 5v6c0 1.93 1.57 3.5 3.5 3.5h6c1.93 0 3.5-1.57 3.5-3.5V5c0-1.93-1.57-3.5-3.5-3.5zM8 4c2.209 0 4 1.791 4 4s-1.791 4-4 4-4-1.791-4-4 1.791-4 4-4zm0 1.5C6.622 5.5 5.5 6.621 5.5 8c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5c0-1.379-1.122-2.5-2.5-2.5zm4.3-2.333c.294 0 .533.239.533.533 0 .294-.239.533-.533.533-.294 0-.533-.239-.533-.533 0-.294.239-.533.533-.533z" transform="translate(976 2970)" />
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
