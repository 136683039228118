import * as React from 'react';

const FreeProductInActive = (props) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.53776 10.1464C7.63153 10.0527 7.75871 10 7.89132 10H18.1316C18.4077 10 18.6316 10.2239 18.6316 10.5V23.1087C18.6316 23.2413 18.5789 23.3685 18.4851 23.4622L17.0938 24.8536C17 24.9473 16.8729 25 16.7403 25H6.5C6.22386 25 6 24.7761 6 24.5V11.8913C6 11.7587 6.05268 11.6315 6.14645 11.5378L7.53776 10.1464Z"
      fill="#DCFFC7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.89132 9H18.1316C18.96 9 19.6316 9.67157 19.6316 10.5V23.1087C19.6316 23.5065 19.4735 23.888 19.1922 24.1693L17.8009 25.5607C17.5196 25.842 17.1381 26 16.7403 26H6.5C5.67158 26 5 25.3284 5 24.5V11.8913C5 11.4935 5.15804 11.112 5.43934 10.8307L6.83066 9.43934C7.11196 9.15804 7.49349 9 7.89132 9ZM7.89132 10C7.75871 10 7.63153 10.0527 7.53776 10.1464L6.14645 11.5378C6.05268 11.6315 6 11.7587 6 11.8913V24.5C6 24.7761 6.22386 25 6.5 25H16.7403C16.8729 25 17 24.9473 17.0938 24.8536L18.4851 23.4622C18.5789 23.3685 18.6316 23.2413 18.6316 23.1087V10.5C18.6316 10.2239 18.4077 10 18.1316 10H7.89132Z"
      fill="#787878"
    />
    <path
      d="M6 12.5C6 12.2239 6.22386 12 6.5 12H16.4474C16.7235 12 16.9474 12.2239 16.9474 12.5V24.5C16.9474 24.7762 16.7235 25 16.4474 25H6.5C6.22386 25 6 24.7762 6 24.5V12.5Z"
      fill="#DCFFC7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 11H16.4474C17.2758 11 17.9474 11.6716 17.9474 12.5V24.5C17.9474 25.3285 17.2758 26 16.4474 26H6.5C5.67158 26 5 25.3285 5 24.5V12.5C5 11.6716 5.67157 11 6.5 11ZM6.5 12C6.22386 12 6 12.2239 6 12.5V24.5C6 24.7762 6.22386 25 6.5 25H16.4474C16.7235 25 16.9474 24.7762 16.9474 24.5V12.5C16.9474 12.2239 16.7235 12 16.4474 12H6.5Z"
      fill="#787878"
    />
    <path
      d="M14 18.5C14 20.433 12.8807 22 11.5 22C10.1193 22 9 20.433 9 18.5C9 16.567 10.1193 15 11.5 15C12.8807 15 14 16.567 14 18.5Z"
      fill="#DCFFC7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0815 21.5561C13.4961 22.3757 12.5971 23 11.5 23C10.4029 23 9.50394 22.3757 8.9185 21.5561C8.33169 20.7346 8 19.6509 8 18.5C8 17.3491 8.33169 16.2654 8.9185 15.4439C9.50394 14.6243 10.4029 14 11.5 14C12.5971 14 13.4961 14.6243 14.0815 15.4439C14.6683 16.2654 15 17.3491 15 18.5C15 19.6509 14.6683 20.7346 14.0815 21.5561ZM11.5 22C12.8807 22 14 20.433 14 18.5C14 16.567 12.8807 15 11.5 15C10.1193 15 9 16.567 9 18.5C9 20.433 10.1193 22 11.5 22Z"
      fill="#787878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0378 10.3536L17.3536 12.0378L16.6465 11.3307L18.3307 9.64645L19.0378 10.3536Z"
      fill="black"
    />
    <circle cx={22} cy={7} r={6.5} fill="black" stroke="black" />
    <path
      d="M19 12C18.725 12 18.4895 11.9067 18.2935 11.72C18.0975 11.5333 17.9997 11.3092 18 11.0476V6.28571C18 6.02381 18.098 5.79952 18.294 5.61286C18.49 5.42619 18.7253 5.33302 19 5.33333H19.5V4.38095C19.5 3.72222 19.7438 3.16064 20.2315 2.69619C20.7192 2.23175 21.3087 1.99968 22 2C22.6917 2 23.2813 2.23222 23.769 2.69667C24.2567 3.16111 24.5003 3.72254 24.5 4.38095V5.33333H25C25.275 5.33333 25.5105 5.42667 25.7065 5.61333C25.9025 5.8 26.0003 6.02413 26 6.28571V11.0476C26 11.3095 25.902 11.5338 25.706 11.7205C25.51 11.9071 25.2747 12.0003 25 12H19ZM22 9.61905C22.275 9.61905 22.5105 9.52571 22.7065 9.33905C22.9025 9.15238 23.0003 8.92825 23 8.66667C23 8.40476 22.902 8.18048 22.706 7.99381C22.51 7.80714 22.2747 7.71397 22 7.71429C21.725 7.71429 21.4895 7.80762 21.2935 7.99429C21.0975 8.18095 20.9997 8.40508 21 8.66667C21 8.92857 21.098 9.15286 21.294 9.33952C21.49 9.52619 21.7253 9.61936 22 9.61905ZM20.5 5.33333H23.5V4.38095C23.5 3.98413 23.3542 3.64683 23.0625 3.36905C22.7708 3.09127 22.4167 2.95238 22 2.95238C21.5833 2.95238 21.2292 3.09127 20.9375 3.36905C20.6458 3.64683 20.5 3.98413 20.5 4.38095V5.33333Z"
      fill="white"
    />
  </svg>
);
export default FreeProductInActive;
