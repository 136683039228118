import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const LineShimmer = () => (
  <Container>
    <Row>
      <Col
        xs={24}
        className="mt-6 line-shimmer-card bg-medium animate"
      />
    </Row>
  </Container>
);

export default LineShimmer;
