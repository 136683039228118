import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 16 16"
  >
    <defs>
      <filter id="a" width="106.8%" height="116.7%" x="-3.4%" y="-8.3%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4" />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path fill={fill} fillRule="evenodd" d="M13.991 2.382a.393.393 0 0 0-.373-.373 19.827 19.827 0 0 0-3.294.176A9.18 9.18 0 0 0 5.01 4.339a4.701 4.701 0 0 0-.265 6.358l-2.63 2.634a.391.391 0 1 0 .553.555l2.626-2.636a4.71 4.71 0 0 0 6.364-.265 9.16 9.16 0 0 0 2.156-5.312c.15-1.09.21-2.191.176-3.291zm-2.887 8.049a3.927 3.927 0 0 1-5.253.263l.703-.72h2.61a.379.379 0 0 0 .392-.375.403.403 0 0 0-.392-.405H7.338L9.05 7.497h2.61a.39.39 0 1 0 0-.781H9.834l1.825-1.824a.392.392 0 1 0-.555-.555l-1.82 1.828V4.339a.396.396 0 0 0-.397-.392.381.381 0 0 0-.379.392v2.606l-1.726 1.71V6.83a.39.39 0 0 0-.782 0v2.608l-.703.702a3.918 3.918 0 0 1 .264-5.248c1.699-1.697 5.242-2.123 7.654-2.108.01 1.29-.117 5.655-2.11 7.647z" filter="url(#a)" />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
