import { urlConfig } from 'api/url';
import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl, OOBaseUrl } = apiUrls();
const { homeDeliveryV1 } = urlConfig;

const cartApis = {
  cartLoad: (params = {}, cancelToken) => {
    const cart = `${newOOBaseUrl}cart`;
    return Axios({
      url: cart,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },

  cartProductAdd: (data, cartId, params = {}) => {
    const cartProductAdd = `${newOOBaseUrl}cart/${cartId || ''}/product/add`;
    return Axios({
      url: cartProductAdd,
      method: 'POST',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartProductBulkAdd: (data, cartId, params = {}) => {
    const cartProductBulkAdd = `${newOOBaseUrl}cart/${cartId || ''}/product/bulk/add`;
    return Axios({
      url: cartProductBulkAdd,
      method: 'POST',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartProductUpdate: (data, cartId, params = {}) => {
    const cartProductUpdate = `${newOOBaseUrl}cart/${cartId || ''}/product/update`;
    return Axios({
      url: cartProductUpdate,
      method: 'PATCH',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  customCartProductUpdate: (data, cartId, params = {}) => {
    const customCartProductUpdate = `${newOOBaseUrl}cart/${cartId || ''}/custom-product/update`;
    return Axios({
      url: customCartProductUpdate,
      method: 'PATCH',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartProductDelete: (data, cartId, params = {}) => {
    const cartProductDelete = `${newOOBaseUrl}cart/${cartId || ''}/product/delete`;
    return Axios({
      url: cartProductDelete,
      method: 'DELETE',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  customCartProductDelete: (data, cartId, params = {}) => {
    const customCartProductDelete = `${newOOBaseUrl}cart/${cartId || ''}/custom-product/delete`;
    return Axios({
      url: customCartProductDelete,
      method: 'DELETE',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartReset: (cartId, params = {}) => {
    const cartProductAdd = `${newOOBaseUrl}cart/${cartId || ''}/reset`;
    return Axios({
      url: cartProductAdd,
      method: 'POST',
      // data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartDelete: (data, cartId, params = {}) => {
    const cartDelete = `${newOOBaseUrl}cart/${cartId || ''}/delete`;
    return Axios({
      url: cartDelete,
      method: 'DELETE',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartAddCoupon: (data, cartId, params = {}) => {
    const cartAddCoupon = `${newOOBaseUrl}cart/${cartId || ''}/coupon/add`;
    return Axios({
      url: cartAddCoupon,
      method: 'POST',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartDeleteCoupon: (data, cartId, params = {}) => {
    const cartDeleteCoupon = `${newOOBaseUrl}cart/${cartId || ''}/coupon/delete`;
    return Axios({
      url: cartDeleteCoupon,
      method: 'DELETE',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartAddOffer: (data, cartId, params = {}) => {
    const cartAddOffer = `${newOOBaseUrl}cart/${cartId || ''}/offer/add`;
    return Axios({
      url: cartAddOffer,
      method: 'POST',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartDeleteOffer: (data, cartId, params = {}) => {
    const cartDeleteOffer = `${newOOBaseUrl}cart/${cartId || ''}/offer/delete`;
    return Axios({
      url: cartDeleteOffer,
      method: 'DELETE',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartDeliveryUpdate: (data, cartId, params = {}) => {
    const cartDeliveryUpdate = `${newOOBaseUrl}cart/${cartId || ''}/delivery/update`;
    return Axios({
      url: cartDeliveryUpdate,
      method: 'PATCH',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  cartPaymentUpdate: (data, cartId, params = {}) => {
    const cartPaymentUpdate = `${newOOBaseUrl}cart/${cartId || ''}/payment/update`;
    return Axios({
      url: cartPaymentUpdate,
      method: 'PATCH',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
    });
  },

  highlightCartValueOffer: (cartId, cancelToken, params = {}) => {
    const cartValueOffer = `${OOBaseUrl}${homeDeliveryV1}/highlightvalueoffers/${cartId || ''}`;
    return Axios({
      url: cartValueOffer,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams(params),
      cancelToken,
    });
  },

  cartCustomProductAdd: (cartId, data) => {
    const cartCustomProductAdd = `${newOOBaseUrl}cart/${cartId}/custom-product/add`;
    return Axios({
      url: cartCustomProductAdd,
      method: 'POST',
      data,
      headers: setHeaders(setAuthorizationHeaders(), true),
    });
  },
};

export default cartApis;
