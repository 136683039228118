import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { customerStoreReferral } from 'api/api';
import { Constants, validateRegex } from '../../utilities';
import Svg from '../../components/common/Svg';

const ReferShopModal = ({ language, closeReferShopModal }) => {
  const [referShopDetails, setReferShopDetails] = useState({
    storeName: '',
    phoneNumber: '',
    area: '',
    pinCode: '',
  });
  const [referStatus, setReferStatus] = useState('');
  const [referErrorMsg, setReferErrorMsg] = useState('');
  const {
    storeName, phoneNumber, area, pinCode,
  } = referShopDetails;
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (!validateRegex(name, value)) {
      return;
    }
    setReferShopDetails({ ...referShopDetails, [name]: value });
  };
  const onHide = () => {
    setReferStatus('');
    setReferErrorMsg('');
    setReferShopDetails({
      storeName: '',
      phoneNumber: '',
      area: '',
      pinCode: '',
    });
  };
  const submitReferStore = () => {
    setReferStatus('loading');
    setReferErrorMsg('');
    customerStoreReferral(
      'POST',
      {
        storeName,
        ownerContact: phoneNumber,
        area,
        pinCode,
      },
    ).then((res) => {
      if (res.status === 200) {
        onHide();
        closeReferShopModal();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = '';
      if (
        err
        && err.response
        && err.response.data
        && err.response.status === 400
        && err.response.data[0].server_message
      ) {
        errorMsg = err.response.data[0].server_message;
      } else {
        errorMsg = Constants.String.OOPS[language];
      }
      setReferErrorMsg(errorMsg);
      setReferStatus('error');
    });
  };

  return (
    <div
      className="r_refShop_container"
    >
      <button
        type="button"
        className="r__refShop_close"
        onClick={() => {
          closeReferShopModal();
        }}
      >
        <Svg
          svg="close"
          width="24px"
          fill={Constants.Color.black72}
        />
      </button>
      <div className="r__refShop_body">
        <div className="r__refShop_title">
          Refer your favourite Local Shop
        </div>
        <div className="r__refShop_row">
          <input
            className="form-control fs-0 border-radius-4"
            type="text"
            placeholder="Shop Name"
            name="storeName"
            value={storeName}
            onChange={handleChange}
            maxLength="50"
          />
        </div>

        <div className="r__refShop_row">
          <input
            className="form-control fs-0 border-radius-4"
            type="text"
            placeholder="Contact Number"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handleChange}
            maxLength="10"
          />
        </div>

        <div className="r__refShop_row">
          <input
            className="form-control fs-0 border-radius-4"
            type="text"
            placeholder="Area"
            name="area"
            value={area}
            onChange={handleChange}
            maxLength="50"
          />
        </div>

        <div className="r__refShop_row">
          <input
            className="form-control fs-0 border-radius-4"
            type="text"
            placeholder="Pin Code"
            name="pinCode"
            value={pinCode}
            onChange={handleChange}
            maxLength="6"
          />
        </div>

        <div className="r__refShop_row">
          <button
            type="button"
            className={`r__refShop_button ${(
              referStatus === 'loading'
              || !storeName
              || !(phoneNumber.length === 10)
              || !(pinCode.length === 6)
            ) ? 'r__refShop_button--disabled' : ''}`}
            onClick={() => {
              submitReferStore();
            }}
          >
            {
              referStatus === 'loading' && (
                <div>
                  &nbsp;
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                  />
                </div>
              )
            }
            {Constants.String.REFER_SHOP[language]}
          </button>
        </div>
        {
        !!referErrorMsg && (
          <div
            className="pt-1 text-center text-danger"
          >
            {referErrorMsg}
          </div>
        )
      }
      </div>
    </div>
  );
};

ReferShopModal.propTypes = {
  language: PropTypes.string.isRequired,
  closeReferShopModal: PropTypes.func.isRequired,
};

export default ReferShopModal;
