import React from 'react';
import PropTypes from 'prop-types';
import OnlinePaymentIcon from 'app/components/common/svg/Cart/OnlinePaymentIcon';
import CashOnDeliveryIcon from 'app/components/common/svg/Cart/CashOnDeliveryIcon';

function PaymentPreferenceBar({ cartDetails }) {
  const paymentMethods = cartDetails.store ? cartDetails.store.paymentModes : {};
  const { cash, digital } = paymentMethods;
  if (
    cash && digital
    && cash.isEnabled !== digital.isEnabled
  ) {
    return (
      <div
        className="fs-5 px-3 py-1 d-flex justify-content-center align-items-center bg-yellow"
      >
        <span>
          { digital.isEnabled ? <OnlinePaymentIcon /> : <CashOnDeliveryIcon /> }
          Store accepts
          <b>{digital.isEnabled ? ' Online Payment only' : ' Cash On Delivery Payments only'}</b>
        </span>
      </div>
    );
  }
  return <></>;
}

PaymentPreferenceBar.propTypes = {
  cartDetails: PropTypes.shape({
    store: PropTypes.shape({
      paymentModes: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default PaymentPreferenceBar;
