import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { fbLogPageView } from 'facebook/Facebook';
import { gaLogPageView } from 'ecommerce_ga_events/EcommerceGAEvents';
import * as referalActionTypes from 'app/store/referrals/actions';
import * as Actions from '../../store/Actions';
import { Svg, CustomModal } from '../../components/common';
import { Constants, Utils } from '../../utilities';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as buttonActions from '../../events/buttonActions';
import * as screens from '../../events/screens';
import * as Freshchat from '../../../freshchat/Freshchat';
import { getCDNImageUrl, isAlreadyLoggedIn, isGuestUser } from '../../utilities/Utils';
import ReferFriendInfo from '../../layout/refer-friends/ReferFriendInfo';
import { logButtonClick, logPageLoad } from '../../events/Events';
// import { getReferralMeta } from '../../utilities/Storage';
import Language from '../changeLanguage/Language';
import LoginAlert from '../../layout/guest-user/LoginAlert';
import '../../styles/account/account.scss';
import CustomImage from '../../components/common/CustomImage';
import ReferShopModal from './ReferShopModal';

class Account extends Component {
  constructor() {
    super();
    this.state = {
      showReferral: false,
      showChangeLanguage: false,
      showReferShop: false,
    };
  }


  componentDidMount = () => {
    gaLogPageView();
    fbLogPageView();
    logPageLoad(
      {
        SCREEN: screens.ACCOUNT,
      },
    );
  }

  render() {
    const {
      customer,
      language,
      isMobile,
      history,
      logout,
      toggleLogin,
      toggleManageLocation,
      performGuestLogin,
      referrals,
      processing,
      failure,
      closeDropdown
    } = this.props;

    const {
      [referalActionTypes.REFERRALS_LOAD_REQUEST]: processingReferrals,
    } = processing;
    const {
      [referalActionTypes.REFERRALS_LOAD_REQUEST]: failureReferrals,
    } = failure;

    const { showReferral, showChangeLanguage, showReferShop } = this.state;
    // const referralMeta = getReferralMeta();

    const pageMeta = isMobile && (
      <Helmet>
        <title>
          LoveLocal | My Account
        </title>
        <link
          rel="canonical"
          href={`https://${window.location.host}/account`}
        />
      </Helmet>
    );

    if (isGuestUser()) {
      return (
        <LoginAlert
          language={language}
          screen={screens.ACCOUNT}
        />
      );
    }

    const handleCloseReferShopModal = () => {
      this.setState({
        showReferShop: false,
      });
    }

    return (
      <Container
        className="bg-white r__acc_container"
      >
        {pageMeta}
        <CustomModal
          show={showReferral}
          title={Constants.String.REFER_AND_EARN[language]}
          body={<ReferFriendInfo {...this.props} onHide={() => { this.setState({ showReferral: false }); }} />}
          onHide={() => {
            this.setState({
              showReferral: false,
            });
          }}
          closeButton
        />
        <CustomModal
          show={showReferShop}
          body={<ReferShopModal {...this.props} closeReferShopModal={handleCloseReferShopModal} />}
          onHide={() => {
            this.setState({
              showReferShop: false,
            });
          }}
          closeButton
        />
        <CustomModal
          show={showChangeLanguage}
          title={Constants.String.CHANGE_LANGUAGE[language]}
          body={(
            <Language
              {...this.props}
              callback={() => {
                this.setState({
                  showChangeLanguage: false,
                });
              }}
            />
          )}
          onHide={() => {
            this.setState({
              showChangeLanguage: false,
            });
          }}
          closeButton
        />
        <div>
          <Col
            xs={24}
            className="px-0"
          >
            <Container
              className={`r__acc_profile ${isMobile ? 'fs-4' : 'fs-5'}`}
            >
              <Row className="d-flex align-items-center">
                <Col xs="auto">
                  <div className="r__acc_profile_image">
                      <div 
                        className="r__acc_edit-pencil"
                        onClick={() => {
                          if(!isMobile){
                            closeDropdown()
                          }
                          history.push('/profile')
                        }}
                      >
                        <Svg
                          svg="pencil"
                          fill="none"
                          stroke={Constants.Color.black72}
                          width="24px"
                          strokeWidth="2px"
                        />
                      </div>
                    {
                      (getCDNImageUrl(customer.profile_image)) ? (
                        <CustomImage
                          imageAlt={`${customer.first_name} ${customer.last_name}`}
                          imageUrl={getCDNImageUrl(customer.profile_image)}
                          imageStyle="w-100 h-100 r__acc_profile_image-img"
                          imageType="PROFILE"
                        />
                        ) : (
                        <>
                          <Svg
                            svg="profileIcon"
                            fill="none"
                            stroke={Constants.Color.black72}
                            width="24px"
                            strokeWidth="2px"
                          />
                        </>
                      )
                    }
                  </div>
                </Col>
                <Col
                  className="px-0"
                >
                  <div
                    className="d-flex align-items-start pb-1 mw-150p r__acc_name"
                  >
                    {
                      customer.first_name
                        ? (
                          <b className="text-dark mr-2 text-truncate">
                            {`${customer.first_name} ${customer.last_name}`}
                          </b>
                        ) : ''
                    }
                  </div>
                  <div
                    className="pb-1 r__acc_contact"
                  >
                    {customer.phone_number}
                  </div>
                  <div
                    className="text-truncate pb-1 mw-150p r__acc_contact"
                  >
                    {customer.email}
                  </div>
                </Col>
                <Col
                  xs="auto"
                  className="d-flex justify-content-center r__acc_edit"
                  onClick={() => {
                    if(!isMobile){
                      closeDropdown()
                    }
                    logButtonClick(
                      {
                        BUTTON: buttonActions.EDIT_PROFILE,
                        SCREEN: screens.ACCOUNT,
                      },
                    );
                    history.push('/profile');
                  }}
                >
                  EDIT
                </Col>
              </Row>
            </Container>
          </Col>

          {
            (processingReferrals)
            && (
              <Col className="r__acc_row-refer">
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </Col>
            )
          }
          {
            (failureReferrals)
            && (
              <Col
                xs={24}
                as={Button}
                variant="link"
                className="r__acc_row-refer r__acc_row-refer--0"
                onClick={() => {
                  if(!isMobile){
                    closeDropdown()
                  }
                  logButtonClick(
                    {
                      BUTTON: buttonActions.REFER_AND_EARN,
                      SCREEN: screens.ACCOUNT,
                    },
                  );
                  if (isMobile) {
                    history.push('/refer-and-earn');
                  } else {
                    this.setState({
                      showReferral: true,
                    });
                  }
                }}
              />
            )
          }
          {
            (!processingReferrals && !failureReferrals)
            && (
            <div
              variant="link"
              className={`r__acc_row-refer ${(referrals?.isReferredBefore) ? 'r__acc_row-refer--1' : 'r__acc_row-refer--0'}`}
              onClick={() => {
                if(!isMobile){
                  closeDropdown()
                }
                logButtonClick(
                  {
                    BUTTON: buttonActions.REFER_AND_EARN,
                    SCREEN: screens.ACCOUNT,
                  },
                );
                if (isMobile) {
                  history.push('/refer-and-earn');
                } else {
                  this.setState({
                    showReferral: true,
                  });
                }
              }}
            >
              {
                (referrals?.isReferredBefore) &&
                <>
                  <div className="r__acc_refer-top">
                  <div className="r__acc_refer-title">Refer 5 Friends Get ₹{referrals?.totalReferralAmount || 375}</div>
                    <div className="r__acc_refer-button">refer now</div>
                  </div>

                  <div className="r__acc_refer-rewards">
                    <div className="r__acc_refer-line" />
                    <div className="r__acc_refer-circles">
                      {
                        referrals?.referrals.map((ref) => (
                          <div className="r__acc_refer-circle">
                            <Svg
                              svg={`${(ref.isUnlocked) ? 'checkCircle' : 'lockCircle'}`}
                              fill="none"
                              stroke={Constants.Color.black72}
                              width="24px"
                            />
                            <div>{ref.amount}</div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </>
              }
            </div>
            )
          }

          <Col
            xs={24}
            as={Button}
            variant="link"
            className="py-0 r__acc_row"
            onClick={() => {
              if(!isMobile){
                closeDropdown()
              }
              if (isAlreadyLoggedIn(toggleLogin)) {
                logButtonClick(
                  {
                    BUTTON: buttonActions.PAST_ORDERS_TAB,
                    SCREEN: screens.ACCOUNT,
                  },
                );
                history.push('/orders');
              }
            }}
          >
            <span
              className={`d-flex text-dark py-2 px-4 ${isMobile
                ? 'fs-4' : 'fs-5'}`}
            >
              <span
                className="mr-2"
              >
                <Svg
                  svg="orders"
                  fill="none"
                  stroke={Constants.Color.black72}
                  width="24px"
                  strokeWidth="2px"
                />
              </span>
              <span
                className="d-flex flex-column align-items-start"
              >
                <b>
                  {Constants.String.YOUR_ORDERS[language]}
                </b>
                {isMobile && (
                <span
                  className="font-weight-light fs-5 mt-1"
                >
                  {Constants.String.VIEW_ALL_ORDERS[language]}
                </span>
                )}
              </span>
            </span>
          </Col>

          <Col
            xs={24}
            as={Button}
            variant="link"
            className="py-0 r__acc_row"
            onClick={() => {
              if(!isMobile){
                closeDropdown()
              }
              if (isAlreadyLoggedIn(toggleLogin)) {
                logButtonClick(
                  {
                    BUTTON: buttonActions.MANAGE_LOCATION,
                    SCREEN: screens.ACCOUNT,
                  },
                );
                toggleManageLocation();
              }
            }}
          >
            <span
              className={`d-flex text-dark py-2 px-4 ${isMobile
                ? 'fs-4' : 'fs-5'}`}
            >
              <span
                className="mr-2"
              >
                <Svg
                  svg="location"
                  fill={Constants.Color.black72}
                  width="24px"
                  strokeWidth='1.5px'
                />
              </span>
              <span
                className="d-flex flex-column align-items-start"
              >
                <b>
                  {Constants.String.SAVED_ADDRESSES[language]}
                </b>
                {isMobile && (
                <span
                  className="font-weight-light fs-5 mt-1"
                >
                  {Constants.String.MANAGE_DELIVERY_ADDRESS[language]}
                </span>
                )}
              </span>
            </span>
          </Col>

          <Col
            xs={24}
            as={Button}
            variant="link"
            className="py-0 r__acc_row"
            onClick={() => {
              if(!isMobile){
                closeDropdown()
              }
              if (isAlreadyLoggedIn(toggleLogin)) {
                logButtonClick(
                  {
                    BUTTON: buttonActions.CHANGE_LANGUAGE,
                    SCREEN: screens.ACCOUNT,
                  },
                );
                if (isMobile) {
                  history.push('/language');
                } else {
                  this.setState({
                    showChangeLanguage: true,
                  });
                }
              }
            }}
          >
            <span
              className={`d-flex text-dark py-2 px-4 ${isMobile
                ? 'fs-4' : 'fs-5'}`}
            >
              <span
                className="mr-2"
              >
                <Svg
                  svg="language"
                  width="24px"
                  stroke={Constants.Color.black72}
                  strokeWidth="2"
                />
              </span>
              <span
                className="d-flex flex-column align-items-start"
              >
                <b>
                  {Constants.String.LANGUAGE_SETTTINGS[language]}
                </b>
                {isMobile && (
                <span
                  className="font-weight-light fs-5 mt-1"
                >
                  {Constants.String.CHANGE_LOCAL_LANGUAGE_DESC[language]}
                </span>
                )}
              </span>
            </span>
          </Col>

          <Col
            xs={24}
            as={Button}
            variant="link"
            className="py-0 r__acc_row"
            onClick={() => {
              if(!isMobile){
                closeDropdown()
              }
              logButtonClick(
                {
                  BUTTON: buttonActions.TERMS_AND_CONDITION,
                  SCREEN: screens.ACCOUNT,
                },
              );
              window.open(
                Utils.LOVELOCAL.tnc,
                '_blank',
              );
            }}
          >
            <span
              className={`d-flex text-dark py-2 px-4 ${isMobile
                ? 'fs-4' : 'fs-5'}`}
            >
              <span
                className="mr-2"
              >
                <Svg
                  svg="terms"
                  fill="none"
                  width="24px"
                  stroke={Constants.Color.black72}
                />
              </span>
              <span
                className="d-flex flex-column align-items-start"
              >
                <span>
                  <b>
                    {Constants.String.TERMS_CONDITION[language]}
                  </b>
                </span>
              </span>
            </span>
          </Col>

          <Col
            xs={24}
            as={Button}
            variant="link"
            className="py-0 r__acc_row"
            onClick={() => {
              if(!isMobile){
                closeDropdown()
              }
              logButtonClick(
                {
                  BUTTON: buttonActions.PRIVACY_POLICY,
                  SCREEN: screens.ACCOUNT,
                },
              );
              window.open(
                Utils.LOVELOCAL.privacyPolicy,
                '_blank',
              );
            }}
          >
            <span
              className={`d-flex text-dark py-2 px-4 ${isMobile
                ? 'fs-4' : 'fs-5'}`}
            >
              <span
                className="mr-2"
              >
                <Svg
                  svg="privacy"
                  fill="none"
                  width="24px"
                  stroke={Constants.Color.black72}
                />
              </span>
              <span
                className="d-flex flex-column align-items-start"
              >
                <span>
                  <b>
                    {Constants.String.PRIVACY_POLICY[language]}
                  </b>
                </span>
              </span>
            </span>
          </Col>

          <Col
            xs={24}
            as={Button}
            variant="link"
            className="py-0 r__acc_row"
            onClick={() => {
              if(!isMobile){
                closeDropdown()
              }
              LogClevertapEvent.frequentlyAskedQuesClicked(
                'Account Section',
              );
              logButtonClick(
                {
                  BUTTON: buttonActions.FAQ,
                  SCREEN: screens.ACCOUNT,
                },
              );
              history.push('/faq');
            }}
          >
            <span
              className={`d-flex text-dark py-2 px-4 ${isMobile
                ? 'fs-4' : 'fs-5'}`}
            >
              <span
                className="mr-2"
              >
                <Svg
                  svg="help"
                  fill="none"
                  width="24px"
                  strokeWidth="1.6px"
                  stroke={Constants.Color.black72}
                />
              </span>
              <span
                className="d-flex flex-column align-items-start"
              >
                <span>
                  <b>
                    {Constants.String.FAQS[language]}
                  </b>
                </span>
                {isMobile && (
                <span
                  className="font-weight-light fs-5 mt-1"
                >
                  {Constants.String.FAQS_DESC[language]}
                </span>
                )}
              </span>
            </span>
          </Col>

          <Col
            xs={24}
            as={Button}
            variant="link"
            className="py-0 r__acc_row"
            onClick={() => {
              if(!isMobile){
                closeDropdown()
              }
              logButtonClick(
                {
                  BUTTON: buttonActions.INVITE_STORE,
                  SCREEN: screens.ACCOUNT,
                },
              );
              this.setState({
                showReferShop: true,
              });
            }}
          >
            <span
              className={`d-flex text-dark py-2 px-4 ${isMobile
                ? 'fs-4' : 'fs-5'}`}
            >
              <span
                className="mr-2"
              >
                <Svg
                  svg="referShop"
                  fill={Constants.Color.black72}
                  width="24px"
                />
              </span>
              <span
                className="d-flex flex-column align-items-start"
              >
                <b>
                  {Constants.String.REFER_A__SHOP[language]}
                </b>
              </span>
            </span>
          </Col>

          <Col
            xs={24}
            as={Button}
            variant="link"
            className="py-0 r__acc_row"
            onClick={() => {
              if(!isMobile){
                closeDropdown()
              }
              logButtonClick(
                {
                  BUTTON: buttonActions.SELL_ON_LOVELOCAL,
                  SCREEN: screens.ACCOUNT,
                },
              );
              window.open(
                Utils.LOVELOCAL.partnerPlayStore,
                '_blank',
              );
            }}
          >
            <span
              className={`d-flex text-dark py-2 px-4 ${isMobile
                ? 'fs-4' : 'fs-5'}`}
            >
              <span
                className="mr-2"
              >
                <Svg
                  svg="logo"
                  fill="none"
                  stroke={Constants.Color.black72}
                  width="20px"
                  strokeWidth="1.8px"
                />
              </span>
              <span
                className="d-flex flex-column align-items-start"
              >
                <span>
                  <b>
                    {Constants.String.WANT_TO_SELL_ON_LOVELOCAL[language]}
                  </b>
                </span>
                {isMobile && (
                <span
                  className="font-weight-light fs-5 mt-1"
                >
                  {Constants.String.DOWNLOAD_LOVELOCAL_PARTNER_APP[language]}
                </span>
                )}
              </span>
            </span>
          </Col>

          <Col
            xs={24}
            as={Button}
            variant="link"
            className="py-0 r__acc_row"
            onClick={() => {
              if(!isMobile){
                closeDropdown()
              }
              logButtonClick(
                {
                  BUTTON: buttonActions.LOGOUT,
                  SCREEN: screens.ACCOUNT,
                },
              );
              logout();
              LogClevertapEvent.logout();
              Freshchat.clearUser();
              history.push('/');
              performGuestLogin();
            }}
          >
            <span
              className={`d-flex text-dark py-2 px-4 ${isMobile
                ? 'fs-4' : 'fs-5'}`}
            >
              <span
                className="mr-2"
              >
                <Svg
                  svg="logout"
                  fill="none"
                  stroke={Constants.Color.black72}
                  width="20px"
                />
              </span>
              <span
                className="d-flex flex-column align-items-start"
              >
                <span>
                  <b>
                    {Constants.String.LOGOUT[language]}
                  </b>
                </span>
              </span>
            </span>
          </Col>

          <div
            className="r__acc_footer"
          >
            <div className="r__acc_barcode" />
            <div className="r__acc_copy">
              <div className="r__acc_copy-heading">
                Easily deliveries, Live order tracking and much more on the
                <span> LoveLocal App</span>
              </div>
              <div className="r__acc_copy-info">
                Scan the QR code to download the app
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.main.customer,
  processing: state.main.requestsProcessing,
  failure: state.main.requestsFailure,
  referrals: state.main.referrals,
});

const mapDispatchToProps = (dispatch) => ({
  logout:
  () => {
    dispatch(Actions.logout());
  },
  toggleLogin:
  () => {
    dispatch(Actions.toggleLogin());
  },
  toggleManageLocation:
  () => {
    dispatch(Actions.toggleManageLocation());
  }
});

Account.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_number: PropTypes.string,
    profile_image: PropTypes.string,
    email: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  toggleManageLocation: PropTypes.func.isRequired,
  page: PropTypes.string,
  performGuestLogin: PropTypes.func.isRequired,
};

Account.defaultProps = {
  customer: null,
  page: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
