import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <g id="Frames-24px">
      <rect fill="none" width={width} />
    </g>
    <g id="Outline">
      <g>
        <path
          fill={fill}
          d="M20,11H4c-1.103,0-2,0.898-2,2v9c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-9
           C22,11.898,21.103,11,20,11z M18.113,13L12,16.821L5.887,13H18.113z M4,22v-7.821l7.47,4.669C11.632,18.949,11.816,19,12,19
           s0.368-0.051,0.53-0.152L20,14.179L20.001,22H4z"
        />
        <polygon fill={fill} points="16.707,4.707 15.293,3.293 13,5.586 13,1 11,1 11,5.586 8.707,3.293 7.293,4.707 12,9.415" />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
