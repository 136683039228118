import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <g
        fill={fill}
      >
        <g>
          <g>
            <path
              d="M6.77 6c.757-.007 1.402.547 1.508 1.295.095.722.273 1.432.528 2.115.207.55.075 1.17-.34 1.588l-.957.956c1.073 1.884 2.637 3.445 4.525 4.516l.958-.956c.418-.413 1.04-.545 1.591-.338.684.254 1.395.431 2.12.526.758.107 1.316.764 1.297 1.528v2.258c.001.424-.176.83-.49 1.116-.312.286-.732.428-1.155.39-2.32-.252-4.55-1.043-6.508-2.31-1.823-1.157-3.368-2.698-4.525-4.517-1.275-1.964-2.068-4.2-2.316-6.526-.038-.42.103-.838.388-1.15.286-.313.69-.49 1.113-.491H6.77zm9.355-3C18.817 3 21 4.847 21 7.125s-2.183 4.125-4.875 4.125c-.322 0-.636-.026-.94-.077L12.75 12l.628-1.467c-1.285-.742-2.128-1.992-2.128-3.408C11.25 4.847 13.433 3 16.125 3z"
              transform="translate(-264 -625) translate(248 617) translate(16 8)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
