import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g stroke={stroke}>
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-250 -376) translate(0 116) translate(16 236) translate(224 16) translate(10 8)">
                  <circle cx="8" cy="8" r="6" />
                </g>
                <g>
                  <path d="M4.125 6.3l1.071 1.2m0 0l2.679-3" transform="translate(-250 -376) translate(0 116) translate(16 236) translate(224 16) translate(10 8) translate(2 2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
