import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    style={{
      width,
    }}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8 9.131-3.434 3.434-1.132-1.13L6.87 8 3.434 4.565l1.132-1.13L8 6.867l3.434-3.434 1.132 1.131L9.13 8l3.435 3.434-1.132 1.132L8 9.13z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
