import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const FallbackShimmer = () => (
  <Container
    fluid
  >
    <Row>
      <Col
        xs={24}
        className="fallback-loader"
      />
    </Row>
  </Container>
);

export default FallbackShimmer;
