import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const CategoryCard = () => (
  <Container className="my-2">
    <Row>
      <Col xs={6} className="bg-medium animate shimmer p-6" />
      <Col xs={17} className="ml-2">
        <div className="w-50 my-1 bg-medium animate shimmer p-2" />
        <div className="my-1 bg-medium animate shimmer p-2" />
      </Col>
    </Row>
  </Container>
);

export default CategoryCard;
