import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner, Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomScroller from 'app/components/common/CustomScroller';
import DefaultGuestLocationAlert from 'app/components/common/DefaultGuestLocationAlert';
import { popularCityAndLocality } from '../../../api/api';
import Footer from './Footer';
import FooterMobile from './FooterMobile';
import { Placeholder, Svg } from '../../components/common';
import { Constants } from '../../utilities';
import '../../styles/all_city_locality/cityLocalityPage.scss';

function AllCityAndLocality(props) {
  const { isMobile, history, language } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [cities, setCities] = useState(null);
  const [selectedLetter, setSelectedLetter] = useState('A');
  const [seeAll, setSeeAll] = useState({});

  const handleLoad = () => {
    setLoading(true);
    popularCityAndLocality(
      'GET',
      {
        letterWiseListing: true,
      },
    ).then((res) => {
      setData(res.data.cities);
      setCities(res.data.cities[0]);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  useEffect(() => {
    handleLoad();
  }, []);

  if (loading) {
    return (
      <Row
        className="w-100 py-3 mx-0 mt-3 justify-content-center"
      >
        <Spinner
          animation="border"
          variant="primary"
        />
      </Row>
    );
  }

  if (error) {
    return (
      <Placeholder
        language={language}
        handleRetry={handleLoad}
        imageSrc="/images/error-placeholder.png"
        heading={Constants.String.OH_NO[language]}
      />
    );
  }

  const cityLocality = (
    <Container
      className="px-0 bg-white border-radius"
    >
      <Row
        className="mx-0 p-1"
      >
        <Col
          xs={24}
        >
          {
            !!data
            && (
              <CustomScroller
                content={(
                  <div
                    className="d-flex"
                  >
                    <>
                      {
                        data.map((item) => (
                          <Col
                            className={`${selectedLetter === item.letter ? 'border-pink' : 'border-width'}
                             ${isMobile ? 'pr-3' : ''} text-center`}
                          >
                            <Button
                              variant="link"
                              onClick={() => {
                                setSelectedLetter(item.letter);
                                setCities(item);
                                setSeeAll({});
                              }}
                              className={`fs-2 ${selectedLetter === item.letter ? '' : 'text-black'}
                                ${isMobile ? '' : 'p-0'}`}
                            >
                              {item.letter}
                            </Button>
                          </Col>
                        ))
                      }
                    </>
                  </div>
                )}
                contentPrefix="city-cityLocality"
              />
            )
          }
        </Col>
      </Row>
      <Row
        className="mx-0 p-2"
      >
        <Col
          xs={24}
        >
          {
            !!cities && (
              <>
                {cities.city.sort((a, b) => (
                  (a.name > b.name) ? 1 : -1
                )).map((city) => (
                  <Container
                    className="border-bottom p-3"
                  >
                    <Row>
                      <Col
                        xs={`${isMobile ? 8 : 12}`}
                      >
                        <Link
                          className="text-black fs-3"
                          to={`/india/${city.filterValue}`}
                        >
                          <b>{city.name}</b>
                        </Link>
                      </Col>
                      {
                        city.locality.length > 4 && (
                          <Col
                            xs={`${isMobile ? 16 : 12}`}
                            className="d-flex justify-content-end"
                          >
                            <Button
                              variant="link"
                              onClick={() => {
                                if (seeAll[city.name]) {
                                  setSeeAll({
                                    ...seeAll,
                                    [city.name]: false,
                                  });
                                } else {
                                  setSeeAll({
                                    ...seeAll,
                                    [city.name]: true,
                                  });
                                }
                              }}
                              className={`fs-5 ${city.locality.length > 4 ? 'text-black' : ''}`}
                              disabled={city.locality.length <= 4}
                            >
                              {seeAll[city.name] ? 'See fewer locations' : 'See all locations'}
                              &nbsp;
                              {
                                seeAll[city.name]
                                  ? (
                                    <Svg
                                      svg="arrowUp"
                                      width="24px"
                                      height="24px"
                                    />
                                  ) : (
                                    <Svg
                                      svg="arrowDown"
                                      width="24px"
                                      stroke={
                                        city.locality.length > 4
                                          ? Constants.Color.black : Constants.Color.gray
                                      }
                                    />
                                  )
                              }
                            </Button>
                          </Col>
                        )
                      }
                    </Row>
                    <Row>
                      {
                        city.locality.sort((a, b) => (
                          (a.name > b.name) ? 1 : -1
                        )).slice(0, 4).map((locality) => (
                          <Col
                            xs={`${isMobile ? 12 : 6}`}
                            className="pt-2"
                          >
                            <Link
                              className="text-gray fs-5"
                              to={`/india/${city.filterValue}/${locality.filterValue}`}
                            >
                              {locality.name}
                            </Link>
                          </Col>
                        ))
                      }
                    </Row>
                    <Row>
                      {
                        seeAll[city.name]
                        && city.locality.slice(4).map((locality) => (
                          <Col
                            xs={`${isMobile ? 12 : 6}`}
                            className="pt-2"
                          >
                            <Link
                              className="text-gray fs-5"
                              to={`/india/${city.filterValue}/${locality.filterValue}`}
                            >
                              {locality.name}
                            </Link>
                          </Col>
                        ))
                      }
                    </Row>
                  </Container>
                ))}
              </>
            )
          }
        </Col>
      </Row>
    </Container>
  );

  return (
    <Container
      fluid
      className="mx-0 px-0 bg-light"
    >
      <DefaultGuestLocationAlert
        {...props}
      />
      <Container
        fluid
      >
        <Row>
          <Col
            xs={24}
            className="bg-primary pt-3 px-0 d-none d-md-block cityHeader"
          >
            <Container>
              <Row
                className="align-items-center py-4"
              >
                <Col
                  xs="auto"
                  className="fs-5 pr-0"
                >
                  <Link
                    className="text-white"
                    to="/"
                  >
                    HOME
                  </Link>
                </Col>
                <Col
                  xs="auto"
                  className="fs-5 px-0"
                >
                  <Link
                    className="text-white"
                    to="/shops"
                  >
                    &nbsp;/&nbsp;
                    INDIA
                  </Link>
                </Col>
                <Col
                  xs="auto"
                  className="fs-5 px-0"
                >
                  <Link
                    className="text-white"
                    to="/india/all"
                  >
                    &nbsp;/&nbsp;
                    ALL
                  </Link>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            xs={24}
            className="pb-8 px-0 d-none d-md-block margin-top"
          >
            {cityLocality}
          </Col>
        </Row>

        {/* Mobile View */}
        <Row
          className="px-0"
        >
          <Col
            xs={24}
            className="bg-white py-2 px-0 d-md-none"
          >
            <Container>
              <Row
                className="align-items-center pt-3"
              >
                <Col
                  xs="auto"
                  className="fs-5 pr-0"
                >
                  <Link
                    className="text-gray"
                    to="/"
                  >
                    <b>HOME</b>
                  </Link>
                </Col>
                <Col
                  xs="auto"
                  className="fs-5 px-0"
                  onClick={() => {
                    history.push('/shops');
                  }}
                >
                  <Link
                    className="text-gray"
                    to="/shops"
                  >
                    <b>
                      &nbsp;/&nbsp;
                      INDIA
                    </b>
                  </Link>
                </Col>
                <Col
                  xs="auto"
                  className="fs-5 px-0"
                >
                  <Link
                    className="text-gray"
                    to="/india/all"
                  >
                    <b>
                      &nbsp;/&nbsp;
                      ALL
                    </b>
                  </Link>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            xs={24}
            className="bg-white px-0 d-md-none pb-8"
          >
            {cityLocality}
          </Col>
        </Row>
      </Container>

      <div
        className="d-md-none bg-black"
      >
        <FooterMobile
          {...props}
          language={language}
        />
        <div className="p-6" />
      </div>
      <div
        className="d-none d-md-block bg-black"
      >
        <Footer
          {...props}
          language={language}
        />
      </div>
    </Container>
  );
}

AllCityAndLocality.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default AllCityAndLocality;
