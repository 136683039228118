import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const StoreCard = () => (
  <Container className="mx-2 d-inline-block store-card">
    <Row className="mx-0">
      <Col
        xs={6}
        className="line-shimmer-card bg-medium animate shimmer"
      />
      <Col xs={1} className="px-0" />
      <Col
        xs={17}
      >
        <Row>
          <Col xs={12} className="bg-medium animate shimmer p-2" />
          <Col xs={24} className="mt-2 bg-medium animate shimmer p-2" />
          <Col xs={24} className="mt-2 bg-medium animate shimmer p-2" />
          <Col xs={24} className="mt-2 bg-medium animate shimmer p-2" />
        </Row>
      </Col>
    </Row>
    <Row className="mx-0">
      <Col
        xs={24}
        className="mt-6 p-2 bg-medium animate shimmer"
      />
    </Row>
  </Container>
);

export default StoreCard;
