import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.412 4.882A.6.6 0 0 1 10 4.4h4a.6.6 0 0 1 .588.482l.404 2.018H19a.6.6 0 1 1 0 1.2h-1.425l-.396 9.508a2.6 2.6 0 0 1-2.597 2.492H9.418a2.6 2.6 0 0 1-2.597-2.492L6.424 8.1H5a.6.6 0 0 1 0-1.2h4.008l.404-2.018zm.82 2.018h3.536l-.26-1.3h-3.016l-.26 1.3zM7.625 8.1l.395 9.458A1.4 1.4 0 0 0 9.418 18.9h5.164a1.4 1.4 0 0 0 1.398-1.342l.394-9.458H7.625zm2.875 2.3a.6.6 0 0 1 .6.6v4.5a.6.6 0 1 1-1.2 0V11a.6.6 0 0 1 .6-.6zm3 0a.6.6 0 0 1 .6.6v4.5a.6.6 0 1 1-1.2 0V11a.6.6 0 0 1 .6-.6z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
