import React from 'react';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Svg } from 'app/components/common';
import StoreFAQs from 'app/pages/stores/StoreFAQs';
import { Constants } from 'app/utilities';
import { LOVELOCAL, getSEOFriendlyURL, getSeoShopUrl } from 'app/utilities/Utils';

function StoreFooterSEOData({
  storeDetails, language, b2bStorePreview,
  categories,
}) {
  let city = '';
  let locality = '';
  let storeType = '';
  let { hasSeoData } = { hasSeoData: false };
  if (storeDetails) {
    ({ hasSeoData } = getSeoShopUrl(
      'india',
      storeDetails.city,
      storeDetails.locality,
      storeDetails.store_type,
      storeDetails.store_name,
      storeDetails.uid,
    ));
    city = getSEOFriendlyURL(storeDetails.city);
    locality = getSEOFriendlyURL(storeDetails.locality);
    storeType = getSEOFriendlyURL(storeDetails.store_type);
  }

  return (
    <>
      {/* FAQ's */}
      <Col
        xs={24}
        className="px-0"
      >
        <Container
          fluid
        >
          <StoreFAQs
            storeDetails={storeDetails}
            language={language}
          />
        </Container>
      </Col>

      {/* Store Details Payment and Desc. Info */}
      <Col
        xs={24}
        className="px-0 bg-light"
      >
        <Container>
          {/* Desktop View */}
          <Row
            className="mx-0 pt-8 d-none d-md-block"
          >
            <Col
              xs={24}
              className="px-0"
            >
              <Row>
                <Col
                  xs={8}
                  className="p-7 text-black-72 fs-5"
                >
                  <div
                    className="font-weight-black text-center"
                  >
                    <span
                      className="border-bottom border-black-72"
                    >
                      Address
                    </span>
                  </div>
                  <div
                    className="pt-3 text-center"
                  >
                    {storeDetails.completeAddress}
                  </div>
                </Col>
                <Col
                  xs={8}
                  className="p-7 text-black-72 fs-5"
                >
                  <div
                    className="font-weight-black text-center"
                  >
                    <span
                      className="border-bottom border-black-72"
                    >
                      Payment Options
                    </span>
                  </div>
                  <div
                    className="pt-3 text-center"
                  >
                    {storeDetails.payment_mode.reduce((acc, cMode) => (
                      cMode.isEnabled ? [...acc, cMode.name] : acc
                    ), []).join(', ')}
                  </div>
                </Col>
                <Col
                  xs={8}
                  className="p-7 text-black-72 fs-5"
                >
                  <div
                    className="font-weight-black text-center"
                  >
                    <span
                      className="border-bottom border-black-72"
                    >
                      Contact
                    </span>
                  </div>
                  <div
                    className="pt-3 text-center"
                  >
                    {LOVELOCAL.supportEmail}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Mobile View */}
          <Row
            className="mx-0 py-7 d-md-none"
          >
            <Col
              xs={24}
              className="px-0"
            >
              <Row>
                <Col
                  xs={24}
                  className="font-weight-black fs-4"
                >
                  {Constants.String.SHOP_DETAILS[language]}
                </Col>
              </Row>
              <Row
                className="align-items-center pt-3"
              >
                <Col
                  xs="auto"
                  className="pr-0"
                >
                  <Svg
                    svg="location"
                    fill={Constants.Color.black72}
                    width="1.5rem"
                  />
                </Col>
                <Col
                  className="pl-2 fs-6 text-black-72"
                >
                  {storeDetails.completeAddress}
                </Col>
              </Row>
              <Row
                className="align-items-center pt-3"
              >
                <Col
                  xs="auto"
                  className="pr-0"
                >
                  <Svg
                    svg="cashIcon"
                    fill1={Constants.Color.white}
                    fill2={Constants.Color.black72}
                    width="1.5rem"
                  />
                </Col>
                <Col
                  className="pl-2 fs-6 text-black-72"
                >
                  {storeDetails.payment_mode.reduce((acc, cMode) => (
                    cMode.isEnabled ? [...acc, cMode.name] : acc
                  ), []).join(', ')}
                </Col>
              </Row>
              <Row
                className="align-items-center pt-3"
              >
                <Col
                  xs="auto"
                  className="pr-0"
                >
                  <Svg
                    svg="phone"
                    fill={Constants.Color.black72}
                    width="1.5rem"
                  />
                </Col>
                <Col
                  className="pl-2 fs-6 text-black-72"
                >
                  {`+91 ${LOVELOCAL.support}`}
                </Col>
              </Row>
              <Row
                className="align-items-center pt-3"
              >
                <Col
                  xs="auto"
                  className="pr-0"
                >
                  <Svg
                    svg="supportEmail"
                    stroke={Constants.Color.black72}
                    width="1.5rem"
                  />
                </Col>
                <Col
                  className="pl-2 fs-6 text-black-72"
                >
                  {LOVELOCAL.supportEmail}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Col>

      {
        (!b2bStorePreview && hasSeoData)
        && (
        <Col
          xs={24}
          className="px-0"
        >
          <Container>
            <Row
              className="pt-3"
            >
              <Col
                xs={24}
                className="text-center font-weight-black text-black-72 fs-4"
              >
                {Constants.String.LOOKING_FOR_SOMETHING_ELSE[language]}
              </Col>
            </Row>
            <Row
              className="pt-3 justify-content-center"
            >
              <Col
                xs="auto"
                className="mx-2 mb-2 fs-6 px-3 py-1 bg-white border-radius-8"
              >
                <Link
                  to={`/india/${city}`}
                  className="text-black"
                >
                  Shops in&nbsp;
                  {storeDetails.city}
                </Link>
              </Col>
              <Col
                xs="auto"
                className="mx-2 mb-2 fs-6 border-radius-8 px-3 py-1 bg-white"
              >
                <Link
                  to={`/india/${city}/${locality}`}
                  className="text-black"
                >
                  Shops in&nbsp;
                  {storeDetails.locality}
                </Link>
              </Col>
              <Col
                xs="auto"
                className="mx-2 mb-2 fs-6 border-radius-8 px-3 py-1 bg-white"
              >
                <Link
                  to={`/india/${city}/c/${storeType}`}
                  className="text-black"
                >
                  {storeDetails.store_type}
                  &nbsp;Shops in&nbsp;
                  {storeDetails.city}
                </Link>
              </Col>
              <Col
                xs="auto"
                className="mx-2 mb-2 fs-6 border-radius-8 px-3 py-1 bg-white"
              >
                <Link
                  to={`/india/${city}/${locality}/c/${storeType}`}
                  className="text-black"
                >
                  {storeDetails.store_type}
                  &nbsp;Shops in&nbsp;
                  {storeDetails.locality}
                </Link>
              </Col>
            </Row>
          </Container>
        </Col>
        )
      }
      {
        (!!categories && categories.count > 0)
        && (
          <Col
            xs={24}
            className="px-0"
          >
            <Container>
              <Row
                className="pt-3"
              >
                <Col
                  xs={24}
                  className="text-center font-weight-black text-black-72 fs-4"
                >
                  {Constants.String.PRODUCT_CATEGORY_AVAILABLE[language]}
                </Col>
              </Row>
              <Row
                className="pt-3 justify-content-center"
              >
                {categories.data.map((item) => (
                  <Col
                    xs="auto"
                    key={item.name}
                    className="mx-2 mb-2 fs-6 px-3 py-1 bg-white border-radius-8"
                  >
                    <Link
                      to={`/pc/${getSEOFriendlyURL(item.categoryCode)}`}
                      className="text-black"
                    >
                      {item.name}
                    </Link>
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
        )
      }

      {
        !!storeDetails.storeDescription && (
          <Col
            xs={24}
            className="px-0"
          >
            <Container>
              <Row
                className="pt-6"
              >
                <Col
                  xs={24}
                  className="text-center text-black-72 font-weight-black fs-4 pb-3"
                >
                  {Constants.String.SHOP_DESCRIPTION[language]}
                </Col>
                <Col
                  xs={24}
                  className="text-center text-black-64 fs-6"
                >
                  {storeDetails.storeDescription}
                </Col>
              </Row>
            </Container>
          </Col>
        )
      }

      <Col
        xs={24}
        className={`px-0 ${
          b2bStorePreview ? 'pointer-event-none' : ''
        }`}
      >
        <Container>
          <Row
            className="mx-0 pt-8 text-black-72 fs-6"
          >
            <Col
              xs={24}
              lg="auto"
              className="mx-auto px-0"
            >
              <Button
                block
                variant="outline-secondary"
                className="border-radius-8 px-6 py-3"
              >
                <Link
                  to="/member/terms"
                  className="text-black-72 fs-5"
                >
                  {Constants.String.RETURN_REFUND_CANCELLATION_POLICY[language]}
                </Link>
              </Button>
            </Col>
          </Row>
        </Container>
      </Col>
    </>
  );
}

StoreFooterSEOData.propTypes = {
  storeDetails: PropTypes.shape({
    country: PropTypes.string,
    city: PropTypes.string,
    locality: PropTypes.string,
    store_type: PropTypes.string,
    store_name: PropTypes.string,
    uid: PropTypes.number,
    completeAddress: PropTypes.string,
    payment_mode: PropTypes.arrayOf(PropTypes.shape({})),
    storeDescription: PropTypes.string,
  }).isRequired,
  language: PropTypes.string.isRequired,
  b2bStorePreview: PropTypes.bool.isRequired,
  categories: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    count: PropTypes.number,
  }),
};

StoreFooterSEOData.defaultProps = {
  categories: null,
};

export default StoreFooterSEOData;
