import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 23.546 23.546"
  >
    <g transform="translate(-146 -1154)">
      <circle
        cx="3.532"
        cy="3.532"
        r="3.532"
        transform="translate(154.241 1162.241)"
        fill={fill}
      />
      <path
        d="M169.546,1164.6H167.11a9.428,9.428,0,0,0-8.16-8.16V1154H156.6v2.436a9.429,9.429,0,0,0-8.16,8.16H146v2.354h2.436a9.429,9.429,0,0,0,8.16,8.16v2.436h2.355v-2.436a9.428,9.428,0,0,0,8.16-8.16h2.436Zm-11.773,8.241a7.064,7.064,0,1,1,7.064-7.064A7.072,7.072,0,0,1,157.773,1172.837Z"
        transform="translate(0)"
        fill={fill}
      />
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
