import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 5L0.669873 0.499999L9.33013 0.5L5 5Z"
      fill="black"
    />
  </svg>
);

Svg.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
