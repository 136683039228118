import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  width, height, isCircleTick, circleTickColor, pathFill,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 9h14v10.76a1.5 1.5 0 0 1 1.38.187l.54.384a1.375 1.375 0 0 1-.195 2.358l-5.482 2.66a1.5 1.5 0 0 1-.655.151h-6.024a1.5 1.5 0 0 1-1.085-.464l-2.272-2.381a.5.5 0 0 0-.362-.155H3A1.5 1.5 0 0 1 1.5 21v-3A1.5 1.5 0 0 1 3 16.5h3.5V9zm.355 8.479a.502.502 0 0 0 .534-.165l1.804-.63a.5.5 0 0 1 .527.127l2.123 2.225a1.5 1.5 0 0 0 1.085.464h2.008a.5.5 0 0 1 .361.155l.383.4a.5.5 0 0 1 .138.346V21.5h-2.89a.5.5 0 0 1-.362-.155l-1.613-1.69a.5.5 0 0 0-.724.69l1.614 1.69a1.5 1.5 0 0 0 1.085.465h4.099a1.5 1.5 0 0 0 .696-.171l3.057-1.602a.5.5 0 0 1 .521.035l.541.384a.375.375 0 0 1-.053.643l-5.482 2.66a.5.5 0 0 1-.219.051h-6.024a.5.5 0 0 1-.361-.155l-2.273-2.38a1.5 1.5 0 0 0-1.085-.465H3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h3.603c.085 0 .169-.007.252-.021zm9.963 4.021v-1.1a1.5 1.5 0 0 0-.415-1.035l-.382-.4a1.5 1.5 0 0 0-1.085-.465h-2.008a.5.5 0 0 1-.362-.155l-2.123-2.224a1.5 1.5 0 0 0-1.58-.38l-1.363.475V10h12v10.269l-2.241 1.174a.5.5 0 0 1-.232.057h-.209zM13 11.5h5v3h-5v-3zm1 1v1h3v-1h-3z"
      fill={pathFill}
    />
    {
      !!isCircleTick && (
        <>
          <circle
            cx="23"
            cy="6"
            r="6"
            fill="#fff"
          />
          <circle
            cx="23"
            cy="6"
            r="6"
            fill={circleTickColor}
            fillOpacity=".6"
          />
          <circle
            cx="23"
            cy="6"
            r="5.5"
            stroke="#000"
            strokeOpacity=".32"
          />
          <path
            d="m20 6.046 2 2.204 4-4.5"
            stroke="#fff"
            strokeWidth="2"
          />
        </>
      )
    }
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  isCircleTick: PropTypes.bool.isRequired,
  pathFill: PropTypes.string.isRequired,
  circleTickColor: PropTypes.string.isRequired,
};

export default Svg;
