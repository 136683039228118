import * as React from 'react';

const ValueOfferActive = (props) => (
  <svg
    width={30}
    height={29}
    viewBox="0 0 30 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 26L14.3702 23.5121L17.7851 23.8944L18.0015 20.465L20.8633 18.5628L18.8247 15.7966L19.7942 12.5L16.4545 11.6913L15.0782 8.54277L12 10.07L8.92181 8.54277L7.54547 11.6913L4.20576 12.5L5.17527 15.7966L3.13672 18.5628L5.99843 20.465L6.2149 23.8944L9.62979 23.5121L12 26Z"
      fill="url(#paint0_linear_1856_36846)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 27C12.2736 27 12.5353 26.8879 12.724 26.6898L14.752 24.5611L17.6738 24.8882C17.9457 24.9186 18.2183 24.8363 18.4279 24.6604C18.6375 24.4846 18.7659 24.2305 18.7831 23.9574L18.9683 21.0232L21.4168 19.3956C21.6447 19.2442 21.8006 19.0059 21.8481 18.7365C21.8956 18.467 21.8306 18.1899 21.6683 17.9696L19.9241 15.6028L20.7536 12.7821C20.8308 12.5196 20.7971 12.237 20.6602 12C20.5234 11.763 20.2955 11.5925 20.0296 11.5281L17.1721 10.8362L15.9945 8.14223C15.8849 7.89151 15.6773 7.69666 15.4202 7.60307C15.1631 7.50949 14.8788 7.52535 14.6337 7.64696L12 8.95368L9.36626 7.64696C9.12115 7.52535 8.83691 7.50949 8.57979 7.60307C8.32267 7.69666 8.11513 7.89151 8.00553 8.14223L6.82792 10.8362L3.97042 11.5281C3.70449 11.5925 3.47655 11.763 3.33974 12C3.20293 12.237 3.16919 12.5196 3.24639 12.7821L4.07592 15.6028L2.33171 17.9696C2.16938 18.1899 2.1044 18.467 2.15192 18.7365C2.19943 19.0059 2.35529 19.2442 2.58316 19.3956L5.03168 21.0232L5.21689 23.9574C5.23413 24.2305 5.36251 24.4846 5.57212 24.6604C5.78172 24.8363 6.05425 24.9186 6.32617 24.8882L9.24799 24.5611L11.276 26.6898C11.4647 26.8879 11.7264 27 12 27ZM9.62979 23.5121L6.21491 23.8944L5.99844 20.465L3.13672 18.5628L5.17528 15.7966L4.20577 12.5L7.54548 11.6913L8.92181 8.54277L12 10.07L15.0782 8.54277L16.4545 11.6913L19.7942 12.5L18.8247 15.7966L20.8633 18.5628L18.0015 20.465L17.7851 23.8944L14.3702 23.5121L12 26L9.62979 23.5121Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.60293 13.6318C9.51438 13.7647 9.44336 13.977 9.44336 14.2364C9.44336 14.4959 9.51438 14.7082 9.60293 14.8411C9.69381 14.9774 9.76445 14.9837 9.77488 14.9837C9.78531 14.9837 9.85595 14.9774 9.94683 14.8411C10.0354 14.7082 10.1064 14.4959 10.1064 14.2364C10.1064 13.977 10.0354 13.7647 9.94683 13.6318C9.85595 13.4955 9.78531 13.4892 9.77488 13.4892C9.76445 13.4892 9.69381 13.4955 9.60293 13.6318ZM8.77088 13.0771C8.98095 12.762 9.32607 12.4892 9.77488 12.4892C10.2237 12.4892 10.5688 12.762 10.7789 13.0771C10.9913 13.3957 11.1064 13.8071 11.1064 14.2364C11.1064 14.6658 10.9913 15.0772 10.7789 15.3958C10.5688 15.7109 10.2237 15.9837 9.77488 15.9837C9.32607 15.9837 8.98095 15.7109 8.77088 15.3958C8.55848 15.0772 8.44336 14.6658 8.44336 14.2364C8.44336 13.8071 8.55848 13.3957 8.77088 13.0771Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7602 19.4525C13.6716 19.5854 13.6006 19.7977 13.6006 20.0571C13.6006 20.3166 13.6716 20.5289 13.7602 20.6618C13.851 20.7981 13.9217 20.8044 13.9321 20.8044C13.9425 20.8044 14.0132 20.7981 14.1041 20.6618C14.1926 20.5289 14.2636 20.3166 14.2636 20.0571C14.2636 19.7977 14.1926 19.5854 14.1041 19.4525C14.0132 19.3162 13.9425 19.3099 13.9321 19.3099C13.9217 19.3099 13.851 19.3162 13.7602 19.4525ZM12.9281 18.8978C13.1382 18.5827 13.4833 18.3099 13.9321 18.3099C14.3809 18.3099 14.726 18.5827 14.9361 18.8978C15.1485 19.2164 15.2636 19.6278 15.2636 20.0571C15.2636 20.4865 15.1485 20.8979 14.9361 21.2165C14.726 21.5316 14.3809 21.8044 13.9321 21.8044C13.4833 21.8044 13.1382 21.5316 12.9281 21.2165C12.7157 20.8979 12.6006 20.4865 12.6006 20.0571C12.6006 19.6278 12.7157 19.2164 12.9281 18.8978Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0508 12.5795C15.277 12.7379 15.332 13.0497 15.1737 13.2759L9.35302 21.5911C9.19466 21.8173 8.8829 21.8724 8.65667 21.714C8.43045 21.5556 8.37543 21.2439 8.53379 21.0177L14.3544 12.7024C14.5128 12.4762 14.8246 12.4212 15.0508 12.5795Z"
      fill="black"
    />
    <circle cx={22.334} cy={7} r={6.5} fill="#B8238E" stroke="#7D005A" />
    <path
      d="M18.834 7.05357L21.1673 9.625L25.834 4.375"
      stroke="white"
      strokeWidth={2}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1856_36846"
        x1={2.99999}
        y1={8}
        x2={28.0919}
        y2={17.6103}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9C132" />
        <stop offset={1} stopColor="#F9ED32" />
      </linearGradient>
    </defs>
  </svg>
);
export default ValueOfferActive;
