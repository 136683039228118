import React from 'react';
import PropTypes from 'prop-types';

const OrderModified = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <circle cx={12} cy={12} r={12} fill="#B8238E" />
    <path
      fill="#B8238E"
      stroke="#fff"
      d="m10.677 6.02-4.734 8.874A1.5 1.5 0 0 0 7.267 17.1h9.466a1.5 1.5 0 0 0 1.324-2.206L13.324 6.02c-.565-1.059-2.083-1.059-2.647 0Z"
    />
    <path
      fill="#fff"
      d="M12.642 8.59v2.516c0 .27-.016.538-.048.805-.032.264-.075.534-.128.81h-.924a12.152 12.152 0 0 1-.127-.81 6.696 6.696 0 0 1-.049-.805V8.589h1.276Zm-1.425 5.72c0-.106.019-.205.057-.296a.796.796 0 0 1 .163-.237.774.774 0 0 1 .246-.159.761.761 0 0 1 .308-.061c.109 0 .21.02.304.061a.774.774 0 0 1 .246.159c.07.067.125.147.163.237a.738.738 0 0 1-.163.832.77.77 0 0 1-.55.216.77.77 0 0 1-.554-.216.794.794 0 0 1-.163-.238.788.788 0 0 1-.057-.299Z"
    />
  </svg>
);

OrderModified.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default OrderModified;
