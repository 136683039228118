import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width,
    }}
  >
    <path
      d="M0 0h24v24H0V0z"
      fill="none"
    />
    <path
      d="M7 15v2h10v-2H7zm-4 6h18v-0zm0-8h18v-2H3v2zm4-6v2h10V7H7zM3"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
