import React from 'react';
import { Svg } from 'app/components/common';
import { Constants } from 'app/utilities';
import { dukaan, businessTips, zubani } from './constants';
import style from './index.module.scss';

const PartnerFooter = () => (
  <footer>
    <div className={style.footer_outer}>
      <div className={style.footer_main}>
        <div className={style.footer_section}>
          <div className={style.sell_logo}>
            <div className={style.sell_img}>
              <img src="/images/footer-shopkeeper.png" alt="Sell Now" />
            </div>
            <div className={style.shopkeeper_right}>
              <div className={style.shop_head}>Banao Apni Digital Dukaan!</div>
              <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=mpaani.com.android.yodaapp" className={style.textViolet}>
                <div className={style.shop_button}>Start Selling Now</div>

              </a>
            </div>
          </div>
          <div className={style.logo_outer}>
            <div className={style.logo_img}>
              <a target="_blank" rel="noopener noreferrer" href="/">
                <img src="/Assets/blue_logo.png" alt="LoveLocal" />
              </a>
            </div>
            <div className={style.app_link}>
              <a href="https://play.google.com/store/apps/details?id=mpaani.com.android.yodaapp" target="_blank" rel="noopener noreferrer">
                <img src="/Assets/google-play.svg" alt="Download LoveLocal" />
              </a>
            </div>

          </div>
        </div>

        <div className={`${style.footer_section} ${style.footer_link_section} `}>
          <div className={style.link_header}>Lovelocal Dukaan ke Features</div>
          {dukaan.map((item) => (
            <div className={style.link} key={item.id}><a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a></div>
          ))}
        </div>

        <div className={`${style.footer_section} ${style.footer_link_section} `}>
          <div className={style.link_header}>Business Tips</div>
          {businessTips.map((item) => (
            <div className={style.link} key={item.id}><a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a></div>
          ))}
        </div>

        <div className={`${style.footer_section} ${style.footer_link_section} `}>
          <div className={style.link_header}>Suniye Local Retailers Ki Zubaani</div>
          {zubani.map((item) => (
            <div className={style.link} key={item.id}><a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a></div>
          ))}
        </div>

        {/* Footer bottom */}
        <div className={style.footer_bottom}>
          <div className={style.footerOuter}>
            <div className={style.footer_link}>
              <a target="_blank" href="/about" rel="noopener noreferrer" title="About lovelocal">About Us</a>
            </div>

            <div className={style.footer_link}>
              <a target="_blank" href="mailto:cs@lovelocal.in" rel="noopener noreferrer" title="Contact lovelocal">Contact Us</a>
            </div>

            <div className={style.footer_link}>
              <a target="_blank" href="https://retailerblog.lovelocal.in" rel="noopener noreferrer" title="lovelocal blog">Blog</a>
            </div>

            <div className={style.footer_link}>
              <a target="_blank" href="/privacy-policy/" rel="noopener noreferrer" title="lovelocal policy">Privacy Policy</a>
            </div>

            <div className={style.footer_link}>
              <a target="_blank" href="/member/terms/" rel="noopener noreferrer" title="lovelocal terms">Terms &amp; Condition</a>
            </div>

            <div className={style.footer_link}>
              <a target="_blank" href="mailto:dream@lovelocal.in/" rel="noopener noreferrer" title="lovelocal terms">Careers: dream@lovelocal.in</a>
            </div>
          </div>

          <div className={style.footer_social}>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/lovelocalindia/" className="text-white">
              <Svg
                svg="facebook" // @ts-ignore
                fill={Constants.Color.white}
                width="14px"
              />
            </a>

            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/m-paani/" className="text-white">
              <Svg
                svg="linkedIn" // @ts-ignore
                fill={Constants.Color.white}
                width="14px"
              />
            </a>

            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/lovelocalindia/" className="text-white">
              <Svg
                svg="twitter" // @ts-ignore
                fill={Constants.Color.white}
                width="14px"
              />
            </a>

            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCqs6-xP0gZZZBtaED7DssZg/" className="text-white">
              <Svg
                svg="youtube" // @ts-ignore
                fill={Constants.Color.white}
                width="14px"
              />
            </a>

            <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/lovelocalindia/" className="text-white">
              <Svg
                svg="instagram" // @ts-ignore
                fill={Constants.Color.white}
                width="14px"
              />
            </a>
          </div>

        </div>
      </div>
    </div>
  </footer>
);

export default PartnerFooter;
