import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  setGuestCustomCartItems,
} from 'app/store/cart/actions';
import CustomImage from '../../components/common/CustomImage';
import { getCDNImageUrl } from '../../utilities/Utils';

function GuestUserCustomCartProduct(props) {
  const {
    product, productId, guestCustomCartItems,
    setUpdatedCustomCartItems,
  } = props;

  const { quantity } = guestCustomCartItems[productId];

  const Counter = () => (
    <Row
      className="order border-radius-8 border border-primary mx-0 fs-6 cart-counter-min"
    >
      <Col
        xs="auto"
        className="px-0"
      >
        <Button
          variant="link"
          className="px-0 py-0 h-100 fs-6 text-primary text-decoration-none w-25p"
          onClick={() => {
            const p = guestCustomCartItems[productId];
            if (quantity === 1) {
              const updatedCustomCartItems = Object.keys(guestCustomCartItems).reduce(
                (acc, id) => {
                  if (id === productId) { return acc; }
                  return { ...acc, [id]: guestCustomCartItems[id] };
                }, {},
              );
              setUpdatedCustomCartItems(updatedCustomCartItems);
            } else {
              setUpdatedCustomCartItems({
                ...guestCustomCartItems,
                [productId]: {
                  ...p,
                  quantity: quantity - 1,
                },
              });
            }
          }}
        >
          -
        </Button>
      </Col>
      <Col
        className="px-0 text-center text-primary font-weight-black py-1"
      >
        {quantity}
      </Col>
      <Col
        xs="auto"
        className="px-0"
      >
        <Button
          variant="link"
          className="px-0 py-0 h-100 fs-4 text-primary text-decoration-none w-25p"
          onClick={() => {
            const p = guestCustomCartItems[productId];
            setUpdatedCustomCartItems({
              ...guestCustomCartItems,
              [productId]: {
                ...p,
                quantity: quantity + 1,
              },
            });
          }}
        >
          +
        </Button>
      </Col>
    </Row>
  );

  return (
    <Col
      id="cart-product"
      xs={24}
      lg={24}
      className="px-0 py-4"
    >
      <Row
        className="mx-0 flex-nowrap"
      >
        <Col
          xs={4}
          className="px-0"
        >
          <div
            className="cart-product-image"
          >
            <CustomImage
              imageUrl={getCDNImageUrl(product.imageUrl)}
              imageType="PRODUCT"
              imageStyle="mh-100 mw-100"
              imageAlt={product.productName}
              imageTitle={product.productName}
            />
          </div>
        </Col>
        <Col
          xs="auto"
          className="flex-grow-1 flex-shrink-1 fs-5 px-2"
        >
          <Row
            className="mx-0 pb-1 fs-5 text-truncate-1 word-break"
            title={product.productName}
          >
            <b>{product.productName}</b>
          </Row>
          <Row
            className="mx-0 mr-2 fs-5 text-truncate-1 text-medium word-break"
          >
            {product.unitString}
          </Row>
          <Row className="mx-0 fs-6 text-secondary">
            This product is subject to availability
          </Row>
        </Col>
        <Col
          xs={5}
          className="px-0 d-flex flex-column align-items-center"
        >
          <Row
            xs="auto"
            className="mx-0 px-0"
          >
            <Counter />
          </Row>
          <Row
            className="mx-0 mt-2 px-0 text-center"
          >
            <span
              className="bg-yellow fs-8 px-2 py-1 border-radius-16 font-weight-black"
            >
              {'Custom Item'.toUpperCase()}
            </span>
          </Row>
        </Col>
        <Col xs={5} className="px-0" style={{ }} />
      </Row>
    </Col>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUpdatedCustomCartItems: (customCartItems) => {
    dispatch(setGuestCustomCartItems(customCartItems));
  },
});

GuestUserCustomCartProduct.propTypes = {
  product: PropTypes.shape({
    productName: PropTypes.string,
    id: PropTypes.number,
    imageUrl: PropTypes.string,
    quantity: PropTypes.number,
    unitString: PropTypes.string,
  }).isRequired,
  productId: PropTypes.number.isRequired,
  guestCustomCartItems: PropTypes.shape({}).isRequired,
  setUpdatedCustomCartItems: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(GuestUserCustomCartProduct);
