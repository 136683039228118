import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../utilities';
import * as ctEvents from '../../events/Events';

const ScratchCards = ({
  item, language, history, page,
}) => (
  (item.scratchCardCount > 0)
  && (
    <button
      type="button"
      className="r__scratch_card"
      onClick={() => {
        history.push({ pathname: '/offers' });
        ctEvents.logBannerClicked(
          {
            PAGE: page,
            BANNER_NAME: 'Scratch Card',
            SECTION: 'Scratch Card',
          },
        );
      }}
    >
      <div className="r__scratch_card-title">
        {item.scratchCardCount}
        {' '}
        scratch cards available
      </div>
      <div className="r__scratch_card-btn">
        {Constants.String.UNLOCK_DISCOUNT[language].toUpperCase()}
      </div>
    </button>
  )
);

ScratchCards.propTypes = {
  item: PropTypes.shape({
    scratchCardCount: PropTypes.number,
  }).isRequired,
  language: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ScratchCards;
