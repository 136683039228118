import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth="1px"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 19.2l-2.781 1.36-1.45-2.736-3.05-.534.434-3.065L3 12l2.153-2.225L4.72 6.71l3.05-.534 1.45-2.736L12 4.8l2.781-1.36 1.45 2.736 3.05.534-.434 3.065L21 12l-2.153 2.225.434 3.065-3.05.534-1.45 2.736L12 19.2zm-1.125-7.95c.621 0 1.125-.504 1.125-1.125S11.496 9 10.875 9s-1.125.504-1.125 1.125.504 1.125 1.125 1.125zm3 3.75c.621 0 1.125-.504 1.125-1.125s-.504-1.125-1.125-1.125-1.125.504-1.125 1.125S13.254 15 13.875 15zm.91-5.873l-4.82 5.746"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default Svg;
