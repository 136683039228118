import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 11.957c0-.835-.63-1.498-1.425-1.498-.52 0-.959.288-1.205.691v-.028c0-.836-.63-1.498-1.425-1.498-.493 0-.959.288-1.205.691v-.029c0-.835-.63-1.497-1.425-1.497-.493 0-.959.288-1.205.691V4.498c0-.404-.137-.778-.411-1.066-.274-.288-.63-.432-1.014-.432-.795 0-1.425.662-1.425 1.498v9.244c0 .087-.055.144-.11.202-.082.029-.164.029-.218-.058L6.979 12.39c-.493-.519-1.26-.576-1.835-.173-.329.23-.576.605-.63 1.037-.055.432.054.864.328 1.18l3.617 4.522C9.473 20.251 10.979 21 12.569 21h2.246c1.26 0 2.438-.518 3.315-1.44.877-.922 1.37-2.16 1.37-3.485v-4.118z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default Svg;
