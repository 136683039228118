import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  fill, height, width,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.435 4.434a.8.8 0 0 0 0 1.13L6.87 9l-3.434 3.434a.8.8 0 0 0 1.131 1.132l4-4a.8.8 0 0 0 0-1.131l-4-4a.8.8 0 0 0-1.13 0z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
