import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Form,
} from 'react-bootstrap';
import Axios from 'axios';
import QueryString from 'query-string';
import { connect } from 'react-redux';
import RecentSearchSuggestion from 'app/layout/global_search/RecentSearchSuggestion';
import { isAuthenticated } from 'app/utilities/Utils';
import * as Actions from '../../store/Actions';
import Svg from './Svg';
import { Constants } from '../../utilities';
import ShopByCategory from '../../layout/category-landing/ShopByCategory';
import { createSearchHistory, newGlobalSearch, recentSearch } from '../../../api/api';
import SearchTextSuggestions from '../../layout/global_search/SearchTextSuggestions';

const { CancelToken } = Axios;

class TopNavSearchBar extends React.Component {
  constructor() {
    super();
    this.state = {
      searchText: '',
      activeTimeOut: -1,
      suggestions: [],
      recentSearchSuggestions: null,
    };
    this.source = CancelToken.source();
    this.ref = React.createRef();
  }

  componentDidMount = () => {
    const { categoryRequest, isMobile } = this.props;
    document.addEventListener('click', this.handleRecentSearchModal, true);
    if (!isMobile) {
      categoryRequest(null, null);
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const { history, isGlobalSearch } = props;
    const queryParam = QueryString.parse(history.location.search);
    const { q = '' } = queryParam;
    if (state.searchText !== q && !isGlobalSearch) {
      return ({ searchText: q });
    }
    return null;
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleRecentSearchModal, true);
  }

  getRecentSearch = () => {
    const { selectedAddress } = this.props;
    recentSearch(
      'GET',
      {
        latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
        longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
      },
    ).then((res) => {
      this.setState({
        recentSearchSuggestions: res.data.data,
      });
    }).catch(() => {});
  }

  getSuggestions = () => {
    const { searchText } = this.state;
    const { selectedAddress } = this.props;
    newGlobalSearch(
      'GET',
      this.source.token,
      'all', // path
      {
        latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
        longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        searchText,
      },
    ).then((res) => {
      this.setState({
        suggestions: res.data.data,
      });
    }).catch(() => {});
  }

  handleSearch = (event) => {
    const { value } = event.target;
    const { activeTimeOut } = this.state;
    if (activeTimeOut !== -1) {
      clearTimeout(activeTimeOut);
    }
    if (this.source) {
      this.source.cancel();
    }
    if (value) {
      this.source = CancelToken.source();
      this.setState({
        searchText: value,
        suggestions: [],
        recentSearchSuggestions: null,
      }, () => {
        const timeOutId = setTimeout(() => {
          this.getSuggestions();
          this.setState({ activeTimeOut: -1 });
        }, 200);
        this.setState({ activeTimeOut: timeOutId });
      });
      return;
    }
    this.setState({
      searchText: value,
      suggestions: [],
      activeTimeOut: -1,
      recentSearchSuggestions: null,
    });
  }

  addSearchHistory = (searchText, searchType = 'PRODUCT', isComplete = false, storeId = '') => {
    const { selectedAddress } = this.props;
    let body = {
      searchTerm: searchText,
      searchType,
      isComplete,
      latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
      longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
    };
    if (searchType === 'STORE') {
      body = {
        ...body,
        storeId,
      };
    }
    createSearchHistory(
      'POST',
      body,
    ).then(() => {}).catch(() => {});
  }

  onKeyPress = (event) => {
    const { history } = this.props;
    const { searchText } = this.state;
    if (event.key === 'Enter' && event.which === 13) {
      if (
        searchText
        && (searchText).trim().length
      ) {
        if (isAuthenticated()) {
          this.addSearchHistory(searchText, 'PRODUCT', false);
        }
        if (this.source) {
          this.source.cancel();
        }
        history.push(`/search?q=${searchText}`);
        setTimeout(() => {
          this.setState({
            searchText,
            suggestions: [],
            recentSearchSuggestions: null,
          });
        }, 200);
      }
    }
  }

  onSelectSuggestion = (suggestedText) => {
    const { history } = this.props;
    if (
      suggestedText
      && (suggestedText).trim().length
    ) {
      if (isAuthenticated()) {
        this.addSearchHistory(suggestedText, 'PRODUCT', true);
      }
      history.push(`/search?q=${suggestedText}`);
      setTimeout(() => {
        this.setState({
          searchText: suggestedText,
          suggestions: [],
          recentSearchSuggestions: null,
        });
      }, 200);
    }
  }

  onStoreSelectionSuggestion = (storeId, storeName) => {
    const { history } = this.props;
    if (storeId) {
      if (isAuthenticated()) {
        this.addSearchHistory(storeName, 'STORE', true, storeId.toString());
      }
      history.push(`/shops/${storeId}`);
      setTimeout(() => {
        this.setState({
          searchText: '',
          suggestions: [],
          recentSearchSuggestions: null,
        });
      }, 200);
    }
  }

  onSelectBrand = (brandId, brandName) => {
    const { history } = this.props;
    if (brandId) {
      history.push(`/brands/${brandName.toLowerCase()}-${brandId}`);
      setTimeout(() => {
        this.setState({
          searchText: '',
          suggestions: [],
          recentSearchSuggestions: null,
        });
      }, 200);
    }
  }

  handleRecentSearchModal = (event) => {
    if (
      this.ref.current
      && (!this.ref.current.contains(event.target) || (event.target.className === 'mw-100 mh-100 mostSearchProduct'))
    ) {
      this.setState({
        recentSearchSuggestions: null,
      });
    }
  }

  render() {
    const {
      isMobile, language, showCategories,
    } = this.props;
    const {
      searchText, suggestions, recentSearchSuggestions,
    } = this.state;
    return (
      <Container
        className="border-radius-16 h-38p"
      >
        <Row
          className="h-100 d-flex align-items-center"
        >
          <Col
            xs="auto"
            className={`p-0 nav-categories ${showCategories ? '' : 'd-none'}`}
          >
            <div
              className="border font-weight-bold fs-6 bg-light top-nav-category-left"
            >
              ALL
            </div>
            <div
              className={`nav-categories-primary text-black px-n4 shadow ${
                suggestions.length ? 'd-none' : ''
              }`}
            >
              <ShopByCategory
                {...this.props}
              />
            </div>
          </Col>
          <Col
            className={`px-0 h-100 border d-flex align-items-center bg-white py-0 px-3 ${
              isMobile ? 'border-radius-8' : 'global-search-input'
            }`}
          >
            <div
              className="d-block d-md-none"
            >
              <Svg
                svg="search"
                fill={Constants.Color.secondary}
                width="16px"
              />
            </div>
            <Form.Control
              value={searchText}
              type="search"
              spellCheck={false}
              maxLength={25}
              onChange={this.handleSearch}
              onKeyPress={this.onKeyPress}
              className="h-100 py-0 shadow-none border-0 text-black bg-white fs-5"
              placeholder={Constants.String.SEARCH_PRODUCTS_AND_BRANDS_ACROSS_SHOPS[language]}
              autoComplete="off"
              autoCorrect="off"
              onBlur={() => {
                setTimeout(() => {
                  this.setState({
                    suggestions: [],
                  });
                }, 200);
              }}
              onFocus={() => {
                if (!searchText) {
                  this.getRecentSearch();
                }
              }}
            />
            <div
              ref={this.ref}
              className={`recent-search-suggestion border-radius-16 border overflow-hidden ${
                (!searchText && recentSearchSuggestions) ? '' : 'd-none'
              }`}
            >
              <RecentSearchSuggestion
                {...this.props}
                recentSearchSuggestions={recentSearchSuggestions}
                onSelectSuggestion={this.onSelectSuggestion}
                onStoreSelectionSuggestion={this.onStoreSelectionSuggestion}
                onSelectBrand={this.onSelectBrand}
              />
            </div>
            <div
              className={`search-suggestions border-radius-16 border overflow-hidden ${
                suggestions.length ? '' : 'd-none'
              }`}
            >
              <SearchTextSuggestions
                {...this.props}
                language={language}
                searchText={searchText}
                suggestions={suggestions}
                onSelectSuggestion={this.onSelectSuggestion}
                onStoreSelectionSuggestion={this.onStoreSelectionSuggestion}
              />
            </div>
          </Col>
          <Col
            xs="auto"
            className={`p-0 cursor-pointer ${showCategories ? '' : 'd-none'}`}
            onClick={() => {
              if (searchText) {
                this.onSelectSuggestion(searchText);
              }
            }}
          >
            <div
              className="bg-primary top-nav-category-right"
            >
              <Svg
                svg="search"
                fill={Constants.Color.white}
                width="20px"
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  // categories: state.main.categories,
  cartId: state.main.cartId,
  selectedAddress: state.main.selectedAddress,
  profile: state.main.customer,
});

const mapDispatchToProps = (dispatch) => ({
  toggleGlobalSearch: () => {
    dispatch(Actions.toggleGlobalSearch());
  },
  categoryRequest: (storeId, cancelToken) => {
    dispatch(Actions.productCategoryRequest(cancelToken, storeId));
  },
});

TopNavSearchBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  cartId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  language: PropTypes.string.isRequired,
  selectedAddress: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
  profile: PropTypes.shape({
    uid: PropTypes.string,
  }),
  categoryRequest: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  showCategories: PropTypes.bool,
  toggleSideOverlay: PropTypes.func.isRequired,
};

TopNavSearchBar.defaultProps = {
  selectedAddress: null,
  profile: null,
  showCategories: false,
  cartId: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavSearchBar);
