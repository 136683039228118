import React from 'react';
import PropTypes from 'prop-types';
import Nav from './Nav';

function Navbar(props) {
  const {
    navElement, navConfig, classNames,
    navState, toggleLogin, history, toggleGlobalSearch,
  } = props;
  return (
    <nav className={classNames.navbar}>
      {
        navConfig.map((item) => (
          <Nav
            key={item.id}
            childNode={navElement(item)}
            navBarDisplayText={item.displayText}
            link={item.link}
            isHrefApplicable={item.isHrefApplicable}
            toggleGlobalSearch={toggleGlobalSearch}
            className={classNames.navLink}
            state={navState(item.link)}
            toggleLogin={toggleLogin}
            history={history}
            buttonAction={item.buttonAction}
          />
        ))
      }
    </nav>
  );
}

Navbar.propTypes = {
  navConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  classNames: PropTypes.shape({
    navbar: PropTypes.string.isRequired,
    navLink: PropTypes.string.isRequired,
  }).isRequired,
  navElement: PropTypes.func.isRequired,
  navState: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  toggleGlobalSearch: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Navbar;
