import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';

type sizeType = 'sm' | 'md' | 'lg';

interface modalInterface {
  show: boolean;
  onHide: any;
  children: any;
  size?: sizeType;
  backdropStatic?: boolean;
}

const Modal = ({
  show, onHide, size = 'md', children, backdropStatic = false,
}:modalInterface) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      if (modalRef.current !== null) {
        modalRef.current.classList.add(style.visible);
      }
    } else if (modalRef.current !== null) {
      modalRef.current.classList.remove(style.visible);
    }
  }, [show]);

  const handleBackdrop = () => {
    if (!backdropStatic) {
      onHide();
    }
  };

  return (
    <>
      <div className={` ${style.modalMain} `} ref={modalRef}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className={style.backdrop}
          onClick={handleBackdrop}
          onKeyDown={handleBackdrop}
          role="button"
          tabIndex={0}
        />
        <div className={` ${style.modalBody} ${style[size]}`}>
          {children}
        </div>

      </div>
    </>
  );
};

export default Modal;
