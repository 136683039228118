import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 14.286 15.06"
  >
    <g id="Address" transform="translate(-50.518 -1153.002)">
      <g id="Group_3611" data-name="Group 3611" transform="translate(53.895 1153.002)">
        <path
          id="Path_2125"
          data-name="Path 2125"
          d="M58.768,1165.805a.751.751,0,0,1-.686-.441c-.514-1.13-3.08-6.857-3.08-8.6a3.766,3.766,0,0,1,7.531,0c0,1.74-2.566,7.467-3.08,8.6A.752.752,0,0,1,58.768,1165.805Zm0-11.3a2.262,2.262,0,0,0-2.259,2.259c0,.891,1.2,3.962,2.259,6.423,1.06-2.46,2.259-5.532,2.259-6.423A2.262,2.262,0,0,0,58.768,1154.508Z"
          transform="translate(-55.002 -1153.002)"
          fill={fill}
        />
      </g>
      <g
        id="Group_3612"
        data-name="Group 3612"
        transform="translate(56.531 1155.638)"
      >
        <circle id="Ellipse_70" data-name="Ellipse 70" cx="1.13" cy="1.13" r="1.13" fill={fill} />
      </g>
      <g
        id="Group_3613"
        data-name="Group 3613"
        transform="translate(50.518 1161.285)"
      >
        <path
          id="Path_2126"
          data-name="Path 2126"
          d="M63.3,1170.778H52.024a1.506,1.506,0,0,1-1.384-2.1l1.613-3.764a1.5,1.5,0,0,1,1.384-.913h.621v1.506h-.621l-1.614,3.766H63.3l-1.614-3.766h-.645V1164h.645a1.5,1.5,0,0,1,1.385.914l1.613,3.765a1.506,1.506,0,0,1-1.384,2.1Z"
          transform="translate(-50.518 -1164)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
