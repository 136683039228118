import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  width, height,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8"
      cy="8"
      r="7.5"
      fill="#fff"
    />
    <circle
      cx="8"
      cy="8"
      r="7.5"
      stroke="#fff"
    />
    <circle
      cx="8"
      cy="8"
      r="7.5"
      stroke="#000"
      strokeOpacity=".64"
    />
    <path
      d="M8 3v4.667L11 10"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3v4.667L11 10"
      stroke="#000"
      strokeOpacity=".64"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  // stroke1: PropTypes.string.isRequired,
  // stroke2: PropTypes.string.isRequired,
  // fill1: PropTypes.string.isRequired,
  // fill2: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
