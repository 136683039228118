import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Button, Col, Container, FormControl, Row, Spinner,
} from 'react-bootstrap';
import { Constants, Storage } from '../../utilities';
import { Svg } from '../../components/common';
import '../../styles/orders/submit_feedback.scss';
import { customerStoreReview } from '../../../api/api';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';

function getReviewConfig(rating, isShopPickup) {
  const positiveStickers = [
    {
      key: 'positive_great_service_of_shop',
      label: 'GREAT_SERVICE_OF_SHOP',
      image: 'Service_1.svg',
      highlightedImage: 'Service_2.svg',
    },
    {
      key: 'positive_great_quality_of_products',
      label: 'GREAT_QUALITY_OF_PRODUCTS',
      image: 'Quality_1.svg',
      highlightedImage: 'Quality_2.svg',
    },
    isShopPickup
      ? {
        key: 'positive_super_fast_order_processing',
        label: 'SUPERFAST_ORDER_PROCESSING',
        image: 'Processing_1.svg',
        highlightedImage: 'Processing_2.svg',
      }
      : {
        key: 'positive_super_fast_delivery',
        label: 'SUPERFAST_DELIVERY',
        image: 'Delivery_1.svg',
        highlightedImage: 'Delivery_2.svg',
      },
    {
      key: 'positive_exciting_offers',
      label: 'EXCITING_OFFERS',
      image: 'Offers_1.svg',
      highlightedImage: 'Offers_2.svg',
    },
    {
      key: 'positive_wide_variety_of_products',
      label: 'WIDE_VARIETY_OF_PRODUCT',
      image: 'Variety_1.svg',
      highlightedImage: 'Variety_2.svg',
    },
    {
      key: 'positive_great_prices_of_products',
      label: 'GREAT_PRICES_OF_PRODUCTS',
      image: 'Pricing_1.svg',
      highlightedImage: 'Pricing_2.svg',
    },
  ];
  const negativeStickers = [
    {
      key: 'negative_bad_service_of_shop',
      label: 'BAD_SERVICE_OF_SHOP',
      image: 'Service_1.svg',
      highlightedImage: 'Service_3.svg',
    },
    {
      key: 'negative_poor_quality_of_products',
      label: 'POOR_QUALITY_OF_PRODUCTS',
      image: 'Quality_1.svg',
      highlightedImage: 'Quality_3.svg',
    },
    isShopPickup
      ? {
        key: 'negative_slow_order_processing_speed',
        label: 'SLOW_ORDER_PROCESSING_SPEED',
        image: 'Processing_1.svg',
        highlightedImage: 'Processing_3.svg',
      }
      : {
        key: 'negative_slow_order_delivery_speed',
        label: 'SLOW_ORDER_DELIVERY',
        image: 'Delivery_1.svg',
        highlightedImage: 'Delivery_3.svg',
      },
    {
      key: 'negative_no_availability_of_offers',
      label: 'NO_OFFERS_AVAILABLE',
      image: 'Offers_1.svg',
      highlightedImage: 'Offers_3.svg',
    },
    {
      key: 'negative_low_variety_of_products',
      label: 'LOW_VARIETY_OF_PRODUCTS',
      image: 'Variety_1.svg',
      highlightedImage: 'Variety_3.svg',
    },
    {
      key: 'negative_high_prices_of_products',
      label: 'HIGH_PRICES_OF_PRODUCTS',
      image: 'Pricing_1.svg',
      highlightedImage: 'Pricing_3.svg',
    },
  ];
  switch (rating) {
    case 1:
    case 2:
      return {
        stickerData: negativeStickers,
        stickersHeader: 'WHAT_WENT_WRONG',
        reviewHeader: 'PLEASE_TELL_US_MORE',
      };
    case 3:
      return {
        stickerData: negativeStickers,
        stickersHeader: 'WHAT_WERE_YOU_UNHAPPY_WITH',
        reviewHeader: 'PLEASE_TELL_US_MORE',
      };
    case 4:
    case 5:
      return {
        stickerData: positiveStickers,
        stickersHeader: 'WHAT_DID_THE_SHOP_IMPRESS_YOU_WITH',
        reviewHeader: 'TELL_US_WHAT_YOU_LIKED_MOST',
      };
    default:
      break;
  }
  return null;
}

class SubmitFeedback extends React.Component {
  constructor(props) {
    super();
    const { location, isMobile } = props;
    const { fromOrderDetails = false } = location.state || {};
    const query = QueryString.parse(location.search);
    const {
      shopId,
      orderId,
      rating,
      isShopPickup,
    } = query;
    this.state = {
      rateOrder: isMobile && fromOrderDetails
        ? {
          shopId,
          orderId,
          rating: Number(rating),
          isShopPickup,
        }
        : props.rateOrder,
      sticker: [],
      content: '',
      loading: false,
      error: null,
    };
  }

  componentDidMount = () => {
    const { isMobile, history, location } = this.props;
    if (isMobile) {
      const {
        fromRateOrder = false,
        fromOrderDetails = false,
      } = location.state || {};
      if (fromOrderDetails) {
        return;
      }
      if (fromRateOrder) {
        history.replace({});
      } else {
        history.push('/');
      }
    }
  }

  handleRatingStar = (rating) => {
    const { rateOrder } = this.state;
    this.setState({
      rateOrder: {
        ...rateOrder, rating,
      },
      sticker: [],
    });
  }

  handleChange = (e) => {
    this.setState({
      content: e.target.value,
    });
  }

  onSubmit = () => {
    const {
      language, onOrderFeedbackSuccess, isMobile, history,
      location,
    } = this.props;
    const { rateOrder, content, sticker } = this.state;
    const {
      fromRateOrder = false,
      fromOrderDetails = false,
    } = location.state || {};
    customerStoreReview(
      'post',
      null,
      rateOrder.orderId,
      {
        // retailer_uid: rateOrder.shopId,
        // order_id: rateOrder.orderId,
        rating: rateOrder.rating,
        comment: content,
        stickers: sticker,
      },
    )
      .then(() => {
        onOrderFeedbackSuccess();
        if (isMobile) {
          if (fromRateOrder) {
            history.push('/');
          } else if (fromOrderDetails) {
            history.push(`/orders/${rateOrder.orderId}`);
          }
        }
        LogClevertapEvent.ratingSubmitted(
          rateOrder.rating,
          sticker,
        );
      })
      .catch((err) => {
        let error = Constants.String.OOPS[language];
        if (err.response
          && err.response.status === 400
          && err.response.data[0]) {
          error = error.response.data[0].client_message;
        }
        this.setState({
          loading: false,
          error,
        });
      });
  }

  render() {
    const { isMobile, language, onClose } = this.props;
    const {
      rateOrder, sticker, content, loading, error,
    } = this.state;
    const ratingStar = [];
    const ratingLabels = [
      'UNSATISFIED',
      'SATISFIED',
      'GOOD',
      'EXCELLENT',
      'DELIGHTED',
    ];
    if (!!rateOrder && rateOrder.rating) {
      const reviewConfig = getReviewConfig(
        rateOrder.rating,
        rateOrder.isShopPickup,
      );
      for (let i = 1; i <= 5; i += 1) {
        ratingStar.push(
          <Button
            key={i}
            variant="link"
            onClick={() => {
              if (rateOrder.rating !== i) {
                this.handleRatingStar(i);
              }
            }}
            className="p-0 mx-1"
          >
            <Svg
              svg="star2"
              fill={i > rateOrder.rating
                ? Constants.Color.white
                : Constants.Color.pink}
              stroke={Constants.Color.pink}
              width="2.5rem"
            />
          </Button>,
        );
      }
      return (
        <Container
          id="submit-order-feedback"
          className="bg-white px-0"
        >
          <Row
            className="mx-0 bg-grey flex-column h-100"
          >
            {!isMobile && (
              <Col
                className="flex-grow-0 py-3 border-bottom fs-3 bg-white"
              >
                <Row
                  className="mx-0 align-items-center"
                >
                  <Col
                    className="pl-0 pr-2"
                  >
                    <b>
                      {Constants.String.SUBMIT_FEEDBACK[language]}
                    </b>
                  </Col>
                  {!!onClose && (
                  <Col
                    xs="auto"
                    className="pl-2 pr-0"
                  >
                    <Button
                      variant="link"
                      onClick={onClose}
                      className="d-flex p-0"
                    >
                      <Svg
                        svg="circleClose"
                        pathFill={Constants.Color.black}
                        circleFill={Constants.Color.secondary}
                        width="1.2rem"
                      />
                    </Button>
                  </Col>
                  )}
                </Row>
              </Col>
            )}
            <Col
              className="flex-grow-1 overflow-y-scroll"
            >
              <Row>
                <Col
                  xs={24}
                  className="py-3 mb-2 bg-white"
                >
                  <Row
                    className="mx-0 align-items-center justify-content-between"
                  >
                    <Col
                      xs="auto"
                      className="p-0 pr-4"
                    >
                      {ratingStar}
                    </Col>
                    {!!rateOrder.rating && (
                    <Col
                      xs="auto"
                      className="p-0 text-primary"
                    >
                      <b>
                        {`${Constants.String[
                          ratingLabels[rateOrder.rating - 1]][language].toUpperCase()}`}
                      </b>
                    </Col>
                    )}
                  </Row>
                </Col>
                <Col
                  xs={24}
                  className="py-3 mb-2 bg-white"
                >
                  <div
                    className="mb-2 text-lg-center"
                  >
                    <b>
                      {Constants.String[reviewConfig.stickersHeader][language]}
                    </b>
                  </div>
                  <Row
                    className="mx-auto align-items-start justify-content-between sticker-section"
                  >
                    {reviewConfig.stickerData.map((item) => {
                      const isSelected = sticker.includes(item.key);
                      return (
                        <Col
                          key={item.key}
                          xs={8}
                          className="p-2 cursor-pointer d-flex flex-column
                              align-items-center justify-content-center"
                          onClick={() => {
                            this.setState({
                              sticker: isSelected
                                ? sticker.filter((item1) => item.key !== item1)
                                : [...sticker, item.key],
                            });
                          }}
                        >
                          <div
                            className="mb-2"
                          >
                            <img
                              src={`/images/web_stickers/${
                                isSelected ? item.highlightedImage : item.image}`}
                              alt=""
                              className="mw-100"
                            />
                          </div>
                          <div
                            className={`text-center fs-6 ${isSelected
                              ? 'font-weight-bold' : ''}`}
                          >
                            {Constants.String[item.label][language]}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col
                  xs={24}
                  className="py-3 mb-2 bg-white"
                >
                  <div
                    className="mb-3 text-lg-center"
                  >
                    <b>
                      {Constants.String[reviewConfig.reviewHeader][language]}
                    </b>
                  </div>
                  <div>
                    <FormControl
                      rows={4}
                      as="textarea"
                      value={content}
                      placeholder={Constants.String.YOUR_TEXT_GOES_HERE[language]}
                      onChange={this.handleChange}
                      autoFocus={rateOrder.rating <= 3}
                      autoComplete="off"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              className="flex-grow-0 py-3 bg-white"
            >
              {
                rateOrder.rating < 4 && !sticker.length && (
                <div
                  className="fs-6 text-danger text-center mb-2"
                >
                  {Constants.String.SELECT_ONE_REASON_DESC[language]}
                </div>
                )
            }
              <Button
                disabled={loading || (rateOrder.rating < 4 && !sticker.length)}
                className="w-100"
                onClick={() => {
                  this.setState({
                    loading: true,
                    error: null,
                  }, this.onSubmit);
                }}
              >
                {
                loading && (
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                )
              }
              &nbsp;
              &nbsp;
                {Constants.String.SUBMIT_FEEDBACK[language].toUpperCase()}
              </Button>
              {!!error && (
              <div
                className="text-danger fs-5"
              >
                {error}
              </div>
              )}
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  }
}

SubmitFeedback.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  rateOrder: PropTypes.shape({}),
  onOrderFeedbackSuccess: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      fromRateOrder: PropTypes.bool,
    }),
  }).isRequired,
  onClose: PropTypes.func,
};

SubmitFeedback.defaultProps = {
  rateOrder: null,
  onOrderFeedbackSuccess: () => {},
  onClose: null,
};

export default SubmitFeedback;
