import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl } = apiUrls();
const superSavingsApis = {
  superSavings: (cancelToken, params = {}) => {
    const home = `${newOOBaseUrl}customer/super-savings`;
    return Axios({
      url: home,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },
};
export default superSavingsApis;
