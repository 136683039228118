import React from 'react';
import PropTypes from 'prop-types';

const OrderWaiting = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <circle cx={12} cy={12} r={12} fill="#005317" />
    <path
      fill="#fff"
      d="M11.867 14.286c.911 0 1.784.36 2.428 1.004a3.426 3.426 0 0 1 1.006 2.424.571.571 0 0 1-.572.572H9.006a.573.573 0 0 1-.572-.572c0-.91.361-1.781 1.005-2.424a3.437 3.437 0 0 1 2.428-1.004Zm-.527-4.572h1.055a1.763 1.763 0 0 0 1.761-1.759.526.526 0 0 0-.527-.527h-3.523a.528.528 0 0 0-.528.527 1.758 1.758 0 0 0 1.762 1.76Zm7.395 9.715a.57.57 0 0 1-.573.571H5.572a.572.572 0 1 1 0-1.143h1.145v-.914c0-2.425 1.13-4.406 2.952-5.169a.836.836 0 0 0 0-1.548c-1.821-.763-2.952-2.744-2.952-5.17v-.913H5.572a.572.572 0 1 1 0-1.143h12.59a.573.573 0 1 1 0 1.143h-1.144v.914c0 2.425-1.131 4.406-2.953 5.169a.836.836 0 0 0 0 1.548c1.822.763 2.953 2.744 2.953 5.17v.913h1.144a.573.573 0 0 1 .573.572Zm-2.862-.572v-.914c0-1.957-.862-3.533-2.25-4.116a1.98 1.98 0 0 1-1.074-2.585 1.98 1.98 0 0 1 1.074-1.07c1.388-.582 2.25-2.159 2.25-4.115v-.914H7.861v.914c0 1.956.863 3.533 2.25 4.115a1.979 1.979 0 0 1 1.074 2.585c-.201.485-.587.87-1.073 1.07-1.388.582-2.25 2.16-2.25 4.116v.914h8.011Z"
    />
  </svg>
);

OrderWaiting.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default OrderWaiting;
