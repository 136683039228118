import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_68_6074)">
      <path
        d="M24.5501 4.89809C25.9923 3.78076 28.0077 3.78076 29.4499 4.89809L32.0934 6.94626C32.828 7.5154 33.7378 7.81102 34.6666 7.78236L38.0092 7.67919C39.8327 7.62292 41.4632 8.80756 41.9731 10.5592L42.9079 13.77C43.1677 14.6622 43.73 15.4362 44.4983 15.9589L47.2631 17.8402C48.7714 18.8665 49.3942 20.7833 48.7772 22.5001L47.6462 25.6472C47.3319 26.5217 47.3319 27.4783 47.6462 28.3528L48.7772 31.4999C49.3942 33.2167 48.7714 35.1335 47.2631 36.1598L44.4983 38.0411C43.73 38.5638 43.1677 39.3378 42.9079 40.23L41.9731 43.4408C41.4632 45.1924 39.8327 46.3771 38.0092 46.3208L34.6666 46.2176C33.7378 46.189 32.828 46.4846 32.0934 47.0537L29.4499 49.1019C28.0077 50.2192 25.9923 50.2192 24.5501 49.1019L21.9066 47.0537C21.172 46.4846 20.2622 46.189 19.3334 46.2176L15.9908 46.3208C14.1673 46.3771 12.5368 45.1924 12.0269 43.4408L11.0921 40.23C10.8323 39.3378 10.27 38.5638 9.50171 38.0411L6.73688 36.1598C5.22859 35.1335 4.60579 33.2167 5.22279 31.4999L6.35381 28.3528C6.6681 27.4783 6.6681 26.5217 6.35381 25.6472L5.22279 22.5001C4.60579 20.7833 5.22859 18.8665 6.73688 17.8402L9.50171 15.9589C10.27 15.4362 10.8323 14.6622 11.0921 13.77L12.0269 10.5592C12.5368 8.80756 14.1673 7.62292 15.9908 7.67919L19.3334 7.78236C20.2622 7.81102 21.172 7.5154 21.9066 6.94626L24.5501 4.89809Z"
        fill="#005317"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_68_6074"
        x={0.986694}
        y={0.0600891}
        width={52.0266}
        height={53.8798}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_68_6074"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_68_6074"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
