import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

function List({
  Component, idField, list, referCard, coachmark, screen, isOnSeoPage, ...props
}) {
  return (
    <Row
      className={`mx-n4 ${isOnSeoPage ? 'r__localstore_cardlist' : ''}`}
    >
      {referCard}
      {coachmark}
      {
        list.map((item) => (
          <Component {...props} key={item[idField]} item={item} screen isOnSeoPage />
        ))
      }
    </Row>
  );
}

List.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  idField: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  referCard: PropTypes.element,
  coachmark: PropTypes.element,
  screen: PropTypes.string,
  isOnSeoPage: PropTypes.bool,
};

List.defaultProps = {
  referCard: null,
  coachmark: null,
  screen: '',
  isOnSeoPage: false,
};

export default List;
