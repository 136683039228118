import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  width, stroke, strokeWidth,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth || '1px'}
      d="M3 15l4.125-9 4.125 9m1.875-3c1.243 0 2.25-1.007 2.25-2.25s-1.007-2.25-2.25-2.25-2.25 1.007-2.25 2.25M11.25 15c.322.85 1.288 1.5 2.25 1.5 1.243 0 2.25-1.007 2.25-2.25S14.743 12 13.5 12m-8.977.36h5.25M16.5 8.625H21M14.25 12h4.5m0-3v9"
      transform="translate(-16 -347) translate(16 347)"
    />
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  strokeWidth: PropTypes.string.isRequired,
};

export default Svg;
