// Order Tracking Load Actions
export const ACTIVE_ORDER_LOAD_REQUEST = 'ACTIVE_ORDER_LOAD_REQUEST';
export const ACTIVE_ORDER_LOAD_SUCCESS = 'ACTIVE_ORDER_LOAD_SUCCESS';
export const ACTIVE_ORDER_LOAD_FAILURE = 'ACTIVE_ORDER_LOAD_FAILURE';

export const activeOrderActions = {

  loadRequest: (
    onSuccess, onFailure,
  ) => ({
    type: ACTIVE_ORDER_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
    },
  }),

  loadSuccess: (data) => ({
    type: ACTIVE_ORDER_LOAD_SUCCESS,
    payload: {
      activeOrders: data.res,
    },
  }),

  loadFailure: () => ({
    type: ACTIVE_ORDER_LOAD_FAILURE,
  }),

};
