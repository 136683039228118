import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 14 14"
  >
    <path
      id="Add_Location"
      data-name="Add Location"
      d="M6,14V8H0V6H6V0H8V6h6V8H8v6Z"
      transform="translate(0 0)"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
