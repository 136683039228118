import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from '.';

const CustomRoundBadge = (props) => {
  const {
    badgeText, badgeType, isSmall,
  } = props;
  let badgeSvgJSX = null;
  if (badgeType === 'ROUND_OFFER') {
    badgeSvgJSX = (
      <Svg
        svg="roundOfferBadge"
        width={isSmall ? '32' : '48'}
        height={isSmall ? '32' : '48'}
      />
    );
  }
  return (
    <div
      className="r__badge_wrapper"
    >
      <div className="r__badge_svg">
        {badgeSvgJSX}
      </div>
      <div
        className={`past-product-badge-text font-weight-bold text-white ${isSmall ? 'small fs-8' : 'fs-6'}`}
      >
        <b>{badgeText}</b>
      </div>
    </div>
  );
};

CustomRoundBadge.propTypes = {
  badgeText: PropTypes.string.isRequired,
  badgeType: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
};

CustomRoundBadge.defaultProps = {
  isSmall: false,
};

export default CustomRoundBadge;
