import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
  >
    <g id="Frames-24px">
      <rect fill="none" style={{ width }} />
    </g>
    <g id="Outline">
      <g>
        <g>
          <path
            fill={fill}
            d="M12,15c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5h-2c0-1.654-1.346-3-3-3s-3,1.346-3,3s1.346,3,3,3
               V15z"
          />
        </g>
        <g>
          <path fill={fill} d="M16,15h-3v-4h4v1.829c1.164-0.414,2-1.525,2-2.829V8h2v2C21,12.757,18.757,15,16,15z" />
        </g>
        <g>
          <path fill={fill} d="M20,24H4v-2c0-3.533,3.29-6,8-6s8,2.467,8,6V24z M6,22h12c0-2.355-2.467-4-6-4S6,19.645,6,22z" />
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
