import React from 'react';
import PropTypes from 'prop-types';

const OrderPickUp = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <circle cx={12} cy={12} r={12} fill="#005317" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.5 4.667h12.833v9.863c.42-.153.894-.092 1.265.171l.496.352a1.26 1.26 0 0 1-.18 2.162l-5.025 2.439c-.187.09-.392.138-.6.138H9.767c-.376 0-.735-.154-.995-.426L6.69 17.183a.458.458 0 0 0-.331-.141H3.292c-.76 0-1.375-.616-1.375-1.375v-2.75c0-.76.615-1.375 1.375-1.375H6.5V4.667Zm.325 7.772a.459.459 0 0 0 .49-.15l1.653-.578a.458.458 0 0 1 .483.116l1.947 2.039c.259.272.618.426.994.426h1.84c.126 0 .246.05.332.142l.35.367a.458.458 0 0 1 .127.316v1.008h-2.649a.458.458 0 0 1-.332-.142l-1.479-1.55a.458.458 0 1 0-.663.633l1.48 1.55c.259.272.618.426.994.426h3.757c.223 0 .441-.054.638-.157l2.803-1.468a.458.458 0 0 1 .478.032l.495.352a.344.344 0 0 1-.048.59l-5.026 2.438a.46.46 0 0 1-.2.046H9.767a.458.458 0 0 1-.331-.142l-2.084-2.182a1.375 1.375 0 0 0-.994-.426H3.292a.458.458 0 0 1-.459-.458v-2.75c0-.253.205-.459.458-.459h3.303c.078 0 .155-.006.231-.02Z"
      clipRule="evenodd"
    />
  </svg>
);

OrderPickUp.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default OrderPickUp;
