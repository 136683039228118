/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { Component } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import FooterDeskTop from '../../layout/partner/FooterDeskTop';
import FooterMobile from '../../layout/partner/FooterMobile';

class BuildherMission extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <Container
        fluid
        className="h-100"
      >
        <Helmet>
          <link
            rel="icon"
            href="https://love-local.s3.ap-south-1.amazonaws.com/images/LoveLocal_B2C_Icon.png"
          />
          <link
            rel="apple-touch-icon"
            href="https://love-local.s3.ap-south-1.amazonaws.com/images/LoveLocal_B2C_Icon.png"
          />
          <title>
            LoveLocal launches BuildHER, the first ever Women Engineers Tech Internship Program in the Indian startup ecosystem
          </title>
        </Helmet>
        <Row
          className="justify-content-center bg-white"
        >
          <Container>
            <Row>
              <Col
                xs={24}
                className="p-0"
              >
                <img
                  alt="Build-mission-her-top-banner"
                  src="/Assets/buildher-mission/LL_BiuldHER_Press Release_PR_Top_Banner.jpg"
                  width="100%"
                />
              </Col>
            </Row>
          </Container>
        </Row>

        <Row
          className="h-100 py-9 bg-white"
        >
          <Container>
            <Row>
              <Col
                xs={24}
                className=""
              >
                <p
                  className="m-0 fs-4"
                >
                  LoveLocal announces the launch of BuildHER with a mission to create a diverse tech future by investing in and training the next generation of women engineers in India. LoveLocal BuildHER offers young female developers an exposure to building real-time tech infrastructure with industry experts and mentorship by global tech leaders like Nami Zarringhalam from Truecaller & Anna Lambert from Shopify.
                </p>

                <p
                  className="m-0 fs-4 pt-4"
                >
                  <i>
                    <b>Akanksha Hazari, Founder & CEO of LoveLocal, says:</b>
                    “Change requires action. LoveLocal is committed to investing in growing female engineering talent in the country, so we may build a diverse tech future. The lack of diversity in tech is a global problem. With BuildHER, we want to lead the way in changing the face of the tech ecosystem in India and, by doing so, also make India a leader in changing the face of tech globally.”
                  </i>
                </p>

                <p
                  className="m-0 fs-4 pt-4"
                >
                  LoveLocal has consistently been a leader in driving positive change in India’s startup ecosystem. Founded by Akanksha Hazari, LoveLocal’s Pre-Series B funding last year marked the largest round ever raised by a solo woman tech founder in India. This was a huge step forward in the rapidly growing Indian startup ecosystem, especially at a time when less than 1% of Indian VC deals are invested in women-only led companies.
                </p>
              </Col>
            </Row>
          </Container>
        </Row>

        <Row
          className="justify-content-center bg-white"
        >
          <Container>
            <Row>
              <Col
                xs={24}
                className="p-0"
              >
                <img
                  alt="Buildher-mentors"
                  src="/Assets/buildher-mission/LL_BiuldHER_Press Release_PR_Program_Mentors.jpg"
                  width="100%"
                />
              </Col>
            </Row>
          </Container>
        </Row>

        <Row
          className="h-100 py-9 bg-white"
        >
          <Container>
            <Row>
              <Col
                xs={24}
                className=""
              >
                <p
                  className="m-0 fs-4 pt-4"
                >
                  <i>
                    <b>Anna Lambert, Director of Corporate Development at Shopify & Founding Partner Backbone Angels says, </b>
                    “LoveLocal is always finding ways to add value for their customers, their team, and the broader tech ecosystem. BuildHer is just another example of the company building for the long term. I look forward to supporting the BuildHer participants with career development, team building, navigating the engineering job market, and growing as engineering experts and leaders.”
                  </i>
                </p>

                <p
                  className="m-0 fs-4 pt-4"
                >
                  <i>
                    <b>Nami Zarringhalam, Co-Founder & CSO at Truecaller says, </b>
                    “I'm excited to support BuildHER so that we can together empower female developers and create a brighter, more inclusive future for all. The change it aims to drive is critical for the tech world, and close to my heart. I am looking forward to mentoring the participants to help develop them into future tech leaders.”
                  </i>
                </p>

                <p
                  className="m-0 fs-4 pt-4"
                >
                  LoveLocal BuildHER is an opportunity to board a soonicorn rocketship and participate in LoveLocal’s mission to revolutionize how India shops locally by building India’s largest hyperlocal e-commerce marketplace and empowering millions of local retailers. The program is designed to enable young women developers to gain real-world experience solving complex product and tech problems early in their careers.
                </p>

                <p
                  className="m-0 fs-4 pt-4"
                >
                  <i>
                    <b>Rohit Kondapalli, Head of Technology & Data Sciences at LoveLocal says, </b>
                    “With BuildHer, we are looking forward to offering a program where young, capable women get exposure to the challenging real-world engineering problems. They will get to work on a multi-stack backend service, modern front-end frameworks like NextJS, Android & IOS development, Test Engineering, Infrastructure setup & monitoring, etc. Being part of a fast-growing startup, this is a unique opportunity to learn how to build for both speed and scale.”
                  </i>
                </p>

                <p
                  className="m-0 fs-4 pt-4"
                >
                  BuildHER interns will be considered for full-time placement opportunities at LoveLocal and help them grow into future leaders and role models in the industry. BuildHER is a 6-months intensive internship program for students in their 3rd and 4th year of engineering. Female students with programming experience in Java, JavaScript and/or Python can apply for this program by sending an email with their CV to
                  <a href="mailto:dream@lovelocal.in?subject=Application for BuildHER - <Your Name>" target="_blank" rel="noopener noreferrer" className="text-primary">
                    &nbsp;dream@lovelocal.in&nbsp;
                  </a>
                  with the subject line
                  &nbsp;
                  <b>‘Application for BuildHER - &lt;Your Name&gt;’</b>
                  .
                </p>

                <p
                  className="m-0 fs-4 pt-4"
                >
                  Launched in 2020, LoveLocal is India's fastest-growing hyperlocal e-commerce marketplace, powered by local retailers. It delivers an integrated O2O shopping experience in the daily essentials categories, by connecting local retailers to consumers. LoveLocal’s mission is to digitize and empower local retailers and power the neighborhood shopping experience of the future. The company is backed by some of the best investors and founders globally - Vulcan Capital, a16z, Blume Ventures, and more.
                </p>
              </Col>
            </Row>
          </Container>
        </Row>

        <Row
          className="justify-content-center bg-white"
        >
          <Container>
            <Row>
              <Col
                xs={24}
                className="p-0"
              >
                <img
                  alt="Digitising-lovelocal"
                  src="/Assets/buildher-mission/digitising-lovelocal.jpg"
                  width="100%"
                />
              </Col>
            </Row>
          </Container>
        </Row>

        <Row
          className="h-100 py-6 bg-white"
        >
          <Container>
            <Row>
              <Col
                xs={24}
                className=""
              >
                <p
                  className="m-0 fs-4 pt-4"
                >
                  <div
                    className=""
                  >
                    <b>
                      About Akanksha Hazari, CEO & Founder of LoveLocal
                    </b>
                  </div>

                  Akanksha is a Princeton University and Cambridge University alumnus. A successful second-time entrepreneur, Akanksha has 10+ years of business experience across India, MENA and the US. She has won multiple awards including, the Hult Prize 2011 where she was awarded $1 million by former US President Bill Clinton and the Vital Voices Global Economic Empowerment Award 2016 from Secretaries of State Hillary Rodham Clinton and
                  Madeleine Albright, and is named / recognised in US Senate Resolution 418. Akanksha is the only Indian tech entrepreneur to be featured in the book - "100 Women Using their Power to Empower''. The book celebrates 100 global women leaders who are redefining power, in which Akanksha is featured alongside Melinda Gates, Serena Williams, Greta Thunberg, and many other inspiring women. Akanksha speaks six languages and is a former competitive squash player ranked among the top 20 junior players internationally, including being a two-time India national juniors champion.
                  <div>
                    For more information, please visit:
                    <a href="https://www.linkedin.com/in/akanksha-hazari-7835963/" target="_blank" rel="noopener noreferrer" className="text-black">
                      https://www.linkedin.com/in/akanksha-hazari-7835963/
                    </a>
                  </div>
                </p>
              </Col>
            </Row>
          </Container>
        </Row>

        <Row
          className="h-100 pb-9 bg-white"
        >
          <Container>
            <Row>
              <Col
                className="fs-4 text-center"
              >
                <b className="text-primary">Website: </b>
                <a href="https://www.lovelocal.in/" target="_blank" rel="noopener noreferrer" className="text-black">
                  www.lovelocal.in
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;

                <b className="text-primary">Twitter: </b>
                <a href="https://twitter.com/lovelocalindia/" target="_blank" rel="noopener noreferrer" className="text-black">
                  @LoveLocalindia
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;

                <b className="text-primary">Instagram: </b>
                <a href="https://www.instagram.com/lovelocalindia/" target="_blank" rel="noopener noreferrer" className="text-black">
                  @lovelocalindia
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;

                <b className="text-primary">LinkedIn: </b>
                <a href="https://in.linkedin.com/company/lovelocalindia" target="_blank" rel="noopener noreferrer" className="text-black">
                  @lovelocalindia
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;

                <div>
                  <b className="text-primary">Press: </b>
                  <a href="mailto:media@lovelocal.in" target="_blank" rel="noopener noreferrer" className="text-black">
                    media@lovelocal.in
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;

                  <b className="text-primary">Partnership: </b>
                  <a href="mailto:partner@lovelocal.in" target="_blank" rel="noopener noreferrer" className="text-black">
                    partner@lovelocal.in
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;

                  <b className="text-primary">Hiring: </b>
                  <a href="mailto:dream@lovelocal.in" target="_blank" rel="noopener noreferrer" className="text-black">
                    dream@lovelocal.in
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>

        <Row
          className="bg-white"
        >
          <Col
            xs={24}
            className="p-0"
          >
            <img
              alt="Buildher-footer"
              src="/Assets/buildher-mission/LL_BiuldHER_Press Release_PR_Footer.jpg"
              width="100%"
            />
          </Col>
        </Row>

        {/* Footer */}
        <Row
          className="h-100 py-9 bg-black d-none d-md-block"
        >
          <FooterDeskTop
            {...this.props}
            language="en"
            pinkLogo
          />
        </Row>
        <Row
          className="h-100 py-9 bg-black d-block d-md-none"
        >
          <FooterMobile
            {...this.props}
            language="en"
            pinkLogo
          />
        </Row>
      </Container>
    );
  }
}

BuildherMission.propTypes = {
  language: PropTypes.string.isRequired,
};

export default BuildherMission;
