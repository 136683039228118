import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Form, InputGroup, Spinner, Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import QueryString from 'query-string';
import Axios from 'axios';
import * as Actions from '../../store/Actions';
import { CustomModal, Svg, Placeholder } from '../../components/common';
import DefaultGlobalSearchResult from '../../components/derived/globalSearch/DefaultGlobalSearchResult';
import { Constants } from '../../utilities';
import Category from '../../layout/global_search/Category';
import OnlineProduct from '../../components/derived/onlineProduct/OnlineProduct';
import Store from '../../layout/global_search/Store';
import { globalSearch } from '../../../api/api';
import * as LogCleverTap from '../../../clevertap/LogEvent';
import * as screens from '../../events/screens';
import { logPageLoad } from '../../events/Events';
import { getCodeFromURL, getSEOFriendlyProductName } from '../../utilities/Utils';
import '../../styles/global_search/global_search.scss';

const { CancelToken } = Axios;

class GlobalSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      searchText: '',
      loader: false,
      searchData: null,
      limit: 10,
      offset: 0,
      storeId: null,
    };
    this.activeTimeout = null;
    this.source = CancelToken.source();
  }

  static getDerivedStateFromProps = (props, state) => {
    const { history, isMobile } = props;
    const queryParams = QueryString.parse(history.location.search);
    const { q = '', sid = null } = queryParams;
    if (isMobile
      && (state.searchText !== q || state.storeId !== sid)
    ) {
      return {
        searchText: q,
        loader: !!q,
        offset: 0,
        searchData: null,
        storeId: sid,
      };
    }
    return null;
  }

  onEnter = () => {
    const { storeId } = this.state;
    const { match } = this.props;
    let newStoreId = match.params.storeId;
    if (!newStoreId) {
      newStoreId = getCodeFromURL(match.params.shopURL);
    }
    if (newStoreId && (newStoreId !== storeId)) {
      this.setState({ storeId: newStoreId });
    }
  }

  componentWillUnmount = () => {
    this.reset();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { searchText } = this.state;
    if (prevState.searchText !== searchText) {
      this.handleSearchFilter();
    }
  }

  handleSearchFilter = () => {
    const { loader, searchText } = this.state;
    if (this.activeTimeout) {
      clearTimeout(this.activeTimeout);
      this.activeTimeout = null;
    }
    if (loader) {
      this.source.cancel();
      this.source = CancelToken.source();
    }
    if (searchText) {
      this.activeTimeout = setTimeout(() => {
        this.loadSearchData();
      }, 300);
    }
  }

  handleChange = (value) => {
    this.setState({
      searchText: value,
      loader: !!value,
      offset: 0,
      searchData: null,
    });
  }

  loadSearchData = (reason = 'reset') => {
    const { cartId, selectedAddress } = this.props;
    const {
      searchText, searchData, offset, limit, storeId,
    } = this.state;
    const latlng = selectedAddress ? `${selectedAddress.location.lat},${selectedAddress.location.lng}` : '19.1207983,72.8782323';
    globalSearch(
      this.source.token,
      latlng,
      cartId,
      limit,
      offset,
      searchText,
      storeId,
    ).then((res) => {
      let newSearchData = res.data;
      if (reason === 'lazyLoad') {
        newSearchData = { ...res.data, results: [...searchData.results, ...res.data.results] };
      } else {
        const count = newSearchData.results.length;
        LogCleverTap.searchedGlobal(
          count ? searchText : null,
          !count ? searchText : null,
        );
        logPageLoad(
          {
            SCREEN: screens.GLOBAL_SEARCH,
            SEARCH_TEXT: searchText,
            PRODUCT_COUNT: newSearchData.count,
          },
        );
      }
      this.setState({
        loader: false,
        searchData: newSearchData,
      });
    }).catch((error) => {
      if (Axios.isCancel(error)) {
        return;
      }
      this.setState({
        loader: false,
      });
    });
  }

  handleLazyLoad = (e) => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    const {
      loader, searchData, offset, limit,
    } = this.state;
    if (!loader
      && searchData
      && (searchData.count > offset + searchData.results.length)
      && (scrollHeight - scrollTop === offsetHeight)) {
      this.setState({
        offset: offset + limit,
        loader: true,
      }, () => {
        this.loadSearchData('lazyLoad');
      });
    }
  }

  handleProductScreen = (productId, productName) => {
    const {
      isMobile, location, history,
    } = this.props;
    const { pathname, search } = location;
    const queryParam = QueryString.parse(search);
    if (isMobile) {
      history.push(`/products/${getSEOFriendlyProductName(productName)}-${
        productId}?${QueryString.stringify(queryParam)}`);
      return;
    }
    history.push(`${pathname}?${QueryString.stringify(queryParam)}`);
  }

  reset = () => {
    this.source.cancel();
    this.source = CancelToken.source();
    if (this.activeTimeout) {
      clearTimeout(this.activeTimeout);
      this.activeTimeout = null;
    }
    this.setState({
      searchText: '',
      loader: false,
      searchData: null,
      offset: 0,
      limit: 10,
      storeId: null,
    });
  }

  render() {
    const {
      cartItems,
      isMobile,
      language,
      onGlobalSearch,
      toggleGlobalSearch,
    } = this.props;
    const {
      searchData,
      loader,
      searchText,
      storeId,
    } = this.state;

    const showcase = (
      <Container
        className={`bg-white rounded  ${
          isMobile ? 'overflow h-100' : ''}`}
        id={isMobile ? 'global-search' : 'global-search-modal'}
        onScroll={this.handleLazyLoad}
      >
        <Row
          className="align-items-center justify-content-center rounded"
        >
          <Col
            xs={24}
            className={isMobile ? 'd-none' : 'shadow sticky-top z-1 bg-white'}
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="bg-white border-0"
                >
                  <Svg
                    svg="search"
                    fill={Constants.Color.dark}
                    width="1rem"
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                value={searchText}
                size="lg"
                spellCheck={false}
                autoComplete="off"
                autoCorrect="off"
                placeholder={Constants.String.SEARCH[language]}
                className="border-0 rounded-0 shadow-none"
                onChange={(e) => this.handleChange(e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  variant="link"
                  className="p-0"
                  onClick={searchText
                    ? () => this.handleChange('') : toggleGlobalSearch}
                >
                  <Svg
                    svg="circleClose"
                    circleFill={Constants.Color.dark}
                    pathFill={Constants.Color.white}
                    width="1.1rem"
                  />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={24} className={`px-0 ${isMobile ? '' : 'search-results-scroll'}`}>
            {
              !loader
              && searchData
              && searchData.results.length === 0
                ? (
                  <Placeholder
                    language={language}
                    imageSrc="/images/no-results.png"
                    heading={Constants.String.NO_RESULTS_FOUND[language]}
                  />
                ) : ''
            }
            {
              searchData
              && searchData.results.length > 0
                ? (
                  <div
                    id="search-results"
                  >
                    {
                      searchData.results.map((item) => (
                        <div
                          key={
                            (item.type === 'store' && item.details.storeCode)
                              || (item.type === 'category' && item.details.categoryCode)
                              || (item.details.id)
                          }
                        >
                          {
                            item.type === 'store'
                              && (
                                <Store
                                  {...this.props}
                                  item={item.details}
                                />
                              )
                          }
                          {
                            item.type === 'category_new'
                              && (
                                <Category
                                  {...this.props}
                                  item={item.details}
                                  storeId={storeId}
                                />
                              )
                          }
                          {
                            item.type === 'category'
                              && (
                                <Category
                                  {...this.props}
                                  item={item.details}
                                  storeId={storeId}
                                />
                              )
                          }
                          {
                            item.type === 'product'
                              && (
                                <OnlineProduct
                                  {...this.props}
                                  cartItems={cartItems}
                                  item={item.details}
                                  handleProductScreen={storeId
                                    ? this.handleProductScreen
                                    : null}
                                />
                              )
                          }
                        </div>
                      ))
                    }
                  </div>
                ) : ''
            }
            {
              searchText === ''
              && isMobile
              && (
                <DefaultGlobalSearchResult
                  {...this.props}
                />
              )
            }
            {
              loader
                ? (
                  <div className="py-4 text-center">
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                ) : ''
            }
          </Col>
        </Row>
      </Container>
    );

    if (isMobile) {
      return showcase;
    }
    return (
      <CustomModal
        show={onGlobalSearch}
        body={showcase}
        size=""
        onEnter={this.onEnter}
        onHide={toggleGlobalSearch}
        reset={this.reset}
        backdrop
      />
    );
  }
}

const mapStateToProps = (state) => ({
  onGlobalSearch: state.main.onGlobalSearch,
  selectedAddress: state.main.selectedAddress,
  cartId: state.main.cartId,
  cartItems: state.main.cartItems,
});

const mapDispatchToProps = (dispatch) => ({
  toggleGlobalSearch:
  () => dispatch(Actions.toggleGlobalSearch()),
});

GlobalSearch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      storeId: PropTypes.string,
      shopURL: PropTypes.string,
    }),
  }).isRequired,
  cartId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  cartItems: PropTypes.shape({}),
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onGlobalSearch: PropTypes.bool.isRequired,
  toggleGlobalSearch: PropTypes.func.isRequired,
  selectedAddress: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  page: PropTypes.string.isRequired,
};

GlobalSearch.defaultProps = {
  selectedAddress: null,
  cartId: null,
  cartItems: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalSearch);
