import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import StoreCard from '../individual-layout/StoreCard';

const StoreCardsLayout = () => (
  <Container>
    <Row>
      <Col
        xs={24}
        className="mt-4"
      >
        <StoreCard />
        <StoreCard />
      </Col>
      <Col
        xs={24}
        className="mt-4"
      >
        <StoreCard />
        <StoreCard />
      </Col>
      <Col
        xs={24}
        className="mt-4"
      >
        <StoreCard />
        <StoreCard />
      </Col>
    </Row>
  </Container>
);

export default StoreCardsLayout;
