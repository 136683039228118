const {
  dataLayer = [],
} = window;

export function uaLogPageView() {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({ event: 'pageview' });
}

export function uaLogProductImpression(items = []) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'productImpression',
    ecommerce: {
      currencyCode: 'INR',
      impressions: items,
    },
  });
}

export function uaLogProductClick(product = {}) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: 'Store Product' },
        products: [product],
      },
    },
  });
}

export function uaLogProductAddOrRemoveToCart(product = {}, isAdding) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: isAdding ? 'addToCart' : 'removeFromCart',
    ecommerce: isAdding ? {
      currencyCode: 'INR',
      add: {
        products: [product],
      },
    } : {
      remove: {
        products: [product],
      },
    },
  });
}

export function uaLogPurchaseItems(cartDetails = {}) {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      purchase: cartDetails,
    },
  });
}

export function uaLogBegunCheckout() {
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'beginCheckout',
    ecommerce: {
      checkout: {},
    },
  });
}

export function uaLogSignUp(userId = '') {
  dataLayer.push({
    event: 'registration',
    authenticationMethod: 'mobile',
    userId,
  });
}

export function uaLogSignUpClicked() {
  dataLayer.push({
    event: 'signUpClicked',
  });
}

export function uaLogCustomEvents(eventName = '', data = {}) {
  dataLayer.push({
    event: eventName,
    ecommerce: {
      data,
    },
  });
}
