import React from 'react';
import { Svg } from 'app/components/common';
import Constants from 'app/utilities/Constants';
import { languageConstant } from '../../utils/homeConstants';
import style from './index.module.scss';

const Language = (props:any) => {
  const { language, languageSelected, closeModal } = props;

  return (
    <div className={style.languageMain}>
      <div className={style.languageHead}>
        <div className={style.languageHeadText}>Select Language</div>
        <div
          className={style.languageHeadClose}
          role="button"
          onClick={closeModal}
          onKeyDown={closeModal}
          tabIndex={0}
        >
          <Svg
            svg="close"
                // @ts-ignore
            fill={Constants.Color.white}
            stroke={Constants.Color.white}
            width="24px"
          />
        </div>
      </div>

      <div className={style.languageContainer}>

        {languageConstant.map((item:any) => (
          <div
            className={` ${style.language} ${language === item.code ? style.active : ''}`}
            role="button"
            onClick={() => languageSelected(item.code)}
            onKeyDown={() => languageSelected(item.code)}
            tabIndex={0}
            key={item.id}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Language;
