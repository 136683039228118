import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={stroke}
      fillRule="evenodd"
      d="M10.875 3.8C6.968 3.8 3.8 6.968 3.8 10.875c0 3.908 3.168 7.075 7.075 7.075s7.075-3.167 7.075-7.075c0-3.907-3.168-7.075-7.075-7.075zM2.2 10.875c0-4.79 3.884-8.675 8.675-8.675s8.675 3.884 8.675 8.675c0 2.108-.752 4.04-2.002 5.544l.04.038 3.978 3.977c.312.313.312.82 0 1.132-.313.312-.82.312-1.132 0l-3.977-3.978-.038-.04c-1.503 1.25-3.436 2.002-5.544 2.002-4.791 0-8.675-3.884-8.675-8.675z"
      clipRule="evenodd"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
