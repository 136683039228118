import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Svg } from 'app/components/common';
import { Constants, Utils } from '../../utilities';
import * as buttonActions from '../../events/buttonActions';
import { errorImageFallback, getCDNImageUrl } from '../../utilities/Utils';
import { logButtonClick } from '../../events/Events';

const CartSpendOffer = (props) => {
  const {
    item,
    language, handleSpendOffer,
  } = props;

  const style = {
    left: {
      display: 'flex',
      alignItems: 'center',
      height: '126px',
      width: '94px',
      padding: '12px 8px',
      borderRadius: '16px 0 0 16px',
      border: '1px solid #005317',
      borderRight: 'none',
    },
    image_wrapper: {
      width: '77px',
      height: '77px',
      padding: '10px',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: '16px',
    },
    image: {
      width: '100%',
    },
  };

  return (
    <Col
      id="store-spend-offer"
      xs={24}
      className="mr-6 p-0 text-wrap"
    >
      <Row
        className="mx-0 py-2 pr-2 px-0  border border-radius-16 align-items-center"
      >
        <Col
          className="px-0"
          xs={24}
        >
          <Row
            className="mx-0 align-items-center flex-nowrap"
          >
            <Col
              xs="auto"
              className="px-0 spend-offer-image bg-white overflow-hidden"
            >
              {
                (item.offerType === 'SPEND_OFFER')
                  && (
                  <div className="mr-2">
                    <img
                      style={style.image}
                      src={getCDNImageUrl(item.image) || Utils.LOVELOCAL.productPlaceholder}
                      alt={item.image ? item.name : ''}
                      title={item.image ? item.name : ''}
                      onError={(event) => {
                        errorImageFallback(event, Utils.LOVELOCAL.productPlaceholder);
                      }}
                    />
                    <div className="r__offer_card-label">
                      Free!
                    </div>
                  </div>
                  )
              }
              {
                (item.offerType === 'VALUE_OFFER')
                  && (
                    <div className={`font-weight-black text-white p-4 value-offer-image ${item.isApplicable ? '' : 'disabled'}`}>
                      &#x20B9;
                      {item.discountValue}
                      {' '}
                      off
                    </div>
                  )
              }
            </Col>
            <Col
              className="pr-2 pl-0 flex-shrink-1"
            >
              <div
                className={`font-weight-black ${item.isApplicable ? 'text-green-dark' : ''}`}
              >
                {item.name}
              </div>
              {
                item.appliedStatus && (
                  <div className="fs-6">
                    Shop Offer Applied
                  </div>
                )
              }
              {
                !item.isApplicable && (
                  <div className="fs-6 text-primary font-weight-bold">
                    {item.errorMessage}
                  </div>
                )
              }
            </Col>
            <Col
              xs="auto"
              className="d-flex px-0 align-items-center justify-content-center cursor-pointer"
              onClick={() => {
                handleSpendOffer(!item.appliedStatus, item);
                logButtonClick(
                  {
                    BUTTON: item.offerType === 'VALUE_OFFER'
                      ? buttonActions[
                        item.appliedStatus
                          ? 'REMOVE_VALUE_OFFER'
                          : 'APPLY_VALUE_OFFER'
                      ]
                      : buttonActions[
                        item.appliedStatus
                          ? 'REMOVE_SPEND_OFFER'
                          : 'APPLY_SPEND_OFFER'
                      ],
                  },
                );
              }}
            >
              {
                item.appliedStatus ? (
                  <div>
                    <Svg
                      svg="circleClose"
                      width="24px"
                      circleFill={Constants.Color.gray}
                      pathFill={Constants.Color.white}
                    />
                  </div>
                ) : (
                  <div
                    className={`fs-6 font-weight-black ${item.isApplicable ? 'text-primary' : 'd-none'}`}
                  >
                    {Constants.String.APPLY[language].toUpperCase()}
                  </div>
                )
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

CartSpendOffer.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    store: PropTypes.shape({
      code: PropTypes.string,
      displayName: PropTypes.string,
      locality: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      storeType: PropTypes.shape({
        displayName: PropTypes.string,
      }),
    }),
    name: PropTypes.string,
    description: PropTypes.string,
    appliedStatus: PropTypes.bool,
    offerType: PropTypes.string,
    discountValue: PropTypes.number,
    errorMessage: PropTypes.string,
    isApplicable: PropTypes.bool,
  }).isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  handleSpendOffer: PropTypes.func,
};

CartSpendOffer.defaultProps = {
  handleSpendOffer: () => {},
};

export default CartSpendOffer;
