import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl } = apiUrls();

const brandSpaceApis = {
  brandSpaces: (cancelToken, params = {}) => {
    const space = `${newOOBaseUrl}customer/brand-space`;
    return Axios({
      url: space,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },
};

export default brandSpaceApis;
