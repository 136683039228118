export const dukaan = [
  {
    id: 1,
    title: 'How to setup your shop',
    link: 'https://youtu.be/NbX3ibh2i0Y',
  },
  {
    id: 2,
    title: 'How to setup your catalogue & price',
    link: 'https://youtu.be/ToNEp3Hfn-g',
  },
  {
    id: 3,
    title: 'How to Promote shop',
    link: 'https://youtu.be/0w8q-rMECj0',
  },
  {
    id: 4,
    title: 'How to create offers',
    link: 'https://youtu.be/iNyjVsB30Ao',
  },
  {
    id: 5,
    title: 'How to opt for Home Delivery',
    link: 'https://youtu.be/cTWuwzdBtsk',
  },
];

export const businessTips = [
  {
    id: 1,
    title: 'How to grow your online store presence',
    link: 'https://youtu.be/TZi04EzaQeg',
  },
  {
    id: 2,
    title: 'Why Customer Experience is important',
    link: 'https://youtu.be/vRxCQj0i6vM',
  },
  {
    id: 3,
    title: 'Advantages of promoting Your Shop',
    link: 'https://youtu.be/CqiOu2-Fcfw',
  },
  {
    id: 4,
    title: 'Advantages of creating offers',
    link: 'https://youtu.be/my5YpzdWaxI',
  },
  {
    id: 5,
    title: 'What is Dark Store and why we say #NoDarkStore',
    link: 'https://youtu.be/7dYzurTlwAY',
  },
];

export const zubani = [
  {
    id: 1,
    title: 'Offers banane kyu hai zaruri',
    link: 'https://youtu.be/gtmr_lmNqQ4',
  },
  {
    id: 2,
    title: 'Promo Cards ke benefit',
    link: 'https://youtube.com/shorts/R2ramvs2PjQ',
  },
  {
    id: 3,
    title: 'Tarakki Ki Kahani',
    link: 'https://youtube.com/shorts/hBizUSf4vG4',
  },
  {
    id: 4,
    title: 'LoveLocal ne kese ki madat',
    link: 'https://youtu.be/-jnprL7CGT0',
  },
];
