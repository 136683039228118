import React, { useState, Suspense } from 'react';
import PartnerFooter from 'app/components/common/partner-footer';
import Modal from '../../../components/common/modal';
import Shimmer from '../../../shimmer/Shimmer';
import Button from '../../../components/common/button';
import { Svg } from '../../../components/common';
import Constants from '../../../utilities/Constants';
import style from './index.module.scss';
import Consultation from '../layout/consultation';
import Language from '../layout/language';

const PartnerWrapper = (props:any) => {
  const {
    headerTheme, match, Component, history,
  } = props;
  const language = match.params.lang;

  const languageExpand = (languageCode:string) => {
    if (languageCode === 'en') {
      return 'English';
    }
    return 'हिन्दी';
  };

  const [showConsultation, setShowConsultation] = useState(false);
  const [showLanguageChange, setShowLanguageChange] = useState(false);

  const languageHandler = (selectedlanguage:string) => {
    localStorage.setItem('languagePartner', selectedlanguage);

    history.push(
      `${
        history.location.pathname.replace(`/${language}`, `/${selectedlanguage}`)
      }${
        history.location.search || ''
      }`,
    );
    setShowLanguageChange(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Suspense
      fallback={(
        <Shimmer
          shimmerType="FALLBACK"
        />
          )}
    >
      <div className={style.headerMain}>
        <div className={`${style.headerLeft}`}>
          <span
            onClick={scrollToTop}
            onKeyDown={scrollToTop}
            role="button"
            tabIndex={0}
          >
            <img
              alt="LoveLocal-Icon"
              src={headerTheme === 'BLUE'
                ? '/Assets/logo.png'
                : '/Assets/logo-pink.png'}
              height="60px"
              className={style.logoImg}
            />
          </span>

        </div>

        <div className={`${style.headerRight}`}>
          {/* Get help button */}
          <div className={style.helpButtonContainer} style={{ marginRight: '5px' }}>
            <Button color="violet" onClick={() => setShowConsultation(true)}>
              <div className={style.buttonHelp}>
                <img
                  alt=""
                  src="/Assets/phone_icon.svg"
                  height="20px"
                  width="20px"
                />
                <span>Get Help</span>
              </div>
            </Button>
          </div>

          {/* Language button */}
          <div className={style.languageButtonContainer}>
            <Button color="violet" onClick={() => setShowLanguageChange(true)}>
              <div className={style.buttonLanguage}>
                <span>{languageExpand(language)}</span>

                <Svg
                  svg="arrowDown"
                // @ts-ignore
                  fill="none"
                  stroke={Constants.Color.violet}
                  width="20px"
                />
              </div>
            </Button>
          </div>

        </div>
      </div>

      <Modal
        show={showConsultation}
        onHide={() => setShowConsultation(false)}
        size="sm"
      >
        <Consultation
          {...props}
          language={language}
        />
      </Modal>

      <Modal
        show={showLanguageChange}
        onHide={() => setShowLanguageChange(false)}
        size="sm"
        backdropStatic
      >
        <Language
          {...props}
          language={language}
          languageSelected={languageHandler}
          closeModal={() => setShowLanguageChange(false)}
        />
      </Modal>

      {/* <PartnerNew /> */}
      <Component
        {...props}
        language={language}
      />

      <div className={`${style.footerContainer} bg-black`}>
        {/* <div
          className="d-md-none bg-black"
        >
          <FooterMobile
            {...props}
            language={language}
          />
          <div className="p-6" />
        </div>
        <div
          className="d-none d-md-block bg-black"
        >
          <Footer
            {...props}
            language={language}
          />
        </div> */}
        <PartnerFooter />
      </div>

    </Suspense>
  );
};

export default PartnerWrapper;
