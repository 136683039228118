import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  width, height,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 73 72"
  >
    <defs>
      <linearGradient id="2e7l9el92c" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#20B038" />
        <stop offset="100%" stopColor="#60D66A" />
      </linearGradient>
      <linearGradient id="n8ijcp33md" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#F9F9F9" />
        <stop offset="100%" stopColor="#FFF" />
      </linearGradient>
      <filter id="e3p2rh89ya" width="105.5%" height="105.6%" x="-2.8%" y="-2.8%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
      </filter>
      <path id="aws4nnvtmb" d="M0 54l3.836-13.86C1.465 36.07.22 31.454.226 26.756.227 11.998 12.36 0 27.264 0c7.173 0 14.051 2.822 19.122 7.844 5.07 5.023 7.918 11.834 7.915 18.935-.006 14.76-12.136 26.758-27.037 26.758h-.01c-4.515 0-8.957-1.12-12.921-3.258L0 54zm14.996-8.564l.822.481c3.462 2.03 7.413 3.1 11.437 3.1h.008c12.386 0 22.466-9.978 22.471-22.24.003-5.9-2.362-11.56-6.575-15.735-4.212-4.174-9.927-6.52-15.887-6.522-12.396 0-22.476 9.976-22.48 22.239-.006 4.187 1.184 8.29 3.434 11.836l.536.841-2.265 8.207 8.499-2.207z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero" transform="translate(-12 -563) translate(0 482) translate(0 57) translate(12 24) translate(9.05 9)">
        <use fill="#000" filter="url(#e3p2rh89ya)" xlinkHref="#aws4nnvtmb" />
        <use fill="#FFF" xlinkHref="#aws4nnvtmb" />
      </g>
      <path fill="url(#2e7l9el92c)" fillRule="nonzero" d="M1.697 52.313l3.597-13C3.07 35.5 1.904 31.175 1.91 26.773c0-13.835 11.375-25.085 25.346-25.085 6.724 0 13.173 2.645 17.926 7.353 4.754 4.708 7.423 11.093 7.421 17.75 0 13.835-11.377 25.087-25.345 25.087h-.012c-4.231 0-8.395-1.05-12.112-3.053L1.697 52.312z" transform="translate(-12 -563) translate(0 482) translate(0 57) translate(12 24) translate(9.05 9)" />
      <path fill="url(#n8ijcp33md)" fillRule="nonzero" d="M0 54l3.836-13.86C1.465 36.07.22 31.454.226 26.756.227 11.998 12.36 0 27.264 0c7.173 0 14.051 2.822 19.122 7.844 5.07 5.023 7.918 11.834 7.915 18.935-.006 14.76-12.136 26.758-27.037 26.758h-.01c-4.515 0-8.957-1.12-12.921-3.258L0 54zm14.996-8.564l.822.481c3.462 2.03 7.413 3.1 11.437 3.1h.008c12.386 0 22.466-9.978 22.471-22.24.003-5.9-2.362-11.56-6.575-15.735-4.212-4.174-9.927-6.52-15.887-6.522-12.396 0-22.476 9.976-22.48 22.239-.006 4.187 1.184 8.29 3.434 11.836l.536.841-2.265 8.207 8.499-2.207z" transform="translate(-12 -563) translate(0 482) translate(0 57) translate(12 24) translate(9.05 9)" />
      <path fill="#FFF" d="M21.314 16.286c-.439-1.044-.898-1.065-1.316-1.083-.337-.017-.73-.015-1.122-.015-.599.017-1.163.3-1.558.784-.536.627-2.046 2.143-2.046 5.227 0 3.083 2.094 6.064 2.387 6.482.293.419 4.044 6.948 9.984 9.46 4.935 2.089 5.945 1.674 7.014 1.564 1.069-.11 3.458-1.516 3.946-2.98s.487-2.717.337-2.98c-.15-.261-.537-.418-1.122-.73-.586-.313-3.458-1.83-3.995-2.039-.536-.208-.925-.314-1.315.314-.39.628-1.51 2.037-1.851 2.456-.342.418-.682.471-1.267.157-.584-.314-2.468-.975-4.7-3.11-1.739-1.662-2.916-3.713-3.253-4.34-.337-.628-.036-.963.257-1.279.262-.281.584-.732.877-1.098.293-.366.39-.627.585-1.045.195-.419.097-.784-.05-1.098-.147-.314-1.273-3.409-1.792-4.647z" transform="translate(-12 -563) translate(0 482) translate(0 57) translate(12 24) translate(9.05 9)" />
    </g>
  </svg>
);

Svg.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Svg;
