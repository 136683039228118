import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Toast = (WrappedComponent) => (
  class extends React.Component {
    constructor() {
      super();
      this.state = {
        show: false,
        text: '',
      };
    }

    showToast = (text, time = 3000) => {
      this.setState({
        show: true,
        text,
      }, () => {
        setTimeout(() => {
          this.setState({
            show: false,
            text: '',
          });
        }, time);
      });
    }

    render() {
      const { show, text } = this.state;
      return (
        <>
          <WrappedComponent
            {...this.props}
            showToast={this.showToast}
          />
          {
            show && (
            <Row
              className="toast-popup justify-content-center mx-0"
            >
              <Col
                xs="auto"
                className="text-center text-white bg-black-72 rounded-pill py-3 px-6"
              >
                <div>
                  {text}
                </div>
              </Col>
            </Row>
            )
          }
        </>
      );
    }
  }
);

export default Toast;
