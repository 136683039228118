import React from 'react';
import PropTypes from 'prop-types';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

function CustomBottomSheet(props) {
  const {
    body, onHide, show,
  } = props;
  return (
    <BottomSheet
      open={show}
      onDismiss={onHide}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, 0.75 * maxHeight]}
    >
      {body}
    </BottomSheet>
  );
}

CustomBottomSheet.propTypes = {
  body: PropTypes.element.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
};

CustomBottomSheet.defaultProps = {
  onHide: () => {},
};

export default CustomBottomSheet;
