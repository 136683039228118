import React from 'react';

const Svg = () => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3635_24515)">
      <circle cx="26" cy="26" r="25" fill="#FAFAFA" stroke="#DCDCDC" strokeWidth="2" />
      <circle cx="26" cy="52" r="19" stroke="#DCDCDC" strokeWidth="2" />
      <circle cx="26" cy="20" r="10" stroke="#DCDCDC" strokeWidth="2" />
      <path d="M30.4577 23.2647C30.0414 24.084 29.4074 24.7728 28.6253 25.2553C27.8432 25.7378 26.9432 25.9955 26.0242 25.9999C25.1052 26.0044 24.2028 25.7555 23.416 25.2805C22.6293 24.8056 21.9886 24.123 21.5645 23.3078" stroke="#DCDCDC" strokeWidth="2" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_3635_24515">
        <rect width="52" height="52" rx="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Svg;
