import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Container, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { cartAddOfferRequest, cartDeleteOfferRequest } from 'app/store/cart/actions';
import SpendOfferCard from 'app/components/derived/offer/SpendOfferCard';
import Cart from 'app/pages/cart/Cart';
import { storeOfferLoadActions } from 'app/store/offer/actions';
import Constants from '../../utilities/Constants';
import { ActionAlert, Svg } from '../../components/common';
import * as screens from '../../events/screens';
import RewardCoupon from 'app/pages/offers/RewardCoupon';

const isMobile = window.screen.width < 992;
// http://localhost:3000/india/mumbai/jogeshwari-west/meat-fish-shops/z-a-qureshi-chicken-824086
// http://localhost:3000/india/pune/pimpri-colony/groceries/mahesh-mart-149777
class ApplicableValueOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertReason: null,
      spendOfferError: null,
      spendOffer: null,
    };
  }

  componentDidMount() {
    this.loadOffers();
  }

  loadOffers = () => {
    const { storeOffersLoadReq, cartDetails } = this.props;
    storeOffersLoadReq(
      cartDetails.store.id,
      null,
      null,
      {
        cartId: cartDetails.cartId,
      },
    );
  }

  handleSpendOfferRequest = (offer, apply) => {
    const {
      language, applyOffer,
    } = this.props;
    const offerType = offer.offerType === 'SPECIAL_SPEND_OFFER' ? 'SPEND_OFFER' : 'VALUE_OFFER';
    const onSuccess = {
      key: 'async',
      callback: () => {
        this.setState({
          alertReason: apply
            ? 'SPEND_OFFER_APPLIED'
            : 'SPEND_OFFER_REMOVED',
        });
      },
    };
    const onFailure = {
      key: 'async',
      callback: (data) => {
        this.setState({
          alertReason: 'SPEND_OFFER_FAILURE',
          spendOfferError: (data && data.error)
          || Constants.String.OOPS[language],
        });
      },
    };
    applyOffer[apply ? 'addRequest' : 'deleteRequest'](
      offer.id,
      offerType,
      onSuccess,
      onFailure,
    );
  }

  render() {
    const {
      cartDetails, language, history,
      storeOffers, toggleSideOverlay, backTo,
    } = this.props;
    const {
      alertReason, spendOfferError,
    } = this.state;
    // if (!location.state) {
    //   return (<Redirect to="/checkout" />);
    // }
    // let spendOffers = applicableOffers;
    // if (location.state && cartDetails) {
    //   spendOffers = location.state.applicableOffers;
    // }

    let title = null;
    let message = '';
    let actionOnConfirm = null;
    let actionOnCancel = null;
    let confirmText = '';
    let cancelText = '';
    if (alertReason) {
      switch (alertReason) {
        case 'REMOVE_SPEND_OFFER':
          title = Constants.String.ARE_YOU_SURE[language];
          message = Constants.String.REMOVE_SPEND_OFFER_ALERT[language];
          cancelText = Constants.String.GO_BACK[language];
          confirmText = Constants.String.YES_REMOVE[language];
          actionOnCancel = () => {
            this.setState({
              alertReason: '',
              spendOffer: null,
            });
          };
          actionOnConfirm = () => {
            const { spendOffer } = this.state;
            this.handleSpendOfferRequest(spendOffer, false);
            this.setState({
              alertReason: '',
              spendOffer: null,
            });
          };
          break;
        case 'REPLACE_SPEND_OFFER':
          title = Constants.String.REPLACE_OFFER[language];
          message = Constants.String.REPLACE_OFFER_DESC[language];
          cancelText = Constants.String.DISMISS[language];
          confirmText = Constants.String.OKAY[language];
          actionOnConfirm = () => {
            const { spendOffer } = this.state;
            this.handleSpendOfferRequest(spendOffer, true);
            this.setState({
              alertReason: '',
              spendOffer: null,
            });
          };
          actionOnCancel = () => {
            this.setState({
              alertReason: '',
              spendOffer: null,
            });
          };
          break;
        case 'SPEND_OFFER_APPLIED':
          title = Constants.String.OFFER_APPLIED[language];
          message = Constants.String.OFFER_APPLIED_DESC[language];
          confirmText = Constants.String.YAY[language];
          actionOnConfirm = () => {
            this.setState({
              alertReason: '',
            });
            // history.push('/checkout', { fromApplicableValueOffers: true });
            if (!isMobile) {
              toggleSideOverlay(Cart);
              return;
            }
            history.push('/cart');
          };
          break;
        case 'SPEND_OFFER_REMOVED':
          title = Constants.String.OFFER_REMOVED[language];
          message = Constants.String.OFFER_REMOVED_DESC[language];
          confirmText = Constants.String.OKAY[language];
          actionOnConfirm = () => {
            this.setState({
              alertReason: '',
            });
          };
          break;
        case 'SPEND_OFFER_FAILURE':
          message = spendOfferError;
          cancelText = Constants.String.OKAY[language];
          actionOnCancel = () => {
            this.setState({
              alertReason: '',
            });
          };
          break;
        default:
          break;
      }
    }

    const goBack = () => {
      if (backTo === 'REWARD_COUPON') {
        toggleSideOverlay(RewardCoupon, false);
      } else {
        toggleSideOverlay(Cart);
      }
    };

    return (
      <Container
        fluid
        className="h-100 d-flex flex-column"
      >
        <ActionAlert
          show={!!alertReason}
          title={title}
          message={message}
          actionOnCancel={actionOnCancel}
          actionOnConfirm={actionOnConfirm}
          cancelText={cancelText}
          confirmText={confirmText}
          isMobile={isMobile}
        />
        <Row className="d-flex py-4">
          {!isMobile && (
            <>
              <Col
                xs="auto"
                className="font-weight-bold py-0 pr-0 align-items-center cursor-pointer"
                onClick={goBack}
              >
                <Svg
                  svg="leftArrow"
                  fill={Constants.Color.black}
                  width="1rem"
                />
              </Col>
              <Col xs="auto" className="flex-grow-1 font-weight-black">
                Shop Offers
              </Col>
              <Col
                xs="auto"
                className="cursor-pointer"
                onClick={() => {
                  toggleSideOverlay(null);
                }}
              >
                <Svg
                  svg="close"
                  width="24px"
                  fill={Constants.Color.mediumLight}
                />
              </Col>
            </>
          )}
        </Row>
        <Row className="pb-4 d-flex justify-content-center overflow-y-scroll">
          <Col xs="auto" className="d-flex flex-column gap-10">
            {
              (storeOffers || []).map((offerItem) => (
                <SpendOfferCard
                  key={offerItem.offerId}
                  item={{
                    ...offerItem,
                    offerType: `SPECIAL_${offerItem.offerType}`,
                    id: offerItem.offerId,
                    isApplicable: offerItem.isValid,
                    image: offerItem.images[0] || '',
                    store: {
                      code: cartDetails.store.id,
                      displayName: cartDetails.store.name,
                      locality: cartDetails.store.locality,
                      city: cartDetails.store.city,
                      country: 'india',
                      storeType: {
                        displayName: cartDetails.store.type,
                      },
                    },
                  }}
                  language={language}
                  history={history}
                  isCheckoutView
                  isFullSpanView
                  toggleSideOverlay={toggleSideOverlay}
                  isMobile={isMobile}
                  screen={screens.APPLICABLE_VALUE_OFFERS}
                  spendOfferInCart={cartDetails.cartOffers ? cartDetails.cartOffers[0] : null}
                />
              ))
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  cartDetails: state.main.cartDetails,
  storeOffers: state.main.storeOffers,
});

const mapDispatchToProps = (dispatch) => ({
  applyOffer: {
    addRequest: (
      offerId,
      offerType,
      onSuccess,
      onFailure,
    ) => {
      dispatch(
        cartAddOfferRequest.addRequest(
          onSuccess,
          onFailure,
          {
            offerId,
            offerType,
          },
          {},
        ),
      );
    },
    deleteRequest: (
      offerId,
      offerType,
      onSuccess,
      onFailure,
    ) => {
      dispatch(
        cartDeleteOfferRequest.deleteRequest(
          onSuccess,
          onFailure,
          {
            offerId,
            offerType,
          },
          {},
        ),
      );
    },
  },
  storeOffersLoadReq: (
    storeCode,
    onSuccess,
    onFailure,
    params = {},
  ) => {
    dispatch(
      storeOfferLoadActions.loadRequest(
        onSuccess,
        onFailure,
        storeCode,
        params,
      ),
    );
  },
});

ApplicableValueOffers.propTypes = {
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  cartDetails: PropTypes.shape({
    store: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.number,
      type: PropTypes.number,
      city: PropTypes.number,
      locality: PropTypes.number,
    }),
    cartId: PropTypes.number,
  }),
  applyOffer: PropTypes.shape({
    addRequest: PropTypes.func,
    deleteRequest: PropTypes.func,
  }).isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  storeOffers: PropTypes.arrayOf({}).isRequired,
  storeOffersLoadReq: PropTypes.func.isRequired,
  backTo: PropTypes.string,
};

ApplicableValueOffers.defaultProps = {
  cartDetails: null,
  backTo: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicableValueOffers);
