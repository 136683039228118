import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 16 16"
  >
    <defs>
      <filter id="glq40s0isa">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#glq40s0isa)" transform="translate(-838 -2970)">
        <g>
          <path fill={fill} fillRule="nonzero" d="M3.578 5v11H0V5h3.578zm5 0v2.103C9.014 6.517 10.337 5 12.423 5c.244 0 .452.027.642.065.049.008.097.017.145.028.04.011.076.024.114.036C14.861 5.53 16 6.915 16 8.577V16h-3.577V9c-1.141-2.766-3.845 1-3.845 1v6H5V5h3.578zM1.75 0C2.716 0 3.5.784 3.5 1.75S2.716 3.5 1.75 3.5 0 2.716 0 1.75.784 0 1.75 0z" transform="translate(838 2970)" />
        </g>
      </g>
    </g>
  </svg>

);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
