import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl } = apiUrls();

const superSaverCouponApis = {
  superSaverCoupon: (cancelToken, params = {}) => {
    const superSaver = `${newOOBaseUrl}customer/super-savings`;
    return Axios({
      url: superSaver,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },
};

export default superSaverCouponApis;
