import React from 'react';

const Svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 13V2H4V18H11" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 13V2H4V18H11" stroke="black" strokeOpacity="0.72" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 6H15" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 6H15" stroke="black" strokeOpacity="0.72" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 18L15.5 19L17 16" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 18L15.5 19L17 16" stroke="black" strokeOpacity="0.72" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 10H15" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 10H15" stroke="black" strokeOpacity="0.72" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 14H9" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 14H9" stroke="black" strokeOpacity="0.72" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="15.5" cy="17.5" r="4.5" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="15.5" cy="17.5" r="4.5" stroke="black" strokeOpacity="0.72" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Svg;
