import { createStore, applyMiddleware, combineReducers } from 'redux';
import logger from 'redux-logger';
import APIMiddleware from './APIMiddleware';
import MainReducer from './MainReducer';

export default createStore(
  combineReducers({
    main: MainReducer,
  }),
  applyMiddleware(
    ...[APIMiddleware, logger],
  ),
);
