import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Constants } from 'app/utilities';
import {
  getCloseDefaultGuestLocation, setCloseDefaultGuestLocation,
} from 'app/utilities/Storage';
// import * as Clevertap from 'clevertap/Clevertap';
import { toggleGuestLocation } from 'app/store/Actions';
import { isAuthenticated } from 'app/utilities/Utils';
import Svg from './Svg';

const isMobile = window.screen.width < 992;

function DefaultGuestLocationAlert(props) {
  const {
    selectedAddress, language, toggleLocation, history,
  } = props;
  const [
    isGuestLocationClosed, setIsGuestLocationClosed,
  ] = useState(!!getCloseDefaultGuestLocation());

  const isGuestLocationEligible = (
    selectedAddress && selectedAddress.isDummy && !isAuthenticated() && !isGuestLocationClosed
  );
  return isGuestLocationEligible ? (
    <Container
      fluid
      className="mx-0 px-0 h-40p d-flex align-items-center bg-yellow-light"
    >
      <Container
        className={isMobile ? 'px-2' : ''}
      >
        <Row
          className="align-items-center fs-6"
        >
          <Col
            className={isMobile ? 'pr-0' : 'text-center'}
          >
            You are currently shopping at
            &nbsp;
            <b>
              <u>
                {selectedAddress.addressText}
              </u>
            </b>
          </Col>
          <Col
            xs="auto"
            className="pl-0 d-flex align-items-center"
          >
            <Button
              variant="link"
              className="p-0 fs-5"
              onClick={() => {
                if (isMobile) {
                  history.replace('/guest-location');
                } else {
                  toggleLocation(true);
                }
              }}
            >
              {Constants.String.CHANGE[language].toUpperCase()}
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setIsGuestLocationClosed(true);
                setCloseDefaultGuestLocation(new Date().getTime());
              }}
            >
              <Svg
                svg="circleClose"
                width={16}
                pathFill={Constants.Color.grayLighter}
                circleFill={Constants.Color.gray}
              />
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  ) : <></>;
}

const mapStateToProps = (state) => ({
  selectedAddress: state.main.selectedAddress,
  cartItems: state.main.cartItems,
  language: state.main.language,
});

const mapDispatchToProps = (dispatch) => ({
  toggleLocation: (toggleGuestLocationPopup) => {
    dispatch(toggleGuestLocation(toggleGuestLocationPopup));
  },
});

DefaultGuestLocationAlert.propTypes = {
  cartItems: PropTypes.shape({}),
  language: PropTypes.string.isRequired,
  toggleLocation: PropTypes.func.isRequired,
  selectedAddress: PropTypes.shape({
    addressText: PropTypes.string,
    isDummy: PropTypes.bool,
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

DefaultGuestLocationAlert.defaultProps = {
  cartItems: null,
  selectedAddress: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultGuestLocationAlert);
