import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Button,
} from 'react-bootstrap';

const SideOverlay = (props) => {
  const {
    toggleSideOverlay, ChildComponent, closeButton,
  } = props;
  return (
    <Container
      fluid
      className="px-0 h-100"
    >
      {closeButton && (
      <Row
        className="mx-0 position-absolute z-100 w-100"
      >
        <Button
          variant="link"
          className="ml-auto fs-1 z-index-1 text-secondary"
          onClick={() => toggleSideOverlay(null)}
        >
          &times;
        </Button>
      </Row>
      )}
      <Row
        className="mx-0 h-100"
      >
        {
          ChildComponent && (
            <ChildComponent
              {...props}
            />
          )
        }
      </Row>
    </Container>
  );
};

SideOverlay.propTypes = {
  ChildComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  toggleSideOverlay: PropTypes.func.isRequired,
  closeButton: PropTypes.bool,
};

SideOverlay.defaultProps = {
  ChildComponent: null,
  closeButton: true,
};

export default SideOverlay;
