import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from '.';

const CustomOfferBadge = ({ badgeText, badgeType }) => {
  let badgeSvgJSX = null;
  if (badgeType === 'STAR_OFFER') {
    badgeSvgJSX = (
      <Svg
        svg="starOfferBadge"
        width="48"
        height="48"
      />
    );
  }
  return (
    <div
      className="r__custom_offer_badge"
    >
      {badgeSvgJSX}
      <div
        className="r__custom_offer_badge-text"
      >
        <b>{badgeText}</b>
      </div>
    </div>
  );
};

CustomOfferBadge.propTypes = {
  badgeText: PropTypes.string.isRequired,
  badgeType: PropTypes.string.isRequired,
};

export default CustomOfferBadge;
