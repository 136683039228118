import React from 'react';
import PropTypes from 'prop-types';

const DownArrow = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill="#B8238E"
      fillRule="evenodd"
      d="M17.066 9.434a.8.8 0 0 1 0 1.132l-4 4a.8.8 0 0 1-1.132 0l-4-4a.8.8 0 1 1 1.132-1.132L12.5 12.87l3.434-3.435a.8.8 0 0 1 1.132 0Z"
      clipRule="evenodd"
    />
  </svg>
);

DownArrow.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default DownArrow;
