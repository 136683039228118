import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill1, fill2, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={fill1}
      fillRule="evenodd"
      d="M12 6.7c-2.927 0-5.3 2.373-5.3 5.3 0 2.927 2.373 5.3 5.3 5.3 2.927 0 5.3-2.373 5.3-5.3 0-2.927-2.373-5.3-5.3-5.3zM5.3 12c0-3.7 3-6.7 6.7-6.7s6.7 3 6.7 6.7-3 6.7-6.7 6.7-6.7-3-6.7-6.7z"
      clipRule="evenodd"
    />
    <path
      fill={fill2}
      fillOpacity=".72"
      fillRule="evenodd"
      d="M12 6.7c-2.927 0-5.3 2.373-5.3 5.3 0 2.927 2.373 5.3 5.3 5.3 2.927 0 5.3-2.373 5.3-5.3 0-2.927-2.373-5.3-5.3-5.3zM5.3 12c0-3.7 3-6.7 6.7-6.7s6.7 3 6.7 6.7-3 6.7-6.7 6.7-6.7-3-6.7-6.7z"
      clipRule="evenodd"
    />
    <path
      fill={fill1}
      fillRule="evenodd"
      d="M7.495 4.505c.274.273.274.717 0 .99l-1 1c-.273.273-.716.273-.99 0-.273-.273-.273-.717 0-.99l1-1c.274-.273.717-.273.99 0z"
      clipRule="evenodd"
    />
    <path
      fill={fill2}
      fillOpacity=".72"
      fillRule="evenodd"
      d="M7.495 4.505c.274.273.274.717 0 .99l-1 1c-.273.273-.716.273-.99 0-.273-.273-.273-.717 0-.99l1-1c.274-.273.717-.273.99 0z"
      clipRule="evenodd"
    />
    <path
      fill={fill1}
      fillRule="evenodd"
      d="M16.505 4.505c-.273.273-.273.717 0 .99l1 1c.274.273.717.273.99 0 .274-.273.274-.717 0-.99l-1-1c-.273-.273-.716-.273-.99 0z"
      clipRule="evenodd"
    />
    <path
      fill={fill2}
      fillOpacity=".72"
      fillRule="evenodd"
      d="M16.505 4.505c-.273.273-.273.717 0 .99l1 1c.274.273.717.273.99 0 .274-.273.274-.717 0-.99l-1-1c-.273-.273-.716-.273-.99 0z"
      clipRule="evenodd"
    />
    <path
      fill={fill1}
      fillRule="evenodd"
      d="M12 8.3c.387 0 .7.313.7.7v2.71l1.795 1.795c.274.273.274.717 0 .99-.273.273-.716.273-.99 0l-2-2c-.131-.131-.205-.31-.205-.495V9c0-.387.314-.7.7-.7z"
      clipRule="evenodd"
    />
    <path
      fill={fill2}
      fillOpacity=".72"
      fillRule="evenodd"
      d="M12 8.3c.387 0 .7.313.7.7v2.71l1.795 1.795c.274.273.274.717 0 .99-.273.273-.716.273-.99 0l-2-2c-.131-.131-.205-.31-.205-.495V9c0-.387.314-.7.7-.7z"
      clipRule="evenodd"
    />
  </svg>
);

Svg.propTypes = {
  fill1: PropTypes.string.isRequired,
  fill2: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
