import React from 'react';
import PropTypes from 'prop-types';
import Account from './svg/Account';
import Add from './svg/Add';
import Address from './svg/Address';
import ArrowDown from './svg/ArrowDown';
import Badge from './svg/Badge';
import Bell from './svg/Bell';
import Bin from './svg/Bin';
import BirthDate from './svg/BirthDate';
import InfoFilled from './svg/InfoFilled';
import Camera from './svg/Camera';
import Cart from './svg/Cart';
import Chat from './svg/Chat';
import CircleArrow from './svg/CircleArrow';
import CircleClose from './svg/CircleClose';
import CircleDone from './svg/CircleDone';
import CircleLeftArrow from './svg/CircleLeftArrow';
import CirclePlus from './svg/CirclePlus';
import CircleRightArrow from './svg/CircleRightArrow';
import ClippedOffer from './svg/ClippedOffer';
import Close from './svg/Close';
import Coin from './svg/Coin';
import Copy from './svg/Copy';
import Coupon from './svg/Coupon';
import Delivery from './svg/Delivery';
import DeliveryTruck from './svg/DeliveryTruck';
import Description from './svg/Description';
import Email from './svg/Email';
import Fresh from './svg/Fresh';
import FavouriteLove from './svg/FavouriteLove';
import Gender from './svg/Gender';
import GooglePlay from './svg/GooglePlay';
import Home from './svg/Home';
import HeartFilled from './svg/HeartFilled';
import HeartOutline from './svg/HeartOutline';
import Info from './svg/Info';
import Language from './svg/Language';
import LeftArrow from './svg/LeftArrow';
import LessThan from './svg/LessThan';
import LocateMe from './svg/LocateMe';
import Location from './svg/Location';
import Lock from './svg/Lock';
import Logo from './svg/Logo';
import LogoLL from './svg/LogoLL';
import Offers from './svg/Offers';
import OffersFilled from './svg/OffersFilled';
import Orders from './svg/Orders';
import Payment from './svg/Payment';
import Phone from './svg/Phone';
import PhoneOutgoing from './svg/PhoneOutgoing';
import Play from './svg/Play';
import PlayNew from './svg/PlayNew';
import PointHistory from './svg/PointHistory';
import RequestPoint from './svg/RequestPoint';
import RewardGift from './svg/RewardGift';
import RewardCup from './svg/RewardCup';
import Search from './svg/Search';
import Shop from './svg/Shop';
import ShoppingBasket from './svg/ShoppingBasket';
import Star from './svg/Star';
import StarFilled from './svg/StarFilled';
import Stores from './svg/Stores';
import Support from './svg/Support';
import TakeAway from './svg/TakeAway';
import Timer from './svg/Timer';
import Zigzag from './svg/Zigzag';
import ArrowRight from './svg/ArrowRight';
import ReferAndEarn from './svg/ReferAndEarn';
import CopyReferral from './svg/CopyReferral';
import Whatsapp from './svg/Whatsapp';
import WhatsappOutlined from './svg/WhatsappOutlined';
import Help from './svg/Help';
import SupportChat from './svg/SupportChat';
import Share from './svg/Share';
import Finger from './svg/Finger';
import PastOrders from './svg/PastOrders';
import CouponTag from './svg/CouponTag';
import OffersYellow from './svg/OffersYellow';
import CircleTick from './svg/CircleTick';
import WhatsappIcon from './svg/WhatsappIcon';
import Chat2 from './svg/Chat2';
import Star2 from './svg/Star2';
import GraphicOffer from './svg/GraphicOffer';
import IconOval from './svg/IconOval';
import GraphicLogoLL from './svg/GraphicLogoLL';
import ArrowLeft from './svg/ArrowLeft';
import ProfileIconOval from './svg/ProfileIconOval';
import SupportEmail from './svg/SupportEmail';
import SupportPhone from './svg/SupportPhone';
import HelpSupport from './svg/HelpSupport';
import HelpSupportOutlined from './svg/HelpSupportOutlined';
import PhoneCircle from './svg/PhoneCircle';
import ChatCircle from './svg/ChatCircle';
import Facebook from './svg/Facebook';
import LinkedIn from './svg/LinkedIn';
import Twitter from './svg/Twitter';
import Youtube from './svg/Youtube';
import Instagram from './svg/Instagram';
import SocialShareTwitter from './svg/SocialShareTwitter';
import SocialShareWhatsapp from './svg/SocialShareWhatsapp';
import SocialShareFacebook from './svg/SocialShareFacebook';
import SocialShareCopy from './svg/SocialShareCopy';
import Forward from './svg/Forward';
import GlobalSearch from './svg/GlobalSearch';
import FreeBadge from './svg/FreeBadge';
import PlusBadge from './svg/PlusBadge';
import TimerClock from './svg/TimerClock';
import CashIcon from './svg/CashIcon';
import Chevron from './svg/Chevron';
import ProductBadge from './svg/ProductBadge';
import OfferBadge from './svg/OfferBadge';
import TiltArrowRight from './svg/TiltArrowRight';
import FilledTriangle from './svg/FilledTriangle';
import GraphicRupee from './svg/GraphicRupee';
import OfferTag from './svg/OfferTag';
import TiltArrowLeft from './svg/TiltArrowLeft';
import PickUp from './svg/PickUp';
import Truck from './svg/Truck';
import EditPencil from './svg/EditPencil';
import Caution from './svg/Caution';
import Alarm from './svg/Alarm';
import Scooter from './svg/Scooter';
import ArrowUp from './svg/ArrowUp';
import CartDanger from './svg/CartDanger';
import Warning from './svg/Warning';
import RoundOfferBadge from './svg/RoundOfferBadge';
import Star3 from './svg/Star3';
import Bike from './svg/Bike';
import Vehical from './svg/Vehical';
import ShareButton from './svg/ShareButton';
import Premium from './svg/Premium';
import GreatOffers from './svg/GreatOffers';
import Trending from './svg/Trending';
import WideVariety from './svg/WideVariety';
import BestRated from './svg/BestRated';
import StoreNew from './svg/StoreNew';
import Verified from './svg/Verified';
import StarOfferBadge from './svg/StarOfferBadge';
import OfferStar from './svg/OfferStar';
import ArrowRightRound from './svg/ArrowRightRound';
import ArrowRightNew from './svg/ArrowRightNew';
import PreviousSearchIcon from './svg/PreviousSearchIcon';
import TrendingSearchIcon from './svg/TrendingSearchIcon';
import SearchIcon from './svg/SearchIcon';
import CheckBox from './svg/Checkbox';
import ArrowNew from './svg/ArrowNew';
import GreyCircle from './svg/GreyCircle';
import CircleCheckbox from './svg/CircleCheckbox';
import HomeIcon from './svg/HomeIcon';
import Work from './svg/Work';
import LocationIcon from './svg/LocationIcon';
import MoreOption from './svg/MoreOption';
import TickMark from './svg/TickMark';
import AddLocation from './svg/AddLocation';
import UpArrow from './svg/UpArrow';
import DownArrow from './svg/DownArrow';
import CurrentLocation from './svg/CurrentLocation';
import ReferShop from './svg/ReferShop';
import Privacy from './svg/Privacy';
import LockCircle from './svg/LockCircle';
import CheckCircle from './svg/CheckCircle';
import Terms from './svg/Terms';
import Logout from './svg/Logout';
import ProfileIcon from './svg/ProfileIcon';
import Pencil from './svg/Pencil';
import FillArrow from './svg/FillArrow';
import OrderWaiting from './svg/OrderWaiting';
import OrderAccepted from './svg/OrderAccepted';
import OrderOnTheWay from './svg/OrderOnTheWay';
import OrderPickUp from './svg/OrderPickUp';
import OrderModified from './svg/OrderModified';
import PaymentPending from './svg/PaymentPending';
import PaymentTimer from './svg/PaymentTimer';
import Clock from './svg/Clock';
import FreeSample from './svg/FreeSample';
import SupportIcon from './svg/SupportIcon';
import BlackCaution from './svg/BlackCaution';
import StripScooter from './svg/StripScooter';
import AppStore from './svg/AppStore';

const Svg = (props) => {
  const {
    svg,
  } = props;
  switch (svg) {
    case 'account': return <Account {...props} />;
    case 'add': return <Add {...props} />;
    case 'address': return <Address {...props} />;
    case 'arrowDown': return <ArrowDown {...props} />;
    case 'badge': return <Badge {...props} />;
    case 'bell': return <Bell {...props} />;
    case 'bin': return <Bin {...props} />;
    case 'birthDate': return <BirthDate {...props} />;
    case 'camera': return <Camera {...props} />;
    case 'cart': return <Cart {...props} />;
    case 'chat': return <Chat {...props} />;
    case 'circleArrow': return <CircleArrow {...props} />;
    case 'circleClose': return <CircleClose {...props} />;
    case 'circleDone': return <CircleDone {...props} />;
    case 'circleLeftArrow': return <CircleLeftArrow {...props} />;
    case 'circleRightArrow': return <CircleRightArrow {...props} />;
    case 'circleTick': return <CircleTick {...props} />;
    case 'close': return <Close {...props} />;
    case 'coin': return <Coin {...props} />;
    case 'copy': return <Copy {...props} />;
    case 'coupon': return <Coupon {...props} />;
    case 'delivery': return <Delivery {...props} />;
    case 'deliveryTruck': return <DeliveryTruck {...props} />;
    case 'description': return <Description {...props} />;
    case 'email': return <Email {...props} />;
    case 'fresh': return <Fresh {...props} />;
    case 'favouriteLove': return <FavouriteLove {...props} />;
    case 'gender': return <Gender {...props} />;
    case 'googlePlay': return <GooglePlay {...props} />;
    case 'home': return <Home {...props} />;
    case 'heartFilled': return <HeartFilled {...props} />;
    case 'heartOutline': return <HeartOutline {...props} />;
    case 'info': return <Info {...props} />;
    case 'infoFilled': return <InfoFilled {...props} />;
    case 'language': return <Language {...props} />;
    case 'leftArrow': return <LeftArrow {...props} />;
    case 'lessThan': return <LessThan {...props} />;
    case 'locate-me': return <LocateMe {...props} />;
    case 'location': return <Location {...props} />;
    case 'lock': return <Lock {...props} />;
    case 'logo': return <Logo {...props} />;
    case 'logo-ll': return <LogoLL {...props} />;
    case 'offers': return <Offers {...props} />;
    case 'offersYellow': return <OffersYellow {...props} />;
    case 'offersFilled': return <OffersFilled {...props} />;
    case 'orders': return <Orders {...props} />;
    case 'payment': return <Payment {...props} />;
    case 'phone': return <Phone {...props} />;
    case 'phoneOutgoing': return <PhoneOutgoing {...props} />;
    case 'play': return <Play {...props} />;
    case 'playNew': return <PlayNew {...props} />;
    case 'pointHistory': return <PointHistory {...props} />;
    case 'requestPoint': return <RequestPoint {...props} />;
    case 'rewardGift': return <RewardGift {...props} />;
    case 'rewardCup': return <RewardCup {...props} />;
    case 'search': return <Search {...props} />;
    case 'globalSearch': return <GlobalSearch {...props} />;
    case 'shop': return <Shop {...props} />;
    case 'shoppingBasket': return <ShoppingBasket {...props} />;
    case 'star': return <Star {...props} />;
    case 'starFilled': return <StarFilled {...props} />;
    case 'stores': return <Stores {...props} />;
    case 'support': return <Support {...props} />;
    case 'take-away': return <TakeAway {...props} />;
    case 'timer': return <Timer {...props} />;
    case 'zigzag': return <Zigzag {...props} />;
    case 'arrowRight': return <ArrowRight {...props} />;
    case 'referAndEarn': return <ReferAndEarn {...props} />;
    case 'whatsapp': return <Whatsapp {...props} />;
    case 'whatsappOutlined': return <WhatsappOutlined {...props} />;
    case 'copyReferral': return <CopyReferral {...props} />;
    case 'help': return <Help {...props} />;
    case 'supportChat': return <SupportChat {...props} />;
    case 'share': return <Share {...props} />;
    case 'finger': return <Finger {...props} />;
    case 'pastOrders': return <PastOrders {...props} />;
    case 'couponTag': return <CouponTag {...props} />;
    case 'whatsappIcon': return <WhatsappIcon {...props} />;
    case 'chat2': return <Chat2 {...props} />;
    case 'star2': return <Star2 {...props} />;
    case 'graphicOffer': return <GraphicOffer {...props} />;
    case 'clippedOffer': return <ClippedOffer {...props} />;
    case 'circlePlus': return <CirclePlus {...props} />;
    case 'iconOval': return <IconOval {...props} />;
    case 'graphicLogoLL': return <GraphicLogoLL {...props} />;
    case 'arrowLeft': return <ArrowLeft {...props} />;
    case 'profileIconOval': return <ProfileIconOval {...props} />;
    case 'supportEmail': return <SupportEmail {...props} />;
    case 'supportPhone': return <SupportPhone {...props} />;
    case 'helpSupport': return <HelpSupport {...props} />;
    case 'helpSupportOutlined': return <HelpSupportOutlined {...props} />;
    case 'phoneCircle': return <PhoneCircle {...props} />;
    case 'chatCircle': return <ChatCircle {...props} />;
    case 'facebook': return <Facebook {...props} />;
    case 'linkedIn': return <LinkedIn {...props} />;
    case 'twitter': return <Twitter {...props} />;
    case 'youtube': return <Youtube {...props} />;
    case 'instagram': return <Instagram {...props} />;
    case 'socialShareTwitter': return <SocialShareTwitter {...props} />;
    case 'socialShareWhatsapp': return <SocialShareWhatsapp {...props} />;
    case 'socialShareFacebook': return <SocialShareFacebook {...props} />;
    case 'socialShareCopy': return <SocialShareCopy {...props} />;
    case 'forward': return <Forward {...props} />;
    case 'freeBadge': return <FreeBadge {...props} />;
    case 'plusBadge': return <PlusBadge {...props} />;
    case 'timerClock': return <TimerClock {...props} />;
    case 'cashIcon': return <CashIcon {...props} />;
    case 'chevron': return <Chevron {...props} />;
    case 'productBadge': return <ProductBadge {...props} />;
    case 'offerBadge': return <OfferBadge {...props} />;
    case 'filledTriangle': return <FilledTriangle {...props} />;
    case 'graphicRupee': return <GraphicRupee {...props} />;
    case 'offerTag': return <OfferTag {...props} />;
    case 'tiltArrowRight': return <TiltArrowRight {...props} />;
    case 'truck': return <Truck {...props} />;
    case 'pickup': return <PickUp {...props} />;
    case 'editPencil': return <EditPencil {...props} />;
    case 'tiltArrowLeft': return <TiltArrowLeft {...props} />;
    case 'caution': return <Caution {...props} />;
    case 'alarm': return <Alarm {...props} />;
    case 'scooter': return <Scooter {...props} />;
    case 'arrowUp': return <ArrowUp {...props} />;
    case 'warning': return <Warning {...props} />;
    case 'cartDanger': return <CartDanger {...props} />;
    case 'roundOfferBadge': return <RoundOfferBadge {...props} />;
    case 'newStar': return <Star3 {...props} />;
    case 'bike': return <Bike {...props} />;
    case 'vehical': return <Vehical {...props} />;
    case 'shareButton': return <ShareButton {...props} />;
    case 'premium': return <Premium {...props} />;
    case 'greatOffers': return <GreatOffers {...props} />;
    case 'trending': return <Trending {...props} />;
    case 'wideVariety': return <WideVariety {...props} />;
    case 'bestRated': return <BestRated {...props} />;
    case 'storeNew': return <StoreNew {...props} />;
    case 'verified': return <Verified {...props} />;
    case 'starOfferBadge': return <StarOfferBadge {...props} />;
    case 'offerStar': return <OfferStar {...props} />;
    case 'arrowRightNew': return <ArrowRightNew {...props} />;
    case 'arrowRightRound': return <ArrowRightRound {...props} />;
    case 'previousSearch': return <PreviousSearchIcon {...props} />;
    case 'trendingSearch': return <TrendingSearchIcon {...props} />;
    case 'searchIcon': return <SearchIcon {...props} />;
    case 'checkBox': return <CheckBox {...props} />;
    case 'arrowNew': return <ArrowNew {...props} />;
    case 'greyCircle': return <GreyCircle {...props} />;
    case 'circleCheckbox': return <CircleCheckbox {...props} />;
    case 'homeIcon': return <HomeIcon {...props} />;
    case 'work': return <Work {...props} />;
    case 'locationIcon': return <LocationIcon {...props} />;
    case 'moreOption': return <MoreOption {...props} />;
    case 'tickMark': return <TickMark {...props} />;
    case 'addLocation': return <AddLocation {...props} />;
    case 'upArrow': return <UpArrow {...props} />;
    case 'downArrow': return <DownArrow {...props} />;
    case 'currentLocation': return <CurrentLocation {...props} />;
    case 'referShop': return <ReferShop {...props} />;
    case 'privacy': return <Privacy {...props} />;
    case 'lockCircle': return <LockCircle {...props} />;
    case 'checkCircle': return <CheckCircle {...props} />;
    case 'terms': return <Terms {...props} />;
    case 'logout': return <Logout {...props} />;
    case 'profileIcon': return <ProfileIcon {...props} />;
    case 'pencil': return <Pencil {...props} />;
    case 'fillArrow': return <FillArrow {...props} />;
    case 'orderPlaced': return <OrderWaiting {...props} />;
    case 'orderAccepted': return <OrderAccepted {...props} />;
    case 'orderOnTheWay': return <OrderOnTheWay {...props} />;
    case 'orderPickUp': return <OrderPickUp {...props} />;
    case 'orderModified': return <OrderModified {...props} />;
    case 'paymentPending': return <PaymentPending {...props} />;
    case 'paymentTimer': return <PaymentTimer {...props} />;
    case 'clock': return <Clock {...props} />;
    case 'freeSample': return <FreeSample {...props} />;
    case 'supportIcon': return <SupportIcon {...props} />;
    case 'blackCaution': return <BlackCaution {...props} />;
    case 'stripScooter': return <StripScooter {...props} />;
    case 'appStore': return <AppStore {...props} />;
    default: return null;
  }
};

Svg.propTypes = {
  svg: PropTypes.string.isRequired,
};

export default Svg;
