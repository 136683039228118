import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 16 12"
  >
    <defs>
      <filter id="qsf8qt943a">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#qsf8qt943a)" transform="translate(-930 -2972)">
        <g>
          <path fill={fill} fillRule="nonzero" d="M15.32 1.727c-.434-.772-.905-.914-1.864-.968-.958-.065-3.367-.092-5.454-.092-2.091 0-4.501.027-5.458.09-.957.056-1.429.197-1.867.97C.23 2.497 0 3.826 0 6.164v.008C0 8.5.23 9.838.677 10.6c.438.772.909.912 1.866.977.958.056 3.368.089 5.459.089 2.087 0 4.496-.033 5.455-.088.959-.065 1.43-.205 1.864-.977.451-.763.679-2.101.679-4.43v-.004-.003c0-2.34-.228-3.667-.68-4.438zM6 9.167v-6l5 3-5 3z" transform="translate(930 2972)" />
        </g>
      </g>
    </g>
  </svg>

);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
