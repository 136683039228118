// Super Saver Coupons Load Actions
export const SUPER_SAVER_COUPONS_LOAD_REQUEST = 'SUPER_SAVER_COUPONS_LOAD_REQUEST';
export const SUPER_SAVER_COUPONS_LOAD_SUCCESS = 'SUPER_SAVER_COUPONS_LOAD_SUCCESS';
export const SUPER_SAVER_COUPONS_LOAD_FAILURE = 'SUPER_SAVER_COUPONS_LOAD_FAILURE';

export const superSaverCouponsLoadAction = {
  loadRequest: (
    onSuccess, onFailure, cancelToken, params,
  ) => ({
    type: SUPER_SAVER_COUPONS_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      cancelToken,
      params,
    },
  }),

  loadSuccess: (data) => ({
    type: SUPER_SAVER_COUPONS_LOAD_SUCCESS,
    payload: {
      superSaverCoupons: data.res,
    },
  }),

  loadFailure: () => ({
    type: SUPER_SAVER_COUPONS_LOAD_FAILURE,
  }),
};
