import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import { initSingular } from 'singular';
import App from './app/App';
import store from './app/store/Store';
import { initClevertap } from './clevertap/Clevertap';

const initSentry = () => {
  if (process.env.REACT_APP_ENV === 'production' && (process.env.NODE_ENV !== 'development')) {
    Sentry.init({
      dsn: 'https://887460589be640cfbacde3e1b084b7cf@o4504672018628608.ingest.sentry.io/4504779605409792',
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.0,
    });
  }
};

initClevertap();
initSentry();
initSingular();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
