import React from 'react';
import PropTypes from 'prop-types';

const AddLocation = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="#B8238E"
        d="M12.667 5.333a.645.645 0 0 0 .475-.192.643.643 0 0 0 .192-.474V3.333h1.333a.645.645 0 0 0 .475-.192.643.643 0 0 0 .192-.474.646.646 0 0 0-.192-.476.643.643 0 0 0-.475-.191h-1.333V.667a.646.646 0 0 0-.192-.476.643.643 0 0 0-.475-.191.645.645 0 0 0-.475.192.643.643 0 0 0-.192.475V2h-1.333a.645.645 0 0 0-.475.192.643.643 0 0 0-.192.475c0 .189.064.347.192.475a.643.643 0 0 0 .475.191H12v1.334c0 .189.064.347.192.475a.643.643 0 0 0 .475.191ZM8 8c.367 0 .681-.13.942-.392.262-.261.392-.575.392-.941 0-.367-.131-.681-.392-.942A1.281 1.281 0 0 0 8 5.333c-.366 0-.68.131-.942.392a1.281 1.281 0 0 0-.391.942c0 .366.13.68.392.942C7.32 7.87 7.634 8 8 8Zm0 6.417a.8.8 0 0 1-.5-.183C5.878 12.8 4.667 11.468 3.867 10.24c-.8-1.228-1.2-2.375-1.2-3.441 0-1.667.536-2.994 1.609-3.983C5.348 1.827 6.59 1.333 8 1.333c.311 0 .62.025.926.076.305.05.603.13.891.241a1.286 1.286 0 0 0-.483 1.017c0 .366.13.68.392.942.261.261.575.391.941.391h.667v.667c0 .366.13.68.392.942.261.261.575.391.941.391.111 0 .217-.011.317-.033.1-.023.194-.056.283-.1a6.655 6.655 0 0 1 .067.933c0 1.067-.4 2.214-1.2 3.441-.8 1.228-2.011 2.559-3.634 3.992a.8.8 0 0 1-.5.184Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

AddLocation.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default AddLocation;
