import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Container, Row, Col,
} from 'react-bootstrap';
import { Svg } from '../../components/common';
import Constants from '../../utilities/Constants';
import { LOVELOCAL, isAuthenticated } from '../../utilities/Utils';
import * as Freshchat from '../../../freshchat/Freshchat';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as buttonActions from '../../events/buttonActions';
import { logButtonClick } from '../../events/Events';

const isMobileAgent = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

const CustomerSupport = (props) => {
  const {
    language, toggleLogin, show, stores, toggleSideOverlay,
    toggleOverlay, toggleSupportDialog, isMobile, page,
  } = props;
  let supportActionJsx = null;
  if (isMobile) {
    supportActionJsx = (
      <Container>
        <Row
          className="py-4 border-bottom"
        >
          <Col
            xs={12}
            className="d-flex align-items-center font-weight-bold"
          >
            <Svg
              svg="supportIcon"
              width={26}
              height={26}
              fill={Constants.Color.black}
              stroke={Constants.Color.black}
            />
            &nbsp;&nbsp;
            {Constants.String.SUPPORT[language]}
          </Col>
          <Col
            xs={12}
            className="text-right"
            onClick={() => toggleSideOverlay(null)}
          >
            <Svg
              svg="close"
              fill={Constants.Color.black}
              width="1rem"
            />
          </Col>
        </Row>
        <Row
          className="py-6"
        >
          <Col
            xs={12}
            className="text-center"
          >
            <Button
              variant="link"
              className="p-0 text-black"
              {
                ...isMobileAgent ? (
                  {
                    href: `tel: ${LOVELOCAL.support}`,
                  }
                ) : (
                  {
                    onClick: () => {
                      toggleSideOverlay(null);
                      toggleSupportDialog();
                      LogClevertapEvent.supportCall(page);
                    },
                  }
                )
              }
            >
              <Svg
                svg="phoneCircle"
                width="56"
              />
              <div
                className="pt-2 fs-6 font-weight-bold"
              >
                {Constants.String.CALL_TO_ORDER[language]}
              </div>
            </Button>
          </Col>
          <Col
            xs={12}
            className="text-center"
          >
            <Button
              variant="link"
              className="p-0 text-black"
              onClick={() => {
                if (isAuthenticated()) {
                  Freshchat.updateProfile();
                  Freshchat.open();
                  toggleSideOverlay(null);
                  LogClevertapEvent.supportChat(page);
                  logButtonClick(
                    {
                      BUTTON: buttonActions.CHAT_SUPPORT,
                    },
                  );
                  return;
                }
                toggleSideOverlay(null);
                toggleLogin();
              }}
            >
              <Svg
                svg="chatCircle"
                width="56"
              />
              <div
                className="pt-2 fs-6 font-weight-bold"
              >
                {Constants.String.CHAT_SUPPORT[language]}
              </div>
            </Button>
          </Col>
        </Row>
      </Container>
    );
  } else {
    supportActionJsx = (
      <div
        id="customer-support"
        className="customer-support-overlay text-right"
      >
        {!isAuthenticated()
            || (stores && stores.onMpaaniCount !== 0)
          ? (
            <Button
              variant="link"
              className="bg-transparent d-flex align-items-center justify-content-end p-0 ml-auto mb-2"
              {
                ...isMobileAgent ? (
                  {
                    href: `tel: ${LOVELOCAL.support}`,
                  }
                ) : (
                  {
                    onClick: () => {
                      toggleOverlay();
                      toggleSupportDialog();
                      LogClevertapEvent.supportCall(page);
                    },
                  }
                )
              }
            >
              <div
                className="text-truncate bg-white rounded fs-5 text-primary px-3 py-1"
              >
                {Constants.String.CALL_TO_ORDER[language]}
              </div>
                &nbsp;&nbsp;&nbsp;
              <div
                className="d-flex align-items-center p-3 bg-white rounded-circle"
              >
                <Svg
                  svg="phoneOutgoing"
                  fill={Constants.Color.primary}
                  width="1.4rem"
                />
              </div>
            </Button>
          )
          : ''}
        <Button
          variant="link"
          className="bg-transparent d-flex align-items-center p-0 mb-2"
          onClick={() => {
            if (isAuthenticated()) {
              Freshchat.updateProfile();
              Freshchat.open();
              toggleOverlay();
              LogClevertapEvent.supportChat(page);
              logButtonClick(
                {
                  BUTTON: buttonActions.CHAT_SUPPORT,
                },
              );
              return;
            }
            toggleLogin();
          }}
        >
          <div
            className="text-truncate bg-white rounded fs-5 text-primary px-3 py-1"
          >
            {Constants.String.CHAT_SUPPORT[language]}
          </div>
            &nbsp;&nbsp;&nbsp;
          <div
            className="d-flex align-items-center p-3 bg-white rounded-circle"
          >
            <Svg
              svg="chat"
              stroke={Constants.Color.primary}
              width="1.5rem"
            />
          </div>
        </Button>
      </div>
    );
  }
  return (
    <div
      className="customer-support"
    >
      <Button
        variant="link"
        className={`py-2 px-4 d-flex align-items-center bg-primary text-white border-radius-25p font-weight-black ${isMobile ? '' : 'fs-3'}`}
        onClick={() => {
          if (isMobile) {
            toggleSideOverlay(
              () => (
                supportActionJsx
              ),
              false,
            );
          } else {
            toggleOverlay();
          }
        }}
      >
        <Svg
          svg="supportIcon"
          width={26}
          height={26}
          fill="#fff"
          stroke="#fff"
        />
         &nbsp;&nbsp;
        {Constants.String.SUPPORT[language]}
      </Button>
      {
        (show && !isMobile) ? (
          supportActionJsx
        ) : ''
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  stores: state.main.stores,
});

CustomerSupport.propTypes = {
  language: PropTypes.string.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired,
  stores: PropTypes.shape({
    onMpaaniCount: PropTypes.number,
  }),
  toggleOverlay: PropTypes.func.isRequired,
  toggleSupportDialog: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
};

CustomerSupport.defaultProps = {
  stores: null,
};

export default connect(mapStateToProps)(CustomerSupport);
