import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 40 40">
    <g transform="translate(5 5)" fill="none" fillRule="evenodd">
      <circle cx={15} cy={15} r={15} fill="#2B2BA0" fillRule="nonzero" />
      <path
        fill="#E065BE"
        d="M18.655 23.762v1.175h-6.587v-1.175h6.587zm-7.396-6.498v.641h.641v.355h-.64v.641h-.356v-.64h-.64v-.356h.64v-.641h.355zm8.01.28a.336.336 0 11-.001.673.337.337 0 110-.673zm-9.792-1.608a.42.42 0 11-.001.001zm1.548-6.883l-.223 1.255c-1.031-.184-1.832.036-1.994.546-.252.793.83 2.655 4.843 4.537l-.541 1.154c-4.778-2.24-5.983-4.608-5.517-6.075.37-1.165 1.717-1.721 3.432-1.416zm12.105 1.416c.466 1.468-.739 3.835-5.517 6.076l-.542-1.154c4.013-1.882 5.096-3.744 4.844-4.537-.162-.51-.963-.73-1.994-.546l-.223-1.255c1.715-.305 3.062.25 3.432 1.416zm-9.507-4.672v.509h.51v.282h-.51v.509h-.281v-.51h-.51v-.281h.51v-.509h.281zm5.867 0a.325.325 0 110 .65.325.325 0 010-.65z"
      />
      <path fill="#F2BA49" d="M14.774 21.511h1.175v-6.588h-1.175z" />
      <path
        fill="#F9ED32"
        fillRule="nonzero"
        d="M9.072 7.585l3.102 8.41a3.397 3.397 0 006.375 0l3.102-8.41H9.07m6.983 13.925a2.265 2.265 0 012.264 2.265h-5.911a2.264 2.264 0 012.263-2.264zm6.995-14.225a.376.376 0 110 .753.376.376 0 010-.753zM18.139 5a.533.533 0 110 1.065.533.533 0 010-1.065z"
      />
      <path
        fill="#F9B732"
        fillRule="nonzero"
        d="M15.361 8.953l.707 2.175h2.287l-1.85 1.345.707 2.175-1.851-1.345-1.85 1.345.707-2.175-1.85-1.345h2.287l.706-2.175"
      />
    </g>
  </svg>
);

Svg.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
