import React from 'react';

const Svg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8"
      cy="8"
      r="7"
      fill="#B8238E"
    />
    <path
      d="M4.5 7.75L7 10.25L11.5 5.75"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export default Svg;
