// Store Offer Load Actions
export const STORE_OFFER_LOAD_REQUEST = 'STORE_OFFER_LOAD_REQUEST';
export const STORE_OFFER_LOAD_SUCCESS = 'STORE_OFFER_LOAD_SUCCESS';
export const STORE_OFFER_LOAD_FAILURE = 'STORE_OFFER_LOAD_FAILURE';

// Past Purchase items Offer
export const PAST_PURCHASE_ITEMS_LOAD_REQUEST = 'PAST_PURCHASE_ITEMS_LOAD_REQUEST';
export const PAST_PURCHASE_ITEMS_LOAD_SUCCESS = 'PAST_PURCHASE_ITEMS_LOAD_SUCCESS';
export const PAST_PURCHASE_ITEMS_LOAD_FAILURE = 'PAST_PURCHASE_ITEMS_LOAD_FAILURE';

// Max Savings
export const MAX_SAVINGS_LOAD_REQUEST = 'MAX_SAVINGS_LOAD_REQUEST';
export const MAX_SAVINGS_LOAD_SUCCESS = 'MAX_SAVINGS_LOAD_SUCCESS';
export const MAX_SAVINGS_LOAD_FAILURE = 'MAX_SAVINGS_LOAD_FAILURE';

// BOGO Offers
export const BUY_1_GET_1_FREE_LOAD_REQUEST = 'BUY_1_GET_1_FREE_LOAD_REQUEST';
export const BUY_1_GET_1_FREE_LOAD_SUCCESS = 'BUY_1_GET_1_FREE_LOAD_SUCCESS';
export const BUY_1_GET_1_FREE_LOAD_FAILURE = 'BUY_1_GET_1_FREE_LOAD_FAILURE';

// GET Free Products
export const GET_FREE_PRODUCT_LOAD_REQUEST = 'GET_FREE_PRODUCT_LOAD_REQUEST';
export const GET_FREE_PRODUCT_LOAD_SUCCESS = 'GET_FREE_PRODUCT_LOAD_SUCCESS';
export const GET_FREE_PRODUCT_LOAD_FAILURE = 'GET_FREE_PRODUCT_LOAD_FAILURE';

export const storeOfferLoadActions = {
  loadRequest: (
    onSuccess, onFailure, storeCode, params,
  ) => ({
    type: STORE_OFFER_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      storeCode,
      params,
    },
  }),
  loadSuccess: (data) => ({
    type: STORE_OFFER_LOAD_SUCCESS,
    payload: {
      storeOffers: data.res,
    },
  }),
  loadFailure: () => ({
    type: STORE_OFFER_LOAD_FAILURE,
  }),
};

export const pastPurchaseItemsActions = {
  loadRequest: (
    onSuccess, onFailure, cancelToken, params,
  ) => ({
    type: PAST_PURCHASE_ITEMS_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      cancelToken,
      params,
    },
  }),

  loadSuccess: (data) => ({
    type: PAST_PURCHASE_ITEMS_LOAD_SUCCESS,
    payload: {
      pastPurchaseItems: data.res,
    },
  }),

  loadFailure: () => ({
    type: PAST_PURCHASE_ITEMS_LOAD_FAILURE,
  }),
};

export const maxSavingsActions = {
  loadRequest: (
    onSuccess, onFailure, cancelToken, params,
  ) => ({
    type: MAX_SAVINGS_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      cancelToken,
      params,
    },
  }),

  loadSuccess: (data) => ({
    type: MAX_SAVINGS_LOAD_SUCCESS,
    payload: {
      maxSavingsOffers: data.res,
    },
  }),

  loadFailure: () => ({
    type: MAX_SAVINGS_LOAD_FAILURE,
  }),
};

export const bogoOffersActions = {
  loadRequest: (
    onSuccess, onFailure, storeCode, params,
  ) => ({
    type: BUY_1_GET_1_FREE_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      storeCode,
      params,
    },
  }),

  loadSuccess: (data) => ({
    type: BUY_1_GET_1_FREE_LOAD_SUCCESS,
    payload: {
      bogoOffers: data.res,
    },
  }),

  loadFailure: () => ({
    type: BUY_1_GET_1_FREE_LOAD_FAILURE,
  }),
};

export const getFreeProductsActions = {
  loadRequest: (
    onSuccess, onFailure, storeCode, params,
  ) => ({
    type: GET_FREE_PRODUCT_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      storeCode,
      params,
    },
  }),

  loadSuccess: (data) => ({
    type: GET_FREE_PRODUCT_LOAD_SUCCESS,
    payload: {
      comboOffers: data.res,
    },
  }),

  loadFailure: () => ({
    type: GET_FREE_PRODUCT_LOAD_FAILURE,
  }),
};
