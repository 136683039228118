import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth="1px"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5c3.314 0 6 2.79 6 6.23h0V18H6v-7.27l.004-.228C6.12 7.167 8.76 4.5 12 4.5zM9 18c0 1.657 1.343 3 3 3s3-1.343 3-3H9zm3-15v1.5"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
