import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import * as Actions from '../../store/Actions';
import { Constants, Utils } from '../../utilities';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import { getCDNImageUrl, getSEOFriendlyURL } from '../../utilities/Utils';

function Category(props) {
  const {
    history,
    item,
    isMobile,
    language,
    toggleGlobalSearch,
    toggleLogin,
    storeId,
  } = props;
  return (
    <Container className="p-1 mx-0">
      <div className="p-3 bg-white">
        <Row
          as={Button}
          variant="link"
          onClick={() => {
            if (Utils.isAlreadyLoggedIn(toggleLogin)) {
              history.push(`/pc/${getSEOFriendlyURL(item.categoryCode)}?q=${item.query}${storeId
                ? `&sid=${storeId}` : ''}`);
              if (!isMobile) {
                toggleGlobalSearch();
              }
              LogClevertapEvent.searchedElementClick(
                item.query,
                item.categoryName,
              );
            }
          }}
          className="mx-0 w-100 d-flex p-0 cursor-pointer"
        >
          <Col xs={6} className="p-1">
            <div className="category-image-wrapper">
              <img
                src={item.categoryImage !== '' ? getCDNImageUrl(item.categoryImage) : Utils.LOVELOCAL.categoryPlaceholder}
                alt={item.categoryName}
                title={item.categoryName}
                className="category-image"
              />
            </div>
          </Col>
          <Col
            xs={18}
            className={`p-1 text-left ${isMobile ? 'pt-8' : 'pt-6'}`}
          >
            <div className="mb-1">
              <b className="text-black fs-4">
                {item.categoryName}
              </b>
            </div>
            <div className="mt-1">
              <b className="text-primary fs-5">
                {item.count}
                &nbsp;
                {Constants.String.PRODUCTS[language]}
              </b>
            </div>
          </Col>
        </Row>
      </div>
    </Container>

  );
}

const mapDispatchToProps = (dispatch) => ({
  toggleGlobalSearch:
    () => {
      dispatch(Actions.toggleGlobalSearch());
    },
  toggleLogin:
    () => {
      dispatch(Actions.toggleLogin());
    },
});

Category.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  item: PropTypes.shape({
    count: PropTypes.number,
    categoryName: PropTypes.string,
    categoryCode: PropTypes.string,
    categoryImage: PropTypes.string,
    query: PropTypes.string,
  }).isRequired,
  language: PropTypes.string.isRequired,
  toggleGlobalSearch: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  storeId: PropTypes.string,
};

Category.defaultProps = {
  storeId: null,
};

export default connect(null, mapDispatchToProps)(Category);
