import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 17 16"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        stroke={stroke}
      >
        <g>
          <g>
            <g>
              <path
                d="M9.529 3.9L6.882 6.096c-.501.405-1.204.405-1.704 0L2.508 3.9"
                transform="translate(-14 -2515) translate(13 2459) translate(1 56) translate(2.508 2.1)"
              />
              <path
                d="M2.932.4h5.655c.815.01 1.591.362 2.147.976.556.613.844 1.434.796 2.272v4.004c.048.838-.24 1.659-.796 2.272-.556.614-1.332.967-2.147.976H2.932C1.18 10.9 0 9.443 0 7.652V3.648C0 1.858 1.18.4 2.932.4z"
                transform="translate(-14 -2515) translate(13 2459) translate(1 56) translate(2.508 2.1)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
