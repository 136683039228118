import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from '../../store/Actions';
import { Constants, Utils } from '../../utilities';
import { Svg } from '../../components/common';
import {
  getCDNImageUrl, getSeoShopUrl, isAlreadyLoggedIn,
} from '../../utilities/Utils';

function Store(props) {
  const {
    history,
    item,
    isMobile,
    language,
    toggleLogin,
    toggleGlobalSearch,
  } = props;

  const { hasSeoData, shopUrl } = getSeoShopUrl(
    item.country,
    item.city,
    item.locality,
    item.storeType
      ? item.storeType.displayName
      : '',
    item.storeName,
    item.storeCode,
  );

  return (
    <Container
      className="p-1 mx-0"
    >
      <div
        className="p-3 bg-white"
      >
        <Row
          as={hasSeoData ? Link : 'div'}
          to={shopUrl}
          onClick={() => {
            if (isAlreadyLoggedIn(toggleLogin)) {
              if (!hasSeoData) {
                history.push(shopUrl);
              }
              if (!isMobile) {
                toggleGlobalSearch();
              }
            }
          }}
          className={`mx-0 w-100 d-flex p-0 cursor-pointer ${hasSeoData ? 'link-tag' : ''}`}
        >
          <Col
            xs={6}
            className="p-1 category-image-wrapper"
          >
            {
              item.storeImage
                ? (
                  <div
                    className="category-image-wrapper"
                  >
                    <img
                      src={getCDNImageUrl(item.storeImage)}
                      alt={item.storeName}
                      title={item.storeName}
                      className="category-image"
                    />
                  </div>
                ) : (
                  <div
                    className="p-0 d-flex align-items-center justify-content-center h-100 bg-primary-light"
                  >
                    <span
                      className="fs-2 font-weight-bold text-primary"
                    >
                      {Utils.getInitials(item.storeName)}
                    </span>
                  </div>
                )
            }
          </Col>
          <Col
            xs={18}
            className={`p-1 text-left ${isMobile ? 'pt-8' : 'pt-6'}`}
          >
            <div
              className="mb-1"
            >
              <b
                className="text-black fs-4"
              >
                {item.storeName}
              </b>
            </div>
            <Row
              className="m-0 fs-5 mb-2"
            >
              {
                item.rating && (
                  <Col
                    xs="auto"
                    className="d-flex pl-0 pr-2"
                  >
                    <span
                      className="d-flex align-items-center"
                    >
                      <Svg
                        svg="star"
                        fill={Constants.Color.secondary}
                        width="1.2rem"
                      />
                    </span>
                    &nbsp;
                    <b
                      className="text-muted fs-5"
                    >
                      {item.rating}
                    </b>
                  </Col>
                )
              }
              {
                !item.pickupOnlyAllowed
                && (
                <Col
                  xs="auto"
                  className="d-flex align-items-center pl-0 pr-2"
                >
                  <span
                    className="d-flex align-items-center"
                  >
                    <Svg
                      svg="delivery"
                      fill={Constants.Color.secondary}
                      width="1.2rem"
                    />
                  </span>
                  &nbsp;
                  <b
                    className="text-muted fs-5"
                  >
                    {item.expectedDeliveryTime}
                  </b>
                </Col>
                )
              }
              {
                item.pickupOnlyAllowed
                && (
                  <Col
                    xs="auto"
                    className="d-flex px-0"
                  >
                    <span
                      className="d-flex align-items-center"
                    >
                      <Svg
                        svg="timer"
                        fill={Constants.Color.secondary}
                        width="1.2rem"
                      />
                    </span>
                    &nbsp;
                    <b
                      className="text-muted fs-5"
                    >
                      {Constants.String.SHOP_PICKUP_ONLY[language]}
                    </b>
                  </Col>
                )
              }
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => ({
  toggleGlobalSearch:
    () => {
      dispatch(Actions.toggleGlobalSearch());
    },
  toggleLogin:
    () => {
      dispatch(Actions.toggleLogin());
    },
});

Store.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  item: PropTypes.shape({
    rating: PropTypes.string,
    storeCode: PropTypes.string,
    storeImage: PropTypes.string,
    storeName: PropTypes.string,
    expectedDeliveryTime: PropTypes.string,
    pickupOnlyAllowed: PropTypes.bool,
    locality: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    storeType: PropTypes.shape({
      displayName: PropTypes.string,
    }),
  }).isRequired,
  language: PropTypes.string.isRequired,
  toggleGlobalSearch: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Store);
