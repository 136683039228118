import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    style={{
      width,
    }}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 16.086v2.917h2.917l8.602-8.603-2.916-2.917L5 16.086zm13.775-7.942a.774.774 0 0 0 0-1.096l-1.82-1.82a.775.775 0 0 0-1.097 0L14.435 6.65l2.917 2.917 1.423-1.424z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
