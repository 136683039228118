import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.833 3h12.334c.736 0 1.333.597 1.333 1.333v15.334c0 .736-.597 1.333-1.333 1.333H5.833c-.736 0-1.333-.597-1.333-1.333V4.333C4.5 3.597 5.097 3 5.833 3zm5.042 4.875h4.5m-4.5 3.75h4.5m-4.5 3.75h4.5m-6.938-7.5h.376m-.376 3.75h.376m-.376 3.75h.376"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
