import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as BrandSpaceActionTypes from 'app/store/brand-space/actions';
import * as AckBannersActionTypes from 'app/store/acknowledgement/actions';
import { useSelector, useDispatch } from 'react-redux';
import { CustomModal, Svg, Placeholder } from 'app/components/common';
import { Spinner } from 'react-bootstrap';
import ReferFriendInfo from 'app/layout/refer-friends/ReferFriendInfo';
import QuickCard from '../../components/common/QuickCard';
import Footer from '../../layout/footer/Footer';
import FooterMobile from '../../layout/footer/FooterMobile';
import { Constants } from '../../utilities';
import * as ctEvents from '../../events/Events';
import CustomScroller from 'app/components/common/CustomScroller';
import Lottie from 'app/lottie';
import { orderDetailsRequest, toggleScratchCard, cartNullRequest} from 'app/store/Actions';
import { gaLogPurchaseEvents } from 'ecommerce_ga_events/EcommerceGAEvents';
import { Redirect } from 'react-router-dom';
import offerApis from 'api/offer';

const Success = (props) => {
  const { history, language, isMobile, match } = props;
  const isFirstLoad = history?.location?.state?.isFirstLoad;

  const { orderId } = match.params;
  const [scratchCardDetails, setScratchCardDetails] = useState(null);
  const [showBrandVideoModal, setShowBrandVideoModal] = useState(false);
  const [brandVideoLink, setBrandVideoLink] = useState('');
  const [showReferAndEarn, setshowReferAndEarn] = useState(false);

  const handleSetBrandVideoLink = (banner) => {
    setBrandVideoLink(`${banner.deepLink.web}?autoplay=1`);
  };

  const handleToggleVideoModal = () => {
    setShowBrandVideoModal(!showBrandVideoModal);
  };

  const brandVideoFrame = (
    <div className="r__brandVideoFrame">
      <iframe
        title="YouTube video player"
        width="992"
        height="558"
        src={brandVideoLink}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        autoPlay
      />
    </div>
  );

  const {
    brandSpaces,
    ackBanners,
    requestsProcessing,
    requestsFailure,
    customer,
    orderDetails,
  } = useSelector((state) => state.main);

  const dispatch = useDispatch();

  const loadBrandSpaces = () => {
    dispatch(BrandSpaceActionTypes.brandSpaceLoadActions.loadRequest(null, null, '', {
      requestFrom: 'WEB',
      page: 'REFER',
    }));
  };

  const loadAckBanners = () => {
    dispatch(AckBannersActionTypes.ackLoadActions.loadRequest('', {
      section: 'REFER',
    }));
  };

  const {
    [BrandSpaceActionTypes.BRAND_SPACE_LOAD_REQUEST]: processingBrandSpaces,
  } = requestsProcessing;
  const {
    [BrandSpaceActionTypes.BRAND_SPACE_LOAD_REQUEST]: failureBrandSpaces,
  } = requestsFailure;
  const {
    [AckBannersActionTypes.ACK_LOAD_REQUEST]: processingAckBanners,
  } = requestsProcessing;
  const {
    [AckBannersActionTypes.ACK_LOAD_REQUEST]: failureAckBanners,
  } = requestsFailure;

  const orderPurchaseLog = (order = {}) => {
    gaLogPurchaseEvents(
      customer,
      order.orderId,
      order.couponApplied ? order.couponApplied.couponCode : '',
      order.payment.totalBill,
      order.payment.billBeforeDiscount,
      order.payment.overallDiscount,
      order.retailerProducts.map((product) => ({
        item_name: product.name,
        item_id: product.libraryProductId,
        price: product.totalPrice,
        quantity: product.quantity,
      })),
    );
  }

  const loadOrderDetails = () => {
    dispatch(orderDetailsRequest(
      orderId,
      {
        type: 'sync',
        callback: (data) => {
          orderPurchaseLog(data.res);
        }
      }
    ));
  }

  const handleToggleScratchCard = () => {
    if(scratchCardDetails) {
      dispatch(toggleScratchCard(scratchCardDetails))
    }
  }

  const handleCartNullRequest = () => {
    dispatch(cartNullRequest());
  }

  const earnScratchCard = () => {
    offerApis.generateScratchCard(
      orderId,
      {},
      null,
    ).then((res) => {
      handleCartNullRequest();
      const { data } = res.data;
      if (data && Object.keys(data).length > 0) {
        setScratchCardDetails({
          ...data,
          createdOnOrder: true,
        })
      }
    }).catch(() => {
      handleCartNullRequest();
    });
  }

  useEffect(() => {
    if(isFirstLoad){
      loadBrandSpaces();
      loadAckBanners();
      loadOrderDetails();
      earnScratchCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoad]);
  
  if(!isFirstLoad){
    return (
      <Redirect to="/" />
    )
  }
  return (
    <>
      {
        (processingBrandSpaces && processingAckBanners)
          ? (
            <div
              className="r__loader"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          ) : null
      }
      {
        (failureBrandSpaces && failureAckBanners)
          ? (
            <div className="r__loader">
              <Placeholder
                language={language}
                imageSrc="/images/error-placeholder.png"
                heading={Constants.String.OH_NO[language]}
              />
            </div>
          ) : null
      }
      {
        (!processingBrandSpaces && !failureBrandSpaces && !processingAckBanners && !failureAckBanners)
          ? (
            <>
              <div className="r__container r__container_rest">
                <CustomModal
                  show={showReferAndEarn}
                  title={Constants.String.REFER_AND_EARN[language]}
                  closeButton
                  onHide={() => setshowReferAndEarn(false)}
                  body={(
                    <ReferFriendInfo
                      {...props}
                      onHide={() => { setshowReferAndEarn(false); }}
                    />
                  )}
                />
                <CustomModal
                  show={showBrandVideoModal}
                  title=" "
                  size="lg"
                  body={brandVideoFrame}
                  centered
                  closeButton
                  backdrop
                  onHide={() => handleToggleVideoModal()}
                />

                <div className="r__ack_header">
                  <span className='r__ack_message'>Your Order has been placed!</span>
                  <div className='r__ack_lottie'> 
                    <Lottie 
                      animationData={null}
                      background='transparent'
                      height={ (isMobile) ? '80px' : '140px' }
                      width={ (isMobile) ? '140px' : '200px' }
                      animationFor={'ORDER_ACK'}
                      loop={false}
                    />
                  </div>
                  <div className="r__ack_circle">
                  </div>
                </div>

                <section className='r__ack_buttons'>
                  <div 
                    className='r__btn r__btn--secondary'
                    onClick={() => {
                      ctEvents.orderDetailsViewed({
                        Page: 'Order Confirmation',
                        Id: orderId,
                        Source: 'Web'
                      })
                      history.replace(`/orders/${orderId}`);
                      handleToggleScratchCard();
                    }}
                  >Review my Order</div>
                  <div 
                    className='r__btn r__btn--primary'
                    onClick={() => {
                      ctEvents.orderSocialShareTapped({
                        Page: 'Order Confirmation',
                        Store: orderDetails.store.name,
                        Store_Id: orderDetails.store.id,
                        Source: 'Web'
                      })
                      setshowReferAndEarn(true);
                    }}
                  >
                    <span>Refer for discounts</span>
                    <Svg
                      svg="shareButton"
                      width="14px"
                      height="18px"
                      fill="#fff"
                    />
                  </div>
                </section>

                {
                  (ackBanners?.length > 0) ?
                  (
                    <section className='r__section r__container_rest text-center'>
                      {
                        ackBanners?.map((banner) => (
                          (banner.title === "refer and earn") ? 
                            (
                              <button
                                key={banner.name}
                                type="button"
                                className='r__card_wrapper_btn'
                                onClick={() => {
                                  ctEvents.logBannerClicked(
                                    {
                                      Page: 'Order Confirmation',
                                      Banner: banner.title,
                                      Section: banner.type,
                                      Source: 'Web'
                                    },
                                  );
                                  setshowReferAndEarn(true)
                                }}
                              >
                                <QuickCard
                                  name={banner.title}
                                  image={banner.image}
                                  cardStyle={
                                    {
                                      width: (isMobile) ? '320px' : '478px',
                                      height: (isMobile) ? '180px' : '269px',
                                      overflow: 'hidden',
                                      borderRadius: '16px',
                                    }
                                  }
                                />
                              </button>
                            ) : null
                        ))
                      }
                    </section>
                  ) : null
                }

                {
                  (ackBanners?.length > 0) ?
                    (<section 
                      className='r__section r__container_rest'
                    >
                      <CustomScroller
                        noMargin
                        content={(
                          <div className={`r__flex ${(!isMobile && ackBanners.length < 4) ? 'r__flex-28 justify-content-center' : 'r__flex-10'}`}>
                            {ackBanners?.map((banner) => (
                              (banner.title !== "refer and earn") ? 
                                (
                                  <button
                                    key={banner.name}
                                    type="button"
                                    className='r__card_wrapper_btn'
                                    onClick={() => {
                                      ctEvents.logBannerClicked(
                                        {
                                          Page: 'Order Confirmation',
                                          Banner: banner.title,
                                          Section: banner.type,
                                          Source: 'Web'
                                        },
                                      );
                                      (banner.videoLink) && handleSetBrandVideoLink(banner);
                                      (banner.videoLink) && handleToggleVideoModal();
                                    }}
                                  >
                                    <QuickCard
                                      name={banner.title}
                                      image={banner.image}
                                      link= {(!banner.videoLink) ? banner.deepLink.web : null}
                                      cardStyle={
                                        {
                                          width: (isMobile) ? '320px' : '478px',
                                          height: (isMobile) ? '180px' : '269px',
                                          overflow: 'hidden',
                                          borderRadius: '16px',
                                        }
                                      }
                                    />
                                    {
                                      (banner.videoLink) ?
                                        (<div className='r__play_button'>
                                          <Svg
                                            svg="playNew"
                                            width="30px"
                                            fill="#fff"
                                          />
                                        </div>
                                        ) : null
                                    }
                                </button>
                                ) : null
                            ))}
                          </div>
                        )}
                        contentPrefix="featured"
                      />
                    </section>) : null
                }

                {
                  (brandSpaces?.featured.length > 0) ?
                    (
                      <section className="r__section r__container_rest">
                        <CustomScroller
                          noMargin
                          content={(
                            <div className={`r__flex ${(!isMobile && brandSpaces?.featured.length < 4) ? 'r__flex-28 justify-content-center' : 'r__flex-10'}`}>
                              {brandSpaces?.featured?.map((brand) => (
                                <button
                                  key={brand.brandId}
                                  type="button"
                                  className='r__button_clean'
                                  onClick={() => {
                                    ctEvents.logBrandBannerClicked(
                                      {
                                        Page: 'Order Confirmation',
                                        Banner: brand.name,
                                        Section: 'Featured',
                                        Source: 'Web'
                                      }
                                    )
                                    history.push(`/brands/${brand.name.toLowerCase()}-${brand.brandId}`)
                                  }}
                                >
                                  <QuickCard
                                    name={brand.name}
                                    image={brand.image}
                                    cardStyle={
                                      {
                                        width: '350px',
                                        height: '222px',
                                        overflow: 'hidden',
                                        borderRadius: '16px',
                                      }
                                    }
                                  />
                                </button>
                              ))}
                            </div>
                          )}
                          contentPrefix="ackFeatured"
                        />
                      </section>
                    ) : null
                }
              </div>

              {/* Footer Start */}
              <div className="d-md-none bg-black mt-2">
                <FooterMobile {...props} />
                <div className="p-6" />
              </div>

              <div className="d-none d-md-block bg-black mt-4">
                <Footer {...props} />
              </div>
              {/* Footer End */}
            </>
          ) : null
      }
    </>
  );
};

Success.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        isFirstLoad: PropTypes.bool,
      }),
    }),
  }).isRequired,
  language: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string,
    }),
  }).isRequired,
};

export default Success;
