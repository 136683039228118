import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  width, height, isCircleTick, circleTickColor, pathFill,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 29"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 9.5h13v4.085c.156-.055.325-.085.5-.085h2.586a1.5 1.5 0 0 1 1.06.44l3.415 3.414a1.5 1.5 0 0 1 .439 1.06V21a1.5 1.5 0 0 1-1.5 1.5h-.55a2.5 2.5 0 0 1-4.9 0h-6.1a2.5 2.5 0 0 1-4.9 0H1.5v-13zm3.05 12a2.5 2.5 0 0 1 4.9 0h4.05v-11h-11v11h2.05zm9.95 0h1.05a2.5 2.5 0 0 1 4.9 0H21a.5.5 0 0 0 .5-.5v-2.586a.5.5 0 0 0-.146-.353l-3.415-3.415a.5.5 0 0 0-.353-.146H15a.5.5 0 0 0-.5.5v6.5zM4.5 13a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm2 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H7a.5.5 0 0 1-.5-.5zm2 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H9a.5.5 0 0 1-.5-.5zM7 20.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
      fill={pathFill}
    />
    {
      !!isCircleTick && (
        <>
          <circle
            cx="23"
            cy="6"
            r="6"
            fill="#fff"
          />
          <circle
            cx="23"
            cy="6"
            r="6"
            fill={circleTickColor}
            fillOpacity=".6"
          />
          <circle
            cx="23"
            cy="6"
            r="5.5"
            stroke="#000"
            strokeOpacity=".32"
          />
          <path
            d="m20 6.046 2 2.204 4-4.5"
            stroke="#fff"
            strokeWidth="2"
          />
        </>
      )
    }
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  isCircleTick: PropTypes.bool.isRequired,
  pathFill: PropTypes.string.isRequired,
  circleTickColor: PropTypes.string.isRequired,
};

export default Svg;
