import React from 'react';
import PropTypes from 'prop-types';

const SupportIcon = ({
  width, height, fill, stroke,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke={stroke}
      strokeWidth={2}
      d="M13 1C6.51 1 1 5.706 1 11.8c0 3.574 1.92 6.696 4.8 8.642V23.8a1.2 1.2 0 0 0 1.92.96l3.114-2.336c.703.116 1.427.176 2.166.176 6.49 0 12-4.706 12-10.8S19.49 1 13 1Z"
    />
    <circle cx={8.5} cy={11.5} r={1.5} fill={fill} />
    <circle cx={13.5} cy={11.5} r={1.5} fill={fill} />
    <circle cx={18.5} cy={11.5} r={1.5} fill={fill} />
  </svg>
);

SupportIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

SupportIcon.defaultProps = {
  width: 26,
  height: 26,
  fill: '#fff',
  stroke: '#fff',
};

export default SupportIcon;
