import React from 'react';
import PropTypes from 'prop-types';

const UpArrow = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill="#B8238E"
      fillRule="evenodd"
      d="M16.566 14.566a.8.8 0 0 0 0-1.132l-4-4a.8.8 0 0 0-1.132 0l-4 4a.8.8 0 1 0 1.132 1.132L12 11.13l3.434 3.435a.8.8 0 0 0 1.132 0Z"
      clipRule="evenodd"
    />
  </svg>
);

UpArrow.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default UpArrow;
