import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import StoreCardsLayout from './StoreCardsLayout';
import CategoryCard from '../individual-layout/CategoryCard';

const ShopsTabShimmer = () => (
  <Container>
    <Row className="align-items-center">
      <Col xs={6} className="mt-6 border-right d-none d-md-block">
        <CategoryCard />
        <CategoryCard />
        <CategoryCard />
        <CategoryCard />
        <CategoryCard />
        <CategoryCard />
        <CategoryCard />
        <CategoryCard />
        <CategoryCard />
      </Col>
      <Col xs={24} md={18} className="mt-6 px-0 border-left">
        <StoreCardsLayout />
      </Col>
    </Row>
  </Container>
);

export default ShopsTabShimmer;
