import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import {
  cartAddCouponRequest, cartCouponDeleteActions,
} from 'app/store/cart/actions';
import offerApis from 'api/offer';
import CustomScroller from 'app/components/common/CustomScroller';
import { storeOfferLoadActions } from 'app/store/offer/actions';
import ApplicableValueOffers from 'app/layout/checkout/ApplicableValueOffers';
import CouponCard from 'app/components/derived/offer/CouponCard';
import SpendOfferCard from 'app/components/derived/offer/SpendOfferCard';
import { logValueOfferSeeAllClicked } from 'clevertap/LogEvent';
import * as ActionTypes from '../../store/ActionTypes';
import * as Actions from '../../store/Actions';
import { Constants } from '../../utilities';
import * as screens from '../../events/screens';
import * as buttonActions from '../../events/buttonActions';
import {
  Placeholder, Svg, CustomModal,
} from '../../components/common';
import Reward from '../../components/derived/offer/Reward';
import { logPageLoad, logButtonClick } from '../../events/Events';
import { isGuestUser } from '../../utilities/Utils';
import LoginAlert from '../../layout/guest-user/LoginAlert';
import RewardList from './RewardList';
import CouponList from './CouponList';
import Cart from '../cart/Cart';

const { CancelToken } = Axios;

class RewardCoupon extends React.Component {
  constructor(props) {
    super(props);
    const { cartDetails } = props;
    let cartStoreId = '';
    if (cartDetails && cartDetails.store) {
      cartStoreId = cartDetails.store.id;
    }
    this.state = {
      couponCode: '',
      offerApplied: null,
      couponError: '',
      rewardError: '',
      coupons: null,
      couponReqStatus: '',
      cartStoreId,
      selectedOfferType: 'All',
    };
    this.source = CancelToken.source();
  }

  componentDidMount() {
    logPageLoad({
      SCREEN: screens.REWARD_COUPONS,
    });
    this.handleLoad();
  }

  componentDidUpdate(prevProps) {
    const {
      failure, processing, rewards, rewardLoadRequest,
    } = this.props;
    const {
      rewards: prevRewards,
    } = prevProps;
    const { cartStoreId } = this.state;
    let prevRewardsCount = -1;
    if (prevRewards) {
      prevRewardsCount = prevRewards.count;
    }
    const {
      [ActionTypes.REWARD_LOAD_REQUEST]: processingReward,
    } = processing;
    const {
      [ActionTypes.REWARD_LOAD_REQUEST]: failureReward,
    } = failure;
    if (!failureReward && !processingReward && !rewards) {
      rewardLoadRequest(
        {
          storeId: cartStoreId,
        },
        this.source.token,
      );
    }
    if (
      rewards
      && rewards.count !== prevRewardsCount
    ) {
      logPageLoad(
        {
          SCREEN: screens.REWARD_COUPONS,
          SCRATCH_CARD_COUNT: rewards.count,
        },
      );
    }
  }

  componentWillUnmount = () => {
    const { resetRequestStatus } = this.props;
    this.source.cancel();
    resetRequestStatus([
      ActionTypes.REWARD_LOAD_REQUEST,
      ActionTypes.COUPON_CODE_REQUEST,
      ActionTypes.APPLY_REWARD_REQUEST,
    ]);
  }

  handleLoad = () => {
    const {
      cartDetails, rewardLoadRequest, cartId,
      storeOffersLoadReq,
    } = this.props;
    const { cartStoreId } = this.state;
    this.setState({
      couponReqStatus: 'loading',
    });
    offerApis.cartCoupons(cartDetails.store.id, cartId).then((res) => {
      const coupons = res.data;
      this.setState({
        coupons,
        couponReqStatus: '',
      });
    }).catch(() => {
      this.setState({
        couponReqStatus: 'error',
      });
    });
    rewardLoadRequest({
      storeId: cartStoreId,
    }, this.source.token);
    storeOffersLoadReq(
      cartStoreId,
      null,
      null,
      {
        cartId,
      },
    );
  }

  componentWillUnmount = () => {
    const { resetRequestStatus } = this.props;
    this.source.cancel();
    resetRequestStatus([
      ActionTypes.COUPON_CODE_REQUEST,
      ActionTypes.APPLY_REWARD_REQUEST,
    ]);
  }

  handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    this.setState({
      couponCode: value.toUpperCase(),
    });
  }

  handleCouponCode = (couponCode, apply = true) => {
    const { couponCodeRequest, language } = this.props;
    couponCodeRequest(
      couponCode,
      'NORMAL',
      apply,
      {
        type: 'sync',
        callback: (res) => {
          const discountValue = res.res.appliedCoupon?.campaign?.discountValue || '';
          const code = res.res.appliedCoupon?.code || '';
          this.setState({
            offerApplied: {
              type: 'coupon',
              feedbackMsgTitle: `Saved ₹${discountValue}`,
              feedbackMsgDesc: `You got a ₹${discountValue} by using coupon code ${code}.`,
            },
          });
        },
      },
      {
        type: 'sync',
        callback: (data) => {
          this.setState({
            offerApplied: {
              type: 'coupon',
              feedbackMsgTitle: 'Sorry, can’t apply Coupon',
              feedbackMsgDesc: (data && data.errorMsg) || Constants.String.OOPS[language],
            },
            rewardError: (
              (data && data.errorMsg)
              || Constants.String.OOPS[language]
            ),
          });
        },
      },
    );
  }

  useReward = (reward, apply = true) => {
    const { couponCodeRequest, language } = this.props;
    couponCodeRequest(
      reward.coupon.couponCode,
      'SCRATCH_CARD',
      apply,
      {
        type: 'sync',
        callback: (res) => {
          const discountValue = res.res.appliedCoupon?.campaign?.discountValue || '';
          const code = res.res.appliedCoupon?.code || '';
          this.setState({
            offerApplied: {
              type: 'reward',
              feedbackMsgTitle: `Saved ₹${discountValue}`,
              feedbackMsgDesc: `You got a ₹${discountValue} by using coupon code ${code}.`,
            },
          });
        },
      },
      {
        type: 'sync',
        callback: (data) => {
          this.setState({
            offerApplied: {
              type: 'reward',
              feedbackMsgTitle: 'Sorry, can’t apply Reward',
              feedbackMsgDesc: (data && data.errorMsg) || Constants.String.OOPS[language],
            },
            rewardError: (
              (data && data.errorMsg)
              || Constants.String.OOPS[language]
            ),
          });
        },
      },
    );
  }

  handleOfferTypeChange = (offerType) => {
    this.setState({
      selectedOfferType: offerType,
    });
  }

  render() {
    const {
      language, processing, toggleSideOverlay,
      isMobile, history, failure,
      rewards, storeOffers, cartDetails,
    } = this.props;
    const {
      couponCode, offerApplied, couponError, rewardError,
      couponReqStatus, coupons, selectedOfferType,
    } = this.state;

    // const { applyOffer } = (
    //   (location && location.state) || page === 'cart' ? { applyOffer: true } : {}
    // );

    const applyOffer = true;

    const {
      [ActionTypes.COUPON_CODE_REQUEST]: processingCouponCode,
      [ActionTypes.APPLY_REWARD_REQUEST]: processingRewardUse,
      [ActionTypes.REWARD_LOAD_REQUEST]: processingReward,
    } = processing;

    const {
      [ActionTypes.REWARD_LOAD_REQUEST]: failureReward,
    } = failure;

    const processingOffer = processingCouponCode || processingRewardUse;
    const offerFailure = couponError || rewardError;

    const spendAndValueOffers = storeOffers ? storeOffers.filter(
      (item) => (item.offerType === 'VALUE_OFFER' || item.offerType === 'SPEND_OFFER'),
    ) : [];

    if (
      (!coupons && couponReqStatus === 'loading')
      || (!rewards && processingReward)
      || processingOffer
    ) {
      return (
        <Row
          className="w-100 py-3 mx-0 mt-3 justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </Row>
      );
    }

    if (
      (!coupons && couponReqStatus === 'error')
      && (!rewards && failureReward)
    ) {
      return (
        <>
          <Placeholder
            language={language}
            handleRetry={this.handleLoad}
            imageSrc="/images/error-placeholder.png"
            heading={Constants.String.OH_NO[language]}
          />
        </>
      );
    }

    if (
      (!coupons || (coupons && coupons.count === 0))
      && (!rewards || (rewards && rewards.count === 0))
      && (!spendAndValueOffers || (spendAndValueOffers && spendAndValueOffers.length === 0))
    ) {
      return (
        <>
          <Placeholder
            language={language}
            imageSrc="/images/no-offers.png"
            heading={Constants.String.NO_OFFERS_FOUND[language]}
            subHeading={Constants.String.NO_OFFERS_SUBHEADING[language]}
          />
        </>
      );
    }

    if (isGuestUser()) {
      return (
        <LoginAlert
          language={language}
          screen={screens.REWARD_COUPONS}
        />
      );
    }

    return (
      <Container
        fluid
        id="cart"
        className={`d-flex flex-column h-100 ${isMobile ? 'my-3' : ''}`}
      >
        {offerApplied && (
          <CustomModal
            show
            backdrop
            body={(
              <Container>
                <Row
                  className="bg-white py-6 px-4 flex-column r__action_container"
                >
                  <Col className="mb-4 r__action_title">
                    {(offerFailure ? (
                      <Svg
                        svg="circleClose"
                        circleFill={Constants.Color.danger}
                        pathFill={Constants.Color.white}
                        width="2rem"
                      />
                    ) : (
                      <Svg
                        svg="graphicOffer"
                        pathFill={Constants.Color.green}
                        circleFill={Constants.Color.white}
                        width="48px"
                      />
                    ))}
                  </Col>
                  <Col
                    className="mb-4 r__action_title"
                  >
                    {offerApplied.feedbackMsgTitle}
                  </Col>
                  <Col
                    className="mb-4 r__action_copy"
                  >
                    {offerApplied.feedbackMsgDesc}
                  </Col>
                  <Col
                    className="d-flex justify-content-center"
                  >
                    <Button
                      variant="link"
                      className="r__action_confirm_one"
                      onClick={() => {
                        this.setState({
                          offerApplied: null,
                          couponError: '',
                          rewardError: '',
                        });
                        if (!offerFailure) {
                          if (!isMobile) {
                            toggleSideOverlay(Cart);
                            return;
                          }
                          history.push('/cart', { fromRewardCoupon: true });
                        }
                      }}
                    >
                      { offerFailure ? 'OK' : Constants.String.YAY[language].toUpperCase()}
                    </Button>
                  </Col>
                </Row>
              </Container>
            )}
            onHide={() => {
              this.setState({
                offerApplied: null,
                couponError: '',
                rewardError: '',
              });
              if (!offerFailure) {
                if (!isMobile) {
                  toggleSideOverlay(Cart);
                  return;
                }
                history.push('/checkout', { fromRewardCoupon: true });
              }
            }}
          />
        )}
        {!isMobile && (
          <>
            <Row>
              <Col xs={24} className="py-4 align-items-center bg-white d-flex">
                <Button
                  variant="link"
                  className="py-0 pl-0"
                  onClick={() => {
                    toggleSideOverlay(Cart);
                  }}
                >
                  <Svg
                    svg="leftArrow"
                    width="1rem"
                    fill={Constants.Color.black}
                  />
                </Button>
                <div
                  className="pl-2 font-weight-black flex-grow-1"
                >
                  {Constants.String.APPLY_COUPON[language]}
                </div>
                <Col
                  xs="auto"
                  className="cursor-pointer px-0"
                  onClick={() => {
                    toggleSideOverlay(null);
                  }}
                >
                  <Svg
                    svg="close"
                    width="24px"
                    fill={Constants.Color.mediumLight}
                  />
                </Col>
              </Col>
            </Row>
            <Row
              className="py-1 bg-light"
            />
          </>
        )}
        <Row className="h-100 overflow-y-scroll" style={{ display: 'block' }}>
          {applyOffer && (
          <Col
            xs={24}
            className="bg-white mb-2 px-0"
          >
            <Container>
              <Row
                className="py-6"
              >
                <Col
                  xs={24}
                  className="pl-2 pr-2"
                >
                  <div
                    className="d-flex align-items-center border border-radius-16 overflow-hidden"
                  >
                    <input
                      type="text"
                      placeholder={Constants.String.ENTER_COUPON_CODE[language]}
                      value={couponCode}
                      onChange={this.handleChange}
                      className="border-0 text-black-50 fs-6 flex-grow-1 shadow-none p-3"
                    />
                    <Button
                      variant="link"
                      className="fs-6 font-weight-black"
                      onClick={() => {
                        if (couponCode) {
                          logButtonClick(
                            {
                              BUTTON: buttonActions.APPLY_COUPON,
                              SCREEN: screens.REWARD_COUPONS,
                              COUPON_CODE: couponCode,
                            },
                          );
                          this.handleCouponCode(couponCode);
                        }
                      }}
                    >
                      {Constants.String.APPLY[language]}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={24}
                  className="d-flex gap-10 overflow-x-scroll pb-2"
                  style={{
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {[
                    'All',
                    ...spendAndValueOffers && spendAndValueOffers.length > 0 ? ['Shop Offers'] : [],
                    ...rewards && rewards.count > 0 ? ['Scratch Cards'] : [],
                    ...coupons && coupons.count > 0 ? ['Coupons'] : [],
                  ].map((item) => (
                    <Button
                      id={item}
                      onClick={() => this.handleOfferTypeChange(item)}
                      variant="link"
                      className={`fs-6 border border-radius-16 shadow-sm ${
                        item === selectedOfferType
                          ? 'border-primary text-primary ' : 'border text-medium'
                      }`}
                    >
                      {item}
                    </Button>
                  ))}
                </Col>
              </Row>
            </Container>
          </Col>
          )}

          {((!coupons && couponReqStatus === 'loading')
            || (!rewards && processingReward)
            || processingOffer
          ) && (
            <Row
              className="w-100 py-3 mx-0 mt-3 justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </Row>
          )}

          {(!coupons && couponReqStatus === 'error'
          && !rewards && failureReward
          ) && (
            <>
              <Placeholder
                language={language}
                handleRetry={this.handleLoad}
                imageSrc="/images/error-placeholder.png"
                heading={Constants.String.OH_NO[language]}
              />
            </>
          )}

          {(coupons && coupons.count === 0 && couponReqStatus !== 'loading' && couponReqStatus !== 'error')
            && (rewards && rewards.count === 0 && !failureReward && !processingReward)
            && spendAndValueOffers && spendAndValueOffers.length === 0
            && (
              <Col xs={24}>
                <Placeholder
                  language={language}
                  imageSrc="/images/no-offers.png"
                  heading={Constants.String.NO_OFFERS_FOUND[language]}
                  subHeading={Constants.String.NO_OFFERS_SUBHEADING[language]}
                />
              </Col>
            )}

          {/* Spend Offer Section */}
          {
            (selectedOfferType === 'All' || selectedOfferType === 'Shop Offers')
            && (spendAndValueOffers && spendAndValueOffers.length > 0)
            && (
              <Col
                xs={24}
                className="pt-4"
              >
                <Row
                  className="align-items-center"
                >
                  <Col
                    className="d-flex align-items-center"
                  >
                    <span
                      className="fs-5 font-weight-black"
                    >
                      Shop Offers
                    </span>
                  </Col>
                </Row>
                <Row
                  className="py-3 mx-0"
                >
                  <CustomScroller
                    contentPrefix="spend-value-offers-cart"
                    noMargin
                    content={(
                      <div
                        className="d-flex gap-10"
                      >
                        {spendAndValueOffers.slice(0, 3).map((offerItem) => (
                          <SpendOfferCard
                            {...this.props}
                            key={offerItem.offerType}
                            item={{
                              ...offerItem,
                              image: offerItem.images[0],
                              offerType: `SPECIAL_${offerItem.offerType}`,
                              isApplicable: offerItem.isValid,
                            }}
                            isCheckoutView
                            isMobile={isMobile}
                            spendOfferInCart={cartDetails.cartOffers ? cartDetails.cartOffers[0] : null}
                          />
                        ))}
                        {
                        spendAndValueOffers.length > 3 && (
                          <Button
                            className="fs-4 text-wrap text-left p-4 w-100 border-primary border-radius-16 bg-white text-primary font-weight-bold"
                            onClick={() => {
                              logValueOfferSeeAllClicked(
                                { Page: 'Checkout' },
                              );
                              if (isMobile) {
                                history.push(
                                  '/checkout/applicable-value-offers',
                                  { applicableOffers: spendAndValueOffers },
                                );
                              } else if (toggleSideOverlay) {
                                toggleSideOverlay(() => (
                                  <ApplicableValueOffers
                                    {...this.props}
                                    applicableOffers={spendAndValueOffers}
                                    backTo="REWARD_COUPON"
                                  />
                                ), false);
                              }
                            }}
                          >
                            See all
                            <br />
                            Offers
                            <br />
                            <Svg
                              svg="circleArrow"
                              width="24px"
                              circleFill={Constants.Color.primary}
                              pathFill={Constants.Color.white}
                            />
                          </Button>
                        )
                      }
                      </div>
                  )}
                  />
                </Row>
              </Col>
            )
          }

          {
            (selectedOfferType === 'All' || selectedOfferType === 'Scratch Cards')
            && (!!rewards && rewards.count > 0 && !failureReward)
            && (
              <Col
                xs={24}
                className="bg-white pt-4"
              >
                <Row
                  className="align-items-center justify-content-between mx-0"
                >
                  <Col
                    className="px-0"
                  >
                    <span
                      className="font-weight-bold fs-4"
                    >
                      {Constants.String.LOVELOCAL_REWARDS[language]}
                    </span>
                    &nbsp;
                    <span
                      className="text-medium fs-7"
                    >
                      {`(${rewards.count})`}
                    </span>
                  </Col>
                </Row>
                <Row
                  xs={24}
                  className="py-1"
                >
                  <CustomScroller
                    contentPrefix="reward-coupons-cart"
                    noMargin
                    content={(
                      <div
                        className="d-flex px-2"
                      >
                        {rewards.data.slice(0, 6).map((item) => (
                          <Row className="mx-0">
                            <Reward
                              {...this.props}
                              key={item.id}
                              item={item}
                              isApplyCoupon
                              isOfferView
                              applyReward={
                                applyOffer
                                  ? this.useReward
                                  : null
                              }
                              screen={screens.REWARD_COUPONS}
                            />
                          </Row>
                        ))}
                        {
                          rewards.count > 5 && (
                            <Col className="pr-2 pl-0 see-all-reward">
                              <Button
                                type="button"
                                className="fs-4 text-wrap text-left p-4 m-2 border-primary border-radius-16 bg-white text-primary font-weight-bold h-100 w-100"
                                onClick={() => {
                                  if (!isMobile) {
                                    if (toggleSideOverlay) {
                                      toggleSideOverlay(RewardList, false);
                                    }
                                    return;
                                  }
                                  history.push('/offers/rewards');
                                }}
                              >
                                See all
                                Scratch&nbsp;Cards
                                <br />
                                <Svg
                                  svg="circleArrow"
                                  width="24px"
                                  circleFill={Constants.Color.primary}
                                  pathFill={Constants.Color.white}
                                />
                              </Button>
                            </Col>
                          )
                        }
                      </div>
                    )}
                  />
                </Row>
              </Col>
            )
          }

          {
            (selectedOfferType === 'All' || selectedOfferType === 'Coupons')
            && (!!coupons && coupons.count > 0)
            && (
              <Col
                xs={24}
                className="bg-white mb-2 pt-4"
              >
                <Row
                  className="align-items-center justify-content-between mx-0"
                >
                  <Col
                    className="font-weight-bold fs-4 px-0"
                  >
                    {Constants.String.LOVELOCAL_COUPONS[language]}
                  </Col>
                </Row>
                <Row
                  className="py-3 mx-0"
                >
                  <CustomScroller
                    contentPrefix="ll-coupons"
                    noMargin
                    content={(
                      <div
                        className="d-flex gap-10"
                      >
                        {coupons.data.slice(0, 3).map((item) => (
                          <CouponCard
                            {...this.props}
                            key={item.id}
                            item={item}
                            isApplyCoupon
                            applyCoupon={
                              applyOffer
                                ? this.handleCouponCode
                                : null
                            }
                          />
                        ))}
                        {
                          coupons.data.length > 3 && (
                            <Button
                              className="fs-4 text-wrap text-left p-4 w-100 border-primary border-radius-16 bg-white text-primary font-weight-bold"
                              onClick={() => {
                                if (!isMobile) {
                                  if (toggleSideOverlay) {
                                    toggleSideOverlay(() => (
                                      <CouponList
                                        {...this.props}
                                        isApplyCoupon
                                      />
                                    ), false);
                                  }
                                  return;
                                }
                                history.push('/offers/coupons');
                              }}
                            >
                              See all
                              <br />
                              Coupons
                              <br />
                              <Svg
                                svg="circleArrow"
                                width="24px"
                                circleFill={Constants.Color.primary}
                                pathFill={Constants.Color.white}
                              />
                            </Button>
                          )
                        }
                      </div>
                    )}
                  />
                </Row>
              </Col>
            )
          }

        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  processing: state.main.requestsProcessing,
  failure: state.main.requestsFailure,
  cartId: state.main.cartId,
  cartDetails: state.main.cartDetails,
  rewards: state.main.rewards,
  storeOffers: state.main.storeOffers,
});

const mapDispatchToProps = (dispatch) => ({
  resetRequestStatus: (requests) => {
    dispatch(Actions.resetRequestStatus(requests));
  },
  couponCodeRequest: (
    couponCode,
    type,
    apply,
    onSuccess,
    onFailure,
  ) => {
    const { addRequest } = cartAddCouponRequest;
    const { deleteRequest } = cartCouponDeleteActions;
    const requestMethod = apply ? addRequest : deleteRequest;
    dispatch(requestMethod(
      onSuccess,
      onFailure,
      {
        couponCode,
        type,
      },
      {},
    ));
  },
  rewardLoadRequest: (params, cancelToken) => {
    dispatch(Actions.rewardLoadRequest(
      params, cancelToken,
    ));
  },
  storeOffersLoadReq: (
    storeCode,
    onSuccess,
    onFailure,
    params = {},
  ) => {
    dispatch(
      storeOfferLoadActions.loadRequest(
        onSuccess,
        onFailure,
        storeCode,
        params,
      ),
    );
  },
});

RewardCoupon.propTypes = {
  language: PropTypes.string.isRequired,
  processing: PropTypes.shape({}).isRequired,
  failure: PropTypes.shape({}).isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  rewardLoadRequest: PropTypes.func.isRequired,
  rewards: PropTypes.shape({
    count: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  cartDetails: PropTypes.shape({
    store: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      applyOffer: PropTypes.bool,
    }),
  }).isRequired,
  page: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  couponCodeRequest: PropTypes.shape({}).isRequired,
  isTablet: PropTypes.bool.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  cartId: PropTypes.number.isRequired,
  storeOffersLoadReq: PropTypes.func.isRequired,
  storeOffers: PropTypes.arrayOf({}).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardCoupon);
