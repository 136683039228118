import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 3.75c4.97 0 9 3.358 9 7.5 0 4.142-4.03 7.5-9 7.5-.412 0-.817-.023-1.214-.068L5.25 20.25l1.495-2.91C4.477 15.977 3 13.757 3 11.25c0-4.142 4.03-7.5 9-7.5z"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
