import React from 'react';
import PropTypes from 'prop-types';

const OrderAccepted = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <circle cx={12} cy={12} r={12} fill="#005317" />
    <path stroke="#fff" strokeWidth={3} d="M6 11.556 10.286 16 18 8" />
  </svg>
);

OrderAccepted.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default OrderAccepted;
