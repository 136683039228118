import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ stroke, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 64 64"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        stroke={stroke}
      >
        <g>
          <g>
            <g transform="translate(-928 -732) translate(0 552) translate(928 180)">
              <circle
                cx="32"
                cy="32"
                r="24"
              />
            </g>
            <g>
              <path d="M20 8l-8 8m8 8l-8-8" transform="translate(-928 -732) translate(0 552) translate(928 180) translate(16 16) matrix(-1 0 0 1 32 0)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
