import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 26 26"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M12.025 14.967v7.811h-5.85a1.953 1.953 0 0 1-1.95-1.953v-5.858h7.8zm9.75 0v5.858a1.953 1.953 0 0 1-1.95 1.953h-5.85v-7.811h7.8zM7.031 4.108c1.106-1.106 3.032-1.106 4.136-.002 1.596 1.6 1.812 4.608 1.831 4.946.001.02-.008.037-.008.056h.02c0-.019-.01-.036-.009-.056.02-.338.235-3.346 1.831-4.945 1.106-1.106 3.03-1.105 4.135 0a2.938 2.938 0 0 1 .001 4.143 4.864 4.864 0 0 1-1.205.858H20.8c1.076 0 1.95.876 1.95 1.953v2.929h-8.775v-3.906h-1.95v3.906H3.25v-2.93c0-1.076.875-1.952 1.95-1.952h3.036a4.882 4.882 0 0 1-1.205-.858 2.936 2.936 0 0 1 0-4.142zm9.87 1.095a.97.97 0 0 0-.691.286c-.575.576-.91 1.61-1.09 2.472.86-.178 1.893-.513 2.469-1.091.38-.381.38-1.001 0-1.383a.97.97 0 0 0-.689-.284zm-7.802 0a.967.967 0 0 0-.688.285.98.98 0 0 0 0 1.382c.571.572 1.61.91 2.469 1.09-.18-.862-.515-1.896-1.09-2.472a.971.971 0 0 0-.69-.285z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
