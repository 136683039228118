import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, FormCheck,
} from 'react-bootstrap';
import { Constants } from '../../utilities';
import { Svg } from '../../components/common';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as buttonActions from '../../events/buttonActions';
import * as screens from '../../events/screens';
import { logButtonClick } from '../../events/Events';

const { String, Color } = Constants;

class Addresses extends React.Component {
  isFirstLoad = true;

  componentDidUpdate = () => {
    const { addresses } = this.props;
    if (addresses.length && this.isFirstLoad) {
      const currentAddress = addresses.find((address) => address.isDefault);
      LogClevertapEvent.addressSelectionPage(
        (currentAddress && currentAddress.addressText) || '',
      );
      this.isFirstLoad = false;
    }
  }

  render() {
    const {
      history,
      isMobile,
      language,
      addresses,
      notifyAction,
      notifyView,
    } = this.props;

    return (
      <Container>
        <Row className={`sticky-top py-4 pr-0${isMobile
          ? ' bg-light' : ' bg-white'}`}
        >
          <Col xs={24}>
            <Button
              variant="link"
              className="w-100 text-left border border-primary bg-white"
              onClick={() => {
                if (isMobile) {
                  history.push('/add-location');
                } else {
                  notifyView('add-location');
                }
                logButtonClick(
                  {
                    BUTTON: buttonActions.ADD_NEW_ADDRESS,
                    SCREEN: screens.MANAGE_LOCATION,
                  },
                );
                LogClevertapEvent.newLocationAddButton();
              }}
            >
              <span>
                <Svg
                  svg="add"
                  fill={Color.primary}
                  width="0.95rem"
                />
              </span>
              <span className="ml-2 fs-5">
                {String.ADD_LOCATION[language]}
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
          {
            addresses.map((item, index) => (
              <Col
                xs={24}
                className="mb-3"
                key={item.locationId}
              >
                <Container
                  className="bg-white shadow-sm rounded"
                >
                  <Row
                    className="py-3"
                  >
                    <Col
                      xs={24}
                    >
                      <FormCheck
                        custom
                        type="radio"
                        id={`custom-radio-${item.locationId}`}
                        label={(
                          <b
                            className={`fs-4 ${
                              item.isDefault
                                ? 'text-primary' : 'text-dark'
                            }`}
                          >
                            {item.addressType}
                            <span className={`ml-1${item.isDefault ? '' : ' d-none'}`}>
                              {`(${String.DEFAULT[language]})`}
                            </span>
                          </b>
                        )}
                        checked={item.isDefault}
                        className={item.isDefault ? 'pointer-event-none' : ''}
                        onChange={() => {
                          notifyAction({ ...item }, 'change');
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="pb-4">
                    <Col xs={24} className="fs-5">{item.addressText}</Col>
                  </Row>
                  <Row className="border-top py-1">
                    <Col>
                      <Button
                        variant="link"
                        className="w-100 fs-5"
                        onClick={() => {
                          notifyView('edit-address');
                          notifyAction({ ...item });
                          logButtonClick(
                            {
                              BUTTON: buttonActions.EDIT_ADDRESS,
                              SCREEN: screens.MANAGE_LOCATION,
                              ADDRESS_ID: item.locationId,
                            },
                          );
                        }}
                      >
                        {String.EDIT[language]}
                      </Button>
                    </Col>
                    <Col
                      xs="auto"
                      className="border-left p-0 my-1"
                    />
                    <Col>
                      <Button
                        variant="link"
                        className="w-100 fs-5"
                        disabled={(addresses.length === 1 && index === 0) || item.isDefault}
                        onClick={() => {
                          notifyAction({ ...item }, 'delete');
                          logButtonClick(
                            {
                              BUTTON: buttonActions.DELETE_ADDRESS,
                              SCREEN: screens.MANAGE_LOCATION,
                              ADDRESS_ID: item.locationId,
                            },
                          );
                        }}
                      >
                        {String.DELETE[language]}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))
          }
        </Row>
      </Container>
    );
  }
}

Addresses.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.shape({
    addressText: PropTypes.string,
  })),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  notifyAction: PropTypes.func.isRequired,
  notifyView: PropTypes.func.isRequired,
};

Addresses.defaultProps = {
  addresses: [],
};

export default Addresses;
