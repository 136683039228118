import * as React from 'react';

const FreeProductAtive = (props) => (
  <svg
    width={30}
    height={29}
    viewBox="0 0 30 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.68421 10H18.6316V23.3158L16.9474 25H6V11.6842L7.68421 10Z"
      fill="url(#paint0_linear_1856_36846)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.9771 9.29289C7.16464 9.10536 7.41899 9 7.68421 9H18.6316C19.1839 9 19.6316 9.44772 19.6316 10V23.3158C19.6316 23.581 19.5262 23.8354 19.3387 24.0229L17.6545 25.7071C17.4669 25.8946 17.2126 26 16.9474 26H6C5.44772 26 5 25.5523 5 25V11.6842C5 11.419 5.10536 11.1646 5.29289 10.9771L6.9771 9.29289ZM7.68421 10L6 11.6842V25H16.9474L18.6316 23.3158V10H7.68421Z"
      fill="black"
    />
    <path d="M6 12H16.9474V25H6V12Z" fill="url(#paint1_linear_1856_36846)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12C5 11.4477 5.44772 11 6 11H16.9474C17.4997 11 17.9474 11.4477 17.9474 12V25C17.9474 25.5523 17.4997 26 16.9474 26H6C5.44772 26 5 25.5523 5 25V12ZM6 12V25H16.9474V12H6Z"
      fill="black"
    />
    <path
      d="M14 18.5C14 20.433 12.8807 22 11.5 22C10.1193 22 9 20.433 9 18.5C9 16.567 10.1193 15 11.5 15C12.8807 15 14 16.567 14 18.5Z"
      fill="url(#paint2_linear_1856_36846)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0815 21.5561C13.4961 22.3757 12.5971 23 11.5 23C10.4029 23 9.50394 22.3757 8.9185 21.5561C8.33169 20.7346 8 19.6509 8 18.5C8 17.3491 8.33169 16.2654 8.9185 15.4439C9.50394 14.6243 10.4029 14 11.5 14C12.5971 14 13.4961 14.6243 14.0815 15.4439C14.6683 16.2654 15 17.3491 15 18.5C15 19.6509 14.6683 20.7346 14.0815 21.5561ZM11.5 22C12.8807 22 14 20.433 14 18.5C14 16.567 12.8807 15 11.5 15C10.1193 15 9 16.567 9 18.5C9 20.433 10.1193 22 11.5 22Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0378 10.3536L17.3536 12.0378L16.6465 11.3307L18.3307 9.64645L19.0378 10.3536Z"
      fill="black"
    />
    <circle cx={22.334} cy={7} r={6.5} fill="#B8238E" stroke="#7D005A" />
    <path
      d="M18.834 7.05357L21.1673 9.625L25.834 4.375"
      stroke="white"
      strokeWidth={2}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1856_36846"
        x1={6}
        y1={25}
        x2={24.2889}
        y2={19.1013}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9C132" />
        <stop offset={1} stopColor="#F9ED32" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1856_36846"
        x1={6}
        y1={25}
        x2={21.8504}
        y2={19.8878}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9C132" />
        <stop offset={1} stopColor="#F9ED32" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1856_36846"
        x1={9}
        y1={22}
        x2={16.4359}
        y2={19.9657}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9C132" />
        <stop offset={1} stopColor="#F9ED32" />
      </linearGradient>
    </defs>
  </svg>
);
export default FreeProductAtive;
