import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { previousOrderProducts } from 'api/shop-front';
import { Placeholder, Svg } from 'app/components/common';
import ProductThumb from 'app/components/common/ProductThumb';
import { Constants } from 'app/utilities';
import { SHOP_FRONT } from 'app/events/screens';

function RepeatProductsSeeAll({
  storeId: storeIdFromProp, match, isMobile,
  changeStoreState, language, storeName,
}) {
  const { CancelToken } = Axios;
  const source = CancelToken.source();
  const cartItems = useSelector((state) => state.main.cartItems);
  const selectedAddress = useSelector((state) => state.main.selectedAddress);
  const [productsList, setProductsList] = useState(null);
  const [loadStatus, setLoadStatus] = useState('');

  let storeId = storeIdFromProp;
  if (!storeId) {
    const { storeCode } = match.params;
    storeId = storeCode;
  }

  const loadProducts = () => {
    if (storeId) {
      setLoadStatus('loading');
      previousOrderProducts(
        storeId,
        source.token,
        {
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        },
      ).then((res) => {
        const { data } = res;
        if (data.count === 0) {
          setLoadStatus('zero_results');
        } else {
          setProductsList(data);
          setLoadStatus('');
        }
      }).catch(() => {
        setLoadStatus('error');
      });
    }
  };

  useEffect(() => (() => {
    source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    changeStoreState('ALL');
  };

  return (
    <div className="bg-white px-4 px-md-0 py-4 py-md-0">
      {
        (() => {
          if (loadStatus === 'loading') {
            return (
              <div
                className="r__loader"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          }
          if (loadStatus === 'error') {
            return (
              <div className="r__loader">
                <Placeholder
                  language={language}
                  handleRetry={loadProducts}
                  imageSrc="/images/error-placeholder.png"
                  heading={Constants.String.OH_NO[language]}
                />
              </div>
            );
          }
          if (loadStatus === 'zero_results') {
            return (
              <Placeholder
                language={language}
                imageSrc="/images/no-results.png"
                heading={Constants.String.NO_RESULTS_FOUND[language]}
              />
            );
          }
          return (
            productsList && productsList.count > 0 ? (
              <>
                <div
                  className="d-flex justify-content-space-between pb-5"
                >
                  <div className="d-flex fs-3 font-weight-black">
                    {
                      !isMobile && (
                        <>
                          <Button
                            variant="link"
                            onClick={handleBack}
                            className="p-0 d-flex align-items-center"
                          >
                            <Svg
                              svg="leftArrow"
                              fill={Constants.Color.black}
                              width="1rem"
                            />
                          </Button>
                          &nbsp;&nbsp;
                        </>
                      )
                    }
                    {Constants.String.WANT_TO_REPEAT[language]}
                  </div>
                  <div className="">
                    {`${productsList.count} Items`}
                  </div>
                </div>
                <div className="r__flex r__flex-20 pb-2 flex-wrap">
                  {productsList.data.map((product) => (
                    <ProductThumb
                      key={product.libraryProductId}
                      cartItems={cartItems}
                      libraryProductId={product.libraryProductId}
                      retailProductId={product.id}
                      name={product.name}
                      image={product.image}
                      mrp={product.mrp}
                      sellingPrice={product.sellingPrice}
                      measurementDisplayText={product.measurementDisplayText}
                      storeName={storeName}
                      storeId={storeId}
                      offerPercent={product.offerPercent}
                      previouslyBought={product.previouslyBought}
                      inStock={product.inStock}
                      horizontalView={isMobile}
                      page={SHOP_FRONT}
                      section="Want To Repeat See All"
                    />
                  ))}
                </div>
              </>
            ) : null
          );
        })()
      }
    </div>
  );
}

RepeatProductsSeeAll.propTypes = {
  storeId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      storeCode: PropTypes.string,
    }),
  }).isRequired,
  changeStoreState: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
};

export default RepeatProductsSeeAll;
