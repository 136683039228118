import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomModal, Svg } from 'app/components/common';
import CustomScroller from 'app/components/common/CustomScroller';
import QuickCard from 'app/components/common/QuickCard';
import { logBannerClicked } from 'app/events/Events';
import ReferFriendInfo from 'app/layout/refer-friends/ReferFriendInfo';
import { Constants } from 'app/utilities';

function BottomBanners({
  isMobile, ackBanners, page, language, toggleSideOverlay,
}) {
  const [showBrandVideoModal, setShowBrandVideoModal] = useState(false);
  const [brandVideoLink, setBrandVideoLink] = useState('');
  const [showReferAndEarn, setshowReferAndEarn] = useState(false);

  const handleSetBrandVideoLink = (banner) => {
    setBrandVideoLink(`${banner.deepLink.web}?autoplay=1`);
  };

  const handleToggleVideoModal = () => {
    setShowBrandVideoModal(!showBrandVideoModal);
  };

  const brandVideoFrame = (
    <div className="r__brandVideoFrame">
      <iframe
        title="YouTube video player"
        width="992"
        height="558"
        src={brandVideoLink}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        autoPlay
      />
    </div>
  );

  return (
    <CustomScroller
      noMargin
      content={(
        <div className="r__flex r__flex-10">
          <CustomModal
            show={showReferAndEarn}
            title={Constants.String.REFER_AND_EARN[language]}
            closeButton
            onHide={() => setshowReferAndEarn(false)}
            body={(
              <ReferFriendInfo
                isMobile={isMobile}
                language={language}
                toggleSideOverlay={toggleSideOverlay}
                page={page}
                onHide={() => { setshowReferAndEarn(false); }}
              />
            )}
          />
          <CustomModal
            show={showBrandVideoModal}
            title=" "
            size="lg"
            body={brandVideoFrame}
            centered
            closeButton
            backdrop
            onHide={() => handleToggleVideoModal()}
          />
          {ackBanners?.map((banner) => (
            <button
              type="button"
              className="r__card_wrapper_btn"
              onClick={() => {
                logBannerClicked(
                  {
                    Page: page,
                    Banner: banner.title,
                    BannerType: banner.type,
                    Section: 'Shop Front - Bottom Banner',
                  },
                );
                if (banner.title === 'refer and earn') {
                  setshowReferAndEarn(true);
                  return;
                }
                if (banner.videoLink) {
                  handleSetBrandVideoLink(banner);
                  handleToggleVideoModal();
                }
              }}
            >
              <QuickCard
                name={banner.title}
                image={banner.image}
                link={(!banner.videoLink && banner.title !== 'refer and earn') ? banner.deepLink.web : null}
                cardStyle={
                  {
                    width: (isMobile) ? '320px' : '478px',
                    height: (isMobile) ? '180px' : '269px',
                    overflow: 'hidden',
                    borderRadius: '16px',
                  }
                }
              />
              {
                (banner.videoLink)
                  ? (
                    <div className="r__play_button">
                      <Svg
                        svg="playNew"
                        width="30px"
                        fill="#fff"
                      />
                    </div>
                  ) : null
              }
            </button>
          ))}
        </div>
      )}
      contentPrefix="featured"
    />
  );
}

BottomBanners.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  ackBanners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
};

export default BottomBanners;
