import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
// import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { storeProducts } from 'api/shop-front';
import { Placeholder, Svg } from 'app/components/common';
import ProductThumb from 'app/components/common/ProductThumb';
import { Constants } from 'app/utilities';
import '../../StoreDetails.scss';
import { SHOP_FRONT } from 'app/events/screens';
import { logCTEvent } from 'app/events/Events';
import Events from 'clevertap/Event';
import CategoryProductsSuggestions from './CategoryProductsSuggestions';
import { recommendedProducts } from 'api/api';

function CategoryProductsSeeAll({
  storeId: storeIdFromProp, match, isMobile,
  changeStoreState, selectedCategory: selectedCategoryFromProp,
  history, language, storeName,
}) {
  const { CancelToken } = Axios;
  const source = CancelToken.source();
  const cartItems = useSelector((state) => state.main.cartItems);
  const [productsList, setProductsList] = useState(null);
  const [loadStatus, setLoadStatus] = useState('');
  const [subCategories, setSubCategories] = useState(null);

  const [suggestedProductDetails, setSuggestedProductDetails] = useState({
    loading: false,
    failure: false,
    retailerProductId: null,
    data: null,
  });

  const getSuggestedProductsData = (
    retailerProductId,
  ) => {
    setSuggestedProductDetails({
      retailerProductId,
      loading: true,
      failure: false,
      data: null,
    });
    recommendedProducts(
      'SHOPFRONT',
      retailerProductId,
      '',
    ).then((res) => {
      window.scrollBy(0, 100);
      setSuggestedProductDetails({
        retailerProductId,
        loading: false,
        failure: false,
        data: res.data,
      });
    }).catch(() => {
      setSuggestedProductDetails({
        retailerProductId: null,
        loading: false,
        failure: true,
        data: null,
      });
    });
  }

  let storeId = storeIdFromProp;
  if (!storeId) {
    const { storeCode } = match.params;
    storeId = storeCode;
  }

  let selectedCategory = selectedCategoryFromProp;
  if (!selectedCategory) {
    const { productCategory: selectedCategoryFromState } = history.location.state;
    if (selectedCategoryFromState) {
      selectedCategory = selectedCategoryFromState;
    }
  }

  const loadProducts = (selectedSubCatId = '') => {
    if (storeId && selectedCategory) {
      setLoadStatus('loading');
      let params = {};
      params = {
        categoryId: selectedCategory.id,
        ...selectedSubCatId > 0 ? { subCategoryId: selectedSubCatId } : {},
      };
      storeProducts(
        storeId,
        source.token,
        params,
      ).then((res) => {
        const { data } = res.data;
        const { subCategory, productsWithMeasurements } = data;
        const subCat = subCategory;
        if (subCat.length > 0 && subCat.findIndex((i) => i.isSelected) === -1) {
          subCat[0].isSelected = true;
        }
        setSubCategories(subCat);
        if (productsWithMeasurements.length === 0) {
          setLoadStatus('zero_results');
        } else {
          setProductsList(productsWithMeasurements);
          setLoadStatus('');
        }
      }).catch(() => {
        setLoadStatus('error');
      });
    }
  };

  useEffect(() => () => {
    source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const handleBack = () => {
    changeStoreState('ALL');
  };

  return (
    <div className={`bg-white ${isMobile ? 'h-100' : ''}`}>
      {
        !isMobile ? (
          <div
            className="d-flex justify-content-space-between p-4 p-md-6"
            style={{
              ...selectedCategory.topColor && selectedCategory.bottomColor
                ? { backgroundImage: `linear-gradient(to bottom, ${selectedCategory.topColor} 0%, ${selectedCategory.bottomColor} 100%)` }
                : {},
            }}
          >
            <div
              className="d-flex fs-3 font-weight-black"
            >
              <Button
                variant="link"
                onClick={handleBack}
                className="p-0 d-flex align-items-center"
              >
                <Svg
                  svg="leftArrow"
                  fill={Constants.Color.black}
                  width="1rem"
                />
              </Button>
                &nbsp;&nbsp;
              {selectedCategory.name || ''}
            </div>
            {
                productsList ? (
                  <div className="">
                    {`${productsList.length} Items`}
                  </div>
                ) : null
              }
          </div>
        ) : null
      }
      {
        (() => {
          if (loadStatus === 'loading') {
            return (
              <div
                className="r__loader"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          }
          if (loadStatus === 'error') {
            return (
              <div className="r__loader">
                <Placeholder
                  language={language}
                  handleRetry={loadProducts}
                  imageSrc="/images/error-placeholder.png"
                  heading={Constants.String.OH_NO[language]}
                />
              </div>
            );
          }
          return (
            <>
              <div className="mb-5 px-4 px-md-6">
                {
                  subCategories && subCategories.length > 0 ? (
                    <div className="d-flex r__flex-10 py-4" style={{ overflowX: 'scroll' }}>
                      {
                        subCategories.map((subcat) => (
                          <Button
                            key={subcat.id}
                            variant={subcat.isSelected ? 'primary' : 'outline-primary'}
                            onClick={() => {
                              loadProducts(subcat.id);
                              logCTEvent(
                                Events.Sub_Category_clicked,
                                {
                                  'EP Code': storeId,
                                  'Store Name': storeName,
                                  Page: SHOP_FRONT,
                                  'Category Id': selectedCategory.id,
                                  'Sub Category Id': subcat.id,
                                  'Sub Category Name': subcat.name,
                                },
                              );
                            }}
                            className={`category-button ${!subcat.isSelected ? 'disabled' : 'enabled'}`}
                          >
                            {subcat.name}
                          </Button>
                        ))
                      }
                    </div>
                  ) : null
                }
              </div>
              <div
                className="r__flex r__flex-20 pb-2 flex-wrap px-4 px-md-6"
              >
                {
                  loadStatus === 'zero_results' ? (
                    <Placeholder
                      language={language}
                      imageSrc="/images/no-results.png"
                      heading={Constants.String.NO_RESULTS_FOUND[language]}
                    />
                  ) : (
                    <>
                      {(productsList || []).map((product) => (
                        <>
                          <ProductThumb
                            history={history}
                            key={product[0].libraryProductId}
                            libraryProductId={product[0].libraryProductId}
                            retailProductId={product[0].retailerProductId}
                            name={product[0].name}
                            image={product[0].image}
                            mrp={product[0].mrp}
                            sellingPrice={product[0].sellingPrice}
                            measurementDisplayText={product[0].measurementDisplayText}
                            storeName={storeName}
                            storeId={storeId}
                            offerPercent={product[0].offerPercent}
                            previouslyBought={product[0].previouslyBought}
                            inStock={product[0].inStock}
                            cartItems={cartItems}
                            horizontalView={isMobile}
                            variants={product.map((i) => ({
                              libraryProductId: i.libraryProductId,
                              retailProductId: i.retailerProductId,
                              name: i.name,
                              image: i.image,
                              mrp: i.mrp,
                              sellingPrice: i.sellingPrice,
                              measurementDisplayText: i.measurementDisplayText,
                              offerPercent: i.offerPercent,
                            }))}
                            page={SHOP_FRONT}
                            section={`Category see all - ${selectedCategory.name}`}
                            getRetailerProductId={(retailerProductId) => {
                              getSuggestedProductsData(retailerProductId);
                              // console.log('retailerProductId -->', retailerProductId);
                            }}
                          />
                          {
                            !!(
                              product[0].retailerProductId === suggestedProductDetails.retailerProductId
                              && isMobile
                              && suggestedProductDetails?.data?.count
                            ) && (
                              <CategoryProductsSuggestions
                                suggestedProductDetails={suggestedProductDetails}
                                cartItems={cartItems}
                              />
                            )
                          }
                        </>
                      ))}
                    </>
                  )
                }
              </div>
            </>
          );
        })()
      }
    </div>
  );
}

CategoryProductsSeeAll.propTypes = {
  storeId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      storeCode: PropTypes.string,
      shopURL: PropTypes.string,
    }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  changeStoreState: PropTypes.func.isRequired,
  selectedCategory: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        productCategory: PropTypes.shape({}),
      }),
    }),
  }).isRequired,
  language: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
};

export default CategoryProductsSeeAll;
