import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={width} height={height} rx="8" fill="#FFF3E0" />
    <g opacity=".55" stroke="#EB7D00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
      <path d="M10.667 17.333h26.666L35.13 33.862a4 4 0 0 1-3.965 3.471h-14.33a4 4 0 0 1-3.964-3.471l-2.204-16.529z" />
      <path d="M30.667 17.333a6.667 6.667 0 0 0-13.334 0" />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="m16.784 32.53 6.334-11.876a1 1 0 0 1 1.764 0l6.334 11.875A1 1 0 0 1 30.333 34H17.667a1 1 0 0 1-.883-1.47zm8.019-5.398v-3.146h-1.595v3.146c0 .338.02.673.06 1.007.04.33.094.667.16 1.012h1.155c.066-.345.12-.682.16-1.012.04-.334.06-.67.06-1.006zm-1.71 3.636a.943.943 0 0 0-.072.369c0 .131.024.256.072.373.05.114.119.213.203.297.088.085.19.15.308.198.117.048.246.072.385.072a.998.998 0 0 0 .688-.27.876.876 0 0 0 .203-.296.923.923 0 0 0 0-.743.876.876 0 0 0-.203-.297.967.967 0 0 0-.308-.198.937.937 0 0 0-.38-.077.95.95 0 0 0-.385.077.967.967 0 0 0-.308.198.994.994 0 0 0-.203.297z" fill="#EB7D00" />
  </svg>
);

Svg.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

Svg.defaultProps = {
  width: '48',
  height: '48',
};

export default Svg;
