import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { getSeoShopUrl } from 'app/utilities/Utils';
import { Constants, Utils } from '../../utilities';
import { Svg } from '../../components/common';

function SocialShareOverlay({ language, isMobile, storeDetails }) {
  const [copied, setCopied] = useState(false);

  const { shopUrl } = getSeoShopUrl(
    storeDetails.country || 'india',
    storeDetails.city || 'mumbai',
    storeDetails.locality || 'andheri',
    storeDetails.storeType?.name || storeDetails.storeType?.displayName || '',
    storeDetails.name || storeDetails.displayName || '',
    storeDetails.storeId || storeDetails.code || '',
  );

  const getShareShopText = (shopName, shopURL) => (`
    Find ${shopName || 'Shop'} on LoveLocal. You can get ₹50 OFF on your first order. 
    Use Code: LOVELOCAL 
    Visit shop: ${shopURL} 
    Download LoveLocal App: https://lovelocal.page.link/lovelocal-daily-needs 
  `);

  const socialShareMedia = [
    {
      mediaName: Constants.String.WHATSAPP[language],
      mediaIconJsx: (
        <WhatsappShareButton
          url="https://www.lovelocal.in"
          title={storeDetails ? getShareShopText(storeDetails.name || storeDetails.displayName, `https://lovelocal.in${shopUrl}`) : ''}
        >
          <Svg
            svg="socialShareWhatsapp"
            width="73px"
          />
        </WhatsappShareButton>
      ),
    },
    {
      mediaName: Constants.String.FACEBOOK[language],
      mediaIconJsx: (
        <FacebookShareButton
          url="https://www.lovelocal.in"
          quote={storeDetails ? getShareShopText(storeDetails.name || storeDetails.displayName, `https://lovelocal.in${shopUrl}`) : ''}
        >
          <Svg
            svg="socialShareFacebook"
            width="73px"
          />
        </FacebookShareButton>
      ),
    },
    {
      mediaName: Constants.String.TWITTER[language],
      mediaIconJsx: (
        <TwitterShareButton
          url="https://www.lovelocal.in"
          title={storeDetails ? getShareShopText(storeDetails.name || storeDetails.displayName, `https://lovelocal.in${shopUrl}`) : ''}
        >
          <Svg
            svg="socialShareTwitter"
            width="73px"
            fillOuter="#55acee"
            fillInner="#f1f2f2"
          />
        </TwitterShareButton>
      ),
    },
    {
      mediaName: Constants.String.COPY_LINK[language],
      mediaIconJsx: (
        <Button
          onClick={() => {
            Utils.copyToClipboard(storeDetails ? getShareShopText(storeDetails.name || storeDetails.displayName, `https://lovelocal.in${shopUrl}`) : '');
            setCopied(true);
          }}
          variant="link"
          className="p-0"
        >
          <Svg
            svg="socialShareCopy"
            width="73px"
            fill={Constants.Color.pinkPale}
            stroke={Constants.Color.primary}
          />
        </Button>
      ),
    },
  ];

  return (
    <Container className="cont">
      {
        isMobile && (
          <Row className="p-4 border-bottom fs-3 font-weight-black">
            {Constants.String.SHARE_OPTIONS[language]}
          </Row>
        )
      }
      <Row className={`${isMobile ? 'py-6' : 'py-4'} px-0 mx-0`}>
        {
          socialShareMedia.map((item) => (
            <Col xs={6} key={item.mediaName} className="d-flex align-items-center justify-content-center flex-column">
              <Row className="mx-0">
                {item.mediaIconJsx}
              </Row>
              <Row className="mx-0 text-center py-2">
                {item.mediaName}
              </Row>
            </Col>
          ))
        }
      </Row>
      {
        copied && (
          <Row className="fs-5 mx-0 pb-6">
            <Col xs={24} className="p-2 bg-pink-pale text-primary">
              <Svg
                svg="copy"
                width="1.2rem"
                fill={Constants.Color.primary}
              />
              <b>{Constants.String.SOCIAL_SHARE_COPIED_DESC1[language]}</b>
              {Constants.String.SOCIAL_SHARE_COPIED_DESC2[language]}
            </Col>
            <Col xs={24} className="p-2 bg-gray-lighter">
              {getShareShopText(storeDetails.name || storeDetails.displayName, `https://lovelocal.in${shopUrl}`)}
            </Col>
          </Row>
        )
      }
    </Container>
  );
}

SocialShareOverlay.propTypes = {
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  storeDetails: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    locality: PropTypes.string,
    storeId: PropTypes.number,
    code: PropTypes.string,
    storeType: PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
    }),
  }).isRequired,
};

export default SocialShareOverlay;
