import React, { Component } from 'react';
import {
  Form, Row, Col, Container, Spinner,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Constants, validateForm } from '../../../utilities';
import * as Actions from '../../../store/Actions';
import * as ActionTypes from '../../../store/ActionTypes';

const { CancelToken } = Axios;

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      email: '',
      errors: {},
    };
    this.source = CancelToken.source();
  }

  componentWillUnmount = () => {
    const { resetRequestStatus } = this.props;
    this.source.cancel();
    resetRequestStatus([
      ActionTypes.PROFILE_EDIT_REQUEST,
    ]);
  }

  handleChange = (field, value) => {
    const { errors } = this.state;
    delete errors[field];
    this.setState({ [field]: value });
  }

  updateProfile = () => {
    const { profileEditRequest, onSuccess } = this.props;
    const {
      firstName,
      email,
      errors,
    } = this.state;
    const newErrors = validateForm(errors, { firstName, email });
    if (Object.keys(newErrors).length) {
      this.setState({ errors: newErrors });
      return;
    }
    const data = new FormData();
    if (firstName) {
      data.set('first_name', firstName);
    }
    if (email) {
      data.set('email', email);
    }
    profileEditRequest(
      data,
      onSuccess,
      this.source.token,
    );
  }


  render() {
    const {
      language, requestsFailure, requestsProcessing,
    } = this.props;
    const {
      firstName, email, errors,
    } = this.state;
    const loader = requestsProcessing[ActionTypes.PROFILE_EDIT_REQUEST];
    const failure = requestsFailure[ActionTypes.PROFILE_EDIT_REQUEST];
    return (
      <Container>
        <Row>
          <Col
            xs={24}
          >
            <Form.Group
              controlId="firstName"
              className="mb-6"
            >
              <Form.Label
                className="form-label fs-5"
              >
                {Constants.String.YOUR_NAME[language]}
              </Form.Label>
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => {
                  this.handleChange('firstName', e.target.value);
                }}
                isInvalid={errors.firstName}
                autoComplete="off"
                spellCheck={false}
                autoCorrect="off"
              />
              {
                errors.firstName
                  ? (
                    <Form.Control.Feedback
                      type="invalid"
                    >
                      {errors.firstName[language]}
                    </Form.Control.Feedback>
                  )
                  : ''
                  }
            </Form.Group>
          </Col>
          <Col
            xs={24}
          >
            <Form.Group
              controlId="email"
              className="mb-6"
            >
              <Form.Label
                className="form-label fs-5"
              >
                {`${Constants.String.EMAIL_ADDRESS[language]} (${Constants.String.OPTIONAL[language]})`}
              </Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={(e) => {
                  this.handleChange('email', e.target.value);
                }}
                isInvalid={errors.email}
                autoComplete="off"
                spellCheck={false}
                autoCorrect="off"
              />
              {
                errors.email
                  ? (
                    <Form.Control.Feedback
                      type="invalid"
                    >
                      {errors.email[language]}
                    </Form.Control.Feedback>
                  )
                  : ''
                  }
            </Form.Group>
          </Col>
          {
            !!firstName && (
              <Col
                xs={24}
                className="pb-4"
              >
                {
                  loader
                    ? (
                      <div
                        className="text-center"
                      >
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    ) : (
                      <Button
                        className="p-3 w-100 fs-3"
                        onClick={() => {
                          this.updateProfile();
                        }}
                        block
                      >
                        {Constants.String.SAVE[language].toUpperCase()}
                      </Button>
                    )
                }
              </Col>
            )
          }
          {
            failure
              ? (
                <Col
                  xs={24}
                  className="fs-5 mb-4 text-danger"
                >
                  {Constants.String.OOPS[language]}
                </Col>
              ) : ''
          }
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  requestsProcessing: state.main.requestsProcessing,
  requestsFailure: state.main.requestsFailure,
});

const mapDispatchToProps = (dispatch) => ({
  profileEditRequest:
  (profile, onSuccess, cancelToken) => {
    dispatch(Actions.profileEditRequest(profile, onSuccess, cancelToken));
  },
  resetRequestStatus:
  (requests) => dispatch(Actions.resetRequestStatus(requests)),
});

CustomerDetails.propTypes = {
  language: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  profileEditRequest: PropTypes.func.isRequired,
  requestsProcessing: PropTypes.shape({}).isRequired,
  requestsFailure: PropTypes.shape({}).isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
