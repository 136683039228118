import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { getPlatFormFeeSubText } from 'app/utilities/Storage';
import { Constants } from '../../utilities';

const PlatformFeeBreakup = (props) => {
  const {
    language, platformFeeDetails, toggleDialog,
  } = props;
  const { gst, finalValue } = platformFeeDetails;
  const platformFeeInfoText = getPlatFormFeeSubText();
  return (
    <Container
      className="r__action_container r__action_container--mid"
    >
      <Row>
        <Col
          xs={24}
          className="mb-4 fs-3 r__action_title"
        >
          <b>
            {Constants.String.PLATFORM_FEE_BREAKUP[language]}
          </b>
        </Col>
        <Col
          xs={24}
          className={platformFeeInfoText ? 'text-center mb-4' : 'd-none'}
        >
          {platformFeeInfoText}
        </Col>
        <Col
          xs={24}
          className="mb-6"
        >
          {
            finalValue >= 0 && (
            <Row
              className="align-items-center justify-content-between"
            >
              <Col
                className="fs-5"
              >
                {Constants.String.PLATFORM_FEE[language]}
              </Col>
              <Col
                xs="auto"
                className="fs-5 text-green font-weight-bold"
              >
                {`\u20B9 ${finalValue}`}
              </Col>
            </Row>
            )
          }
          {
            gst >= 0 && (
            <Row
              className="align-items-center justify-content-between mt-2"
            >
              <Col
                className="fs-5"
              >
                {Constants.String.GST[language]}
              </Col>
              <Col
                xs="auto"
                className="fs-5 text-green font-weight-bold"
              >
                {`\u20B9 ${gst}`}
              </Col>
            </Row>
            )
          }
        </Col>
        <Col
          xs={24}
          className="d-flex justify-content-end"
        >
          <Button
            variant="link"
            className="w-100 py-3 r__action_confirm_one"
            onClick={toggleDialog}
          >
            {Constants.String.OKAY[language].toUpperCase()}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

PlatformFeeBreakup.propTypes = {
  language: PropTypes.string.isRequired,
  platformFeeDetails: PropTypes.shape({
    gst: PropTypes.number.isRequired,
    finalValueAndGst: PropTypes.number.isRequired,
    finalValue: PropTypes.number.isRequired,
  }).isRequired,
  toggleDialog: PropTypes.func.isRequired,
};

export default PlatformFeeBreakup;
