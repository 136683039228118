import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <rect width={width} height={height} fill="#B8238E" fillOpacity={0.15} rx={8} />
    <path
      stroke="#B8238E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m24.063 24.063 3.75 3.75M19.375 25.313a5.937 5.937 0 1 0 0-11.875 5.937 5.937 0 0 0 0 11.874Z"
    />
  </svg>
);

SearchIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default SearchIcon;
