import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl } = apiUrls();
const acknowledgementApis = {
  ackBanners: (cancelToken, params = {}) => {
    const acknowledgement = `${newOOBaseUrl}customer/home/banners/brand`;
    return Axios({
      url: acknowledgement,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    });
  },
};
export default acknowledgementApis;
