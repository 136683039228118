// Cart Load Actions
export const CART_LOAD_REQUEST = 'CART_LOAD_REQUEST';
export const CART_LOAD_SUCCESS = 'CART_LOAD_SUCCESS';
export const CART_LOAD_FAILURE = 'CART_LOAD_FAILURE';

export const cartLoadActions = {
  loadRequest: (
    onSuccess, onFailure, params,
  ) => ({
    type: CART_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
    },
  }),
  loadSuccess: (data) => ({
    type: CART_LOAD_SUCCESS,
    payload: {
      cartDetails: data.res,
    },
  }),
  loadFailure: () => ({
    type: CART_LOAD_FAILURE,
  }),
};

// Cart Bulk add Product
export const CART_BULK_ADD_LOAD_REQUEST = 'CART_BULK_ADD_LOAD_REQUEST';
export const CART_BULK_ADD_LOAD_SUCCESS = 'CART_BULK_ADD_LOAD_SUCCESS';
export const CART_BULK_ADD_LOAD_FAILURE = 'CART_BULK_ADD_LOAD_FAILURE';

export const cartBulkAddActions = {
  addRequest: (
    onSuccess, onFailure, params, body,
  ) => ({
    type: CART_BULK_ADD_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
      body,
    },
  }),
  addSuccess: (data) => ({
    type: CART_BULK_ADD_LOAD_SUCCESS,
    payload: {
      cartDetails: data.res,
    },
  }),
  addFailure: () => ({
    type: CART_BULK_ADD_LOAD_FAILURE,
  }),
};

// Cart Product actions
export const CART_PRODUCT_REQUEST = 'CART_PRODUCT_REQUEST';
export const CART_PRODUCT_SUCCESS = 'CART_PRODUCT_SUCCESS';
export const CART_PRODUCT_FAILURE = 'CART_PRODUCT_FAILURE';

export const cartProductActions = {

  // Cart Product Action
  request: (
    storeId, productId, quantity, onSuccess, onFailure, productInfo = {},
    forceAdd = false, actionType = '', isCustomProduct = false,
  ) => ({
    type: CART_PRODUCT_REQUEST,
    payload: {
      data: {
        product: {
          storeId,
          productId,
          quantity,
          productInfo,
        },
        forceAdd,
        actionType,
        isCustomProduct,
      },
      onSuccess,
      onFailure,
    },
  }),
  success: (data) => ({
    type: CART_PRODUCT_SUCCESS,
    payload: {
      product: data.product,
      cartDetails: data.res,
      forceAdd: data.forceAdd,
    },
  }),
  failure: (data) => ({
    type: CART_PRODUCT_FAILURE,
    payload: {
      productId: data.productId,
      multiStoreCheckoutErrorMsg: data.errorMsg,
      product: data.product,
      productInfo: data.productInfo,
    },
  }),
};

// Cart Delete Request
export const CART_DELETE_REQUEST = 'CART_DELETE_REQUEST';
export const CART_DELETE_SUCCESS = 'CART_DELETE_SUCCESS';
export const CART_DELETE_FAILURE = 'CART_DELETE_FAILURE';

export const cartDeleteActions = {
  deleteRequest: (
    onSuccess, onFailure, params,
  ) => ({
    type: CART_DELETE_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
    },
  }),
  deleteSuccess: (data) => ({
    type: CART_DELETE_SUCCESS,
    payload: {
      cartDetails: data.res,
    },
  }),
  deleteFailure: () => ({
    type: CART_DELETE_FAILURE,
  }),
};

export const CART_ADD_COUPONS_REQUEST = 'CART_ADD_COUPONS_REQUEST';
export const CART_ADD_COUPONS_SUCCESS = 'CART_ADD_COUPONS_SUCCESS';
export const CART_ADD_COUPONS_FAILURE = 'CART_ADD_COUPONS_FAILURE';

export const cartAddCouponRequest = {
  // Cart Coupon Add requests
  addRequest: (
    onSuccess, onFailure, body, params,
  ) => ({
    type: CART_ADD_COUPONS_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
      body,
    },
  }),
  addSuccess: (data) => ({
    type: CART_ADD_COUPONS_SUCCESS,
    payload: {
      cartDetails: data.res,
    },
  }),
  addFailure: () => ({
    type: CART_ADD_COUPONS_FAILURE,
  }),
};

export const CART_DELETE_COUPON_REQUEST = 'CART_DELETE_COUPON_REQUEST';
export const CART_DELETE_COUPON_SUCCESS = 'CART_DELETE_COUPON_SUCCESS';
export const CART_DELETE_COUPON_FAILURE = 'CART_DELETE_COUPON_FAILURE';

export const cartCouponDeleteActions = {
  deleteRequest: (
    onSuccess, onFailure, params,
  ) => ({
    type: CART_DELETE_COUPON_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
    },
  }),
  deleteSuccess: (data) => ({
    type: CART_DELETE_COUPON_SUCCESS,
    payload: {
      cartDetails: data.res,
    },
  }),
  deleteFailure: () => ({
    type: CART_DELETE_COUPON_FAILURE,
  }),
};

export const CART_ADD_OFFER_REQUEST = 'CART_ADD_OFFER_REQUEST';
export const CART_ADD_OFFER_SUCCESS = 'CART_ADD_OFFER_SUCCESS';
export const CART_ADD_OFFER_FAILURE = 'CART_ADD_OFFER_FAILURE';

export const cartAddOfferRequest = {
  // Cart offer Add requests
  addRequest: (
    onSuccess, onFailure, body, params,
  ) => ({
    type: CART_ADD_OFFER_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
      body,
    },
  }),
  addSuccess: (data) => ({
    type: CART_ADD_OFFER_SUCCESS,
    payload: {
      cartDetails: data.res,
    },
  }),
  addFailure: () => ({
    type: CART_ADD_OFFER_FAILURE,
  }),
};

export const CART_DELETE_OFFER_REQUEST = 'CART_DELETE_OFFER_REQUEST';
export const CART_DELETE_OFFER_SUCCESS = 'CART_DELETE_OFFER_SUCCESS';
export const CART_DELETE_OFFER_FAILURE = 'CART_DELETE_OFFER_FAILURE';

export const cartDeleteOfferRequest = {
  // Cart offer Delete requests
  deleteRequest: (
    onSuccess, onFailure, body, params,
  ) => ({
    type: CART_DELETE_OFFER_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
      body,
    },
  }),
  deleteSuccess: (data) => ({
    type: CART_DELETE_OFFER_SUCCESS,
    payload: {
      cartDetails: data.res,
    },
  }),
  deleteFailure: () => ({
    type: CART_DELETE_OFFER_FAILURE,
  }),
};

export const CART_DELIVERY_UPDATE_REQUEST = 'CART_DELIVERY_UPDATE_REQUEST';
export const CART_DELIVERY_UPDATE_SUCCESS = 'CART_DELIVERY_UPDATE_SUCCESS';
export const CART_DELIVERY_UPDATE_FAILURE = 'CART_DELIVERY_UPDATE_FAILURE';

export const cartDeliveryUpdateRequest = {
// Delivery Update requests
  updateRequest: (
    onSuccess, onFailure, params, body,
  ) => ({
    type: CART_DELIVERY_UPDATE_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
      body,
    },
  }),
  updateSuccess: (data) => ({
    type: CART_DELIVERY_UPDATE_SUCCESS,
    payload: {
      cartDetails: data.res,
    },
  }),
  updateFailure: () => ({
    type: CART_DELIVERY_UPDATE_FAILURE,
  }),
};

export const CART_PAYMENT_UPDATE_REQUEST = 'CART_PAYMENT_UPDATE_REQUEST';
export const CART_PAYMENT_UPDATE_SUCCESS = 'CART_PAYMENT_UPDATE_SUCCESS';
export const CART_PAYMENT_UPDATE_FAILURE = 'CART_PAYMENT_UPDATE_FAILURE';

export const cartPaymentUpdateRequest = {
  // Payment Update requests
  updateRequest: (
    onSuccess, onFailure, body, params,
  ) => ({
    type: CART_PAYMENT_UPDATE_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
      body,
    },
  }),
  updateSuccess: (data) => ({
    type: CART_PAYMENT_UPDATE_SUCCESS,
    payload: {
      cartDetails: data.res,
    },
  }),
  updateFailure: () => ({
    type: CART_PAYMENT_UPDATE_FAILURE,
  }),
};

// Set cartItems
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const setCartItems = (
  cartItems,
) => ({
  type: SET_CART_ITEMS,
  payload: {
    cartItems,
  },
});

// Set Guest Custom Cart Items
export const SET_GUEST_CUSTOM_CART_ITEMS = 'SET_GUEST_CUSTOM_CART_ITEMS';
export const setGuestCustomCartItems = (
  guestCustomCartItems,
) => ({
  type: SET_GUEST_CUSTOM_CART_ITEMS,
  payload: {
    guestCustomCartItems,
  },
});

// Highlight Cart Offer requests
export const HIGHLIGHT_CART_OFFER_REQUEST = 'HIGHLIGHT_CART_OFFER_REQUEST';
export const HIGHLIGHT_CART_OFFER_SUCCESS = 'HIGHLIGHT_CART_OFFER_SUCCESS';
export const HIGHLIGHT_CART_OFFER_FAILURE = 'HIGHLIGHT_CART_OFFER_FAILURE';

export const highlightCartOfferRequest = {
  loadRequest: (
    onSuccess, onFailure, params,
  ) => ({
    type: HIGHLIGHT_CART_OFFER_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      params,
    },
  }),

  loadSuccess: (data) => ({
    type: HIGHLIGHT_CART_OFFER_SUCCESS,
    payload: {
      highlightCartOfferDetails: data.res,
    },
  }),

  loadFailure: () => ({
    type: HIGHLIGHT_CART_OFFER_FAILURE,
  }),
};
