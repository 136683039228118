const productCategories = [
  {
    categoryDetailDescription: '',
    categoryId: 'pharma_and_otc_products',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'hand_wash',
        categoryMetaData: '',
        children: [],
        displayName: 'Hand Wash',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7998,
        image: 'https://lovelocal-store-front.s3.ap-south-1.amazonaws.com/04232021112639533100_category_images.png',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_syrups',
        categoryMetaData: '',
        children: [],
        displayName: 'Syrups',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7963,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'hand_sanitizers',
        categoryMetaData: '',
        children: [],
        displayName: 'Hand Sanitizers',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7997,
        image: 'https://lovelocal-store-front.s3.ap-south-1.amazonaws.com/04232021112639533100_category_images.png',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'This products helps you to increase your physical state, mental state, and social well-being which has a connections with sexuality.Growing awareness regarding sexual wellness inspires a positive and respectful approach involving sexuality and sexual relationships.',
        categoryId: 'sexual_wellness',
        categoryMetaData: '',
        children: [],
        displayName: 'Sexual Wellness',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7901,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 115,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'basis_pharma',
        categoryMetaData: '',
        children: [],
        displayName: 'Basis Pharma',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8189,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 110,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'hand_wash_and_sanitizers',
        categoryMetaData: '',
        children: [],
        displayName: 'Hand Wash & Sanitizers',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8185,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 113,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_all_products',
        categoryMetaData: '',
        children: [],
        displayName: 'All OTC Products',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7964,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'digestive_care',
        categoryMetaData: '',
        children: [],
        displayName: 'Digestive Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8192,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'toothpastes_and_toothbrushes',
        categoryMetaData: '',
        children: [],
        displayName: 'Toothpastes & Toothbrushes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8183,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 114,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_devices',
        categoryMetaData: '',
        children: [],
        displayName: 'Devices',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7959,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'immunity_boosters',
        categoryMetaData: '',
        children: [],
        displayName: 'Immunity Boosters',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7996,
        image: 'https://lovelocal-store-front.s3.ap-south-1.amazonaws.com/04232021112639533100_category_images.png',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_pain_relief',
        categoryMetaData: '',
        children: [],
        displayName: 'Pain Relief',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7962,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'supplements_and_proteins',
        categoryMetaData: '',
        children: [],
        displayName: 'Supplements & Proteins',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8188,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 101,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'respiratory_masks',
        categoryMetaData: '',
        children: [],
        displayName: 'Respiratory Masks',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7999,
        image: 'https://lovelocal-store-front.s3.ap-south-1.amazonaws.com/04232021112639533100_category_images.png',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_chyawanprash',
        categoryMetaData: '',
        children: [],
        displayName: 'Chyawanprash & Health Foods',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7958,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'health_wellness',
        categoryMetaData: '',
        children: [],
        displayName: 'Health & Wellness',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7902,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: "Antiseptics creams are substances that help to stop the growth of microorganisms on the skin. They're used daily in medical settings to reduce the risk of infection and stop the spread of germs.",
        categoryId: 'antiseptic_liquid_cream',
        categoryMetaData: '',
        children: [],
        displayName: 'Antiseptic Liquid and Cream',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7946,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'all_medicines',
        categoryMetaData: '',
        children: [],
        displayName: 'All Medicines',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7984,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_vitamins_supplements',
        categoryMetaData: '',
        children: [],
        displayName: 'Nutrition, Vitamins & Supplements',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7954,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'disinfectants',
        categoryMetaData: '',
        children: [],
        displayName: 'Disinfectants',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7994,
        image: 'https://lovelocal-store-front.s3.ap-south-1.amazonaws.com/04232021112639533100_category_images.png',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'mask_and_gloves',
        categoryMetaData: '',
        children: [],
        displayName: 'Mask & Gloves',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8193,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'antiseptics_and_bandages',
        categoryMetaData: '',
        children: [],
        displayName: 'Antiseptics & Bandages',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8190,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 112,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_personal_care',
        categoryMetaData: [
          {
            paragraph1: 'Maintaining good <a href="https://www.lovelocal.in/pc/personal-care" target="_blank">personal care</a> is very important and it requires all the right products to ensure that the users’ beauty and hygiene needs are fulfilled. Personal care houses a wide range of products under it like <a href="https://www.lovelocal.in/pc/personal-care/skin-care" target="_blank">skincare products</a>, <a href="https://www.lovelocal.in/pc/personal-care/hair-care" target="_blank">hair care products</a>, <a href="https://www.lovelocal.in/pc/personal-care/health-wellness" target="_blank">wellness</a> products, <a href="https://www.lovelocal.in/pc/personal-care/fragrances-deos" target="_blank">deodorants</a>, <a href="https://www.lovelocal.in/search?q=handwash" target="_blank">hand wash</a>, shower gels, <a href="https://www.lovelocal.in/pc/personal-care/oral-care" target="_blank">oral care products</a>, <a href="https://www.lovelocal.in/pc/personal-care/feminine-hygiene" target="_blank">feminine hygiene products</a>, makeup and <a href="https://www.lovelocal.in/pc/personal-care/mens-grooming" target="_blank">men’s grooming products</a>. All these personal care items are things that everyone uses invariably in their daily lives.',
            question: ' \nBuy personal care and hygiene products online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: "Your local trusted retailers around you have stocked up all the products that you use the most – be it your favourite brand’s face wash, special shampoo for dry hair, sanitary napkins, toothbrush, toothpaste, dental floss, or more. Personal care cosmetic products has beauty products like Lakmé’s complexion care cream, <a href=\"https://www.lovelocal.in/products/lakme-eyeconic-kajal-deep-33964\" target=\"_blank\">Lakmé Kajal</a>, <a href=\"https://www.lovelocal.in/products/maybelline-colossal-kajal-33971\" target=\"_blank\">Maybelline Mascara</a>, Lakmé nail polish remover,  L'Oréal mask, <a href=\"https://www.lovelocal.in/products/himalaya-lip-869076\" target=\"_blank\">Himalaya lip balm</a> and a lot more that helps you bring out the beauty in you that is already present. Shop oral care products like <a href=\"https://www.lovelocal.in/search?q=toothpaste\" target=\"_blank\">toothpaste</a> and <a href=\"https://www.lovelocal.in/search?q=dental%20floss\" target=\"_blank\">dental floss</a> to ensure you maintain oral hygiene that is important to ward off other diseases as well.",
            question: 'You favourite skin care products',
            questionTag: 'h2',
            type: 'TYPE1',
          },
          {
            paragraph1: 'Personal care products are a very important purchase and almost every 2-3 months, we recommend you restock them so you are always caring for yourself. <a href="https://www.lovelocal.in/pc/personal-care/feminine-hygiene" target="_blank">Feminine hygiene</a> is also very important to maintain and it is advised to use products that you have been using for long and know that it suits you. Buy sanitary napkins from big brands like <a href="https://www.lovelocal.in/search?q=whisper" target="_blank">Whisper</a>, <a href="https://www.lovelocal.in/search?q=whisper" target="_blank">Stay Free</a> or <a href="https://www.lovelocal.in/search?q=carefree" target="_blank">Carefree</a>, buy women friendly razors from <a href="https://www.lovelocal.in/products/gillette-venus-blades-razor-for-1207784" target="_blank">Gillette</a> and hair removal creams from Veet to ensure your personal care is maintained in the best possible way. Not to forget men’s grooming products like shaving cream, hair styling gel, face wash and razors are available to shop as well. So, what are you waiting for? Just make a list of all the personal care products you and your family need and order them online from your trusted local shops now available on LoveLocal.',
            question: 'Love to pamper yourself? You’ll love LoveLocal too!',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Personal Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7953,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'pain_relief_produtcs',
        categoryMetaData: '',
        children: [],
        displayName: 'Pain Relief Produtcs',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8186,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_baby_infant',
        categoryMetaData: '',
        children: [],
        displayName: 'Baby & Infant Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7960,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 111,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_digestive_pills',
        categoryMetaData: '',
        children: [],
        displayName: 'Digestive Pills',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7961,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_sexual_wellness',
        categoryMetaData: '',
        children: [],
        displayName: 'Sexual Wellness',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7955,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'otc_diabetic_care',
        categoryMetaData: '',
        children: [],
        displayName: 'Diabetic Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7956,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'medical_devices',
        categoryMetaData: '',
        children: [],
        displayName: 'Medical Devices',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7995,
        image: 'https://lovelocal-store-front.s3.ap-south-1.amazonaws.com/04232021112639533100_category_images.png',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Feminine hygiene products are personal care products used during menstruation, vaginal discharge, and other bodily functions related to the vulva and vagina. Those products that are used during menstruation may also be called menstrual hygiene products.',
        categoryId: 'feminine_hygiene',
        categoryMetaData: '',
        children: [],
        displayName: 'Feminine Hygiene',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7898,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 116,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'gloves',
        categoryMetaData: '',
        children: [],
        displayName: 'Gloves',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8000,
        image: 'https://lovelocal-store-front.s3.ap-south-1.amazonaws.com/04232021112639533100_category_images.png',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'mouthwash_and_cleaners',
        categoryMetaData: '',
        children: [],
        displayName: 'Mouthwash & Cleaners',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8184,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Pharma and OTC Products',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8048,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153520027621_product___pharma_and_otc.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'cereals_and_breakfast',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: 'Ketchup is a table condiment or sauce. The unmodified term now typically refers to tomato ketchup, although original recipes used egg whites, mushrooms, oysters, grapes, mussels, or walnuts, among other ingredients.',
        categoryId: 'sauces_ketchups',
        categoryMetaData: [
          {
            paragraph1: 'Sauces and ketchups are famous condiments. These are commonly used to enhance the taste and flavour of food. A bottle of tomato ketchup should always be on the dining table.',
            paragraph2: 'They add flavour and taste to our sandwiches, noodles, and even chapati on occasion.',
            paragraph3: 'Everything tastes great with tomato ketchup. Kissan ketchup is a well-known brand in the ketchup and tomato sauce industry. A ketchup bottle is a basic necessity in every household, and LoveLocal has a variety of them.',
            question: 'Buy Best Ketchup Brands in India From LoveLocal',
            questionTag: 'h1',
            type: 'TYPE3',
          },
          {
            paragraph1: "Many individuals enjoy Chinese cuisine. It's known for its spiciness, as well as a blend of sauces and a tangy vinegar flavour. Chilli sauce is also utilised in Chinese cuisine. One of the most famous ones is Chings <b>red chilli sauce pouch</b>.",
            paragraph2: "Pasta sauces are also becoming more popular on the market as people's interest in Italian food grows. LoveLocal allows you to order sauces, ketchup, pasta sauces, chilli sauce, jellies, and mayonnaise online. You may conveniently purchase online while sitting in the comfort of your own home.",
            paragraph3: "These sauces and ketchup can always be used to improve the flavour and taste of your food. When you're dealing with bland, tasteless food, they're a lifesaver. It's past time for you to include your favourite products.",
            question: 'Buy Sauces Online',
            questionTag: 'h2',
            type: 'TYPE3',
          },
        ],
        children: [],
        displayName: 'Sauces & Ketchups',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7883,
        image: '',
        largeImage: '',
        maxDiscountPercent: 5,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'oats_and_porridge',
        categoryMetaData: '',
        children: [],
        displayName: 'Oats & Porridge',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8103,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: "These Protein Granola Energy Bars are a powerhouse of nutrition that tastes delicious. They're loaded with the goodness of oats, honey, chia seeds, protein, and chocolate chips. Old fashioned, rolled oats are a key reason these protein bars so healthy, satisfying, and chewy-delicious.",
        categoryId: 'granola_protein_bars',
        categoryMetaData: '',
        children: [],
        displayName: 'Granola & Protein Bars',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7890,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'kids_cereals',
        categoryMetaData: '',
        children: [],
        displayName: 'Kids Cereals',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8105,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'flakes',
        categoryMetaData: '',
        children: [],
        displayName: 'Flakes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8101,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 111,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'baking_and_desserts',
        categoryMetaData: '',
        children: [],
        displayName: 'Baking & Desserts',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8107,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Olives are small fruits that grow on olive trees (Olea europaea). They belong to a group of fruit called drupes, or stone fruits, and are related to mangoes, cherries, peaches, almonds, and pistachios. Olives are very high in vitamin E and other powerful antioxidants.',
        categoryId: 'olives_tinned_food',
        categoryMetaData: '',
        children: [],
        displayName: 'Olives & Tinned Food',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7892,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'cereals_and_millets',
        categoryMetaData: '',
        children: [],
        displayName: 'Cereals & Millets',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8104,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'museli',
        categoryMetaData: '',
        children: [],
        displayName: 'Muesli',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8102,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 110,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Chyawanprash is a nutritious jam, widely recommended by Ayurveda for its wide spectrum of health benefits. In Sanskrit, the word Prash means specially prepared food. It was reportedly formulated by two ancient sages for restoring youth to an elderly sage Chyawan- hence named Chyawanprash.',
        categoryId: 'chyawanprash_health_foods',
        categoryMetaData: '',
        children: [],
        displayName: 'Chyawanprash & Health Foods',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7885,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Cereals and Breakfast',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8039,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153244291952_product___cereals_and_breakfast.webp',
    largeImage: '',
    maxDiscountPercent: 5,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'bath_body_and_hair',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: 'Skin care is the range of practices that support skin integrity, enhance its appearance and relieve skin conditions. They can include nutrition, avoidance of excessive sun exposure and appropriate use of emollients.',
        categoryId: 'skin_care',
        categoryMetaData: '',
        children: [],
        displayName: 'Skin Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7894,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'hair_care',
        categoryMetaData: '',
        children: [],
        displayName: 'Hair Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7895,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'hair_styling',
        categoryMetaData: '',
        children: [],
        displayName: 'Hair Styling',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8073,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Deodorant is used on the body to cover the body smell brought about by sweat and microbes. Perfumes contain 15-25% fragrance oil in concentrated alcohol. A deodorant usually contains 2-3% solution of fragrance oil and mild alcohol. Perfume provides complete fragrance to your body. Deodorant helps to stop body odour.',
        categoryId: 'fragrances_deos',
        categoryMetaData: '',
        children: [],
        displayName: 'Fragrances & Deos',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7896,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'face_cream',
        categoryMetaData: '',
        children: [],
        displayName: 'Face Cream',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8079,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 111,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'moisturiser',
        categoryMetaData: '',
        children: [],
        displayName: 'Moisturiser',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8075,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'body_wash_and_shower_gel',
        categoryMetaData: '',
        children: [],
        displayName: 'Body Wash & Shower Gel',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8072,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 110,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Gentle Foaming Hand Soap delivers a cloud of luxurious foam that transforms into a rich, creamy lather to gently wash away dirt and germs, while soothing Aloe and nourishing Vitamin E leave hands feeling soft, smooth and lightly scented.',
        categoryId: 'bath_body_handwash',
        categoryMetaData: '',
        children: [],
        displayName: 'Bath, Body & Hand Wash',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7897,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: "Oral hygiene or Oral care is the practice of keeping one's mouth clean and free of disease and other problems by regular brushing of the teeth and cleaning between the teeth. It is important that oral hygiene be carried out on a regular basis to enable prevention of dental disease and bad breath.",
        categoryId: 'oral_care',
        categoryMetaData: '',
        children: [],
        displayName: 'Oral Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7899,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'shampoo_and_conditioner',
        categoryMetaData: '',
        children: [],
        displayName: 'Shampoo & Conditioner',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8069,
        image: '',
        largeImage: '',
        maxDiscountPercent: 5,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 116,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'cleansers',
        categoryMetaData: '',
        children: [],
        displayName: 'Cleansers',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8076,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'perfume_deo_and_talc',
        categoryMetaData: '',
        children: [],
        displayName: 'Perfume, Deo & Talc',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8083,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 112,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'hair_oil_and_serum',
        categoryMetaData: '',
        children: [],
        displayName: 'Hair Oil & Serum',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8080,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 114,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'bathing_soap',
        categoryMetaData: '',
        children: [],
        displayName: 'Bathing Soap',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8070,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 115,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'sun_screen',
        categoryMetaData: '',
        children: [],
        displayName: 'Sun Screen',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8077,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'face_wash',
        categoryMetaData: '',
        children: [],
        displayName: 'Face Wash',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8078,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 113,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'body_lotion',
        categoryMetaData: '',
        children: [],
        displayName: 'Body Lotion',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8074,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Bath, Body and Hair',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8036,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153206270387_product___bath_body_and_hair.webp',
    largeImage: '',
    maxDiscountPercent: 5,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'beauty_and_grooming',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'face_and_lip_care',
        categoryMetaData: '',
        children: [],
        displayName: 'Face & Lip Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8089,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'nail_care',
        categoryMetaData: '',
        children: [],
        displayName: 'Nail Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8087,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'shaving_needs',
        categoryMetaData: '',
        children: [],
        displayName: 'Shaving Needs',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8086,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'body_powder',
        categoryMetaData: '',
        children: [],
        displayName: 'Body Powder',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8084,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: "Make up is a category of health and beauty products that are used to care for the face and body, or used to accentuate or change a person's appearance. Make up can also refer to a number of products used to care for the skin and the body, as well as those used to add fragrance to it.",
        categoryId: 'makeup',
        categoryMetaData: '',
        children: [],
        displayName: 'Makeup',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7893,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'ayurveda',
        categoryMetaData: '',
        children: [],
        displayName: 'Ayurveda',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8092,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 101,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'aromatherapy',
        categoryMetaData: '',
        children: [],
        displayName: 'Aromatherapy',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8085,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Tools and accessories in personal care includes Manicure & Pedicure Kits, Body Scrubbers, Brushes\nLoofahs, Sponges\nShower Caps etc.',
        categoryId: 'accessories_tools',
        categoryMetaData: '',
        children: [],
        displayName: 'Accessories & Tools',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7903,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Male grooming refers to men paying attention to fashion and enhancing their own appearance. This interest has become increasingly apparent in popular culture. Face Wash,trimmer,Moisturizer and Sunscreen,Hair Comb,Shower gel or soap,Deodorant and A Perfume are some men grooming products.',
        categoryId: 'mens_grooming',
        categoryMetaData: '',
        children: [],
        displayName: "Men's Grooming",
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7900,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 110,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'hair_colouring',
        categoryMetaData: '',
        children: [],
        displayName: 'Hair Colouring',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8090,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'eye_care',
        categoryMetaData: '',
        children: [],
        displayName: 'Eye Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8091,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Beauty & Grooming',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8037,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153219940791_product___beauty___grooming.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'cleaning_essentials',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'dishwash',
        categoryMetaData: '',
        children: [],
        displayName: 'Dishwash',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8120,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'detergent_bar',
        categoryMetaData: '',
        children: [],
        displayName: 'Detergent Bar',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8119,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 110,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'scrub_and_sponges',
        categoryMetaData: '',
        children: [],
        displayName: 'Scrub & Sponges',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8113,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'toilet_and_bathroom_cleaners',
        categoryMetaData: '',
        children: [],
        displayName: 'Toilet & Bathroom Cleaners',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8122,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Dishwashing is the process of cleaning cooking utensils, dishes, cutlery and other items to prevent foodborne illness.Alkaline salts is a primary component in older and original-style dishwasher detergent powders.',
        categoryId: 'deteregents_dishwash',
        categoryMetaData: '',
        children: [],
        displayName: 'Detergents & Dishwash',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7934,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'kitchen_glass_and_drain',
        categoryMetaData: '',
        children: [],
        displayName: 'Kitchen Glass & Drain',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8121,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'All-purpose cleaners are usually concentrated solutions of surfactants and water softeners, which enhance the behavior of surfactant in hard water. ... Their dilute solutions are neutral or weakly alkaline, and are safe for use on most surfaces.',
        categoryId: 'all_purpose_cleaners',
        categoryMetaData: '',
        children: [],
        displayName: 'All Purpose Cleaners',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7929,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'descaler',
        categoryMetaData: '',
        children: [],
        displayName: 'Descaler',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8115,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'liquid_detergents',
        categoryMetaData: '',
        children: [],
        displayName: 'Liquid Detergents',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8116,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 111,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'detergent_powders',
        categoryMetaData: '',
        children: [],
        displayName: 'Detergent Powders',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8114,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 112,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'handwash_and_sanitizers',
        categoryMetaData: '',
        children: [],
        displayName: 'Handwash & Sanitizers',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8124,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'fabric_needs',
        categoryMetaData: '',
        children: [],
        displayName: 'Fabric Needs',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8123,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'floor_cleaners',
        categoryMetaData: '',
        children: [],
        displayName: 'Floor Cleaners',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8118,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Cleaning Essentials',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8041,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153313210004_product___cleaning_essentials.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'tea_coffee_and_more',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: 'Tea is a sweet-smelling refreshment regularly set up by pouring hot or bubbling water over restored or new leaves of Camellia sinensis, an evergreen bush local to East Asia. After water, it is the most broadly burned-through beverage on the planet.',
        categoryId: 'tea',
        categoryMetaData: '',
        children: [],
        displayName: 'Tea',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7846,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'green_and_herbal_tea',
        categoryMetaData: '',
        children: [],
        displayName: 'Green & Herbal Tea',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8210,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Malted beverages are popularly known as health drinks in India. While they have positioned such beverages as nutritious drinks, these are widely consumed as taste enhancers to encourage drinking of milk among growing children.',
        categoryId: 'health_drinks_supplements',
        categoryMetaData: [
          {
            paragraph1: '<a href="https://www.lovelocal.in/pc/drinks/health_drinks_supplements" target="_blank">Health drinks</a> are a quick and easy method to provide your body the energy it requires. There are many different brands of energy drinks available on the market. These health supplements are an excellent method to get the minerals and protein you need in your regular diet. LoveLocal’s online store now offers a broad choice of supplements, including protein supplements, food supplements, and nutritional supplements. These supplements can benefit adults in a variety of ways in addition to providing nutritious value for children. Browse our whole selection of health drinks and supplements to find the right supplement for your taste and needs.',
            question: 'Buy Health Drinks online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: 'There are a few brands of health drinks that stand out among the many on the market and are liked by the majority of consumers. Some of the most popular brands are Horlicks, <a href="https://www.lovelocal.in/products/cadbury-bournvita-health-drink-1207504" target="_blank">Bournvita</a>, <a href="https://www.lovelocal.in/products/boost-health-energy-and-sports-nutrition-drink-42537" target="_blank">Boost</a>, and Complan. All of these brands, as well as others, may be found in our online store. Other popular choices among adults who drink health beverages include Pro360, <a href="https://www.lovelocal.in/products/ensure-nutrition-vanilla-flavour-powder-jar-1207328" target="_blank">Ensure</a>, and <a href="https://www.lovelocal.in/products/womens-horlicks-health-nutrition-drink-chocolate-868145" target="_blank">Horlicks for women</a>.',
            question: 'Health Drinks Online Shopping from LoveLocal',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Health Drinks & Supplements',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7851,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'instatnt_drink_mixes',
        categoryMetaData: '',
        children: [],
        displayName: 'Instatnt Drink Mixes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8211,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'milk_based_beverages',
        categoryMetaData: '',
        children: [],
        displayName: 'Milk Based Beverages',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8213,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'coffee_and_pre-mix',
        categoryMetaData: '',
        children: [],
        displayName: 'Coffee & Pre-Mix',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8209,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Tea, Coffee & More',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8051,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153545846574_product___tea_coffee___more.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'home_and_kitchen_needs',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: 'Freshner Repellent is made from pure extracts of Lemongrass and Citronella essential oils which work as a repellent for mosquitoes, flies and insects.Freshner Repellent is purely water based and does not contain any harmful chemicals or gases.',
        categoryId: 'freshners_repellants',
        categoryMetaData: '',
        children: [],
        displayName: 'Fresheners & Repellents',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7931,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Major appliances, also known as white goods, comprise major household appliances and may include: air conditioners, dishwashers, clothes dryers, drying cabinets, freezers, refrigerators, kitchen stoves, water heaters, washing machines, trash compactors, microwave ovens, and induction cookers.',
        categoryId: 'electronic_appliances',
        categoryMetaData: '',
        children: [],
        displayName: 'Electronic Appliances',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7936,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'shoe care involves much more than just regularly cleaning your shoes. Correctly putting on and taking off shoes, committing to regular breaks from wear and using shoe shapers for storage are all key components of comprehensive shoe care.',
        categoryId: 'shoe_care',
        categoryMetaData: [
          {
            paragraph1: "Do you want to restore the lustre of your shoes? You'll need to do more than just dust and brush them for this. This is the role of products such as shoe polishes, creams, and sprays. You should have no trouble keeping your footwear game strong now that you know what to do and get.",
            question: 'Buy shoe care products online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Shoe Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7935,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 101,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'pooja_essentials',
        categoryMetaData: '',
        children: [],
        displayName: 'Pooja Essentials',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8153,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 111,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'mops_and_brushes',
        categoryMetaData: '',
        children: [],
        displayName: 'Mops & Brushes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8155,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'tissues_and_disposables',
        categoryMetaData: '',
        children: [],
        displayName: 'Tissues & Disposables',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8157,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'air_freshners',
        categoryMetaData: '',
        children: [],
        displayName: 'Air Freshners',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8149,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 114,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'garbage_bags_and_foils',
        categoryMetaData: '',
        children: [],
        displayName: 'Garbage Bags & Foils',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8151,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 112,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'kitchen tools accessories includes Utensils & Gadgets,Mixing Bowls,Kitchen Storage,Cutting Boards,Salt & Pepper,Cookbooks,Colanders & Salad Spinners.It makes our life easy in kitchen.',
        categoryId: 'kitchen_tools_accessories',
        categoryMetaData: '',
        children: [],
        displayName: 'Kitchen Tools & Accessories',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7928,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'repelllent',
        categoryMetaData: '',
        children: [],
        displayName: 'Repelllent',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8150,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 113,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'disinfectants_and_sprays',
        categoryMetaData: '',
        children: [],
        displayName: 'Disinfectants And Sprays',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8154,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 110,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Home & Kitchen Needs',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8045,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153405438630_product___home___kitchen_needs.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'atta_rice_and_dals',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'peanut_and_poha',
        categoryMetaData: '',
        children: [],
        displayName: 'Peanut And Poha',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8059,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'toor_chana_and_moong_dal',
        categoryMetaData: '',
        children: [],
        displayName: 'Toor, Chana & Moong Dal',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8057,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 110,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: "Rava or sooji is made from pure durum wheat. Sooji Ravva is a staple in the Indian eating routine. It's nutritious and has a rich flavor and fragrance. mainly used in making couscous, pasta, upma, and sweet puddings .Rava will keep you stay lite as it does not cause any heavy feeling after eating, which makes it healthy and keeps you active.",
        categoryId: 'sooji_rawa',
        categoryMetaData: '',
        children: [],
        displayName: 'Sooji | Rawa',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7856,
        image: '',
        largeImage: '',
        maxDiscountPercent: 33,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'other_dals_and_pulses',
        categoryMetaData: '',
        children: [],
        displayName: 'Other Dals & Pulses',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8058,
        image: '',
        largeImage: '',
        maxDiscountPercent: 10,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Organic staples supply one or more of the three organic macronutrients needed for survival and health: carbohydrates, proteins, and fats. Typical examples of staples include tuber- or root-crops, grains, legumes, and other seeds.',
        categoryId: 'organic_staples',
        categoryMetaData: '',
        children: [],
        displayName: 'Organic Staples',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7860,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'ready_to_cook',
        categoryMetaData: '',
        children: [],
        displayName: 'Ready To Cook',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8056,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'sooji_maida_and_besan',
        categoryMetaData: '',
        children: [],
        displayName: 'Sooji Maida & Besan',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8055,
        image: '',
        largeImage: '',
        maxDiscountPercent: 8,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'atta_whole_wheat',
        categoryMetaData: '',
        children: [],
        displayName: 'Atta Whole Wheat',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8053,
        image: '',
        largeImage: '',
        maxDiscountPercent: 16,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 112,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'rice_products',
        categoryMetaData: '',
        children: [],
        displayName: 'Rice Products',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8062,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'rice_and_other_flours',
        categoryMetaData: '',
        children: [],
        displayName: 'Rice & Other Flours',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8054,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 111,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'all_grains_oil_masalas',
        categoryMetaData: '',
        children: [],
        displayName: 'Others',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7864,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 1,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Rice products include noodles, breakfast cereals, baby foods, extruded snacks, crackers, baked goods, rice milk, fermented beverages, and rice bran oil.',
        categoryId: 'rice_rice_products',
        categoryMetaData: '',
        children: [],
        displayName: 'Rice | Rice Products',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7855,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'dals_pulses',
        categoryMetaData: '',
        children: [],
        displayName: 'Dals | Pulses',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7859,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Atta, Rice & Dals',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8035,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153122315242_product___atta_rice___dals.webp',
    largeImage: '',
    maxDiscountPercent: 33,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'dry_fruits_oils_and_masala',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: 'Dried fruit is fruit from which the majority of the original water content has been removed either naturally, through sun drying, or through the use of specialized dryers or dehydrators. Dried fruit has a long tradition of use dating back to the fourth millennium BC in The World, and is prized because of its sweet taste, nutritive value, and long shelf life. Dry fruits are rich in vitamins and proteins; they also boost immunity and prevent lifestyle diseases such as cholesterol and diabetes. Most dry fruits are rich in minerals, proteins, fibre and vitamins add to that they are tasty and delicious too. Dry fruits are excellent and healthy substitute for daily snacks.',
        categoryId: 'dry_fruits_nuts',
        categoryMetaData: '',
        children: [],
        displayName: 'Dry Fruits & Nuts',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7865,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 110,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'vanaspati_ghee',
        categoryMetaData: '',
        children: [],
        displayName: 'Vanaspati Ghee',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8142,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'A papadum is a thin, crisp, round flatbread from India. It is typically based on a seasoned dough usually made from peeled black gram flour, either fried or cooked with dry heat. Flours made from other sources such as lentils, chickpeas, rice, tapioca, certain millets or potato can be used.',
        categoryId: 'papad_fryums',
        categoryMetaData: '',
        children: [],
        displayName: 'Papads & Fryums',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7880,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Jaggery is known as Gud in Hindi .Jaggery is a natural Cane sugar, renowned for its unique taste & texture. Jaggery helps to cleanse the body and act as a digestive agent also provides good amounts of minerals. Jaggery is full of Iron, Calcium, essential amino acid, magnesium, minerals and vital vitamins. It is also used to treat Anemia.',
        categoryId: 'sugar_salt_jaggery',
        categoryMetaData: '',
        children: [],
        displayName: 'Sugar, Salt & Jaggery',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7862,
        image: '',
        largeImage: '',
        maxDiscountPercent: 27,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 112,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Garam masala is a blend of ground spices, originating from South Asia, common in Indian, Pakistani, Nepalese, Bangladeshi, Sri Lankan and Afghan cuisines. It is used alone or with other seasonings.',
        categoryId: 'spices_masalas',
        categoryMetaData: '',
        children: [],
        displayName: 'Spices | Masalas',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7861,
        image: '',
        largeImage: '',
        maxDiscountPercent: 11,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 111,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'edible oil or cooking oil is plant, animal, or synthetic fat used in frying, baking, and other types of cooking. It is also used in food preparation and flavouring not involving heat, such as salad dressings and bread dippings like bread dips.There is a wide variety of edible oils from plant sources such as olive oil, palm oil, soybean oil, canola oil (rapeseed oil), corn oil, peanut oil and other vegetable oils, as well as animal-based oils like butter and lard.',
        categoryId: 'edible_oils',
        categoryMetaData: [
          {
            paragraph1: 'Cooking oil or edible oil is oil extracted from plants and vegetables. Vegetable oil is derived solely from the seed or other plant or fruit parts. Edible oils come in a wide range of flavours.',
            paragraph2: 'Olive oil, coconut oil, palm oil, flaxseed oil, mustard oil, and canola oil are the most common cooking oils. You can easily buy <b>edible oil online</b> from LoveLocal. Your local retailers are always ready to provide their customers with the latest and high-quality products.',
            question: 'Buy Cooking Oil Online',
            questionTag: 'h1',
            type: 'TYPE2',
          },
          {
            paragraph1: "Plant-based oils are commonly used in Indian homes for frying and cooking. Because of its low cost, it is widely consumed. It is beneficial to one's health, weight management, and cholesterol control. For cooking large amounts of meals like french fries and pakoras, groundnut oil is widely utilised.",
            question: 'Cooking Oil Online',
            questionTag: 'h2',
            type: 'TYPE1',
          },
          {
            paragraph1: 'Cooking oils from Saffola, Fortune, Figaro, Gold Winner, and Parachute are well-known brands. Depending on your needs, cooking oil can be purchased in a pet bottle, refill pouch, canister, sachet, or jar.',
            paragraph2: 'In India, the cost of cooking oil varies based on grading, packaging, brand, and other factors. To achieve a flavorful taste, use these oils to softly saute, deep fry, and season your cuisine. You can also use them to make excellent salad dressings by combining them with spices.',
            paragraph3: 'For a healthy snack, sprinkle flavoured oils over the vegetables and roast them in the oven. You may order edible oil online and have it delivered straight to your door.',
            question: 'Buy Edible Oil Online',
            questionTag: 'h2',
            type: 'TYPE3',
          },
        ],
        children: [],
        displayName: 'Edible Oils',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7857,
        image: '',
        largeImage: '',
        maxDiscountPercent: 5,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 114,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'olive_oils',
        categoryMetaData: '',
        children: [],
        displayName: 'Olive Oils',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8216,
        image: '',
        largeImage: '',
        maxDiscountPercent: 5,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Chutney. Pickle. It is a spicy condiment of Indian origin made from fruits or vegetables. It is a raw fruit or vegetable that is sun-dried and marinated in brine or flavored oil. Smaller pieces of fruits and vegetables are used in the preparation of chutneys.',
        categoryId: 'pickles_chutneys',
        categoryMetaData: [
          {
            paragraph1: "India is known for its chutneys, murabas, spicy and non-spicy pickles, and each area has its unique flavour and type of pickle. In North India, mustard oil is used to make pickles, but in South India, sesame oil is used. Mirchi.com offers a diverse selection of North Indian and South Indian pickles from all around the country. So, if you're looking for a pickle to accompany your lunch, you've come to the correct spot! You can <a href=\"https://www.lovelocal.in/pc/ready_to_eat_instant_and_frozen_food/pickles_chutneys\" target=\"_blank\">buy pickles online</a> from LoveLocal, that too delivered to you from your nearest local retailer.",
            question: 'Buy pickles and chutneys online from LoveLocal',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: 'Choose from traditional vegetarian pickles such as tangy lemon pickle, sweet lemon pickle, mango pickle, green/red chili pickle, mixed vegetable pickle, lotus stem pickle, lehsun pickle. Apart from this you can get the best <b>chutneys online</b> from LoveLocal! Be it your desi garlic chutney or <a href="https://www.lovelocal.in/products/chings-schezwan-chutney-187081" target="_blank">desi-Chinese Schezwan chutney</a>, we have it all.',
            question: 'Buy pickles from Local retailers',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Pickles & Chutneys',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7881,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'sauces_and_vinegar',
        categoryMetaData: '',
        children: [],
        displayName: 'Sauces & Vinegar',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8144,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'seeds will always give a boost to your overall health and supply your body with large amount of antioxidants and minerals.some daily use seeds are almond, pista, kaju, dates, walnut, raisins etc.',
        categoryId: 'seeds',
        categoryMetaData: '',
        children: [],
        displayName: 'Seeds',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7867,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'ghee',
        categoryMetaData: '',
        children: [],
        displayName: 'Ghee',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8141,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 113,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'hearbs_and_seasoning',
        categoryMetaData: '',
        children: [],
        displayName: 'Hearbs & Seasoning',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8145,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'pastes',
        categoryMetaData: '',
        children: [],
        displayName: 'Pastes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8139,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Dry Fruits, Oils & Masala',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8044,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153347311965_product___dry_fruits_oils___masala.webp',
    largeImage: '',
    maxDiscountPercent: 27,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'dairy_bread_and_eggs',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: 'Cheese is a dairy product, derived from milk and produced in wide ranges of flavors, textures and forms by coagulation of the milk protein casein. It comprises proteins and fat from milk, usually the milk of cows, buffalo, goats, or sheep.',
        categoryId: 'cheese',
        categoryMetaData: '',
        children: [],
        displayName: 'Cheese',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7868,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'condensed_powdered_milk',
        categoryMetaData: '',
        children: [],
        displayName: 'Condensed Powdered Milk',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8133,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Bread is a staple food prepared from a dough of flour and water, usually by baking. Throughout recorded history, it has been a prominent food in large parts of the world. And A bun is a small, sometimes sweet, bread-based item or roll. Though they come in many shapes and sizes, they are most commonly hand-sized or smaller, with a round top and flat bottom. Buns are usually made from flour, sugar, milk, yeast and butter.',
        categoryId: 'breads_buns',
        categoryMetaData: '',
        children: [],
        displayName: 'Breads & Buns',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7921,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Dairy products or milk products are a type of food produced from or containing the milk of mammals, most commonly cattle, water buffaloes, goats, sheep, and camels. Dairy products include food items such as yogurt, cheese and butter. A facility that produces dairy products is known as a dairy, or dairy factory.',
        categoryId: 'dairy_products',
        categoryMetaData: [
          {
            paragraph1: 'Dairy products are milk products made from cow, water buffalo, goat, and other animal milk. Clotted cream, powdered milk, condensed milk, khoya, evaporated milk, cheese, casein, yoghurt, and ice creams are all milk-based products that fall under the dairy category.',
            question: 'Buy dairy products online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: 'Dairy is widely used in Indian households to prepare a variety of dishes. Dairy is present in all of these delicacies and drinks, from using ready-made condensed milk for kheer to making cottage cheese, buttermilk, lassi, basundi, and ghee. All over the world, frozen custards, frozen yoghurt, gelato ice cream, curds, and other products are popular. There are no limits to the number of dishes that can be prepared using dairy products due to their ease of availability over the counter and through online shopping.',
            question: 'Dairy products list',
            questionTag: 'h2',
            type: 'TYPE1',
          },
          {
            paragraph1: "The extensive use of various dairy products in Indian dishes is what makes them so popular and sought-after around the world. Ghee or clarified butter, malai, and other ingredients can enhance the flavour and aroma of any vegetarian or non-vegetarian dish. They have the ability to tingle your taste buds and keep you wanting more. These products not only enhance the flavours, but they are also healthy. Ghee, for example, has anti-inflammatory properties. It's also high in vitamins and omega-3 fatty acids. These properties can assist the body in fighting diseases.",
            question: 'Online dairy products',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Dairy Products',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7873,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'curd_and_yogurts',
        categoryMetaData: '',
        children: [],
        displayName: 'Curd & Yogurts',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8134,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Usually Indian eat chicken eggs in their daily routin. The chicken egg is made up of approximately two-thirds white and one-third yolk. The yolk contains lipids, vitamins, minerals, and carotenoid pigments.',
        categoryId: 'eggs',
        categoryMetaData: [
          {
            paragraph1: "Eggs are a good source of multivitamins and one of the best sources of protein. Eggs are naturally produced foods that contain many essential nutrients for an adult and child's balanced diet. An egg is a necessary food that can be cooked in a variety of ways and eaten raw. Eggs are an excellent source of multivitamins and proteins, which help children and working-class people perform their daily tasks with renewed vigour and enthusiasm. Eggs purchased online are the most efficient way to shop for hygienic and healthy eggs because they are delivered directly to your door. Fresh eggs offer the lowest possible egg price on our grocery online store, ready to be delivered whenever it is convenient for you. Our online egg store has a large selection for you to choose from.",
            question: 'Buy eggs online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: 'Eggs are a necessary component of our daily diet. Eggs are eaten at any time of day, whether for breakfast, lunch, or dinner. You can get 1 tray of eggs at a reasonable price from grocery e-commerce websites. For a filling breakfast, try scrambled eggs with toast and juice, or make egg curry and serve it with rice for lunch. For a tasty evening snack, use eggs to make egg rolls, egg puffs, or egg chops. What else? Eggs are also required in the preparation of cakes, pastries, and brownies. They are considered a superfood because they are high in proteins, vitamins, and minerals, providing our bodies with essential nutrients for a healthy lifestyle.',
            question: 'Buy online eggs from LoveLocal',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Eggs',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7920,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'paneer_and_cream',
        categoryMetaData: '',
        children: [],
        displayName: 'Paneer & Cream',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8137,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'butter_and_cheese',
        categoryMetaData: '',
        children: [],
        displayName: 'Butter & Cheese',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8135,
        image: '',
        largeImage: '',
        maxDiscountPercent: 4,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Paneer or Indian cottage cheese is a fresh cheese common in the Indian subcontinent made from cow or buffalo milk. It is a non-aged, non-melting soft cheese made by curdling milk with a fruit- or vegetable-derived acid, such as lemon juice.',
        categoryId: 'paneer_curd',
        categoryMetaData: [
          {
            paragraph1: "One of the most popular dairy products is paneer. It's a non-melting, unaged farmer cheese prepared by curdling hot milk with lemon juice, vinegar, or other culinary acids. In eastern India, the crumbly and wet type of cheese curd or acid-set cottage cheese is known as chhena. <a href=\"https://www.lovelocal.in/products/paneer--1676614\" target=\"_blank\">Paneer</a> is a high-protein, high-calcium food. Paneer can aid weight loss by curbing appetite and keeping you feeling content after meals and snacks. Paneer is a fantastic source of protein, especially for vegetarians who don't obtain their protein from meat sources.",
            question: 'Buy paneer online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: 'We often treat ourselves to exquisite meals. Palak paneer, butter paneer masala, and a variety of other great meals are some of the delicious foods that may be served during these occasions. While there are many other varieties of paneer on the market, <a href="https://www.lovelocal.in/products/amul-paneer-186271" target="_blank">Amul paneer</a>, soya paneer/tofu paneer, and Amul paneer are the finest to try. The most popular vegetarian foods include soya paneer, Amul paneer, and Amul cream, which are available in a wide variety of flavours.',
            question: 'Buy paneer from LoveLocal',
            questionTag: 'h2',
            type: 'TYPE1',
          },
          {
            paragraph1: "<a href=\"https://www.lovelocal.in/products/curd--1676611\" target=\"_blank\">Yogurt</a> or curd has been a staple of Indian cuisine since the beginning of time, and with good reason! Yogurt has the highest concentration of probiotics (good bacteria), which are important for digestion and intestinal health. Yogurt is high in calcium, vitamins, and protein, in addition to probiotics. It's no surprise that curd is recommended by dietitians all across the world. You would always prefer buying curd online after seeing our diverse selection of yoghurts.",
            question: 'Buy Curd online',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Paneer & Curd',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7871,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'dairy_alternatives',
        categoryMetaData: '',
        children: [],
        displayName: 'Dairy Alternatives',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8138,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Milk is a nutrient-rich liquid food produced by the mammary glands of mammals. It is the primary source of nutrition for young mammals, including breastfed human infants before they are able to digest solid food.',
        categoryId: 'milk',
        categoryMetaData: [
          {
            paragraph1: "Milk is an essential part of our diet because it is so nutritious. It is high in calcium, vitamins, and minerals. LoveLocal offers a variety of milk brands to satisfy all dietary needs. Today, order your favourite milk and have it delivered right to your door by your nearest local retailer. If you're watching your weight and searching for low-fat milk, LoveLocal offers it as well. LoveLocalhas a variety of skimmed milk brands for you to pick from. Skimmed milk has a low fat content and is ideal for those watching their weight.",
            question: 'Buy Amul Milk online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: 'Although cow milk is the most frequent variety, milk can also be obtained from other animals such as goats, buffaloes, and natural sources. When purchasing milk online, reading the label is an important step in maintaining a healthy diet. When you buy milk from LoveLocal, read the ingredient label to learn all about its nutritious benefits. Consuming fresh milk provides the greatest nutritional benefits. It provides a lot of energy and keeps you full all day. If you are lactose intolerant, we also provide options for lactose-free milk.',
            question: 'Best <b>Amul Milk Price</b> on LoveLocal',
            questionTag: 'h2',
            type: 'TYPE1',
          },
          {
            paragraph1: 'Lactose-free milk eliminates milk side effects such as bloating, indigestion, and other digestive disorders without sacrificing milk flavour. Toned milk, which is essentially buffalo milk, is also available, as is skimmed milk, which is low-fat milk. Your local retailers on LoveLocal sell a variety of pasteurised and homogenised milk in tetra packs. Tetra-pack milk has multiple layers of protection that keep milk fresh. Tetra pack milk is also more convenient to store and pour than fresh milk offered in pouches. You can also buy milk that does not need to be heated and is okay to consume directly. If you are a vegan, you can use soy milk or almond milk for cow milk.',
            question: 'Buy Almond Milk',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Milk',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7869,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Buttermilk is a fermented dairy drink. Traditionally, it was the liquid left behind after churning butter out of cultured cream; however, as most modern butter is made not with cultured cream, but with sweet cream, most modern buttermilk is cultured.',
        categoryId: 'buttermilk_lassi',
        categoryMetaData: '',
        children: [],
        displayName: 'Buttermilk & Lassi',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7872,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'milkshakes_and_more',
        categoryMetaData: '',
        children: [],
        displayName: 'Milkshakes & More',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8136,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 101,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Dairy, Bread & Eggs',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8043,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153335421808_product___dairy_bread___eggs.webp',
    largeImage: '',
    maxDiscountPercent: 4,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'sauces_and_spreads',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'jams_honey_and_spreads',
        categoryMetaData: '',
        children: [],
        displayName: 'Jams, Honey And Spreads',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8194,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Jam is made with mashed fruit. Preserves: Preserves have whole fruit or large pieces of fruit. ... Fruit spreads (only fruit): These are 100% fruit with no sugar added. If needed, a sweet fruit juice such as white grape juice or apple juice may be added.',
        categoryId: 'jams_honey_spreads',
        categoryMetaData: '',
        children: [],
        displayName: 'Jams, Honey & Spreads',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7884,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'chocolate_spreads',
        categoryMetaData: '',
        children: [],
        displayName: 'Chocolate Spreads',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8195,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 101,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'peanut_butters',
        categoryMetaData: '',
        children: [],
        displayName: 'Peanut Butters',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8196,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Sauces and Spreads',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8049,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023175221852984_product___sauces_and_spreads.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'biscuits',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: 'A cookie is a baked or cooked food that is typically small, flat and sweet. It usually contains flour, sugar and some type of oil or fat. It may include other ingredients such as raisins, oats, chocolate chips, nuts, etc.',
        categoryId: 'cookies',
        categoryMetaData: '',
        children: [],
        displayName: 'Cookies',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7919,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'cream_biscuits_and_wafers',
        categoryMetaData: '',
        children: [],
        displayName: 'Cream Biscuits & Wafers',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8099,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'salted_and_plain',
        categoryMetaData: '',
        children: [],
        displayName: 'Salted & Plain',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8095,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'A cookie is a baked or cooked food that is typically small, flat and sweet. It usually contains flour, sugar and some type of oil or fat. It may include other ingredients such as raisins, oats, chocolate chips, nuts, etc.',
        categoryId: 'cookies_biscuits',
        categoryMetaData: [
          {
            paragraph1: "Biscuits and cookies are one of  the most popular snacks. Biscuits are something that everyone enjoys, whether it's your children in their snack box or to dip with your high tea. When it comes to biscuits, there are numerous brands to choose from. Good day biscuits, marie biscuits, parle g biscuits, tiger biscuit, brittania biscuits, and bourbon biscuits are some of the most popular biscuits in the Indian market. Each of these brands has a diverse product line, so you can imagine how long the list is. Butter biscuits are extremely popular in the market and can be found in a variety of stores ranging from small to large.",
            question: 'Buy Cookies Online - Cookies Vs Biscuits',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: "India is a tea country, and what better accompaniment to a good cup of tea than a biscuit or cookie? No amount of cupcakes, macarons, or pies will ever be able to replace the humble biscuits and cookies that go so well with afternoon tea. Today, biscuits and cookies are no longer so humble, and they come in exciting new types and flavours to meet the markets' growing demands. There are the evergreen Marie biscuits, and then there are a variety of cream biscuits. Aside from that, this spice-loving country has plenty of chocolate chip cookies and spiced biscuits.",
            paragraph2: 'Apart from them, most people in India enjoy salt biscuits and milk biscuits. There are also several options for those who are health-conscious. From the plethora of brands that offer biscuits and cookies, healthier options such as digestive biscuits and nutri choice are available.',
            question: 'Buy biscuits online',
            questionTag: 'h2',
            type: 'TYPE2',
          },
        ],
        children: [],
        displayName: 'Cookies & Biscuits',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7912,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'marie_health_digestive',
        categoryMetaData: '',
        children: [],
        displayName: 'Marie Health Digestive',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8096,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'rusk_and_khari',
        categoryMetaData: '',
        children: [],
        displayName: 'Rusk & Khari',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8097,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'organic_cookies',
        categoryMetaData: '',
        children: [],
        displayName: 'Organic Cookies',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8093,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'glucose_and_milk_biscuits',
        categoryMetaData: '',
        children: [],
        displayName: 'Glucose & Milk Biscuits',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8094,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Biscuits',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8038,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153231796972_product___biscuits.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'chocolates_and_desserts',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'flavored_yogurts',
        categoryMetaData: '',
        children: [],
        displayName: 'Flavored Yogurts',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8111,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 101,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Mukhwas is a colorful Indian-Pakistani after-meal snack or digestive aid widely used as a mouth freshener, especially after meals. It can be made of various seeds and nuts, often fennel, anise, coconut, coriander, and sesame.',
        categoryId: 'mukhwas',
        categoryMetaData: '',
        children: [],
        displayName: 'Mukhwas',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7914,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'It strengthens sense organs, improves memory, intelligence, and vitality. It detoxifies and rejuvenates the system, he shares. Wheat or rawa, another key ingredient in mithai, has the capacity to strengthen all dhatu (seven levels of tissue), improve complexion, and pacify vata and pitta.',
        categoryId: 'mithai',
        categoryMetaData: '',
        children: [],
        displayName: 'Mithai',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7866,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Chocolate is a preparation of roasted and ground cacao seeds that is made in the form of a liquid, paste, or in a block, which may also be used as a flavoring ingredient in other foods.',
        categoryId: 'chocolates',
        categoryMetaData: [
          {
            paragraph1: "Chocolate is literally synonymous with happiness. It secretes anandamide, a neurotransmitter, which means \"joy.\" This delicious food product is liked and eaten across cultures and is extracted from the bitter pods of the cacao plant. They come in a variety of bitterness and sweetness levels - dark, milk, white, and pink. Baked goods such as cakes, pastries, mousse, pies, and so on would be incomplete without the goodness of these variants. Chocolates, known as a child's delight, have a variety of little-known health benefits. They contain plant chemicals that act as antioxidants in our bodies, benefiting our heart health and weight loss efforts. It also influences the release of serotonin, a happy chemical in our brain. Dark chocolate is even more beneficial as it contains a huge amount of antioxidants. You can <b>buy dark chocolate</b> on LoveLocal from your nearest local retailer",
            question: 'Buy Chocolates Online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: "Cadbury, Nestle, Fabelle, Hershey, and Amul are some of the well-known chocolate companies that sell their products online. Dairy Milk is one of Cadbury's most popular brands among their vast array of chocolates. Other than the original, it is available as Dairy Milk Silk, Silk Mousse, Silk Oreo, and Dairy Milk Crackle. Its rich sweet taste is enjoyed by both children and adults. You can buy your favourite chocolates online for yourself or share the joy by selecting ones that your friends and family enjoy.",
            question: 'Buy Chocolates',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Chocolates',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7913,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'cones_bars_and_cups',
        categoryMetaData: '',
        children: [],
        displayName: 'Cones, Bars & Cups',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8109,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'candies_and_gums',
        categoryMetaData: '',
        children: [],
        displayName: 'Candies And Gums',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8110,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'ice_creams_packs_and_tubs',
        categoryMetaData: '',
        children: [],
        displayName: 'Ice Creams Packs & Tubs',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8108,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Chocolates & Desserts',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8040,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153300442172_product___chocolates___desserts.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'munchies',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'bhujia_and_namkeens',
        categoryMetaData: '',
        children: [],
        displayName: 'Bhujia & Namkeens',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8169,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'indian_snacks',
        categoryMetaData: '',
        children: [],
        displayName: 'Indian Snacks',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8173,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Mixed farsan refers to a mixture of roasted or fried ingredients like chiwda (rice flakes), papdi (salted fried gram flour cookies), cornflakes, peanuts, roasted moth beans, dried fruits, salt and spices.',
        categoryId: 'farsan_namkeen',
        categoryMetaData: '',
        children: [],
        displayName: 'Farsan & Namkeen',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7916,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'chips_wafers_and_nachos',
        categoryMetaData: '',
        children: [],
        displayName: 'Chips, Wafers & Nachos',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8176,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Chips have been either deep fried or baked until crunchy. They are commonly served as a snack, side dish, or appetizer.',
        categoryId: 'chips_wafers',
        categoryMetaData: '',
        children: [],
        displayName: 'Chips & Wafers',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7915,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'popcorn',
        categoryMetaData: '',
        children: [],
        displayName: 'Popcorn',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8171,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Munchies',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8047,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153451131097_product___munchies.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'cold_drinks_and_juices',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'instant_drink_mixes',
        categoryMetaData: '',
        children: [],
        displayName: 'Instant Drink Mixes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8131,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 101,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'icetea_non_aerated_drink',
        categoryMetaData: '',
        children: [],
        displayName: 'Icetea Non Aerated Drink',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8129,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'squash_and_syrups',
        categoryMetaData: '',
        children: [],
        displayName: 'Squash & Syrups',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8132,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice.',
        categoryId: 'juices',
        categoryMetaData: '',
        children: [],
        displayName: 'Juices',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7850,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'energy_drinks',
        categoryMetaData: '',
        children: [],
        displayName: 'Energy Drinks',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8126,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'juices_and_fruits_drinks',
        categoryMetaData: '',
        children: [],
        displayName: 'Juices & Fruits Drinks',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8125,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'water_and_ice_cubes',
        categoryMetaData: '',
        children: [],
        displayName: 'Water & Ice Cubes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8128,
        image: '',
        largeImage: '',
        maxDiscountPercent: 5,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'soda_and_mixers',
        categoryMetaData: '',
        children: [],
        displayName: 'Soda And Mixers',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8130,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'soft_drinks',
        categoryMetaData: '',
        children: [],
        displayName: 'Soft Drinks',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8127,
        image: '',
        largeImage: '',
        maxDiscountPercent: 5,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Energy drink, any beverage that contains high levels of a stimulant ingredient, usually caffeine, as well as sugar and often supplements, such as vitamins or carnitine, and that is promoted as a product capable of enhancing mental alertness and physical performance.',
        categoryId: 'energy_soft_drinks',
        categoryMetaData: '',
        children: [],
        displayName: 'Energy & Soft Drinks',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7848,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Cold drinks & Juices',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8042,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153324711045_product___cold_drinks___juices.webp',
    largeImage: '',
    maxDiscountPercent: 5,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'instant_foods',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: 'cereals muesli Oats. Oats are a nutritious cereal choice. They are commonly rolled or crushed and then consumed as oatmeal, or porridge. Since oats are whole grains, they are rich in fiber and important nutrients.',
        categoryId: 'cereals_muesli_oats',
        categoryMetaData: [
          {
            paragraph1: "A good start to the day is essential for feeling energised and productive. A healthy existence requires the correct mix of nutrients and calories. This breakfast is the best choice if you're seeking something quick and healthy to eat. Muesli comes in a variety of tastes and textures. You can <a href=\"https://www.lovelocal.in/pc/ready_to_eat_instant_and_frozen_food/cereals_muesli_oats\" target=\"_blank\">buy muesli</a> and get this nutritious meal in a variety of flavours and combinations, including mixed fruits, <a href=\"https://www.lovelocal.in/products/plain-badam-605843\" target=\"_blank\">almonds</a>, raisins, honey and nuts, chocolate flavour, and more. As a result, you may make it more sweet and nutritious by adding milk, fruits, honey, and other nuts and seeds of your choice.",
            question: 'Buy Muesli Online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: 'With our hectic schedules, we prefer the convenience of ordering breakfast cereals online and avoiding the morning tension. Add fresh fruits like <a href="https://www.lovelocal.in/products/banana-673116" target="_blank">bananas</a>, apples, <a href="https://www.lovelocal.in/products/pears-715474" target="_blank">pear</a>, strawberries, or even more almonds, walnuts, pumpkin seeds, chia or flaxseed to your breakfast cereal on bright and lazy mornings, and convert it into a bowl of bliss.',
            question: 'Buy cereals online from LoveLocal',
            questionTag: 'h2',
            type: 'TYPE1',
          },
          {
            paragraph1: "You've come to the correct spot if you're seeking for a healthy and delicious morning breakfast. Oats are high in protein, magnesium, phosphorus, zinc, and iron, and can be eaten for breakfast as porridge, muesli, or granola. Baked items such as muffins, cookies, and bread can also be made as healthy alternatives. Buy oats and muesli online from LoveLocal and wake up to a wholesome breakfast from brands like Quaker, <a href=\"https://www.lovelocal.in/products/saffola-masala-oats-33773\" target=\"_blank\">Saffola oats</a>, Bagrry, <a href=\"https://www.lovelocal.in/products/kelloggs-oats-868446\" target=\"_blank\">Kelloggs</a>, Horlicks, and more.",
            question: 'Buy oats online',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Cereals, Muesli & Oats',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7889,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Snacks is light meal. We can eat it between meals.Samosa,dhokla,khaman,kachori,sev,kabab,chips,popcorn,french fries are some examples of them.',
        categoryId: 'cooked_snacks',
        categoryMetaData: '',
        children: [],
        displayName: 'Cooked Snacks',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7968,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'instant_mixes',
        categoryMetaData: '',
        children: [],
        displayName: 'Instant Mixes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8161,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'noodles_and_vermicelli',
        categoryMetaData: '',
        children: [],
        displayName: 'Noodles & Vermicelli',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8164,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'veg_frozen',
        categoryMetaData: '',
        children: [],
        displayName: 'Veg - Frozen',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8160,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Freezing food preserves it from the time it is prepared to the time it is eaten. Since early times, farmers, fishermen, and trappers have preserved grains and produce in unheated buildings during the winter season.',
        categoryId: 'frozen_food',
        categoryMetaData: '',
        children: [],
        displayName: 'Frozen Food',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7887,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'A ready-made meal is a prepackaged frozen or fresh meal that requires none or very little preparation besides heating up.',
        categoryId: 'ready_meals_mixes',
        categoryMetaData: '',
        children: [],
        displayName: 'Ready made Meals & Mixes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7886,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'ready_to_eat_items',
        categoryMetaData: '',
        children: [],
        displayName: 'Ready to eat',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8215,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'pasta_and_soups',
        categoryMetaData: '',
        children: [],
        displayName: 'Pasta & Soups',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8158,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Instant Foods',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8046,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153424265145_product___instant_foods.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'bakery_and_baking',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'pastries_and_brownies',
        categoryMetaData: '',
        children: [],
        displayName: 'Pastries & Brownies',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8064,
        image: '',
        largeImage: '',
        maxDiscountPercent: 5,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: "Khari biscuit is an indian version of 'rough puff pastry'. It is prepared from ingredients like flour, salt, butter and ghee. Khari biscuits are ideal of breakfasts and stand as an alternative to regular breads and biscuits. Toast is sliced bread which has been browned by exposure to dry heat.",
        categoryId: 'toast_khari',
        categoryMetaData: [
          {
            paragraph1: 'What could be a better companion to a delicious cup of tea than a crisp khari biscuit? India is a country that runs on tea. Cupcakes, macarons, and pies will never be able to replace the simple <a href="https://www.lovelocal.in/pc/bakery_baking_and_eggs/toast_khari" target="_blank">khari toast</a> that goes so well with afternoon tea. Biscuits and kharis are no longer so plain, and they now come in a variety of interesting new sorts and flavours to meet market demand.',
            question: 'Buy Khari biscuit online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: 'We offer you a variety of toasts and kharis from well known brands. Starting from <a href="https://www.lovelocal.in/products/satyam-dhanadal-868779" target="_blank">britannia toast</a> to <a href="https://www.lovelocal.in/products/tosita-maska-khari-868787" target="_blank">Tosita Maska Khari</a>, we serve it all! Get them delivered to you from your nearest local retailer',
            question: 'Buy Khari Online from LoveLocal',
            questionTag: 'h2',
            type: 'TYPE1',
          },
        ],
        children: [],
        displayName: 'Toast & Khari',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7925,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'A pie is a sweet or savory dish with a crust and a filling. ... Pies are served straight from the dish in which they were baked. A tart is a sweet or savory dish with shallow sides and only a bottom crust. Tart crusts are usually made from pastry dough: traditionally flour, unsalted butter, cold water, and sometimes sugar.',
        categoryId: 'muffins_pies_tarts',
        categoryMetaData: '',
        children: [],
        displayName: 'Muffins, Pies, Tarts',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7922,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Baking supplies is tools which are essential in baking daily foods. They are Measuring Cups and Spoons,Rubber Spatula,Pastry Brush,Rolling Pin,Kitchen Scissors,Dough Blender etc.',
        categoryId: 'baking_supplies',
        categoryMetaData: '',
        children: [],
        displayName: 'Baking Supplies',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7923,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 101,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'croissants_bagels',
        categoryMetaData: '',
        children: [],
        displayName: 'Croissants Bagels',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8066,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: "While a good shopping list is the key to a quick and painless shooping, a well-stocked pantry is the best way to ensure that you'll have most of what you'll need to cook. Oils, Vinegars and Condiments. Oils: canola oil, extra-virgin olive oil, toasted sesame.",
        categoryId: 'cooking_needs',
        categoryMetaData: '',
        children: [],
        displayName: 'Cooking Needs',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7924,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 6,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'baking_and_dessert_mixes',
        categoryMetaData: '',
        children: [],
        displayName: 'Baking And Dessert Mixes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8063,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'cakes_and_roll',
        categoryMetaData: '',
        children: [],
        displayName: 'Cakes And Roll',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8065,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 108,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Bakery and Baking',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8052,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153140388387_product___bakery_and_baking.webp',
    largeImage: '',
    maxDiscountPercent: 5,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'stationery_and_electrical',
    categoryMetaData: '',
    children: [
      {
        categoryDetailDescription: '',
        categoryId: 'batteries_and_electrical',
        categoryMetaData: '',
        children: [],
        displayName: 'Batteries & Electrical',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8197,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 111,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'basic_electrical',
        categoryMetaData: '',
        children: [],
        displayName: 'Basic Electrical',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 8199,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 112,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Stationery and Electrical',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8050,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153533540927_product___stationery_and_electrical.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: '',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: 'Dietary supplements are substances you might use to add nutrients to your diet or to lower your risk of health problems, like osteoporosis or arthritis. Dietary supplements come in the form of pills, capsules, powders, gel tabs, extracts, or liquids.',
    categoryId: 'health_and_medicine',
    categoryMetaData: [
      {
        paragraph1: 'Nowadays medicines and pharmaceuticals have become an inevitable essential in every household. But many medicines are not available everywhere and we have to hop from one pharmacy to another to get what we need. But do not worry anymore and get your <a href="https://www.lovelocal.in/pc/health-and-medicine" target="_blank">online medicine delivery</a> from LoveLocal!',
        question: 'Buy Medicine Online',
        questionTag: 'h1',
        type: 'TYPE1',
      },
      {
        paragraph1: 'You can access the <b>online pharmacy store</b> in just a click and get your required drugs and medicines delivered to your homes. You can sit in the comfort of your homes and <b>buy medicine</b> online without any hassle. We cover a wide range of medicines including diabetes, nutrition, sports and fitness and mother and baby care. We have a thorough awareness of your requirements and make every attempt to meet them with a diverse range of brands. All of these products are delivered to your doorstep from the nearest pharmacy or medical store. This ensures that you get your <b>online medicine delivery</b> on time.',
        paragraph2: 'Our <b>online pharmacy store</b> also offers <b>ayurvedic medicine online delivery</b> and over-the-counter medicine delivery which are sold by your local pharmacists and ayurvedic stores. So ditch the hectic trips to hundreds of pharmacies and get your <b>online medicine delivery</b> from LoveLocal now.',
        question: 'Get Online Medicine Delivery with LoveLocal',
        questionTag: 'h2',
        type: 'TYPE2',
      },
    ],
    children: [],
    displayName: 'Health & Medicine',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 7827,
    image: 'https://retailer-onboarding-kyc.s3.amazonaws.com/4a92ccee20211117-164242.jepg',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: 'https://retailer-onboarding-kyc.s3.amazonaws.com/6319ad0520211117-164246.jepg',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: 'Fruit, the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Thus, apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, all are fruits. Vegetables are parts of plants that are consumed by humans or other animals as food. The original meaning is still commonly used and is applied to plants collectively to refer to all edible plant matter, including the flowers, fruits, stems, leaves, roots, and seeds.',
    categoryId: 'fruits_and_vegetables',
    categoryMetaData: [
      {
        paragraph1: "Who doesn't love fresh fruits and vegetables? Vegetables are an important part of the Indian diet and nowadays most of us are preferring to <a href=\"https://www.lovelocal.in/pc/fruits-and-vegetables\" target=\"_blank\">buy fresh fruits and vegetables online</a>. While many fruits and vegetables can be found easily in <b>fruits and vegetable stores</b> around us, ordering digitally is easier. But who knows your diet better than local fruit and vegetable store owners? They are well aware of your taste and daily consumption of food. Through LoveLocal you can get <a href=\"https://www.lovelocal.in/pc/fruits-and-vegetables\" target=\"_blank\">online fruits and vegetables delivery</a> right from your nearest retailer.",
        paragraph2: 'Craving for mixed veg sabzi? Order everything from <a href="https://www.lovelocal.in/products/potato-673156" target="_blank">potatoes</a>, <a href="https://www.lovelocal.in/products/cauliflower-715347" target="_blank">cauliflower</a>, onion, carrots, capsicum, beetroot, peas, <a href="https://www.lovelocal.in/products/broccoli-715437" target="_blank">broccoli</a>, tomato and cabbage. We have the biggest shopping catalogue with thousands of products that will come in handy in any dish you make. You can order <b>fresh fruits and vegetables online</b> from LoveLocal whenever you crave your next veg dish!',
        question: 'Buy fruits and vegetables online',
        questionTag: 'h1',
        type: 'TYPE2',
      },
      {
        paragraph1: 'You can also buy exotic fruits and vegetables such as broccoli, blueberries and imported cherries. Apart from getting the freshest products, you’ll also get your <b>online fruits and vegetables delivery</b> at the most affordable prices at LoveLocal, as your local retailers will not charge unnecessary taxes from you. No matter where you order from, the quality and price will remain the best. So do not wait anymore and buy fruits and vegetables online from LoveLocal.',
        question: 'Buy exotic fruits and vegetables from LoveLocal',
        questionTag: 'h2',
        type: 'TYPE1',
      },
    ],
    children: [
      {
        categoryDetailDescription: 'Exotic vegetables are commercially cultivated in green shade net houses or poly houses. These veggies are imported from outside India.',
        categoryId: 'exotic',
        categoryMetaData: '',
        children: [],
        displayName: 'Exotic',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7836,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 109,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Fresh vegetables have a specific color, firmness and smell.Some seasonally available vegetables are: January - June: onions, potatoes, asparagus, greens, lettuce, peas, radishes, spinach and turnips. July- August: beans, beets, broccoli, carrots, cauliflower, cucumbers, zucchini, summer squash and corn.',
        categoryId: 'fresh_vegetables',
        categoryMetaData: '',
        children: [],
        displayName: 'Fresh Vegetables',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7834,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 113,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Herbs are plants with savory or aromatic properties that are used for flavoring and garnishing food, for medicinal purposes, or for fragrances; excluding vegetables and other plants consumed for macronutrients. Culinary use typically distinguishes herbs from spices.',
        categoryId: 'herbs',
        categoryMetaData: '',
        children: [],
        displayName: 'Herbs',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7839,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 111,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Most fresh fruits are picked before they are ripe. This allows them time to fully ripen during transportation. It also gives them less time to develop a full range of vitamins, minerals and natural antioxidants.',
        categoryId: 'fresh_fruits',
        categoryMetaData: '',
        children: [],
        displayName: 'Fresh Fruits',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7835,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 114,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Fruits & Vegetables',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 7814,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023175243852416_product___fruits__vegetables_1.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: 'https://retailer-onboarding-kyc.s3.amazonaws.com/7461c4fb20211117-164855.jepg',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: 'Meat is animal flesh that is eaten as food. Humans have hunted and killed animals for meat since prehistoric times. The advent of civilization allowed the domestication of animals such as chickens, sheep, rabbits, pigs and cattle. Seafood is any form of sea life regarded as food by humans, prominently including fish and shellfish. Fish,molluscs,prawns,crabs,lobsters etc are some common sea foods.Seafood is an excellent source of lean, high quality, easily digested protein. Also Seafood is low in saturated fat and sodium and is a rich source of many essential vitamins and minerals.',
    categoryId: 'meat_and_seafood',
    categoryMetaData: [
      {
        paragraph1: 'Do you love indulging in butter chicken or fish cutlets? We totally understand how important it is to get fresh <a href="https://www.lovelocal.in/pc/meat-and-seafood" target="_blank">meat and seafood</a>. When it comes to the meat we eat in India, quality is a key concern. Who can sell fresh and best-quality meat apart from your local meat shops? LoveLocal takes care of everything by providing the highest quality of <b>fresh meat online</b> through local retailers and fish and meat shop owners.',
        paragraph2: "Through our <b>fresh meat delivery</b>, you'll get every type of meat you're looking for. It doesn't matter if it's fish, poultry, or mutton. Under fresh chicken we have different cuts of chicken starting from <a href=\"https://www.lovelocal.in/products/chicken-keema-1676715\" target=\"_blank\">kheema</a> to <a href=\"https://www.lovelocal.in/products/chicken-boneless-605357\" target=\"_blank\">boneless</a>, <a href=\"https://www.lovelocal.in/products/chicken-lollipop-1676733\" target=\"_blank\">lollypop</a> and <a href=\"https://www.lovelocal.in/products/chicken-skinless-605366\" target=\"_blank\">skinless</a> depending on the requirement of your dish. Same goes for mutton where you can find boneless, curry cut and mutton kheema.",
        question: 'Buy fresh meat and seafood online - Fresh meat delivery near me',
        questionTag: 'h1',
        type: 'TYPE2',
      },
      {
        paragraph1: 'If you want to <b>buy fish online</b>, LoveLocal is here at your service. You can get fresh fish and seafood delivered to your doorstep from the nearest local seafood store. We have a wide range of fish varieties to choose from, like Rohu, Pomfret, Katla and different types of shrimps and prawns that too with tailed and detailed options. So what are you waiting for? Download the app now and order the best seafood and meat near you!',
        question: 'Fresh seafood and online fish delivery',
        questionTag: 'h2',
        type: 'TYPE1',
      },
    ],
    children: [
      {
        categoryDetailDescription: 'chicken is supremely advantageous for your body. Filled with vitamin, mineral and protein, chicken promotes brain development, strengthens your bones, aids in weight loss, builds muscle and helps in a healthy heart.',
        categoryId: 'chicken',
        categoryMetaData: '',
        children: [],
        displayName: 'Chicken',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7948,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Meat & Seafood',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 7828,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153655120629_product___meat_and_seafood.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: 'https://retailer-onboarding-kyc.s3.amazonaws.com/c68990af20211117-163941.jepg',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: 'Baby Products are products intended to be used on infants and children under the age of three. Baby products are specially formulated to be mild and non-irritating and use ingredients that are selected for these properties. Baby products include baby shampoos and baby lotions, oils, powders and creams.',
    categoryId: 'maternity_and_baby_care',
    categoryMetaData: [
      {
        paragraph1: "Babies require a lot of attention and care. However, you'll be astonished at how many items they actually require. Aside from the obvious love, caring for a baby takes a lot of time, effort, and <a href=\"https://www.lovelocal.in/pc/maternity-and-baby-care/baby-skin-hair-care\" target=\"_blank\">baby care products</a>. There is a wide range and diversity of baby care products available, and various brands sell them. You no longer need to leave your house to shop for them; instead, you can purchase the best <b>baby products online</b> from your nearest local retailer only through <a href=\"https://www.lovelocal.in/\" target=\"_blank\">LoveLocal</a>.",
        question: 'Buy Baby Products Online',
        questionTag: 'h1',
        type: 'TYPE1',
      },
      {
        paragraph1: "You'll find all the quality baby care products you need at your nearest retailer store available on LoveLocal, from baby food like cerelac to the <a href=\"https://www.lovelocal.in/pc/maternity-and-baby-care\" target=\"_blank\">best baby skincare products</a> like baby lotion. Baby diapers are likely to be one of the most important baby care items you'll need. And the smartest way to stock up on diapers is to order them online. Many popular brands offer baby diapers in a variety of sizes. We have everything from Mamy Poko Pants and <a href=\"https://www.lovelocal.in/products/huggies-wonder-pants-small-869209\" target=\"_blank\">Huggies</a> diapers to Johnson's baby diapers. You should also order some baby wipes while you're out shopping for diapers. You'll need a lot of diaper-changing supplies, including baby wipes.",
        question: 'Baby product List available online',
        questionTag: 'h2',
        type: 'TYPE1',
      },
      {
        paragraph1: "We also have a huge selection of <a href=\"https://www.lovelocal.in/pc/maternity-and-baby-care\" target=\"_blank\">maternity care products</a>. We ensure that all the new mothers get all their necessary products on our platform. You can cross off every item on your <b>maternity products list</b> and order them directly from LoveLocal. Starting from your newborn's products to the best quality formula and supplements for a new mother, we have it all. So what are you waiting for? Order the best maternity and baby products from LoveLocal!",
        question: 'Buy Maternity Care Products Online',
        questionTag: 'h2',
        type: 'TYPE1',
      },
    ],
    children: [
      {
        categoryDetailDescription: 'It is a means of achieving cleanliness by washing away dead skin cells, dirt, and soil and as a preventative measure to reduce the incidence and spread of disease. It also may reduce body odors, however, some people note that may not be so necessary as commonly thought.',
        categoryId: 'bath_hygiene',
        categoryMetaData: '',
        children: [],
        displayName: 'Bath & Hygiene',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7906,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'A diaper or a nappy is a type of underwear that allows the wearer to urinate or defecate without using a toilet, by absorbing or containing waste products to prevent soiling of outer clothing or the external environment. And A wet wipe, also known as a wet towel or a moist towelette, disinfecting wipe, or a baby wipe in specific circumstances, is a small to medium-sized moistened piece of plastic or cloth that often comes.',
        categoryId: 'diapers_wipes',
        categoryMetaData: '',
        children: [],
        displayName: 'Diapers & Wipes',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7904,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 107,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: '',
        categoryId: 'feeding_nursing',
        categoryMetaData: '',
        children: [],
        displayName: 'Feeding & Nursing',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7908,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 102,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Baby food is any soft, easily consumed food other than breastmilk or infant formula that is made specifically for human babies between four and six months and two years old.',
        categoryId: 'baby_food',
        categoryMetaData: [
          {
            paragraph1: "You must provide your kid with the highest quality food that contains all of the vital nutrients for his development. Proteins and nutrients must be provided in baby food, and cerelac baby food is one of the best options. It is high in probiotics and iron, both of which are essential for your baby's growth. Because it's soft and liquid, it'll be easy for your baby to eat. Infant cereal is another type of baby food supplement that comprises readily digested cereals like rice, barley, or oats. Their digestive systems are weak between the ages of six and twelve months, and they have difficulty digesting grains like wheat and maize.",
            question: 'Buy baby food online',
            questionTag: 'h1',
            type: 'TYPE1',
          },
          {
            paragraph1: "These nutritious grains should only be included in your baby's diet once they've begun eating solid meals, not before. To provide your infant with the greatest vitamins, you must first speak with a doctor and then proceed. You may look for information on the finest baby food in India on the internet. LoveLocal offers you a variety of options for any brand you wish to go with. You may choose from the list of baby food products and choose one that is appropriate for your child.",
            paragraph2: "LoveLocal provides the greatest deals on a variety of baby food supplements and eatables. You do not need to travel anywhere in India to find the greatest baby food items. Rather than wandering through stores and shops, simply browse the alternatives we've provided and purchase the healthiest goods for your infant. The list has a variety of brands; choose the finest one and place your order immediately.",
            question: 'Buy baby food products on LoveLocal',
            questionTag: 'h2',
            type: 'TYPE2',
          },
        ],
        children: [],
        displayName: 'Baby Food',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7911,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 105,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'This includes baby diapers, soaps, shampoo, lotion, and creams, among others. The other products include baby wipes, baby oil, baby toothbrush, baby toothpaste, baby ear, and nail care products.',
        categoryId: 'baby_accessories',
        categoryMetaData: '',
        children: [],
        displayName: 'Baby Accessories',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7909,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 101,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: "Gently wash his hair with a tear-free, baby shampoo and wash out with warm water. If your baby has tight curls that can easily tangle, opt for a baby-safe conditioner that will nourish the hair and help detangle. A small amount of extra-virgin olive oil can also be combed through your child's hair for extra moisture.",
        categoryId: 'baby_skin_hair_care',
        categoryMetaData: '',
        children: [],
        displayName: 'Baby Skin & Hair Care',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7910,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 103,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'Infant formula, baby formula or just formula or baby milk, infant milk or first milk, is a manufactured food designed and marketed for feeding to babies and infants under 12 months of age, usually prepared for bottle-feeding or cup-feeding from powder or liquid.',
        categoryId: 'food_formula',
        categoryMetaData: '',
        children: [],
        displayName: 'Food & Formula',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7905,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Maternity & Baby Care',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 7822,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153619451102_product___baby_care.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: 'https://retailer-onboarding-kyc.s3.amazonaws.com/87a90ae920211117-164234.jepg',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'ayurvedic',
    categoryMetaData: '',
    children: [],
    displayName: 'Ayurvedic',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 8025,
    image: 'https://retailer-onboarding-kyc.s3.amazonaws.com/70233d4020230105-163252.jepg',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: 'https://retailer-onboarding-kyc.s3.amazonaws.com/bd4f698720230105-163256.jepg',
    priority: 0,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: ' The care and medical treatment of pets.A pet accessories is products used for pets like GPS Tracker,Pet Wash,Carpet Cleaner,Bath Towel,Coat,Safety Collar etc.',
    categoryId: 'pet_supplies',
    categoryMetaData: [
      {
        paragraph1: 'Our furry friends require even more care and attention than us! But many  times we are unable to find the best pet supplies and have to go from store to store. But do not worry anymore and get the best <a href="https://www.lovelocal.in/pc/pet-supplies" target="_blank">pet care products</a> on LoveLocal. We have a diverse range of <b>pet supplies stores</b> associated with us who will ensure you get the best products on time. You can order all of your <b>pet products online</b> and get them delivered to your doorstep.',
        question: 'Buy pet care products online',
        questionTag: 'h1',
        type: 'TYPE1',
      },
      {
        paragraph1: 'Be it a dog or cat, we have branded pet supplies for all. On LoveLocal you can order everything from dog treats, calcium bones, grain-free dog food to duck slices, chicken and liver chunks and dog supplements. Apart from that, we have delicious treats for your cats that will keep them in a great mood all day. We have special chicken sandwiches, chicken strips, salmon and a different set of <b>pet supplies</b> for both adult cats and kittens. Some of the most famous brands you can order from are Drools, <a href="https://www.lovelocal.in/products/whiskas-chicken-in-gravy-wet-cat-food-271153" target="_blank">Whiskas</a>, Kittos, Lets Bite, Pedigree, First Meow, Nootie, Canine and Cesar.',
        question: 'Buy pet supplies online',
        questionTag: 'h2',
        type: 'TYPE1',
      },
      {
        paragraph1: 'Apart from delicious pet food for your dogs and cats, you can also find premium quality leashes and collars for your pets. You just have to select the colour and type of leash you need from the wide range of options available and it will get delivered to you in no time. We have padded and non-padded collars, nylon printed harness, nylon choke collar, printed leashes and padded handle chains available. So what are you waiting for? Order the best <b>pet care products online</b> from LoveLocal!',
        question: 'Get the best pet care products online ',
        questionTag: 'h2',
        type: 'TYPE1',
      },
    ],
    children: [
      {
        categoryDetailDescription: 'A pet shop or pet store is a retail business which sells different kinds of animals to the public. A variety of animal supplies and pet accessories are also sold in pet shops. The products sold include: food, treats, toys, collars, leashes, cat litter, cages and aquariums.',
        categoryId: 'all_pet_supplies',
        categoryMetaData: '',
        children: [],
        displayName: 'All Pet Supplies',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7879,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 104,
        productCount: 0,
        showMrp: false,
      },
      {
        categoryDetailDescription: 'A pet accessories is products used for pets like GPS Tracker,Pet Wash,Carpet Cleaner,Bath Towel,Coat,Safety Collar etc.',
        categoryId: 'pet_accessories',
        categoryMetaData: '',
        children: [],
        displayName: 'Accessories',
        freshnessApplicable: false,
        freshnessDefault: false,
        id: 7878,
        image: '',
        largeImage: '',
        maxDiscountPercent: 0,
        mrpApplicable: true,
        needed: true,
        placeholderImage: '',
        priority: 106,
        productCount: 0,
        showMrp: false,
      },
    ],
    displayName: 'Pet Care',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 7819,
    image: 'https://lovelocal-store-front.s3.amazonaws.com/11202023153503267840_product___pet_care.webp',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: 'https://retailer-onboarding-kyc.s3.amazonaws.com/6a89fa4820211117-165226.jepg',
    priority: 6,
    productCount: 0,
    showMrp: false,
  },
  {
    categoryDetailDescription: '',
    categoryId: 'others',
    categoryMetaData: '',
    children: [],
    displayName: 'Other',
    freshnessApplicable: false,
    freshnessDefault: false,
    id: 7982,
    image: 'https://retailer-onboarding-kyc.s3.amazonaws.com/1bc24eb920211117-164921.jepg',
    largeImage: '',
    maxDiscountPercent: 0,
    mrpApplicable: true,
    needed: true,
    placeholderImage: 'https://retailer-onboarding-kyc.s3.amazonaws.com/1bc24eb920211117-164921.jepg',
    priority: 100,
    productCount: 0,
    showMrp: false,
  },
];

export default productCategories;
