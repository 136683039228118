/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */

import { getToken } from 'app/utilities/Storage';

const { criteo_q = [] } = window;

const deviceType = /iPad/.test(navigator.userAgent) ? 't' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd';

const siteTypeData = { event: 'setSiteType', type: deviceType };

const PARTNER_ID = process.env.REACT_APP_ENV === 'production' ? 106064 : '';

export const cqSetAccount = (email = 'marketing@mpaani.com') => {
  criteo_q.push(
    {
      event: 'setAccount', account: PARTNER_ID,
    },
    { event: 'setCustomerId', id: getToken() || 'Guest' },
    { event: 'setEmail', email, hash_method: 'sha256' },
    siteTypeData,
  );
};

export const cqVisitTag = () => {
  criteo_q.push(
    { event: 'viewPage' },
    siteTypeData,
  );
};

export const cqHomepageTag = () => {
  criteo_q.push(
    { event: 'viewHome' },
    siteTypeData,
  );
};

export const cqSearchProductListing = (item = [], category = '', keywords = '') => {
  const cqItem = item.map((cqi) => (cqi.item_id));
  criteo_q.push(
    {
      event: 'viewList',
      item: cqItem,
      category,
      keywords,
      page_number: 2,
      filters: [],
    },
    siteTypeData,
  );
};

export const cqProductTag = (productId, price) => {
  criteo_q.push(
    {
      event: 'viewItem',
      item: productId,
      price,
      availability: true,
    },
    siteTypeData,
  );
};

export const cqAddToCart = (productId, price, quantity) => {
  criteo_q.push(
    {
      event: 'addToCart',
      item: [
        { id: productId, price, quantity },
      ],
    },
    siteTypeData,
  );
};

export const cqViewBasket = (cartItems = []) => {
  const cqItem = cartItems.map((cqi) => ({
    ...cqi,
    id: cqi.item_id,
  }));
  criteo_q.push(
    {
      event: 'viewBasket',
      item: cqItem,
    },
    siteTypeData,
  );
};

export const cqSalesTag = (transactionId, cartItems = []) => {
  const cqItem = cartItems.map((cqi) => ({
    id: cqi.item_id,
    price: cqi.price,
    quantity: cqi.quantity,
  }));
  criteo_q.push(
    {
      event: 'trackTransaction',
      id: transactionId,
      new_customer: '',
      deduplication: '',
      item: cqItem,
    },
    siteTypeData,
  );
};
