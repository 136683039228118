import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import CustomRoundBadge from 'app/components/common/CustomRoundBadge';
import { getCDNImageUrl } from '../../utilities/Utils';
import CustomImage from '../../components/common/CustomImage';
import RoundedCartCounter from '../../components/derived/cart/RoundedCartCounter';

function GuestUserCartProduct(props) {
  const {
    product, store, isCart, isCheckout, reorder,
    outOfStock,
    language,
  } = props;

  let offerTag = '';
  const offPer = ((product.mrp - product.sellingPrice) / product.mrp) * 100;
  offerTag = `${Math.ceil(offPer)}% OFF`;

  return (
    <Col
      id="cart-product"
      xs={24}
      lg={reorder || outOfStock ? 12 : 24}
      className={`px-0 py-4 ${isCheckout ? 'fs-6' : ''}`}
    >
      <Row
        className={`mx-0 flex-nowrap ${reorder ? 'border p-3' : ''}`}
      >
        <Col
          xs={4}
          className="px-0"
        >
          {
            offerTag && product.mrp !== product.sellingPrice ? (
              <div
                className="top-saving-offer-tag-badge"
                style={{ zIndex: 1 }}
              >
                <CustomRoundBadge
                  badgeText={offerTag}
                  isRibbon
                  isSmall
                  badgeType="ROUND_OFFER"
                />
              </div>
            ) : null
          }
          <Col
            className="p-0 cart-product-image"
          >
            <CustomImage
              imageUrl={getCDNImageUrl(product.image)}
              imageType="PRODUCT"
              imageStyle="mh-100 mw-100"
              imageAlt={product.displayName}
              imageTitle={product.displayName}
            />
          </Col>
        </Col>
        <Col
          xs="auto"
          className="flex-grow-1 flex-shrink-1 fs-5 px-2"
        >
          <Row
            className="mx-0  text-truncate-2 word-break"
            title={product.displayName}
          >
            {product.displayName}
          </Row>
          <Row
            className="mx-0"
          >
            <span
              className="text-medium mr-2 word-break"
            >
              {product.measurementDisplayText}
            </span>
          </Row>
        </Col>
        <Col
          xs={5}
          className="px-0 d-flex justify-content-center"
        >
          <Row className="mx-0">
            {
              !outOfStock && (
                <Col
                  xs="auto"
                  className="px-0"
                >
                  <RoundedCartCounter
                    language={language}
                    productInfo={{
                      storeName: store.displayName,
                      productName: product.displayName,
                      sellingPrice: product.sellingPrice,
                      mrp: product.mrp,
                      imageUrl: product.image,
                      measurementDisplayText: product.measurementDisplayText,
                    }}
                    storeCode={store.code}
                    productId={product.id}
                    quantity={product.quantity}
                    isCart={isCart}
                    isCheckout={isCheckout}
                  />
                </Col>
              )
            }
          </Row>
        </Col>
        <Col
          xs={5}
          className="d-flex flex-column align-items-end justify-content-start px-2"
          style={{
            // maxWidth: '58px',
          }}
        >
          <Row
            className="pb-1 mx-0"
          >
            <b>
              <span
                className=""
              >
                {`\u20B9 ${Math.ceil(product.sellingPrice)}`}
              </span>
            </b>
          </Row>
          {
            Math.ceil(product.mrp) !== Math.ceil(product.sellingPrice) && (
              <Row className="mx-0">
                <span
                  className="text-medium text-strike-through"
                >
                  <del>
                    {`\u20B9 ${Math.ceil(product.mrp)}`}
                  </del>
                </span>
              </Row>
            )
          }
        </Col>
      </Row>
    </Col>
  );
}

GuestUserCartProduct.propTypes = {
  product: PropTypes.shape({
    displayName: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    mrp: PropTypes.number,
    quantity: PropTypes.number,
    sellingPrice: PropTypes.number,
    measurementDisplayText: PropTypes.string,
    storeName: PropTypes.string,
  }).isRequired,
  store: PropTypes.shape({
    code: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  isCheckout: PropTypes.bool,
  isCart: PropTypes.bool,
  outOfStock: PropTypes.bool,
  cartItems: PropTypes.shape({}),
  reorder: PropTypes.bool,
  requestsProcessing: PropTypes.shape({}),
  // isMobile: PropTypes.bool,
  language: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

GuestUserCartProduct.defaultProps = {
  isCheckout: false,
  isCart: false,
  outOfStock: false,
  requestsProcessing: null,
  reorder: false,
  // isMobile: false,
  cartItems: null,
  language: 'en',
  history: null,
};

export default GuestUserCartProduct;
