import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill1, fill2, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill={fill1}
      fillRule="evenodd"
      d="M10 19c4.97 0 9-4.03 9-9s-4.03-9-9-9-9 4.03-9 9 4.03 9 9 9zm0 1c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
      clipRule="evenodd"
    />
    <path
      fill={fill2}
      fillOpacity=".72"
      fillRule="evenodd"
      d="M10 19c4.97 0 9-4.03 9-9s-4.03-9-9-9-9 4.03-9 9 4.03 9 9 9zm0 1c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
      clipRule="evenodd"
    />
    <path
      fill={fill1}
      d="M14.95 11.39h-3.7v3.98H8.73v-3.98H5.05V9.17h3.68V5.21h2.52v3.96h3.7v2.22z"
    />
    <path
      fill={fill2}
      fillOpacity=".72"
      d="M14.95 11.39h-3.7v3.98H8.73v-3.98H5.05V9.17h3.68V5.21h2.52v3.96h3.7v2.22z"
    />
  </svg>

);

Svg.propTypes = {
  fill1: PropTypes.string.isRequired,
  fill2: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
