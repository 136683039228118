import React from 'react';
import PropTypes from 'prop-types';

const Work = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M6.857 17.688V6.313h-.214a.636.636 0 0 1-.455-.193.663.663 0 0 1 0-.928A.636.636 0 0 1 6.643 5h10.714c.17 0 .334.07.455.192a.663.663 0 0 1 0 .928.636.636 0 0 1-.455.192h-.214v11.375h.214c.17 0 .334.07.455.193a.663.663 0 0 1 0 .928.636.636 0 0 1-.455.192h-3a.636.636 0 0 1-.454-.192.663.663 0 0 1-.189-.464v-2.188a.663.663 0 0 0-.188-.464.636.636 0 0 0-.455-.192H10.93c-.17 0-.334.07-.455.192a.663.663 0 0 0-.188.464v2.188c0 .174-.068.34-.189.464a.636.636 0 0 1-.454.192h-3a.636.636 0 0 1-.455-.192.663.663 0 0 1 0-.928.636.636 0 0 1 .455-.192h.214ZM9.43 8.063c0-.117.045-.228.125-.31a.424.424 0 0 1 .303-.128h.857c.114 0 .223.046.303.128.08.082.126.193.126.31v.874a.442.442 0 0 1-.126.31.424.424 0 0 1-.303.128h-.857a.424.424 0 0 1-.303-.128.442.442 0 0 1-.125-.31v-.874Zm.428 3.062a.424.424 0 0 0-.303.128.442.442 0 0 0-.125.31v.874c0 .117.045.228.125.31.08.082.19.128.303.128h.857a.424.424 0 0 0 .303-.128.442.442 0 0 0 .126-.31v-.874a.442.442 0 0 0-.126-.31.424.424 0 0 0-.303-.128h-.857Zm3-3.063c0-.116.045-.227.126-.309a.424.424 0 0 1 .303-.128h.857c.114 0 .223.046.303.128.08.082.125.193.125.31v.874a.442.442 0 0 1-.125.31.424.424 0 0 1-.303.128h-.857a.424.424 0 0 1-.303-.128.442.442 0 0 1-.126-.31v-.874Zm.429 3.063a.424.424 0 0 0-.303.128.442.442 0 0 0-.126.31v.874c0 .117.045.228.126.31.08.082.189.128.303.128h.857a.424.424 0 0 0 .303-.128.442.442 0 0 0 .125-.31v-.874a.442.442 0 0 0-.125-.31.424.424 0 0 0-.303-.128h-.857Z"
      clipRule="evenodd"
    />
  </svg>
);

Work.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Work;
