import Regex from './Regex';
import Errors from './ErrorStrings';

const validate = (field, value) => {
  switch (field) {
    case 'phoneNumber': {
      const regex = new RegExp(`^${Regex[field]}{10}$`);
      if (value === '') {
        return Errors[field].empty;
      }
      if (value.toString().length !== 10) {
        return Errors[field].length;
      }
      if (value.toString().match(regex) === null) {
        return Errors[field].valid;
      }
      return null;
    }

    case 'otp': {
      const regex = new RegExp(`^${Regex[field]}{4}$`);
      if (value === '') {
        return Errors[field].empty;
      }
      if (value.toString().match(regex) === null) {
        return Errors[field].valid;
      }
      if (value.toString().length !== 4) {
        return Errors[field].length;
      }
      return null;
    }

    case 'flatNumber': {
      if (value.trim() === '') {
        return Errors[field].empty;
      }
      return null;
    }

    case 'buildingName': {
      if (value.trim() === '') {
        return Errors[field].empty;
      }
      return null;
    }

    case 'streetName': {
      if (value.trim() === '') {
        return Errors[field].empty;
      }
      return null;
    }

    case 'localityName': {
      if (value.trim() === '') {
        return Errors[field].empty;
      }
      return null;
    }

    case 'pinCode': {
      const regex = new RegExp(`^${Regex[field]}{6}$`);
      if (value === '') {
        return Errors[field].empty;
      }
      if (value.toString().length !== 6) {
        return Errors[field].length;
      }
      if (value.toString().match(regex) === null) {
        return Errors[field].valid;
      }
      return null;
    }

    case 'addressType': {
      if (value.trim() === '') {
        return Errors[field].empty;
      }
      return null;
    }

    case 'lastName':
    case 'firstName': {
      if (value === '') {
        return Errors[field].empty;
      }
      return null;
    }

    case 'email': {
      const regex = new RegExp(Regex.email);
      if (value && !(regex.test(String(value).toLowerCase()))) {
        return Errors[field].invalid;
      }
      return null;
    }

    case 'selectedAddressType': {
      if (value === '') {
        return Errors[field].empty;
      }
      return null;
    }

    default: return null;
  }
};

export const validateRegex = (field, value) => {
  switch (field) {
    case 'phoneNumber': {
      const regex = new RegExp(`^${Regex[field]}{0,10}$`);
      if (value.toString().match(regex)) {
        return true;
      }
      return false;
    }
    case 'otp': {
      const regex = new RegExp(`^${Regex[field]}{0,4}$`);
      if (value.toString().match(regex)) {
        return true;
      }
      return false;
    }
    case 'pinCode': {
      const regex = new RegExp(`^${Regex[field]}{0,6}$`);
      if (value.toString().match(regex)) {
        return true;
      }
      return false;
    }
    default: return true;
  }
};

const validateForm = (currentErrors, fieldsObj) => {
  const errors = currentErrors;
  const fields = Object.keys(fieldsObj);
  fields.forEach((field) => {
    const error = validate(field, fieldsObj[field]);
    if (error) {
      errors[field] = error;
    } else {
      delete errors[field];
    }
  });
  return errors;
};

export default validateForm;
