import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomRoundBadge from 'app/components/common/CustomRoundBadge';
import CustomImage from 'app/components/common/CustomImage';
import { getCDNImageUrl, getSeoProductUrl } from 'app/utilities/Utils';
import RoundedCartCounter from 'app/components/derived/cart/RoundedCartCounter';

const ProductList = (props) => {
  const {
    cartItems, language, onSuccess, item, relatedProduct, offerProduct, popularProducts,
    eventTrigger,
  } = props;

  const [index, setIndex] = useState(0);

  const discount = item[index].mrp !== -1 && item[index].mrp > item[index].sellingPrice
    ? item[index].mrp - item[index].sellingPrice : 0;

  const quantity = !!cartItems && !!cartItems[item[index].retailerProductId]
    ? cartItems[item[index].retailerProductId].quantity : 0;

  const { pathName, state, searchParams } = getSeoProductUrl(
    item[index].libraryProductId, item[index].name, item[index].store.id,
  );
  const isSingleVariant = item.length === 1;

  const { inStock } = item[index];

  return (
    <>
      {
        (relatedProduct || offerProduct) && (
          <Col
            className="px-0 related-product"
          >
            <img
              alt=""
              src={`${relatedProduct ? '/images/related_product.png' : '/images/offer_product.png'}`}
              className="mh-100"
            />
          </Col>
        )
      }
      <Col
        id=""
        xs="auto"
        className="store-top-saving-offer mr-6 text-wrap border border-radius-8 pt-3 px-3"
      >
        <Row
          className="mx-0 align-items-center top-saving-card"
        >
          <Col
            className="px-0"
            xs={24}
          >
            <Row
              className={`${inStock ? '' : 'opacity-50'} mx-0`}
            >
              <Link
                to={{ pathname: pathName, state, search: searchParams }}
                onClick={() => {
                  eventTrigger();
                }}
                className={`${inStock ? '' : 'pointer-events-none'}`}
              >
                <Col
                  className="px-0 top-saving-image"
                >
                  {
                    item[index].offerPercent > 0 && (
                      <div
                        className="past-product-save-tag"
                      >
                        <CustomRoundBadge
                          badgeText={`${parseInt(item[index].offerPercent, 10)}% OFF`}
                          isRibbon
                          badgeType="ROUND_OFFER"
                        />
                      </div>
                    )
                  }
                  {!inStock && (
                    <div
                      className="fs-6 text-white text-center out-of-stock-box w-100 p-1 border-radius-8 font-weight-bold"
                    >
                      OUT OF STOCK
                    </div>
                  )}
                  <CustomImage
                    imageStyle="mw-100 mh-100 mostSearchProduct"
                    imageUrl={getCDNImageUrl(item[index].image)}
                    imageType="PRODUCT"
                    imageAlt={item[index].name}
                    imageTitle={item[index].name}
                  />
                  {(item[index].previouslyBought) && (
                    <div
                      key={item[index].retailerProductId}
                      className="fs-6 text-black text-center previously-bought bg-green-light w-100 p-1 border-radius-8 font-weight-bold"
                    >
                      {item[index].previouslyBought}
                    </div>
                  )}
                </Col>
              </Link>
            </Row>
            <Row
              className={`${inStock ? '' : 'opacity-50'} mx-0 pt-3 pb-2`}
            >
              <Col
                className="px-0 text-truncate-22 fs-4 font-weight-bold"
                title={item[index].name}
              >
                {item[index].name}
              </Col>
            </Row>
            <Row
              className="mx-0 pb-3"
            >
              <Col
                xs={24}
                className="px-0"
              >
                <span
                  className={`${inStock ? '' : 'opacity-50'} fs-4 font-weight-bold text-primary`}
                >
                  {`\u20B9 ${Math.ceil(item[index].sellingPrice)}`}
                </span>
                {
                  (
                    discount > 0
                  ) && (
                    <span
                      className={`${inStock ? '' : 'opacity-50'} pl-2 fs-5 text-black-50 text-strike-through`}
                    >
                      <del>
                        {`\u20B9 ${Math.ceil(item[index].mrp)}`}
                      </del>
                    </span>
                  )
                }
                {
                  popularProducts && item[index].store.name && (
                    <Row
                      className={`${inStock ? '' : 'opacity-50'} mx-0 mb-3 text-medium fs-6 text-truncate-1`}
                    >
                      {item[index].store.name}
                    </Row>
                  )
                }
                {
                  isSingleVariant ? (
                    <div
                      className={`cart-counter text-center border ${inStock ? '' : 'opacity-50'}
                      fs-5 border-radius-8 px-2 py-1 bg-white mb-3 font-weight-bold mt-2`}
                    >
                      {item[index].measurementDisplayText}
                    </div>
                  ) : (
                    <div className="cart-counter border text-center fs-5 border-radius-8 py-1 bg-white mb-3 mt-2 font-weight-bold">
                      <select
                        name={`search-product-variant form-control ${inStock ? '' : 'opacity-50'}`}
                        onChange={(event) => {
                          setIndex(event.target.value);
                        }}
                        className="variant-product"
                      >
                        {
                          item.map((variant, indexNo) => (
                            <option
                              key={variant.retailerProductId}
                              value={indexNo}
                              className={`font-weight-black text-center ${inStock ? '' : 'opacity-50'}`}
                            >
                              {item[indexNo].measurementDisplayText || '-- --'}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  )
                }

                <Row
                  className="mx-0"
                >
                  <Col
                    xs={24}
                    className="px-0"
                  >
                    <RoundedCartCounter
                      language={language}
                      productInfo={{
                        storeName: item[index].store.name,
                        productName: item[index].name,
                        sellingPrice: Math.ceil(item[index].sellingPrice),
                        mrp: Math.ceil(item[index].mrp),
                        imageUrl: item[index].image,
                        measurementDisplayText: item[index].measurementDisplayText,
                      }}
                      storeCode={item[index].store.id}
                      productId={item[index].retailerProductId}
                      libProductId={item[index].libraryProductId}
                      quantity={quantity}
                      onSuccess={onSuccess}
                      inStock={inStock}
                      previouslyBought={item[index].previouslyBought}
                      page="Recent_Search"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

ProductList.propTypes = {
  cartItems: PropTypes.shape({}),
  item: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  language: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  relatedProduct: PropTypes.bool,
  offerProduct: PropTypes.bool,
  popularProducts: PropTypes.bool,
  eventTrigger: PropTypes.func,
};

ProductList.defaultProps = {
  cartItems: null,
  onSuccess: () => {},
  relatedProduct: false,
  offerProduct: false,
  popularProducts: false,
  eventTrigger: () => {},
};

export default ProductList;
