import React from 'react';

const Svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_825_31747)">
      <path d="M21.5501 1.89809C22.9923 0.780757 25.0077 0.780756 26.4499 1.89809L29.0934 3.94626C29.828 4.5154 30.7378 4.81102 31.6666 4.78236L35.0092 4.67919C36.8327 4.62292 38.4632 5.80756 38.9731 7.55916L39.9079 10.77C40.1677 11.6622 40.73 12.4362 41.4983 12.9589L44.2631 14.8402C45.7714 15.8665 46.3942 17.7833 45.7772 19.5001L44.6462 22.6472C44.3319 23.5217 44.3319 24.4783 44.6462 25.3528L45.7772 28.4999C46.3942 30.2167 45.7714 32.1335 44.2631 33.1598L41.4983 35.0411C40.73 35.5638 40.1677 36.3378 39.9079 37.23L38.9731 40.4408C38.4632 42.1924 36.8327 43.3771 35.0092 43.3208L31.6666 43.2176C30.7378 43.189 29.828 43.4846 29.0934 44.0537L26.4499 46.1019C25.0077 47.2192 22.9923 47.2192 21.5501 46.1019L18.9066 44.0537C18.172 43.4846 17.2622 43.189 16.3334 43.2176L12.9908 43.3208C11.1673 43.3771 9.53681 42.1924 9.02685 40.4408L8.09206 37.23C7.8323 36.3378 7.26999 35.5638 6.50171 35.0411L3.73688 33.1598C2.22859 32.1335 1.60579 30.2167 2.22279 28.4999L3.35381 25.3528C3.6681 24.4783 3.6681 23.5217 3.35381 22.6472L2.22279 19.5001C1.60579 17.7833 2.22859 15.8665 3.73688 14.8402L6.50171 12.9589C7.26999 12.4362 7.8323 11.6622 8.09206 10.77L9.02685 7.55916C9.53681 5.80756 11.1673 4.62292 12.9908 4.67919L16.3334 4.78236C17.2622 4.81102 18.172 4.5154 18.9066 3.94626L21.5501 1.89809Z" fill="#005317" />
    </g>
    <defs>
      <filter id="filter0_d_825_31747" x="-2.01318" y="-2.93945" width="52.0264" height="53.8789" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_825_31747" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_825_31747" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default Svg;
