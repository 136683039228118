import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { getCDNImageUrl, isGuestUser } from 'app/utilities/Utils';
import { logButtonClick } from 'app/events/Events';
import CustomImage from 'app/components/common/CustomImage';
import * as LogClevertapEvent from '../../../../clevertap/LogEvent';
import * as buttonActions from '../../../events/buttonActions';

const StoreCategory = (props) => {
  const {
    item, storeId, isMobile, screen,
    onCategoryClick,
  } = props;

  return (
    <Col
      id={`store-category-${item.categoryCode}`}
      key={item.categoryCode}
      xs={8}
      md={6}
      className="product-category px-0 cursor-pointer"
      onClick={() => {
        if (!isGuestUser()) {
          LogClevertapEvent.categoryClicked(
            item.name,
            item.categoryCode,
          );
        }
        logButtonClick(
          {
            BUTTON: buttonActions.CATEGORY,
            SCREEN: screen,
            CATEGORY_ID: item.categoryCode,
            CATEGORY_NAME: item.name,
            ...(
              storeId ? ({
                STORE_ID: storeId,
              }) : {}
            ),
          },
        );
        if (onCategoryClick) {
          onCategoryClick(item);
        }
      }}
    >
      <div
        className={`text-center d-flex flex-column ${
          isMobile ? '' : 'border'
        } p-2 m-lg-2`}
      >
        <div
          className="flex-grow-1"
        >
          <CustomImage
            imageStyle="w-100"
            imageUrl={getCDNImageUrl(item.image)}
            imageType="CATEGORY"
            imageAlt={item.name}
            imageTitle={item.name}
          />
        </div>
        <div
          className={`font-weight-bold ${
            isMobile ? 'fs-6' : 'fs-4'}`}
        >
          {item.name}
        </div>
      </div>
    </Col>
  );
};

StoreCategory.defaultProps = {
  storeId: null,
  onCategoryClick: null,
};

StoreCategory.propTypes = {
  item: PropTypes.shape({
    categoryCode: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  storeId: PropTypes.string,
  screen: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onCategoryClick: PropTypes.func,
};

export default StoreCategory;
