import Axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Col, Row, Container, Spinner, Button,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { fbLogPageView } from 'facebook/Facebook';
import fetchBlogLinks from 'app/utilities/category-constants';
import DefaultGuestLocationAlert from 'app/components/common/DefaultGuestLocationAlert';
import { gaLogPageView } from 'ecommerce_ga_events/EcommerceGAEvents';
import StoreCard from 'app/components/common/StoreCard';
import {
  List, Placeholder, Svg,
} from '../../components/common';
import * as Actions from '../../store/Actions';
import * as ActionTypes from '../../store/ActionTypes';
import { Constants } from '../../utilities';
import * as screens from '../../events/screens';
import FooterMobile from '../../layout/footer/FooterMobile';
import Footer from '../../layout/footer/Footer';
import {
  countryMappingInfo, storeCategoriesMappingInfo, getSeoTitle,
} from '../../utilities/Utils';
import ShopCategoryFilter from '../../layout/seo-stores/ShopCategoryFilter';
import SEOQuestionType from './SEOQuestionType';

const { CancelToken } = Axios;

const seeAllLink = (onclick, language) => (
  <Button
    variant="link"
    className="px-0 px-md-2 font-weight-bold text-black"
    onClick={onclick}
  >
    <span
      className="d-flex align-items-center"
    >
      <span>
        {Constants.String.SEE_ALL[language].toUpperCase()}
      </span>
      &nbsp;&nbsp;
      <span>
        <Svg
          svg="circleArrow"
          circleFill={Constants.Color.primary}
          pathFill={Constants.Color.white}
          width="1.4rem"
        />
      </span>
    </span>
  </Button>
);

const getCityLocalityCategoryUrl = (city, locality = null, storeType = '') => {
  let url = '/india';
  if (city) {
    url = url.concat(`/${city}`);
  }
  if (locality) {
    url = url.concat(`/${locality}`);
  }
  if (storeType) {
    url = url.concat(`/c/${storeType}`);
  }
  return url;
};

class LocalStores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: 'india',
      city: null,
      locality: null,
      storeType: null,
      loading: false,
    };
    this.source = CancelToken.source();
    this.refLocalStores = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const { match } = props;
    const {
      country = '', city = '', locality = '', storeType = '',
    } = match.params;
    if (
      country !== state.country
      || city !== state.city
      || locality !== state.locality
      || storeType !== state.storeType
    ) {
      return {
        country,
        city,
        locality,
        storeType,
        loading: true,
      };
    }
    return null;
  }

  componentDidMount() {
    this.handleLoad();
    gaLogPageView();
    fbLogPageView();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      country, city, locality, storeType,
    } = this.state;
    if (
      (country !== prevState.country
        || city !== prevState.city
        || locality !== prevState.locality
        || storeType !== prevState.storeType)
    ) {
      this.handleLoad();
    }
  }

  componentWillUnmount = () => {
    const { resetRequestStatus } = this.props;
    this.source.cancel();
    resetRequestStatus([
      ActionTypes.ONLINE_STORES_LOAD_REQUEST,
      ActionTypes.STORE_CATEGORIES_REQUEST,
    ]);
  }

  handleLoad = () => {
    const {
      onlineStoresLoadRequest, storeCategoriesRequest, productCategoryRequest,
      seoMetadataLoadRequest,
    } = this.props;
    const {
      city, locality, storeType,
    } = this.state;
    storeCategoriesRequest(this.source.token);
    onlineStoresLoadRequest(
      {
        key: 'async',
        callback: () => {
          this.setState({ loading: false });
        },
      }, this.source.token, null, 'SEO_FILTER', city, locality, storeType,
    );
    seoMetadataLoadRequest(
      this.source.token, city, locality, storeType,
    );
    productCategoryRequest(this.source.token);
  }

  render() {
    const {
      loadingStoreCategories, storeCategoriesLoadFailure, isMobile, language, history,
      onlineStores, onlineStoresLoadFailure, loadingOnlineStores, storeCategories,
      seoMetadata,
    } = this.props;

    let blogLinks = null;
    if (seoMetadata) {
      blogLinks = fetchBlogLinks(Object.keys(seoMetadata)[0]);
    }

    const {
      country, city, locality, storeType,
      loading,
    } = this.state;

    const countryInfo = countryMappingInfo(country || 'none');

    if (
      (country && !countryInfo.title)
      || (!city && !locality)
    ) {
      return (
        <Redirect to="/shops" />
      );
    }

    let pageMeta = null;
    const cityTitle = getSeoTitle(city);
    const localityTitle = getSeoTitle(locality);

    if (storeType) {
      pageMeta = (
        <Helmet>
          <title>
            {(storeCategoriesMappingInfo(storeType, localityTitle, cityTitle).seoMetaTitle)}
          </title>
          <meta
            name="description"
            content={
              (storeCategoriesMappingInfo(storeType, localityTitle, cityTitle).seoMetaDescription)
            }
          />
          <link
            rel="canonical"
            href={`https://${window.location.host}/india${city ? `/${city}` : ''}${locality ? `/${locality}` : ''}${storeType ? `/c/${storeType}` : ''}`}
          />
        </Helmet>
      );
    } else {
      pageMeta = (
        <Helmet>
          <title>
            {`${(localityTitle ? 'Order' : 'Buy')} Groceries, Fruits & Vegetables and Medicines Products Online in 
            ${localityTitle ? `${localityTitle}, ` : ''} ${cityTitle} at best prices | LoveLocal`}
          </title>
          <meta
            name="description"
            content={`${(localityTitle ? 'Order' : 'Buy')} all types of Groceries, Fruits & Vegetables and Medicines products in 
            ${localityTitle ? `${localityTitle}, ` : ''} ${cityTitle} at LoveLocal. Avail great offers and discounts while you shop online from stores near your location. Try it out today.`}
          />
          <link
            rel="canonical"
            href={`https://${window.location.host}/india${city ? `/${city}` : ''}${locality ? `/${locality}` : ''}${storeType ? `/c/${storeType}` : ''}`}
          />
        </Helmet>
      );
    }

    if ((!storeCategories && loadingStoreCategories)) {
      return (
        <Row
          className="w-100 py-3 mx-0 mt-3 justify-content-center"
        >
          {pageMeta}
          <Spinner
            animation="border"
            variant="primary"
          />
        </Row>
      );
    }

    if (!storeCategories && storeCategoriesLoadFailure) {
      return (
        <>
          {pageMeta}
          <Placeholder
            language={language}
            handleRetry={this.handleLoad()}
            imageSrc="/images/error-placeholder.png"
            heading={Constants.String.OH_NO[language]}
          />
        </>
      );
    }

    return (
      <Container
        fluid
        className="mx-0 px-0 bg-white"
        ref={this.refLocalStores}
      >
        {pageMeta}
        <DefaultGuestLocationAlert
          {...this.props}
        />
        <Container
          fluid
          className={`mx-0 pt-6 ${isMobile ? 'bg-white' : ''}`}
        >
          <Container
            className="px-0 px-lg-2"
          >
            <Row
              className="align-items-center"
            >
              <Col
                xs="auto"
                className="fs-5 pr-0"
              >
                <Link
                  className="text-gray-light"
                  to="/"
                >
                  Home
                </Link>
              </Col>
              <Col
                xs="auto"
                className="fs-5 px-0"
              >
                <Link
                  className="text-gray-light"
                  to="/india/all"
                >
                  &nbsp;/&nbsp;
                  {countryInfo.title}
                </Link>
              </Col>
              <Col
                xs="auto"
                className="fs-5 px-0"
              >
                <Link
                  className={`${locality || storeType ? 'text-gray-light' : 'text-black'}`}
                  to={getCityLocalityCategoryUrl(city)}
                >
                  &nbsp;/&nbsp;
                  {cityTitle}
                </Link>
              </Col>
              {
                !!locality && (
                  <Col
                    xs="auto"
                    className="fs-5 px-0"
                  >
                    <Link
                      className={`${storeType ? 'text-gray-light' : 'text-black'}`}
                      to={getCityLocalityCategoryUrl(city, locality)}
                    >
                      &nbsp;/&nbsp;
                      {localityTitle}
                    </Link>
                  </Col>
                )
              }
              {
                !!storeType && (
                  <Col
                    xs="auto"
                    className="px-0 fs-5 cursor-pointer"
                  >
                     &nbsp;/&nbsp;
                    {storeCategoriesMappingInfo(storeType).title}
                  </Col>
                )
              }
            </Row>
          </Container>
        </Container>

        {/* stores */}
        <Container className={`mt-md-6 ${isMobile ? 'bg-white' : ''}`}>
          <Row className="mx-0">
            {
              (storeCategories?.length > 0) && (
                <Col
                  xs={6}
                  className="d-none d-md-block mb-4 bg-white px-0 height-fit-contain"
                >
                  <ShopCategoryFilter
                    categories={storeCategories}
                    categoryURL={storeType}
                    state={this.state}
                  />
                </Col>
              )
            }
            <Col
              xs={24}
              md={18}
              className="px-0 px-md-4"
            >
              {
                (loading || loadingOnlineStores) && (
                  <Row
                    className="w-100 py-3 mx-0 mt-3 justify-content-center"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </Row>
                )
              }
              {
                !loading && !loadingOnlineStores && onlineStoresLoadFailure && (
                  <Placeholder
                    language={language}
                    handleRetry={this.handleLoad}
                    imageSrc="/images/error-placeholder.png"
                    heading={Constants.String.OH_NO[language]}
                  />
                )
              }
              {
                !loading && !loadingOnlineStores && !onlineStoresLoadFailure && !!onlineStores && (
                  onlineStores.count === 0 ? (
                    <div>
                      <Placeholder
                        language={language}
                        imageSrc="/images/no-results.png"
                        heading={Constants.String.NO_RESULTS_FOUND[language]}
                      />
                    </div>
                  ) : (
                    <Container
                      fluid
                      className="mb-4 px-0 p"
                      id="stores"
                    >
                      <Container
                        className="px-0 px-lg-2"
                      >
                        {
                          !storeType
                          && onlineStores?.metaData
                          && (
                            <Row className={`mx-0 ${isMobile ? 'pt-4' : ''} px-lg-2`}>
                              <h1 className="m-0 fs-3 font-weight-black">
                                {onlineStores.metaData.heading}
                              </h1>
                            </Row>
                          )
                        }
                        <Row
                          className="mx-0"
                        >
                          {
                            !storeType
                              ? !!onlineStores
                                && (onlineStores.results).map((storesGroupedCategory) => (
                                  <>
                                    {storesGroupedCategory.storeLists.length ? (
                                      <Col
                                        xs={24}
                                        className="px-4 pt-2"
                                      >
                                        <Row
                                          className="align-items-center py-2"
                                        >
                                          <Col
                                            xs={`${storesGroupedCategory.storeCount > 4 ? 16 : 24}`}
                                            className="px-0 px-md-2"
                                          >
                                            <h2
                                              className="fs-3 font-weight-bold text-truncate"
                                            >
                                              {storesGroupedCategory.description || ''}
                                              &nbsp;
                                              {
                                                ` ${Constants.String.IN_LOCALITY[language]
                                                  .replace(/{locality}/g, localityTitle || cityTitle)}`
                                              }
                                            </h2>
                                          </Col>
                                          {
                                            storesGroupedCategory.storeCount > 4 && (
                                              <Col
                                                xs={8}
                                                className="text-right px-0"
                                              >
                                                {
                                                  seeAllLink(
                                                    () => {
                                                      this.refLocalStores.current.scrollTop = 0;
                                                      history.push(
                                                        getCityLocalityCategoryUrl(
                                                          city, locality,
                                                          storesGroupedCategory.urlStoreTypeDesc,
                                                        ),
                                                      );
                                                    },
                                                    language,
                                                  )
                                                }
                                              </Col>
                                            )
                                          }
                                        </Row>
                                        <Row>
                                          <Col xs={24}>
                                            <List
                                              {...this.props}
                                              idField="code"
                                              list={storesGroupedCategory.storeLists}
                                              Component={StoreCard}
                                              screen={screens.STORES}
                                              isOnSeoPage
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                    ) : ''}
                                  </>
                                ))
                              : (
                                <Col
                                  xs={24}
                                  className="px-4 pt-2"
                                >
                                  {onlineStores ? (
                                    <>
                                      <Row
                                        className="align-items-center px-2"
                                      >
                                        <Col
                                          xs={22}
                                          className="px-0"
                                        >
                                          <h1
                                            className="fs-3 font-weight-black"
                                          >
                                            {storeCategoriesMappingInfo(
                                              storeType, localityTitle, cityTitle,
                                            ).categoryTitleh1}
                                          </h1>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={24}>
                                          <List
                                            {...this.props}
                                            idField="code"
                                            list={onlineStores.results}
                                            Component={StoreCard}
                                            screen={screens.STORES}
                                            isOnSeoPage
                                          />
                                        </Col>
                                      </Row>
                                    </>
                                  ) : ''}
                                </Col>
                              )
                          }
                        </Row>
                      </Container>
                    </Container>
                  )
                )
              }
            </Col>
          </Row>
        </Container>
        {
          (
            !!seoMetadata
            && Object.keys(seoMetadata).length > 0
            && (
              onlineStores
              && !!onlineStores.count
            )
          ) && (
            <Container fluid className="py-3 py-lg-4 my-2 my-lg-0 mx-0 bg-white">
              {
                (Object.keys(seoMetadata)).map((stype) => (
                  seoMetadata[stype].map((meta) => (
                    <Container className="mb-4 px-0" key={`${stype}`}>
                      <SEOQuestionType
                        content={meta}
                      />
                    </Container>
                  ))
                ))
              }
            </Container>
          )
        }

        {/* Read more for seo blog linking */}
        {
        blogLinks
        && (
          onlineStores
          && !!onlineStores.count
        ) && (
          <Container
            fluid
            className="py-3 py-lg-4 my-2 my-lg-0 mx-0 bg-white"
          >
            <Row>
              <Col
                xs={24}
                className="bg-white text-center"
              >
                <h2>Read More</h2>
              </Col>
            </Row>

            <Row>
              <Col
                xs={24}
                className="bg-white text-gray-light pt-3 mb-6 text-center d-flex flex-wrap justify-content-center"
              >
                {blogLinks.map((item) => (
                  <div className="bg-light blogLinks" key={item.id}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">{item.linkName}</a>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        )
}

        <div
          className="d-md-none bg-black"
        >
          <FooterMobile
            {...this.props}
            language={language}
            refLocalStores={() => {
              this.refLocalStores.current.scrollTop = 0;
            }}
          />
          <div className="p-6" />
        </div>
        <div
          className="d-none d-md-block bg-black"
        >
          <Footer
            {...this.props}
            language={language}
            refLocalStores={() => {
              this.refLocalStores.current.scrollTop = 0;
            }}
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  onlineStores: state.main.onlineStores,
  seoMetadata: state.main.seoMetadata,
  storeCategories: state.main.storeCategories,
  categories: state.main.categories,
  loadingOnlineStores: state.main.requestsProcessing[ActionTypes.ONLINE_STORES_LOAD_REQUEST],
  onlineStoresLoadFailure: state.main.requestsFailure[ActionTypes.ONLINE_STORES_LOAD_REQUEST],
  loadingStoreCategories: state.main.requestsProcessing[ActionTypes.STORE_CATEGORIES_REQUEST],
  storeCategoriesLoadFailure: state.main.requestsFailure[ActionTypes.STORE_CATEGORIES_REQUEST],
  loadingCategories: state.main.requestsProcessing[ActionTypes.PRODUCT_CATEGORY_REQUEST],
  categoriesFailure: state.main.requestsFailure[ActionTypes.PRODUCT_CATEGORY_REQUEST],
  loadingSeoMetadata: state.main.requestsProcessing[ActionTypes.SEO_METADATA_LOAD_REQUEST],
  seoMetadataLoadFailure: state.main.requestsFailure[ActionTypes.SEO_METADATA_LOAD_REQUEST],
});

const mapDispatchToProps = (dispatch) => ({
  onlineStoresLoadRequest:
  (onSuccess, cancelToken, offset, filterFlag, city, locality, storeType) => {
    dispatch(Actions.onlineStoresLoadRequest(
      onSuccess, cancelToken, null, offset, filterFlag, city, locality, storeType,
    ));
  },
  storeCategoriesRequest: (cancelToken) => {
    dispatch(Actions.storeCategoriesRequest(cancelToken));
  },
  productCategoryRequest: (cancelToken) => {
    dispatch(Actions.productCategoryRequest(cancelToken));
  },
  seoMetadataLoadRequest: (cancelToken, city, locality, storeType) => {
    dispatch(Actions.seoMetadataLoadRequest(cancelToken, city, locality, storeType));
  },
  resetRequestStatus:
  (requests) => {
    dispatch(Actions.resetRequestStatus(requests));
  },
});

LocalStores.defaultProps = {
  stores: null,
  onlineStores: null,
  storeCategories: null,
  categories: null,
  seoMetadata: null,
};

LocalStores.propTypes = {
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  match: PropTypes.shape({
    params: PropTypes.shape({
      country: PropTypes.string,
      city: PropTypes.string,
      locality: PropTypes.string,
      storeType: PropTypes.string,
    }),
  }).isRequired,
  stores: PropTypes.shape({
    onMpaaniCount: PropTypes.number,
    onMpaani: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onlineStores: PropTypes.shape({
    metaData: PropTypes.shape({
      heading: PropTypes.string,
    }),
    categoryMetaData: PropTypes.shape({
      description: PropTypes.string,
    }),
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  seoMetadata: PropTypes.shape({}),
  storeCategories: PropTypes.arrayOf(PropTypes.shape({})),
  loadingOnlineStores: PropTypes.bool.isRequired,
  onlineStoresLoadRequest: PropTypes.func.isRequired,
  onlineStoresLoadFailure: PropTypes.bool.isRequired,
  loadingSeoMetadata: PropTypes.bool.isRequired,
  seoMetadataLoadFailure: PropTypes.bool.isRequired,
  seoMetadataLoadRequest: PropTypes.func.isRequired,
  loadingStoreCategories: PropTypes.bool.isRequired,
  storeCategoriesLoadFailure: PropTypes.bool.isRequired,
  storeCategoriesRequest: PropTypes.func.isRequired,
  loadingStores: PropTypes.bool.isRequired,
  storesLoadFailure: PropTypes.bool.isRequired,
  storesLoadRequest: PropTypes.func.isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  productCategoryRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  page: PropTypes.string.isRequired,
  loadingCategories: PropTypes.bool.isRequired,
  categoriesFailure: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalStores);
