import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  fill, width, height,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.4185 12.4185 16 8 16H0V8C0 3.5815 3.5815 0 8 0C12.4185 0 16 3.5815 16 8Z"
      fill={fill}
    />
    <path
      d="M6.5584 9.29439C6.54298 9.28884 5.40016 8.32825 4.5148 7.58295C3.95111 7.10843 3.10969 7.18151 2.63607 7.74595C2.16322 8.30946 2.23672 9.1496 2.80024 9.62244L5.35504 11.7662C6.20119 12.4762 7.4627 12.3658 8.1727 11.5197L12.0312 6.92133C12.5037 6.35823 12.4302 5.5187 11.8671 5.0462C11.3025 4.57244 10.4612 4.64671 9.98911 5.21274C8.7441 6.7056 6.57636 9.30085 6.5584 9.29439Z"
      fill="white"
    />
  </svg>
);
Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
