import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  cartDeleteActions, cartLoadActions, CART_LOAD_REQUEST,
} from 'app/store/cart/actions';
import * as Actions from '../../store/Actions';
import * as ActionTypes from '../../store/ActionTypes';
import { Constants } from '../../utilities';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import { isGuestUser } from '../../utilities/Utils';

const { String } = Constants;

class LocationActionAlert extends React.Component {
  onCancel = () => {
    const { onCancel, resetRequestStatus } = this.props;
    onCancel();
    resetRequestStatus([
      ActionTypes.ADDRESS_CHANGE_REQUEST,
      ActionTypes.ADDRESS_DELETE_REQUEST,
      CART_LOAD_REQUEST,
    ]);
  }

  onSettingLocation = () => {
    const { page } = this.props;
    if (page === 'product-details') {
      // remove query params and render rest
      window.location.replace(window.location.pathname);
    } else {
      window.location.reload();
    }
  }

  onConfirm = () => {
    const {
      action,
      address,
      addressChangeRequest,
      addressDeleteRequest,
      recentAddressLoadRequest,
      cartIdLoadRequest,
      cartDeleteReq,
      notifyAction,
      toggleManageLocation,
      isMobile,
      history,
      isReorder,
      isLocationSearch,
      addAddress,
      reset,
    } = this.props;
    const api = isLocationSearch ? addAddress : addressChangeRequest;
    switch (action) {
      case 'change':
      case 'set-default':
        api(
          address,
          {
            type: 'async',
            callback: (data, addressChangeSuccess) => {
              if (isGuestUser()) {
                addressChangeSuccess();
                notifyAction();
                LogClevertapEvent.appLaunchedOrAddressChanged(data.res);
                LogClevertapEvent.locationSwitch(
                  data.res.addressText,
                );
                if (isReorder && isMobile) {
                  history.push('/cart');
                  return;
                }
                toggleManageLocation();
                this.onSettingLocation();
              } else {
                cartDeleteReq(
                  {
                    type: 'sync',
                    callback: () => {
                      cartIdLoadRequest(
                        {
                          type: 'sync',
                          callback: () => {
                            addressChangeSuccess();
                            recentAddressLoadRequest();
                            reset();
                            LogClevertapEvent.appLaunchedOrAddressChanged(data.res);
                            LogClevertapEvent.locationSwitch(
                              data.res.addressText,
                            );
                            if (isReorder) { notifyAction(); }
                            if (isReorder && isMobile) {
                              history.push('/cart');
                              return;
                            }
                            toggleManageLocation();
                            this.onSettingLocation();
                          },
                        },
                      );
                    },
                  },
                  null,
                  {},
                );
              }
            },
          },
        );
        break;
      case 'delete':
        addressDeleteRequest(
          address,
          {
            type: 'sync',
            callback: () => {
              notifyAction();
            },
          },
        );
        break;
      default:
        break;
    }
  }

  render() {
    const {
      language,
      requestsProcessing,
      requestsFailure,
      action,
      isMobile,
      cartItems,
    } = this.props;
    let message = null;
    let cancelString = null;
    let confirmString = null;
    let header = null;
    const loader = requestsProcessing[ActionTypes.ADDRESS_CHANGE_REQUEST]
    || requestsProcessing[ActionTypes.ADDRESS_DELETE_REQUEST]
    || requestsProcessing[ActionTypes.ADD_ADDRESS_REQUEST];
    const failure = requestsFailure[ActionTypes.ADDRESS_CHANGE_REQUEST]
    || requestsFailure[ActionTypes.ADDRESS_DELETE_REQUEST]
    || requestsFailure[ActionTypes.ADD_ADDRESS_REQUEST];
    switch (action) {
      case 'change':
        header = String.CLEAR_CART[language];
        message = String.CLEAR_CART_DESCRIPTION[language];
        cancelString = String.CANCEL[language];
        confirmString = String.CONTINUE[language];
        break;
      case 'delete':
        header = String.DELETE_ADDRESS[language];
        message = String.DELETE_ADDRESS_DESCRIPTION[language];
        cancelString = String.NO[language];
        confirmString = String.YES[language];
        break;
      case 'set-default':
        header = String.SET_DEFAULT[language];
        message = String.SET_DEFAULT_DESCRIPTION[language];
        cancelString = String.NO[language];
        confirmString = String.YES[language];
        break;
      default:
        break;
    }

    const content = (
      <Container
        className="text-lg-left py-5 px-7 bg-white r__action_container"
      >
        {(() => {
          let showcase = null;
          if (
            action === 'change'
            && ((cartItems && Object.keys(cartItems).length === 0) || cartItems === null)
          ) {
            showcase = (
              <>
                <Row className="py-2">
                  <Col xs={24}>
                    <h3 className="text-primary fs-3 m-0 r__action_title">
                      {
                        loader
                          ? String.UPDATING_LOCATION[language] : String.UPDATE_LOCATION[language]
                      }
                    </h3>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col xs={24} className="fs-4 text-dark r__action_copy">
                    <span />
                  </Col>
                </Row>
              </>
            );
          } else {
            showcase = (
              <>
                <Row className="py-2">
                  <Col xs={24}>
                    <h3 className="text-primary fs-3 m-0 r__action_title">
                      {
                        (action === 'change' && loader)
                          ? String.CLEARING_CART[language] : header
                      }
                    </h3>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col xs={24} className="fs-4 text-dark r__action_copy">
                    {message}
                  </Col>
                </Row>
              </>
            );
          }
          return showcase;
        })()}
        {
          loader
            ? (
              <Row
                className="py-2"
              >
                <Col
                  xs={24}
                  className="text-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </Col>
              </Row>
            ) : (
              <Row
                className="justify-content-center py-2 r__action_btn-wrapper"
              >
                <Col
                  xs="auto"
                  md
                  className="py-1 px-0"
                >
                  <Button
                    variant={isMobile ? 'link' : 'outline-primary'}
                    className="fs-5 w-100 py-3 r__action_cancel"
                    onClick={this.onConfirm}
                  >
                    {confirmString}
                  </Button>
                </Col>
                <Col
                  xs="auto"
                  md
                  className="py-1 px-0"
                >
                  <Button
                    variant={isMobile ? 'link' : 'primary'}
                    className="fs-5 w-100 py-3 r__action_confirm"
                    onClick={this.onCancel}
                  >
                    {cancelString}
                  </Button>
                </Col>
              </Row>
            )
        }
        {failure && (
        <Row>
          <Col
            xs={24}
            className="text-danger fs-5"
          >
            {Constants.String.OOPS[language]}
          </Col>
        </Row>
        )}
      </Container>
    );

    return content;
  }
}

const mapStateToProps = (state) => ({
  requestsProcessing: state.main.requestsProcessing,
  requestsFailure: state.main.requestsFailure,
  cartItems: state.main.cartItems,
});

const mapDispatchToProps = (dispatch) => ({
  addressChangeRequest:
   (address, onSuccess) => {
     dispatch(Actions.addressChangeRequest(address, onSuccess));
   },
  recentAddressLoadRequest: (onSuccess, onFailure) => {
    dispatch(Actions.recentAddressLoadRequest(onSuccess, onFailure));
  },
  addAddress: (address, onSuccess) => {
    dispatch(Actions.addAddress(address, onSuccess));
  },
  addressDeleteRequest:
    (address, onSuccess) => {
      dispatch(Actions.addressDeleteRequest(address, onSuccess));
    },
  cartIdLoadRequest:
  (onSuccess) => {
    dispatch(cartLoadActions.loadRequest(onSuccess));
  },
  cartDeleteReq: (onSuccess, onFailure, params) => {
    dispatch(cartDeleteActions.deleteRequest(onSuccess, onFailure, params));
  },
  resetRequestStatus:
  (requests) => dispatch(Actions.resetRequestStatus(requests)),
  toggleManageLocation:
  () => dispatch(Actions.toggleManageLocation()),
});

LocationActionAlert.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  action: PropTypes.string,
  language: PropTypes.string.isRequired,
  address: PropTypes.shape({}),
  requestsProcessing: PropTypes.shape({}).isRequired,
  requestsFailure: PropTypes.shape({}).isRequired,
  addressChangeRequest: PropTypes.func.isRequired,
  recentAddressLoadRequest: PropTypes.func.isRequired,
  addAddress: PropTypes.func.isRequired,
  addressDeleteRequest: PropTypes.func.isRequired,
  cartIdLoadRequest: PropTypes.func.isRequired,
  cartDeleteReq: PropTypes.func.isRequired,
  notifyAction: PropTypes.func.isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  toggleManageLocation: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    replace: PropTypes.func,
    length: PropTypes.number,
    goBack: PropTypes.func,
  }).isRequired,
  isReorder: PropTypes.bool,
  isLocationSearch: PropTypes.bool,
  reset: PropTypes.func,
  cartItems: PropTypes.shape({}),
  page: PropTypes.string.isRequired,
};

LocationActionAlert.defaultProps = {
  action: null,
  address: null,
  isReorder: false,
  isLocationSearch: false,
  reset: () => {},
  cartItems: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationActionAlert);
