// Brand Space Load Actions
export const BRAND_SPACE_LOAD_REQUEST = 'BRAND_SPACE_LOAD_REQUEST';
export const BRAND_SPACE_LOAD_SUCCESS = 'BRAND_SPACE_LOAD_SUCCESS';
export const BRAND_SPACE_LOAD_FAILURE = 'BRAND_SPACE_LOAD_FAILURE';

export const brandSpaceLoadActions = {
  loadRequest: (
    onSuccess, onFailure, cancelToken, params,
  ) => ({
    type: BRAND_SPACE_LOAD_REQUEST,
    payload: {
      onSuccess,
      onFailure,
      cancelToken,
      params,
    },
  }),
  loadSuccess: (data) => ({
    type: BRAND_SPACE_LOAD_SUCCESS,
    payload: {
      brandSpaces: data.res,
    },
  }),
  loadFailure: () => ({
    type: BRAND_SPACE_LOAD_FAILURE,
  }),
};
