import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as buttonActions from '../../events/buttonActions';
import { logButtonClick } from '../../events/Events';
import { getCDNImageUrl, getSEOFriendlyURL, isGuestUser } from '../../utilities/Utils';
import CustomImage from '../../components/common/CustomImage';

const ProductCategory = (props) => {
  const {
    item, storeId, isMobile, screen,
    onCategoryClick, history,
  } = props;

  return (
    <Col
      id={`product-category-${item.categoryId}`}
      key={item.categoryId}
      xs={8}
      md={6}
      className="product-category px-0 cursor-pointer"
      onClick={() => {
        // History Push
        history.push({
          pathname: `/pc/${getSEOFriendlyURL(item.categoryId)}`,
          search: `?${storeId ? `sid=${storeId}&` : ''}`,
        });

        // CT Events
        if (!isGuestUser()) {
          LogClevertapEvent.categoryClicked(
            item.displayName,
            item.categoryId,
          );
        }
        logButtonClick(
          {
            BUTTON: buttonActions.CATEGORY,
            SCREEN: screen,
            CATEGORY_ID: item.categoryId,
            CATEGORY_NAME: item.displayName,
            ...(
              storeId ? ({
                STORE_ID: storeId,
              }) : {}
            ),
          },
        );
        if (onCategoryClick) {
          onCategoryClick(item);
        }
      }}
    >
      <div
        className={`text-center d-flex flex-column ${
          isMobile ? '' : 'border'
        } p-2 m-lg-2`}
      >
        <div
          className="flex-grow-1"
        >
          <CustomImage
            imageStyle="w-100"
            imageUrl={getCDNImageUrl(item.image)}
            imageType="CATEGORY"
            imageAlt={item.displayName}
            imageTitle={item.displayName}
          />
        </div>
        <div
          className={`font-weight-bold ${
            isMobile ? 'fs-6' : 'fs-4'}`}
        >
          {item.displayName}
        </div>
      </div>
    </Col>
  );
};

ProductCategory.defaultProps = {
  storeId: null,
  onCategoryClick: null,
};

ProductCategory.propTypes = {
  item: PropTypes.shape({
    categoryId: PropTypes.string,
    displayName: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  storeId: PropTypes.string,
  screen: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onCategoryClick: PropTypes.func,
};

export default ProductCategory;
