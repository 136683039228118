import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Svg } from '../../components/common';
import { Constants, Utils } from '../../utilities';
import 'app/styles/stores/store.scss';

const { LOVELOCAL } = Utils;

function Footer(props) {
  const { language, refLocalStores } = props;

  const localityColSize = 8;
  // eslint-disable-next-line max-len
  const localityArray = (array, size) => Array.from({ length: Math.ceil(array.length / size) }, (v, i) => array.slice(i * size, i * size + size));
  const localities = localityArray(Utils.popularLocalities, localityColSize);

  return (
    <Container
      className="py-9 text-white"
    >
      <Row
        className="pb-9 border-bottom fs-5 justify-content-between flex-row"
      >
        <Col xs={6} className="pr-7">
          <div className="mb-6">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.becomeShop}
            >
              <Svg
                svg="logo-ll"
                fill1={Constants.Color.white}
                fill2={Constants.Color.primary}
                width="9rem"
              />
            </a>
          </div>
          <div className="mb-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.playstore}
            >
              <Svg
                svg="googlePlay"
                width="9rem"
              />
            </a>
          </div>
          <div className="mt-1">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOVELOCAL.appstore}
            >
              <Svg
                svg="appStore"
                height="9rem"
                width="9rem"
              />
            </a>
          </div>
          <Row>
            <Col xs="auto" className="">
              <img src="/images/footer-shopkeeper.png" alt="Sell Now" />
            </Col>
            <Col className="d-flex flex-column justify-content-center pl-0">
              <div className="text-yellow font-weight-bold mb-2">
                {Constants.String.BANO_APNI_DIGITAL_DUKAN[language]}
              </div>
              <div className="bg-yellow text-center p-1">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={LOVELOCAL.partnerPlayStore}
                  className="text-violet fs-6"
                >
                  {Constants.String.START_SELLING_NOW[language]}
                </a>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={6}
        >
          <h5 className="text-gray mb-6">
            {Constants.String.POPULAR_CITIES[language]}
          </h5>
          <Row>
            <Col>
              {
                Utils.popularCities.slice(0, 6).map((item) => (
                  <Row
                    key={item.title}
                    className="mx-0 mb-4 justify-content-start"
                  >
                    <Link
                      to={{
                        pathname: item.urlPath,
                        state: {
                          scrollToTop: true,
                        },
                      }}
                      className="text-white"
                      onClick={() => {
                        if (refLocalStores) {
                          refLocalStores();
                        }
                      }}
                    >
                      {item.title}
                    </Link>
                  </Row>
                ))
              }
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <h5 className="text-gray mb-6">
            {Constants.String.POPULAR_LOCALITIES[language]}
          </h5>
          <Row>
            {localities.map((chunk, index) => (
              <Col key={index}>
                {chunk.map((item) => (
                  (
                    <Row
                      key={item.title}
                      className="mx-0 mb-4 justify-content-start white-space-nowrap "
                    >
                      <Link
                        to={{
                          pathname: item.urlPath,
                          state: {
                            scrollToTop: true,
                          },
                        }}
                        className="text-white"
                        onClick={() => {
                          if (refLocalStores) {
                            refLocalStores();
                          }
                        }}
                      >
                        {item.title}
                      </Link>
                    </Row>
                  )
                ))}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="mt-6">
        <div className="pr-4">
          <a
            target="_blank"
            href={LOVELOCAL.aboutUs}
            rel="noopener noreferrer"
            className="text-white"
          >
            {Constants.String.ABOUT_US[language]}
          </a>
        </div>
        <div className="px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${LOVELOCAL.contactUs}`}
            className="text-white"
          >
            {Constants.String.CONTACT_US[language]}
          </a>
        </div>
        <div className="px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.blog}
            className="text-white"
          >
            {Constants.String.BLOG[language]}
          </a>
        </div>
        <div className="px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.privacyPolicy}
            className="text-white"
          >
            {Constants.String.PRIVACY_POLICY[language]}
          </a>
        </div>
        <div className="px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.tnc}
            className="text-white"
          >
            {Constants.String.TERMS_CONDITION[language]}
          </a>
        </div>
        <div className="px-4">
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${LOVELOCAL.career}`}
              className="text-white"
            >
              {`${Constants.String.CAREERS[language]} :`}
              {LOVELOCAL.career}
            </a>
          </div>
        </div>
        <div className="px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.facebook}
            className="text-white"
          >
            <Svg
              svg="facebook"
              fill={Constants.Color.white}
              width="1rem"
            />
          </a>
        </div>
        <div className="px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.linkedin}
            className="text-white"
          >
            <Svg
              svg="linkedIn"
              fill={Constants.Color.white}
              width="1rem"
            />
          </a>
        </div>
        <div className="px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.twitter}
            className="text-white"
          >
            <Svg
              svg="twitter"
              fill={Constants.Color.white}
              width="1rem"
            />
          </a>
        </div>
        <div className="px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.youtube}
            className="text-white"
          >
            <Svg
              svg="youtube"
              fill={Constants.Color.white}
              width="1rem"
            />
          </a>
        </div>
        <div className="px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LOVELOCAL.insta}
            className="text-white"
          >
            <Svg
              svg="instagram"
              fill={Constants.Color.white}
              width="1rem"
            />
          </a>
        </div>
      </Row>
    </Container>
  );
}

Footer.propTypes = {
  language: PropTypes.string.isRequired,
  refLocalStores: PropTypes.func,
};
Footer.defaultProps = {
  refLocalStores: () => {},
};

export default Footer;
