import React from 'react';
import PropTypes from 'prop-types';

const LocationIcon = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill="#000"
      d="M11.611 3A6.585 6.585 0 0 0 6.94 4.958 6.721 6.721 0 0 0 5 9.678a6.656 6.656 0 0 0 1.332 4.008s.18.24.21.274l4.303 5.127a1 1 0 0 0 1.532 0l4.306-5.13c.026-.032.207-.271.207-.271l.001-.002a6.653 6.653 0 0 0 1.331-4.005 6.721 6.721 0 0 0-1.938-4.72A6.585 6.585 0 0 0 11.61 3Zm0 9.107c-.475 0-.94-.142-1.336-.41a2.424 2.424 0 0 1-.885-1.09 2.451 2.451 0 0 1 .521-2.646 2.382 2.382 0 0 1 2.62-.526c.44.184.815.495 1.079.894a2.446 2.446 0 0 1-.3 3.066 2.394 2.394 0 0 1-1.699.712Z"
    />
  </svg>
);

LocationIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default LocationIcon;
