import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  fill, width, height,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2 8a.8.8 0 0 1 .8-.8h6.154a.8.8 0 0 1 0 1.6H9.93l7.635 7.634a.8.8 0 1 1-1.132 1.132L8.8 9.93v4.223a.8.8 0 0 1-1.6 0V8z"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2 8a.8.8 0 0 1 .8-.8h6.154a.8.8 0 0 1 0 1.6H9.93l7.635 7.634a.8.8 0 1 1-1.132 1.132L8.8 9.93v4.223a.8.8 0 0 1-1.6 0V8z"
      fill={fill}
      // fillOpacity=".4"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
