import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    style={{
      width,
    }}
    viewBox="0 0 56 56"
  >
    <defs>
      <path
        id="j7mtv65bta"
        d="M0.058 0.074L13.961 0.074 13.961 13.972 0.058 13.972z"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
    >
      <g>
        <g>
          <g
            fill="#FFEBF9"
            transform="translate(-74 -615) translate(74 615)"
          >
            <circle
              cx="28"
              cy="28"
              r="28"
            />
          </g>
          <g>
            <path
              fill="#B8238E"
              d="M24.904 24.464c-.006 0-.006.006 0 0-.237.254-.48.484-.741.738-.395.375-.796.768-1.173 1.21-.613.653-1.336.961-2.284.961-.091 0-.188 0-.28-.006-1.804-.115-3.48-.816-4.738-1.415-3.44-1.657-6.459-4.01-8.968-6.992-2.071-2.486-3.457-4.785-4.374-7.252-.565-1.506-.772-2.68-.68-3.787.06-.707.333-1.294.838-1.796l2.072-2.063c.297-.278.613-.43.923-.43.383 0 .692.231.887.424l.018.018c.371.345.723.702 1.094 1.083.188.194.383.387.577.587l1.659 1.651c.644.641.644 1.234 0 1.875-.177.176-.347.351-.523.52-.51.52-.996 1.005-1.525 1.476-.012.012-.024.019-.03.03-.523.52-.425 1.029-.316 1.373l.018.055c.432 1.04 1.04 2.02 1.963 3.187l.006.006c1.677 2.057 3.444 3.66 5.395 4.888.249.157.504.284.747.405.219.109.425.212.601.32.025.013.049.03.073.043.207.102.401.151.602.151.504 0 .82-.315.923-.417l2.078-2.069c.207-.206.535-.454.918-.454.376 0 .686.236.874.442l3.36 3.345c.626.617.626 1.252.006 1.893zm-2.175-6.37c-.59-.61-1.3-.937-2.053-.937-.748 0-1.465.321-2.078.932l-1.92 1.905c-.158-.085-.316-.163-.468-.242-.219-.109-.425-.211-.602-.32-1.798-1.138-3.432-2.62-5-4.537-.759-.956-1.27-1.76-1.64-2.576.498-.454.96-.926 1.41-1.38.17-.169.34-.344.51-.514 1.276-1.27 1.276-2.915 0-4.185L9.23 4.589c-.188-.188-.382-.381-.565-.575-.364-.375-.747-.762-1.142-1.125C6.932 2.309 6.228 2 5.487 2c-.741 0-1.458.308-2.066.889l-.012.012-2.066 2.075C.566 5.75.123 6.693.025 7.788c-.146 1.766.377 3.412.778 4.488.984 2.643 2.454 5.093 4.648 7.718 2.66 3.164 5.862 5.661 9.52 7.422 1.397.659 3.262 1.439 5.346 1.572.128.006.261.012.383.012 1.403 0 2.582-.502 3.506-1.5.006-.012.018-.018.024-.03.316-.381.68-.726 1.063-1.095.261-.248.528-.508.79-.78.601-.623.917-1.349.917-2.093 0-.75-.322-1.47-.935-2.075l-3.336-3.332z"
              transform="translate(-74 -615) translate(74 615) translate(13 15)"
            />
            <g
              transform="translate(-74 -615) translate(74 615) translate(13 15) translate(15)"
            >
              <mask
                id="ex7zmuoksb"
                fill="#fff"
              >
                <use
                  xlinkHref="#j7mtv65bta"
                />
              </mask>
              <path
                fill="#B8238E"
                d="M13.948 13.007c-.55-3.23-2.073-6.17-4.412-8.508C7.197 2.159 4.258.638 1.028.087.576.007.149.316.068.767c-.073.458.23.885.688.966 2.883.489 5.513 1.856 7.605 3.942 2.091 2.091 3.452 4.72 3.941 7.604.068.409.42.693.823.693.05 0 .093-.006.142-.012.452-.068.762-.501.681-.953"
                mask="url(#ex7zmuoksb)"
              />
            </g>
            <path
              fill="#B8238E"
              d="M20.05 8.945c1.197 1.197 1.972 2.697 2.256 4.349.07.416.428.706.838.706.05 0 .095-.006.145-.013.466-.075.775-.516.7-.983-.34-1.998-1.286-3.82-2.73-5.263-1.443-1.444-3.264-2.39-5.262-2.73-.467-.075-.901.234-.984.694-.082.46.221.907.688.983 1.65.277 3.15 1.06 4.348 2.257z"
              transform="translate(-74 -615) translate(74 615) translate(13 15)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
};

export default Svg;
