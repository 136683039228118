export const ACCEPT_CHANGES = 'ACCEPT_CHANGES';
export const ACCOUNT_TAB = 'ACCOUNT_TAB';
export const ADD_MORE_PRODUCTS = 'ADD_MORE_PRODUCTS';
export const ADD_NEW_ADDRESS = 'ADD_NEW_ADDRESS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const APPLY_COUPON = 'APPLY_COUPON';
export const APPLY_OFFERS = 'APPLY_OFFERS';
export const APPLY_REFERAL = 'APPLY_REFERAL';
export const APPLY_REWARD = 'APPLY_REWARD';
export const APPLY_SPEND_OFFER = 'APPLY_SPEND_OFFER';
export const APPLY_VALUE_OFFER = 'APPLY_VALUE_OFFER';
export const BEST_RATED_CARD = 'BEST_RATED_CARD';
export const BUY_NOW = 'BUY_NOW';
export const CATEGORY = 'CATEGORY';
export const CART_CLICKED = 'CART_CLICKED';
export const CART_BOTTOM_BAR = 'CART_BOTTOM_BAR';
export const CHAT_SUPPORT = 'CHAT_SUPPORT';
export const PHONE_SUPPORT = 'PHONE_SUPPORT';
export const EMAIL_SUPPORT = 'EMAIL_SUPPORT';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LOCATION = 'CHANGE_LOCATION';
export const CART_TAB = 'CART_TAB';
export const CHECKOUT = 'CHECKOUT';
export const COPY_REFERRAL_CODE = 'COPY_REFERRAL_CODE';
export const COUPON_CODE_COPIED = 'COUPON_CODE_COPIED';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DISCOUNT_OFFERS_TILE = 'DISCOUNT_OFFERS_TILE';
export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EXPLORE_SHOPS = 'EXPLORE_SHOPS';
export const FAQ = 'FAQ';
export const FAVOURITE_SHOPS = 'FAVOURITE_SHOPS';
export const GET_DIRECTION = 'GET_DIRECTION';
export const HOME_COUPON_BANNER = 'HOME COUPON BANNER';
export const HOME_PAGE_BANNER = 'HOME PAGE BANNER';
export const HOME_TAB = 'HOME_TAB';
export const HOME_TOPMOST_BANNER = 'HOME TOPMOST BANNER';
export const IN_PROGRESS_ORDERS_TAB = 'IN_PROGRESS_ORDERS_TAB';
export const INVITE_STORE = 'INVITE_STORE';
export const LOCATE_ME = 'LOCATE_ME';
export const LOGOUT = 'LOGOUT';
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const MANAGE_LOCATION = 'MANAGE_LOCATION';
export const NOTIFICATION_TAB = 'NOTIFICATION_TAB';
export const OFFERS_TAB = 'OFFERS_TAB';
export const ORDER_AGAIN = 'ORDER_AGAIN';
export const ORDERS_TAB = 'ORDERS_TAB';
export const PAST_ORDERS_TAB = 'PAST_ORDERS_TAB';
export const PAYMENT_PENDING_POPUP = 'PAYMENT_PENDING_POPUP';
export const PICKUP_NOT_DONE_POPUP = 'PICKUP_NOT_DONE_POPUP';
export const PLACE_ORDER = 'PLACE_ORDER';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const REFER_LOCAL_SHOPS = 'REFER_LOCAL_SHOPS';
export const REFER_AND_EARN = 'REFER_AND_EARN';
export const REFER_SHOP = 'REFER_SHOP';
export const REJECT_CHANGES = 'REJECT_CHANGES';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_VALUE_OFFER = 'REMOVE_VALUE_OFFER';
export const REMOVE_SPEND_OFFER = 'REMOVE_SPEND_OFFER';
export const RETRY_PAYMENT = 'RETRY_PAYMENT';
export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const SCRATCH_CARD_OFFERS_TILE = 'SCRATCH_CARD_OFFERS_TILE';
export const SCRATCH_CARDS_TAB = 'SCRATCH_CARDS_TAB';
export const SEE_ALL_BOGO_OFFERS = 'SEE_ALL_BOGO_OFFERS';
export const SEE_ALL_CATEGORIES = 'SEE_ALL_CATEGORIES';
export const SEE_ALL_COUPONS = 'SEE_ALL_COUPONS';
export const SEE_ALL_DISCOUNT_OFFERS = 'SEE_ALL_DISCOUNT_OFFERS';
export const SEE_ALL_FREE_GIFT_OFFERS = 'SEE_ALL_FREE_GIFT_OFFERS';
export const SEE_ALL_PAST_ORDER_PRODUCTS = 'SEE_ALL_PAST_ORDER_PRODUCTS';
export const SEE_ALL_SCRATCH_CARD = 'SEE_ALL_SCRATCH_CARD';
export const SEE_ALL_SHOP_BY_CATEGORY = 'SEE_ALL_SHOP_BY_CATEGORY';
export const SEE_ALL_SHOP_COUPONS = 'SEE_ALL_SHOP_COUPONS';
export const SEE_ALL_SPEND_OFFER = 'SEE_ALL_SPEND_OFFER';
export const SELECT_HOME_DELIVERY = 'SELECT_HOME_DELIVERY';
export const SELECT_SHOP_PICK_UP = 'SELECT_SHOP_PICK_UP';
export const SELL_ON_LOVELOCAL = 'SELL_ON_LOVELOCAL';
export const SHOP_FRONT_SPEND_OFFER = 'SHOP_FRONT_SPEND_OFFER';
export const SHOP_FRONT_COUPON_OFFER = 'SHOP_FRONT_COUPON_OFFER';
export const SHOP_OFFERS_TAB = 'SHOP_OFFERS_TAB';
export const SHOPS_TAB = 'SHOPS_TAB';
export const STORE_FILTER_SELECTION = 'STORE_FILTER_SELECTION';
export const STORE_CATEGORY_SELECTION = 'STORE_CATEGORY_SELECTION';
export const STORE_FILTERS_LAUNCHED = 'STORE_FILTER_LAUNCHED';
export const STORE_FILTERS_APPLIED = 'STORE_FILTERS_APPLIED';
export const STORE_FILTERS_CLEARED = 'STORE_FILTERS_CLEARED';
export const STORE_SHARE_CLICKED = 'STORE_SHARE_CLICKED';
export const STORE_SHARE_SELECTION = 'STORE_SHARE_SELECTION';
export const SPECIAL_OFFERS_TILE = 'SPECIAL_OFFERS_TILE';
export const SPEND_OFFER = 'SPEND_OFFER';
export const STORE = 'STORE';
export const STORE_FAVOURITE = 'STORE_FAVOURITE';
export const STORE_UNFAVOURITE = 'STORE_UNFAVOURITE';
export const SUB_CATEGORY = 'SUB_CATEGORY';
export const SUBMIT_NEW_ADDRESS = 'SUBMIT_NEW_ADDRESS';
export const TERMS_AND_CONDITION = 'TERMS_AND_CONDITION';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const VIEW_DETAILS = 'VIEW_DETAILS';
export const VISIT_STORE_DETAILS = 'VISIT_STORE_DETAILS';
export const OFFER_COUPON_BANNER = 'OFFER COUPON BANNER';
export const GLOBAL_SEARCH = 'GLOBAL SEARCH';
export const LL_SHOPS = 'LL SHOPS';
export const LL_OFFERS = 'LL OFFERS';
export const SEE_ALL_MONTHLY_SAMAN = 'SEE_ALL_MONTHLY_SAMAN';
export const SEE_ALL_SHOPS = 'SEE_ALL_SHOPS';
export const TRENDING_PRODUCTS = 'TRENDING_PRODUCTS';
export const FAVOURITE_SHOP = 'FAVOURITE_SHOP';
export const USE_CURRENT_LOCATION = 'USE_CURRENT_LOCATION';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const SELECT_PAYMENT_METHOD = 'Select Payment Method';
export const PAY_CASH_ON_DELIVERY = 'Pay Cash On Delivery';
export const PAY_ONLINE = 'Pay Online';
export const PAY_ONLINE_ON_DELIVERY = 'Pay Online On Delivery';

// Web engage event name changes
export const SEE_ALL_CLICKED = 'See All Clicked';
