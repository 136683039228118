import React from 'react';
import PropTypes from 'prop-types';
import { CustomModal, Svg } from 'app/components/common';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import CustomScroller from 'app/components/common/CustomScroller';
// eslint-disable-next-line import/no-cycle
import ProductThumb from 'app/components/common/ProductThumb';
import { useSelector } from 'react-redux';
import CustomBottomSheet from '../../common/CustomBottomSheet';

function NotifyRecommendedProducts({
  notifyProducts, onHideModal, page,
}) {
  const cartItems = useSelector((state) => state.main.cartItems);
  const isMobile = window.screen.width < 992;

  const body = (
    <Container
      className="bg-light border-radius-8 "
    >
      {
        (() => {
          let productsJSX = null;
          if (notifyProducts?.status === 'loading') {
            productsJSX = (
              <Row
                className="p-4 justify-content-center"
              >
                <Spinner
                  variant="primary"
                />
              </Row>
            );
          } else if (notifyProducts?.status === 'error') {
            productsJSX = (
              <Row
                className="p-4 justify-content-center"
              >
                <b>
                  Oops something went wrong, Please try again.
                </b>
              </Row>
            );
          } else {
            productsJSX = (
              <Row
                className="px-3 pb-3"
              >
                {
                  !isMobile && (
                    <Col
                      xs={24}
                      className="px-0 d-flex justify-content-end"
                    >
                      <Button
                        variant="link"
                        onClick={() => {
                          onHideModal();
                        }}
                        className="px-0"
                      >
                        <Svg
                          svg="close"
                          fill="#000"
                          width={24}
                        />
                      </Button>
                    </Col>
                  )
                }
                <Col
                  xs={24}
                  className={`pb-5 fs-4 text-weight-bold ${notifyProducts ? '' : 'd-flex justify-content-center'} `}
                >
                  We’ll notify you when this product is available!
                </Col>
                { notifyProducts?.data?.data.length > 0 && (
                <>
                  <Col
                    xs={24}
                    className="pb-3 fs-3 font-weight-black"
                  >
                    Check out these Similar Products
                  </Col>
                  <Col
                    xs={24}
                  >
                    <CustomScroller
                      content={(
                        <div className="d-flex">
                          {
                          notifyProducts?.data?.data?.map((product) => (
                            <div
                              key={product.libraryProductId}
                              className="mr-3 bg-white border-radius-16"
                            >
                              <ProductThumb
                                key={product.libraryProductId}
                                libraryProductId={product.libraryProductId}
                                retailProductId={product.retailerProductId}
                                name={product.name}
                                image={product.primaryImage}
                                mrp={product.mrp}
                                sellingPrice={product.sellingPrice}
                                measurementDisplayText={product.measurementDisplayText}
                                storeId={product.storeId}
                                offerPercent={product.offerPercent}
                                previouslyBought={product.previouslyBought}
                                inStock={product.inStock}
                                cartItems={cartItems}
                                notifyProd
                                page={page}
                                section="Notify Similar Products"
                              />
                            </div>
                          ))
                        }
                        </div>
                      )}
                      contentPrefix="notifyProducts"
                    />
                  </Col>
                </>
                )}
              </Row>
            );
          }
          return productsJSX;
        })()
      }
    </Container>
  );

  return (
    isMobile ? (
      <CustomBottomSheet
        show={notifyProducts.showModal}
        onHide={onHideModal}
        body={body}
      />
    ) : (
      <CustomModal
        show={notifyProducts.showModal}
        onHide={onHideModal}
        backdrop
        size={notifyProducts?.data?.data?.length > 0 ? 'lg' : 'md'}
        body={body}
      />
    )
  );
}

NotifyRecommendedProducts.propTypes = {
  notifyProducts: PropTypes.shape({
    showModal: PropTypes.bool,
    data: PropTypes.shape({
      data: PropTypes.arrayOf({}),
    }),
    status: PropTypes.string,
  }).isRequired,
  onHideModal: PropTypes.func.isRequired,
  page: PropTypes.string,
};

NotifyRecommendedProducts.defaultProps = {
  page: null,
};
export default NotifyRecommendedProducts;
