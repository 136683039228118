import React from 'react';
import PropTypes from 'prop-types';

const MoreOption = ({ fill, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
  >
    <path
      fill="#000"
      d="M16 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM16 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM16 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
  </svg>
);

MoreOption.propTypes = {
  fill: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default MoreOption;
