import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  width,
  fill,
  stroke,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 73 72"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fill} transform="translate(-274 -563) translate(0 482) translate(0 57) translate(274 24)">
        <ellipse cx="36.2" cy="36" rx="27.15" ry="27" />
      </g>
      <g stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M6.525 9H25.15c1.105 0 2 .895 2 2v18.5c0 1.105-.895 2-2 2H6.525c-1.105 0-2-.895-2-2V11c0-1.105.895-2 2-2zm4.787-4.5h18.363c1.105 0 2 .895 2 2v18.25h0" transform="translate(-274 -563) translate(0 482) translate(0 57) translate(274 24) translate(18.1 18)" />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  width: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
};

export default Svg;
