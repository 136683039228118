import React from 'react';
import PropTypes from 'prop-types';

const FillArrow = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm1.566-14.066a.8.8 0 1 0-1.132 1.132L14.57 11.2H7.5a.8.8 0 0 0 0 1.6h7.069l-2.135 2.134a.8.8 0 0 0 1.132 1.132l3.5-3.5a.799.799 0 0 0 0-1.132l-3.5-3.5Z"
      clipRule="evenodd"
    />
  </svg>
);

FillArrow.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
};

export default FillArrow;
