import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    style={{
      width,
    }}
    fill={fill}
  >
    <circle opacity="0.5" cx="20" cy="20" r="20" fill="black" />
    <path
      d="M32 20L13.25 30.3923L13.25 9.60769L32 20Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
