import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({
  width, height, fill, stroke, stroke1,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="20.0002"
      cy="21.6667"
      r="11.6667"
      fill={fill}
      stroke={stroke}
      strokeWidth="1.4"
    />
    <path
      d="M6.6665 11.6667L9.99984 8.33337"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.3335 11.6667L30.0002 8.33337"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 16.6666V21.6666L23.3333 25"
      stroke={stroke1}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  stroke1: PropTypes.string.isRequired,
};

export default Svg;
