import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from 'api/utils';
import Axios from 'axios';

const { newOOBaseUrl, OOBaseUrl } = apiUrls();
const homeDeliveryV1 = 'homedelivery/v1';

export const storeCategoryProducts = (storeCode, cancelToken, params = {}) => {
  const storeCatProducts = `${newOOBaseUrl}/store/store-category-products/${storeCode}`;
  return Axios({
    url: storeCatProducts,
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    params: getParams(params),
    cancelToken,
  });
};

export const previousOrderProducts = (
  storeCode, cancelToken, params = {},
) => {
  const repeatOrderProducts = `${newOOBaseUrl}/store/shop-front/repeat-order-products/${storeCode}`;
  return (
    Axios({
      url: repeatOrderProducts,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders(), true),
      params: getParams(params),
      cancelToken,
    })
  );
};

export const getStoreCategories = (storeCode, cancelToken) => {
  const storeCategoriesUrl = `${newOOBaseUrl}store/store-categories/${storeCode}`;
  return Axios({
    url: storeCategoriesUrl,
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    cancelToken,
  });
};

export const storePreviousOrderProducts = (
  cancelToken, params = {},
) => {
  const repeatOrderProducts = `${OOBaseUrl}${homeDeliveryV1}/onlineorderstorepreviusorderproductlisting/`;
  return (
    Axios({
      url: repeatOrderProducts,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams(params),
      cancelToken,
    })
  );
};

export const storeProducts = (
  storeCode, cancelToken, params = {},
) => {
  const storeCategoriesUrl = `${newOOBaseUrl}store/store-products/${storeCode}`;
  return Axios({
    url: storeCategoriesUrl,
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    params: getParams(params),
    cancelToken,
  });
};

export const storeSuperSave = (
  storeCode, cancelToken, params = {},
) => {
  const storeCategoriesUrl = `${newOOBaseUrl}store/store-super-save/${storeCode}`;
  return Axios({
    url: storeCategoriesUrl,
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    params: getParams(params),
    cancelToken,
  });
};

export const storeSearch = (
  storeCode, cancelToken, params = {},
) => {
  const shopfrontSearch = `${newOOBaseUrl}store/shop-front-products/${storeCode}`;
  return Axios({
    url: shopfrontSearch,
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    params: getParams(params),
    cancelToken,
  });
};
