import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 26 26"
  >
    <path
      fill={fill}
      d="M9.407 3.25c1.082 0 1.969.836 2.049 1.898l.006.153v4.105c-.001 1.097-.863 2-1.958 2.051.398 2.603 2.437 4.648 5.038 5.055.042-1.049.865-1.888 1.895-1.967l.156-.006h4.105c1.08.002 1.965.839 2.045 1.9l.005.153v4.105c-.002 1.082-.838 1.967-1.9 2.047l-.152.006h-5.131c-6.697-.008-12.143-5.354-12.311-12.012l-.004-.304V5.303c0-1.082.838-1.968 1.9-2.047l.152-.006h4.105zm.002 2.053H5.304v5.131c.007 5.571 4.446 10.103 9.98 10.26l.282.003 5.132.01V16.6h-4.105v1.026c0 .567-.46 1.026-1.027 1.026-2.182.027-4.283-.827-5.828-2.37-1.545-1.542-2.404-3.641-2.381-5.824-.007-.277.098-.545.29-.742.167-.17.385-.276.618-.303l.118-.006h1.026V5.303zM22.75 3.25v8.036h-2.009V6.678L14.421 13 13 11.58l6.32-6.321h-4.606V3.25h8.036z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
