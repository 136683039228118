import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { gaLogAddOrRemoveProductFromCart } from 'ecommerce_ga_events/EcommerceGAEvents';
import { CustomModal, Svg } from '../../common';
import { Constants } from '../../../utilities';
import CartCounter from '../cart/CartCounter';
import * as LogClevertapEvent from '../../../../clevertap/LogEvent';
import { getCDNImageUrl } from '../../../utilities/Utils';

function Variants(props) {
  const {
    show, onClose, sellingStore, product, cartItems, language,
    isMobile,
  } = props;
  let totalAmount = 0;
  const content = (
    <Row
      id="variant"
      className={`mx-0 pt-3 flex-column bg-white ${isMobile ? 'h-100 w-100' : 'vh-90'}`}
    >
      <Col
        className="px-6 py-4 border-bottom flex-grow-0"
      >
        <Row
          className="mx-0 mb-3 justify-content-between"
        >
          <Col
            xs={6}
            className="product-image-div p-1 border rounded"
          >
            <img
              src={getCDNImageUrl(product.image)}
              alt={product.displayName}
              title={product.displayName}
              className="mh-100 mw-100"
            />
          </Col>
          {!isMobile && (
          <Col
            xs="auto"
            className="pr-0"
          >
            <Button
              variant="link"
              onClick={onClose}
              className="d-flex p-0"
            >
              <Svg
                svg="circleClose"
                pathFill={Constants.Color.black}
                circleFill={Constants.Color.secondary}
                width="1.4rem"
              />
            </Button>
          </Col>
          )}
        </Row>
        <div
          className="mb-1 fs-3 font-weight-bold"
        >
          {product.displayName}
        </div>
        <div>
          {sellingStore.store.displayName}
        </div>
      </Col>
      <Col
        className="px-6 flex-grow-1 overflow-y-scroll"
      >
        {sellingStore.allVariants.map((variant, index) => {
          const {
            sellingPrice, mrp, appPrice, available,
          } = variant;
          const cartItem = cartItems && cartItems[variant.retailerProductId];
          let quantity = 0;
          if (
            cartItem
            && cartItem.storeId === sellingStore.store.id
          ) {
            quantity = cartItem.quantity;
            totalAmount += quantity * (appPrice > 0
              ? appPrice
              : sellingPrice);
          }
          return (
            <Row
              className={`mx-0 py-4 align-items-center justify-content-between 
            ${index < sellingStore.allVariants.length - 1 ? 'dotted-border-bottom' : ''}
            ${available ? '' : 'text-secondary'}`}
            >
              <Col
                className="pl-0 pr-2 d-flex align-items-center"
              >
                <div
                  className="font-weight-bold"
                >
                  {`${variant.unitString} .`}
                </div>
                <div
                  className="ml-1"
                >
                  {appPrice > 0
                    ? `\u20B9 ${appPrice}`
                    : `\u20B9 ${sellingPrice}`}
                  {appPrice > 0 || sellingPrice < mrp
                    ? (
                      <del
                        className="text-secondary ml-1"
                      >
                        {`\u20B9 ${mrp}`}
                      </del>
                    )
                    : ''}
                </div>
              </Col>
              <Col
                xs="auto"
                className="pl-2 pr-0"
              >
                {available
                  ? (
                    <CartCounter
                      productInfo={{
                        storeName: sellingStore.store.displayName,
                        productName: product.displayName,
                        sellingPrice,
                        mrp,
                        imageUrl: product.image,
                        measurementDisplayText: variant.unitString,
                      }}
                      storeCode={sellingStore.store.id}
                      productId={variant.retailerProductId}
                      quantity={quantity}
                      onSuccess={(productCount, isAdding) => {
                        LogClevertapEvent.skusUpdatedToCart(
                          sellingStore.store.displayName,
                          product.displayName,
                          productCount,
                          product.categories,
                        );
                        gaLogAddOrRemoveProductFromCart(
                          isAdding,
                          sellingPrice,
                          {
                            item_id: variant.id,
                            item_name: product.displayName,
                            price: sellingPrice,
                            quantity: productCount,
                          },
                        );
                      }}
                    />
                  )
                  : (
                    <div
                      className="border rounded cart-counter fs-5 text-dark p-1"
                    >
                      &nbsp;
                      <span
                        className="fs-7"
                      >
                        {Constants.String.UNAVAILABLE[language].toUpperCase()}
                      </span>
                      &nbsp;
                    </div>
                  )}
              </Col>
            </Row>
          );
        })}
      </Col>
      <Col
        className="border-top py-2 flex-grow-0"
      >
        <Row
          className={`mx-0 align-items-center justify-content-between
            py-3 text-white font-weight-bold ${totalAmount ? 'bg-primary cursor-pointer'
            : 'bg-primary-light'}`}
          onClick={() => {
            if (totalAmount) {
              onClose();
            }
          }}
        >

          <Col
            xs="auto"
          >
            {totalAmount > 0 && (
              `${Constants.String.ITEM_TOTAL[language]}: \u20B9 ${totalAmount}`
            )}
          </Col>
          <Col
            xs="auto"
          >
            {Constants.String.DONE[language].toUpperCase()}
          </Col>
        </Row>
      </Col>
    </Row>
  );
  if (isMobile) {
    return content;
  }
  return (
    <CustomModal
      show={show}
      body={content}
      onHide={() => {}}
    />
  );
}

const mapStateToProps = (state) => ({
  cartItems: state.main.cartItems,
});

Variants.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    image: PropTypes.string,
    displayName: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.arrayOf({})),
  }).isRequired,
  sellingStore: PropTypes.shape({
    store: PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
    }),
    allVariants: PropTypes.arrayOf(
      PropTypes.shape({
      }),
    ),
  }).isRequired,
  cartItems: PropTypes.shape({}),
  language: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

Variants.defaultProps = {
  cartItems: null,
};

export default connect(mapStateToProps)(Variants);
