import React from 'react';
import PropTypes from 'prop-types';
import FooterMobile from 'app/layout/footer/FooterMobile';
import Footer from 'app/layout/footer/Footer';
import { isAuthenticated, LOVELOCAL } from '../../../utilities/Utils';
import { Constants } from '../../../utilities';

const ComingSoon = (props) => {
  const {
    language, toggleLocation,
  } = props;

  const manageLocation = () => {
    toggleLocation();
  };

  return (
    <div className="mx-0 px-0 container-fluid">
      <div className="text-center mx-0 justify-content-center p-6 w-100 row">
        <div className="mb-4 col-24">
          <img alt="" src="/images/coming-soon.png" className="error-placeholder" />
        </div>
        <div className="mb-4 col-24">
          <div className="mw-400p text-center mx-auto">
            <div className="font-weight-bold">No LoveLocal shops here yet</div>
            <div />
          </div>
        </div>
      </div>
      <div className="mx-0 px-6 mb-8 row">
        <div className="text-center fs-4 col-24">
          <div className="mw-300p mx-auto">
            <button
              type="button"
              className="d-block w-100 mb-4 py-3 btn btn-primary"
              onClick={() => {
                if (isAuthenticated()) {
                  window.open(
                    LOVELOCAL.referShopForm,
                    '_blank',
                  );
                }
              }}
            >
              {Constants.String.REFER_LOCAL_SHOPS[language].toUpperCase()}
            </button>
            <div className="text-wrap">
              {Constants.String.REFER_LOCAL_SHOPS_DES[language]}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mx-0 row">
        <button
          type="button"
          className="fs-4 text-primary font-weight-black btn btn-link"
          onClick={() => {
            manageLocation();
          }}
        >
          {Constants.String.CHANGE_LOCATION[language].toUpperCase()}
        </button>
      </div>

      <div className="d-md-none bg-black mt-2">
        <FooterMobile {...props} />
        <div className="p-6" />
      </div>

      <div className="d-none d-md-block bg-black mt-4">
        <Footer {...props} />
      </div>
    </div>
  );
};

ComingSoon.propTypes = {
  language: PropTypes.string.isRequired,
  toggleLocation: PropTypes.func.isRequired,
};

export default ComingSoon;
