import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { referralCodeGetReq, toggleLogin as toggleLoginAction } from 'app/store/Actions';
import Constants from '../../utilities/Constants';
import { Placeholder, Svg } from '../../components/common';
import { getReferralMeta } from '../../utilities/Storage';
import SocialShare from '../social-share/SocialShare';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import '../../styles/account/refer-and-earn.scss';

const ReferFriendInfo = (props) => {
  const {
    isMobile, language, toggleSideOverlay, page,
    referralCode, referralCodeReq, onHide, toggleLogin,
  } = props;
  const referralMeta = getReferralMeta();
  const [showShareOptions, setShowShareOptions] = useState(false);

  useEffect(() => {
    const fetchReferralCode = () => {
      referralCodeReq();
    };
    fetchReferralCode();
  }, [referralCodeReq]);

  if (!referralMeta) {
    return (
      <div className="py-4 text-center bg-white">
        <Placeholder
          language={language}
          imageSrc="/images/error-placeholder.png"
          heading={Constants.String.OH_NO[language]}
        />
      </div>
    );
  }
  return (
    <Container
      fluid
      className="h-100 bg-white"
    >
      <Row
        className={
          isMobile ? '' : 'px-6 pt-3'
        }
      >
        <Col
          xs={24}
          className={
            isMobile ? 'px-0' : ''
          }
        >
          <img
            alt=""
            src={referralMeta.heroImage}
            width="100%"
          />
        </Col>
      </Row>
      <Row
        className={
          isMobile ? 'pb-12' : 'px-6'
        }
      >
        {isMobile && (
          <Col
            xs={24}
            className="font-weight-bold fs-1 pt-3"
          >
            <b>{referralMeta.heading}</b>
          </Col>
        )}
        <Col
          xs={24}
          className={`font-weight-bold pt-6 ${isMobile ? 'fs-3' : ''}`}
        >
          <b>
            {
              isMobile
                ? Constants.String.HOW_IT_WORKS[language]
                : Constants.String.HOW_IT_WORKS[language].toUpperCase()
            }
            :
          </b>
        </Col>
        <Col
          xs={24}
          className="pt-3"
        >
          <div
            className="d-flex"
          >
            <div>
              <Svg
                svg="share"
                fill="none"
                stroke={Constants.Color.primary}
                width={isMobile ? 16 : 24}
              />
            </div>
            <div
              className="pl-3 fs-5"
            >
              {!isMobile && (
                <b>
                  {Constants.String.DOWNLOAD_THE_LOVELOCAL_APP[language]}
                  &nbsp;
                </b>
              )}
              {referralMeta.step1}
            </div>
          </div>
          <div
            className="d-flex pt-3"
          >
            <div>
              <Svg
                svg="finger"
                fill="none"
                stroke={Constants.Color.primary}
                width={isMobile ? 16 : 24}
              />
            </div>
            <div
              className="pl-3 fs-5"
            >
              {referralMeta.step2}
            </div>
          </div>
          <div
            className="d-flex pt-3"
          >
            <div>
              <Svg
                svg="offers"
                fill="none"
                stroke={Constants.Color.primary}
                width={isMobile ? 16 : 24}
              />
            </div>
            <div
              className="pl-3 fs-5"
            >
              {referralMeta.step3}
            </div>
          </div>
        </Col>
        {
          isMobile ? (
            <Col
              xs={24}
              className="mt-4 d-flex sm-share-button align-items-center"
            >
              <div
                className="py-2 px-3 border width-60"
              >
                <b>{referralCode}</b>
              </div>
              <Button
                variant="green"
                className="width-40 rounded-0"
                onClick={() => {
                  LogClevertapEvent.shareReferralClicked(page, referralCode);
                  if (isMobile && onHide) { onHide(); }
                  toggleSideOverlay(() => (
                    <SocialShare
                      language={language}
                      isMobile
                      toggleLogin={toggleLogin}
                    />
                  ));
                }}
              >
                <span>
                  <Svg
                    svg="forward"
                    width="1.6rem"
                    fill={Constants.Color.white}
                  />
                </span>
                <span
                  className="pl-2"
                >
                  {Constants.String.SHARE[language].toUpperCase()}
                </span>
              </Button>
            </Col>
          ) : ''
        }
      </Row>
      {
        !isMobile && (
          <Row
            className="p-6 text-center mt-6 border-top"
          >
            <Col xs={24} className="px-0">
              <Button
                variant="green"
                className={`w-100 rounded-0 p-4 d-flex justify-content-center ${showShareOptions ? 'pointer-event-none' : ''}`}
                onClick={() => {
                  LogClevertapEvent.shareReferralClicked(page, referralCode);
                  setShowShareOptions(true);
                }}
                disabled={showShareOptions}
              >
                <span>
                  <Svg
                    svg="forward"
                    width="1.6rem"
                    fill={Constants.Color.white}
                  />
                </span>
                <span
                  className="pl-2"
                >
                  {Constants.String.SHARE[language].toUpperCase()}
                </span>
              </Button>
            </Col>
            {
              showShareOptions && (
                <Col xs={24} className="px-0">
                  <SocialShare
                    language={language}
                    isMobile={isMobile}
                    toggleLogin={toggleLogin}
                  />
                </Col>
              )
            }
          </Row>
        )
      }
    </Container>
  );
};

const mapStateToProps = (state) => ({
  referralCode: state.main.referralCode,
});

const mapDispatchToProps = (dispatch) => ({
  referralCodeReq: () => {
    dispatch(referralCodeGetReq());
  },
  toggleLogin: () => {
    dispatch(toggleLoginAction());
  },
});

ReferFriendInfo.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  referralCodeReq: PropTypes.func.isRequired,
  referralCode: PropTypes.string.isRequired,
  onHide: PropTypes.string.isRequired,
  toggleLogin: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferFriendInfo);
