import * as React from 'react';

const StorePickUpChecked = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4H20V14.7603C20.4587 14.5936 20.9753 14.6597 21.3801 14.947L21.9208 15.3306C22.2841 15.5885 22.5 16.0065 22.5 16.452C22.5 16.9787 22.1991 17.4591 21.7253 17.6891L16.2432 20.3495C16.0391 20.4485 15.8152 20.5 15.5884 20.5H9.56426C9.15434 20.5 8.76227 20.3322 8.47923 20.0357L6.2065 17.6548C6.11216 17.5559 5.98147 17.5 5.84483 17.5H2.5C1.67157 17.5 1 16.8284 1 16V13C1 12.1716 1.67157 11.5 2.5 11.5H6V4ZM6.35511 12.4787C6.40097 12.4925 6.44962 12.5 6.5 12.5C6.65698 12.5 6.79706 12.4277 6.88872 12.3145L8.69299 11.6844C8.87818 11.6198 9.08407 11.6694 9.21951 11.8112L11.3429 14.0357C11.6259 14.3322 12.018 14.5 12.4279 14.5H14.4357C14.5724 14.5 14.7031 14.5559 14.7974 14.6548L15.1799 15.0554C15.2686 15.1484 15.3182 15.2721 15.3182 15.4007V16.5H12.4279C12.2913 16.5 12.1606 16.4441 12.0662 16.3452L10.4526 14.6548C10.2619 14.455 9.94542 14.4477 9.74567 14.6383C9.54592 14.829 9.53856 15.1455 9.72923 15.3452L11.3429 17.0357C11.6259 17.3322 12.018 17.5 12.4279 17.5H16.5267C16.7691 17.5 17.0079 17.4412 17.2227 17.3287L20.28 15.7273C20.4466 15.6401 20.648 15.6536 20.8014 15.7625L21.342 16.1462C21.4411 16.2165 21.5 16.3305 21.5 16.452C21.5 16.5957 21.4179 16.7267 21.2887 16.7894L15.8067 19.4498C15.7386 19.4828 15.664 19.5 15.5884 19.5H9.56426C9.42762 19.5 9.29693 19.4441 9.20259 19.3452L6.92986 16.9643C6.64682 16.6678 6.25475 16.5 5.84483 16.5H2.5C2.22386 16.5 2 16.2761 2 16V13C2 12.7239 2.22386 12.5 2.5 12.5H6.10315C6.18773 12.5 6.27202 12.4928 6.35511 12.4787Z"
      fill="black"
    />
  </svg>
);
export default StorePickUpChecked;
