import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../../styles/stores/stores.scss';

function ShopCategoryFilter(props) {
  const {
    categories, categoryURL, state,
  } = props;

  const { city, locality } = state;

  const getCityLocalityCategoryUrl = (getCity, getLocality = null, storeType = '') => {
    let url = '/india';
    if (getCity) {
      url = url.concat(`/${getCity}`);
    }
    if (getLocality) {
      url = url.concat(`/${getLocality}`);
    }
    if (storeType) {
      url = url.concat(`/c/${storeType}`);
    }
    return url;
  };

  return (

    <div className="d-inline-flex flex-column mx-0">
      {
          !!categories && categories.map((item) => (
            <Col
              xs="auto"
              key={item.id}
              className={`cursor-pointer ${item.urlStoreTypeDesc === categoryURL ? 'store-selected-category-bg' : ''} selected-category-btn-web border-light`}
            >
              <Link
                to={{
                  pathname: getCityLocalityCategoryUrl(city, locality, item.urlStoreTypeDesc),
                  state: {
                    scrollToTop: true,
                  },
                }}
              >
                <Row
                  className="d-flex align-items-center px-4 flex-nowrap"
                >
                  <div className="px-2">
                    <img
                      className="category_image"
                      src={item.image}
                      alt={item.description}
                    />
                  </div>
                  <div className={`${item.urlStoreTypeDesc === categoryURL ? 'text-primary' : 'text-black'} text-wrap flex-shrink px-2 align-left`}>
                    { item.description }
                  </div>
                </Row>
              </Link>
            </Col>
          ))
    }
    </div>
  );
}

ShopCategoryFilter.propTypes = {
  categoryURL: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    discription: PropTypes.string,
    urlStoreTypeDesc: PropTypes.string,
  })).isRequired,
  // onChangeCategory: PropTypes.func.isRequired,
  state: PropTypes.arrayOf(PropTypes.shape({
    city: PropTypes.string,
    locality: PropTypes.string,
  })),
};

ShopCategoryFilter.defaultProps = {
  categoryURL: '',
  state: '',
};

export default ShopCategoryFilter;
