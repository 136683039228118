/* eslint-disable no-nested-ternary */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { LoveLocal } from '../../utilities/partner/content';
import Shimmer from '../../shimmer/Shimmer';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import '../../styles/partner/styles.scss';

// const offsiteHeight = window.screen.width < 768;

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // offsetHeight: window.screen.height,
    };
    this.partnerWrapperRef = React.createRef();
  }

  componentDidUpdate = () => {
    const { location, history } = this.props;
    const { scrollToTop } = location.state || {};
    if (scrollToTop) {
      this.partnerWrapperRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
      history.replace({ state: { scrollToTop: false } });
    }
  }

  render() {
    const {
      Component, headerTheme, page, match,
      history,
    } = this.props;
    // const { offsetHeight } = this.state;
    const language = match.params.lang;
    return (
      <ErrorBoundary
        language={language}
      >
        <Suspense
          fallback={(
            <Shimmer
              shimmerType="FALLBACK"
            />
          )}
        >
          <Container
            fluid
            id="partner"
            className="h-100 p-0"
            ref={this.partnerWrapperRef}
          >
            <Row
              className={`bg-white m-0 p-0
            shadow-sm wrapper-header d-none d-md-block`}
            >
              <Row className="mx-0">
                <Col xs={12} className="d-flex">
                  <a
                    {...LoveLocal.lovelocal}
                  >
                    <img
                      alt="LoveLocal-Icon"
                      src={headerTheme === 'BLUE'
                        ? '/Assets/logo.png'
                        : '/Assets/logo-pink.png'}
                      height="90px"
                      className={headerTheme === 'BLUE'
                        ? 'py-4'
                        : 'my-n1'}
                    />
                  </a>
                </Col>
                {
                  (page === 'home' || page === 'thank-you')
                    ? (
                      <Col
                        xs={12}
                        className="text-right py-6"
                      >
                        <Button
                          variant={language === 'en'
                            ? headerTheme === 'PINK'
                              ? 'pink shadow-sm' : 'violet shadow-sm'
                            : 'gray'}
                          className="py-0 px-4"
                          onClick={() => {
                            history.push(
                              `${
                                history.location.pathname.replace(`/${language}`, '/en')
                              }${
                                history.location.search || ''
                              }`,
                            );
                          }}
                        >
                          ENG
                        </Button>
                        <Button
                          variant={language === 'hi'
                            ? headerTheme === 'PINK'
                              ? 'pink shadow-sm' : 'violet shadow-sm'
                            : 'gray'}
                          className="py-0 px-4"
                          onClick={() => {
                            history.push(
                              `${
                                history.location.pathname.replace(`/${language}`, '/hi')
                              }${
                                history.location.search || ''
                              }`,
                            );
                          }}
                        >
                          हिंदी
                        </Button>
                      </Col>
                    )
                    : (
                      <Col xs={12} className="text-right py-6 d-flex flex-row-reverse">
                        <div className="shadow px-4 py-2">
                          <a
                            {...LoveLocal.lovelocal}
                          >
                            <span className={`${headerTheme === 'PINK' ? '' : 'text-violet'} font-weight-bold`}>Shop Now</span>
                          </a>
                        </div>
                      </Col>
                    )
                }
              </Row>
            </Row>
            <Row
              className="bg-white m-0 p-0 shadow-sm wrapper-header d-block d-md-none"
            >
              <Col xs={24} className="d-flex justify-content-between">
                <div>
                  <a
                    {...LoveLocal.lovelocal}
                  >
                    <img
                      alt="LoveLocal-Icon"
                      src={headerTheme === 'BLUE'
                        ? '/Assets/logo.png'
                        : '/Assets/logo-pink.png'}
                      height={headerTheme === 'BLUE'
                        ? '70px'
                        : '90px'}
                      className={headerTheme === 'BLUE'
                        ? 'py-2'
                        : 'my-n2'}
                    />
                  </a>
                </div>
                {
                  (page === 'home' || page === 'thank-you')
                    ? (
                      <div className="py-6">
                        <Button
                          variant={language === 'en'
                            ? headerTheme === 'PINK'
                              ? 'pink shadow-sm' : 'violet shadow-sm'
                            : 'gray'}
                          className="py-0 px-4"
                          onClick={() => {
                            history.push(
                              `${
                                history.location.pathname.replace(`/${language}`, '/en')
                              }${
                                history.location.search || ''
                              }`,
                            );
                          }}
                        >
                          ENG
                        </Button>
                        <Button
                          variant={language === 'hi'
                            ? headerTheme === 'PINK'
                              ? 'pink shadow-sm' : 'violet shadow-sm'
                            : 'gray'}
                          className="py-0 px-4"
                          onClick={() => {
                            history.push(
                              `${
                                history.location.pathname.replace(`/${language}`, '/hi')
                              }${
                                history.location.search || ''
                              }`,
                            );
                          }}
                        >
                          हिंदी
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <a
                          href="https://lovelocal.sng.link/Dg5ou/vm3b"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-3 px-lg-4 d-flex align-items-center"
                        >
                          <span>
                            <img
                              src="/images/play-store-round-icon.png"
                              alt="google-play-app"
                              width="24px"
                            />
                          </span>
                        </a>
                        <div className="shadow px-4 py-2 ">
                          <a
                            {...LoveLocal.lovelocal}
                          >
                            <span className={`${headerTheme === 'PINK' ? '' : 'text-violet'} font-weight-bold`}>Shop Now</span>
                          </a>
                        </div>
                      </div>
                    )
                }
              </Col>
            </Row>
            <Row
              className="main-container m-0 partner-wrapper-view"
            >
              <Component
                {...this.props}
                language={language}
              />
            </Row>

            {/* <Row
            className="scroll-up-button justify-content-center align-items-center"
          >
            <Col
              xs="auto"
              className="cursor-pointer opacity-10 rotate-n90deg p-2 bg-white border-radius-rounded"
              onClick={() => {
                this.partnerWrapperRef.current.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              <img
                alt=""
                src="/images/oval.png"
                width="32px"
                height="32px"
                className="shadow-lg"
              />
            </Col>
          </Row> */}
          </Container>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

Wrapper.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.element, PropTypes.func, PropTypes.object,
  ]).isRequired,
  headerTheme: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lang: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
};

export default Wrapper;
