/* eslint-disable camelcase */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QueryString from 'query-string';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { refreshToken, servicableStoreCount } from 'api/api';
import {
  TopMenu, BottomMenu, SideOverlay, CustomerSupport,
} from './layout/wrapper';
import { Login } from './layout/account';
import * as Actions from './store/Actions';
import {
  BackDrop, CustomModal, Placeholder,
} from './components/common';
import ErrorBoundary from './components/common/ErrorBoundary';
import GlobalSearch from './pages/global_search/GlobalSearch';
import * as Clevertap from '../clevertap/Clevertap';
import * as screens from './events/screens';
import { Constants, Storage, Utils } from './utilities';
import GuestUserCartBar from './components/common/GuestUserCartBar';
import ScratchCard from './components/common/ScratchCard';
import RateOrder from './layout/order/RateOrder';
import SubmitFeedback from './layout/order/SubmitFeedback';
import {
  LOVELOCAL, isAlreadyLoggedIn, isGuestUser,
} from './utilities/Utils';
import * as Freshchat from '../freshchat/Freshchat';
import * as LogClevertapEvent from '../clevertap/LogEvent';
import GuestLocation from './layout/account/GuestLocation';
import Language from './pages/changeLanguage/Language';
import {
  dummyAddressPayload, dummyProfile,
} from './utilities/Config';
import GuestUserCart from './pages/cart/GuestUserCart';
import Shimmer from './shimmer/Shimmer';
import TopNavSearchBar from './components/common/TopNavSearchBar';
import MultiStoreModal from './components/common/MultiStoreModal';
import { cartLoadActions, cartProductActions, highlightCartOfferRequest } from './store/cart/actions';
import ManageAddress from './layout/address/ManageAddress';
import { activeOrderActions } from './store/orders/action';
import DownloadAppStrip from './components/common/DownloadAppStrip';
import ComingSoon from './components/common/homepage/ComingSoon';
import ValueUnlockingStrip from './pages/cart/cart-applicable-offers';
import Modal from './layout/modal';
import Account from './pages/account/Account';
import valueUnlockingStripEL from './event-listeners/CommonEventListener';

const isMobile = window.screen.width < 992;
const isTablet = window.screen.width > 768 && window.screen.width < 992;
const ACCESS_TOKEN_TTL = 23 * 60 * 60 * 1000;

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    const queryParam = QueryString.parse(props.location.search);
    const { b2bStorePreview } = queryParam;
    this.state = {
      loading: !(b2bStorePreview === 'true'),
      isGuestLoginError: false,
      sideOverlayComponent: null,
      sideOverlayCloseButton: false,
      customerSupportOverlay: false,
      callSupportDialog: false,
      fadeCartBar: false,
      rateOrder: null,
      showLanguagePopup: false,
      b2bStorePreview: b2bStorePreview === 'true',
      showDownloadAppStrip: true,
      isServicableLocation: true,
      showAccountDropdown: false,
    };
    this.activeTimeout = null;
    this.contentHeight = 0;
    this.scrolledContent = 0;
    this.chatHelpOpenedAlready = false;
    this.reactRef = React.createRef();
  }

  componentDidMount() {
    const {
      location, history, language, changeLanguage,
      customer, isLogin, toggleLogin,
    } = this.props;

    // If Login route requested
    if (isLogin) {
      toggleLogin();
    }

    // if b2b store preview
    const { b2bStorePreview } = this.state;
    if (b2bStorePreview) {
      return;
    }

    // if very new guest user
    if (!customer) {
      this.performGuestLogin();
      return;
    }

    if (isAlreadyLoggedIn()) {
      valueUnlockingStripEL(document.getElementById('main-content'));
      this.updateAccessToken();
      const queryParam = QueryString.parse(location.search);
      const {
        rateOrderId, shopId, shopName, isShopPickup,
      } = queryParam;
      let rateOrder = Storage.getRateOrder() || null;
      if (
        rateOrderId
        && shopId
        && shopName
        && isShopPickup
      ) {
        rateOrder = {
          orderId: rateOrderId,
          shopId,
          shopName,
          isShopPickup: (isShopPickup !== 'false'),
        };
        history.push('/');
        Storage.setRateOrder(rateOrder);
      }
      if (rateOrder) {
        this.setState({
          rateOrder: {
            ...rateOrder,
            rating: 0,
          },
          sideOverlayComponent: isMobile
            ? () => (
              <RateOrder
                isMobile={isMobile}
                language={language}
                rateOrder={rateOrder}
                handleOrderRatingStar={this.handleOrderRatingStar}
                handleChatSupport={() => {
                  this.setState({
                    sideOverlayComponent: null,
                    rateOrder: null,
                  });
                  Freshchat.open();
                }}
              />
            )
            : null,
        });
      }
      if (!Storage.getLanguage()) {
        changeLanguage(language);
      }
      setTimeout(() => {
        this.launchFreshchat();
      }, 1500);
    }
    this.handleLoad();
  }

  componentDidUpdate = () => {
    this.launchFreshchat();
    const { location, history } = this.props;
    const { scrollToTop } = location.state || {};
    if (scrollToTop) {
      this.reactRef.current.scrollTo(0, 0);
      history.replace({
        state: { ...location.state, scrollToTop: false },
        search: location.search,
      });
    }
  }

  componentWillUnmount() {
    // removing event listener
    valueUnlockingStripEL(document.getElementById('main-container'), true);
  }

  checkServiceability = () => {
    const { selectedAddress } = this.props;
    servicableStoreCount(
      {
        latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
        longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
      },
    ).then((res) => {
      const { data } = res.data;
      const { store_count } = data || {};
      this.setState({ isServicableLocation: !!store_count });
    });
  }

  updateAccessToken = (retryCount = 3) => {
    const { logout, history } = this.props;
    const lastAccessTokenUpdatedAtEpoch = Storage.getLastAccessTokenUpdatedAtEpoch();

    const logoutUser = () => {
      logout();
      LogClevertapEvent.logout();
      history.push('/');
      this.performGuestLogin();
    };

    if (
      !!Number(lastAccessTokenUpdatedAtEpoch)
      && ((new Date()).getTime() - Number(lastAccessTokenUpdatedAtEpoch)) > (ACCESS_TOKEN_TTL)
    ) {
      const refresh_token = Storage.getRefreshToken() || '';
      const access_token = Storage.getAccessToken() || '';
      refreshToken(
        'post',
        {
          refresh_token,
          access_token,
          role: 'CUSTOMER',
        },
      ).then((res) => {
        const tokens = res.data;
        Storage.setAccessToken(tokens.data.access_token);
        Storage.setRefreshToken(tokens.data.refresh_token);
        Storage.setLastAccessTokenUpdatedAtEpoch(new Date().getTime());
        setTimeout(() => {
          this.updateAccessToken();
        }, (new Date().getTime()) + (ACCESS_TOKEN_TTL));
      }).catch((error) => {
        if (error?.response?.data) {
          if (error.response.status === 401) {
            logoutUser();
          } else {
            setTimeout(() => {
              if (retryCount > 1) {
                this.updateAccessToken(retryCount - 1);
              } else {
                logoutUser();
              }
            }, 2000);
          }
        }
      });
    } else if (!Number(lastAccessTokenUpdatedAtEpoch)) {
      logoutUser();
    } else {
      const updateTokenAfter = ((
        Number(lastAccessTokenUpdatedAtEpoch) + (ACCESS_TOKEN_TTL)
      ) - new Date().getTime());
      setTimeout(() => {
        this.updateAccessToken();
      }, updateTokenAfter);
    }
  }

  launchFreshchat = () => {
    if (this.chatHelpOpenedAlready) {
      return;
    }
    const { location } = this.props;
    const queryParam = QueryString.parse(location.search);
    const { chatHelp } = queryParam;
    if (chatHelp && window.fcWidget) {
      Freshchat.open();
      this.chatHelpOpenedAlready = true;
    }
  }

  performGuestLogin = () => {
    const cartItems = Storage.getCartItems();
    const {
      updateCustomer,
      updateCartItems,
      toggleManageLocation,
    } = this.props;
    updateCustomer(dummyProfile);
    updateCartItems(cartItems || {});
    toggleManageLocation();
    this.setState({
      loading: false,
    }, () => {
      LogClevertapEvent.guestLogin(
        Utils.getTimeElapsedInSec(Date.now()),
      );
    });
  }

  handleLoad = () => {
    const {
      addressLoadRequest,
      recentAddressLoadRequest,
      cartLoadRequest,
      customer,
      profileLoadRequest,
      history,
      addAddress,
      toggleLogin,
      cartIdLoadRequest,
      activeOrdersReq,
      address,
      toggleManageLocation,
    } = this.props;
    const onFailure = {
      type: 'sync',
      callback: () => {
        history.push('/');
        this.setState({
          loading: false,
        });
      },
    };
    if (isAlreadyLoggedIn()) {
      activeOrdersReq();
      profileLoadRequest({
        type: 'sync',
        callback: (data) => {
          Clevertap.pushProfile({
            ...data.res,
            dob: new Date(data.res.dob),
          });
        },
      });
      addressLoadRequest(
        {
          type: 'async',
          callback: (data, addressLoadSuccess) => {
            const { res } = data;
            if (res.length > 0) {
              cartLoadRequest(
                {
                  isCheckout: false,
                },
                {
                  type: 'sync',
                  callback: () => {
                    addressLoadSuccess();
                    this.checkServiceability();
                    Clevertap.checkNotificationPermission();
                    this.setState({
                      loading: false,
                    });
                  },
                },
                onFailure,
              );
            } else if (!customer.phone_number) {
              addressLoadSuccess();
              addAddress(
                dummyAddressPayload,
                {
                  type: 'sync',
                  callback: () => {
                    this.checkServiceability();
                    cartIdLoadRequest();
                    this.setState({
                      loading: false,
                    });
                    LogClevertapEvent.defaultLocationSelected({
                      SCREEN: screens.GUEST_LOCATION,
                      address_text: dummyAddressPayload.addressText,
                    });
                  },
                },
                onFailure,
              );
            } else {
              this.checkServiceability();
              addressLoadSuccess();
              toggleLogin();
              this.setState({
                loading: false,
              });
            }
          },
        },
        onFailure,
      );
      recentAddressLoadRequest();
    } else {
      this.checkServiceability();
      if (!address || address?.isDummy) {
        toggleManageLocation();
      }
      this.setState({
        loading: false,
      });
    }
  }

  toggleSideOverlay = (Component, closeButton = true, onHomepage = false, onShopspage = false) => {
    this.setState({
      sideOverlayComponent: Component,
      sideOverlayCloseButton: closeButton,
      onHomepage,
      onShopspage,
    });
  };

  toggleAccountDropdown = () => {
    this.setState((state) => ({
      showAccountDropdown: !state.showAccountDropdown,
    }));
  };

  toggleCustomerSupportOverlay = () => {
    this.setState((state) => ({
      customerSupportOverlay: !state.customerSupportOverlay,
    }));
  }

  toggleLanguagePopup = () => {
    this.setState((state) => ({
      showLanguagePopup: !state.showLanguagePopup,
    }));
  }

  toggleSupportDialog = () => {
    this.setState((state) => ({
      callSupportDialog: !state.callSupportDialog,
    }));
  }

  closeDownloadAppStrip = () => {
    this.setState(() => ({
      showDownloadAppStrip: false,
    }));
  }

  handleCartBarView = (e) => {
    const { cartDetails, cartItems, activeOrders } = this.props;
    const { fadeCartBar } = this.state;
    const { scrollHeight, scrollTop, scrollLeft } = e.target;
    const setTimer = () => {
      this.activeTimeout = setTimeout(() => {
        this.setState({
          fadeCartBar: false,
        });
      }, 200);
    };
    if (
      ((isGuestUser() && (!!cartItems && Object.keys(cartItems).length))
        || (!isGuestUser() && !!cartDetails && cartDetails.cartProductsCount)
        || (isAlreadyLoggedIn() && !!activeOrders))
      && !scrollLeft && scrollHeight === this.contentHeight
      && Math.abs(scrollTop - this.scrolledContent) > 10) {
      this.contentHeight = scrollHeight;
      this.scrolledContent = scrollTop;
      if (fadeCartBar) {
        if (this.activeTimeout) {
          clearTimeout(this.activeTimeout);
        }
        setTimer();
        return;
      }
      this.setState({
        fadeCartBar: true,
      }, () => {
        setTimer();
      });
      return;
    }
    this.contentHeight = scrollHeight;
  }

  onClickGuestUserCartBar = () => {
    const { history } = this.props;
    if (isMobile) {
      history.push('/guestUserCart');
      return;
    }
    this.toggleSideOverlay(GuestUserCart);
  }

  handleOrderRatingStar = (rating = 0) => {
    const { rateOrder } = this.state;
    const { history } = this.props;
    this.setState({
      rateOrder: {
        ...rateOrder,
        rating,
      },
    }, () => {
      if (isMobile) {
        history.push(
          '/order-feedback',
          {
            fromRateOrder: true,
          },
        );
      }
    });
  }

  render() {
    const {
      Component, history, address, page,
      toggleLogin, location, cartItems,
      scratchCard, language, toggleGuestLocationPopup,
      toggleGuestLocation, isMandatoryGuestLocationPopup,
      toggleMultiStoreModal, showMultiStoreCheckoutDialog,
      guestCustomCartItems, isWhitePage, cartDetails,
      showReferNEarn, toggleManageLocation,
    } = this.props;

    const {
      loading, sideOverlayComponent, sideOverlayCloseButton,
      customerSupportOverlay, callSupportDialog, fadeCartBar,
      rateOrder, showLanguagePopup, isGuestLoginError, b2bStorePreview,
      onHomepage, onShopspage, showDownloadAppStrip, showAccountDropdown,
      isServicableLocation,
    } = this.state;

    const cartBar = ['home', 'shops', 'offers',
      'offers-coupon', 'categories', 'shop-detail',
      'category-landing', 'all-city-locality', 'seo-stores', 'local-stores',
      'monthly-saman-list', 'trending', 'brands-listing', 'max-savings',
      'bogo-offers', 'past-products', 'store-wise-order-past-products',
      'order-past-products', 'reward-coupon', 'offers-reward', 'shop-coupons',
      'popular-products', 'applicable-value-offers', 'shops-top-saving-products',
      'shops-category-products', 'shops-combo-offers', 'shops-repeat-products',
    ].includes(page);

    const isServicableLocationUpdated = isServicableLocation || !['home', 'shops', 'offers'].includes(page);

    const mSiteGlobalSearch = isMobile && ['home',
      'offers-spend', 'offers-discount', 'offers-coupon', 'categories',
      'category-landing', 'global-search', 'local-stores', 'seo-stores'].includes(page);

    const currentPath = location.pathname;
    const showBottomMenu = isMobile && (
      currentPath === '/'
      || currentPath === '/shops'
      || currentPath === '/account'
      // || currentPath === '/cart'
      // || currentPath === '/orders'
    );

    if (isGuestLoginError) {
      return (
        <Container
          className="h-100 d-flex justify-content-center align-items-center"
        >
          <Row>
            <Col
              xs={24}
            >
              <Placeholder
                language={language}
                handleRetry={() => {
                  this.setState({
                    loading: true,
                    isGuestLoginError: false,
                  }, this.performGuestLogin);
                }}
                imageSrc="/images/error-placeholder.png"
                heading={Constants.String.OH_NO[language]}
              />
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Container
        fluid
        className="main-body"
      >
        <CustomModal
          show={showLanguagePopup}
          title={Constants.String.SELECT_LANGUAGE[language]}
          body={(
            <Language
              {...this.props}
              callback={() => {
                this.toggleLanguagePopup();
              }}
            />
          )}
          onHide={() => {
            this.toggleLanguagePopup();
          }}
          closeButton
        />
        <CustomModal
          show={toggleGuestLocationPopup}
          onHide={() => {
            if (!isMandatoryGuestLocationPopup) {
              toggleGuestLocation(false);
            }
          }}
          size="lg"
          body={(
            <GuestLocation
              {...this.props}
              isMobile={isMobile}
              toggleGuestLocation={(toggleValue) => {
                toggleGuestLocation(toggleValue);
              }}
              toggleLanguagePopup={() => {
                this.toggleLanguagePopup();
              }}
            />
          )}
          backdrop
        />
        {!isMobile && !!rateOrder && !loading && (
          <CustomModal
            show
            body={rateOrder.rating
              ? (
                <SubmitFeedback
                  isMobile={isMobile}
                  language={language}
                  histor={history}
                  location={location}
                  rateOrder={rateOrder}
                  onOrderFeedbackSuccess={() => {
                    this.setState({
                      rateOrder: null,
                    });
                    Storage.setRateOrder(null);
                  }}
                />
              )
              : (
                <RateOrder
                  isMobile={isMobile}
                  language={language}
                  rateOrder={rateOrder}
                  handleOrderRatingStar={this.handleOrderRatingStar}
                  handleChatSupport={() => {
                    this.setState({
                      rateOrder: null,
                    });
                    Freshchat.open();
                  }}
                />
              )}
            onHide={() => { }}
          />
        )}
        <Login
          isMobile={isMobile}
          language={language}
          history={history}
          toggleLanguagePopup={this.toggleLanguagePopup}
          updateAccessToken={this.updateAccessToken}
          toggleSideOverlay={this.toggleSideOverlay}
        />
        <CustomModal
          show={callSupportDialog}
          body={(
            <div
              className="text-center position-relative py-8 bg-white"
            >
              <div
                className="call-close"
              >
                <Button
                  variant="link"
                  className="fs-1 z-index-1 text-medium"
                  onClick={this.toggleSupportDialog}
                >
                  &times;
                </Button>
              </div>
              <div
                className="fs-2 text-primary"
              >
                {LOVELOCAL.support}
              </div>
              <div
                className="fs-5 text-medium"
              >
                {Constants.String.SUPPORT_DESCRIPTION[language]}
              </div>
            </div>
          )}
          size="md"
          onHide={this.toggleSupportDialog}
          backdrop
        />
        {showMultiStoreCheckoutDialog && (
          <MultiStoreModal
            toggleMultiStoreModal={toggleMultiStoreModal}
            showMultiStoreCheckoutDialog={showMultiStoreCheckoutDialog}
          />
        )}
        {!!scratchCard && (
          <ScratchCard
            reward={scratchCard}
            language={language}
            isMobile={isMobile}
            history={history}
          />
        )}
        <ManageAddress
          {...this.props}
          page={page}
          isMobile={isMobile}
          history={history}
          language={language}
        />
        <Modal
          show={showAccountDropdown}
          onHide={this.toggleAccountDropdown}
          bodystyle={!isMobile ? {
            left: '60%',
            borderRadius: '16px',
            maxHeight: '524px',
            width: '375px',
          } : {}}
        >
          <Account
            isMobile={isMobile}
            history={history}
            language={language}
            performGuestLogin={() => {
              this.setState({
                loading: true,
              }, this.performGuestLogin);
            }}
            closeDropdown={this.toggleAccountDropdown}
          />
        </Modal>
        {!isMobile
          ? (
            <GlobalSearch
              {...this.props}
              isMobile={isMobile}
              language={language}
            />
          )
          : ''}
        {isMobile && !!rateOrder && (page === 'order-feedback' || loading)
          ? ''
          : (
            <>
              <div
                className={`bg-white side-overlay ${sideOverlayComponent ? ''
                  : 'hide-side-overlay'}
                  ${isMobile && !!rateOrder ? 'side-overlay-flexible' : ''} ${(onHomepage || onShopspage) ? 'r__side_overlay-social' : ''}
                  ${page === 'shops' && !isMobile ? 'side-overlay-new' : ''}`}
              >
                {
                  sideOverlayComponent && (
                    <SideOverlay
                      {...this.props}
                      ChildComponent={sideOverlayComponent}
                      toggleSideOverlay={this.toggleSideOverlay}
                      language={language}
                      isMobile={isMobile}
                      isSideOverlay
                      closeButton={sideOverlayCloseButton}
                    />
                  )
                }
              </div>
              <BackDrop
                show={!!sideOverlayComponent || customerSupportOverlay}
                onClick={() => {
                  if (isMobile && !!rateOrder) {
                    return;
                  }
                  if (customerSupportOverlay) {
                    this.toggleCustomerSupportOverlay();
                    return;
                  }
                  if (sideOverlayComponent) {
                    this.toggleSideOverlay(null);
                  }
                }}
              />
            </>
          )}
        {
          !b2bStorePreview && page !== 'guest-location' && (
            <Row
              className={
                `mx-0 fixed-top 
                ${(() => {
                  let top = 'h-76p';
                  if (isMobile) {
                    if (mSiteGlobalSearch) {
                      if (showDownloadAppStrip) {
                        top = 'h-174p';
                      } else {
                        top = 'h-110p';
                      }
                    } else if (showDownloadAppStrip) {
                      top = 'h-118p';
                    } else {
                      top = 'h-70p';
                    }
                  }
                  return top;
                })()}
                bg-white shadow-sm`
              }
            >
              {
               isMobile && showDownloadAppStrip && (
               <Col
                 xs={24}
                 className="px-0 h-48p"
               >
                 <DownloadAppStrip
                   closeStrip={() => { this.closeDownloadAppStrip(); }}
                   language={language}
                 />
               </Col>
               )
            }
              <Col
                xs={24}
                className="px-0 pt-12p pb-12p"
              >
                <TopMenu
                  {...this.props}
                  isMobile={isMobile}
                  language={language}
                  address={address}
                  toggleLogin={toggleLogin}
                  toggleSideOverlay={this.toggleSideOverlay}
                  toggleAccountDropdown={this.toggleAccountDropdown}
                  mSiteGlobalSearch={mSiteGlobalSearch}
                  performGuestLogin={() => {
                    this.setState({
                      loading: true,
                    }, this.performGuestLogin);
                  }}
                />
              </Col>
              {
                mSiteGlobalSearch && (
                  <Col
                    xs={24}
                    className="pb-12p"
                  >
                    <TopNavSearchBar
                      {...this.props}
                      isMobile={isMobile}
                      isGlobalSearch
                      language={language}
                      showCategories={!isMobile}
                    />
                  </Col>
                )
              }
            </Row>
          )
        }
        <Row
          className={`${page === 'guest-location' ? '' : 'main-content-section'} overflow-hidden ${showBottomMenu ? 'show-bottom-menu' : ''}
          ${b2bStorePreview ? 'main-content-b2bstorepreview' : ''}  ${(() => {
            let top = 'top-76p';
            if (isMobile) {
              if (mSiteGlobalSearch) {
                if (showDownloadAppStrip) {
                  top = 'top-174p';
                } else {
                  top = 'top-110p';
                }
              } else if (showDownloadAppStrip) {
                top = 'top-118p';
              } else {
                top = 'top-70p';
              }
            }
            return top;
          })()}`}
        >
          <Col
            xs={24}
            id="main-content"
            className={`px-0 h-100 smooth-scroll
            ${(isWhitePage) ? 'bg-white' : 'bg-light'}
            ${(page === 'orders' || page === 'offers-combo' || page === 'offers-spend' || page === 'global-search'
            || page === 'offers' || page === 'offers-discount' || page === 'shop-detail' || page === 'cart') ? '' : 'overflow-y-scroll'}`}
            onScroll={(e) => {
              if (cartBar) {
                this.handleCartBarView(e);
              }
            }}
            ref={this.reactRef}
          >
            {
              loading ? (
                <Row
                  className=""
                >
                  <Col
                    xs={24}
                    className="px-0"
                  >
                    <Shimmer
                      shimmerType="FALLBACK"
                    />
                  </Col>
                </Row>
              ) : (
                <ErrorBoundary
                  language={language}
                >
                  <Suspense
                    fallback={(
                      <Shimmer
                        shimmerType="FALLBACK"
                      />
                    )}
                  >
                    {
                      isServicableLocationUpdated
                        ? (
                          <Component
                            {...this.props}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            language={language}
                            toggleSideOverlay={this.toggleSideOverlay}
                            rateOrder={rateOrder}
                            onOrderFeedbackSuccess={() => {
                              this.setState({
                                rateOrder: null,
                                sideOverlayComponent: null,
                              });
                              Storage.setRateOrder(null);
                            }}
                            performGuestLogin={() => {
                              this.setState({
                                loading: true,
                              }, this.performGuestLogin);
                            }}
                            showReferNEarn={showReferNEarn}
                          />
                        ) : (
                          <ComingSoon toggleLocation={toggleManageLocation} {...this.props} />
                        )
                    }
                  </Suspense>
                </ErrorBoundary>
              )
            }
          </Col>
          <div
            id="customer-support-value-strip"
            style={{
              ...customerSupportOverlay ? { zIndex: 1036 } : {},
              ...(page === 'product-details' && isMobile) ? { bottom: 40 } : {},
            }}
          >
            {
              cartBar
              && (
                isGuestUser() && isMobile ? (
                  <GuestUserCartBar
                    language={language}
                    cartItems={cartItems}
                    guestCustomCartItems={guestCustomCartItems}
                    fade={fadeCartBar}
                    onClick={this.onClickGuestUserCartBar}
                    page={page}
                  />
                ) : (
                  <div
                    id="global-value-strip"
                    className={`${cartDetails?.cartProductsCount && !customerSupportOverlay ? '' : 'd-none'}`}
                  >
                    <ValueUnlockingStrip
                      toggleSideOverlay={this.toggleSideOverlay}
                      isMobile={isMobile}
                      history={history}
                    />
                  </div>
                )
              )
            }
            {
              (
                page === 'shop-detail'
                || page === 'order-details'
                || page === 'order-feedback'
                || (page === 'checkout' && isMobile)
                || page === 'guest-location'
                || (page === 'global-search' && isMobile)
                || (page === 'cart' && isMobile)
                || (page === 'language' && isMobile)
              )
                ? ''
                : (
                  <CustomerSupport
                    language={language}
                    isMobile={isMobile}
                    toggleSideOverlay={this.toggleSideOverlay}
                    toggleLogin={toggleLogin}
                    show={customerSupportOverlay}
                    toggleOverlay={this.toggleCustomerSupportOverlay}
                    toggleSupportDialog={this.toggleSupportDialog}
                    page={page}
                  />
                )
            }
          </div>
        </Row>
        {
          !b2bStorePreview
            && showBottomMenu
            && isMobile
            ? (
              <Row
                className="mx-0 fixed-bottom h-56p bg-white bottom-menu"
              >
                <Col
                  xs={24}
                  className="px-0"
                >
                  <BottomMenu
                    {...this.props}
                    isMobile={isMobile}
                    language={language}
                    toggleLogin={toggleLogin}
                  />
                </Col>
              </Row>
            ) : ''
        }
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.main.customer,
  address: state.main.selectedAddress,
  cartDetails: state.main.cartDetails,
  cartItems: state.main.cartItems,
  guestCustomCartItems: state.main.guestCustomCartItems,
  notifications: state.main.notifications,
  requestsProcessing: state.main.requestsProcessing,
  scratchCard: state.main.scratchCard,
  language: state.main.language,
  selectedAddress: state.main.selectedAddress,
  toggleGuestLocationPopup: state.main.toggleGuestLocationPopup,
  isMandatoryGuestLocationPopup: state.main.isMandatoryGuestLocationPopup,
  showMultiStoreCheckoutDialog: state.main.showMultiStoreCheckoutDialog,
  activeOrders: state.main.activeOrders,
});

const mapDispatchToProps = (dispatch) => ({
  addressLoadRequest: (onSuccess, onFailure) => {
    dispatch(Actions.addressLoadRequest(onSuccess, onFailure));
  },
  recentAddressLoadRequest: (onSuccess, onFailure) => {
    dispatch(Actions.recentAddressLoadRequest(onSuccess, onFailure));
  },
  cartLoadRequest: (params, onSuccess, onFailure) => {
    dispatch(cartLoadActions.loadRequest(onSuccess, onFailure, params));
  },
  logout: () => {
    dispatch(Actions.logout());
  },
  notificationsLoadRequest: (
    offset, limit, onSuccess, cancelToken,
  ) => {
    dispatch(Actions.notificationsLoadRequest(
      offset, limit, onSuccess, cancelToken,
    ));
  },
  profileLoadRequest: (onSuccess) => {
    dispatch(Actions.profileLoadRequest(onSuccess));
  },
  toggleLogin: () => {
    dispatch(Actions.toggleLogin());
  },
  toggleGuestLocation: (toggleGuestLocationPopup) => {
    dispatch(Actions.toggleGuestLocation(toggleGuestLocationPopup));
  },
  toggleMultiStoreModal: (data) => {
    dispatch(Actions.toggleMultiStoreModal(data));
  },
  cartAddRequest: (storeCode, productId, quantity, productInfo) => {
    dispatch(cartProductActions.request(
      storeCode, productId, quantity, null, null, productInfo, false, 'ADD',
    ));
  },
  changeLanguage: (language) => {
    dispatch(Actions.changeLanguage(language));
  },
  updateCustomer: (customer) => {
    dispatch(Actions.updateCustomer(customer));
  },
  updateSelectedAddress: (address) => {
    dispatch(Actions.updateSelectedAddress(address));
  },
  updateCartItems: (cartItems) => {
    dispatch(Actions.updateCartItems(cartItems));
  },
  cartIdLoadRequest: (onSuccess, onFailure) => {
    dispatch(cartLoadActions.loadRequest(onSuccess, onFailure));
  },
  addAddress: (address, onSuccess, onFailure) => {
    dispatch(Actions.addAddress(address, onSuccess, onFailure));
  },
  highlightCartOfferReq: (onSuccess, onFailure, params) => {
    dispatch(highlightCartOfferRequest.loadRequest(onSuccess, onFailure, params));
  },
  activeOrdersReq: (onSuccess, onFailure) => {
    dispatch(activeOrderActions.loadRequest(onSuccess, onFailure));
  },
  toggleManageLocation: () => {
    dispatch(Actions.toggleManageLocation());
  },
});

Wrapper.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.element, PropTypes.func, PropTypes.object,
  ]).isRequired,
  customer: PropTypes.shape({
    phone_number: PropTypes.string,
    isDummy: PropTypes.bool,
  }),
  addressLoadRequest: PropTypes.func.isRequired,
  recentAddressLoadRequest: PropTypes.func.isRequired,
  activeOrdersReq: PropTypes.func.isRequired,
  cartLoadRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  notificationsLoadRequest: PropTypes.func.isRequired,
  profileLoadRequest: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      scrollToTop: PropTypes.bool,
    }),
  }).isRequired,
  notifications: PropTypes.shape({}),
  address: PropTypes.shape({
    isDummy: PropTypes.bool.isRequired,
  }),
  toggleLogin: PropTypes.func.isRequired,
  cartDetails: PropTypes.shape({
    cartProductsCount: PropTypes.number,
  }),
  cartItems: PropTypes.shape({}),
  guestCustomCartItems: PropTypes.shape({}),
  cartAddRequest: PropTypes.func.isRequired,
  requestsProcessing: PropTypes.shape({}).isRequired,
  page: PropTypes.string.isRequired,
  scratchCard: PropTypes.shape({}),
  changeLanguage: PropTypes.func.isRequired,
  cartIdLoadRequest: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  updateSelectedAddress: PropTypes.func.isRequired,
  selectedAddress: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
  toggleGuestLocation: PropTypes.func.isRequired,
  toggleGuestLocationPopup: PropTypes.bool.isRequired,
  isMandatoryGuestLocationPopup: PropTypes.bool.isRequired,
  addAddress: PropTypes.func.isRequired,
  updateCartItems: PropTypes.func.isRequired,
  toggleMultiStoreModal: PropTypes.func.isRequired,
  showMultiStoreCheckoutDialog: PropTypes.bool.isRequired,
  isLogin: PropTypes.bool,
  isWhitePage: PropTypes.bool,
  showReferNEarn: PropTypes.bool,
  activeOrders: PropTypes.shape({}),
  toggleManageLocation: PropTypes.func.isRequired,
};

Wrapper.defaultProps = {
  customer: null,
  address: null,
  cartDetails: null,
  cartItems: null,
  guestCustomCartItems: null,
  notifications: null,
  scratchCard: null,
  selectedAddress: null,
  isLogin: false,
  isWhitePage: false,
  showReferNEarn: false,
  activeOrders: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
