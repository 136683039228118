import React from 'react';
import PropTypes from 'prop-types';

const Star3 = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill="#128807"
      fillRule="evenodd"
      d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14Zm.22-11.316a.418.418 0 0 0-.596.171l-1.05 2.128-2.349.341a.418.418 0 0 0-.232.714l1.7 1.656-.402 2.34a.418.418 0 0 0 .607.44L7 9.37l2.1 1.104a.42.42 0 0 0 .607-.441l-.4-2.34 1.699-1.655a.42.42 0 0 0-.232-.714l-2.35-.34-1.05-2.129a.418.418 0 0 0-.153-.17Z"
      clipRule="evenodd"
    />
  </svg>
);

Star3.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Star3;
