import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 20 20"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      stroke="#B8238E"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.964 6.976c1.226 0 2.22-1.002 2.22-2.238S14.19 2.5 12.964 2.5c-1.226 0-2.22 1.002-2.22 2.238s.994 2.238 2.22 2.238zm-7.927 5.116c1.4 0 2.537-1.145 2.537-2.558 0-1.413-1.136-2.558-2.537-2.558S2.5 8.121 2.5 9.534c0 1.413 1.136 2.558 2.537 2.558zm5.972-5.995l-3.7 2.398m.265 2.318l3.424 2.558m5.963-1.919h-4.314 1.618c1.078 0 1.617 1.085 1.617 1.628 0 .543-.539 1.628-1.617 1.628h-1.618l3.235 2.792m-3.235-4.42h4.314"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
