import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 27 24"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fill}>
        <path d="M23.322 10.491L16.645 5.42c-.506-.422-1.113 0-1.113.677v2.536c-4.754 0-8.8 2.451-10.722 5.748-.708 1.099-1.113 2.282-1.417 3.466-.202.845 1.315 1.268 1.922.507 2.226-2.959 5.969-4.903 10.217-4.903v2.79c0 .676.607 1.099 1.113.676l6.677-5.072c.404-.338.404-1.015 0-1.353z" transform="translate(-199 -263) translate(16 104) translate(0 39) translate(118 120) translate(65)" />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default Svg;
