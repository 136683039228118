// Trending Products Load Actions
export const TRENDING_PRODUCTS_LOAD_REQUEST = 'TRENDING_PRODUCTS_LOAD_REQUEST';
export const TRENDING_PRODUCTS_LOAD_SUCCESS = 'TRENDING_PRODUCTS_LOAD_SUCCESS';
export const TRENDING_PRODUCTS_LOAD_FAILURE = 'TRENDING_PRODUCTS_LOAD_FAILURE';
export const trendingProductsLoadActions = {
  loadRequest: (
    cancelToken, params,
  ) => ({
    type: TRENDING_PRODUCTS_LOAD_REQUEST,
    payload: {
      cancelToken,
      params,
    },
  }),
  loadSuccess: (data) => ({
    type: TRENDING_PRODUCTS_LOAD_SUCCESS,
    payload: {
      trendingProducts: data.res,
    },
  }),
  loadFailure: () => ({
    type: TRENDING_PRODUCTS_LOAD_FAILURE,
  }),
};
