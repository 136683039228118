import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 16 16"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth || '1px'}
      d="M7.91 14S3 10.727 3 6.91C3 4.197 5.198 2 7.91 2c2.71 0 4.908 2.198 4.908 4.91 0 3.817-4.909 7.09-4.909 7.09zm0-5.455c.903 0 1.635-.732 1.635-1.636 0-.904-.732-1.636-1.636-1.636-.904 0-1.636.732-1.636 1.636 0 .904.732 1.636 1.636 1.636z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  strokeWidth: PropTypes.string,
};

Svg.defaultProps = {
  strokeWidth: '',
};

export default Svg;
