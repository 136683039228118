import React from 'react';
import PropTypes from 'prop-types';

const style = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const QuickCard = ({
  name, link, image, cardStyle,
}) => (
  <>
    {
      (image)
      && (
      <div style={style.wrapper}>
        <a href={link} style={cardStyle}>
          <img className="w-100 h-100" src={image} alt={name} />
        </a>
      </div>
      )
    }
  </>
);

QuickCard.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  cardStyle: PropTypes.shape({}),
};

QuickCard.defaultProps = {
  name: '',
  link: null,
  image: '',
  cardStyle: {},
};

export default QuickCard;
