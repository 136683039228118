import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CustomImage from 'app/components/common/CustomImage';
import Button from 'app/components/common/button';
import { Constants } from 'app/utilities';
import { stickersInfo } from 'app/utilities/Utils';
import { CustomModal, Svg } from 'app/components/common';
import { storeReviewSummary } from 'api/api';
import StoreCover from './StoreCover';

function StoreRetailerStory({
  storeDetails, isMobile,
  language, storeId,
}) {
  const [previewCoverIndex, setPreviewCoverIndex] = useState(-1);
  const [badges, setBadges] = useState(null);
  const retailerStory = !!storeDetails && storeDetails.storeBio
    ? (storeDetails.storeBio.join('')).trim() : '';

  const getStoreStickers = () => {
    storeReviewSummary(
      'GET',
      storeId,
      null,
    ).then((res) => {
      setBadges(res.data);
    }).catch(() => {});
  };

  useEffect(() => {
    getStoreStickers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePreviewCover = (index) => {
    setPreviewCoverIndex(index);
  };

  return (
    <Container
      fluid
      className=""
    >
      {
        storeDetails
        && storeDetails.shop_story_images
        && storeDetails.shop_story_images.length > 0
          ? (
            <CustomModal
              show={previewCoverIndex > -1}
              body={(
                <div
                  className="p-9"
                >
                  <StoreCover
                    storeCovers={storeDetails.shop_story_images}
                    storeName={storeDetails.store_name}
                    isMobile={isMobile}
                    activeIndex={previewCoverIndex}
                    preview
                  />
                </div>
              )}
              size="lg"
              onHide={() => togglePreviewCover(-1)}
              backdrop
            />
          ) : ''
      }
      <Row
        className=""
      >
        {
          retailerStory ? (
            <Col
              xs={{ order: 2, span: 24 }}
              lg={{ order: 1, span: 12 }}
              className={isMobile ? 'px-0' : ''}
            >
              <div
                className="fs-3 font-weight-black"
              >
                {Constants.String.MY_SHOP_STORY[language]}
              </div>
              <div
                className="fs-5 text-black-72 pt-4 word-break"
              >
                {
                  !!storeDetails.storeBio && storeDetails.storeBio.map((item) => (
                    <p key={item}>{item}</p>
                  ))
                }
              </div>
            </Col>
          ) : null
        }
        <Col
          xs={{ order: 1, span: 24 }}
          lg={{ order: 2, span: retailerStory ? 12 : 24 }}
          className={isMobile ? 'px-0' : ''}
        >
          <Row
            className="mx-0 pt-4"
          >
            {
              (
                storeDetails.establishment
                || storeDetails.rating.count
              ) ? (
                <Col
                  xs={24}
                  className="py-4 bg-violet-lighter border-radius-8"
                >
                  <Row
                    className="mx-0 justify-content-center"
                  >
                    {
                      !!storeDetails.rating.count && (
                        <Col
                          xs={12}
                          className="text-center text-violet"
                        >
                          <div
                            className="fs-2 font-weight-black d-flex align-items-center justify-content-center"
                          >
                            {storeDetails.rating.average_rating}
                            &nbsp;
                            <Svg
                              svg="starFilled"
                              fill={Constants.Color.violet}
                              width="22px"
                            />
                          </div>
                          <div
                            className="fs-7"
                          >
                            {
                              storeDetails.rating.count
                                ? `${storeDetails.rating.count} ${Constants.String.RATINGS[language]}`
                                : `${Constants.String.NO_RATINGS[language]}`
                            }
                          </div>
                        </Col>
                      )
                    }
                    {
                      !!storeDetails.establishment && (
                        <Col
                          xs={12}
                          className="text-center text-violet"
                        >
                          <div
                            className="fs-2 font-weight-black"
                          >
                            {storeDetails.establishment}
                          </div>
                          <div
                            className="fs-7"
                          >
                            {Constants.String.ESTABLISHED_IN[language]}
                          </div>
                        </Col>
                      )
                    }
                  </Row>
                </Col>
                ) : null
            }
            <Col
              xs={24}
              className="py-4 px-0"
            >
              <div
                className="fs-3 font-weight-black"
              >
                {Constants.String.COMPLIMENTS_FROM_CUSTOMERS[language]}
              </div>
              <Row
                className={`mx-0 pt-4 ${
                  isMobile ? 'justify-content-center' : ''
                }`}
              >
                {
                  storeDetails.atLeastOnePositiveStickerReviewsCount ? (
                    <>
                      {
                        badges
                        && !!badges.totalSelectedPositiveStickerCount
                        && badges.selectedPositiveStickerCountDetail.map((sticker) => {
                          if (sticker.count > 0) {
                            return (
                              <Col
                                key={sticker.key}
                                xs={(!!retailerStory || isMobile) ? 6 : 'auto'}
                                className="pb-4 d-flex justify-content-center"
                              >
                                <div
                                  className="text-center sticker-image-div"
                                >
                                  <div
                                    className="position-relative"
                                  >
                                    <CustomImage
                                      imageStyle="mw-100 mh-100"
                                      imageUrl={stickersInfo[sticker.key].url}
                                      imageType="STICKER"
                                    />
                                    <div
                                      className="sticker-count-box "
                                    >
                                      <span
                                        className="sticker-count-badge fs-7 font-weight-black"
                                      >
                                        {(() => {
                                          let stickerCount = '';
                                          if (sticker.count > 999) {
                                            stickerCount = '1k+';
                                          } else {
                                            stickerCount = `${sticker.count > 9 ? '' : '0'}${sticker.count}`;
                                          }
                                          return stickerCount;
                                        })()}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="fs-6 pt-2 text-black-72"
                                  >
                                    {stickersInfo[sticker.key].text}
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                          return null;
                        })
                      }
                    </>
                  ) : (
                    <Col
                      xs={24}
                      className="px-0 bg-light py-9 border-radius-8"
                    >
                      <Row>
                        <Col
                          xs={24}
                          className="fs-6 text-center"
                        >
                          {Constants.String.BE_THE_FIRST_FIRST_TO_REVIEW[language]}
                        </Col>
                        <Col
                          xs={24}
                          className="pt-3 text-center d-flex justify-content-center"
                        >
                          <Button
                            variant="primary"
                            className="px-3 border-radius-8"
                            onClick={() => {
                              // if (!b2bStorePreview) {
                              //   this.changeStoreState('SHOP_PRODUCT');
                              // }
                            }}
                          >
                            {Constants.String.START_SHOPPING[language].toUpperCase()}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  )
                }
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {(storeDetails?.shop_story_images?.length > 0)
      && (
        <Row
          className="py-6 mx-0"
        >
          <StoreCover
            storeCovers={storeDetails.shop_story_images}
            storeName={storeDetails.store_name}
            isMobile={isMobile}
            togglePreviewCover={togglePreviewCover}
          />
        </Row>
      )}
    </Container>
  );
}

StoreRetailerStory.propTypes = {
  storeDetails: PropTypes.shape({
    storeBio: PropTypes.arrayOf(PropTypes.string),
    shop_story_images: PropTypes.arrayOf(PropTypes.shape({})),
    store_name: PropTypes.string.isRequired,
    establishment: PropTypes.string,
    rating: PropTypes.shape({
      count: PropTypes.number,
      average_rating: PropTypes.number,
    }),
    atLeastOnePositiveStickerReviewsCount: PropTypes.bool,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  storeId: PropTypes.number.isRequired,
};

export default StoreRetailerStory;
