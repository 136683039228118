import React from 'react';
import PropTypes from 'prop-types';

const PaymentPending = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <circle cx={12} cy={12} r={12} fill="#B8238E" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M18.3 16.5V8.22h1.44v9.72H6.42V16.5H18.3Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3.9 5.7h14.04v10.44H3.9V5.7Zm1.44 1.44v7.56H16.5V7.14H5.34Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10.92 9.84a1.08 1.08 0 1 0 0 2.16 1.08 1.08 0 0 0 0-2.16ZM8.4 10.92a2.52 2.52 0 1 1 5.04 0 2.52 2.52 0 0 1-5.04 0Z"
      clipRule="evenodd"
    />
  </svg>
);

PaymentPending.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default PaymentPending;
