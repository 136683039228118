// import React from 'react';

const homePageStrings:any = {
  GET_HELP: {
    en: 'Get help',
    hi: 'सहायता प्राप्त करें',
  },
  HOME_TITLE: {
    en: 'Create Your Online Dukaan Today',
    hi: 'आज ही अपनी ऑनलाइन दुकान बनाएं',
  },
  NO_REGISTRATION_FEE: {
    en: 'No registration fee',
    hi: 'कोई रजिस्ट्रेशन शुल्क नहीं',
  },
  NO_MARGINS: {
    en: 'No margins',
    hi: 'कोई मार्जिन नहीं',
  },
  NEW_CUSTOMERS: {
    en: 'New Customers',
    hi: 'नए ग्राहक',
  },
  EXCITING_OFFERS: {
    en: 'Exciting offers for customers',
    hi: 'ग्राहकों के लिए रोमांचक ऑफर',
  },
  EASY_CATALOGUE: {
    en: 'Easy cataloguing',
    hi: 'आसान कैटलॉगिंग',
  },
  CUSTOMER_SERVICE: {
    en: 'Customer service',
    hi: 'ग्राहक सेवा',
  },
  HAPPY_RETAILER: {
    en: 'Happy Local Retailers',
    hi: 'खुश स्थानीय रिटेलर',
  },
  HAPPY_SHOPPERS: {
    en: 'Happy Shoppers',
    hi: 'खुश खरीदार',
  },
  RETAILERS: {
    en: 'Retailers',
    hi: 'खुदरा विक्रेता',
  },
  CONSUMERS: {
    en: 'Consumers',
    hi: 'उपभोक्ता',
  },
  PRODUCTS: {
    en: 'products',
    hi: 'उत्पाद',
  },
  CATEGORIES: {
    en: 'across 145 categories',
    hi: '145 श्रेणियों में',
  },
  CITIES: {
    en: 'cities',
    hi: 'शहर',
  },
  PINCODES: {
    en: 'pin codes',
    hi: 'पिन कोड',
  },
  HOW_TO_PARTNER: {
    en: 'How to partner with us',
    hi: 'हमारे साथ साझेदारी कैसे करें',
  },
  DOWNLOAD_APP: {
    en: 'Download the LoveLocal Dukaan App',
    hi: 'LoveLocal Dukaan App डाउनलोड करें',
  },
  ENTER_PHONE_NUMBER: {
    en: 'Enter your phone number and validate with OTP',
    hi: 'अपना फोन नंबर दर्ज करें और OTP के साथ सत्यापित करें',
  },
  ENTER_PERSONAL_DETAILS: {
    en: 'Enter your personal and business details',
    hi: 'अपना व्यक्तिगत और व्यावसायिक विवरण दर्ज करें',
  },
  TEAM_WILL_REVIEW: {
    en: 'Our onboarding team will review your details and approve your shop',
    hi: 'हमारी ऑनबोर्डिंग टीम आपके विवरण की समीक्षा करेगी और आपकी दुकान को मंजूरी देगी',
  },
  OUR_MISSION: {
    en: 'Our mission',
    hi: 'हमारा मिशन',
  },
  REASON_EXISTENCE: {
    en: 'The reason for existence',
    hi: 'अस्तित्व का कारण',
  },
  EMPOWERING_BUSINESS: {
    en: "Empowering India's Local Businesses that are responsible for 40% of employment and are core drivers of our economy",
    hi: 'भारत के स्थानीय व्यवसायों को सशक्त करना जो 40% रोजगार के लिए जिम्मेदार हैं और हमारी अर्थव्यवस्था के मुख्य चालक हैं',
  },
  REQUEST_CALLBACK: {
    en: 'Request a Callback',
    hi: 'कॉलबैक का अनुरोध करें',
  },
  MORE_PRODUCTS: {
    en: 'More than 5,00,000  Products',
    hi: '5,00,000 से अधिक उत्पाद',
  },
  GRAINS_OILS: {
    en: 'Grains, Oils & Masalas',
    hi: 'अनाज, तेल और मसाले',
  },
  GRAINS_OILS_DESC: {
    en: 'Everyday essentials that are staple to the Indian diet',
    hi: 'रोजमर्रा की आवश्यक चीजें जो भारतीय आहार के लिए प्रधान हैं',
  },
  DRY_FRUITS: {
    en: 'Dry fruits & sweets',
    hi: 'सूखे मेवे और मिठाई',
  },
  DRY_FRUITS_DESC: {
    en: 'Variety of products for daily or festive consumption',
    hi: 'दैनिक या उत्सव की खपत के लिए उत्पादों की विविधता',
  },
  MEAT_SEAFOOD: {
    en: 'Meat and seafood',
    hi: 'मांस और समुद्री भोजन',
  },
  MEAT_SEAFOOD_DESC: {
    en: 'The most tender and fresh meat and seafood',
    hi: 'सबसे मुलायम और ताजा मांस और समुद्री भोजन',
  },
  HOUSEHOLD_CLEANING: {
    en: 'Household & cleaning',
    hi: 'घरेलू और सफाई',
  },
  HOUSEHOLD_CLEANING_DESC: {
    en: 'Keeping spaces spick and span was never easier',
    hi: 'रिक्त स्थान साफ-सुथरा रखना कभी आसान नहीं था',
  },
  BEVERAGES: {
    en: 'Beverages',
    hi: 'पेय पदार्थ',
  },
  BEVERAGES_DESC: {
    en: 'From tea to soft drink, everything available in one place',
    hi: 'चाय से लेकर शीतल पेय तक, सब कुछ एक ही स्थान पर उपलब्ध है',
  },
  PERSONAL_CARE: {
    en: 'Personal Care',
    hi: 'व्यक्तिगत देखभाल',
  },
  PERSONAL_CARE_DESC: {
    en: 'From skin care to hair care, all essentials available now',
    hi: 'त्वचा की देखभाल से लेकर बालों की देखभाल तक, अब सभी आवश्यक चीजें उपलब्ध हैं',
  },
  BABY_CARE: {
    en: 'Maternity & Baby care',
    hi: 'मातृत्व और बच्चे की देखभाल',
  },
  BABY_CARE_DESC: {
    en: 'End to end product range for expecting and new mothers, and babies',
    hi: 'उम्मीद करने वाली और नई माताओं, और शिशुओं के लिए शुरु से अंत तक उत्पाद रेंज',
  },
  HEALTH_MEDICINE: {
    en: 'Health & Medicine',
    hi: 'स्वास्थ्य और दवा',
  },
  HEALTH_MEDICINE_DESC: {
    en: 'Products to prevent, manage or cure illnesses',
    hi: 'बीमारियों को रोकने, प्रबंधित करने या ठीक करने के लिए उत्पाद',
  },
  DAIRY: {
    en: 'Dairy',
    hi: 'दुग्धशाला',
  },
  DAIRY_DESC: {
    en: 'Best quality milk & milk products',
    hi: 'सर्वश्रेष्ठ गुणवत्ता वाला दूध और दूध उत्पाद',
  },
  FRUITS_VEGETABLES: {
    en: 'Fruits & Vegetables',
    hi: 'फल और सब्जियाँ',
  },
  FRUITS_VEGETABLES_DESC: {
    en: 'Freshest and best quality fruits and vegetables',
    hi: 'सबसे ताजा और सबसे अच्छी गुणवत्ता वाले फल और सब्जियाँ',
  },
  PET_CARE: {
    en: 'Pet Care',
    hi: 'पालतू जानवरों की देखभाल',
  },
  PET_CARE_DESC: {
    en: 'All supplies for pet friends',
    hi: 'पालतू दोस्तों के लिए सभी आपूर्तियाँ',
  },
  CHOC_SNACKS: {
    en: 'Chocolates & Snacks',
    hi: 'चॉकलेट और स्नैक्स',
  },
  CHOC_SNACKS_DESC: {
    en: 'A wide range of sweet treats and savoury bites',
    hi: 'मीठे और स्वादिष्ट की एक विस्तृत श्रृंखला',
  },
  READY_TO_EAT: {
    en: 'Ready to eat',
    hi: 'खाने के लिए तैयार',
  },
  READY_TO_EAT_DESC: {
    en: 'Pre-packed snacks and meals that require little to no extra cooking',
    hi: 'पूर्व-पैक स्नैक्स और भोजन जिन्हें अतिरिक्त पकाने की बहुत कम की आवश्यकता होती है',
  },
  FOOD_BEVERAGE: {
    en: 'Food & Beverages',
    hi: 'खाद्य और पेय पदार्थ',
  },
  FOOD_BEVERAGE_DESC: {
    en: 'Delicious snacks and drinks...all in one place',
    hi: 'स्वादिष्ट स्नैक्स और पेय ... सभी एक ही स्थान पर',
  },
  STATIONERY: {
    en: 'Stationery',
    hi: 'लेखन-सामग्री',
  },
  STATIONERY_DESC: {
    en: 'Pens, book, pencils & a lot more...',
    hi: 'कलम, किताब, पेंसिल और बहुत कुछ ...',
  },
  MEDICINES: {
    en: 'Medicines',
    hi: 'दवाएँ',
  },
  MEDICINES_DESC: {
    en: 'Medicines to always keep one healhty',
    hi: 'किसी को हमेशा स्वस्थ रखने के लिए दवाएँ',
  },
  BAKERY: {
    en: 'Bakery',
    hi: 'बेकरी',
  },
  BAKERY_DESC: {
    en: 'From cheese, butter to delicious cakes, all bakery products available',
    hi: 'पनीर, मक्खन से लेकर स्वादिष्ट केक तक, सभी बेकरी उत्पाद उपलब्ध हैं',
  },
  FOLLOW_US: {
    en: 'Follow Us',
    hi: 'हमारा अनुसरण करें',
  },
  YOUTUBE: {
    en: 'YouTube',
    hi: 'YouTube',
  },
  YOUTUBE_DESC: {
    en: 'Stay updated with business tips, tutorial videos and a lot more fun content.',
    hi: 'व्यापार युक्तियों, ट्यूटोरियल वीडियो और बहुत अधिक मजेदार सामग्री के साथ अद्यतन रहें।',
  },
  FACEBOOK: {
    en: 'Facebook',
    hi: 'Facebook',
  },
  FACEBOOK_DESC: {
    en: 'From informational posts to funny content, like our page to stay updated about everything LoveLocal.',
    hi: 'सूचनात्मक पोस्ट से लेकर मजेदार सामग्री तक,  LoveLocal के बारे में सब कुछ अपडेट रहने के लिए हमारे पृष्ठ को पसंद करें।',
  },
  INSTAGRAM: {
    en: 'Instagram',
    hi: 'Instagram',
  },
  INSTAGRAM_DESC: {
    en: 'Funny memes, engaging stories and exciting contests, follow us to stay informed and entertained.',
    hi: 'मजेदार कथानक, आकर्षक कहानियों और रोमांचक प्रतियोगिताओं के बारे में सूचित और मनोरंजित रहने के लिए हमारा अनुसरण करें।',
  },
  ABOUT_LOVELOCAL: {
    en: 'About Lovelocal',
    hi: 'Lovelocal के फायेभें',
  },
  ABOUT_LOVELOCAL_DESC: {
    en: 'LoveLocal believes in empowering local retailers by bringing them closer to their customers through online shopping. Simultaneously, it also aims to give consumers the best experience by bringing a wide variety of products across categories. The platform not only services metro cities like Mumbai, Bengaluru, Delhi, Hyderabad, and Kolkata but it is also present in cities like Dehradun, Muzaffarpur, Guwahati -thus including many towns and localities in the country. Our seamless selling interface is designed to give you a tailored experience that you can use to sell easily.',
    hi: 'LoveLocal स्थानीम खुदया विक्रे ताओॊ को ऑनराइन शॉवऩॊग के भाध्मभ सेअऩनेग्राहकों के कयीफ राकय उन्हेंसशक्त फनानेभेंविश्वास यखता है। इसके साथ ही, इसका उद्देश्म विभबन्न श्रेणिमों भेंउत्ऩादों की एक विस्तृत विविधता राकय उऩबोक्ताओॊको सिोत्तभ अनुबि प्रदान कयना है। मह प्रेटपाभमन के िर भुॊफई, फेंगरुरु, ददल्री, हैदयाफाद औय कोरकाता जैसेभेट्रो शहयों भेंसेिा प्रदान कयता हैफणल्क मह देहयादनू , भुजफ्पयऩुय, गुिाहाटी जैसेशहयों भेंबी भौजूद है - इस प्रकाय मह देश के कई कस्फों औय इराकों भेंबी अऩनी सेिा देता है। हभाया सभेदकत वफक्री इॊटयपे स आऩको एक अनुरूऩ अनुबि देनेके भरए दडजाइन दकमा गमा हैणजसका उऩमोग आऩ आसानी सेफेचनेके भरए कय सकतेहैं। ',
  },
  READ_MORE: {
    en: 'Read More...',
    hi: 'और अधिक पढ़ें...',
  },
  READ_LESS: {
    en: 'Read Less',
    hi: 'कम पढ़ें',
  },
  VIDEO_URL: {
    en: 'https://www.youtube.com/embed/akM2f-Xjurg?autoplay=1&loop=1&playlist=akM2f-Xjurg&rel=0&mute=1&muted=1&enable_js=1&#t=0s',
    hi: 'https://www.youtube.com/embed/RoX3Z70C_io?autoplay=1&loop=1&playlist=RoX3Z70C_io&rel=0&mute=1&muted=1&enable_js=1&#t=0s',
  },

  /// /// About content //////
  SETUP_DUKAAN: {
    en: 'Setup Your Online Dukaan',
    hi: 'अऩनी ऑनराइन दक  ुान को स्थावऩत कयें',
  },
  BUILD_PRESENCE: {
    en: 'Build a strong digital presence',
    hi: 'भजफूत दडणजटर उऩणस्थभत फनाएॉ',
  },
  BUILD_PRESENCE_DESC: {
    en: 'Get an e-Commerce website and a customer-facing in-app shop on the LoveLocal app. <b>Create free online shopping store</b> and start accepting digital payments from all popular clients and vendors',
    hi: 'LoveLocal ऐऩ ऩय एक ई-कॉभसमिेफसाइट औय एक ग्राहक-उन्भुख इन-ऐऩ दकु ान फनाएॉ। भुफ्त ऑनराइन शॉवऩॊग स्टोय फनाएॉऔय सबी रोकवप्रम ग्राहकों औय विक्रे ताओॊ सेदडणजटर बुगतान स्िीकाय कयना शुरू कयें',
  },
  BUILD_PRESENCE_DESC_2: {
    en: 'Exciting LoveLocal-sponsored offers that will benefit your customers.',
    hi: 'योभाॊचक LoveLocal –प्रामोणजत ऩेशकश, जो आऩके ग्राहकों को राबाणन्ित कयेंगे।',
  },
  EASY_CATALOGUE_HEAD: {
    en: 'Easy Catalogue & Order Management System',
    hi: 'आसान कै टरॉग औय ऑडमय प्रफॊधन प्रिारी',
  },
  EASY_CATALOGUE_SUB_HEAD: {
    en: 'Manage products and incoming orders',
    hi: 'उत्ऩादों औय आनेिारेआदेशों को प्रफॊभधत कयें',
  },
  EASY_CATALOGUE_DESC: {
    en: 'Easily add and edit products, prices and offers with the fastest and most easy-to-use Catalogue Management System in your <b>online dukaan.</b>',
    hi: 'अऩनेऑनराइन दकु ान भेंसफसेतेज औय उऩमोग भेंआसान कै टरॉग प्रफॊधन प्रिारी के साथ उत्ऩादों, कीभतों औय ऩेशकश को आसानी सेजोडेंऔय सॊऩाददत कयें। ',
  },
  EASY_CATALOGUE_DESC_2: {
    en: 'Manage incoming orders easily. Give your customers both Home-Delivery and Pickup From Shop options.',
    hi: 'आनेिारेआदेशों को आसानी सेप्रफॊभधत कयें। अऩनेग्राहकों को होभ-दडरीियी औय वऩक फ्रॉभ शॉऩ, दोनों का विकल्ऩ दें।',
  },
  POWERFUL_ANALYTICS: {
    en: 'Powerful Business Analytics',
    hi: 'शवक्तशारी व्माऩाय विश्लेविकी',
  },
  POWERFUL_ANALYTICS_SUB_HEAD: {
    en: 'Understand how to grow your business',
    hi: 'सभझेंदक अऩनेव्मिसाम को कै सेफढामा जाए',
  },
  POWERFUL_ANALYTICS_DESC_1: {
    en: 'Understand your daily, weekly, and monthly <b>online grocery stores</b> performances.',
    hi: 'अऩनेऑनराइन दकयाना स्टोय के दैभनक, साप्तादहक औय भाभसक प्रदशमन को सभझें। ',
  },
  POWERFUL_ANALYTICS_DESC_2: {
    en: 'Get detailed knowledge about new, repeat, inactive, and churned customers.',
    hi: 'नए, दोहयाए गए, भनणरक्रम औय भॊथन दकए गए ग्राहकों के फायेभेंविस्तृत जानकायी प्राप्त कयें।',
  },
  POWERFUL_ANALYTICS_DESC_3: {
    en: 'Understand shopping patterns of your top customers to grow your revenue.',
    hi: 'अऩनेयाजस्ि को फढानेके भरए अऩनेशीिमग्राहकों के खयीदायी ऩैटनमको सभझें।',
  },
  LOYALTY_HEAD: {
    en: 'Loyalty & CRM',
    hi: 'िपादायी औय सीआयएभ',
  },
  LOYALTY_SUB_HEAD: {
    en: 'Keep your customers coming back',
    hi: 'अऩनेग्राहकों को िाऩस रातेयहें ',
  },
  LOYALTY_DESC_1: {
    en: '<b>Create online grocery store</b> and run your very own loyalty program. Take advantage of a customizable rewards program that fits your budget. Accept points for discounts at your <b>online kirana store.</b>',
    hi: 'ऑनराइन दकयाना स्टोय फनाएॉऔय अऩना खुद का िपादायी काममक्रभ चराएॉ। अनुकू रन मोग्म ऩुयस्काय काममक्रभ का राब उठाएॉजो आऩके फजट भेंदपट फैठता है। अऩनेऑनराइन दकयाना स्टोय ऩय छू ट के भरए अॊक स्िीकाय कयें।',
  },
  LOYALTY_DESC_2: {
    en: 'Share digital invoice, reward points and offers with customers.',
    hi: 'ग्राहकों के साथ दडणजटर फीजक, ऩुयस्काय अॊक औय ऩेशकश साझा कयें।',
  },
  AND_MUCH_MORE: {
    en: 'And Much More',
    hi: 'औय बी फहुत कु छ',
  },
  AND_MUCH_MORE_1: {
    en: 'Consultation & Planning from our Retail Experts on how to modernize your customer relationships',
    hi: 'अऩनेग्राहक सॊफॊध को आधुभनक फनानेके तयीके ऩय हभायेखुदया विशेिऻों सेऩयाभशमऔय मोजना',
  },
  AND_MUCH_MORE_2: {
    en: 'Retailer Success Support Center available 12 hours a day, 7 days a week by Phone & WhatsApp',
    hi: 'रयटेरय सक्सेस सऩोटमसेंटय ददन भें12 घॊटे, सप्ताह भें7 ददन पोन औय व्हाट्सएऩ द्वाया उऩरब्ध है ',
  },
  AND_MUCH_MORE_3: {
    en: 'Shopper Support—including free physical delivery, call center, and chat support',
    hi: 'खयीदाय को सहामता—भन्शुल्क बौभतक वितयि, कॉर सेंटय औय चैट सभथमन सदहत अन्म सहामता',
  },
  AND_MUCH_MORE_4: {
    en: '<b>Create store online</b> and get free trainings for your staff, with optional staff incentive programs',
    hi: 'िैकणल्ऩक कभमचायी प्रोत्साहन काममक्रभों के साथ ऑनराइन स्टोय फनाएॉऔय अऩनेकभमचारयमों को भन्शुल्क प्रभशणऺत कयाएॉ ',
  },
  AND_MUCH_MORE_5: {
    en: 'Toh banao <b>apni dukaan</b> aaj hi!',
    hi: 'तो फनाओ अऩनी दकु ान आज ही!',
  },
};

const translate = (string:string) => {
  const language = localStorage.getItem('languagePartner') || 'en';

  return (
    homePageStrings[string][language]
  );
};
export { translate, homePageStrings };
