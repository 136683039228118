import React from 'react';
import PropTypes, { shape } from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { cartProductActions } from 'app/store/cart/actions';
import { Svg } from 'app/components/common';
import { getSeoShopUrl } from 'app/utilities/Utils';
import GuestUserCartProduct from './GuestUserCartProduct';
import { Constants } from '../../utilities';
import CreateCustomProduct from './layout/custom-product/CreateCustomProduct';
import GuestUserCustomCartProduct from './GuestUserCustomCartProduct';

class GuestUserCart extends React.PureComponent {
  render() {
    const {
      toggleSideOverlay, cartProductRequest, requestsProcessing,
      isMobile, cartItems, toggleLogin, language, history,
      guestCustomCartItems,
    } = this.props;

    let billAmount = 0;
    let productCount = 0;

    if (cartItems && Object.keys(cartItems).length) {
      Object.keys(cartItems).forEach((productId) => {
        productCount += cartItems[productId].quantity;
        if (cartItems[productId].productInfo) {
          billAmount += cartItems[productId].productInfo?.sellingPrice
            * cartItems[productId].quantity;
        }
      });
    }

    const guestCustomItemsCnt = Object.values(guestCustomCartItems || {})
      .reduce((acc, customProduct) => acc + customProduct.quantity, 0);
    productCount += guestCustomItemsCnt;

    const CustomProductCreation = () => (
      <CreateCustomProduct
        onCloseOverlay={() => { }}
      />
    );

    const firstProductId = cartItems && Object.keys(cartItems).length > 0
      ? Object.keys(cartItems)[0] : null;
    const isEmptyCart = (productCount - guestCustomItemsCnt) === 0;

    return (
      <Container
        fluid
        className={`d-flex flex-column px-0 bg-white h-100 ${isMobile ? 'my-3' : ''}`}
      >
        {!isMobile && (
          <>
            <Row
              className="mx-0 py-4 align-items-center"
            >
              <Col
                xs="auto"
                className="font-weight-black"
              >
                {Constants.String.CHECKOUT[language]}
              </Col>
            </Row>
            <Row
              className="py-1 bg-light mx-0"
            />
          </>
        )}
        <Row
          className="overlay-list pl-4 pr-4 pr-md-0 mx-0 bg-white flex-grow-1 d-block"
        >
          {
            firstProductId ? (
              <Col xs={24}>
                <Row
                  className="py-3 px-0 font-weight-bold border-bottom"
                >
                  <Col
                    xs={24}
                              // className="px-0 fs-4"
                    className="px-0 ml-auto d-flex align-items-center font-weight-bold cursor-pointer"
                    onClick={() => {
                      if (!isMobile && toggleSideOverlay) {
                        toggleSideOverlay(null);
                      }
                      if (cartItems) {
                        const { shopUrl } = getSeoShopUrl(
                          cartItems[firstProductId].productInfo?.country,
                          cartItems[firstProductId].productInfo?.city,
                          cartItems[firstProductId].productInfo?.locality,
                          cartItems[firstProductId].productInfo?.type,
                          cartItems[firstProductId].productInfo?.storeName,
                          cartItems[firstProductId].storeId,
                        );
                        history.push(shopUrl);
                      }
                    }}
                  >
                    {cartItems[firstProductId]?.productInfo?.storeName}
                    <Svg
                      svg="arrowRight"
                      width="16px"
                      stroke={Constants.Color.black}
                    />
                  </Col>
                  <Col
                    xs={24}
                    className="px-0 fs-5"
                  >
                    {`${productCount} Items`}
                  </Col>
                </Row>
              </Col>
            ) : null
          }
          {
            productCount > 0
            && cartItems
            && Object.keys(cartItems).map((productId) => (
              <Col
                xs={24}
                key={cartItems[productId].productId}
                className=""
              >
                <Row
                  className=""
                >
                  <GuestUserCartProduct
                    key={cartItems[productId].productId}
                    product={{
                      displayName: cartItems[productId].productInfo?.productName,
                      id: cartItems[productId].productId,
                      image: cartItems[productId].productInfo?.imageUrl,
                      mrp: cartItems[productId].productInfo?.mrp,
                      quantity: cartItems[productId].quantity,
                      sellingPrice: cartItems[productId].productInfo?.sellingPrice,
                      storeName: cartItems[productId].productInfo?.storeName,
                      measurementDisplayText: cartItems[productId].productInfo?.measurementDisplayText || '1 Unit',

                    }}
                    cartItems={cartItems}
                    store={{
                      code: cartItems[productId].storeId,
                      displayName: cartItems[productId].productInfo?.storeName,
                    }}
                    isCart
                    language={language}
                    cartProductRequest={cartProductRequest}
                    requestsProcessing={requestsProcessing}
                    isMobile={isMobile}
                    toggleSideOverlay={toggleSideOverlay}
                    history={history}
                  />
                </Row>
              </Col>
            ))
          }
          {
            guestCustomCartItems && (
              Object.keys(guestCustomCartItems || {}).map((dummyCustomProductId) => (
                <GuestUserCustomCartProduct
                  key={guestCustomCartItems[dummyCustomProductId].productName}
                  productId={dummyCustomProductId}
                  guestCustomCartItems={guestCustomCartItems}
                  product={guestCustomCartItems[dummyCustomProductId]}
                  language={language}
                  isMobile={isMobile}
                  toggleSideOverlay={toggleSideOverlay}
                  history={history}
                />
              ))
            )
          }
          {
            productCount > 0 && (
              <Col
                xs={24}
              >
                <CustomProductCreation />
              </Col>
            )
          }
        </Row>
        <Row className="w-100 mx-0 pt-0 pb-3 py-md-2 d-flex checkout-section align-items-center border-top">
          <Col xs="auto">
            <div className="font-weight-black">
              {`₹${billAmount}`}
            </div>
          </Col>
          <Col className="flex-grow-1">
            <Button
              variant="primary"
              className={`fs-5 my-3 my-md-0 font-weight-black border-radius-8 w-100
              text-white ${isEmptyCart ? 'bg-disabled' : 'bg-primary'}`}
              disabled={isEmptyCart}
              onClick={() => {
                toggleLogin();
                toggleSideOverlay(null);
              }}
            >
              LOGIN TO CONTINUE
              &nbsp;
              <Svg
                svg="circleArrow"
                width="24px"
                circleFill={isEmptyCart ? Constants.Color.disabled : Constants.Color.primary}
                pathFill={Constants.Color.white}
              />
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  cartProductRequest: (
    storeId, productId, quantity, onSuccess, onFailure,
    productInfo, forceAdd, actionType,
  ) => {
    dispatch(cartProductActions.request(
      storeId, productId, quantity, onSuccess, onFailure,
      productInfo, forceAdd, actionType,
    ));
  },
});

const mapStateToProps = (state) => ({
  cartItems: state.main.cartItems,
  guestCustomCartItems: state.main.guestCustomCartItems,
});

GuestUserCart.propTypes = {
  cartItems: PropTypes.shape({
    productId: PropTypes.shape({
      productId: PropTypes.string.isRequired,
      storeId: PropTypes.number.isRequired,
      productInfo: PropTypes.shape({}).isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    productInfo: shape({}),
  }),
  guestCustomCartItems: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  cartProductRequest: PropTypes.func.isRequired,
  requestsProcessing: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string,
};

GuestUserCart.defaultProps = {
  cartItems: null,
  language: 'en',
  guestCustomCartItems: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestUserCart);
