import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import CustomModal from './CustomModal';

function ActionAlert(props) {
  const {
    actionOnConfirm,
    actionOnCancel,
    confirmText,
    cancelText,
    message,
    show,
    title,
    loader,
    failure,
    isMobile,
  } = props;
  const body = (
    <Container className="py-5 px-7 bg-white r__action_container">
      {title && (
      <Row className="py-2">
        <Col
          xs={24}
          className="text-dark"
        >
          <h3 className="m-0 r__action_title">
            {title}
          </h3>
        </Col>
      </Row>
      )}
      {message && (
      <Row className="py-2">
        <Col
          xs={24}
          className={`${title ? 'r__action_copy' : 'r__action_title--sm'}`}
        >
          {message}
        </Col>
      </Row>
      )}
      <Row
        className="text-center justify-content-center py-2 r__action_btn-wrapper"
      >
        {loader
          ? (
            <Col
              xs={24}
              className="text-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </Col>
          )
          : (
            <>
              {
                !!actionOnCancel
                && (
                  <Col
                    xs="auto"
                    className="py-1 px-0"
                  >
                    <Button
                      variant={isMobile ? 'link' : 'outline-primary'}
                      onClick={actionOnCancel}
                      className="fs-5 w-100 py-3 r__action_cancel"
                    >
                      {cancelText.toUpperCase()}
                    </Button>
                  </Col>
                )
              }
              {
                !!actionOnConfirm
                && (
                  <Col
                    xs="auto"
                    className="py-1 px-0"
                  >
                    <Button
                      variant={isMobile ? 'link' : 'primary'}
                      onClick={actionOnConfirm}
                      className="fs-5 w-100 py-3 r__action_confirm"
                    >
                      {confirmText.toUpperCase()}
                    </Button>
                  </Col>
                )
              }
            </>
          )}
      </Row>
      {failure && (
      <Row>
        <Col
          xs={24}
          className="text-danger fs-5"
        >
          {failure}
        </Col>
      </Row>
      )}
    </Container>
  );
  return (
    <CustomModal
      show={show}
      body={body}
      size=""
      onHide={actionOnCancel || (() => {})}
      backdrop
    />
  );
}

ActionAlert.propTypes = {
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.object,
  ]),
  show: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  actionOnConfirm: PropTypes.func,
  actionOnCancel: PropTypes.func,
  loader: PropTypes.bool,
  failure: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
};

ActionAlert.defaultProps = {
  title: null,
  message: null,
  confirmText: null,
  cancelText: null,
  actionOnConfirm: null,
  actionOnCancel: null,
  loader: false,
  failure: null,
};

export default ActionAlert;
