import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Form, InputGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import QueryString from 'query-string';
import { connect } from 'react-redux';
import * as Actions from '../../store/Actions';
import Svg from './Svg';
import { Constants } from '../../utilities';
import CategoryFilters from '../../layout/category-landing/CategoryFilters';
import ShopByCategory from '../../layout/category-landing/ShopByCategory';
import '../../styles/searchBar/search_bar.scss';

class SearchBar extends React.Component {
  constructor() {
    super();
    this.state = { searchText: '' };
  }

  componentDidMount = () => {
    const { categoryRequest, isMobile } = this.props;
    if (!isMobile) {
      categoryRequest(null, null);
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const { history, isGlobalSearch } = props;
    const queryParam = QueryString.parse(history.location.search);
    const { q = '' } = queryParam;
    if (state.searchText !== q && !isGlobalSearch) {
      return ({ searchText: q });
    }
    return null;
  }

  handleChange = (e) => {
    const { history } = this.props;
    const { value } = e.target;
    const { search } = history.location;
    const queryParam = QueryString.parse(search);
    this.setState({
      searchText: value,
    }, () => {
      if (value) {
        history.push({
          search: `?${QueryString.stringify({ ...queryParam, q: value })}`,
        });
      } else {
        delete queryParam.q;
        history.push({
          search: Object.entries(queryParam).length
            ? `?${QueryString.stringify(queryParam)}`
            : '',
        });
      }
    });
  }

  render() {
    const {
      history,
      isMobile,
      isGlobalSearch,
      language,
      placeholder,
      toggleGlobalSearch,
      showCategories,
      toggleSideOverlay,
    } = this.props;
    const { searchText } = this.state;
    return (
      <Container
        className={`rounded ${isMobile ? ' h-38p' : ' h-60p'}`}
      >
        <Row
          className="h-100 d-flex"
        >
          {
            showCategories && (
              isMobile ? (
                <Col
                  xs="auto"
                  className="h-100 py-0 pl-0 pr-2"
                >
                  <Button
                    variant="white"
                    className="h-100 text-primary border border-radius-8 fs-5"
                    onClick={() => toggleSideOverlay(
                      () => (
                        <CategoryFilters
                          {...this.props}
                          replaceHistory={false}
                        />
                      ),
                    )}
                  >
                    {Constants.String.CATEGORIES[language]}
                  </Button>
                </Col>
              ) : (
                <div
                  className="nav-categories"
                >
                  <Link
                    to="/pc"
                  >
                    <Button
                      variant="primary"
                      className="h-100 px-4 py-0 rounded d-flex align-items-center"
                    >
                      <span>
                        {Constants.String.SHOP_BY_CATEGORY[language]}
                      </span>
                      &nbsp;
                      <Svg
                        svg="arrowDown"
                        width="1.2rem"
                        stroke={Constants.Color.white}
                      />
                    </Button>
                  </Link>
                  <div
                    className="nav-categories-primary text-black px-n4 shadow"
                  >
                    <ShopByCategory
                      {...this.props}
                    />
                  </div>
                </div>
              )
            )
          }
          {
            isGlobalSearch ? (
              <Col
                as={Button}
                variant="link"
                className={`h-100 border d-flex align-items-center bg-white text-secondary py-0 px-3 ${
                  isMobile ? 'border-radius-8' : 'rounded'
                }`}
                onClick={() => {
                  if (isMobile) {
                    history.push('/search');
                  }
                  toggleGlobalSearch();
                }}
              >
                <Svg
                  svg="search"
                  fill={Constants.Color.secondary}
                  width="1.2rem"
                />
                <Form.Control
                  value={searchText}
                  spellCheck={false}
                  onChange={this.handleChange}
                  className="h-100 py-0 shadow-none border-0 bg-white fs-5 text-black"
                  placeholder={Constants.String.SEARCH_PRODUCTS_AND_BRANDS_ACROSS_SHOPS[language]}
                  autoComplete="off"
                  autoCorrect="off"
                  disabled
                />
              </Col>
            ) : (
              <Col
                as={InputGroup}
                className="h-100 d-flex align-items-center bg-white px-5 border border-dark border-radius-8"
              >
                <InputGroup.Prepend>
                  <InputGroup.Text
                    className="border-0 bg-transparent p-0 shadow-none"
                  >
                    <span
                      className="d-flex align-items-center"
                    >
                      <Svg
                        svg="search"
                        fill={Constants.Color.dark}
                        width="1.2rem"
                      />
                    </span>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="search-input"
                  type="search"
                  value={searchText}
                  spellCheck={false}
                  onChange={this.handleChange}
                  className="h-100 text-dark py-0 shadow-none border-0"
                  placeholder={placeholder}
                  autoComplete="off"
                  autoCorrect="off"
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.main.categories,
});

const mapDispatchToProps = (dispatch) => ({
  toggleGlobalSearch: () => {
    dispatch(Actions.toggleGlobalSearch());
  },
  categoryRequest: (storeId, cancelToken) => {
    dispatch(Actions.productCategoryRequest(cancelToken, storeId));
  },
});

SearchBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  isGlobalSearch: PropTypes.bool,
  language: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  toggleGlobalSearch: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  showCategories: PropTypes.bool,
  toggleSideOverlay: PropTypes.func.isRequired,
  categoryRequest: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  isGlobalSearch: false,
  placeholder: null,
  showCategories: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
