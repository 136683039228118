import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { cartProductActions, CART_PRODUCT_REQUEST } from 'app/store/cart/actions';
import { Constants } from '../../../utilities';
import { isAlreadyLoggedIn } from '../../../utilities/Utils';
import Toast from '../../../HOC/Toast';
import * as buttonActions from '../../../events/buttonActions';
import { logButtonClick } from '../../../events/Events';

const language = 'en';

const CartCounter = (props) => {
  const {
    cartProductReq,
    storeCode, productId, processing, isCart, isCheckout, onSuccess,
    showToast, customisable, toggleAddVariants, isProductScreen,
    isOfferSellingStores, isAppExOffer, isStoreDetailsDiscountOffer,
    cartItems, productInfo,
  } = props;
  let { quantity = 0 } = props;
  if (!isAlreadyLoggedIn()) {
    const cartProduct = cartItems && cartItems[productId];
    if (cartProduct) {
      if (cartProduct && cartProduct.storeId === storeCode) {
        quantity = cartProduct.quantity;
      }
    }
  }

  if (!isCheckout && processing[productId] === storeCode && !customisable) {
    return (
      <div
        className="py-3"
      >
        <div
          className="cart-counter-min overflow-hidden bg-primary-light"
        >
          <div
            className="process-add-to-cart"
          />
        </div>
      </div>
    );
  }

  const processingAddToCart = (processing[productId]
    || (isCart && Object.keys(processing).length > 0)
  ) && !customisable;

  const successCallback = (count, isAdding) => ({
    type: 'sync',
    callback: () => { onSuccess(count, isAdding); },
  });

  const onFailure = {
    type: 'sync',
    callback: (data) => {
      if (data.error) {
        showToast(data.error);
      }
    },
  };

  return (
    quantity === 0 ? (
      <Button
        variant={isProductScreen ? 'primary' : 'outline-primary'}
        className={`${isProductScreen ? 'fs-4 py-2' : 'fs-5 py-1'} cart-counter-min`}
        onClick={() => {
          if (customisable) {
            toggleAddVariants();
            return;
          }
          logButtonClick(
            {
              BUTTON: buttonActions.ADD_TO_CART,
              PRODUCT_ID: productId,
              QTY: quantity + 1,
              STORE_ID: storeCode,
            },
          );
          cartProductReq(
            storeCode,
            productId,
            quantity + 1,
            successCallback(1, true),
            onFailure,
            productInfo,
            false,
            'ADD',
          );
        }}
        disabled={processingAddToCart}
        block
      >
        {(
          isProductScreen && (
          <b>
            {Constants.String.ADD_TO_CART[language].toUpperCase()}
          </b>
          )) || (
          (isOfferSellingStores || isStoreDetailsDiscountOffer) && (
          <b>
            {Constants.String.ADD[language].toUpperCase()}
          </b>
          )) || (
          `+ ${Constants.String.ADD[language].toUpperCase()}`
        )}
      </Button>
    ) : ((
      isOfferSellingStores && isAppExOffer && (
        <Button
          variant="outline-secondary"
          className="fs-5 py-1 cart-counter-min"
          onClick={() => {
            logButtonClick(
              {
                BUTTON: buttonActions.REMOVE_FROM_CART,
                PRODUCT_ID: productId,
                QTY: quantity - 1,
                STORE_ID: storeCode,
              },
            );
            cartProductReq(
              storeCode,
              productId,
              quantity - 1,
              successCallback(quantity - 1, false),
              onFailure,
              productInfo,
              false,
              'DELETE',
            );
          }}
          disabled={processingAddToCart}
        >
            {Constants.String.REMOVE[language].toUpperCase()}
        </Button>
      )) || (
      <Row
        className={`border ${processingAddToCart
          ? '' : 'border-primary'} mx-0 fs-6 ${isCheckout
          ? 'min-w-70p' : 'cart-counter-min'} rounded`}
      >
        <Col
          xs="auto"
          className={isCheckout ? 'd-none' : 'px-0'}
        >
          <Button
            variant="link"
            className={`px-0 py-0 h-100 fs-4 text-${processingAddToCart
              ? 'medium' : 'primary'} text-decoration-none w-25p`}
            onClick={() => {
              if (customisable) {
                toggleAddVariants();
                return;
              }
              logButtonClick(
                {
                  BUTTON: buttonActions.REMOVE_FROM_CART,
                  PRODUCT_ID: productId,
                  QTY: quantity - 1,
                  STORE_ID: storeCode,
                },
              );
              cartProductReq(
                storeCode,
                productId,
                quantity - 1,
                successCallback(quantity - 1, false),
                onFailure,
                productInfo,
                false,
                'UPDATE',
              );
            }}
            disabled={processingAddToCart}
          >
            -
          </Button>
        </Col>
        <Col
          className={`px-0 text-center text-${
            processingAddToCart ? 'medium' : 'primary'} ${isProductScreen
            ? 'font-weight-bold py-2' : 'py-1'}`}
        >
          {quantity}
        </Col>
        <Col
          xs="auto"
          className={isCheckout ? 'd-none' : 'px-0'}
        >
          <Button
            variant="link"
            className={`px-0 py-0 h-100 fs-4 text-${processingAddToCart
              ? 'medium' : 'primary'} text-decoration-none w-25p`}
            onClick={() => {
              if (customisable) {
                toggleAddVariants();
                return;
              }
              logButtonClick(
                {
                  BUTTON: buttonActions.ADD_TO_CART,
                  PRODUCT_ID: productId,
                  QTY: quantity + 1,
                  STORE_ID: storeCode,
                },
              );
              cartProductReq(
                storeCode,
                productId,
                quantity + 1,
                successCallback(quantity + 1, true),
                onFailure,
                productInfo,
                false,
                'UPDATE',
              );
            }}
            disabled={processingAddToCart}
          >
            +
          </Button>
        </Col>
      </Row>
    ))
  );
};

const mapStateToProps = (state) => ({
  processing: state.main.requestsProcessing[CART_PRODUCT_REQUEST],
  cartItems: state.main.cartItems,
});

const mapDispatchToProps = (dispatch) => ({
  cartProductReq: (
    storeCode, productId, quantity, onSuccess, onFailure, productInfo,
    forceAdd, actionType,
  ) => {
    dispatch(
      cartProductActions.request(
        storeCode, productId, quantity, onSuccess, onFailure, productInfo,
        forceAdd, actionType,
      ),
    );
  },
});

CartCounter.propTypes = {
  cartProductReq: PropTypes.func.isRequired,
  storeCode: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  processing: PropTypes.shape({}).isRequired,
  cartItems: PropTypes.shape({}),
  productInfo: PropTypes.shape({
    mrp: PropTypes.number,
    productName: PropTypes.string,
    sellingPrice: PropTypes.number,
    imageUrl: PropTypes.string,
  }).isRequired,
  isCart: PropTypes.bool,
  isCheckout: PropTypes.bool,
  onSuccess: PropTypes.func,
  showToast: PropTypes.func.isRequired,
  toggleAddVariants: PropTypes.func,
  customisable: PropTypes.bool,
  isProductScreen: PropTypes.bool,
  isOfferSellingStores: PropTypes.bool,
  isAppExOffer: PropTypes.bool,
  isStoreDetailsDiscountOffer: PropTypes.bool,
};

CartCounter.defaultProps = {
  isCart: false,
  isCheckout: false,
  onSuccess: () => {},
  customisable: false,
  toggleAddVariants: () => {},
  isProductScreen: false,
  isOfferSellingStores: false,
  isAppExOffer: false,
  isStoreDetailsDiscountOffer: false,
  cartItems: null,
};

export default Toast(connect(mapStateToProps, mapDispatchToProps)(CartCounter));
