import React from 'react';

const Svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 4C4 7.55556 4.53333 14.9333 6.66667 16C8.8 17.0667 11.1111 19.1111 12 20" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 4C4 7.55556 4.53333 14.9333 6.66667 16C8.8 17.0667 11.1111 19.1111 12 20" stroke="black" strokeOpacity="0.72" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 4C20 7.55556 19.4667 14.9333 17.3333 16C15.2 17.0667 12.8889 19.1111 12 20" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 4C20 7.55556 19.4667 14.9333 17.3333 16C15.2 17.0667 12.8889 19.1111 12 20" stroke="black" strokeOpacity="0.72" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 4C4 4 6 6 8 6C10 6 12 4 12 4C12 4 14 6 16 6C18 6 20 4 20 4" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 4C4 4 6 6 8 6C10 6 12 4 12 4C12 4 14 6 16 6C18 6 20 4 20 4" stroke="black" strokeOpacity="0.72" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.33331 11.3333L11.3333 13.3333L14.6666 9.33333" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.33331 11.3333L11.3333 13.3333L14.6666 9.33333" stroke="black" strokeOpacity="0.72" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Svg;
