import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { Svg } from '../../components/common';
import { Constants } from '../../utilities';

const RateOrder = (props) => {
  const {
    isMobile, language, rateOrder, handleOrderRatingStar,
    handleChatSupport,
  } = props;
  const ratingStar = [];
  for (let i = 1; i <= 5; i += 1) {
    ratingStar.push(
      <Button
        variant="link"
        onClick={() => {
          handleOrderRatingStar(i);
        }}
        className="p-0 mx-1"
      >
        <Svg
          svg="star2"
          fill={Constants.Color.white}
          stroke={Constants.Color.pink}
          width="3rem"
        />
      </Button>,
    );
  }
  return (
    <Container
      className="px-0"
    >
      <Row
        className="mx-0 bg-white"
      >
        {isMobile && (
        <Col
          xs={24}
          className="py-3 border-bottom"
        >
          <b>
            {Constants.String.RATE_YOUR_EXPERIENCE[language]}
          </b>
        </Col>
        )}
        <Col
          xs={24}
          className="py-4 border-bottom"
        >
          <div
            className={`text-center mb-2 px-10 ${isMobile ? '' : 'font-weight-bold'}`}
          >
            {Constants.String.RATE_YOUR_EXPERIENCE_DESC[language].replace(/{shopName}/g, rateOrder.shopName)}
          </div>
          <div
            className="text-center"
          >
            {ratingStar}
          </div>
        </Col>
        <Col
          xs={24}
          className="py-2 d-flex align-items-center justify-content-center"
        >
          <div
            className="fs-5 mr-1"
          >
            {Constants.String.DIDNOT_RECEIVE_YOUR_ORDER[language]}
          </div>
          <div>
            <Button
              variant="link"
              onClick={handleChatSupport}
              className="p-0 fs-5"
            >
              {Constants.String.CHAT_WITH_SUPPORT[language]}
              &nbsp;
              <Svg
                svg="chat2"
                stroke={Constants.Color.pink}
                width="1.2rem"
              />
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

RateOrder.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  rateOrder: PropTypes.shape({
    shopName: PropTypes.string,
  }).isRequired,
  handleOrderRatingStar: PropTypes.func.isRequired,
  handleChatSupport: PropTypes.func.isRequired,
};

export default RateOrder;
