import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import ProductThumb from 'app/components/common/ProductThumb';
import CustomScroller from 'app/components/common/CustomScroller';

function CategoryProductsSuggestions(props) {
  const { suggestedProductDetails, cartItems } = props;
  return (
    <Container
      fluid
      className="py-4 px-0 bg-light h-100"
    >
      <Row
        className="mx-0"
      >
        <Col
          xs={24}
          className="fs-5 font-weight-black pb-4"
        >
          Frequently bought together
        </Col>
        <Col
          xs={24}
        >
          <CustomScroller
            content={(
              <div className="d-flex">
                {
                  suggestedProductDetails?.data?.data?.map((product) => (
                    <div
                      key={product.libraryProductId}
                      className="mr-3 bg-white border-radius-16"
                    >
                      <ProductThumb
                        key={product.libraryProductId}
                        libraryProductId={product.libraryProductId}
                        retailProductId={product.retailerProductId}
                        name={product.name}
                        image={product.image}
                        mrp={product.mrp}
                        sellingPrice={product.sellingPrice}
                        measurementDisplayText={product.measurementDisplayText}
                        storeId={product.store.id}
                        offerPercent={product.offerPercent}
                        previouslyBought={product.previouslyBought}
                        inStock={product.inStock}
                        cartItems={cartItems}
                        page="SHOPFRONT_SUGGESTED_PRODUCTS"
                        section="Frequently bought together"
                      />
                    </div>
                  ))
                }
              </div>
            )}
            contentPrefix="CategoryProductsSuggestions"
          />
        </Col>
      </Row>
    </Container>
  );
}

CategoryProductsSuggestions.propTypes = {
  // retailerProductId: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.number,
  // ]).isRequired,
  suggestedProductDetails: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  cartItems: PropTypes.shape({}).isRequired,
};

export default CategoryProductsSuggestions;
